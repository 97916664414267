import {AutoList} from './auto_list'

class controller extends AutoList {
    constructor() {
        super();
        this.item = {name: '', value: ''};
    }
}

export const  WizardDeployVariablesComponent = {
    bindings: {
        data : "="
    },
    controller: controller,
    template : `
        <!--<div class="flex layout-row">-->
            <!--<div class="flex-50">Key</div>-->
            <!--<div class="flex-50">Value</div>-->
        <!--</div>-->
        <div ng-repeat="label in $ctrl.list">
            <ng-form name="chartLabels" class="flex layout-row layout-align-space-between-start">
                <md-input-container class="flex-50">
                    <label>Name</label>
                    <input type="text" ng-model="label.key" ng-change="$ctrl.change(chartLabels, $index)" ng-required="$ctrl.canDelete($index)">
                </md-input-container>
                <div class="flex-5"></div>
                <md-input-container class="flex-50">
                    <label>Value</label>
                    <input type="text" ng-model="label.value" ng-change="$ctrl.change(chartLabels, $index)" ng-required="$ctrl.canDelete($index)">
                </md-input-container>
                <div class="flex-5"></div>
                <div class="flex-10">
                    <md-button class="md-icon-button" ng-show="$ctrl.canDelete($index)" ng-click="$ctrl.delete($index)"><md-icon md-svg-icon="core:delete" ></md-icon></md-button>
                </div>
            </ng-form>
        </div>
        
    `
};
