import {CatalogBaseVersionController} from '../version/controller'
// import {ServingFormController} from '../controller'

export class CatalogVersionController extends CatalogBaseVersionController{
    constructor($scope, $state, $mdDialog, $templateCache, Utils, ErrorService, CatalogService) {
        super($scope, $state, $mdDialog, $templateCache, Utils, ErrorService);
        this.Utils = Utils;
        this.CatalogService = CatalogService;
        this.ErrorService = ErrorService;
        this.$mdDialog = $mdDialog;
        this.$templateCache = $templateCache;
        this.canNew = true;
        this.edit = false;
    }

   createNew(version) {
       var newVersion = {
           "Version": null,
           "From": version ? version.Version : null,
           'isNew': true,
           "Message": version ? version.Message : null
       };

       this.versions.unshift(newVersion);
       this.version = this.versions[0];

   }

   removeVersion (v) {
       let rebase = false;
       if(v == this.version) rebase = true;
       this.versions = _.without(this.versions, v);
       this.version = _.first(this.versions);
   }

    getItem(item) {

        var data = this.$state.params;
        if(!item) {
            this.getDefault();
        }
        else {
            this.version = item;
        }
        data.tabItem = this.version.Version;
        this.$state.go( this.$state.current.name, data, {notify:false, location: true});
    }
}

export const CatalogVersionViewComponent = {
    bindings: {
        resource: '<',
        data: '<',
        canManage: '<',
        service: '<',
        canNew: '<'
    },
    controller: CatalogVersionController,
    template:  ($templateCache) => {
        return $templateCache.get('kuberlab/catalog/base/version-view/veiwer/tpl.html');
    }
}
