import {InstallTab} from './install_tab'


class InstallChartStorages extends InstallTab{

    constructor($scope, ProjectStoragesService){
        super();
        this.$scope = $scope;
        this.ProjectStoragesService = ProjectStoragesService;
        this.current = 0;
    }

    $onInit($scope) {
        super.$onInit();
        var self = this;
        this.$scope.$watch(function(){
                return self.parent.clusters;
            },
            function (c) {
                self.enable();
                if(c){
                    self.loadStorages();
                }
            }
        );
        this.$scope.$watch(function(){
                return self.parent.target_application;
            },
            function () {
                self.enable();
            }
        );
        this.$scope.$watch(function(){
                return self.parent.target_applications;
            },
            function () {
                self.enable();
            }
        );
    }

    loadStorages() {
        this.storages = [];
        var self = this;

        _.each(this.parent.clusters, function (cluster) {

            var r = this.ProjectStoragesService.getResource({
                wsId: this.parent.ws.workspace_name,
                projectName: this.parent.ws.project_name,
                envName: this.parent.ws.environment,
                clusterName: cluster
            } , true);
                r.query()
                .$promise
                .then(function (r) {
                    r = _.map(r, function (s) {
                        return _.extend(s, {
                            "cluster": cluster,
                            "cluster_storage": s.Name,
                            "project_name": self.parent.ws.project_name,
                            "workspace_name": self.parent.ws.workspace_name
                        })
                    });
                    self.storages = self.storages.concat(r);
                    self.toogle(self.current);
                }, function (err) {
                    debugger;
                })
        }, this);
    }

    canEdit() {
        return !!(this.parent.clusters && this.parent.clusters.length && (this.parent.target_application || this.parent.target_applications));
    }

    toogle (index) {
        this.storage = this.storages[this.current];
    }
}

export const InstallChartStoragesComponent = {
    bindings: {
        parent: "<",
        tab: "=",
        index: "<",
        storage: "="
    },
    controller : InstallChartStorages,
    template: `
            <div class="layout-collumn">
            
                <md-radio-group ng-model="$ctrl.current" class="layout-column" ng-change="$ctrl.toogle()">
                            <md-radio-button 
                                    ng-repeat="item in $ctrl.storages| orderBy : 'DisplayName'"
                                    value="{{$index}}" 
                                    class="layout-column">
                                <div class="layout-row layout-align-start-center">
                                    <md-icon md-svg-icon="core:nfs" class="md-avatar-icon" ></md-icon>
                                    <div style="padding: 0 1em;" class="flex">
                                        <div><b>{{item.DisplayName}}</b> ({{item.Name}})</div>
                                    </div>
                                    <div><span>Server: {{item.nfs.server}}</span>&nbsp;<span>Path: {{item.nfs.path}}</span></div>
                                </div>
                                <p ng-if="item.Description">{{item.Description}}</p>
                            </md-radio-button>
                        </md-radio-group>
            
            
                <!--<md-checkbox -->
                    <!--ng-repeat="item in $ctrl.storages" -->
                    <!--ng-checked="$ctrl.current == $index" -->
                    <!--ng-click="$ctrl.toogle( $index )">-->
                    <!--<div class="flex layout-row layout-align-start-center">-->
                        <!--<div class="flex">-->
                            <!--<div class="title">{{ item.DisplayName }}</div>-->
                            <!--<div class="desk">Provider: {{item.Provider}} - {{item.Account}} (master ip: {{item.MasterIP}})</div>-->
                        <!--</div>-->
                    <!--</div>-->
                    <!--<div>{{item}}</div>-->
                <!--</md-checkbox>-->
            </div>
    `
};
