import {ErrorControllerBase, ErrorController, ErrorDialogTemplate} from './error_controller'

export class ErrorService {
    constructor($translate, $mdDialog, $rootScope) {
        this.$mdDialog = $mdDialog;
        this.$translate = $translate;
        this.$rootScope = $rootScope;
        this.errorList = [];
        window.ServiceError = this;
    }

    error (error, cb = () => {}, params) {
        if(this.$rootScope.windowFocus) {
            this.errorDialog(error, cb, params);
        }
    }

    errorDialog(error, cb, params) {
        if(this.openDialog)  return;
        this.openDialog = true;
        this.$mdDialog.show({
            locals: {
                $mdDialog: this.$mdDialog,
                $translate: this.$translate,
                error: error
            },
            controller: ErrorConttollerModal,
            controllerAs: "$ctrl",
            template: `
                <md-dialog aria-label="error service">
                        <md-toolbar>
                            <div class="md-toolbar-tools">
                                <h2>{{$ctrl.title}}</h2>
                                <span flex></span>
                                <md-button ng-click="$ctrl.$mdDialog.cancel()" class="md-icon-button"><md-icon md-svg-icon="core:close"></md-icon></md-button>
                            </div>
                        </md-toolbar>
                        <md-dialog-content >
                            <div class="md-dialog-content" ng-switch="$ctrl.errorType">
                                <div ng-switch-when="confirm" bind-html-compile="$ctrl.ConfirmDelete"></div>        
                                <div ng-switch-when="prevent" bind-html-compile="$ctrl.PreventDelete"></div>        
                                <div ng-switch-when="confirmAction" bind-html-compile="$ctrl.confirmAction"></div>      
                                <div ng-switch-when="force" bind-html-compile="$ctrl.FroceDelete"></div>      
                                  
                                <div ng-switch-default>
                                    <p>
                                        <span>{{$ctrl.text}}</span> 
                                        <md-button class="md-icon-button" ng-if="$ctrl.moreText.length" ng-click="$ctrl.more = !$ctrl.more"><md-icon md-svg-icon="core:dots-horizontal"></md-icon></md-button>
                                    </p>
                                    <div ng-if="$ctrl.more" style="margin: 1em 0; overflow: auto" ng-bind-html="$ctrl.moreText"></div>
                                </div>
                            </div>
                        </md-dialog-content>
                    </form>
                </md-dialog>
            `,
            parent: angular.element(document.querySelector('#mainBody')),
            clickOutsideToClose:false
            })
            .then(
                (p) => {
                    cb(params || p);
                },
                () => {
                })
            .finally(() => {
                this.openDialog = false;
            })
        ;
    }
}

class ErrorConttollerModal extends ErrorControllerBase {
    constructor ($translate, $mdDialog,  error, $scope) {
        super(...arguments);
        this.$mdDialog  = $mdDialog;
        this.$scope = $scope;
        this.error = error;
    }

    sendConfirm(form){
        if(form.$valid){
            let p = {};
            if(this.confirm) p.confirm = this.confirm;
            if(this.force) p.force = true;
            this.$mdDialog.hide(p);
        }
    }
}
