
export default {
    state: {
        name: 'catalog_newMain'
    },
    steps:[
        {
            element: '#catalog_interface_chart-mlapp-v2',
            intro: "Go to catalog applications.",
            action: 'click'
        }
    ],
    showBullets: false,
    showButtons: false,

}
