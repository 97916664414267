class controller {

    constructor($scope){
        var self = this;
        $scope.$watch(
            function () {
                return self.data.value;
            },
            function (c) {
                self.parent.setYaml();
            }
        );
    }
}

export const ChartWizardBoolean = {
    bindings: {
        parent: "<",
        data: "="
    },
    controller : controller,
    template: `
            <div class="layout-collumn">
                <md-input-container>
                    <md-checkbox ng-model="$ctrl.data.value"></md-checkbox>
                    <!--<md-select ng-model="$ctrl.data.value">-->
                        <!--<md-option ng-value="item" ng-repeat="item in $ctrl.data.wizard.options">{{item}}</md-option>-->
                    <!--</md-select>         -->
                </md-input-container>
            </div>
    `
};

