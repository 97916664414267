import {d3Metrics, d3Metric, d3Serie, controller} from '../metrics2/applications_metrics_component'


class ApplicationJobMetrics extends controller {

    constructor(ApplicationsService, applicationMetricsList, $state, $localStorage, $mdSidenav, $filter, $scope, $rootScope, $transitions) {
        super(...arguments);
        this._inited = false;
        this.ChartOptions = {legend: {display: false}};
        this.applicationMetricsList = applicationMetricsList;
        this.metricsList = applicationMetricsList;
        this.timeIntervals.push({value: 24 * 60 * 60 * 1000 * 7, title: '1 week'});
        // this.timeInterval = null;

    }

    $onInit() {
        this.getTensorboardMetrics();
        this.$scope.$watch(
            () => {return this.task; },
            (n,o) => {
                if( this.active && n && o && (n.name != o.name) ) {
                    this.Metrics = {};
                    this.d3Metrics.clear();
                    this.initMetricList(this.metricsList);
                    this.updateMetrics();
                }
            }
        );
        this.$scope.$watch(
            () => { return this.active ;},
            (c) => {
                if(c && !this._inited) {
                    this.setMetricElement(this.task, 'taskGraph');
                    this._inited = true;
                }
            }
        );
    }

    setTimeInterval(interval){
        this.timeInterval = interval;
        this.updateMetrics();
    }

    requestTimeInterval() {
        var time = {};
        if(this.timeInterval.start){
            time.start = this.toISOString(this.timeInterval.start);
            if(this.timeInterval.end)
                time.start = this.toISOString(this.timeInterval.end);
        } else if (this.timeInterval.interval) {
            // var start = this.task.stop_time ? new Date(this.task.stop_time) : new Date();
            time.end = this.task.stop_time ? new Date(this.task.stop_time) : new Date();
            time.start = this.toISOString( time.end - this.timeInterval.interval);
        } else {
            return null;
        }

        return time;
    }

    getTensorboardMetrics() {
        this.appController.applicationsResource.taskTensorboardMetrics({
            task: this.task.task_name,
            build: this.task.build,
            application: this.appController.data.Name

        })
            .$promise
            .then(
                (r) => {
                    if(_.isArray(r) && r.length){
                        var list = angular.copy(this.metricsList);
                        this.initMetricList( list.concat(r), 'tensorboard-');
                    }
                },
                (err) => {
                    // debugger
                }
            )
            .finally(() => { this.mComplite = true});

    }

    $onDestroy() {
        // this.listenerState();
    }

    updateMetrics() {
        this.getTensorboardMetrics();
        _.each( this.orderMetric, (v) => {
            this.getTaskGraph(this.task, v);
        });
    }

    getTaskGraph(item, name) {
        var tinterval = this.requestTimeInterval();
        var respData = {
            taskName: item.task_name,
            interval: this.interval,
            build: item.build,
            metrics: this.getMetricsList().join(','),
            details: this.details,
            application: this.appController.data.Name
        };
        if ( tinterval ) {
            _.extend(respData, tinterval);
        }
        var elements = null; // [name];
        this.loading = true;
        this.apiError= false;

        this.appController.applicationsResource.taskGraph(respData).$promise
            .then(
                (r) => {
                    let d3M = {};
                    _.each(r.metrics, (m) => {
                        _.each(m.metrics, (mm) => {
                            if( !d3M[mm.name] ) { d3M[mm.name] = { metrics: {}} }
                            if( !d3M[mm.name].metrics[m.component_name] ) { d3M[mm.name].metrics[m.component_name] = {component_name: m.component_name, component_name_paremt : m.component_name}; } //{component_name: m.component_name, component_name_paremt : name}; }
                            d3M[mm.name].metrics[m.component_name].values = this.mapData(mm.values);
                            d3M[mm.name].unit = mm.unit;
                        })
                    });
                    this.d3Metrics.addMetris(d3M, elements, this.getMetricsList()  );
                    // console.info(d3M,this.d3Metrics)

                },
                (err) => {
                    this.apiError=err;
                    this.$scope.$emit('api_error', err);
                }
            )
            .finally(()=>{
                this.loading = false;
            })
    }


}

export const ApplocationJobMertincsComponent = {
    bindings: {
        appController: '<',
        task: '<',
        active:'<'
    },
    controller: ApplicationJobMetrics,
    template: ($templateCache) => {
        return $templateCache.get('kuberlab/applications/jobs/applications_job_metrics.html')
    }

};
