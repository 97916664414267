// class UserCardHeader{
//     constructor ($scope, CurrentUser, AuthService, UserService) {
//         var self = this;
//         this.ws = UserService.ws;
//         $scope.$watch(function () {
//             return UserService.ws;
//             },
//             function (current) {
//                 self.ws = current;
//             }
//         );
//         this.AuthService = AuthService;
//         this.currentUser = false;
//         CurrentUser.then(
//             function (r) {
//                 self.currentUser = r;
//             }
//         );
//         UserService.getWs();
//     }
// }

import {UserCardController} from './user_card_controller'

export const UserCardHeaderComponent = {
    controller: UserCardController,
    template: `
    
        <cmp-notify-list></cmp-notify-list>
        <md-menu-bar
            id="title_user_card"
            class="full_height">
            <md-menu  md-position-mode="target-right target" md-offset="0 64"
                class="full_height">
                <button ng-click="$mdMenu.open()"
                    class="full_height"
                    layout="row"
                    layout-align="start center">
                    <img ng-src="{{$ctrl.currentUser.Picture}}" class="md-avatar" alt="{{$ctrl.userData.DisplayName}}"/>
                    {{$ctrl.currentUser.DisplayName}}
                    <md-icon md-svg-icon="core:chevron-down" class="icon s16" hide-xs="" aria-hidden="true"></md-icon>
                </button>
                <md-menu-content class="cmp-user-card-header">
                
                    <md-menu-item>
                        <md-button disabled="disablid">Workspace</md-button>
                    </md-menu-item>
                    
                    <md-menu-divider></md-menu-divider>

                    <md-menu-item ng-repeat="data in $ctrl.ws  | orderBy:['Type','DisplayName'] ">
                        <md-button ui-sref="workspace({wsId : data.Name})" class="no-padding">
                            <span class="label">{{data.DisplayName}}</span>
                        </md-button>
                    </md-menu-item>
                    
                    <md-menu-divider></md-menu-divider>
                    
                    <md-menu-item >
                        <md-button ui-sref="setting" class="no-padding">
                            <md-icon md-svg-icon="core:settings"></md-icon>
                            <span class="label no-float">Settings</span>
                        </md-button>
                    </md-menu-item>

                    <md-menu-item ng-if="$ctrl.viewSettings.infra">
                        <md-button ui-sref="sharedCluster.tab({tab: 'available'})" class="no-padding">
                            <md-icon md-svg-icon="core:share"></md-icon>
                            <span class="label no-float">Shared clusters</span>
                        </md-button>
                    </md-menu-item>
                    
                    <md-menu-item >
                        <md-button ng-click="$ctrl.AuthService.logout()">
                            <md-icon md-svg-icon="core:exit" ></md-icon>
                            <span class="label no-float">Logout</span>
                        </md-button>
                    </md-menu-item>
                </md-menu-content>
            </md-menu>
        </md-menu-bar>
        <!--<button class="md-icon-button md-button"-->
                <!--type="button"-->
                <!--id="title_info_block"-->
                <!--ng-click="$ctrl.DashboardService.toggleSidenav('infoBlock')"-->
                <!--aria-label="Toggle quick panel">-->
            <!--<md-icon md-svg-icon="core:info" aria-hidden="true"></md-icon>-->
        <!--</button>-->
    
    `
};