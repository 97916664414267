import {ControllerItem} from '../../core/controller/item'

class controller extends ControllerItem {

    constructor(
        $mdDialog,
        $translate,
        validate
    ){
        super($mdDialog, $translate);
        this.validate = validate;
    }

    // $onInit() {
    //     debugger;
    // }

    isNew() {
        return (this.data && this.data.ID) ? false : true;
    }

    action(action){
        this.data['$' + action]()
            .then(
                (r) => {
                debugger;
                },
                (err) => {
                    this.error = err;
                }
            )
    }
}

export const UserTokenItemComponent = {
    bindings: {
        data: '=',
        listController: '<'
    },
    controller: controller,
    template: function ($templateCache) {
        return $templateCache.get('kuberlab/user/token/user_token_item_component.html')
    }
}
