import {ControllerList} from '../../core/controller/list'

class controller extends ControllerList {
    constructor (CatalogTagService, $state, $scope, CatalogInterface) {
        super();
        this.Service = CatalogTagService;
        this.$state = $state;
        this.resource  = this.Service.getResource($state.params);
        this.$scope = $scope;
        // this.$scope.$watchCollection(
        //     () => {return this.Service.list},
        //     (c) => {this.list = c;}
        // );
        this.CatalogInterface = CatalogInterface;

    }

    $onInit() {

        var data = this.interface ? {for: this.interface} : {};
        if(!this.list){
            this.$scope.$watchCollection(
                () => {return this.Service.list},
                (c) => {this.list = c;}
            );
            this.getList(data);
        }
    }

    getList(params) {
        this.Service.getResource(this.$state.params);
        this.Service.getList(false, params);
    }

    getInterfaceTag(Interface) {
        if(!this.list) return;

        var list =  _.filter(this.list, (v) => {
            var i = _.indexOf(v.For , Interface);
            return  ( i != -1 )
        });
        return list;
    }
}


export const CatalogTagListComponent = {
    bindings: {
        interface: '<',
        list: '<'
    },
    controller: controller,
    template: `
        <div layout="row" layout-align="start" class="catalog-interface-tags col_3_view"">
            <div class="item_view" ng-repeat="item in $ctrl.list" >
                 <a ui-sref="{keyword: $ctrl.$state.params.keyword == item.Tag ? null : item.Tag}" class="item" ng-class="{active: $ctrl.$state.params.keyword == item.Tag}" >
                    <md-card>
                        <div class="img" src="item.Picture" style="background-image: url({{item.Picture}});" ></div>
                        <h4 class="title">{{item.Description}}</h4>
                    </md-card>
                </a>
            </div>
        </div>
    `
};


class controllerMain extends controller {
    constructor (CatalogTagService, $state, $scope, CatalogInterface) {
        super(...arguments)
    }

    $onInit() {
        super.$onInit();
        if(!this.stateName) this.stateName = '.list';
    }

}


export const CatalogTagListMainComponent = {
    bindings: {
        stateName: '<'
    },
    controller: controllerMain,
    template: `
        <md-content layout="column" flex layout-align="start" class="md-padding  _catalog-interface-tags ">
            <div class="item_view" ng-repeat="inter in $ctrl.CatalogInterface" >
                <h3><a ui-sref=".list({Interface: inter.Interface})" id="catalog_interface_{{inter.label}}" style="text-decoration: underline;" translate >{{inter.label}}</a></h3>
                <div layout="row" layout-align="start" class="catalog-interface-tags col_3_view"">
                    <div class="item_view" ng-repeat="item in $ctrl.getInterfaceTag(inter.Interface)" >
                         <a ui-sref=".list({Interface: inter.Interface, keyword: $ctrl.$state.params.keyword == item.Tag ? null : item.Tag})" class="item" ng-class="{active: $ctrl.$state.params.keyword == item.Tag}" >
                            <md-card>
                                <div class="img" src="item.Picture" style="background-image: url({{item.Picture}});" ></div>
                                <h4 class="title">{{item.Description}}</h4>
                            </md-card>
                        </a>
                    </div>
                </div>
            </div>
        </md-content>
    `
};
