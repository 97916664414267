
import {ProjectAppsService} from './project_apps_service'
import {ProjectClusterAppsComponent} from './project_cluster_apps_controller'
import {ProjectClusterAppComponent} from './project_cluster_app_controller'
import {ProjectAppComponent} from  './project_app_controller'


export default angular
    .module('projectApp' , [])
    .service('ProjectAppsService', ProjectAppsService)
    .component('cmpProjectClusterApps', ProjectClusterAppsComponent)
    .component('cmpProjectClusterApp', ProjectClusterAppComponent)
    .component('cmpProjectApp', ProjectAppComponent)