import {InstallTab} from './install_tab'


class InstallChartApplications extends InstallTab{
    constructor(ApplicationsService, $scope, validate){
        super();
        this.APPLICATION_NAME_REGEX = new RegExp(validate.idPattern);

        this.$scope = $scope;
        this.ApplicationsService = ApplicationsService;
        var self = this;

        $scope.$watch(
            function () {
                return self.parent.workspace_name;
            },
            function () {
                if(self.target_application) {
                    self.changeTarget();
                }
                self.enable();
            }
        );
        $scope.$watch(
            function () {
                return self.parent.chart;
            },
            function (c) {
                self.target_application = c.DisplayName;//.toLowerCase();
                self.changeTarget();
                self.enable();
            }
        );
        this.$scope.$watchCollection(function(){
                return self.parent.clusters;
            },
            function () {
                self.enable();
            }
        )
    }

    changeTarget(form) {
        this.error = null;
        if(!form) form = this.$scope.appName;
        form.app.$setValidity("errorname", true);
        this.targetError =  !this.APPLICATION_NAME_REGEX.test(this.target_application);

        if(this.target_application && !this.targetError) {
            form.app.$setValidity("errornamePattern", true);
            this.isavailable(form);
        }else {
            form.app.$setValidity("errornamePattern", false);
            this.application = false;
        }
    }

    isavailable (form){
        if(!this.parent.workspace_name) return false;

        this.appresourceResource = this.ApplicationsService.getResource({wsId: this.parent.workspace_name});
        this.appresourceResource.isavailable({name: this.target_application})
            .$promise
            .then(
                (resp) =>{
                    this.application = this.target_application;
                    form.app.$setValidity("errorname", true);
                },
                (err) => {
                    this.application = false;
                    this.error = err;
                    form.app.$setValidity("errorname", false);
                }
            )
    ;
    }

    canEdit() {
        return !!(this.parent.workspace_name ) && !!(this.parent.clusters && this.parent.clusters.length) ;
    }
}

export const InstallChartMlapplicationsComponent = {
    bindings: {
        application : '=',
        parent: "<",
        tab: "=",
        index: "<"
    },
    controller : InstallChartApplications,
    template: `
        <form name="appName" layout-align="start start" layout="column">
        <p>Description target applications</p>
             <md-input-container style="width: 99%">
                 <label>Choose application name</label>
                 <input type="text" name="app" ng-model="$ctrl.target_application" ng-model-options='{ debounce: 500 }' ng-change="$ctrl.changeTarget(appName)">
                 
                <div ng-messages="appName.app.$error"  multiple md-auto-hide="false">
                    <div ng-message="errornamePattern" translate>FORM_ERROR_ID_PATTERN</div>
                    <div ng-message="errorname">{{$ctrl.error.data.Error}}</div>
                </div>
            </md-input-container>
        </form>
    `
};
