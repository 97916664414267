export class applicationHistoryController {
    constructor(appController){
        this.appController = appController;
        this.formData;
        this.formOptions;
        this.selectedIndex = 0;
    }

    $onInit() {
        _.delay(() => {
            this.complite = true;
        }, 200)
    }

    cancel () {
        this.appController.$mdDialog.cancel();
    };

}

