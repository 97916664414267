import {ProjectClusterService} from './project_clusters_service'
import {ProjectClustersController} from './project_clusters_controller'
import {ProjectClusterComponent} from './project_cluster_component'
import {ProjectClusterNewComponent} from './project_cluster_new'

export default angular
    .module('projectClusters', ['lfNgMdFileInput'])
    .service('ProjectClusterService', ProjectClusterService)
    .component('cmpProjectsClusters', {
        template : function ($templateCache) {
            return $templateCache.get('kuberlab/projects/clusters/project_clusters.html')
        },
        controller: ProjectClustersController
    })
    .component('cmpProjectCluster', ProjectClusterComponent)
    .component('cmpProjectClusterNew', ProjectClusterNewComponent);
