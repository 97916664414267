export function LayoutPageTitle() {

    function link() {

    }

    return ({
        link: link,
        restrict: "E"
    })
}
