export class ProjectClusterService {
    constructor ($resource, $q, KuberlabConfigApi){
        this.cfg = KuberlabConfigApi;
        this.$q = $q;
        this.$resource = $resource;
        this.list = null;
        this.isLoading = false;
    }

    getResource(params, clear) {
        var resource;
        if(!params.wsId || !params.projectName || !params.envName) {
            throw "error params";
        }
        if(this.wsId != params.wsId || this.projectName != params.projectName || this.envName != params.envName){
            this.baseApiPath = this.cfg.APIPath + '/workspace/' + params.wsId + '/projects/' + params.projectName + '/env/' + params.envName + '/clusters/';
            this.apiPath = this.baseApiPath + ':clusterName';
            resource = this.$resource(
                this.apiPath,
                {clusterName: '@Name'},
                _.extend({}, this.cfg.ResourceParams,
                    {
                        clusterscheckname: {
                            method: "GET",
                            url: this.cfg.APIPath + '/workspace/' + params.wsId + '/projects/' + params.projectName + '/env/' + params.envName + '/clusterscheckname'
                        },
                        clusterschecksrc: {
                            method: "GET",
                            url: this.cfg.APIPath + '/workspace/' + params.wsId + '/projects/' + params.projectName + '/env/' + params.envName + '/clusterschecksrc'
                        },

                        upload: {
                            method: 'POST',
                            url: this.apiPath + '/yaml',
                            transformRequest: function (data) {
                                var fd = new FormData();
                                angular.forEach(data, function(value, key) {
                                    fd.append(key, value);
                                });
                                return fd;
                            },
                            headers: {'Content-Type':undefined, enctype:'multipart/form-data'}
                        },
                        create : {
                            method: 'POST',
                            params : {clusterName: null}
                        },
                        update_json : {
                            method: 'PUT',
                            url: this.apiPath + '/config',
                        },
                        get_yaml:{
                            method: 'GET',
                            url: this.apiPath + '/yaml',
                            transformResponse: function(data, headersGetter, status) {
                                return {yaml: data};
                            }
                        },
                        update_yaml:{
                            method: 'PUT',
                            url: this.apiPath + '/yaml'
                        },
                        start : {
                            method : 'PUT',
                            params : {clusterName: '@clusterName'},
                            url: this.apiPath + '/start'
                        },
                        stop : {
                            method : 'PUT',
                            params : {clusterName: '@clusterName'},
                            url: this.apiPath + '/stop'
                        },
                        changeaccount : {
                            method : 'PUT',
                            url: this.apiPath + '/changeaccount'
                        },

                        applications: {
                            method: 'POST',
                            url: this.apiPath + '/applications'
                        },
                        add_share: {
                            method: 'POST',
                            url: this.apiPath + '/share'
                        },
                        storage: {
                            method: "GET",
                            url: this.apiPath + '/storage',
                            isArray: true
                        },
                        delete_application: {
                            method: 'DELETE',
                            // params:{
                            //     chartNamespace: '@Namespace',
                            //     chartName: '@Name'
                            // },
                            url: this.apiPath + '/applications/:chartNamespace/:chartName'
                        },
                        restart_application: {
                            method: 'POST',
                            params:{
                                chartNamespace: '@chartNamespace',
                                chartName: '@chartName',
                                clusterName: '@clusterName'
                            },
                            url: this.apiPath + '/applications/:chartNamespace/:chartName/restart'
                        }

                    }
                )
            );
            if(!clear) {

                this.wsId = params.wsId;
                this.projectName = params.projectName;
                this.envName = params.envName;
                this.list = null;
                this._resource = resource;
            }
        }
        if(!resource) resource = this._resource;
        return resource;
    }

    getList (update) {

        if(!this.list){
            // this.list = [];
            update = true;
        }
        if(update) {
            var self = this;
            this.isLoading = true;
            this.getListPromise(update)
                .then(
                    function (r) {
                        self.list = r;
                        self.isLoading = false;
                    }
                )
        }
        return this.listPromise;
        // return this.list;
    }

    getListPromise (update) {
        if(update) {
            this.listPromise = null;
        }
        if(!this.listPromise) {
            this.listPromise = this._resource.query().$promise;
        }
        return this.listPromise;
    }

    new (data={}) {
        data.AutoDeploy = false;
        var pr = new this._resource(data);
        return this.$q.resolve(pr);
    }

    add(el) {
        if(this.list) {
            if(!_.findWhere(this.list, el)) {
                this.list.push(el);
            }
        }
    }
    remove(el) {
        // debugger;
        if(this.list) {
            if(_.findWhere(this.list, el)) {
                this.list = _.without(this.list, el);
            }
        }
    }
}
