
import {SharedClusterShareTemplate, SharedClusterShareController} from '../../shared/shared_cluster_share_component'

export class ProjectClusterShareController extends SharedClusterShareController{
    constructor(clusterResource, dialog, params) {
        super(...arguments);
    }
    // constructor (clusterResource, dialog, clusterName) {
    //     this.clusterResource = clusterResource;
    //     this.$mdDialog = dialog;
    //     this.clusterName = clusterName;
    // }
    //
    // share (form) {
    //     if(form.$valid){
    //         this.sharing = true;
    //         var self = this;
    //         this.sharingError = false;
    //         this.clusterResource.share({clusterName : this.clusterName}, this.data)
    //             .$promise
    //             .then(
    //                 function (r) {
    //                   self.$mdDialog.hide();
    //                 },
    //                 function (err) {
    //                     self.sharingError = err;
    //                 }
    //             )
    //             .finally(
    //                 function () {
    //                     self.sharing = false;
    //                 }
    //             )
    //
    //     }
    // }
}

export const ProjectClusterShareTemplate = SharedClusterShareTemplate;
        // `<md-dialog aria-label="" class="Share cluster">
        //     <form name="shareCluater" ng-submit="$ctrl.share(shareCluater)">
        //         <md-toolbar class="md-primary">
        //           <div class="md-toolbar-tools">
        //              <h2>Share cluster</h2>
        //             <span flex></span>
        //             <md-button class="md-icon-button" ng-click="$ctrl.$mdDialog.cancel()">
        //               <md-icon md-svg-src="core:close" aria-label="Close dialog"></md-icon>
        //             </md-button>
        //           </div>
        //         </md-toolbar>
        //         <md-dialog-content >
        //             <div class="flex layout-column layout-align-start md-padding" style="width: 350px;">
        //                 <md-input-container class="flex">
        //                     <label>Display name</label>
        //                     <input type="text" name="DisplayName" ng-model="$ctrl.data.DisplayName" required>
        //                 </md-input-container>
        //                 <md-input-container class="flex">
        //                     <label translate>COMMON_DESCRIPTION</label>
        //                     <textarea type="text" name="Description" ng-model="$ctrl.data.Description"></textarea>
        //                 </md-input-container>
        //
        //                 <md-input-container class="flex">
        //                     <label translate>Workspace name(url)</label>
        //                     <textarea type="text" name="Description" ng-model="$ctrl.data.WorkspaceName"></textarea>
        //                      <div class="hint" >One or more divided ","</div>
        //                 </md-input-container>
        //
        //                 <md-input-container class="flex">
        //                     <label translate>Invite email</label>
        //                     <textarea name="Description" ng-model="$ctrl.data.InviteEmail"></textarea>
        //                     <div class="hint" >One or more divided ","</div>
        //                 </md-input-container>
        //
        //                 <div class="cmp-block-compact" class="flex">
        //                     <label class="cmp-block-compact-title">Limits</label>
        //                     <div class="cmp-block-compact-content layout-row layout-align-start">
        //                         <md-input-container class="flex">
        //                             <label>CPU</label>
        //                             <input type="text" ng-model="$ctrl.data.Shared.limits.cpu">
        //                         </md-input-container>
        //                         <md-input-container class="flex">
        //                             <label>Memory</label>
        //                             <input type="text" ng-model="$ctrl.data.Shared.limits.memory">
        //                         </md-input-container>
        //                     </div>
        //                 </div>
        //             </div>
        //         </md-dialog-content>
        //
        //         <md-dialog-actions layout="row">
        //             <div class="flex">
        //                 <md-progress-linear ng-if="$ctrl.sharing" flex></md-progress-linear>
        //                 <cmp-api-error-service ng-if="$ctrl.sharingError" error="$ctrl.sharingError"></cmp-api-error-service>
        //             </div>
        //             <md-button ng-click="$ctrl.$mdDialog.cancel()">Cancel</md-button>
        //             <md-button type="submit" class="md-primary">{{"COMMON_SHARE" | translate}}</md-button>
        //         </md-dialog-actions>
        //     </form>
        // </md-dialog>`
    ;
