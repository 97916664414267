import {CoreServiceResource} from '../../core/service/core_service_resource'

export class CatalogBaseService extends CoreServiceResource {
    constructor($resource, $q, KuberlabConfigApi, $rootScope) {
        super(...arguments);
        this._params = {};
        this.apiDir = '';
    }

    getWsUrl (params, link) {
        return this.cfg.APIPath + '/workspace/' + (params.wsId  ? params.wsId  : ':wsId')  + this.apiDir;
    }

    getResourceUrl(params) {
        return params.wsId
            ? this.getWsUrl(params) + '/:name'
            : this.cfg.APIPath + '/catalog' + this.apiDir;
    }

    resourceAction () {
        return {};
    }

    getResource(params = {}, clear, prCb = () => {}) {
        if( this._params.wsId != params.wsId || !this._resource || clear ) {
            var url = this.getResourceUrl(params);
            this._resource = this.$resource(
                url,
                { name: '@Name' },
                _.extend(
                    {},
                    this.cfg.ResourceParams,
                    {   'create' : {
                            method: 'POST',
                            params: {name: '@createName'}
                        },
                        'publish': {
                            url: url + '/publish',
                            method: "PUT"
                        }
                        ,
                        'unpublish': {
                            url: url + '/unpublish',
                            method: "PUT"
                        },


                        // 'star': {
                        //     method: 'POST',
                        //     url: url + '/star'
                        // },
                        'star' : {
                            method : 'POST',
                            params : {wsId : '@wsId', name : '@Name'},
                            url : this.getWsUrl (params) + '/:name/star'
                            // url: this.cfg.APIPath + '/workspace/:wsId/charts/:name/star'
                        },
                        'unstar': {
                            method: 'POST',
                            url: url + '/unstar'

                        },
                        'versions': {
                            method: 'GET',
                            isArray: true,
                            url: url + '/versions'
                        },
                        'version_delete': {
                            method: 'DELETE',
                            url: url + '/versions/:version'
                        },
                        'comments': {
                            method: 'GET',
                            isArray: true,
                            url: url + '/comments',
                        },
                        'comments_add': {
                            method: 'POST',
                            url: url + '/comments',
                        },

                        'upload': {
                            method: 'POST',
                            params: {
                                version: "@version"
                            },
                            url: url + '/versions/:version/upload',
                            transformRequest: function (data) {
                                var fd = new FormData();
                                angular.forEach(data, function (value, key) {
                                    fd.append(key, value);
                                });
                                return fd;
                            },
                            uploadEventHandlers: {
                                progress: prCb
                            },
                            headers: {'Content-Type': undefined, enctype: 'multipart/form-data'}

                        },
                        'picture': {
                            method: 'POST',
                            url: url + '/picture',
                            transformRequest: function (data) {
                                var fd = new FormData();
                                angular.forEach(data, function (value, key) {
                                    fd.append(key, value);
                                });
                                return fd;
                            },
                            uploadEventHandlers: {
                                progress: prCb
                            },
                            headers: {'Content-Type': undefined, enctype: 'multipart/form-data'}

                        }
                    },
                    this.resourceAction(url)
                 )
            );
            this._params = {wsId : params.wsId};
        }
        return this._resource;
    }

    getVersions(data) {
        return this._resource.versions({name: data.Name}).$promise;
    }
}
