/**
 * Created by ilja on 29.11.16.
 */


class AuthLogin {
    constructor(AuthService, CurrentUser, $mdDialog){
        this.authService = AuthService;
        this.data = {email: '', password: ''};
        var self = this;
        CurrentUser
            .then(
                function (r) {
                    self.currentUser = r;
                }
            );
        // this.currentUser;
    }

    login () {
        this.authService.login(this.data)
    }
}

export const AuthLoginComponent = {
    bindings: {
        currentUser: '='
    },
    template : `
        <cmp-user-card-medium ng-if="$ctrl.currentUser">Current user</cmp-user-card-medium>
        <md-card ng-if="!$ctrl.currentUser">
            <md-card-title>Login</md-card-title>
            <md-card-content layout="row" layout-align="space-between center">
                <md-input-container flex="50">
                    <label>Email</label>
                    <input ng-model="$ctrl.data.email" type="email" name="email">
                </md-input-container>
                <md-input-container flex="50">
                    <label>Password</label>
                    <input ng-model="$ctrl.data.password" type="password">
                </md-input-container>
            </md-card-content>
            <md-card-actions>
                <md-button ng-click="$ctrl.login()" class="md-raised">login</md-button>
            </md-card-actions>
        </md-card>
        `,
    controller: AuthLogin
};
