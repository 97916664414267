/**
 * Created by ilja on 29.11.16.
 */


class LeftMenu{
    constructor($scope, DashboardService){
        var self = this;
        // this.dc;
        this.DashboardService = DashboardService;
        this.currentOwner = this.DashboardService.currentOwner; // html bindings
        $scope.$watch(
            function () {
                return self.DashboardService.currentOwner;
            },
            function (current) {
                self.currentOwner = current;
                self.initMenuItem();
            }
        );

        this.initMenuItem();
    }

    initMenuItem () {
        var id = ( this.currentOwner && this.currentOwner.Ident ) ? this.currentOwner.Ident : false;
        this.menuItem =  [
            {
                title : 'shop (catalog)',
                link: 'catalog',
                icon: 'core:check',
                byOwner : false
            },
            {
                title : 'clusters',
                link : id ? 'ownerClusters({Ident:"' + id+ '"})' : 'clusters',
                icon : 'core:check',
                byOwner : false
            },
            {
                title : 'projects',
                link: id ? 'ownerProjects({Ident:"' + id+ '"})' : 'projects',
                icon: 'core:check',
                byOwner : false
            }
        ];
        console.log("ID", id, this.menuItem);
    }
}

export const LayputLeftMenuComponent = {
    bindings: {
        currentOwner: "@"
    },
    template:`
        <md-list>
            <md-list-item ng-repeat="item in $ctrl.menuItem">
                <a ui-sref="{{item.link}}"><md-item md-svg-icom="{{item.icom}}"></md-item> {{item.title}}</a>
            </md-list-item> 
        </md-list>
    `,
    controller: LeftMenu
};