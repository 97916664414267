import {ControllerList} from '../core/controller/list'

export class ApplicationsListController extends ControllerList {
    constructor(ApplicationsService, $scope, $state, WorkspacePermissions, ApplicationService, ErrorService, UserConfigService) {
        super();
        this.permissions = WorkspacePermissions;
        this.ErrorService = ErrorService;
        this.$scope = $scope;
        this.workspace; //html bindings
        this.ApplicationsService = ApplicationsService;
        this.ApplicationService = ApplicationService;
        this.$state = $state;
        this.isWs = !!$state.params.wsId;
        this.UserConfigService = UserConfigService;
        this.filter = {
            Enable: undefined
        };

        let occ = this.UserConfigService.getWsItemConfig(this.$state.params.wsId, 'orderApp', 0, 'order') || {};
        this.orderContainersCount = ( _.isUndefined(occ.orderContainersCount) || occ.orderContainersCount ) ? true: false;
        this.orderParam = occ.orderParam
            ? occ.orderParam
            : {
                DisplayName : "DisplayName",
                ContainersCount: "-ContainersCount",
                Enabled: '-Enabled'
            };
        this.filter = occ.filter
            ? occ.filter
            : {
                Enable: undefined
            };

        // this.orderContainersCount = true;
        // this.orderParam = {
        //     DisplayName : "DisplayName",
        //     ContainersCount: "-ContainersCount",
        //     Enabled: '-Enabled'
        // };
        this.orderListPrams = [];
        this.orderWithCount();

        this.watchWS('applicationCreate', this.$onInit);
        this.watchWS('applicationDelete', this.$onInit);
        this.watchWS('application', this.ws_application);

        $scope.$watch(
            (r) => { return this.ApplicationsService.list },
            (c) => {
                if( !this.Recent) this.list = c;
            }
        );
        this.Recent =  this.UserConfigService.getWsItemConfig(this.$state.params.wsId, 'recentApp', 0, 'recent');
    }

    orderWithCount() {
        this.orderParam.ContainersCount = this.orderContainersCount ? '-ContainersCount' : '';
        this.orderListPrams = this.orderParam.ContainersCount
            ? [this.orderParam.Enabled, this.orderParam.ContainersCount, this.orderParam.DisplayName]
            : [this.orderParam.Enabled, this.orderParam.DisplayName];
        this.saveFilter();
    }

    saveFilter() {
        this.UserConfigService.setWsItemConfig(this.$state.params.wsId, 'orderApp', 0, 'order', {orderParam : this.orderParam, orderContainersCount : this.orderContainersCount, filter: this.filter});
    }

    $onInit() {
        this.ApplicationsService.getResource(this.$state.params);
        this.getList();
    }

    changeResent() {
        this.UserConfigService.setWsItemConfig(this.$state.params.wsId, 'recentApp', 0, 'recent', this.Recent);
        this.getList();
    }

    getList() {
        if(this.Recent) {
            this.ApplicationsService.getLastApp(this.$state.params.wsId)
                .then(
                    (r) => {
                        this.list = r;
                    },
                    (err) => {}
                )
        } else {
            this.ApplicationsService.getList(true).then(
                (applications) => {
                    this.listEmpty = applications && !applications.length;
                },
                (err) => {
                    this.ErrorService.error(err);

                }
            );
        }
    }

    order (param) {
        this.orderParam[param] = this.orderParam[param] == param ? '-' + param : param;
        this.orderWithCount();
    }


    limitHeigth() {
        return this.mainPage && this.list && this.list.length > 6;
    }

    ws_application(event, data) {
        if(data.WorkspaceName == this.$state.params.wsId) {
            var i, app = _.findWhere(this.list, {Name: data.Name});
            if(app) {
                i = _.indexOf(this.list, app);
                if(i != -1){
                    this.list[i] =_.extend(this.list[i],  data);
                    this.$scope.$apply();
                }
            }
        }
    }

}
