import {ControllerList} from '../core/controller/list'

export class WsaListController extends ControllerList {
    constructor (WorkspaceService, WsaService) {
        super();
        this.list = null;
        this.wsId;
        this.WsaService = WsaService;
        this.WorkspaceService = WorkspaceService;
    }

    $onInit() {
        this.resource = this.WorkspaceService.Serviceacconts(this.wsId);
        // debugger;
    }

    googleProjects (update) {
        var self = this;
        if(!this._gp) {
            this._gp = [];
            update = true;
        }

        if(update) {
            this.WsaService.googleProjects.query()
                .$promise
                .then(
                    function (r) {
                        self._gp = r;
                    },
                    function (r) {
                        self._gp = [];
                    }
                );
        }

        return this._gp;
    }

    saList (update) {
        var self = this;
        if(!this.list) {
            this.list = [];
            update = true;
        }
        if(update) {
            this.resource.query(
                {},
                function(r){
                    self.list = r;
                },
                function (r) {
                    console.error("Organizations team users request error", r);
                }
            );
        }
        return this.list;
    }

    // add (data) {
    //     debugger;
    //     super.add(data);
    // }
}
