    class controller {
    constructor($scope) {
        this.groupApis = {};
        this.$scope = $scope;
        $scope.$watch(() => {return this.options},
            (c) => {
              if(c) {
                  this.setValue();
              }
            }
        );
        $scope.$watch(() => {return this.workspace},
            (c) => {
              if(c) {
                  this.setValue();
              }
            }
        );
        this.defaultValue = {
            int: 0,
            'int8': 0,
            'int16': 0,
            'int32': 0,
            'int64': 0,
            uint: 0,
            'uint8': 0,
            'uint16' : 0,
            float: 0,
            double: 0,
            string: "",
            boolean: false,
            bool: false,
            select: '',
            storage: null,
            list: []

        };
        this.isNumber = ['int','int8','int16','int32','int64','float','double','uint8','uint16'];
        this.valueWithUrl = /^(.*)\[(.*)\]$/;
        this.arrayGallery = {};
    }

    $onInit(){
        this.api = this;
        if(!this.ngModel) this.ngModel = {};
    }

    getValueUrl(value) {
        if (!value || !_.isString(value)) return false;
        return value.match(this.valueWithUrl);
    }

    setValue(){
        this.opt = [];
        var self = this;
        _.each(self.options, (v, i) => {

            var type = v.type;

            // if(type == 'boolean') debugger;



            if( v.type != 'streams' && v.type != 'bytes' && v.type != 'image' && v.type != 'image_webrtc' && v.type != 'audio' && v.type != 'video' && _.isArray(v.value)) {
                var vU = self.getValueUrl(v.value[0]);
                if(vU && vU[2]) {
                    type='arrayGallery'
                }
                else {
                    type = 'array';
                    if( _.indexOf(self.isNumber, v.type ) != -1) {
                        v.value = _.map(v.value, (i) => { return Number(i) ; });
                    }
                    // debugger
                }
            }
            if(  type == 'bytes' || type == 'image' || type == 'image_webrtc' || type == 'audio' || type == 'video' ) {
                type = 'bytes';
            }

            if( type == 'arrayGallery' ) {
                this.arrayGallery[v.name] = _.map(v.value, (i) => { return self.getValueUrl(i); }) // vU;
            }

            if( !v.hidden ) { self.opt.push(_.extend({} , v, {TYPE : type})); }

            if(v.type != 'group' && _.isUndefined(this.ngModel[v.name])) {

                var value = (_.isArray(v.value) && type != 'streams') ? ( type == "arrayGallery" ? this.arrayGallery[v.name][0][1] : v.value[0] ) : v.value;

                if(value){
                    if( _.indexOf(self.isNumber, v.type ) != -1)
                        { this.ngModel[v.name] = Number(value) ;}
                    else if(v.type == 'boolean' || v.type == 'bool')
                        {
                            // debugger;
                            this.ngModel[v.name] = _.isBoolean(value) ? value : ( (value == 'false' || !value.length) ? false : true );
                        }
                    else if(v.type == 'byte' || v.type == 'bytes' || v.type == 'image'|| v.type == 'image_webrtc' || v.type == 'audio' || v.type == 'video')
                        {}
                    else
                        this.ngModel[v.name] = value;
                }else{
                    this.ngModel[v.name] = self.defaultValue[v.type];
                }
            }
        });
        // debugger;
        // console.log(this.ngModel, this.options, this.opt)
    }

    actionDo (event) {
        this.action({$event: event});
    }
}

export const FormConstructorComponent = {
    bindings: {
        options: "=",
        api:"=",
        ngModel: "=",
        clusterId: '<',
        workspace: '<',
        appVolumes: '<',
        multipart: '=',
        action: "&"
    },
    template: ($templateCache) => { return $templateCache.get('kuberlab/core/form-constructor/tpl.html'); },
    controller: controller
};
