export class InstallFromCluster {
    constructor(  ProjectClusterService, $state,  $scope, ChartsService, cluster, dialog, chart){

        this.dataChart = {};
        this.editorOptions = {
            mode : 'tree',
            expanded: true
        };

        this.$mdDialog = dialog;
        this.cluster = cluster;
        this.$state = $state;
        this.clusterResource = ProjectClusterService.getResource($state.params, true);
        this.ChartsService = ChartsService;
        // this.ChartsServiceResource = ChartsService.getResource($state.params, true);
        this.chart;
        var self = this;
        $scope.$watch(
            function () {
                return self.chart
            },
            function () {
                self.changeChart();
            }
        );
        $scope.$watch(
            function () {
                return self.target_applications
            },
            function () {
                self.changeApplications();
            }
        );
    }

    changeChart() {
        if(this.chart) {
            var self = this;
            this.dataChart.chart = this.chart.WorkspaceName + '/' + this.chart.Name + '/' + this.chart.Version;
            this.selectedIndex = 1;
            this.values = {};
            this.loadingValues = true;
            // this.ChartsServiceResource = this.ChartsService.getResource({wsId: this.chart.WorkspaceName}, true);
            // this.ChartsServiceResource
            this.ChartsService.getResource({wsId: this.chart.WorkspaceName}, true)
                .values({chartName: this.chart.Name, chartVersion: this.chart.Version})
                .$promise
                .then(
                    function (r) {
                        self.values = r.toJSON();
                    },
                    function (err) {
                        debugger
                    }
                )
                .finally(function () {
                    self.loadingValues = false;
                });
        }
    }

    changeApplications() {
        this.dataChart.target_applications = this.target_applications;
    }

    tabDisabled (tab) {
        switch(tab) {
            case 1:
                return !( this.chart);
                break;
            case 2:
                return !( this.dataChart.target_applications && this.dataChart.target_applications.length && !this.targetError );
        }
        return false;
    }

    next() {
        this.selectedIndex = this.selectedIndex + 1;
    }

    isOk() {
        return !(
            this.dataChart.target_applications
            && this.dataChart.target_applications.length
            && this.dataChart.chart
            && this.selectedIndex == 2
        );
    }

    installAction() {
        var self = this;
        this.dataChart.values = this.values;
        this.clusterResource.applications(
            {clusterName: this.cluster.Name},
            this.dataChart)
            .$promise
            .then(
                function (r) {
                    self.$mdDialog.hide(self.dataChart);
                    // self.$state.go({tab: 'tasks'});

                },
                function (err) {
                    self.installError = err;
                }
            )

    }

};




export const InstallFromClusterTemplate =
    `<md-dialog  class="installFromChart">
        <md-toolbar class="md-raised md-primary">
          <div class="md-toolbar-tools">
            <h2>Install</h2>
            <span flex></span>
            <md-button class="md-icon-button" ng-click="$ctrl.$mdDialog.cancel()">
              <md-icon md-svg-src="core:close" aria-label="Close dialog"></md-icon>
            </md-button>
          </div>
        </md-toolbar>
    <md-dialog-content style="max-width:800px;max-height:810px;">
        <md-tabs class="md-accent_" style="min-width: 600px;" md-selected="$ctrl.selectedIndex" md-border-bottom md-autoselect>
            <md-tab id="tab1">
                <md-tab-label>Select chart</md-tab-label>
                <md-tab-body>
                    <cmp-install-chart-find chart="$ctrl.chart" ></cmp-install-chart-find>
                </md-tab-body>
            </md-tab>
            <md-tab id="tab2" ng-disabled="$ctrl.tabDisabled(1)">
            <md-tab-label>Application</md-tab-label>
            <md-tab-body>
                <cmp-install-chart-applications applications="$ctrl.target_applications"></cmp-install-chart-applications>
            </md-tab-body>
          </md-tab>
            <md-tab id="tab3" ng-disabled="$ctrl.tabDisabled(2)">
                <md-tab-label>Valums</md-tab-label>
                <md-tab-body>
                    <md-progress-linear ng-if="$ctrl.loadingValues" class="flex"></md-progress-linear>
                    <div ng-jsoneditor="onLoad" ng-if="$ctrl.values" ng-model="$ctrl.values" options="$ctrl.editorOptions" ></div>
                </md-tab-body>
            </md-tab>                  

        </md-tabs>
    </md-dialog-content>
        <cmp-api-error-service ng-if="$ctrl.installError" error="$ctrl.installError"></cmp-api-error-service>
        <md-dialog-actions layout="row">
            <md-progress-linear ng-if="$ctrl.instaling" flex></md-progress-linear>
            <md-button ng-click="$ctrl.$mdDialog.cancel()">Cancel</md-button>
            <md-button ng-click="$ctrl.next()" ng-disabled="$ctrl.tabDisabled($ctrl.selectedIndex + 1)">Next</md-button>
            <md-button ng-click="$ctrl.installAction()" ng-disabled="$ctrl.isOk()">Install</md-button>
        </md-dialog-actions>
    </md-dialog>`
;
