export class applicationPipController {
    constructor(appController){
        this.appController = appController;
        this.formData;
        this.formOptions;
        this.selectedIndex = 0;
    }

    cancel () {
        this.appController.$mdDialog.cancel();
    };

}

