export class ApplicationsTaskController{
    constructor(ApplicationsService, $state , $mdDialog, $translate, $scope, UserService, $element) {
        this.$scope = $scope;
        this.$state = $state;
        this.$translate = $translate;
        this.$mdDialog = $mdDialog;
        this.resource = ApplicationsService.getResource( $state.params );
        this.Allocatednodes;
        this.UserService = UserService;
        this.imgList = ApplicationsService.images;
        $element.ready(() => {
            _.delay(() => { window.dispatchEvent(new Event('resize')); }, 10);
        });
    }

    $onInit() {
        this.getAllocatednodes();
        this.canWsBilling = this.UserService.wsCanBilling(this.$state.params.wsId);
        if(this.data.maxRestartCount == undefined) this.data.maxRestartCount = 0;
        if(this.data.is_permanent == undefined) this.data.is_permanent = false;
    }

    getAllocatednodes() {
        var self = this;
        this.resource.allocatednodes({application: this.application.Name})
            .$promise
            .then(
                function (r) {
                    self.Allocatednodes = r;
                    if(_.isArray(r) && r.length)
                        self.Allocatednodes = [''].concat(r);
                },
                function (err) {
                    self.$scope.$emit('api_error', err);
                }
            )
    }


    startEdit() {
        this.isEdit = true;
    }

    rename(name) {
        this.data.name = name;
        this.isEdit = false;
        this.listController.chnageResource(this.data.name);
    }

    delete() {
        this.listController.deleteResource(this.data);
    }

    copy () {
        debugger
        this.listController.newResource = angular.copy(this.data);
        // this.listController.newResourceName = 'copy_' + this.data.name;
        this.listController.newResource.name = 'copy_' + this.data.name;
        this.listController.resourceID = this.listController.task.resources.length
    }

}


export const ApplicationsTaskComponent = {
    bindings: {
        application: '=',
        data: '=',
        form: '<',
        isServe: '<',
        isUix: '<',
        isNew: '<',
        listController: '<'
    },
    controller: ApplicationsTaskController,
    template: function ($templateCache) {
        return $templateCache.get('kuberlab/applications/models/applications_task_component.html');
    },
};
