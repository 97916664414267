

import {CatalogDataListController} from './catalog_data_list_controller'
import {CatalogDataItemController} from './catalog_data_item_controller'
import {CatalogDataItemNewController} from './catalog_data_item_new_controller'

export default function ($stateProvider) {
    $stateProvider

        .state('wsCatalogData', {
            parent: 'wsBase',
            abstract: true,
            resolve: {
                catalog_dataResource : function(workspace, CatalogDataService, $state, $stateParams, pageConfig){
                    pageConfig.setTitle('catalog_data');
                    return CatalogDataService.getResource($stateParams);
                }
            }
        })

        .state('wsCatalogDataAdd', {
            parent: 'wsCatalogData',
            url : '/add_catalog_data',
            resolve: {
                currentUser : function ( CurrentUser ) {
                    return CurrentUser;
                },
                catalog_data : function( catalog_dataResource, currentUser, workspace, $rootScope){
                    $rootScope.pageTitleConfig =  [
                        {
                            title: workspace.DisplayName,
                            state: 'workspace'
                        },
                        {
                            title : "add new dataset",
                            state: 'wsCatalogDataAdd'
                        }
                    ];
                    return new catalog_dataResource({});
                }
            },
            views : {
                "@wsBase" : {
                    templateProvider: function ($templateCache) {
                        return $templateCache.get('kuberlab/catalog/base/card/card_new.html');
                    },
                    controller: CatalogDataItemNewController,
                    controllerAs: '$ctrl'
                }
            }
        })

        .state('wsCatalogDataList', {
            parent: 'wsCatalogData',
            url : '/datasets?:search:page:limit',
            params: {
                page: '1',
                limit: '30'
            },

            resolve: {

                addBtn : function ($rootScope, workspace) {
                    $rootScope.pageTitleConfig =  [
                        {
                            title: workspace.DisplayName,
                            state: 'workspace'
                        },
                        {
                            title : "catalog_data",
                            state: 'wsCatalogDataList'
                        }
                    ];

                    return $rootScope.addButtonConfig = [
                        {
                            titile :'add new dataset',
                            icon: "core:catalog_data",
                            state: {
                                name: 'wsCatalogDataAdd'
                            }
                        }
                    ];
                }
            },
            views: {
                "@wsBase" : {
                    templateProvider: function ($templateCache) {
                        return $templateCache.get('kuberlab/catalog/base/card/card_list.html');
                    },
                    controller: CatalogDataListController,
                    controllerAs: '$ctrl'
                }
            }
        })

        .state('wsCatalogDataItem_base', {
            parent: 'wsCatalogData',
            url : '/datasets/:item/:tab',
            params: {
                tab: 'readme'
            },
            abstract: true,
            resolve: {
                pageTabs: function (catalogDataPageTabs) {
                    return catalogDataPageTabs;
                },
                catalog_data : function (catalog_dataResource, $stateParams ) {
                    return catalog_dataResource.get({
                            name : $stateParams.item
                    })
                        .$promise;
                    },
                addBtn : function (workspace, $rootScope, catalog_data) {
                        $rootScope.pageTitleConfig =  [
                            {
                                title: workspace.DisplayName,
                                state: 'workspace'
                            },
                            {
                                title : "catalog_data",
                                state: 'wsCatalogDataList'
                            },
                            {
                                title : catalog_data.DisplayName,
                                state: 'wsCatalogDataItem'
                            }
                        ];

                        return $rootScope.addButtonConfig = null;

                    }
                },
            views: {
                        "@wsBase" : {
                        templateProvider: function ($templateCache){
                        return $templateCache.get('kuberlab/catalog/base/card/card_item.html');
                    },
                    controller: CatalogDataItemController,
                    controllerAs: '$ctrl'
                }
            }
        })

        .state('wsCatalogDataItem', {
            parent: 'wsCatalogDataItem_base',
            url:'/:tabItem',
            resolve: {
                currentTab : function (pageTabs, $stateParams, $q, pageConfig, catalog_data) {
                    var tab = _.findWhere(pageTabs, {title: $stateParams.tab});

                    if(tab){
                        return $q.resolve(tab);
                    }
                    else
                        return $q.reject();
                }
            },
            views: {
                tabContent : {
                    templateProvider: function (currentTab) {
                        return currentTab.content;
                    },
                    // controller: function (catalog_data, catalog_dataResource, $state, pageConfig, $stateParams) {
                    //     // debugger;
                    //     pageConfig.setTitle(catalog_data.Name + ' ' + $stateParams.tab);
                    //     this.catalog_data = catalog_data;
                    //     this.catalog_dataResource = catalog_dataResource;
                    //     this.$state = $state;
                    // },
                    controller: function (catalog_data, catalog_dataResource, $state, pageConfig, $stateParams, permissions, CatalogDataService) {
                        debugger;
                        pageConfig.setTitle(catalog_data.Name + ' ' + $stateParams.tab);
                        this.data = catalog_data;
                        this.$state = $state;
                        this.readmeUrl = '/workspace/' + this.$state.params.wsId + '/datasets/' + this.$state.params.item + '/readme';
                        // this.readmeUrl = CatalogDataService.getWsUrl($state.params) + "/" + this.$state.params.item + '/readme';
                        this.resource = catalog_dataResource;
                        this.permissions = permissions;
                        this.canManage = permissions.can('mlapp.manage');
                        this.service = CatalogDataService;
                    },
                    controllerAs: '$ctrl'
                }
            }
        })



}
