import {ChartsListController} from '../charts_list_controller'
import {ChartsItemController} from '../charts_item_controller'
import {InstallChart, InstallChartTemplate} from '../install/install_chart'

class controller extends ChartsListController {
    constructor (ChartsService, $scope, $state, $localStorage, $rootScope, $element, $mdDialog, UserService, WorkspacePermissions, chartTypes) {
        super(...arguments);
        this.cItem = ChartsItemController;
        this.$mdDialog = $mdDialog;
        this.rSerach = 'kuberlabapptemplate';
        this.fSearch;
        this.rec = false;
        // this.search = 'kuberlabapptemplate';
    }

    $onInit () {
        super.$onInit();
        this.resource = this.ChartsService.getResource({Interface: 'chart-mlapp-v2', wsId: this.wsId});
        this.withGlobal = true;
        this.changeRec();
    }

    changeRec() {
        this.search = this.rec ? this.rSerach : this.fSearch;
        this.getList()
    }

    Install(ev, item) {
        var then = this;
        this.installError = null;
        this.$mdDialog.show({
            locals: {
                dialog: then.$mdDialog,
                chart : item,
                clusters: null,
                workspace_name: this.wsId,
                ws: null
                // ws: this.wsId ? {workspace_name: this.wsId} : null
            },
            controller: InstallChart,
            controllerAs: '$ctrl',
            template: InstallChartTemplate,
            parent: angular.element(document.querySelector('#mainBody')), // angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: false,
            escapeToClose: false
        }).then(
            function(data){ },
            function () {}
        )
            .finally(()=>{
                this.resource = this.ChartsService.getResource({Interface: 'chart-mlapp-v2',  wsId: this.wsId});
            });

    }

}


export const ChartsRecommendedComponent = {
    bindings: {
        wsId: "<"
    },
    controller : controller,
    template: `
        <div flex layout="row" layout-align="start center">
            <h4 flex translate>APPLICATION_CREATE_TAB_RECOMMENDED_TITLE</h4>
            <cmp-pagenate layout="row" layout-align="start center" page="$ctrl.page" list="$ctrl.list" limit="$ctrl.limit" ></cmp-pagenate>
            <form ng-if="!$ctrl.rec" class="search" ng-submit="$ctrl.changeRec()" name="serach">
                <input type="text" ng-model="$ctrl.fSearch" ng-model-options='{ debounce: 1000 }' ng-change="$ctrl.changeRec()">
                <md-button type="submit" class="md-icon-button"><md-icon md-svg-icon="core:search"></md-icon></md-button>
            </form>
            <div style="width: 10px"></div>
            <md-input-container style="margin: 0;">
                <md-checkbox ng-model="$ctrl.rec" ng-change="$ctrl.changeRec()" style="margin:0"> Recommended</md-checkbox>
            </md-input-container>
        </div>
        <div ng-if="$ctrl.isLoading"><md-progress-linear></md-progress-linear></div>
        <cmp-error-toast ng-if="$ctrl.ErrorList" error="$ctrl.ErrorList"></cmp-error-toast>
        <div 
            class="chartList"  
            ng-class="$ctrl.grid ? 'col_3_view' : 'line_view'">
            <div 
                class="col_row_item chartList_Item"
                ng-repeat="item in $ctrl.list"  
                ng-include=" 'kuberlab/charts/card/card_chart_small_install.html' "
                ></div>
        </div>
        <div flex layout="row" layout-align="start">
            <div flex></div>
            <cmp-pagenate page="$ctrl.page" list="$ctrl.list" limit="$ctrl.limit" ></cmp-pagenate>
        </div>
    `
};
