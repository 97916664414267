class controller {
    constructor () {
        this.isEdit = false;
    }


    edit(){
        this.isEdit = true;
        this._text = this.data.Text;
    }

    cancel () {
        this.data.Text = this._text;
        this.isEdit = false;
        this.error = null;
    }

    update() {
        this.error = null;
        this.listController.resource.comments_update({name: this.listController.data.Name, cID: this.data.ID}, this.data)
            .$promise
            .then(
                (r) => {
                    this.data = r;
                    this.isEdit = false;
                    },
                (err) => {
                    this.error = err;
                }
            )

    }

    delete() {
        this.listController.deleteComment(this.data);
    }

}

export const CatalogCommentItem = {
    bindings: {
        resource: '<',
        data: '<',
        listController: '<'

    },
    controller: controller,
    template: `
        <md-list-item class="md-2-line" ng-if="!$ctrl.isEdit">
            <img ng-src="{{$ctrl.data.User.Picture}}" class="md-avatar" alt="{{$ctrl.data.User.DisplayName}}" />
            <div class="md-list-item-text flex layout-column">
                <h3><a ui-sref="workspace({wsId: $ctrl.data.User.Workspace})">{{$ctrl.data.User.DisplayName}}</a></h3>
                <p>{{ $ctrl.data.Text }}</p>
            </div>
            <md-menu class="md-secondary" ng-if="$ctrl.data.User.ID == $ctrl.listController.cu.ID || $ctrl.listController.cu.Admin">
              <md-button class="md-icon-button">
                <md-icon md-svg-icon="core:short-menu"></md-icon>
              </md-button>
              <md-menu-content width="4">
                <md-menu-item>
                  <md-button ng-click="$ctrl.edit()"><md-icon md-svg-icen="core:edit"></md-icon>Edit</md-button>
                </md-menu-item>
                <md-menu-item>
                  <md-button ng-click="$ctrl.delete()"><md-icon md-svg-icen="core:delete"></md-icon>Delete</md-button>
                </md-menu-item>
              </md-menu-content>
            </md-menu>
            <md-divider ></md-divider>
        </md-list-item>
        <md-list-item class="md-2-line" ng-if="$ctrl.isEdit">
            <img ng-src="{{$ctrl.listController.cu.Picture}}" class="md-avatar" alt="{{$ctrl.listController.cu.DisplayName}}" />
            <form class="md-list-item-text flex layout-column " ng-submit="$ctrl.update()" flex style="padding-top: 1em">
                <h3><a ui-sref="workspace({wsId: $ctrl.listController.cu.Workspace})">{{$ctrl.listController.cu.DisplayName}}</a></h3>
                <p>
                <textarea name="text" ng-model="$ctrl.data.Text" flex style="min-height: 3em; width: 100%;"></textarea>
                </p>
                <cmp-error-toast ng-if="$ctrl.error" error="$ctrl.error" ></cmp-error-toast>
                <div flex layout="row" layout-align="end center">
                    <md-button ng-click="$ctrl.cancel()">cancel</md-button>
                    <md-button type="submit" ng-disabled="!$ctrl.data.Text">save</md-button>
                </div>
            </form>
        </md-list-item>
    `
}