
import {SharedClusterService} from './shared_cluster_service'
import Config from './shared_cluster_stateconfig'
import {SharedClusterItemComponent} from './shared_cluster_item_controller'
import {SharedClusterItemLinkComponent} from './cluster/shared_cluster_item_link_component'
import {SharedClusterItemStorageComponent} from './cluster/shared_cluster_item_storage_component'
import {SharedClusterListComponent} from './shared_cluster_list_controller'

export default angular
    .module('shared_module', [])
    .config(Config)
    .service('SharedClusterService', SharedClusterService)
    .component('cmpSharedClusterItem', SharedClusterItemComponent)
    .component('cmpSharedClusterItemLink', SharedClusterItemLinkComponent)
    .component('cmpSharedClusterItemStorage', SharedClusterItemStorageComponent)
    .component('cmpSharedClusterList', SharedClusterListComponent)
