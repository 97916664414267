export class ServingTemplateImageController {

    constructor($scope, ErrorService) {
        this.$scope = $scope;
        $scope.$watch(
            () => this.config,
            () => {
                // debugger
                this.response = null;
                this.canUploda = true;
                this.savingError = null;
            },
            true
        );
        this.default_encode = 'png';
        this.ErrorService = ErrorService;
        this.hasWebrtc = false;
        this.workWebrtc = false;
        this.webrtcStatus = null;

    }

    $onInit() {
        this.config = this.parent.config;
        this.canUploda = this.parent.canUploda;

        _.each(this.params, (item) => {
            if(item.type == 'byte' || item.type == 'bytes' || item.type == 'image' || item.type == 'image_webrtc')
                this.paramImageName = item.name;
        });
        // this.hasWebrtc = _.findWhere(this.params, {type: 'webrtc'});
        this.hasWebrtc = _.findWhere(this.params, {type: 'image_webrtc'});
        this.linkStream = `${this.parent.service.cfg.APIPath}/workspace/${this.parent.data.WorkspaceName}/serving/${this.parent.data.Name}`;
    }

    getParams() {
        return this.parent.getData(this.config, this.params, _.bind(this.sendComplite, this))
    }

    webrtcEvent(event) {
        switch(event.type){
            case 'error':
                this.ErrorService.errorDialog(event.data);
                break;
            case 'status':
                this.webrtcStatus = event.data;
                if(!this.webrtcStatus) {
                    this.workWebrtc = false;
                }
                // this.$scope.$apply(() => {
                //     this.$scope.doStuff();
                // });
                break
        }
    }


    send () {
        this.sending = true;
        this.response = {};
        this.error = null;
        this.savingError = null;
        this.canUploda = false;
        this.parent.send(this.config, this.params, _.bind(this.sendComplite, this))
    }

    sendComplite (r, err) {

        this.sending = null;
        if(r) {
            this.canUploda = null;
            var imgName = this.parent.rForma.image && this.parent.rForma.image.length
                ?
                this.parent.rForma.image[0]
                :
                this.parent.rForma.bytes && this.parent.rForma.bytes.length
                    ?
                    this.parent.rForma.bytes[0]
                    :
                    null;

            if(imgName) this.response.image = r[imgName];

            var data = r.table_output && r.table_output.length ? r.table_output : null;
            if(data){ //_.isString( data )){
                this.response.imageBufData = data; //JSON.parse(data);
                this.response.dsFilterList = _.uniq(_.map(this.response.imageBufData, (key) => { return key.type; }));
                this.response.dsFilter = this.response.dsFilterList[0];
                this.dsHide = true;
            }
            var meta = r.table_meta && r.table_meta.length ? r.table_meta : null;
            if(_.isString( meta )){
                this.response.metaData = JSON.parse(meta);
            }

            if(r.caption_output) {
                if(_.isArray(r.caption_output)) this.response.captionBuf = r.caption_output;
                else if (_.isString(r.caption_output) ) this.response.captionBuf = [r.caption_output];
            }
        }

        if(err) {
            this.response = null;
            this.canUploda = true;
            this.imageBuf = false;
            this.dataBuf = null;
            this.savingError = err;
            this.captionBuf = null;
        }
    }

    startWebrtc(event) {
        switch (event.type) {
            case 'start_stream' :
                this.workWebrtc = true;
                break;
        }
    }
}

