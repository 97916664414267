class controller{

    constructor ($scope) {
        this.defaultValue = 'create_new_element';
        this.$scope = $scope;
    }

    $onInit() {
        this.$scope.$watch(
            () => {
                return this.ngModel;
            },
            (c) => {
                this.initData();
            }
        );
        if(!this.options) this.options = [];
        this.initData();
    }

    initData() {
        if(this.data == this.ngModel) return false;

        this.data = this.ngModel;
        if(this.data){
            if(this.options.indexOf(this.data) == -1) {
                this.options.push(this.data);
            }
        }
        if(!this.data && this.options && this.options.length && this.ngRequired) {
            this.data = this.options[0];
            // this.oData = this.data;
            this.setModel();
        }
        if(!this.ngRequired && this.options.indexOf('') == -1){
            this.options.unshift('');
        }
    }

    setModel() {
        if(this.data == this.defaultValue){
            this.createNew = true;
            this.nwElement = '';
        }else{
            this.ngModel = this.data;
            this.oData = this.data;
        }
    }

    cancel() {
        this.data = this.oData;
        this.createNew = false;
    }

    add() {
        this.options.push(this.newElement);
        this.data = this.newElement;
        this.setModel();
        this.createNew = false;
    }

}

export const DynamicSelect = {
    bindings: {
        options: '=',
        ngModel: '=',
        dynamic: '<',
        ngRequired: '<'
    },
    controller: controller,
    template: `
        <md-input-container flex ng-if="!$ctrl.createNew" > 
            <md-select ng-if="$ctrl.options"  ng-model="$ctrl.data" ng-change="$ctrl.setModel()" flex ng-required="$ctrl.ngRequired">
                <md-option ng-repeat="item in $ctrl.options" ng-value="item">{{item}}</md-option>
                <md-option ng-if="$ctrl.dynamic" ng-value="$ctrl.defaultValue">other value</md-option>
            </md-select>
            <input ng-if="!$ctrl.options" type="text" ng-model="$ctrl.data" ng-change="$ctrl.setModel()">
        </md-input-container>
        <div ng-if="$ctrl.createNew" layout="row" layout-align="start center" flex>
            <md-input-container flex>
                <input type="text" ng-model="$ctrl.newElement">
            </md-input-container>
            <md-button ng-click="$ctrl.cancel()">{{'COMMON_CANCEL' | translate}}</md-button>
            <md-button ng-click="$ctrl.add()">{{'COMMON_ADD' | translate}}</md-button>
        </div>
    `
}