export default {
    "metrics": [
        {
            "component_name": "jupyter",
            "component_type": "ui",
            "metrics": [
                {
                    "name": "cpu_usage",
                    "values": [
                        {
                            "ts": 1506960072,
                            "value": 0
                        },
                        {
                            "ts": 1506961872,
                            "value": 0
                        }
                    ],
                    "labels": {
                        "app": "kube-state-metrics",
                        "component": "jupyter",
                        "instance": "10.244.0.95:8080",
                        "job": "kubernetes-endpoints",
                        "kubernetes_name": "kube-state-metrics",
                        "kubernetes_namespace": "kuberlab",
                        "label_component": "jupyter",
                        "label_kuberlab_io_component_type": "ui",
                        "label_kuberlab_io_workspace": "kuberlab-demo",
                        "label_kuberlab_io_workspace_id": "21",
                        "label_pod_template_hash": "134874745",
                        "label_test": "test",
                        "label_workspace": "styles-set",
                        "namespace": "21-styles-set",
                        "pod": "jupyter-134874745-qbhh4",
                        "pod_name": "jupyter-134874745-qbhh4"
                    }
                }
            ]
        },
        {
            "component_name": "tensorboard",
            "component_type": "ui",
            "metrics": [
                {
                    "name": "cpu_usage",
                    "values": [
                        {
                            "ts": 1506960072,
                            "value": 0.0014935702359596857
                        },
                        {
                            "ts": 1506961872,
                            "value": 0.0010920374333333149
                        }
                    ],
                    "labels": {
                        "app": "kube-state-metrics",
                        "component": "tensorboard",
                        "instance": "10.244.0.95:8080",
                        "job": "kubernetes-endpoints",
                        "kubernetes_name": "kube-state-metrics",
                        "kubernetes_namespace": "kuberlab",
                        "label_component": "tensorboard",
                        "label_kuberlab_io_component_type": "ui",
                        "label_kuberlab_io_workspace": "kuberlab-demo",
                        "label_kuberlab_io_workspace_id": "21",
                        "label_pod_template_hash": "324143957",
                        "label_test": "test",
                        "label_workspace": "styles-set",
                        "namespace": "21-styles-set",
                        "pod": "tensorboard-324143957-rxljx",
                        "pod_name": "tensorboard-324143957-rxljx"
                    }
                }
            ]
        },
        {
            "component_name": "serving-train-4",
            "component_type": "serving",
            "metrics": [
                {
                    "name": "cpu_usage",
                    "values": [
                        {
                            "ts": 1506960072,
                            "value": 0.017801895025527655
                        },
                        {
                            "ts": 1506961872,
                            "value": 0.01463587706666689
                        }
                    ],
                    "labels": {
                        "app": "kube-state-metrics",
                        "component": "serving-train-4",
                        "instance": "10.244.0.95:8080",
                        "job": "kubernetes-endpoints",
                        "kubernetes_name": "kube-state-metrics",
                        "kubernetes_namespace": "kuberlab",
                        "label_component": "serving-train-4",
                        "label_kuberlab_io_component_type": "serving",
                        "label_kuberlab_io_serving_id": "serving-train-4",
                        "label_kuberlab_io_workspace": "kuberlab-demo",
                        "label_kuberlab_io_workspace_id": "21",
                        "label_pod_template_hash": "910752739",
                        "label_test": "test",
                        "label_workspace": "styles-set",
                        "namespace": "21-styles-set",
                        "pod": "serving-train-4-910752739-jv2mz",
                        "pod_name": "serving-train-4-910752739-jv2mz"
                    }
                }
            ]
        }
    ],
    "start": "2017-10-02T16:01:12",
    "end": "2017-10-02T16:31:12"
}
