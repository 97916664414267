export class ServingParametersTableComponent {
    constructor($mdPanel, ErrorService, $mdDialog) {
        this.defTableMeta = [
            {
                "name": "type",
                "filtered": true
            },
            {
                "name": "name"
            },
            {
                "name": "prob",
                "type": "number",
                "format": ".2f"
            },
            {
                "name": "image",
                "type": "image"
            }
        ];

        this._mdPanel = $mdPanel;
        this.$mdDialog = $mdDialog;
        this.ErrorService = ErrorService;
    }

    $onInit() {
        if(!this.meta) this.meta = this.defTableMeta;
        this.filtered = {};
        this.filter = {};
        _.each(this.meta, (m) => {
            if(m.filtered) this.filtered[m.name] = {};
        });

        _.each(this.filtered, (f, k) => {
            f.list = _.uniq(_.map(this.data, (key) => { return key[k]; }));
            this.filter[k] = f.list[0];
        })

    }

    prepareData(data, item, edit) {
        var params = [
            {
                name: 'action',
                type: 'string'
            }
            ];
        var config = {
            action: edit.action
        };

        _.each(edit.fields, (i) => {
            var m = _.findWhere(this.meta, {name: i});

            params.push(
                {
                    name: i,
                    type: m.type || 'string'
                }
            );

            config[i] = data[i] || item[i] ;
        });

        return {params: params, config: config}

    }

    sendClarify(data, item, edit) {
        var pData = this.prepareData(...arguments);
        this.send(pData.config, pData.params, (r, error) => {
            if(error) {
                this.ErrorService.errorDialog(error);
            } else {
                item[edit.name] = null;
            }
        })

    }

    send(c, p, cb) {
        // debugger;
        // return
        this.parent.send( ...arguments);
    }



    showClarifyDialog (data, item, edit) {
        var pData = this.prepareData(...arguments);
        this.$mdDialog.show({
            locals: {
                data: pData,
                ctrl: this,
                outMimeType: this.parent.data.Config.spec.outMimeType
            },
            controller: function PanelDialogCtrl( data, ctrl, outMimeType, $q, $mdDialog) {
                this.data = data;
                this.cfg = angular.copy(this.data.config);
                this.outMimeType = outMimeType;
                this.parent = ctrl;
                this.$mdDialog = $mdDialog;
                var self = this;
                this.selectedItem = null;
                this.searchText = null;

                this.closeDialog = function() {
                    $mdDialog.cancel() ; //hide();

                };

                this.querySearch = (query) => {
                    var results = query ? self.classes.filter(createFilterFor(query)) : () => { return self.classes };
                    if(!self.classes) {
                        var defer = $q.defer();
                        var pr = this.parent.parent.send(
                            {action: 'meta'},
                            [{ name: 'action', type: 'string' }],
                            (r,err) => {
                                if(r) {
                                    self.classes = JSON.parse(r.meta).map(function (state) {
                                        return {
                                            value: state.name.toLowerCase(),
                                            name: state.name,
                                            company: state.company,
                                            position: state.position
                                        };
                                    });
                                    defer.resolve(self.classes);
                                }
                            }
                        );
                        return defer.promise;
                    }
                    else {
                        return results;
                    }

                };

                function createFilterFor(query) {
                    var lowercaseQuery = query.toLowerCase();

                    return function filterFn(state) {
                        return (state.value.indexOf(lowercaseQuery) === 0);
                    };
                }

                this.searchTextChange = (text, name) => {
                    this.cfg[name] = text;
                };

                this.selectedItemChange = (item) => {
                    this.cfg = _.extend(this.data.config, _.pick(item, 'name', 'company', 'position'));
                };


                this.querySearch();


                this.save = function () {
                    this.error = null;
                    for (var propName in this.cfg) {
                        if (this.cfg[propName] === null || this.cfg[propName] === undefined) {
                            delete this.cfg[propName];
                        }
                    }
                    this.parent.send( this.cfg, this.data.params, (r, error) => {
                        if(error) {
                            self.error = error;
                        } else {
                            $mdDialog.hide();
                        }
                    })

                }
            },

            controllerAs: '$ctrl',
            template: `
                <style>
                    #servingParamsDialog img {
                     max-height: 100%; max-width: 100%; width: auto;  height: auto;
                    }
                </style>
                
                <md-content class="" style=" min-height: 450px; min-width: 400px; width: 100%" layout="column" layout-align="center center" >
                    <form ng-submit="$ctrl.save()" layout="column" layout-align="center center"  style="width: 100%">
                        <h4>Clarify</h4>
                         <!--<pre>{{$ctrl.data | jsonStringify}}</pre>-->
                        <div ng-repeat="p in $ctrl.data.params " ng-switch="p.type">
                            <img  src="data:image/jpeg;base64,{{$ctrl.cfg[p.name]}}" ng-switch-when="image" style="max-height: 200px;">
                            <div ng-switch-default >
                             
                                <md-input-container ng-if="p.name != 'action' && p.name != 'name'">
                                    <label>{{p.name}}</label>
                                    <input  ng-model="$ctrl.cfg[p.name]">
                                </md-input-container>   
                                <md-autocomplete
                                    ng-if="p.name != 'action' && p.name == 'name' "
                                    md-selected-item="$ctrl.selectedItem"
                                    md-search-text-change="$ctrl.searchTextChange($ctrl.searchText, p.name)"
                                    md-search-text="$ctrl.searchText"
                                    md-selected-item-change="$ctrl.selectedItemChange(item, p.name)"
                                    md-items="item in $ctrl.querySearch($ctrl.searchText)"
                                    md-item-text="item.name"
                                    md-min-length="0"
                                    placeholder="{{p.name}}">
                                    <md-item-template>
                                      <span md-highlight-text="$ctrl.searchText" md-highlight-flags="^i">{{item.name}}</span>
                                    </md-item-template>
                                    <!--<md-not-found>-->
                                        <!--No states matching "{{$ctrl.searchText}}" were found.-->
                                    <!--</md-not-found>-->
                                 </md-autocomplete>
                                 
                            </div>
                            
                        </div>
                        <cmp-error-toast ng-if="$ctrl.error" error="$ctrl.error"></cmp-error-toast>
                        <div>
                            <md-button ng-click="$ctrl.closeDialog()">close</md-button>
                            <md-button type="submit">send</md-button>
                        </div>
                        
                       
                        
                    </form>
                </md-content>
            `,
            parent: angular.element(document.body),
            // clickOutsideToClose:true
        })
            .then (
                () => {
                    item[edit.name] = null;
                }
                )
        ;
    };

}
