/**
 * Created by ilja on 06.12.16.
 */

import {ws_listener} from './ws_listener'

export class ControllerItem extends ws_listener {
    constructor ($mdDialog, $translate, Message) {
        super();
        this.Message = Message;
        this.$mdDialog = $mdDialog;
        this.$translate = $translate;

        var inject = angular.element(document.body).injector();
        this.$mdDialog = inject.get('$mdDialog');
        this.$translate = inject.get('$translate');
        this.$state = inject.get('$state');
        this.Utils = inject.get('Utils');
        // this.$rootScope = inject.get('$rootScope');


        this.data;
        this.listController;
        this.ErrorService = angular.element(document.body).injector().get('ErrorService');


    }

    $onInit () {
        if(this.isNew()){
            this.isNewElement = true;
            this.actionForm();
        } else {
            this.actionView();
        }
    }

    actionForm() {
        this.asForm = true;
        this.sendErrorMessage = null;
        this.oldData = angular.copy(this.data);
        this.prepareEditData();
    }

    actionView() { this.asForm = false; }

    /**
     *  prepare data for create / edit model
     */
    prepareEditData(){}

  /**
   *
   * @param ev {object} Brower event
   * @param [text=COMMON_SURE_DELETE] {string} Message in Confirm Dialog, Language constant to be translated
   * @param [title=COMMON_SURE] {string} Title in Confirm Dialog, Language constant to be translated
   * @param [success=this.delete] {function} function to be executed once user press confirm in dialog
   * @param successData
   */


    deleteConfirm (ev, text, title, success, successData){
      success = success || this.delete;
      this.confirmMessage(ev, text, title, 'COMMON_DELETE', 'COMMON_CANCEL', success, successData);
    }

    // async deleteConfirm (ev, text, title, success, successData) {
    //     title = await (title || "COMMON_SURE");
    //     text = await (text || "COMMON_SURE_DELETE");
    //     success = success || this.delete;
    //     var self = this;
    //     var tr = {};
    //     this.$translate([title, text, "COMMON_DELETE", "COMMON_CANCEL"]).then(function(translations){
    //         tr = translations;
    //     }).finally(function() {
    //         var confirm = self.$mdDialog.confirm()
    //             .title(tr[title])
    //             // .textContent(tr[text])
    //             .htmlContent(tr[text])
    //             .ariaLabel(tr[title])
    //             .targetEvent(ev)
    //             .ok(tr["COMMON_DELETE"])
    //             .cancel(tr["COMMON_CANCEL"]);
    //
    //         self.$mdDialog.show(confirm).then(
    //             function () {
    //                 success.call(self, successData);
    //             }
    //             , function () {
    //                 // debugger;
    //                 // $scope.status = 'You decided to keep your debt.';
    //             }
    //         );
    //
    //     });
    // }

     async confirmMessage (ev, text, title, ok, cancel,  success = () => {}, successData) {
            title = await (title || "COMMON_SURE");
            text = await (text || "COMMON_SURE_DELETE");
            ok = await (ok || "COMMON_OK");
            cancel = await (cancel || "COMMON_CANCEL");

            var self = this;
            var tr = {};
            this.$translate([title, text, ok, cancel]).then(function(translations){
                tr = translations;
            }).finally(function() {
                var confirm = self.$mdDialog.confirm()
                    .title(tr[title])
                    .htmlContent(tr[text])
                    .ariaLabel(tr[title])
                    .targetEvent(ev)
                    .ok(tr[ok])
                    .cancel(tr[cancel]);

                self.$mdDialog.show(confirm).then(
                    function () {
                        success.call(self, successData);
                    }
                    , function () {
                    }
                );

            });
     }

    delete(data) {
        var self = this;
        // this.listController.delete(this.data)
        this.data.$delete(data)
            .then(
                function (r) {
                    self.successDelete(r);
                },
                function (err) {
                    self.errorDelete(err);
                }
            )
            ;
    }

    successDelete(r) {
        if(r){
            this.listController.deleteItem(this.data);
        }else{
            this.errorDelete(r);
        }
    }

    errorDelete(r) {
        this.ErrorService.error(r, _.bind(this.delete, this) );
    }

    Can(perm) {
        if(this.data.Can && this.data.Can[perm]) return true;
        if(this.listController) return this.listController.Can(perm);
        return false;
    }

    prepareSaveData() {};

    save(form) {
         if(this.formValidate(form)){
             this.prepareSaveData();
             var self = this;
             this.saving = true;
             this.sendErrorMessage = false;
             var action = this.isNewElement ? '$create' : '$update' ;
             return this.data[action]()
                 .then(
                     _.bind(this.saveSuccess, self),
                     _.bind(this.saveError, self)
                 )
                 .finally(function () {
                     self.saving = false;
                 })
         }
    }

    formValidate(form){
        return form.$valid;
    }

     saveSuccess(r) {
         this.isNewElement = false;
         this.actionView();
         this.listController.changeList(this.data);
     }

     saveError(r) {
         this.sendErrorMessage = r;
         // this.Message.error(r.data.Error);
         console.error("saveError", this.sendErrorMessage);
     }

     cancel (){
         var self = this;
         if(this.isNewElement){
             if(this.listController) {
                 this.listController.removeNew(this.data);
             } else {
                 console.error('Not link to listController')
             }
         } else {
             this.data = this.oldData;
             this.actionView();
         }
     }

     isNew() {
         return (this.data && this.data.ID) ? false : true;
     }

     // watchWS(event, cb = () => {}) {
     //     if(!_.isArray(this._wsListener))this._wsListener = [];
     //     this._wsListener.push(this.$rootScope.$on('ws:' + event, (event, data) => {
     //         cb.call(this, event, data);
     //     }));
     // }
     //
     // $onDestroy() {
     //     if(this._wsListener){
     //         _.each(this._wsListener, (v) => {
     //             v();
     //         })
     //     }
     // }

}
