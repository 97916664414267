import {Layout} from './layout_controller'
import {LayoutService} from './layout_service'
// import {ctrl} from  './content-menu/layout-content-menu-controller'

import {LayoutPage} from './page/layout_page'
import {LayoutMainMenu} from './layout_main_menu'
import {LayoutPageTitle} from './page/title/layout_page_title'
import {LayoutPageContent} from './page/content/layout_page_content'
import {LayoutPageContentList} from './page/content/layout_page_content_list'
import {LayoutPageContentItem} from './page/content/layout_page_content_item'
import {LayoutPageLoader} from './page/layout_page_loader'
import {LayoutPageTitleUrl} from './page/title/layout_page_title_url'
import {LayoutBack} from './layout_back'
import {LayoutButtonSubmenu} from './layout_button_submenu'
import {LayoutWs} from './layout_ws'

import {LayoutPageContentTitle} from './page/content/layout_page_content_title'


export default angular.module('kuberlab.layout', [])
    // .controller('LayoutController', LayoutController)
    .service('LayoutService', LayoutService)
    .directive('layoutController', Layout)
    .directive('layoutBack', LayoutBack)
    .directive('layoutButtonSubmenu', LayoutButtonSubmenu)
    .directive('laypoutPage', LayoutPage)
    .directive('layoutMainMenu', LayoutMainMenu)
    .directive('layoutPageTitle', LayoutPageTitle)
    .directive('layoutPageContent', LayoutPageContent)
    .directive('layoutPageLoader', LayoutPageLoader)
    .directive('layoutPageTitleUrl', LayoutPageTitleUrl)
    .directive('layoutPageContentList', LayoutPageContentList)
    .directive('layoutPageContentItem', LayoutPageContentItem)
    .directive('layoutWs', LayoutWs)

    // .directive('layoutPageContentTitle', LayoutPageContentTitle)

    // .directive('layoutConteontMenu', ctrl)
    // .component('cmpLayoutConteontMenu', LayoutConteontMenuComponent)
