import {CoreServiceResource} from '../../core/service/core_service_resource'

export class ProjectApplicationService  extends CoreServiceResource{
    constructor ($resource, $q, KuberlabConfigApi, $rootScope){
        super(...arguments);
    }

    getResource(params) {
        if(!params.wsId || !params.projectName || !params.envName) {
            throw "error params";
        }
        if(this.wsId != params.wsId || this.projectName != params.projectName || this.envName != params.envName){
            var apiPath = this.cfg.APIPath + '/workspace/' + params.wsId + '/projects/' + params.projectName + '/env/' + params.envName + '/applications';
            this._resource = this.$resource(
                apiPath,
                {},
                _.extend(
                    {},
                    this.cfg.ResourceParams,
                    {
                        upload: {
                            method: 'POST',
                            transformRequest: function (data) {
                                var fd = new FormData();
                                angular.forEach(data, function(value, key) {
                                    fd.append(key, value);
                                });
                                return fd;
                            },
                            headers: {'Content-Type':undefined, enctype:'multipart/form-data'}
                        },
                        // create : {
                        //     method: 'POST',
                        //     params : {clusterName: null}
                        // },
                        get_yaml : {
                            method : 'GET',
                            url: apiPath + '/yaml',
                            transformResponse: function(data, headersGetter, status) {
                                if(status == 200)
                                    return {yaml: data};
                                else
                                    return angular.fromJson(data);
                            }
                        },
                        deploy : {
                            method : 'POST',
                            url: apiPath + '/deploy'
                        }
                    }
                )
            );
            this.wsId = params.wsId;
            this.projectName = params.projectName;
            this.envName = params.envName;
            this.list = null;
        }
        return this._resource;
    }

}