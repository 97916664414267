
class controller {
    constructor ($scope, $state, CurrentUser, ErrorService) {
        CurrentUser.then(
            (r) => {
                this.cu = r;
            },
            () => {
                self.cu = false;
            }
        );
        this.ErrorService = ErrorService;
        this.$state = $state;
    }

    $onInit () {
        this.getList();
        this.getNewComment();
    }

    getNewComment (data ) {
        this.newComment = {};
    }

    getList() {
        this.loading = true;
        this.resource.comments({name: this.data.Name}).$promise
            .then(
                (r) => {this.list = r},
                (err) => {}
            )
            .finally(() => { this.loading = null; })
    }

    addComment () {
        this.resource.comments_add({name: this.data.Name}, this.newComment)
            .$promise
            .then(
                (r) => {
                    this.list.push(r);
                    this.getNewComment();
                    this.data.$get();
                },
                (err) => {
                    debugger
                }
            )
    }

    deleteComment(comm) {
        var self = this;
        this.resource.comments_delete({name: this.data.Name, cID: comm.ID})
            .$promise
            .then(
                (r) => {
                    debugger
                    self.list = _.without(self.list, comm); // _.indexOf(this.list, {ID: comm.ID}));
                },
                (err) => {
                    this.ErrorService.errorDialog(err);
                }
            )
    }
}

export const CatalogItemCommentComponent = {
    bindings: {
        resource: '<',
        data: '='
    },
    controller: controller,
    template: `
        <md-list class="md-dense flex">
            <cmp-catalog-comment-item  ng-repeat="item in $ctrl.list" data="item" list-controller="$ctrl" flex></cmp-catalog-comment-item>
            <!--<md-list-item class="md-2-line" ng-repeat="item in $ctrl.list">-->
                <!--<cmp-catalog-comment-item data="item" list-controller="$ctrl" flex></cmp-catalog-comment-item>-->
              <!--&lt;!&ndash;<img ng-src="{{item.User.Picture}}" class="md-avatar" alt="{{item.User.DisplayName}}" />&ndash;&gt;-->
              <!--&lt;!&ndash;<div class="md-list-item-text flex layout-column">&ndash;&gt;-->
                <!--&lt;!&ndash;<h3><a ui-sref="workspace({wsId: item.User.Workspace})">{{item.User.DisplayName}}</a></h3>&ndash;&gt;-->
                <!--&lt;!&ndash;<p>{{ item.Text }}</p>&ndash;&gt;-->
              <!--&lt;!&ndash;</div>&ndash;&gt;-->
            <!--<md-divider ></md-divider>-->
            <!--</md-list-item>-->

            <md-list-item ng-if="!$ctrl.cu">
                <p>only reg user</p>
            </md-list-item>
                
            <md-list-item class="md-2-line md-no-proxy" ng-if="$ctrl.cu">
              <img ng-src="{{$ctrl.cu.Picture}}" class="md-avatar" alt="{{$ctrl.cu.DisplayName}}" />
                <!--<pre>{{$ctrl.cu}}</pre>-->
                <form class="md-list-item-text flex layout-column " ng-submit="$ctrl.addComment()" flex style="padding-top: 1em">
                    <h3><a ui-sref="workspace({wsId: $ctrl.cu.Workspace})">{{$ctrl.cu.DisplayName}}</a></h3>
                    <p>
                        <textarea name="text" ng-model="$ctrl.newComment.Text" flex style="min-height: 3em; width: 100%;"></textarea>
                    </p>
                    <div flex layout="row" layout-align="end center">
                        <md-button type="submit" ng-disabled="!$ctrl.newComment.Text">send</md-button>
                    </div>
                </form>
            </md-list-item>

        </md-list>
        `
};