class controller{
    constructor(ApplicationsService, $state, KuberlabConfigApi, $scope, $element, $translate) {
        this.$translate = $translate;
        this.cfg = KuberlabConfigApi;
        this.$state = $state;
        this.resource = ApplicationsService.getResource( $state.params );
        // this.List = [];
        this.$element = $element;
        this.$scope = $scope
    }

    $onInit(){
        this.getPackageList();
        this.data = {
            manager: this.appController.data.Configuration.spec.package_manager,
            packages: []
        }
        this.getList();
    }

    getList() {
        this.listLoading = true;
        this.listLoadingError = false;
        this.resource.packages({application: this.appController.data.Name, all: this.packageAll})
            .$promise
            .then(
                (r) => {
                    this.list = r;
                },
                (err) => {
                    this.listLoadingError = err;
                    // this.$scope.$emit('api_error', err);
                }
            )
            .finally(() => {
                this.listComplite = true;
                this.listLoading = false;
            })
    }

    getPackageList() {
        this.packagesList = [];
        var self = this;
        this.resource.package_managers({application: this.appController.data.Name})
            .$promise
            .then(
                (r) => {
                    self.packagesList = r;
                },
                (err) => {
                    self.$scope.$emit('api_error', err);})
    }

    save(){
        if(this.saving) return false;

        this.Error = false;
        this.saving = true;
        var tr = {};
        var self = this;
        if(!this.pack || this.pack == '') {
            self.$translate(["MLAPPS_PIP_PACKAGE_EMPTY"])
                .then(
                    function(translations){
                        tr = translations;
                    })
                .finally(
                    function () {
                        self.printLog(tr["MLAPPS_PIP_PACKAGE_EMPTY"], 'logError-Item');
                    });
            return false;
        }
        var re = /[;,\t ]/;
        this.data.packages = _.compact(this.pack.split(re));
        self.$translate(["MLAPPS_PIP_START_INSTALL"])
            .then(
                function(translations){
                    tr = translations;
                })
            .finally(
                function () {
                    self.printLog(tr["MLAPPS_PIP_START_INSTALL"] + ': ' + self.pack, 'logStart-Item');
                });

        this.pack = '';


        var url = this.cfg.APIPath + '/workspace/' + this.$state.params.wsId  + '/application/' + this.$state.params.applicationName + '/packages';

        var xhr = new XMLHttpRequest();
        xhr.previous_text = '';

        xhr.onload = function() {
            var tr = {};
            self.getList();
            self.$translate(["MLAPPS_PIP_COMPLETE_INSTALL"])
                .then(
                    function(translations){
                        tr = translations;
                    })
                .finally(
                    function () {
                        self.printLog(tr["MLAPPS_PIP_COMPLETE_INSTALL"], 'logSuccess-Item');
                        self.saving = false;
                    });

        };
        xhr.onerror = function() {
            var tr = {};
            self.$translate(["MLAPPS_PIP_ERROR_INSTALL"])
                .then(
                    function(translations){
                        tr = translations;
                    })
                .finally(function () {
                        self.printLog(tr["MLAPPS_PIP_ERROR_INSTALL"], 'logError-Item');
                        self.saving = false;
                    });
        };
        xhr.onreadystatechange = function()
        {
            try
            {
                if (xhr.readyState > 2)
                {
                    var new_response = xhr.responseText.substring(xhr.previous_text.length);
                    var line = new_response.split('\n');
                    _.each(line, function (v) {
                        self.printLog(v);
                    });
                    // self.printLog(new_response);
                    xhr.previous_text = xhr.responseText;
                }
            }
            catch (e)
            {
                //log_message("<b>[XHR] Exception: " + e + "</b>");
            }
            console.log(xhr.readyState, xhr.responseText);
        };
        xhr.open("POST", url, true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(JSON.stringify(this.data));
    }

    printLog(log, type = 'logList-Item'){
        $(this.$element).find('.logList').append('<div class="' + type + '">' + log + '</div>');
    }
}


export const ApplicationsPipComponent = {
    bindings: {
        application: '=',
        appController: '='
    },
    controller: controller,
    template: function ($templateCache) {
        return $templateCache.get('kuberlab/applications/pip/applications_pip_component.html');
    }
};
