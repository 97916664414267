export class UserService {
    constructor($resource, KuberlabConfigApi, WorkspaceService, $state, CurrentUser, UserConfigService) {
        this.wsResource = WorkspaceService.workspace;
        this.UserConfigService = UserConfigService;
        this.ws;
        this.viewSettings = {infra:  !GlobalSettings.infra_only_for_admins};

        this.$resource = $resource;
        this.cfgApi = KuberlabConfigApi;
        this.login = $resource(this.cfgApi.APIPath + '/cu/login', {},  KuberlabConfigApi.ResourceParams );
        this.save = $resource(this.cfgApi.APIPath + '/cu/update', {},
            _.extend({}, KuberlabConfigApi.ResourceParams, {
                upload: {
                    method: 'POST',
                    url: this.cfgApi.APIPath + '/cu/picture',
                    transformRequest: function (data) {
                        var fd = new FormData();
                        angular.forEach(data, function(value, key) {
                            fd.append(key, value);
                        });
                        return fd;
                    },
                    headers: {'Content-Type':undefined, enctype:'multipart/form-data'}
                },
            })
        );
        this.deleteAccount = $resource(this.cfgApi.APIPath + '/auth/delete', {},  {delete: {method: 'DELETE'}} );

        CurrentUser.then(
            (r) => {
                this.viewSettings.infra = !GlobalSettings.infra_only_for_admins || ( GlobalSettings.infra_only_for_admins && r.Admin );
                this.cu = r;
            },
            (e) => {
                this.cu = false;
            }
        );
    }

    getResource(params, clear) {
        if(!this._resource || clear) {

            var urlAPI = this.cfgApi.APIPath + '/cu';
            this._resource = this.$resource(urlAPI, {},  _.extend({}, this.cfgApi.ResourceParams, {
                login: {
                    method: 'POST',
                    url: urlAPI + '/login'
                },
                password: {
                    method: 'PUT',
                    url: urlAPI + '/password'
                },
                email: {
                    method: 'PUT',
                    url: urlAPI + '/email'
                }
            } ));
        }
        return this._resource;
    }

    getWs (update) {
        var self = this;
        if(!this.ws ) {
            this.ws = [];
            update = true;
            this.wsPromise = null;
        }
        if( !this.wsPromise || update) {
            this.wsPromise = this.wsResource.query()
                .$promise
                .then(
                    function (r) {
                        self.ws = r;
                        var workspace = self.UserConfigService.getConfig('currentWS');
                        if(workspace) {
                            var nWS = _.findWhere(self.ws, {Name: workspace.Name});
                            if(nWS)
                                self.UserConfigService.setConfig('currentWS', nWS);
                            else
                                self.UserConfigService.setConfig('currentWS', undefined);
                        }
                    },
                    function () {
                        self.ws = [];
                    }
                )
        }
        return this.wsPromise;
    }

    wsCanBilling(ws){
        return true;
        // if(!GlobalSettings.billing_enabled) return true;
        // var wsInfo = _.findWhere(this.ws, {Name: ws});
        // if(wsInfo) {
        //     return wsInfo.HasCustomer;
        // }else{
        //     return false;
        // }
    }
 }
