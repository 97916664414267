// import {CatalogBaseService} from '../catalog/base/service'
//
// export class CatalogDataService extends CatalogBaseService {
//     constructor() {
//         super(...arguments);
//         this._params = {};
//         this.apiDir = '/dataset';
//     }
//
//     // getWsUrl (params) {
//     //     return this.cfg.APIPath + '/workspace/' + params.wsId + '/datasets';
//     // }
//     //
//     // getResourceUrl(params){
//     //     return params.wsId
//     //         ? this.getWsUrl(params) + '/:name'
//     //         : this.cfg.APIPath + '/catalog/datasets';
//     // }
// }



import {CatalogBaseService} from '../catalog/base/service'
import {CatalogService} from '../catalog/catalog_service'

export class CatalogDataService extends CatalogService {
    constructor() {
        super(...arguments);
        this._params = {};
        // this.apiDir = '/mlmodel';
        this.Intrface = 'dataset'
    }

    getResource(params = {}, clear, prCb = () => {}) {
        params.Interface = this.Intrface;
        return super.getResource(params, clear, prCb );
    }


    // resourceAction (url) {
    //     debugger;
    //     return  data = {
    //         tree: {
    //             params: {
    //                 version: '@version',
    //                 path: '@path'
    //             },
    //             method: "GET",
    //             url: url + '/versions/:version/tree/:path'
    //         },
    //         fileGet: {
    //             params: {
    //                 version: '@version',
    //                 path: '@path'
    //             },
    //             method: "GET",
    //             url: url + '/versions/:version/file/:path'
    //         },
    //         fileUpload: {
    //             params: {
    //                 version: '@version',
    //                 path: '@path'
    //             },
    //             method: "POST",
    //             url: url + '/versions/:version/file/:path'
    //         },
    //         fileFelete: {
    //             params: {
    //                 version: '@version',
    //                 path: '@path'
    //             },
    //             method: "DELETE",
    //             url: url + '/versions/:version/file/:path'
    //         },
    //         versionCreate: {
    //             params: {
    //                 version: '@version'
    //             },
    //             method: "POST",
    //             url: url + '/versions'
    //         },
    //         versionCommit: {
    //             params: {
    //                 version: '@version'
    //             },
    //             method: "POST",
    //             url: url + '/versions/:version/commit'
    //         }
    //
    //     };



        // switch(this._params.Interface){
        //     case "chart-app":
        //     case "chart-mlapp-v2":
        //         data = {
        //             'yaml': {
        //                 params: {version: '@version'},
        //                 method: "GET",
        //                 transformResponse: function (data, headersGetter, status) {
        //                     return {yaml: data};
        //                 },
        //                 url: url + '/versions/:version/yaml'
        //             },
        //             'values_yaml': {
        //                 params: {version: '@version'},
        //                 method: "GET",
        //                 transformResponse: function(data, headersGetter, status) {
        //                     return {yaml: data};
        //                 },
        //                 url: url + '/versions/:version/values/yaml'
        //             },
        //             'values_yaml_params': {
        //                 params: {version: '@version'},
        //                 method: "POST",
        //                 transformResponse: function(data, headersGetter, status) {
        //                     return {yaml: data};
        //                 },
        //                 url: url + '/versions/:version/values/yaml'
        //             },
        //             "install" : {
        //                 params: {version: '@version'},
        //                 method: "POST",
        //                 url: url + '/versions/:version/install'
        //             },
        //         };
        //         break;
        //     case 'mlmodel':
        //         data = {
        //             'serve': {
        //                 params: {
        //                     version: "@version"
        //                 },
        //                 url: url + '/versions/:version/serving',
        //                 method: "POST"
        //             }
        //         };
        //         break;
        // }
        // return data;
    // }


    // resourceAction (url) {
    //     return {
    //         'serve': {
    //             params: {
    //                 version: "@version"
    //                 },
    //             url: url + '/versions/:version/serving',
    //             method: "POST"
    //         }
    //     }
    // }
}
