export function LayoutPageContent(LayoutService, UserConfigService) {
    function link(scope, element) {

        function setSumMenu(hide) {
            let ac = hide ? 'addClass' : 'removeClass';
            element[ac]('wsHideSubMenu');
            scope.wsHideSubMenu = hide
        };

        scope.$on('LayoutButtonSubmenu', ()=>{
            scope.submenu();
        });

        scope.submenu = function () {
            let hide = UserConfigService.getConfig('wsHideSubMenu');
            hide = hide ? false : true;
            UserConfigService.setConfig('wsHideSubMenu', hide);
            setSumMenu(hide)
        };

        setSumMenu( UserConfigService.getConfig('wsHideSubMenu'))
    }

    return ({
        link: link,
        restrict: "E"
    });
}
