export class ApplicationsTaskTeplateController {
    constructor($mdDialog, ApplicationsService, applicationName, $state) {
        this.$mdDialog = $mdDialog;
        this.resourceApp = ApplicationsService.getResource( $state.params );
        this.applicationName = applicationName;
    }

    $onInit() {
        this.resourceApp.tasktemplates({application: this.applicationName})
            .$promise
            .then(
                (r) => {
                    this.list = r;
                },
                (err) => {
                    this.error = err;
                }
            )
    }

    add(template) {
        // debugger;
        this.$mdDialog.hide(_.first(jsyaml.safeLoad(template.config)));
    }

    cancel() {
        debugger;
        this.$mdDialog.cancel();
    }

}


export const ApplicationsTaskTeplateDialog = `
<md-dialog>
    <md-toolbar>
      <div class="md-toolbar-tools">
        <h2>Application template list</h2>
        <span flex></span>
        <md-button class="md-icon-button" ng-click="$ctrl.cancel()">
          <md-icon md-svg-icon="core:close"></md-icon>
        </md-button>
      </div>
    </md-toolbar>
    <md-dialog-content layout-align="start start" layout="row" style="width: 500px;">
        <div class="md-dialog-content" flex="20" >
            <md-radio-group ng-model="selected">
              <md-radio-button ng-repeat="t in $ctrl.list" ng-value="t">
                     {{ t.name }}
              </md-radio-button>
            </md-radio-group>
            <!--{{selected}}-->
        </div>
        <div flex>
            <div>{{selected.description}}</div>
            <div ui-ace="{mode: 'yaml'}" ng-model="selected.config"></div>
        </div>
    </md-dialog-content>
    <md-dialog-actions layout="row" layout-align="start center">
        <div flex></div>
        <md-button ng-click="$ctrl.add(selected)">{{"COMMON_ADD" | translate}}</md-button>
    </md-dialog-actions>
</md-dialog>
`;
