class Pagenate {
    constructor() {
        this.page; //bindings
        this.limit; //bindings
        this.list; //bindings
        this.limits;
    }
    goPage(page) {
        // debugger;
        this.page = page;
    }
    prevPage () {
        if(this.page > 1) return this.page - 1;
        else {
            return false;
        }
    }
    nextPage() {
        if(this.list && this.list.length >= this.limit) return this.page * 1 + 1;
        else return false;
    }

    isActive() {
        return !!(this.page > 1 || ( this.page == 1 && this.list && this.list.length >= this.limit) || ( this.limits && this.limits.length));

    }

}

export const PagenateComponent = {
    bindings: {
        page: "=",
        limit: "=",
        list: "<",
        limits: "<"
    },
    controller : Pagenate,
    template: `
        <div ng-if="$ctrl.isActive()" class="pagenate layout-row layout-align-start-center">
            <md-button ng-disabled="!$ctrl.prevPage()" ng-click="$ctrl.goPage($ctrl.prevPage())" class="md-icon-button"><</md-button>
            <span>{{"PAGENATE_PAGE"|translate}} {{$ctrl.page}}</span>
            <md-button ng-disabled="!$ctrl.nextPage()" ng-click="$ctrl.goPage($ctrl.nextPage())" class="md-icon-button">></md-button>
            <div ng-if="$ctrl.limits && $ctrl.limits.length" class="layout-row layout-align-start-center">
                <span>{{"PAGENATE_LIMIT"|translate}}: </span> 
                <md-select  ng-model="$ctrl.limit" style="margin: 0;  padding-left: 10px;">
                    <md-option ng-repeat="item in $ctrl.limits" ng-value="item">{{item}}</md-option>
                </md-select>
            </div>
        </div>
    `
};
