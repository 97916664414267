class controller {
    constructor () {
        this.list = [
            {title: 'Getting started with Kibernetika.ai', link: 'https://kibernetika.readthedocs.io/en/latest/#quick-start-guide', class: 'bold'},
            {title: 'Project Page Tour', link: 'https://kibernetika.readthedocs.io/en/latest/tutorials/project-page/'},
            {divider: true},
            {title: 'Fast.ai course lessons', link: 'https://kibernetika.readthedocs.io/en/latest/how-to/fastai/#fastai-deep-learning-course'},
            {divider: true},
            {title: 'Using OpenVINO', link: 'https://kibernetika.readthedocs.io/en/latest/tutorials/openvino-1/'},
            {title: 'Background Removal tutorial', link: 'https://kibernetika.readthedocs.io/en/latest/tutorials/background-rm/'},
            {title: 'CIFAR-10 model training', link: 'https://kibernetika.readthedocs.io/en/latest/how-to/cifar-10/#train-cifar-10-model-from-scratch-using-kibernetikaai'},
            {divider: true},
            {title: 'Datasets management', link: 'https://kibernetika.readthedocs.io/en/latest/datasets/datasets/'},
            {title: 'Versioning Workflow', link: 'https://kibernetika.readthedocs.io/en/latest/models/versioning/'},
            {title: 'CLI tools', link: 'https://kibernetika.readthedocs.io/en/latest/serving/kibernetika-serving/#cli-interface'},
        ]
    }
}


export const WorkspaceFaq = {

    template: `

        <md-card>

            <md-list>
                <!--<md-subheader class="md-no-sticky">3 line item (with hover)</md-subheader>-->
                <div ng-repeat="i in $ctrl.list">
                    <md-list-item ng-if="!i.divider" href="{{i.link}}" target="_blank">
                        <p class="{{i.class}}">{{i.title}}</p>
                    </md-list-item>
                    <md-divider ng-if="i.divider"></md-divider>
                
                </div>
            </md-list>
         </md-card>
    `,
    controller: controller
}
