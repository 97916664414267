import {ApplicationsItemFullController} from './applications_item_full_controller'

export class ApplicationsCreaateController {

    constructor(applicationsResource, $mdDialog, $state, workspace) {
        this.$state = $state;
        this.WS = workspace;
        this.applicationsResource = applicationsResource;
        this.$mdDialog = $mdDialog;
    }

    scan(repo) {
        var self = this;
        this.scanning = true;
        this.scanError = false;
        this.applicationsResource.scanapplication( repo )
            .$promise
            .then(
                function (r) {
                    self.data = r;
                    self.appDialog(r)
                },
                function (err) {
                    self.scanError = err;
                }
            )
            .finally(function () {
                self.scanning = false;
            })
    }

    appDialog(data) {
        var then = this;
        // this.$scope.chart= this.data;
        this.installError = null;
        this.$mdDialog.show({
            locals: {
                // dialog: then.$mdDialog,
                application : data,
                viewTabs: []
                // clusters: null,
                // ws: null
            },
            controller: ApplicationsItemFullController,
            controllerAs: '$ctrl',
            template: `
                <md-dialog>
                    <form ng-cloak name="newMlApp" ng-submit="$ctrl.save(newProject, true)">
                        <md-toolbar>
                          <div class="md-toolbar-tools">
                            <h2>Mango (Fruit)</h2>
                            <span flex></span>
                            <md-button class="md-icon-button" ng-click="$ctrl.cancel()">
                              <md-icon md-svg-icon="core:close"></md-icon>
                            </md-button>
                          </div>
                        </md-toolbar>
                        <md-dialog-content >
                            <div class="md-dialog-content" ng-include="'kuberlab/applications/card/applications_card_form.html'"></div>
                        </md-dialog-content>
                        <md-dialog-actions layout="row" ng-include="'kuberlab/applications/card/applications_card_form_action.html'"></md-dialog-actions>
                    </form>
                </md-dialog>`,
            parent: angular.element(document.querySelector('#mainBody')), // angular.element(document.body),
            // targetEvent: ev,
            clickOutsideToClose: false,
            escapeToClose: false
        }).then(
            function(data){
                // then.$state.go('wsProject.tab', {
                //     // wsId: data.data.workspace_name,
                //     // projectName: data.data.project_name,
                //     // envName: data.data.environment,
                //     tab: "tasks"
                // });
                // then.instaling = true;
                // debugger
                //
            },
            function () {
                // debugger;
            }
        );
    }
}
//
// export const ApplicationsCreaateComponent = {
//     controller: controller,
//     template: 'kuberlab/applications/card/applications_card_create.html'
// };
