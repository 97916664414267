
import {CoreServiceResource} from '../core/service/core_service_resource'

export class ApplicationsService extends CoreServiceResource {
    constructor($resource, $q, KuberlabConfigApi, $rootScope, UserConfigService, WorkspaceService ) {
        super(...arguments);
        this.paramsTask = {};
        this.UserConfigService = UserConfigService;
        this.WorkspaceService = WorkspaceService;

        this.watchWS('applicationTask', this.ws_applicationTask);
        this.watchWS('applicationTaskCreate', this.ws_applicationTaskCreate);
        this.watchWS('applicationTaskDelete', this.ws_applicationTaskDelete);
        this.images = GlobalSettings.container_images;
        // {
        //     cpu: [
        //         'kuberlab/tensorflow:cpu-36-2.1.0-full',
        //         'kuberlab/tensorflow:cpu-36-2.0.0-full',
        //         'kuberlab/tensorflow:cpu-36-1.10.0-full',
        //         'kuberlab/tensorflow:cpu-36-1.10.1-full',
        //         'kuberlab/tensorflow:cpu-36-1.11.0-full',
        //         'kuberlab/tensorflow:cpu-36-1.11-full',
        //         'kuberlab/tensorflow:cpu-36-1.12-full',
        //         'kuberlab/tensorflow:cpu-36-1.13-full',
        //         'kuberlab/openvino:cpu-36',
        //         'kuberlab/serving:latest-openvino',
        //         'kuberlab/serving:latest',
        //         'kuberlab/tensorflow:cpu-36-1.7.0-full',
        //         'kuberlab/tensorflow:cpu-36-1.8.0-full',
        //         'kuberlab/tensorflow:cpu-36-1.9.0-full',
        //     ],
        //     gpu: [
        //         'kuberlab/tensorflow:gpu-36-2.1.0-full',
        //         'kuberlab/tensorflow:gpu-36-2.0.0-full',
        //         'kuberlab/tensorflow:gpu-36-1.10.0-full',
        //         'kuberlab/tensorflow:gpu-36-1.10.1-full',
        //         'kuberlab/tensorflow:gpu-36-1.11.0-full',
        //         'kuberlab/tensorflow:gpu-36-1.11-full',
        //         'kuberlab/tensorflow:gpu-36-1.12-full',
        //         'kuberlab/tensorflow:gpu-36-1.13-full',
        //         'kuberlab/openvino:gpu-36',
        //         'kuberlab/serving:latest-openvino-gpu',
        //         'kuberlab/serving:latest-gpu',
        //         'kuberlab/tensorflow:gpu-36-1.7.0-full',
        //         'kuberlab/tensorflow:gpu-36-1.8.0-full',
        //         'kuberlab/tensorflow:gpu-36-1.9.0-full',
        //     ]
        // }
    }



    getResource(params = {}, clear, prCb = () => {}) {
        var wsId = params.wsId;
        if(this._wsId != wsId || clear ) {
            this._reset('resource');
        }
        if( !this._resource ){
            this._requestParams = params;
            var url = this.cfg.APIPath + '/workspace/' + wsId + '/application/:application';
            this._reset();
            this._resource = this.$resource(
                url,
                {application: '@Name'},
                _.extend({}, this.cfg.ResourceParams,
                    {
                        // update: {
                        //     method: "PUT",
                        //     transformResponse( data ) {
                        //         this._reset('application');
                        //         return JSON.parse(data);
                        //     }
                        //
                        // },
                        uploadFile: {
                            method: 'POST',
                            url: url + '/upload',
                            transformRequest: function (data) {
                                var fd = new FormData();
                                angular.forEach(data, function(value, key) {
                                    fd.append(key, value);
                                });
                                return fd;
                            },
                            uploadEventHandlers: {
                                progress: prCb
                            },
                            // eventHandlers: {
                            //     progress: (c) => {
                            //         debugger;
                            //         if (e.lengthComputable) {
                            //             $scope.progressBar = (e.loaded / e.total) * 100;
                            //             $scope.progressCounter = $scope.progressBar;
                            //         }
                            //     }
                            // },
                            headers: {'Content-Type':undefined, enctype:'multipart/form-data'}
                        },
                        delete: {
                            method: 'DELETE',
                            url : this.cfg.APIPath + '/workspace/' + wsId + '/application/:application',
                        },
                        isavailable : {
                            method: "GET",
                            url: this.cfg.APIPath + '/workspace/' + wsId + '/mlappname/isavailable'
                        },
                        pickup : {
                            method: "GET",
                            url: this.cfg.APIPath + '/workspace/' + wsId + '/mlappname/pickup'
                        },
                        create: {
                            method: "POST",
                            params: {application: "@application"}
                        },

                        scanapplication: {
                            method: "POST",
                            // params: {application: "@application"},
                            url: this.cfg.APIPath + '/workspace/' + wsId + '/scanapplication'
                        },
                        build: {
                            method: "POST",
                            params: {application: "@application", taskName: "@taskName"},
                            url: url + '/build/:taskName',
                                // transformResponse(r) {
                                //     this._reset('application');
                                //     return r;
                                // }
                        },

                        enable: {
                            method: "POST",
                            params: {application: "@application"},
                            url: url + '/enable'
                        },
                        disable: {
                            method: "POST",
                            params: {application: "@application"},
                            url: url + '/disable'
                        },
                        reset: {
                            method: "POST",
                            params: {application: "@application"},
                            url: url + '/reset'
                        },

                        allocatednodes: {
                            method: "GET",
                            isArray: true,
                            params: {application: "@application"},
                            url: url + '/allocatednodes'
                        },

                        status: {
                            method: "GET",
                            // isArray: true,
                            params: {application: "@application"},
                            url: url + '/status'
                        },

                        download: {
                            method: 'GET',
                            params: {application: "@application"},
                            url: url + '/chart',
                            responseType: 'arraybuffer',
                            transformResponse: function(data, headers) {
                                var h =  headers();
                                var blob = new Blob([data], { type: h['content-type'] });
                                return {
                                    response: blob,
                                    filename : h['content-disposition'].match(/filename="(.+)"/)[1],
                                    headers: headers
                                };
                            }
                        },

                        serving: {
                            method: "POST",
                            params: {application: "@application"},
                            url: url + '/serving'
                        },
                        pip_install: {
                            method: "POST",
                            params: {application: "@application"},
                            url: url + '/pip/install'

                        },
                        add_build: {
                            method: "POST",
                            params: {application: "@application"},
                            url: url + '/builds'
                        },
                        build_delete: {
                            method: "DELETE",
                            params: {application: "@application", version: "@version"},
                            url: url + '/builds/:version'
                        },
                        build_muliti_delete: {
                            method: "DELETE",
                            params: {application: "@application", build: "@build"},
                            url: url + '/builds'
                        },
                        build_muliti_restart: {
                            method: "PUT",
                            params: {application: "@application", builds: "@builds"},
                            url: url + '/builds/restart?builds=:builds'
                        },
                        build_muliti_stop: {
                            method: "POST",
                            params: {application: "@application", builds: "@builds"},
                            url: url + '/builds/stop?builds=:builds'
                        },
                        build_start: {
                            method: "PUT",
                            params: {application: "@application", version: "@version"},
                            url: url + '/builds/:version/start'
                        }
    /* ------------------------------------------------------------------------------ */
                        , tasks: {
                            method: "GET",
                            isArray: true,
                            params: {application: "@application"},
                            url: url + '/tasks'
                        }
                        , tasks_search: {
                            method: "PUT",
                            isArray: true,
                            params: {application: "@application"},
                            url: url + '/tasks/search'
                        }
                        , tasksparameters: {
                            method: "GET",
                            isArray: true,
                            params: {application: "@application"},
                            url: url + '/taskparameters/names'
                        }
                        , task: {
                            method: "GET",
                            params: {
                                application: "@application",
                                task: '@task',
                                build: '@build'
                            },
                            url: url + '/tasks/:task/build/:build'
                        }
                        , task_delete: {
                            method: "DELETE",
                            params: {
                                application: "@application",
                                task: '@task',
                                build: '@build'
                            },
                            url: url + '/tasks/:task/build/:build'
                        }
                        , task_stop: {
                            method: "POST",
                            params: {
                                application: "@application",
                                task: '@task',
                                build: '@build'
                            },
                            url: url + '/tasks/:task/build/:build/stop'
                        }
                        , task_restart: {
                            method: "PUT",
                            params: {
                                application: "@application",
                                task: '@task',
                                build: '@build'
                            },
                            url: url + '/tasks/:task/build/:build/restart'
                        }
                        , task_stats: {
                            method: "GET",
                            params: {
                                application: "@application",
                                task: '@task',
                                build: '@build'
                            },
                            url: url + '/tasks/:task/build/:build/stats'
                        }
                        , applicationGraph: {
                            method: "GET",
                            url: url + "/graph",
                            asArray: true,
                            cancellable: true,
                            params: {
                                application: "@application"
                            }
                        },
                        taskGraph: {
                            method: "GET",
                            url : url + '/tasks/:taskName/build/:build/graph',
                            asArray: true,
                            params: {
                                application: "@application",
                                taskName: '@taskName',
                                build: '@build'
                            }
                        }
                        , taskTensorboardMetrics: {
                            method: "GET",
                            url : url + '/tasks/:task/build/:build/tensorboard-metrics',
                            isArray: true,
                            params: {
                                application: "@application",
                                task: '@task',
                                build: '@build'
                            }
                            // , transformResponse: function(data, headers) {
                            //     debugger
                            //     var h =  headers();
                            //     var blob = new Blob([data], { type: h['content-type'] });
                            //     return {
                            //         response: blob,
                            //         filename : h['content-disposition'].match(/filename="(.+)"/)[1],
                            //         headers: headers
                            //     };
                            // }
                        }
                        , task_pods: {
                            method: "GET",
                            isArray: true,
                            cancellable: true,
                            params: {
                                application: "@application",
                                task: '@task',
                                build: '@build'
                            },
                            url: url + '/tasks/:task/build/:build/pods'
                            // , transformResponse: function(data, headers) {
                            //     debugger
                            //     var h =  headers();
                            //     var blob = new Blob([data], { type: h['content-type'] });
                            //     return {
                            //         response: blob,
                            //         filename : h['content-disposition'].match(/filename="(.+)"/)[1],
                            //         headers: headers
                            //     };
                            // }
                        }
                        , task_pod_log: {
                            method: "GET",
                            params: {
                                application: "@application",
                                task: '@task',
                                build: '@build',
                                pod: "@pod"
                            },
                            url: url + '/tasks/:task/build/:build/pods/:pod/log'
                        }
                        , task_graph: {
                            method: "GET",
                            params: {
                                application: "@application",
                                task: '@task',
                                build: '@build'
                            },
                            url: url + '/tasks/:task/build/:build/graph'
                        }
                        , task_serving: {
                            method: "POST",
                            params: {
                                application: "@application",
                                task: '@task',
                                build: '@build'
                            },
                            url: url + '/tasks/:task/build/:build/serving'
                        }
                        , task_export: {
                            method: "POST",
                            params: {
                                application: "@application",
                                task: '@task',
                                build: '@build',
                                eTask: '@eTask'

                            },
                            url: url + '/tasks/:task/build/:build/export/:eTask'
                        }
                        , task_save: {
                            method: "POST",
                            params: {
                                application: "@application",
                                task: '@task',
                                build: '@build'
                            },
                            url: url + '/tasks/:task/build/:build/save'
                        }
                        , task_stop_serving: {
                            method: "DELETE",
                            params: {
                                application: "@application",
                                task: '@task',
                                build: '@build'
                            },
                            url: url + '/tasks/:task/build/:build/serving'
                        }


                        , package_managers: {
                            method: "GET",
                            isArray: true,
                            params: {
                                application: "@application"
                            },
                            url: url + '/package_managers'
                        }
                        , packages: {
                            method: "GET",
                            isArray: true,
                            params: {
                                application: "@application"
                            },
                            url: url + '/packages'
                        }
                        /*---   readme.md   ----*/
                        , readme : {
                            method: "GET",
                            params: {
                                application: "@application"
                            },
                            url: url + '/readme'
                        }
                        , readme_save : {
                            method: "PUT",
                            params: {
                                application: "@application"
                            },
                            url: url + '/readme'
                        }
                        , readme_upload : {
                            method: "POST",
                            params: {
                                application: "@application"
                            },
                            url: url + '/readme'
                        }
                        , tasktemplates : {
                            method: "GET",
                            isArray: true,
                            params: {
                                application: "@application"
                            },
                            url: url + '/tasktemplates'
                        },
                        history: {
                            method: "GET",
                            isArray: true,
                            params: {
                                application: "@application"
                            },
                            url: url + '/history'
                        },
                        experiments: {
                            method: "GET",
                            isArray: true,
                            params: {
                                application: "@application"
                            },
                            url: url + '/experiments'
                        }
                        , checkout: {
                            method: "GET",
                            params: {
                                application: "@application",
                                revision: "@revision"
                            },
                            url: url + '/history/:revision'
                        },

                        simply_form: {
                            method: "GET",
                            isArray: true,
                            params: {application: "@application"},
                            url: url + '/simply_form',
                        }
                        , simply_form_values: {
                            method: "GET",
                            // isArray: true,
                            params: {application: "@application"},
                            url: url + '/simply_form_values',
                        }
                        , simply_form_request: {
                            method: "GET",
                            // isArray: true,
                            params: {application: "@application"},
                            url: url + '/simply_form_request',
                        }
                        , simply_form_save: {
                            method: "PUT",
                            isArray: true,
                            params: {application: "@application"},
                            url: url + '/simply_form',
                        }

                        , simply_template: {
                            method: "GET",
                            params: {application: "@application"},
                            url: url + '/simply_template',
                            transformResponse: function(data, headersGetter, status) {
                                if(status == 200)
                                    return {template: data};
                                else
                                    return angular.fromJson(data);
                            }
                        }

                        , simply_template_save: {
                            method: "PUT",
                            params: {application: "@application"},
                            url: url + '/simply_template',
                            // transformResponse: function(data, headersGetter, status) {
                            //     if(status == 200)
                            //         return {template: data};
                            //     else
                            //         return angular.fromJson(data);
                            // }
                        }

                        , build_simply: {
                            method: "POST",

                            params: {application: "@application"},
                            url: url + '/build_simply',
                        },

                    })
            );
            this._wsId = wsId;
            this.tasksList = [];

        }
        return this._resource;
    }

    _reset(level = 'all') {
        switch (level){
            case 'all':
            case 'resource':
                this._resource = null;
                this.list = null;
            case 'application':
                this.tasksListPromises = null;
                this._applicationPromise = null;
                this._taskparametersParams = null;
            case 'experimants' :
                this._experimentsPromise = null;
            case 'revision':
                this._revisionPromise = null;
        }
    }

    getItem(name, update) {
        console.info('get item');
        var params = {
            application: name
        };
        if(update || !_.isEqual(params, this._applicationParams)) { this._reset('application');}

        if(!this._applicationPromise) {
            this._applicationParams = params;
            this._applicationPromise = this._resource.get(params).$promise;
        }
        return this._applicationPromise;
    }

    remove(data) {
        super.remove(...arguments);
        this._reset('application');
    }

    getExperiments(name, update) {
        var params = {
            application: name
        };
        if(update || !_.isEqual(params, this._experimentsParams)) { this._reset('experimants');}

        if(!this._experimentsPromise) {
            this._experimentsParams = params;
            this._experimentsPromise = this._resource.experiments(params).$promise;
        }
        return this._experimentsPromise;
    }

    getTaskparameters (name, update) {
        var params = {
            application: name
        };
        if(update || !_.isEqual(params, this._taskparametersParams)) { this._taskparametersPromise = null; }

        if(!this._taskparametersPromise) {
            this._taskparametersParams = params;
            this._taskparametersPromise = this._resource.tasksparameters(params).$promise;
        }
        return this._taskparametersPromise;
    }

    getRevisionItem(name, revision, update){
        console.info('get item revision');
        var params = {
            application: name,
            revision: revision
        };
        if(update || !_.isEqual(params, this._revisionParams)) { this._reset('revision')}

        if(!this._revisionPromise) {
            this._revisionParams = params;
            this._revisionPromise = this._resource.checkout(params).$promise;
        }
        return this._revisionPromise;
    }

    getTasks(params, update, request = {}) {
        if( update || this.paramsTask.wsId != params.wsId || this.paramsTask.applicationName != params.applicationName) {
            this.tasksList = [];
            this.tasksListPromises = null;
        }
        if(!this.tasksListPromises){
            var self = this;
            this.tasksList = [];
            this.paramsTask = params;

            this.tasksListPromises = this.getResource(_.extend({}, params, {application : params.applicationName})).tasks(_.extend({}, {application: params.applicationName}, request)).$promise;
            this.tasksListPromises .then(
                    (r) => {
                        var Tasks = _.map(r, (val) => {
                            var o = _.findWhere(self.tasksList, {name: val.name});
                            if(o) return _.extend(o, val);
                            else return val;
                        });
                        self.tasksList = Tasks;
                    },
                    (err) => {
                        // debugger;
                        _.delay(()=>{self.tasksListPromises = null;},100);
                        // self.tasksListPromises = null;
                    }
                );
        }
        return this.tasksListPromises;
    }

    addTask(params, task) {
        if(this._requestParams && this._requestParams.wsId == params.wsId && this._requestParams.applicationName == params.applicationName && this.tasksList) {
            this._addTask(task);
        } else {
            this.getTasks(params).then(
                (r) => {
                    this._addTask(task);
                    // if(!_.findWhere(this.tasksList, {name: task.name, build: task.build} )) {
                    //     var newTask = new this._resource(task);
                    //     this.tasksList.unshift(newTask)
                    // }
                },
                (err) => {}
            )
        }
    }

    _addTask(task) {
        if(!_.findWhere(this.tasksList, {name: task.name, build: task.build} )) {
            var newTask = new this._resource(task);
            this.tasksList.unshift(newTask)
        }
    }

    removeTask(task) {
        this.tasksList = _.without(this.tasksList, task);
    }

    cluster(data) {

        var cluster = {
            ClusterID : data.ClusterID,
            ClusterType : data.ClusterType
            // Uri: data.ClusterUri,
            // ID: data.SharedClusterID || data.GlobalClusterID || data.ClusterName,
            // Meta: {
            //     Global: !!data.GlobalClusterID
            // }
        };
        return cluster;
    }

    isWsApplication(data) {
        // this.isCurrentWs(data.workspac, this._wsId)
        return (this.isCurrentWs(data.workspace, this._wsId) && this._applicationParams && this._applicationParams.application == data.app_name)
        // return (data.workspace == this._wsId && this._applicationParams && this._applicationParams.application == data.app_name)
    }


    getWsApplicationTask(data){
        if(this.isWsApplication(data)) {
            return _.findWhere(this.tasksList, {name: data.name});
        }
    }

    ws_applicationTask(event, data) {
        var task = this.getWsApplicationTask(data);
        if(task) {
            task = _.extend(task, {status: data.status, exec_info: data.exec_info, stop_time: data.stop_time}) ;
            console.info('ws_applicationTask', task);
            this.tasksListPromises = null;
            this.$rootScope.$digest();
        }
    }

    ws_applicationTaskCreate(event, data) {
        // debugger
        if(this.isWsApplication(data)) {
            var task = this.getWsApplicationTask(data);
            if( !task && _.isArray(this.tasksList) ) {
                this.tasksList.unshift(new this._resource(data));
                this.tasksListPromises = null;
                this.$rootScope.$digest();
            }
        }
    }

    ws_applicationTaskDelete(event, data) {
        var task = this.getWsApplicationTask(data);
        if(task) {
            this.tasksList = _.without(this.tasksList, task);
            this.tasksListPromises = null;
            this.$rootScope.$digest();
        }
    }

    lastApps (wsId) {
        return this.UserConfigService.getWsItemConfig(wsId, 'lastApps', 0, 'list');
    }

    addLastApp (wsId, app) {
        if(app && app.Name){
            var list = this.lastApps(wsId) || [];
            var hasApp = _.findWhere(list, {Name: app.Name});
            if (hasApp) {
                list = _.without(list, hasApp);
            }
            list.unshift(app);
            this.updateListApp(wsId, _.first(list, 10));

        }
    }

    getLastApp (wsId) {
        var lastProjects = this.lastApps(wsId);
        var deferred = this.$q.defer();
        var l = _.map(lastProjects, (i) => {
            return i.Name;
        });
        if(l.length) {
            var data = {
                names: l.join(',')
            };

            this.WorkspaceService.getResource({wsId: wsId})
                .dashboard_applications(data)
                .$promise
                .then(
                    (r) => {
                        deferred.resolve(r);
                        this.updateListApp( wsId, angular.copy(r) );
                    },
                    (err) => {
                        deferred.reject(err)
                    }
                )
        } else {
            deferred.resolve( [] );
        }
        return deferred.promise;
    }



    updateListApp (wsId, apps) {
        // console.log('set last app list', apps)
        this.UserConfigService.setWsItemConfig(wsId, 'lastApps', 0, 'list', apps);
    }

//
}
