
import {ApplicationsModelsComponent} from './applications_models_component'
import {ApplicationsTaskComponent} from './applications_task_component'
import {ApplicationsTaskVolumesComponent} from './applications_task_volumes_component'
import {ApplicationsModelService} from './applications_models_service'
import {ApplicationsTaskVolumesGitComponent } from './applications_task_volume_git_component'
import {ApplicationsTaskVolumesRevisionComponent } from './applications_task_volume_revision_component'

export default angular
    .module('applications_models', [])
    .service('ApplicationsModelService', ApplicationsModelService)
    .component('cmpApplicationsModels', ApplicationsModelsComponent)
    .component('cmpApplicationsTask', ApplicationsTaskComponent)
    .component('cmpApplicationsTaskVolumes', ApplicationsTaskVolumesComponent)
    .component('cmpApplicationsTaskVolumesGit', ApplicationsTaskVolumesGitComponent)
    .component('cmpApplicationsTaskVolumesRevision', ApplicationsTaskVolumesRevisionComponent)


