
import {WizardComponent} from './wizard_component'
import {WizardTypeDeployComponent} from './wizard_type_deploy'
import {WizardDeployPortsComponent} from './deploy/ports'
import {WizardDeployLabelsComponent} from './deploy/labels'
import {WizardDeployVariablesComponent} from './deploy/variables'


export default angular
    .module('chartWizard', [])
    .component('cmpChartWizard', WizardComponent)
    .component('cmpWizardTypeDeploy', WizardTypeDeployComponent)
    .component('cmpWizardDeployPorts', WizardDeployPortsComponent)
    .component('cmpWizardDeployLabels', WizardDeployLabelsComponent)
    .component('cmpWizardDeployVariables', WizardDeployVariablesComponent)