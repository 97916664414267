export class controllerItem{

    constructor(
        $scope
        , ApplicationsService
        , $state
        , ClusterService
        , ErrorService
        , StorageService
    ) {
        this.StorageService = StorageService;
        this.ApplicationsService = ApplicationsService;
        this.ClusterService = ClusterService;
        this.ErrorService = ErrorService;
        this.$state = $state;

        this.data;
        this.isNewElement;
        this.listController;
        this.saving = false;
        this.$scope = $scope;
        var self = this;
        $scope.$on('start_saving',
            function () {
                self.saving = true;
            }
        );
        $scope.$on('stop_saving',
            function () {
                self.saving = false;
            }
        );
    }

    $onInit(){
        this.application = this.listController.appController.data;
        this.wsId = this.application.WorkspaceName;
        this.cluster = this.ApplicationsService.cluster(this.application);
        this.types = this.types || this.StorageService.storageTypeList;
        this.data = this.data || {};
        if(this.isTrainLogDir) this.data.isTrainLogDir = this.isTrainLogDir;
        this.oldData = angular.copy(this.data);
        this.Type = this.getType();
        if(this.isNewElement){
            this.edit();
        }
        this.isLibDir = true;
        this.canUploadFile = true;
    }

    getStorage(){
        this.ClusterService.storage(this.wsId, this.cluster)
            .then(
                (r) => {
                    this.clusterStorageList = r;
                },
                (err) => {
                    this.ErrorService.error(err);
                }
            )
    }

    getType () {
        return this.StorageService.getType(this.data);
    }

    edit () {
        this.oldData = angular.copy(this.data);
        this.asForm = true;
        this.getStorage();
    }

    cancel() {
        this.data = this.oldData;
        if(!this.isNewElement) this.asForm = false;
        else this.data = null;
    }

    delete () {
        this.listController.remove(this.data);
    }

    save(form) {
        if(!form.$valid) return false;
        if(this.listController) {
            if(this.isNewElement) {
                this.listController.add(this.data);
            }else{
                this.listController.save(this.data);
            }

        } else {
            this.asForm = false;
        }
    }

    cancelUploadFile() {
        if(this.requestUploadFile) this.requestUploadFile.cancel();
        this.loading = false;
        this.lfApi.removeAll();
        // this.file = null;
        this.loadingError = false;
        this.loadingProgres = 0;
    }

    uploadFile (form) {
        if(!form.$valid) return false;
        var self = this;
        var $scope = this;
        this.loadingError = false;
        this.isLoading = true;
        this.loadingProgres = 0;
        this.requestUploadFile = this.ApplicationsService.getResource(
            this.$state.params,
            true,
            (e) => {
                if (e.lengthComputable) {
                    this.loadingProgres = ~~((e.loaded / e.total) * 100);
                }
            }
        )
            .uploadFile(
                {application: this.application.Name},
                {source: this.data.name, file: this.file[0].lfFile}
            );
        this.requestUploadFile
            .$promise
            .then(
                function (r) {
                    self.addFile = false;
                    self.isLoading = false;
                    self.lfApi.removeAll();
                },
                function (err) {
                    self.loadingError = err;
                    self.isLoading = false;
                }
            );
    }
}

export const ApplicationsVolumesItemComponent = {
    bindings: {
        data: '=',
        // application: '<',
        isNewElement: '<',
        listController: '<',
        isTrainLogDir: '<',
        types: "<",
        fromApplicationForm: '<'
    },
    controller: controllerItem,
    template: function ($templateCache) {
        return $templateCache.get('kuberlab/applications/volumes/applications_volumes_item_component.html');
    },
};
