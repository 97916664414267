import {InstallTab} from './install_tab'


class InstallChartApplications extends InstallTab {

    constructor($scope){
        super();
        var self = this;
        this.$scope = $scope;
        $scope.$watch(
            function () {
                return self.parent.workspace_name;
            },
            function () {
                if(self.target_applications){
                    self.enable();
                }
            }
        );
        this.$scope.$watchCollection(function(){
                return self.parent.clusters;
            },
            function (c) {
                self.enable();
            }
        );

        $scope.$watch(
            function () {
                return self.parent.chart;
            },
            function (c) {
                self.target_applications = (c.DisplayName || c.Name).toLowerCase();
                self.changeTarget();
                // self.enable();
            }
        );


    }

    // $onInit() {
    //     super.$onInit();
    //     debugger;
    //     this.changeTarget();
    // }

    changeTarget() {
        var apps;
        if(this.target_applications) {
            apps = _.compact(_.map(this.target_applications.split(','), function (val) { return $.trim(val); } ));
        }
        var err = _.filter(apps, function(val)  {
            return !/^[a-z0-9]([_-a-z0-9]*[a-z0-9]){2,24}$/.test(val);
        });
        if(err && err.length) {
            this.targetError = err.join(', ');
        }else{
            this.targetError = null;
        }
        this.applications = (apps && apps.length && !this.targetError) ? apps : null;
    }

    canEdit() {
        return !!(this.parent.workspace_name && this.parent.clusters && this.parent.clusters.length);
    }

}

export const InstallChartApplicationsComponent = {
    bindings: {
        applications : '=',
        parent: "<",
        tab: "=",
        index: "<"
    },
    controller : InstallChartApplications,
    template: `
        <p>description target applications</p>
        <md-input-container style="width: 99%;">
         <label>Target applications</label>
         <input type="text" ng-model="$ctrl.target_applications" ng-model-options='{ debounce: 500 }' ng-change="$ctrl.changeTarget()">
         <div ng-if="$ctrl.targetError" >
            <div class="md-input-message-animation" style=" opacity: 1; margin-top: 0px;">Error application name: {{$ctrl.targetError}}</div>
         </div>
        </md-input-container>
    `
};
