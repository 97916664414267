import {ControllerItem} from '../../core/controller/item'

class controller extends ControllerItem{
    constructor($mdDialog, $translate, WorkspacePermissions, validate, $scope, StorageService){
        super(...arguments);
        this.$scope = $scope;
        this.validate = validate;
        // this.types = ['nfs', 'gitRepo', 's3bucket'];
        this.types = {
            gitRepo: 'GIT',
            nfs: 'NFS',
            s3bucket: "s3 bucket"
        };
        this.permissions = WorkspacePermissions;
        this.StorageService = StorageService
    }

    $onInit() {
        super.$onInit();
        this.getType();
        this.getTypeDate();
    }

    getType() {
        this.type = this.StorageService.getType(this.data);

        // var self = this;
        // _.each(this.types, function(val) {
        //     if(self.data[val]){
        //         self.type = val;
        //     }
        // });
    }

    getTypeDate() {
        this.typeData = this.data[this.type] || {};
    }

    isNew() {
        return (this.data && this.data.Name) ? false : true;
    }


    saveStorage(form) {
        if(!form.$valid){return false;}
        var self = this;
        // _.each(this.types, function (val) {
        //     this.data = _.omit(this.data, val);
        // }, this);

        // this.data[this.type] = this.typeData;
        this.save(...arguments);
    }

    can(action){
        switch(action){
            case 'edit':
            case 'delete':
                if(this.data.Dependencies && this.data.Dependencies.length)
                    return false;
                else
                    return true;
        }
    }

    successDelete() {
        this.listController.changeList();
    }

    setDefault () {
        this.listController.resource.default({Name: this.data.Name})
            .$promise
            .then(
                (r) => {
                    this.listController.setDefault(this.data);
                },
                (err) => {
                    this.$scope.$emit('api_error', err);
                }
            )
    }
}


export const ClusterStorageComponent = {
    bindings:{
        data: '=',
        isNewElement: '<',
        listController: '<'
    },
    controller: controller,
    template: `
            <md-divider></md-divider>
            <div ng-if="!$ctrl.asForm" >
                <md-list-item >
                        <md-icon md-svg-icon="core:{{$ctrl.type}}" class="md-avatar-icon"></md-icon>
                        <div class="layout-column layout-aligm-start-start flex-40">
                            <div><b>{{$ctrl.data.DisplayName}}</b> ({{$ctrl.data.Name}})</div>
                        </div>
                        <div ng-switch="$ctrl.type" class="flex">
                            <div ng-switch-when="nfs">
                                <div>Server: {{$ctrl.typeData.server}}</div>
                                <div>Path: {{$ctrl.typeData.path}}</div>
                            </div>
                        </div>
                        <md-input-container layout-align="center center">
                            <md-checkbox style="margin: 0" ng-change="$ctrl.setDefault()" ng-model="$ctrl.data.Default" ng-disabled="$ctrl.data.Default"></md-checkbox>
                            <md-tooltip md-direction="left">{{"COMMON_DEFAULT" | translate}}</md-tooltip>
                        </md-input-container>
                        <!--<div class="flex-15" style="text-align: right;">-->
                            <!---->
                            <!--<span ng-if="$ctrl.data.Default">{{"COMMON_DEFAULT" | translate}}</span>-->
                        <!--</div>-->
                        <md-menu-bar class="padding-zero" >
                            <md-menu class="md-secondary" md-position-mode="target-right target">
                                <md-button class="md-icon-button" ng-disabled="!$ctrl.permissions.can('project.manage')"  aria-label="Add service" ng-click="$mdMenu.open()">
                                    <md-icon md-svg-icon="core:short-menu"></md-icon>
                                </md-button>
                                <md-menu-content width="3">
                                    <md-menu-item ng-if="!$ctrl.envList.Error">
                                        <md-button aria-label="icon" ng-click="$ctrl.actionForm()">
                                            <md-icon md-svg-icon="core:edit"></md-icon> {{"COMMON_EDIT" | translate}}
                                        </md-button>
                                    </md-menu-item>
                                    <md-menu-item>
                                        <md-button 
                                            ng-disabled="!$ctrl.can('delete')"
                                            aria-label="icon" ng-click="$ctrl.deleteConfirm($event, 'CLUSTER_STORAGE_DELETE_DIALOG_MESSAGE', 'CLUSTER_STORAGE_DELETE_DIALOG_TITLE')">
                                            <md-icon md-svg-icon="core:delete"></md-icon> {{"COMMON_DELETE" | translate}}
                                        </md-button>
                                    </md-menu-item>
                                </md-menu-content>
                            </md-menu>
                        </md-menu-bar>
                </md-list-item>
                <div ng-if="$ctrl.data.Description || ($ctrl.data.Dependencies && $ctrl.data.Dependencies.length)" style="padding: 1em 0 1em 74px;">
                    <div ng-if="$ctrl.data.Description">
                        {{$ctrl.data.Description}}
                    </div>
                    <div ng-if="$ctrl.data.Dependencies && $ctrl.data.Dependencies.length">
                        <b>Dependencies</b> - 
                        <span ng-repeat="item in $ctrl.data.Dependencies">{{item.type}}: {{item.id}}</span> 
                        
                    </div>
                </div>
            </div>
            <div ng-if="$ctrl.asForm && $ctrl.permissions.can('project.manage')" ng-include="'kuberlab/projects/storages/storage_form.html'"></div>
        `
};
