    import {CoreServiceResource} from '../core/service/core_service_resource'
import {CatalogService} from '../catalog/catalog_service'

export class ChartsService extends CatalogService {
    isMlapp(item) {
        return !!(item && item.Interface == 'chart-mlapp-v2');
    }
}

export class ChartsServiceOld extends CoreServiceResource {
    constructor ($resource, $q, KuberlabConfigApi, $rootScope){
        super(...arguments);
    }

    getScanResource(params){
        if (params.wsId) {
            var apiPath = this.cfg.APIPath + '/workspace/' + params.wsId + '/scancharts?add=true';
        }
        return this.$resource(
            apiPath,
            {},
            _.extend(
                {},
                this.cfg.ResourceParams, {
                    'scan' : {
                        method: 'POST',
                        isArray: true
                    }
                }
            )
        )
    }

    getResource(params, clear) {
        var resource;
        if (!this._resource || this.wsId != params.wsId  || clear){
            var apiPath = this.cfg.APIPath;
            if(params.wsId) {
                apiPath = apiPath + '/workspace/' + params.wsId ;
            }
            else {
                apiPath = apiPath + '/catalog';
            }
            apiPath = apiPath + '/charts/:chartName';
            resource = this.$resource(
                apiPath,
                {chartName: '@Name'},
                _.extend(
                    {},
                    this.cfg.ResourceParams,
                    {
                        'isavailable' : {
                            method : 'GET',
                            params: {name: '@name'},
                            url: this.cfg.APIPath + '/workspace/:wsId/chartname/isavailable'
                        },
                        'pickup' : {
                            method : 'GET',
                            params: {wsId : '@wsId', name: '@name'},
                            url: this.cfg.APIPath + '/workspace/:wsId/chartname/pickup'
                        },
                        'create' : {
                            method: 'POST',
                            params: {chartName: '@chartName'}
                        },
                        'star' : {
                            method : 'POST',
                            // params: {chartName: '@chartName'},
                            // url: apiPath + '/star',
                            params : {wsId : '@wsId', chartName : '@chartName'},
                            url: this.cfg.APIPath + '/workspace/:wsId/charts/:chartName/star'

                        },
                        'unstar' : {
                            method : 'POST',
                            // params: {chartName: '@chartName'},
                            // url: apiPath + '/unstar',
                            params : {wsId : '@wsId', chartName : '@chartName'},
                            url: this.cfg.APIPath + '/workspace/:wsId/charts/:chartName/unstar'

                        },
                        'chartVersions' : {
                            method : 'GET',
                            // params : {chartName : '@Name'},
                            url: apiPath + '/versions',
                            isArray: true
                            },
                        'chartByVersion' : {
                            params : {chartVersion : '@chartVersion'},
                            method : 'GET',
                            url: apiPath + '/versions/:chartVersion'
                        },
                        "install" : {
                            params: {chartVersion: '@Version'},
                            method: "POST",
                            url: apiPath + '/versions/:chartVersion/install'
                        },
                        'values': {
                            params: {chartVersion: '@Version'},
                            method: "GET",
                            url: apiPath + '/versions/:chartVersion/values'
                        },
                        'yaml': {
                            params: {wsId : '@wsId', chartName : '@chartName', chartVersion: '@chartVersion'},
                            method: "GET",
                            transformResponse: function(data, headersGetter, status) {
                                return {yaml: data};
                            },
                            url: this.cfg.APIPath + '/workspace/:wsId/charts/:chartName/versions/:chartVersion/yaml'
                        },
                        'update_yaml': {
                            params: {wsId : '@wsId', chartName : '@chartName', chartVersion: '@chartVersion'},
                            method: "PUT",
                            url: this.cfg.APIPath + '/workspace/:wsId/charts/:chartName/versions/:chartVersion/yaml'
                        },
                        'values_yaml': {
                            params: {wsId : '@wsId', chartName : '@chartName', chartVersion: '@chartVersion'},
                            method: "GET",
                            transformResponse: function(data, headersGetter, status) {
                                return {yaml: data};
                            },
                            url: this.cfg.APIPath + '/workspace/:wsId/charts/:chartName/versions/:chartVersion/values/yaml'
                        },
                        'values_yaml_params': {
                            params: {wsId : '@wsId', chartName : '@chartName', chartVersion: '@chartVersion'},
                            method: "POST",
                            transformResponse: function(data, headersGetter, status) {
                                return {yaml: data};
                            },
                            url: this.cfg.APIPath + '/workspace/:wsId/charts/:chartName/versions/:chartVersion/values/yaml'
                        },
                        'update_values_yaml': {
                            params: {wsId : '@wsId', chartName : '@chartName', chartVersion: '@chartVersion'},
                            method: "PUT",
                            url: this.cfg.APIPath + '/workspace/:wsId/charts/:chartName/versions/:chartVersion/values/yaml'
                        },
                        config: {
                            method : 'POST',
                            params : {wsId : '@wsId', chartName : '@chartName'},
                            url: this.cfg.APIPath + '/workspace/:wsId/charts/:chartName/config'
                        },
                        readme: {
                            method : 'GET',
                            params : {wsId : '@wsId', chartName : '@chartName'},
                            url: this.cfg.APIPath + '/workspace/:wsId/charts/:chartName/readme'
                        }
                    }

                )

            );
            if(!clear){

                this.wsId = params.wsId;
                this.list = null;
                this._resource = resource;
            }
        }
        return resource || this._resource ;
    }

    isMlapp(item) {
        return !!( item && item.Type.search(/^mlapp/) == 0 );
    }

}