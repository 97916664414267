export default function (AnalyticsProvider) {
    if(window.GlobalSettings.google_analytics)
        AnalyticsProvider.setAccount(window.GlobalSettings.google_analytics);
    // KuberlabServiceProvider.$get().settings()
    //     .then((r) => {
    //         if(r.google_analytics)
    //             AnalyticsProvider.setAccount(r.google_analytics || false);
    //             angular.module('kuberlab')
    //                 .run(['Analytics', function(Analytics) {
    //                     debugger;
    //                 }]);
    //     });
}