/**
 * Created by ilja on 09.12.16.
 */


class AuthCardHeaderController {
}

export const AuthCardHeaderComponent = {
    template: `
        <a ui-sref="home" ui-sref-opts="{reload: true}" class="item">Login</a>
        <!--<a href="/" class="item">Login</a>-->
        `,
    controller: AuthCardHeaderController
};
