class FooterCopyrightController {
    constructor() {
        this.currentYear = new Date().getFullYear();
    }
}

export default angular
    .module('footer', ['ngMaterial'])
    .component('cmpFooterCopyright', {
        controller: FooterCopyrightController,
        template : `
            <span>
                &copy; <cmp-logo></cmp-logo> 2016-{{$ctrl.currentYear}}
            </span>
        `
    })
    .component('cmpFooterVersion', {
        bindings : {
            'commit': '@',
            'author': '@',
            'timeStamp' : '@'
        },
        template : `
            <div id="footer-version">
                UI commit {{$ctrl.commit}} by {{$ctrl.author}}, built {{$ctrl.timeStamp | date:'MMM d yyyy HH:mm'}}
            </div>
        `
    });
