class CatalogRating {
    constructor () {
        this.resource;//
        this.resourceParams; //
        this.data; //
    }

    star() {
        var self = this;
        this.loadingError = false;
        this.resource.star(this.resourceParams)
            .$promise
            .then(
                function (r) {
                    self.data.Stars = r.Stars;
                    self.data.Star = r.Star;
                },
                function (err) {
                    self.loadingError = err;
                }
            )
    }
}

export const CatalogRatingComponent = {
    bindings: {
        resource : '<',
        resourceParams : '<',
        data : '='
    },
    controller: CatalogRating,
    template: `
            <md-button ng-click="$ctrl.star()">
                <md-icon md-svg-icon="core:star"  ng-if="$ctrl.data.Star" ></md-icon>
                <md-icon md-svg-icon="core:star-outline"  ng-if="!$ctrl.data.Star" ></md-icon>
                {{$ctrl.data.Stars}}
                <md-tooltip>Favotite</md-tooltip>
                <cmp-api-error-service ng-if="$ctrl.loadingError" error="$ctrl.loadingError" format="'dialog'"></cmp-api-error-service>
            </md-button>
            `
};
