
import {InviteController} from './invite_controller'

export default function ($stateProvider, $urlRouterProvider) {
    $stateProvider
        .state('invite', {
            url : '/invite/:inviteId',
            resolve:{
                // inviteInfo : function (InviteService, $stateParams) {
                //     var r = InviteService.invite;
                //     return r.get({inviteId : $stateParams.inviteId}).$promise;
                // },
                // currentUser : function (CurrentUser) {
                //     return CurrentUser;
                // }
            },
            views : {
                "home@" : {
                    templateProvider: function ($templateCache) {
                        debugger
                        return $templateCache.get('kuberlab/settings/settings.html');
                    },
                    // template: `
                    //     <div flex layout='column' layout-align="center center" class="login-form-wrapper">
                    //         <div class="main_width_greed" ng-if="$ctrl.invite" >
                    //             <home-card-welcom >
                    //                 <home-card-welcom-content flex="70">
                    //                     <h2>You've been invited</h2>
                    //                     <div class="content">
                    //                         by <span class="select">{{$ctrl.invite.Actor.DisplayName}}</span>
                    //                         to organization
                    //                         <span class="select">{{$ctrl.invite.Workspace.DisplayName}}</span>,
                    //                         team <span class="select">{{$ctrl.invite.Team.DisplayName}}</span>.<br />
                    //                         <span ng-if="!$ctrl.currentUser">Please, authorize to accept the invitation.</span>
                    //                     </div>
                    //                 </home-card-welcom-content>
                    //                 <home-card-welcom-actions class="" ng-if="$ctrl.currentUser">
                    //                     <md-button ng-if="!$ctrl.accepted" class="md-raised md-primary" ng-click="$ctrl.accept()" ng-disabled="$ctrl.sending">Accept invitation</md-button>
                    //                     <md-button ng-if="$ctrl.accepted" ui-sref="workspace({wsId : $ctrl.invite.Workspace.Name})">{{"COMMON_CONTINUE"|translate}}</md-button>
                    //                 </home-card-welcom-actions>
                    //                 <home-card-welcom-actions ng-if="!$ctrl.currentUser" flex="30">
                    //                     <cmp-auth-form invite="$ctrl.invite"></cmp-auth-form>
                    //                 </home-card-welcom-actions>
                    //             </home-card-welcom>
                    //         </div>
                    //         <cmp-api-error-service ng-if="$ctrl.inviteError" error="$ctrl.inviteError"  class="main_width_greed" ></div>
                    //         <cmp-api-error-service ng-if="$ctrl.errorAccept" error="$ctrl.errorAccept" format="'dialog'"></cmp-api-error-service>
                    //     </div>
                    // `,

                    template: `
                            <div flex layout='column'
                                    layout-align="center center"
                                    class="login-form-wrapper"
                                    ng-if="$ctrl.invite"  >
                                <div class="home-card-welcom" layout="column" layout-align="space-between center" >
                                    <home-card-welcom-content >
                                        <h2>You've been invited</h2>
                                        <div class="content">
                                            by <span class="select">{{$ctrl.invite.Actor.DisplayName}}</span>
                                            to organization
                                            <span class="select">{{$ctrl.invite.Workspace.DisplayName}}</span>,
                                            team <span class="select">{{$ctrl.invite.Team.DisplayName}}</span>.<br />
                                            <span ng-if="!$ctrl.currentUser">Please, authorize to accept the invitation.</span>
                                        </div>
                                    </home-card-welcom-content>
                                    <!--<home-card-welcom-actions >-->
                                      <!--<cmp-auth-form></cmp-auth-form>-->
                                    <!--</home-card-welcom-actions>-->


                                     <home-card-welcom-actions class="" ng-if="$ctrl.currentUser">
                                        <md-button ng-if="!$ctrl.accepted" class="md-raised md-primary" ng-click="$ctrl.accept()" ng-disabled="$ctrl.sending">Accept invitation</md-button>
                                        <md-button ng-if="$ctrl.accepted" ui-sref="workspace({wsId : $ctrl.invite.Workspace.Name})">{{"COMMON_CONTINUE"|translate}}</md-button>
                                     </home-card-welcom-actions>
                                     
                                     <home-card-welcom-actions ng-if="!$ctrl.currentUser" >
                                        <cmp-auth-form invite="$ctrl.invite"></cmp-auth-form>
                                     </home-card-welcom-actions>
                                </div>
                            </div>
                        `,

                    controller: InviteController,
                    controllerAs: '$ctrl'
                }
            }
        })
}
