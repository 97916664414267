class controller {
    constructor (Utils, ApplicationsService, $state, $scope, ErrorService) {
        this.Utils = Utils;
        this.$scope = $scope;
        this.resource = ApplicationsService.getResource( $state.params );
        this.isEdit = false;
        this.saving = false;
        this.ErrorService = ErrorService;
    }

    $onInit() {
        this.gpu = ( this.uix.resources.accelerators.gpu > 0 );
        this.getAllocatednodes();
    }

    changeSetting(param) {


        var self = this;
        switch (param) {
            case 'disabled' :
                this.uix.disabled = !this.uix.disabled;
                break;
            case 'allocator':
                break;
            case 'gpu':
                this.uix.resources.accelerators.gpu = this.gpu ? 1 : 0;
                break;
            // case 'settings':
            //     this.uix = this.editData;
            //     break;
        }
        this.saveApp(param);
    }

    saveApp(param) {
        this.saving = true;
        this.error = null;

        this.appController.update()
            .then(
                (r) => {
                    switch (param) {
                        case 'settings':
                            this.isEdit = false;
                            this.uix = _.findWhere(this.appController.data.Configuration.spec.uix, {name: this.uix.name});
                            // this.uix = this.editData;
                            break;
                    }
                },
                (err) => {
                    switch (param) {
                        case 'disabled' :
                            // this.uix.disabled = !this.uix.disabled;
                            break;
                        case 'allocator':
                            break;
                        case 'gpu':
                            this.gpu = !this.gpu;
                            this.uix.resources.accelerators.gpu = this.gpu ? 1 : 0;
                            break;
                    }
                    // this.error = err;
                    this.ErrorService.errorDialog(err);
                }
            )
            .finally( () => {
                this.saving = false;
            });
    }

    asForm() {
        if(!this.isEdit) {
            this.editData = angular.copy(this.uix);
        }
        this.isEdit = !this.isEdit;
    }

    cancel() {
        _.extend(this.uix, this.editData);
        this.asForm();
    }

    getAllocatednodes() {
        var self = this;
        this.resource.allocatednodes({application: this.appController.data.Name})
            .$promise
            .then(
                function (r) {
                    self.Allocatednodes = r;
                    if(_.isArray(r) && r.length)
                        self.Allocatednodes = [''].concat(r);
                },
                function (err) {
                    self.$scope.$emit('api_error', err);
                }
            )
    }

}

export const ApplicationUixController = {
    bindings: {
        uix: "=",
        appController: "<"
    },
    controller: controller,
    template: `
        <div layout="row" layout-align="end center" class="">
            <md-input-container ng-if="!$ctrl.uix.disabled">
                <label>Node allocator</label>
                <md-select ng-model="$ctrl.uix.nodes" ng-change="$ctrl.changeSetting('allocator')" ng-disabled="!$ctrl.Allocatednodes || $ctrl.Allocatednodes.length == 0">
                    <md-option ng-value="item" ng-repeat="item in $ctrl.Allocatednodes">{{item}}</md-option>
                </md-select>
            </md-input-container>
            <md-input-container ng-if="!$ctrl.uix.disabled" >
                <md-checkbox ng-change="$ctrl.changeSetting('gpu')" ng-model="$ctrl.gpu" style="margin: 0" >GPU</md-checkbox>
            </md-input-container>
            <md-button ng-click="$ctrl.changeSetting('disabled')" >
                <md-tooltip md-direction="top">{{'MLAPP_UIX_DISABLE' | translate}}</md-tooltip>
                <md-icon md-svg-icon="core:edit"></md-icon>
                {{$ctrl.uix.disabled ? 'ENABLE' : 'DISABLE' | translate}}
            </md-button>
            <md-button  ng-if="!$ctrl.uix.disabled" ng-click="$ctrl.Utils.openWindow($ctrl.uix.front_api)" target="_blank">
                <md-tooltip md-direction="top">{{'COMMON_OPEN_NEW_TAB' | translate}}</md-tooltip>
                <md-icon md-svg-icon="core:openInNew"></md-icon>
                {{'COMMON_OPEN_NEW_TAB' | translate}}
            </md-button>
            <md-button  ng-click="$ctrl.asForm()" class="md-icon-button" ng-disabled="$ctrl.isEdit">
                <md-tooltip md-direction="top">{{'SETITNGS_COMMON_TITLE_PAGA' | translate}}</md-tooltip>
                <md-icon md-svg-icon="core:settings"></md-icon>
                <!--{{'SETITNGS_COMMON_TITLE_PAGA' | translate}}-->
            </md-button>
            
        </div>
        <div ng-if="!$ctrl.isEdit">
        
            <div class="md-padding" ng-if="$ctrl.error" ><cmp-error-toast error="$ctrl.error"></cmp-error-toast></div>
            <md-card ng-if="$ctrl.uix.disabled && $ctrl.uix.disabledReason"><md-content class="md-padding">
                <h4>Component disabled</h4>
                Reason: {{$ctrl.uix.disabledReason}}
            </md-content></md-card>
            <cmp-iframe link="$ctrl.uix.front_api" ng-if="!$ctrl.uix.disabled"></cmp-iframe>
        </div>
        <div ng-if="$ctrl.isEdit">
            <form ng-submit="$ctrl.changeSetting('settings')">
                <h4>Settings</h4>
                <md-content class="md-padding">
                    <cmp-applications-task application="$ctrl.appController.data" data="$ctrl.uix" is-uix="1" ></cmp-applications-task>
                    <div flex layout="row" layout-align="center center">
                        <div flex>
                            <md-progress-linear ng-if="$ctrl.saving"></md-progress-linear>
                            <cmp-api-error-service ng-if="$ctrl.sendErrorMessage" error="$ctrl.sendErrorMessage"></cmp-api-error-service>
                        </div>
                        <div>
                            <md-button ng-click="$ctrl.cancel()" ng-disabled="$ctrl.saving"><md-icon md-svg-icon="core:close"></md-icon> Cancel</md-button>
                            <md-button type="submit" class="md-raised md-primary" ng-disabled="$ctrl.saving "><md-icon md-svg-icon="core:check"></md-icon> Save</md-button>
                        </div>
                    </div>
                </md-content>
            </form>
        </div>
    `
};
