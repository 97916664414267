export class CatalogListController {
    constructor($state, CatalogService, $scope, $rootScope, UserConfigService) {
        this.$state = $state;
        this.$scope = $scope;
        this.$rootScope = $rootScope;
        this.CatalogService = CatalogService;
        this.page = 1;
        this.UserConfigService = UserConfigService;
        this.catalogListView = this.UserConfigService.getConfig('catalogListView');
        this.isCatalog = $state.includes('catalog');

    }

    $onInit() {
        this.search = this.$state.params.search;
        this.page = this.$state.params.page || 1;
        this.limit = this.$state.params.limit || 30;

        this.listOrder = this.UserConfigService.getConfig('catalogListOrder') || 'stars';
        this.listDesc = this.UserConfigService.getConfig('catalogListDesc') || false;

        this.$scope.$watch(
            () => this.listOrder,
            () => {
                this.UserConfigService.setConfig('catalogListOrder', this.listOrder);
                this.getList();
            }
        );
        this.$scope.$watch(
            () => this.listDesc,
            () => {
                this.UserConfigService.setConfig('catalogListDesc', this.listDesc);
                this.getList();
            }
        );

        var data = this.$state.params;
        if(this.interface){
            data.Interface = this.interface;
        }
        this.resource = this.CatalogService.getResource(data);
        var self = this;
        this.$scope.$watch(
            () => { return this.page; },
            (c) => {
                if( (!self.listState && c != self.$state.params.page) || (this.listState && c != 1) ){
                    let state = self.listState ? self.listState : self.$state.current.name;
                    self.$state.go(state, {page: c});
                }
            }
        );
        // this.getList();
    }

    find() {
        // debugger;
        if(this.listState) {
            this.$state.go(this.listState, {search: this.search});
        } else {
            this.$state.go(this.$state.current.name, {search: this.search}, {notify: false})
                .then(
                    () => {
                        // this.getList();
                    }
                );
        }
    }

    getList() {
        let keyword;
        this.error = null;
        this.loading = true;
        this.complite = false;


        // var data = _.pick(this.$state.params, 'page', 'limit', 'search', 'keyword');
        // data.page = this.page;
        // data.limit = this.limit;
        var data;
        data = {
            page: this.page,
            limit: this.limit
        };

        if ( keyword = this.$state.params.keyword ) {
            data.keyword = keyword;
        }
        if(this.search){
            data.search = this.search;
        } else {
            data.order = this.listOrder;
            data.desc = this.listDesc;
        }

        if(_.isEqual(this.lastDataQuery, data)) return false;
        this.lastDataQuery = data;


        this.resource.query(data).$promise
            .then(
                (r) => {
                    this.list = r;
                    this.$rootScope.$emit('can_inter');
                },
                (err) => {
                    this.error = err;
                }
            )
            .finally(() => {
                this.complite = true;
                this.loading = false;
            })
    }

    changeView() {
        this.catalogListView = this.UserConfigService.setConfig('catalogListView', this.catalogListView == 'grid' ? 'sequential' : 'grid');
    }
}

export const CatalogListComponent = {
    bindings: {
        itemState: '<',
        // listState: '<',
        // isCatalog: '<',
        interface: '<',
        multy: '<'
    },
    controller: CatalogListController,
    template : ($templateCache) => { return $templateCache.get('kuberlab/catalog/card/list.html');}
};
