export function LayoutPageContentList(LayoutService) {

    function link(scope) {
        LayoutService.hasSubMenu = true;
        scope.$on('$destroy', () => {
            LayoutService.hasSubMenu = null;
        });
    }

    return ({
        link: link,
        restrict: "E"
    });
}
