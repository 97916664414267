import {ControllerList} from '../../core/controller/list'

class controller extends ControllerList {
    constructor($scope, $state, ProjectStoragesService, WorkspacePermissions) {
        super();
        this.permissions = WorkspacePermissions;
        this.service = ProjectStoragesService;
        this.$scope = $scope;
        this.$state = $state;
        this.resource = this.service.getResource(_.extend({}, this.$state.params, {clusterName: this.$state.params.tabItem}));
        this.list;
        var self = this;
        $scope.$watch(
            function(){
                return self.service.list;
            },
            function (c) {
                if(c){
                    self.list = c;
                }
            }
        )
    }

    $onInit() {
        this.service.getList(true);
        // this.getNew();
    }

    getNew(){
        var newEl = new this.resource({nfs: {
            Path: '',
            Server: ''
        }});
        this.service.add(newEl);
    }

    setDefault(data) {
        // var _.findWhere(this.resource.list, data
        _.each(this.service.list, (v) => {
            if(v.Name != data.Name){
                v.Default = false;
            }
        })
    }

    changeList(data) {
        this.service.getList(true);
        //
        // this.service.add(data);
        // var self = this;
        // debugger;
        // _.delay(
        //     function(){
        //         self.getNew();
        //     }, 500);
    }

    edit() {
      const newEl = new this.resource({nfs: {
          Path: '',
          Server: ''
        }});

      this.list.push(newEl);
    }
}


export const ClusterStoragesComponent = {
    controller: controller,
    template: `
            <md-list>
                <cmp-cluster-storage ng-repeat="item in $ctrl.list | orderBy: 'DisplayName'" list-controller="$ctrl" data="item"></cmp-cluster-storage>
                <!--<cmp-cluster-storage data="$ctrl.new" list-controller="$ctrl" is-new-element="true"></cmp-cluster-storage>-->
            </md-list>
            <div flex layout="column" layout-align="center-end">
              <md-button class="md-raised md-primary md-button" flex ng-click="$ctrl.edit()">
                Add new storage
              </md-button>
            </div>
            
        `
};