
import {WsCardHomeController} from './card/ws_card_home_controller'
import {wsCardTitleController} from './card/ws_card_title_controller'



export const wsBaseStateConfig = {
    parent: 'content',
    data : {
        contentId : 'workspace',
        displayName: '{{workspace.DisplayName}}'
    },
    url : '/:wsId',
    abstract: true,
    resolve:{
        workspace : function (WorkspaceService, $stateParams, pageConfig){
            pageConfig.setContentId('workspace');
            pageConfig.setTitle('workspace ' + $stateParams.wsId);
            return WorkspaceService.workspace.get({wsId:$stateParams.wsId})
                .$promise

        },

        permissions: function (WorkspacePermissions, workspace, WorkspaceService) {
            WorkspaceService.currentWS = workspace;
            WorkspacePermissions.set(workspace);
            return WorkspacePermissions;
        },

        viewTypes: function($localStorage){
            $localStorage.$default({
                viewType:'devops'
            });
            return {
                devops: {
                    title: "Devops"
                },
                scientist: {
                    title: "Scientist"
                }
            }
        },

        projectViewTabs : function (projectPageTabs, projectPageViewTabs, $localStorage) {
            $localStorage.$default({
                projectViewType:'devops'
            });
            var view = projectPageViewTabs[$localStorage.projectViewType];
            var tabs = [];
            if(view && view.tabs){
                _.each(view.tabs, function (v) {    // controller: function(workspace) {
                    tabs.push(_.findWhere(projectPageTabs, {title: v}));
                })
            }

            return tabs;
        }
    },

    onEnter: (UserConfigService, $state, LayoutService, workspace) => {
        UserConfigService.setConfig('currentWS', workspace);
    },

    views : {
        // "@layout-page-content"    : {
        //     template: `
        //         <div class="contentPageTitle">
        //             <div class="main_width_greed inner" layout="row" layout-align="start center">
        //               <div ui-view="title" flex></div>
        //               <div ui-view="sub-title"></div>
        //             </div>
        //         </div>
        //         <div ui-view flex layout="column" class="main_width_greed wsContent contentPageText"></div>
        //     `
        // },

        // "menuLevel2@" : {
        //     controller: function(workspace, $state) {
        //         this.workspace = workspace;
        //         this.$state = $state;
        //         this.listState = {
        //             'Projects' : 'wsApplicationsList',
        //             'Serving': 'wsServingList',
        //             'Infrastructure': 'wsProjectsList',
        //             'K-storage': 'wsStorages',
        //             'Shared clusters': 'wsClusters',
        //             // 'Catalog' : 'wsCatalogMain'
        //             'Catalog' : 'wsCatalog'
        //         };
        //     },
        //     controllerAs: "$ctrl",
        //     template: "<cmp-workspace-main-menu states-list='$ctrl.listState' workspace='$ctrl.workspace' state='$ctrl.$state'></cmp-workspace-main-menu>"
        // }
    }
};


export default function ($stateProvider) {
    $stateProvider

        .state('workspace', {
            parent: 'wsBase',
            url : '',
            views : {
                "layout-page-content@" : {
                    templateProvider: function ($templateCache) {
                        return $templateCache.get('kuberlab/workspace/card/ws_card_home.html');
                    },
                    controller: WsCardHomeController,
                    controllerAs: '$ctrl'
                }
            }
        })

}
