class AuthErrorContoller {
    constructor(AuthService) {
        // this.AuthService = AuthService;
        // this.ServiceList = AuthService.ServiceList();
        // this.invite;
        // var self = this;
        // this.resounce = AuthService.oautherror;
        // this.resounce.$get().$promice.then(
        //     function (r) {
        //         self.errorResponce
        //     },
        //     function (err) {
        //         debugger;
        //     }
        // )
    }
    
    // login (service) {
    //     this.AuthService.authInService(service, this.invite)
    // }
    //
    // getIcon (name) {
    //     return this.AuthService.getIcon(name);
    // }
}

export const AuthErrorComponent = {
    bindings: {
        authError: '<'
    },
    template: function ($templateCache) {
        return $templateCache.get('kuberlab/auth/auth_error_component.html');
    },
    controller: AuthErrorContoller
};