class Controller {
    constructor(RepoService, AuthService, $scope, $mdDialog) {
        // debugger;
        this.$mdDialog = $mdDialog;
        this.reposProvider = null;
        this.reposOwner = null;
        this.reposService = RepoService;
        this.authService = AuthService;

        this.reposResource = this.reposService.getResource();
        this.reposOwners = null;
        this.reposOwnersLoading = false;
        this.reposOwnersError = null;
        this.reposOwnersShow = false;
        this.reposList = null;
        this.reposListLoading = false;
        this.reposListError = null;
        this.reposListShow = false;

        this.repositoryUrl;
        this.repositoryDir;

        var self = this;

        AuthService.ServiceList()
            .then(function (r) {
                // self.ServiceList = r;
                self.listProviders = _.where(r, {Repo: true});
            }, function (err) {
            });

        $scope.$watch(
            function () {
                return self.reposProvider;
            },
            function (c) {
                if(c) self.loadOwners();
            }
        );

        // $scope.$watch(
        //     function () {
        //         return self.reposOwner;
        //     },
        //     function (c) {
        //         // debugger;
        //         if(c != null)
        //             self.loadRepos();
        //     }
        // );

        $scope.$watch(
            function () {
                return self.reposOwner;
            },
            function (c) {
                if(c != null) self.loadRepos()
            }
        );


        $scope.$watch(
            function () {
                return self.repositoryUrl;
            },
            function (c) {
                if(c && self.env !== undefined)
                    self.loadBranches()
            }
        );
    }

    loadOwners () {
        var self = this;
        this.reposOwnersLoading = true;
        this.reposOwnersError = null;
        this.reposOwnersShow = false;
        this.reposListShow = false;
        this.reposListError = null;
        this.reposOwner = null;
        this.repositoryUrl = null;
        this.reposResource.orgs({provider: this.reposProvider})
            .$promise
            .then(
                function(resp) {
                    self.reposOwnersShow = true;
                    self.reposOwners = resp;
                    if((!self.reposOwner || !self.reposOwner.length) && self.reposOwners.length ){
                        self.reposOwner = _.first(self.reposOwners).name;
                        // self.loadRepos();
                    }
                    // if(self.reposOwners.length == 1)
                    //     self.reposOwner = _.first(self.reposOwners).name;
                },
                function(err) {
                    self.reposOwnersShow = false;
                    self.reposOwnersError = err;
                }
            )
            .finally(
                function () {
                    self.reposOwnersLoading = false;
                }
            );
    }

    loadRepos () {
        // debugger

        var self = this;
        this.reposListLoading = true;
        this.reposListError = null;
        this.reposListShow = false;
        this.repositoryUrl = null;
        var data = {provider: this.reposProvider};
        var action = 'repos';
        if(this.reposOwner) {
            data.owner = this.reposOwner;
            action = 'orgrepos';
        }
        this.reposResource[action](data)
            .$promise
            .then(
                function(resp) {
                    self.reposListShow = true;
                    self.reposList = resp;
                    if(self.reposList.length == 1)
                        self.repositoryUrl = _.first(self.reposList).url;
                },
                function(err) {
                    self.reposListShow = false;
                    self.reposListError = err;
                    self.reposList = [];
                }
            )
            .finally(
                function () {
                    self.reposListLoading = false;
                }
            );
    }

    loadBranches() {
        var repo = _.findWhere(this.reposList, {url: this.repositoryUrl});
        if(this.reposProvider && repo && repo.owner &&repo.name) {
            var self = this;
            this.branchListLoading = true;
            this.branchListError = null;
            this.branchListShow = false;
            this.reposResource.branches({provider: this.reposProvider, owner: repo.owner, repo: repo.name})
                .$promise
                .then(
                    function (resp) {
                        self.branchListShow = true;
                        self.branchList = resp;
                        if (resp.length == 1) {
                            self.env = _.first(resp).name
                        }
                    },
                    function (err) {
                        self.branchListShow = false;
                        self.branchListError = err;
                        self.branchList = [];
                    }
                )
                .finally(function () {
                    self.branchListLoading = false;
                });
        }
    }

    connectProvider() {
        this.authService.authInService(this.reposProvider, null, 'repository');
    }

    changeEnv(){
        debugger;
    }

}


export const RepoSelectWizardComponent = {

    controller : Controller,

    bindings : {
        'repositoryUrl': '=',
        'repositoryDir' : '=',
        'env': '='
    },

    template : `
    <!--<h4>Select repository and repository dir</h4>-->
    
    <div layout-wrap layout="row" layout-align="start top">
   
        <md-input-container flex="50">
            <md-select ng-model="$ctrl.reposProvider" placeholder="Provider" ng-change="$ctrl._loadOwners()">
                <md-option ng-value="rPr.Name" ng-repeat="rPr in $ctrl.listProviders">
                    <md-icon md-svg-icon="services:{{ rPr.Name }}" class="ng-scope"></md-icon> {{ "SERVICE_NAME." + rPr.Name | translate }}
                 </md-option>
            </md-select>
            <help-hint hint="repo.provider"></help-hint>
        </md-input-container>

        <md-input-container flex="50">
            <label>Repository owner</label>
            <md-select ng-disabled="!$ctrl.reposOwnersShow" ng-model="$ctrl.reposOwner" >
                <md-option ng-value="owner.name" ng-repeat="owner in $ctrl.reposOwners">{{ owner.display_name}}</md-option>
            </md-select>
            <help-hint hint="repo.owner"></help-hint>
            <md-progress-linear ng-if="$ctrl.reposOwnersLoading"></md-progress-linear>
            <p ng-if="$ctrl.reposOwnersError">{{ $ctrl.reposProvider }} is not connected. <a href ng-click="$ctrl.connectProvider()">Connect?</a></p>
        </md-input-container>
        <div class="layout-row layout-align-start-center" style="width: 100%;">
            <md-input-container flex>
                <label translate>REPO_NAME</label>
                <md-select 
                        ng-change="$ctrl._loadBranches()" 
                        ng-disabled="!$ctrl.reposListShow" 
                        name="RepositoryURL" 
                        ng-model="$ctrl.repositoryUrl" 
                        >
                    <md-option ng-value="repo.url" ng-repeat="repo in $ctrl.reposList | orderBy:['owner', 'display_name'] ">{{ repo.owner }} : {{ repo.display_name }}</md-option>
                    <md-option ng-if="$ctrl.reposList && !$ctrl.reposList.length" ng-disabled="true" translate>COMMON_LIST_EMPTY</md-option>
                </md-select>
                <help-hint hint="repo.url"></help-hint>
                <md-progress-linear ng-if="$ctrl.reposListLoading"></md-progress-linear>
                <p ng-if="$ctrl.reposListError">Error loading {{ $ctrl.repoProvider }} repositories.</p>
            </md-input-container>
            
            <md-input-container flex ng-if="$ctrl.env !== undefined">
                <label>Environment</label>
                <md-select
                        ng-change="$ctrl._changeEnv()"   
                        ng-disabled="!$ctrl.repositoryUrl"
                        name="Environment"
                        ng-model="$ctrl.env"
                        >
                    <md-option ng-value="br.name" ng-repeat="br in $ctrl.branchList">{{br.name}}</md-option>
                </md-select>
                
                <help-hint hint="repo.environment"></help-hint>
                <md-progress-linear ng-if="$ctrl.branchListLoading"></md-progress-linear>
                <p ng-if="$ctrl.branchListError">Error loading environmen.</p>
            </md-input-container>
            
            <!--<md-input-container flex>-->
                <!--<label>Repository Dir</label>-->
                <!--<input type="text" ng-disabled="!$ctrl.repositoryUrl" name="RepositoryDir" ng-model="$ctrl.repositoryDir">-->
                <!--<help-hint hint="repo.dir"></help-hint>-->
            <!--</md-input-container>-->
        </div>
    </div>
    `
};
