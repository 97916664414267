import {CatalogItemController} from './catalog_item_controller'

export const stateCatalogConfig = {
    main: {
        parent: 'content',
        url : '/catalog',
        views: {
            '' : {
                templateProvider: ($templateCache) => { return $templateCache.get('kuberlab/catalog/card/main.html');  },
                controller: function (listState) { this.listState = listState;},
                controllerAs: '$ctrl'
            }
        }
    },

    list: {
        url: '/:Interface?:search:page:limit:type:keyword',
        params: {
            limit: '30',
            page: '1'
        },
        resolve: {
            catalog_dataResource: (CatalogService, $stateParams) => {
                return CatalogService.getResource($stateParams);
            },
            isCatalog: () => { return true; },
            // addBtn : function ($rootScope, $stateParams, UserService) {
            //     // this.ws = UserService.ws;
            //     // debugger;
            //     UserService.getWs()
            //         .then((ws) => {
            //             $rootScope.addButtonConfig = _.map(UserService.ws, (v) => {
            //                 return {
            //                     title: 'Add to ' + v.DisplayName,
            //                     img: v.Picture,
            //                     // interface: v.Interface,
            //                     state: {
            //                         name: 'wsCatalog.add',
            //                         params: {Interface: $stateParams.Interface, wsId: v.Name}
            //                     }
            //                 }
            //             })
            //         });
            // }
        },
        views: {
            // "" : {
            "layout-page-content@" : {
                template: `<cmp-catalog-list item-state="$ctrl.itemState" is-catalog="$ctrl.isCatalog"></cmp-catalog-list>`,
                controller: function (itemState, isCatalog) {
                    this.itemState = itemState;
                    this.isCatalog = isCatalog;
                },
                controllerAs: '$ctrl'
            }
        }
    },

    add: {
        // url: '/:Interface/create_new',
        url: '/create_new',
        views: {
        "layout-page-content@" : {
                template: `create new`
            }
        }
    },

    item: {
        // url: '/:Interface/:item',
        url: '/:item',

        views: {
            // "" : {
            "layout-page-content@" : {
                templateProvider: ($templateCache) => { return $templateCache.get('kuberlab/catalog/card/card_item.html');  },
                controller: CatalogItemController,
                controllerAs: '$ctrl'
            }
        }
    }
}
