import {UserCardHeaderComponent} from './card/user_card_header'
import {UserCardBigComponent, UserCardBigNewComponent} from './card/user_card_big_component'
import {UserCardHomeComponent} from './card/user_card_home'
import {UserService} from './user_service'
import {UserPassword} from './user_password'
import UserToken from './token/user_token_module'
import {UserConfigService} from './user-config-service'


// 2.0.0
import {UserProjectMenuComponent} from './2.0.0/user_project_menu'




export default angular
    .module('user', [
        UserToken.name
    ])
    .service('UserService', UserService)
    .service('UserConfigService', UserConfigService)
    .component('cmpUserCardHeader', UserCardHeaderComponent)
    .component('cmpUserCardBig', UserCardBigComponent)
    .component('cmpUserCardBigNew', UserCardBigNewComponent)
    .component('cmpUserCardHome', UserCardHomeComponent)
    .component('cmpUserPassword', UserPassword)



// 2.0.0
    .component('cmpUserProjectMenu', UserProjectMenuComponent )
