import  {controllerList} from '../../applications/volumes/applications_volumes_component'

class listController extends controllerList {
    constructor ($scope, ProjectStoragesService, ErrorService) {
        super(...arguments);
        // this.ErrorService = ErrorService;
        // this.$scope = $scope;
        // this.ProjectStoragesService = ProjectStoragesService;
    }

    save(data) {
        var i =_.indexOf(this.list, _.findWhere(this.list, {$$hashKey: data.$$hashKey}));

        if( i != -1) {
            this.list[i] = data;
        }

        // this.applicationSave(data);
    }

    add (data) {
        if( !_.isObject(this.list) ) this.list= [];
        var d = angular.copy(data);
        this.list.push(d);
        // this.setIsTrainLogDir(d);
        // this.applicationSave(d, "add");
        this.Source = null;
    }

    remove (data, save = true) {
        this.list = _.without(this.list, data);
    }

}

export const ServingVolumeListComponent = {
    bindings:{
        list: "=",
        data: "=",
        serving: "=",
        workspaceName: '<'
    },
    controller: listController,
    template: function ($templateCache) {
        return $templateCache.get('kuberlab/serving/volumes/list.html');
    }
}