import {CatalogBaseItemController} from './base/item_controller'
import {InstallChart, InstallChartTemplate} from '../charts/install/install_chart'
import {AuthRequestTemplateComponent, AuthRequestController} from '../auth/auth_request_component'

export class CatalogItemController extends CatalogBaseItemController{
    constructor (
            // itemState, listState,
                 catalog_data, pageTabs, catalog_dataResource, CatalogService, $scope, permissions, $state) {
        super();

        this.isCatalog = $state.includes('catalog');
        this.permissions = permissions;
        this.canManage = permissions.can('mlapp.manage');
        this.data = catalog_data;
        this.Service = CatalogService;
        this.resource = CatalogService.getResource(this.$state.params) ; // catalog_dataResource;
        this.$scope = $scope;
        this.isLoading = {};
        this.loadingError = {};
        this.pageTabs = pageTabs;
        if(!this.$state.params.tab) this.$state.go('.tab');
        this.fullConfiguration = false;
    }

    $onInit() {
        super.$onInit();
        switch (this.data.Interface) {
            case "chart-app":
                this.dialogTitle = 'CHART_DELETE_DIALOG_TITLE';
                this.dialogMessage = 'CHART_DELETE_DIALOG_MESSAGE';
                break;
            case "chart-mlapp-v2":
                this.dialogTitle = 'APPLICATION_DELETE_DIALOG_TITLE';
                this.dialogMessage = 'APPLICATION_DELETE_DIALOG_MESSAGE';
                break;
            case "mlmodel":
                this.dialogTitle = 'MLMODEL_DELETE_DIALOG_TITLE';
                this.dialogMessage = 'MLMODEL_DELETE_DIALOG_MESSAGE';
                break;
            case "dataset":
                this.dialogTitle = 'DATASET_DELETE_DIALOG_TITLE';
                this.dialogMessage = 'DATASET_DELETE_DIALOG_MESSAGE';
                break;

        }
        // this.itemState = this.itemState ? this.itemState : '.item';
        // debugger;
        // this.link =  this.itemState + '({wsId: $ctrl.data.WorkspaceName, Interface:$ctrl.data.Interface, item : $ctrl.data.Name})'
    }

    successDelete(r) {
        // debugger
        this.$state.go('wsCatalog.list');
        // this.$state.go(this.listState, {Interface: this.$state.params.Interface});
    }

    prepareSaveData() {
        debugger
        if(this.data.Interface == 'mlmodel') {
            this.old_data = null;
            if( !this.fullConfiguration ) {
                this.old_data = this.data;
                this.data.Config = null;
            }
        }
    }
}


export class CatalogItemChartController extends CatalogItemController{
    constructor (
        // itemState,
        // listState,
        catalog_data, pageTabs, catalog_dataResource, CatalogService, $scope, permissions, $state, CU) {
        super(...arguments);
        this.CU = CU;
        this.isChart = true;
    }

    install(ev, name, version) {
        name = name || this.data.Name;
        version = version || this.data.Version;
        // debugger;
        this.Service.getResource(this.$state.params);
        this.Service.installChart(name, version);
    }
}




export const CatalogItemComponent = {
    controller: CatalogItemController,
    template: ($templateCache) => { return $templateCache.get('kuberlab/catalog/base/card/card_item.html');  },
};

export class CatalogItemSmallController extends CatalogBaseItemController{
    constructor () {
        super(...arguments);
    }

    // $onInit() {
    //     debugger;
    //     super.$onInit();
    //
    // }
}

export const CatalogItemSmallComponent = {
    bindings: {
        data: '=',
        itemState: '<',
        listState: '<',
        resource: '<',
        multy: '<'
    },
    controller: CatalogItemSmallController,
    template: ($templateCache) => { return $templateCache.get('kuberlab/catalog/card/item_small.html');  },
};
