import {CoreServiceResource} from '../core/service/core_service_resource'

export class BillingService extends CoreServiceResource {
    constructor ($resource, $q, KuberlabConfigApi, $rootScope){
        super(...arguments);
    }

    getResource(params, clear) {
        var resource;
        if (!this._resource || clear){
            var apiPath = this.cfg.APIPath;
                apiPath = apiPath + '/billing/:action';

            resource = this.$resource(
                apiPath,
                {action: '@action'},
                _.extend(
                    {},
                    this.cfg.ResourceParams,
                    {
                        'plans' : {
                            isArray: true,
                            method : 'GET',
                            params: {action: 'plans'},
                        },
                        'cost' : {
                            isArray:true,
                            method : 'GET',
                            params: {action: 'cost'},
                        }
                    }

                )

            );
            if(!clear){

                this.list = null;
                this._resource = resource;
            }
        }
        return resource || this._resource ;
    }

    getPlans() {
        if(!this._plansPromise){
            this._plansPromise = this.getResource().plans().$promise
        }
        return this._plansPromise ;
    }

    getCost() {
        if(!this._costPromise){
            this._costPromise = this.getResource().cost().$promise
        }
        return this._costPromise ;
    }
}