import {CoreServiceResource} from '../core/service/core_service_resource'



export class WorkspaceService extends CoreServiceResource {
    constructor($resource, $q, KuberlabConfigApi, $rootScope) {
        super(...arguments);
        this.workspace = $resource(
                KuberlabConfigApi.APIPath + '/workspace/:wsId',
                {wsId: '@ID'},
                _.extend({}, KuberlabConfigApi.ResourceParams, {

                })
        );
        this.workspacename = $resource(KuberlabConfigApi.APIPath + '/name/isavailable',
            {},
            KuberlabConfigApi.ResourceParams
        );
    }

    getResource(params, clear){
        if(!this._resource || clear || this.params.wsId != params.wsId){
            var url = this.cfg.APIPath + '/workspace/' + params.wsId;
            this.clear();
            this._resource = this.$resource(
                url,
                {wsId: '@ID'},
                _.extend({}, this.cfg.ResourceParams, {
                    clusters: {
                        method: "GET",
                        url: url + '/clusters',
                        isArray: true
                    },
                    customer: {
                        method: 'GET',
                        url: url + '/customer'
                    },
                    customerAdd: {
                        method: 'POST',
                        url: url + '/customer'
                    },
                    unsubscribe: {
                      method: 'DELETE',
                      url: url + '/customer'
                    },
                    invoices: {
                      isArray: true,
                      method: 'GET',
                      url: url + '/customer/invoices'
                    },
                    payments: {
                      isArray: true,
                      method: 'GET',
                      url: url + '/customer/payments'
                    },
                    usage: {
                      // isArray: true,
                      method: 'GET',
                      url: url + '/customer/usage'
                    },
                    dashboard_active_tasks: {
                      isArray: true,
                      method: 'GET',
                      url: url + '/dashboard/active-tasks'
                    },
                    dashboard_applications: {
                      isArray: true,
                      method: 'GET',
                      url: url + '/dashboard/applications'
                    },
                    dashboard_servings: {
                      isArray: true,
                      method: 'GET',
                      url: url + '/dashboard/servings'
                    },
                    dashboard_cluster_stats: {
                      isArray: true,
                      method: 'GET',
                      url: url + '/dashboard/cluster-stats'
                    },

                })
            );
            this.params = _.clone(params);
        }
        return this._resource;
    }

    clear() {
        this._resource = null;
        this._clustersPromise = null;
    }

    clusters(params, clear){
        var resource = this.getResource(...arguments);
        // debugger
        if(clear) {
            this._clustersPromise = null;
        }
        if(!this._clustersPromise ) {
            this._clustersPromise = resource.clusters().$promise;
        }
        return this._clustersPromise;
    }

    Serviceacconts (wsId) {
        return this.$resource(this.cfg.APIPath + '/workspace/' + wsId + '/serviceaccounts/:saId',
            {saId : '@ID'},
            this.cfg.ResourceParams);
    }
}


