class InvoiceController {
    constructor(WorkspaceService, $mdDialog, NgTableParams, BillingService) {
        this.$mdDialog = $mdDialog;
        this.WorkspaceService = WorkspaceService;
        this.NgTableParams = NgTableParams;
        this.BillingService = BillingService;


    }

    $onInit() {
        this.BillingService.getPlans().then((r) => {
                this.plans = r;
            }, (err) => {
            }
        );
        this.BillingService.getCost().then((r) => {
                this.cost = r;
            }, (err) => {
            }
        );
        this.wsResource = this.WorkspaceService.getResource({wsId: this.wsId});
        var self = this;
        this.wsResource.invoices().$promise.then(
            (r) => {
                self.invoicesList = r;
                self.tableData = new self.NgTableParams({}, { dataset: self.invoicesList});
            },
            (err) => {
                self.error = err;
            }
        );
    }

    usage (item) {
        this.usageDialog(item.Data);
    }

    usageDialog(data) {
        this.$mdDialog.show({
            locals: {
                data: data,
                error: this.usageError,
                dialog: this.$mdDialog,
                plans: this.plans,
                cost: this.cost
            },
            controller: function (data, error, dialog, plans, cost) {
                this.plans = plans;
                this.cost = cost;
                this.data = data;
                this.error = error;
                this.$mdDialog = dialog;
                this.getVolume = function (item, type){
                    var v = _.findWhere(item.metrics, {type: type});
                    return v ? v.value : "";
                };
                this.planName = (id) => {
                    var plan = _.findWhere(this.plans, {ID: id});
                    return plan ? plan.Name : id;
                };

                this.getCost = (resource) => {
                    return _.findWhere(this.cost, {Resource: resource});
                };

                this.more = (item) => {
                    if(item.res && item.res.length) {
                        item.more = !!!item.more;
                    }
                };

                this.getResource = (type, label) => {
                    var r = [];
                    _.each(this.data.Resources, (v) => {
                        var metrics = _.where(v.metrics, {type: type});
                        var addLabel, add;
                        // debugger;
                        _.each(metrics, (m) => {
                            if(m.labels && _.invert(m.labels)[label]){
                                addLabel = m;
                            } else if( !m.labels ) {
                                add = m;
                            }
                        });

                        if( addLabel || add ) {
                            var mm = addLabel || add;
                            r.push(_.extend({}, v, {value: _.clone(mm).value}))
                        }
                    });
                    return r;
                };

                _.each(this.data.Items, (v) => {
                    if(v.Resource){
                        v.res = this.getResource(v.Resource, v.ResourceLabel);
                        v.cost = _.findWhere(this.cost, {Resource: v.Resource});
                    }
                });

                this.title = []; // [{name: application}];
                if(this.data){
                    _.each(this.data.metrics, (v, k) => {
                        this.title.push({name: v.type});
                    });
                }
            },
            controllerAs: "$ctrl",
            template: usageTemplate,
            parent: angular.element(document.body),
            clickOutsideToClose:true,
        });
    }

}

var usageTemplate = `
<md-dialog aria-label="Mango (Fruit)">
    <md-toolbar>
      <div class="md-toolbar-tools">
        <h2 translate>COMMON_DETAIL</h2>
        <span flex></span>
        <md-button class="md-icon-button" ng-click="$ctrl.$mdDialog.cancel()">
          <md-icon md-svg-src="core:close" aria-label="Close dialog"></md-icon>
        </md-button>
      </div>
    </md-toolbar>

    <md-dialog-content>
      <div class="md-dialog-content">
      <div ng-if="!$ctrl.data.Items" style="text-align: center">
            no resource invoice
        </div>
      <table class="table active-table" ng-if="$ctrl.data.Items">
        <thead>
            <th>#</th>
            <th>Amount</th>
            <th>Quantity</th>
            <th>Prepaid</th>
        </thead>
        
        <tbody ng-repeat="item in $ctrl.data.Items">
            <tr ng-click="$ctrl.more(item)">
              <td >
                <span ng-if="item.Plan">Plan {{$ctrl.planName(item.Plan)}}</span> 
                <span ng-if="item.Resource">{{"BILLING_RESOURCE_NAME_" + item.MeasuredResource | translate}}<span ng-if="item.ResourceLabel" style="margin-left:1em" translate>{{item.ResourceLabel}}</span> {{"BILLING_RESOURCE_UNIT_" + item.MeasuredResource | translate}}</span>
                <span ng-if="item.ExtraUser">{{"BILLING_RESOURCE_EXTRA_USER" | translate}}</span>
              </td>
              <td><md-icon md-svg-icon="core:{{item.Currency}}"></md-icon>{{+item.Amount.toFixed(2)}} 
                <md-icon md-svg-icon="core:hint" ng-if="item.MeasuredCost">
                    <md-tooltip md-direction="bottom" class="help-hint"><span ng-if="item.MeasuredPrepaid">({{+item.MeasuredQuantity.toFixed(4)}} - {{+item.MeasuredPrepaid.toFixed(4)}})</span><span ng-if="!item.MeasuredPrepaid">{{+item.MeasuredQuantity.toFixed(4)}}</span> * {{item.MeasuredCost}} per {{"BILLING_RESOURCE_UNIT_" + item.MeasuredResource | translate}}</md-tooltip>
                </md-icon>
              </td>
              <td>{{item.MeasuredQuantity ? +item.MeasuredQuantity.toFixed(4) : item.Quantity ? +item.Quantity.toFixed(4) : "-" }}</td>
              <td>{{item.MeasuredPrepaid ? +item.MeasuredPrepaid.toFixed(4) : item.Prepaid ? +item.Prepaid.toFixed(4) : "-" }}</td>
            </tr>
            <tr ng-if="item.more" >
                <td colspan="4" align="right">
                  <table class="table">
                    <tr ng-repeat="res in item.res">
                        <!--<td>{{res.app_name || res.storage_name}}</td>-->
                        <td>{{res.res_type}} {{res.res_name || res.storage_name}}</td>
                        <td>{{res.value}} {{"BILLING_RESOURCE_UNIT_" + item.Resource | translate}}</td>
                      </tr>
                  </table>
                </td>
            </tr>
        </tbody>
      </table>

  </div>
  </md-dialog-content>
  </md-dialog>

`;

export const InvoiceWsComponent = {
    bindings: {
        wsId: '<',
        plans: '<'
    },
    controller: InvoiceController,
    template: function($templateCache) {
        return $templateCache.get('kuberlab/billing/ws/invoice_ws_component.html')
    }
};
