
import {ControllerList} from '../core/controller/list'

export class ServingListController extends ControllerList {
    constructor (ServingService, $scope, UserConfigService, WorkspaceService) {
        super();
        var inject = angular.element(document.body).injector();
        this.storage = inject.get('$localStorage');
        this.$state = inject.get('$state');
        this.permissions = inject.get('WorkspacePermissions');
        this.$state = inject.get('$state');
        this.UserConfigService = UserConfigService;
        this.WorkspaceService = WorkspaceService;

        this.$scope = $scope;
        this.listEmpty = false;
        this.Service = ServingService;

        this.$scope.$watch(
            () => { return this.Service.list },
            (c) => {
                if( !this.Recent ) this.list = c;
            }
        );

        let occ = this.UserConfigService.getWsItemConfig(this.$state.params.wsId, 'orderServ', 0, 'order') || {};
        this.orderContainersCount = ( _.isUndefined(occ.orderContainersCount) || occ.orderContainersCount ) ? true: false;
        this.orderParam = occ.orderParam
            ? occ.orderParam
            : {
                Name : "Name",
                ContainersCount: "-ContainersCount"
            };
        this.filter = occ.filter ? occ.filter : {};
        this.orderListPrams = [];
        this.orderWithCount();

        this.Recent =  this.UserConfigService.getWsItemConfig(this.$state.params.wsId, 'recentServ', 0, 'recent');
        this.watchWS('servings', this.ws_servings);
    }

    orderWithCount() {
        this.orderParam.ContainersCount = this.orderContainersCount ? '-ContainersCount' : null;
        this.orderListPrams = this.orderParam.ContainersCount
            ? [this.orderParam.ContainersCount, this.orderParam.Name]
            : [ this.orderParam.Name];
        this.saveFilter();
    }

    saveFilter() {
        this.UserConfigService.setWsItemConfig(this.$state.params.wsId, 'orderServ', 0, 'order', {orderParam : this.orderParam, orderContainersCount : this.orderContainersCount, filter: this.filter});
    }

    changeResent() {
        this.UserConfigService.setWsItemConfig(this.$state.params.wsId, 'recentServ', 0, 'recent', this.Recent);
        this.getList(true);
    }

    order (param) {
        this.orderParam[param] = this.orderParam[param] == param ? '-' + param : param;
        this.orderWithCount();
    }

    $onInit () {
        this.resource = this.Service.getResource(this.$state.params);
        this.getList(true);
    }

    getList (update, page, clear) {
        this.isLoading = true;
        this.ErrorList = false;
        if(this.Recent) {
            this.Service.getLastServ(this.$state.params.wsId)
                .then(
                    (r) => {
                        this.list = r;
                    },
                    (err) => {}
                )
                .finally(() => {
                    this.isLoading = false;
                })
        } else {

            var self = this;
            var data = {};

           ;
            this.listPromise = this.Service.getList(update, data, clear);
            this.listPromise
                .then(
                    (r) => {
                    },
                    (err) => {
                        this.ErrorList = err;
                    }
                )
                .finally(() => {
                    this.isLoading = false;
                });
        }
    }

    getServingItem(data) {
        if(this.$state.params.wsId == data.WorkspaceName && _.isArray(this.list)) {
            return _.findWhere(this.list, {Name : data.Name});
        }
    }

    ws_servings(event, data) {
        if(data.WorkspaceName == this.$state.params.wsId) {
            var i, item = _.findWhere(this.list, {Name: data.Name});
            if(item) {
                i = _.indexOf(this.list, item);
                if(i != -1){
                    this.list[i] =_.extend(this.list[i],  data);
                    this.$scope.$apply();
                    this.ServingService.updateListServ(this.$state.params.wsId, angular.copy(this.list));
                }
            }
        }
    }
}

export const ServingListConponent = {
    controller: ServingListController,
    template: ($templateCache) => { return $templateCache.get('kuberlab/serving/card/serving_list.html'); }
}

