
import {ControllerList} from '../core/controller/list'

export class SharedClusterListController extends ControllerList {
    constructor (SharedClusterService, $scope, $state) {
        super();
        this.SharedClusterService = SharedClusterService;

        this.$scope = $scope;
        this.$state = $state;

        var self = this;

        this.$scope.$watch(
            function () {
                return self.SharedClusterService.list
            },
            function (c) {
                if(c) {
                    self.list = c;
                }
            }
        );
    }

    $onInit() {
        this.getList();

    }

    getList(){
        this.resource = this.SharedClusterService.getResource({type: this.type});
        this.loading = true;
        this.error = false;

        this.SharedClusterService.getList(true, this.params)
            .then(
                (r) => {},
                (err) => {
                    this.error = err;
                }
                )
            .finally(() => {
                this.loading = false;
                this.complite = true;
            });
    }

}

export const SharedClusterListComponent = {
    bindings: {
        type: '<',
        params: '<'
    },
    controller: SharedClusterListController,
    template: function ($templateCache) {
        return $templateCache.get('kuberlab/shared/card/shared_cluster_list.html');
    }
}