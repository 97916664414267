
export class KuberlabController {
    constructor($scope, KuberlabService, CurrentUser, $rootScope, $state, pageConfig, Analytics, $mdToast, ErrorService, WS, Deb, $window, IntroService, $element) {
        var self = this;
        this.IntroService = IntroService;
        this.$mdToast = $mdToast;
        $scope.pageConfig = pageConfig;
        this.KuberlabService = KuberlabService;
        this.currentUser = false;
        this.currentUserLoaded = false;
        this.state = $state;
        this.Analytics = Analytics;
        this.ErrorService = ErrorService;
        this.$rootScope = $rootScope;
        this.$rootScope.windowFocus = true;
        this.GlobalSettings = window.GlobalSettings;
        if(this.GlobalSettings.title){
            document.title = this.GlobalSettings.title;
        }

        angular.element( $window ).on( "blur",
            () => {
                this.delayFocus = _.delay(() => {
                    this.$rootScope.windowFocus = false;
                } , 20000);

            }
        );

        angular.element( $window ).on( "focus",
            () => {
                clearTimeout(this.delayFocus);
                this.$rootScope.windowFocus = true;
            }
        );

        CurrentUser.then(
            function (r) {
                self.currentUser = r;
                if(window.GlobalSettings.google_analytics) Analytics.set('&uid', r.Login);
            }
        ).finally(
            function() {
                self.currentUserLoaded = true
            }
        );

        this.apiError = null;

        $scope.$on('api_error', function (event, error) {
            self.ErrorService.error(error);
        });

        $rootScope.$on('show_toast', function (event, data) {
            self.showToast(data.message, data.button);
        });

        this.extStyleLink = `/${window.uiRevCommit}/ext_style/main.css`;
        this.hasExtStyle = true;
    }

    showToast(message, button = 'UNDO', time = 5000 ) {
        var toast =
        this.$mdToast.simple()
            .textContent(message)
            .action(button)
            .highlightAction(true)
            .highlightClass('md-accent')// Accent is used by default, this just demonstrates the usage.
            .position('bottom right')
            .hideDelay(time);

        this.$mdToast.show(toast).then(function(response) {
            if ( response == 'ok' ) {
                // alert('You clicked the \'UNDO\' action.');
            }
        });
    }
}


export class KuberlabController_w {
    constructor ( $scope, CurrentUser) {
        var  self = this;
        this.currentUser = false;
        CurrentUser.then(
            function (r) {
                self.currentUser = r;
            }
        );

        this.rootScope.$on('currentOwner', function (event, data) {
            self.DashboardService.currentOwner = data;
            // self.currentOwner = data;
        });
    }

}
