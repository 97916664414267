 export class SettingsController {
    constructor(currentUser, SettingsService,  $scope, $state) {
        this.currentUser = currentUser;
        this.stateParams = SettingsService.stateParams;
        var self = this;
        $scope.$watch(function () {
            return SettingsService.stateParams;
            },
            function (current){
                self.stateParams = current;
            }
        );
        if($state.current.name == "settings")
            $state.go('settingsCurrentUser');
    }
 }