
import cfg from './resources_stateconfig'
export default angular
    .module('resources', [])
    .config(cfg)
    .component('cmpWsResourceMainPage', {
        bindings: {
            viewInfra: '<'
        },
        template: function ($templateCache) {
            return $templateCache.get('kuberlab/resources/main_page.html');
        },
        controller: function ($state) {
            this.$state = $state;
        }
    })