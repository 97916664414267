export class applicationEditTemplateController {
    constructor(){
        // this.appController = appController;
    }


    $onInit() {
        this.getTemplate();
    }

    getTemplate() {
        this.appController.applicationsResource.simply_template({application: this.appController.data.Name})
            .$promise
            .then(
                (r) => {
                    this.template = r.template;
                },
                (err) => {
                    this.error = err;
                }
            )
            .finally(
                () => {
                    this.saving = null;
                }
            )
    }

    save() {
        this.saving = true;
        this.appController.applicationsResource.simply_template_save({application: this.appController.data.Name}, this.template)
            .$promise
            .then(
                (r) => {
                    this.index = 0;
                    // this.appController.$mdDialog.hide();getTemplate
                },
                (err) => {
                    this.error = err;
                }
            )
            .finally(
                () => {
                    this.saving = null;
                }
            )
    }

    // cancel () {
    //     this.appController.$mdDialog.cancel();
    // };

}

export const ApplicationEditTemplateComponent = {
    bindings: {
        appController: '<',
        index: "="
    },
    controller: applicationEditTemplateController,
    template:  `
        <form name="saveConfig" ng-submit="$ctrl.save()" flex layout="column" layout-align="start">
            <div ui-ace="{mode: 'yaml', onLoad: aceLoaded}" ng-model="$ctrl.template"></div>
            <div layout="row" layout-align="start" flex>
                <div flex layout="row" layout-align="start"  class="nd-padding" style="margin-left:0; margin-right: 0">
                    <md-progress-liner ng-if="$ctrl.saving"></md-progress-liner>
                    <cmp-error-toast ng-if="$ctrl.error" error="$ctrl.error"></cmp-error-toast>
                </div>
                <md-button class="md-primary" type="submit">Save</md-button>
            </div>
        </form>
    `
}

