class controller {
    constructor($scope){
        this.$scope = $scope;
    }
    $onInit(){
        this.upload();
    }

    progress(event) {
        this.total = event.total;
        this.loaded = event.loaded;
        // console.log(this.total, this.loaded );
        this.proc = 100  - (this.total - this.loaded) / this.total * 100;
    }

    upload() {
        this.loading = true;
        this.error = null;
        this.request = this.ctrl.listController.CatalogService.getResource(this.ctrl.listController.$state.params, true, _.bind(this.progress, this))
            .fileUpload(
                {name: this.ctrl.datasetName(), version: this.data.Version, path: this.data.path + this.data.file.name},
                { file: this.data.file}
            );

        this.request
            .$promise
            .then(
                (r) => {
                    _.delay(() => {this.complite(); this.$scope.$apply() }, 1000);
                },
                (err) => {
                    let error = err.data ? err : {data:{Error: 'Error'}};
                    // this.data.eroor = error;
                    this.error = error;
                    this.setError();
                }
            )
            .finally(() => {
                this.loading = false;
            });
    }

    complite() {
        this.ctrl.uploadingFiles = _.without(this.ctrl.uploadingFiles, this.data);
    }

    remove(){
        if(this.loading)
            this.request.$cancelRequest();
        if(this.loading || this.error) {
            this.deleteFromTree();
        }
        this.complite();
    }

    deleteFromTree() {
        this.ctrl.treeApi.removeNewFile(this.data);
    }

    setError(){
        this.ctrl.treeApi.addError(this.data);
    }

}

export const DatasetVersionUploadingFiles = {
    bindings: {
        data: '<',
        ctrl: '<',
        file: '<'
    },
    controller: controller,
    template: `
        <div layout="row" layout-align="start center">
            <div flex>{{$ctrl.data.path}}{{$ctrl.data.name}}</div>
            <div ng-if="!$ctrl.error">loading: {{$ctrl.proc.toFixed(1)}}%</div>
            <!--<div ng-if="$ctrl.error">{{$ctrl.error.data.Error}}</div>-->
            <cmp-error-toast ng-if="$ctrl.error" error="$ctrl.error"></cmp-error-toast>
            <md-button ng-if="$ctrl.error" class="md-icon-button" aria-label="Add service" ng-click="$ctrl.upload()" >
                <md-icon md-svg-icon="core:reload"></md-icon>
            </md-button>
            <md-button class="md-icon-button" aria-label="Add service" ng-click="$ctrl.remove()" >
                <md-icon md-svg-icon="core:close"></md-icon>
            </md-button>
       </div>
    `
}