export class ws_listener {
    constructor() {
        var inject = angular.element(document.body).injector();
        this.$rootScope = inject.get('$rootScope');
    }

    watchWS(event, cb = () => {}) {
        if(!_.isArray(this._wsListener))this._wsListener = [];
        this._wsListener.push(this.$rootScope.$on('ws:' + event, (event, data) => {
            cb.call(this, event, data);
        }));
    }

    isCurrentWs(eventWs, objectWs) {
        return eventWs == objectWs;
    }

    $onDestroy() {
        if(this._wsListener){
            _.each(this._wsListener, (v) => {
                v();
            })
        }
    }
}
