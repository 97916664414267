
import {ControllerList} from '../../core/controller/list'

export class CatalogBaseListController extends ControllerList {
    // constructor (MlmodelService, $scope,  $element) {
    constructor () {

        super();
        var inject = angular.element(document.body).injector();
        this.storage = inject.get('$localStorage');
        this.$state = inject.get('$state');
        this.permissions = inject.get('WorkspacePermissions');

        this.$state = inject.get('$state');
        this.isWs = !!this.$state.params.wsId;
        this.search = this.$state.params.search;
        this.page = this.$state.params.page || 1;
        this.limit = this.$state.params.limit || 30;
        this.storage.$default({
            chartView: true
        });
        this.grid = this.storage.chartView;
        this.listEmpty = false;
        this.list = [];

        this.$element; // = $element;
        this.$scope; // = $scope;
        this.Service; // = MlmodelService;
        this.stateName;
        this.stateList; // = "wsMlmodelList";
        this.stateNew; // = "wsMlmodelList";
    }

    $onInit () {
        this.resource = this.Service.getResource(this.$state.params);
        this.$scope.$watch(
            () => {
                return this.page;
            },
            (c) => {
                this.getList(true);
            }
        );
    }


    goToItem (params) {
        this.$state.go(this.stateName, params);
    }

    getList (update, page, clear) {
        var self = this;
        var data = {
            page: this.page,
            limit: this.limit
        };
        data.search = this.search;

        this.isLoading = true;
        this.ErrorList = false;
        this.listPromise = this.Service.getList(update, data, clear);
        this.listPromise
            .then(
                function (r) {
                    self.list = r;
                    self.listEmpty = (r && r.length == 0);
                    self.$state.go( self.$state.current.name, data, {notify:false, location: true})
                },
                function (err) {
                    self.ErrorList = err;
                }
            )
            .finally(function () {
                self.isLoading = false;
            });

    }

    find () {
        this.page = 1;
        this.getList(true, this.page);
    }

    // star(data) {
    //     var action = data.Star ? "$unstar" : "$star";
    //     data[action]({wsId: data.WorkspaceName, mlmodelName: data.Name})
    //         .then(
    //             function (r) {
    //                 // debugger;
    //             },
    //             function (err) {
    //                 // debugger;
    //             }
    //         )
    // }

    changeView (list  ) {
        this.grid = !this.grid;
        this.storage.chartView = this.grid;
        // this.setView();
    }

    goTo(type) {
        this.$state.go(this.$state.current.name, {type: type});
    }

    setView () {
        // debugger;
        // if(this.grid) {
        //     $(this.$element).find('.chartList').removeClass('line_view');
        //     $(this.$element).find('.chartList').addClass('col_3_view');
        // } else {
        //     $(this.$element).find('.chartList').addClass('line_view');
        //     $(this.$element).find('.chartList').removeClass('col_3_view');
        // }
    }
}

