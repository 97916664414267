
import {TeamUserListController, TeamUserController} from './team_users'
import {TeamRightsComponent} from './team_rights'

export default angular
    .module('organization.team', [])
    .component('cmpTeamRights', TeamRightsComponent)
    .component('cmpTeamUserList', {
        bindings: {
            organizationId: '<',
            teamId: '<',
            isOwnerTeam : "<"
        },
        template: `
                <div class="listTeamUser" flex layout="column">
                    <div  class="title layout-row layout-align-space-between-center">
                       <h5>{{"COMMON_USERS"|translate}} ({{$ctrl.list.length}})</h5>
                        <md-button ng-click="$ctrl.addInvite()" ng-disabled="$ctrl.inviteForm" class="md-raised md-primary">
                            <md-icon md-svg-icon="core:plus"></md-icon> {{"SETTINGS_INVITE_ACTION_NEW"|translate}}
                        </md-button>
                    </div>
                    
                    <md-card ng-if="$ctrl.inviteForm"> 
                        <form name="inviteForm">
                            <md-card-content class="layout-row layout-align-start-center">
                                <md-input-container flex=>
                                    <label>{{"SETTINGS_INVITE_EMAIL"|translate}}</label>
                                    <input type="email" name="Email" ng-model="$ctrl.inviteData.Email" required> 
                                </md-input-container>
                                <md-button class="md-raised md-primary" aria-label="send" ng-click="$ctrl.sendInvite(inviteForm)"><md-icon md-svg-icon="core:plus"></md-icon> {{"COMMON_INVITE"|translate}}</md-button>
                                <md-button aria-label="cancel" name="Name" ng-click="$ctrl.cancelInvite()"><md-icon md-svg-icon="core:close"></md-icon> {{"COMMON_CANCEL"|translate}}</md-button>
                            </md-card-content>
                            <md-card-action>
                                <md-progress-linear ng-if="$ctrl.saving"></md-progress-linear>
                                <cmp-api-error-service ng-if="$ctrl.inviteSendError" error="$ctrl.inviteSendError" fromat="'dialog'"></cmp-api-error-service>
                            </md-card-action>
                        </form>
                    </md-card>
                    
                    
                    <md-list flex>
                        <cmp-team_user ng-repeat="data in $ctrl.userList()" data="data" list-controller="$ctrl" team-id="$ctrl.teamId" organization-id="$ctrl.organizationId"></cmp-team_user>
                    </md-list>
                </div>                
                <!--<md-divider></md-divider>-->
                <!--<cmp-team-rights flex="33" team-id="$ctrl.teamId" org-id="$ctrl.organizationId" class="listTeamRights" is-owner-team="$ctrl.isOwnerTeam"></cmp-team-rights>-->
            
        `,
        controller: TeamUserListController
    })
    .component('cmpTeamUser', {
        bindings: {
            listController: '<',
            organizationId: '<',
            teamId: '=',
            data: "<"
        },
        template: `
            <md-list-item class="hovered cmp-clickablew">
                <md-icon ng-if="!$ctrl.data.Accepted" md-svg-icon="core:account-alert" class="md-avatar-icon"></md-icon>
                <md-icon ng-if="$ctrl.data.Accepted && !$ctrl.data.Picture" md-svg-icon="core:user" class="md-avatar-icon"></md-icon>
                <img ng-if="$ctrl.data.Accepted && $ctrl.data.Picture" class="md-avatar" ng-src="{{$ctrl.data.Picture}}">
                <span flex>{{$ctrl.data.DisplayName}} <span ng-if="$ctrl.data.DisplayName != $ctrl.data.Email">({{$ctrl.data.Email}})</span></span>
                <md-button class="md-secondary md-icon-button showByHoverParent" aria-label="Add service" ng-click="$ctrl.delete()">
                    <md-icon md-svg-icon="core:delete"></md-icon>
                </md-button>
            </md-list-item>        
            `,
        controller: TeamUserController
    });
