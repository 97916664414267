import {InferenceListController} from './inference_list_controller'

// class controller extends InferenceListController {
//     constructor (ChartsService, $scope, $state, $localStorage, $rootScope, UserService, $element, WorkspacePermissions) {
//         super(...arguments);
//         // this.limit = 3;
//     }
// }

export const InferenceCardHomeComponent = {
    controller : InferenceListController,
    template:  ($templateCache) => {
        return $templateCache.get('kuberlab/catalog/base/card/card_home.html');
    }
};
