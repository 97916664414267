
import {CatalogBaseListController} from '../catalog/base/list_controller'

export class CatalogDataListController extends CatalogBaseListController {
    constructor (CatalogDataService, $scope,  $element) {

        super();
        this.$element = $element;
        this.$scope = $scope;
        this.Service = CatalogDataService;
        this.stateName = "wsCatalogDataItem";
        this.stateList = "wsCatalogDataList";
        this.stateNew = "wsCatalogDataAdd";
    }
}

