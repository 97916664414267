 export function PleaseWait ($q) {
     var slice = Array.prototype.slice;
     return {
         restrict: 'E',
         transclude: true,
         template: `
            <div>
                <div class="title"><ng-transclude>Please wait.</ng-transclude></div>
                <md-progress-linear ng-if="!norogress"></md-progress-linear>
            </div>
        
        `,
         scope: {
             norogress: '='
         },
         link: function(scope, element) {
            element.parent().css({position: 'relative'})
         }
     };
}
