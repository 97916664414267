import {CatalogVersionController} from '../../catalog/base/version-view/controller'
import {ServingFormController} from '../../serving/form/controller'

class controller extends CatalogVersionController{
    constructor($scope, $state, $mdDialog, $templateCache, Utils, ErrorService, CatalogService) {
        super(...arguments);

    }

    $onInit() {
        // super.$onInit();
        this.CatalogResource = this.CatalogService.getResource(this.$state.params);
        this. $scope.$watch(
            () => {return this.data;},
            () => { this.getVersions();},
            true
        )
    }

    serveDialog(ev, version) {
        var self = this;

        if( !version.Config.serving.sources ) version.Config.serving.sources = [];
        var s = _.findWhere(version.Config.serving.sources, {name: 'model'});
        if(!s) {
            version.Config.serving.sources.push(
                {
                    "name": "model",
                    "mountPath": "/model",
                    "model": {
                        "workspace": this.data.WorkspaceName,
                        "model": this.data.Name,
                        "version": version.Version,
                    }
                }
            )
        }
        this.$mdDialog.show({
            locals: {
                data: this.data,
                resource: this.resource,
                version: version,
            },
            controllerAs: '$ctrl',
            controller: ServingFormController,
            template: this.$templateCache.get('kuberlab/serving/form/controller.html'),
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose:false,
        })
            .then(
                function(params) {},
                function() {});
    }

    // getVersionsComplite () {
    //     debugger;
    // }

    // saveConfig( version) {
    //     this.saving = true;
    //     this.saveError = null;
    //     this.CatalogResource.version_save({name: this.data.Name, version: version.Version}, version.Config).$promise
    //         .then(
    //             (r) => {
    //                 // this.versions = _.without(this.versions, this.version);
    //                 // this.version = _.first(this.versions);
    //             },
    //             (err) => {
    //                 this.saveError=err;
    //             }
    //         )
    //         .finally(() => {
    //             this.saving = false;
    //         })
    // }

}

export const MlmodelVersionComponent = {
    bindings: {
        resource: '<',
        data: '<',
        canManage: '<',
        service: '<',
        canNew: '<'
    },
    controller: controller,
    template:  ($templateCache) => {
        return $templateCache.get('kuberlab/mlmodel/version/template.html');
    }
}
