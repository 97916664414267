
class controller {

    constructor(UserConfigService, UserService, $scope, $rootScope)  {
        this.ws = UserService.ws;
        $scope.$watch(
            () => {
                return UserService.ws;
            },
            (c) => {
                this.ws = c;
            }
        );

        $scope.$watch(
            () => { return UserService.viewSettings; },
            (c) => {
                this.viewSettings = c;
                this.statesList = this.viewSettings.infra
                ?
                    {
                        'Projects' : 'wsApplicationsList',
                        'Servings': 'wsServingList',
                        'Workspace Catalog' : 'wsCatalog',
                        'Infrastructure': 'wsProjectsList',
                        'K-storage': 'wsStorages',
                        'Shared clusters': 'wsClusters'
                    }
                :
                    {
                        'Projects' : 'wsApplicationsList',
                        'Servings': 'wsServingList',
                        'Workspace Catalog' : 'wsCatalog',
                        'K-storage': 'wsStorages',
                        // 'Shared clusters': 'wsClusters'
                    }
                ;
            },
            true
        );

        this.workspace = UserConfigService.getConfig('currentWS');
        $rootScope.$on('UserConfigService_currentWS',
            (event, data) => {
                this.workspace = data;
                }
            )
    }

    $onInit() {
        // debugger;
    }

}

export const WorkspaceMainMenu = {
    bindings: {
        // statesList: '<',
        // workspace: '<',
        // state: '<'
    },
    controller: controller,
    template: ($templateCache) => {
        return $templateCache.get('kuberlab/workspace/2.0.0/workspace_main_menu.html');
        // $templateCache.get('');
    }
}
