import {ControllerItem} from '../../core/controller/item'

class controller extends ControllerItem {
  constructor($mdDialog, $translate, Message) {
    super(...arguments);
  }
}

export const ProjectItemComponent = {
  bindings: {
    data: '=',
    listController: '<'
  },
  controller: controller,
  template: function($templateCache) {
    return $templateCache.get('kuberlab/projects/card/project_card_item.html');
  }
};
