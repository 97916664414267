export class ServingTemplateVideoController {
    constructor(ErrorService, $state, $translate, $mdDialog, WorkspaceService) {
        this.ErrorService = ErrorService;
        this.$state = $state;
        this.$translate = $translate;
        this.$mdDialog = $mdDialog;
        this.currentWS = WorkspaceService.currentWS;
    }

    setDef () {
        this.newStream = {
            key: null,
            input: null,
            output: null,
            description: null,
            params: null
        }

    }

    $onInit() {
        this.setDef();
        this.config = this.parent.config;
        this.rList();
        // this.playLink = '/api/v1/stream/' + this.$state.params.wsId + '/' + this.$state.params.servingName + '/';
        this.playLink = `/api/v1/stream/${this.currentWS.Name}/${this.parent.data.Name}/`;
        console.log(this.playLink)
    }

    send (confog, param, cb = () => {}) {
        this.saving = true;
        this.parent.send(...arguments)
    }

    rList () {
        var params =[{name: "method", type: "string"}];
        this.send({method: 'rtmp_list'}, params, _.bind(this.listComplite, this));
        // this.send({method: 'rtmp_list'}, this.params, _.bind(this.listComplite, this));
    }

    listComplite (r, err) {
        this.saving = null;
        this.list = r.result;
        this.videoList = [];
        this.getError = [];
        if(r) {
            if(r.result)
                this.list = r.result;
                // this.list = JSON.parse(r.result);
        }
        this.listError = err;
    }

    getComplite(r, err) {
        this.saving = null;
        if(r){
            this.videoList.push(r);
        }
        if(err)
            this.getError.push(err);
    }



    deleteComplite(r,err){
        this.saving = null;
        if(err) {
            this.controller.ErrorService.error(err);
        }
        if(r) {
            this.controller.rList();
        }
    }

    isPlay(output) {
        return !/^rtmp:\/\//.test(output);
    }

    // create(output, input, form) {
    create(form) {
        this.errorCreate = null;
        this.creating = true;
        var params = [{name: "method", type: "string"}, {name: "output", type: "string"}, {name: "input", type: "string"}, {name: "key", type: "string"}, {name: "description", type: "string"}, {name: "params", type: "string"}];
        let data = _.extend({method:'rtmp_create'}, this.newStream);
        console.log('create new serving', data);
        this.send(data, params, _.bind(this.createComplite, this));
        form.$setPristine();

    }

    createComplite(r,err){
        this.saving = null;
        this.creating = null;
        if(err) {
            this.errorCreate = err;
        }
        if(r) {
            this.setDef();
            this.rList();
        }
    }

    delete(ev, item){
        this.confirmMessage(ev, null, null, 'COMMON_DELETE', 'COMMON_CANCEL', this._delete, item);
    }

    _delete(item) {
        var params =[{name: "method", type: "string"}, {name: "paramcopyPlayLink", type: "string"}, {name: "key_id", type: "string"}];
        this.send({method:'rtmp_delete', param: item.key, key_id: item.key}, params,_.bind(this.deleteComplite, {item: item, controller: this}));
        // this.send({method:'rtmp_delete', param: item.key}, this.params,_.bind(this.deleteComplite, {item: item, controller: this}));
    }

    async confirmMessage (ev, text, title, ok, cancel,  success = () => {}, successData) {
        title = await (title || "COMMON_SURE");
        text = await (text || "COMMON_SURE_DELETE");
        ok = await (ok || "COMMON_OK");
        cancel = await (cancel || "COMMON_CANCEL");

        var self = this;
        var tr = {};
        this.$translate([title, text, ok, cancel]).then(function(translations){
            tr = translations;
        }).finally(function() {
            var confirm = self.$mdDialog.confirm()
                .title(tr[title])
                .htmlContent(tr[text])
                .ariaLabel(tr[title])
                .targetEvent(ev)
                .ok(tr[ok])
                .cancel(tr[cancel]);

            self.$mdDialog.show(confirm).then(
                function () {
                    success.call(self, successData);
                }
                , function () {
                }
            );

        });
    }

    // hlsPlayLink (item) {
    //     return this.playLink  +  item.key + '.' + (item.output == 'hls' ? 'm3u8' : item.output);
    // }

    getPlayLink(item) {
        return this.playLink +  item.key + '.' + (item.output == 'hls' ? 'm3u8' : item.output);
    }

    getInputLink(item, env) {
        if(env == 'internal'){
            return `rtmp://${this.parent.data.Name}.${this.currentWS.ID}-${this.currentWS.Name}/live/${item.key}`;
        }
        else{
            const b64 = btoa(`${item.key}?workspace=${this.currentWS.Name}&serving=${this.parent.data.Name}`);
            return `rtmp://${location.host}/proxy/${b64}` ;
        }
    }

    copyPlayLink (item, type)   {
        const el = document.createElement('textarea');
        el.value = type == 'play' ? location.origin + this.getPlayLink(item) : this.getInputLink(item, type);
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    playDialog(ev, item) {
        this.$mdDialog.show({
            locals:{
                item:item,
                link:this.getPlayLink(item)
            },
            controller: function ($scope, item, link, $mdDialog) {
                $scope.$mdDialog = $mdDialog;
                $scope.item = item;
                $scope.playLink = link; // +  item.key.replace(/\//g,'-') + '.' + (item.output == 'hls' ? 'm3u8' : item.output);
                $scope.inNewTab = () => {
                    window.open($scope.playLink,'_blank');
                    $scope.$mdDialog.hide();
                }
            },
            template: `
                <md-content style="background-color: #000;" layout="column">
                    <div layout="row" layout-align="center"><div flex></div><md-button class="md-icon-button" ng-click="$mdDialog.hide()"><md-icon md-svg-icon="core:close" style="color: #fff;" ></md-icon></md-button></div>
                    <!--<video src="{{playLink}}" flex ng-if="item.output == 'mjpg' " style="min-width: 400px; min-height: 300px" controls>-->
                       <!--Your browser does not support the <code>video</code> element.-->
                    <!--</video>-->
                    <!--<hls-video src="playLink" flex ng-if="item.output == 'hls' " ></hls-video>-->
                    <!--<div layout="row" layout-align="center" style="padding-bottom: 8px;"><div flex></div><md-button ng-click="inNewTab()" class="md-button" style="color: #fff;">open in new tab</md-button></div>-->
                    <hls-video src="playLink" flex ></hls-video>
                </md-content>
                
            `,
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose:true,
        })
    }
}

