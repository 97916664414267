/**
 * Created by ilja on 17.11.16.
 */

class LayoutTitleUserController{
    constructor () {
        this.currentUser;
    }
}

export const LayoutTitleUserComponent = {
    bindings: {
        currentUser: '='
    },
    template: function ($templateCache) {
        return $templateCache.get('kuberlab/layout/title/layout_title_user_component.html');
    },
    controller: LayoutTitleUserController
};