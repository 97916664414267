class controller {
    $onInit() {
        this.serving_param_types = GlobalSettings && GlobalSettings.serving_param_types || ["int", "int8", "int16", "int32", "int64", "float", "double", "string", "uint8", "uint16", "bool", "bytes", "image", "image_stream", "video", "audio", "webrtc"];
        if(!this.data) this.data;

        if(!this.data.options) {
            this.data.options = {};
        }
        if(_.isUndefined(this.data.options.noCache)) this.data.options.noCache = true;
        // if(_.isUndefined(this.data.options.saveStreamPreviews)) this.data.options.saveStreamPreviews = true;image_stream

    }
}

export const ServingFormSpecComponent = {
    bindings: {
        data: "=",
        project: "="
    },
    controller: controller,
    template:  ($templateCache) => {
        return $templateCache.get('kuberlab/serving/form/spec/serving_form_spec_component.html');
    }
}


