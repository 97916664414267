
import {ApplicationJobsComponent} from './applications_jobs_component'
import {ApplicationJobItemComponent} from './application_job_item'
import {ApplicationJobsFilterComponent} from './application_job_filter'
import {ApplicationJobsFilterNewComponent} from './application_job_filter_new'
import {ApplicationJobsFilterItemComponent} from './application_job_filter_item'
import {ApplicationJobItemFullComponent} from './application_job_item_full'
import {ApplocationJobMertincsComponent} from './application_job_metrics'
export default angular
    .module('application_jobs', [])
    .component('cmpApplicationsJobs', ApplicationJobsComponent)
    .component('cmpApplicationJobItem', ApplicationJobItemComponent)
    .component('cmpApplicationJobItemFull', ApplicationJobItemFullComponent)
    .component('cmpApplicationJobsFilter', ApplicationJobsFilterComponent)
    .component('cmpApplicationJobsFilterNew', ApplicationJobsFilterNewComponent)
    .component('cmpApplicationJobsFilterItem', ApplicationJobsFilterItemComponent)
    .component('cmpApplocationJobMertincs', ApplocationJobMertincsComponent)
    .constant('projectJobParamSeparator', '.')


