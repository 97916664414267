import {ApplicationsItemController} from './applications_item_controller'
import {ApplicationsItemFullController} from './applications_item_full_controller'
import {ApplicationsListController} from './applications_list_controller'
import {ApplicationsCreaateController} from './applications_create'


export default function ($stateProvider, $urlRouterProvider) {

    $stateProvider
        .state('wsApplicationsList', {
            parent: 'workspace',
            url :  "/applications",
            resolve: {
                currentUser : function ( CU ) {
                    return CU;
                },

                applicationsResource : function( $stateParams, ApplicationsService, currentUser ){
                    return ApplicationsService.getResource($stateParams);
                },

                mlList : function (ApplicationsService, applicationsResource) {
                    return ApplicationsService.getListPromise();
                },
            },

            views: {
                "layout-page-content@" : {
                    template: `
                        <layout-page-content-title class="md-whiteframe-1dp" layout="row" layout-align="start center">
                            <layout-back></layout-back>
                            <h3 flex>{{$ctrl.WS.DisplayName}} projects</h3>
                            <md-button ng-if="$ctrl.permissions.can('application.manage')" ui-sref="wsApplicationsAdd" class="md-raised md-primary">{{'MLAPPS_LIST_EMPTY_ACTION' | translate}}</md-button>
                        </layout-page-content-title>
                        <cmp-applications flex layout="column" layout-align="start"></cmp-applications>
                    `,
                    controller: function(workspace, permissions) {
                        this.WS = workspace;
                        this.permissions = permissions;
                    },
                    controllerAs: '$ctrl'
                }
            }
        })

        .state('wsApplicationsAdd', {
            parent: 'wsApplicationsList',
            url : '/applications_create',
            resolve: {
                viewTabs: function () {
                    return [];
                },
             },

            views : {
                // "@wsBase" : {
                "layout-page-content@" : {
                    templateProvider: function ($templateCache, permissions) {
                        return permissions.can('application.manage')
                            ?
                            $templateCache.get('kuberlab/applications/card/applications_card_create.html')
                            :
                            `{{"COMMON_PERMISSIONS_DINIED"|translate}}`;
                    },

                    controller: ApplicationsCreaateController,
                    controllerAs: '$ctrl'
                }
            }
        })

        .state('wsApplication', {
            url : '/:applicationName/:revision',
            params: {
                revision: 'master'
            },
            parent: 'wsApplicationsList',
            resolve: {
                applicationBase : function(applicationsResource, ApplicationsService, $stateParams){
                    let app = ApplicationsService.getItem($stateParams.applicationName);
                    app.then(
                        (r) => {
                            ApplicationsService.addLastApp($stateParams.wsId, r);
                        }
                    );

                    return app;
                },
                appRevision: function (ApplicationsService, $stateParams, applicationBase) {
                    return ApplicationsService.getRevisionItem( $stateParams.applicationName, $stateParams.revision );
                },

                application: function (applicationBase, appRevision) {
                    _.extend(applicationBase.Configuration, _.pick.apply(this, [appRevision.config, 'revision'].concat(_.keys(applicationBase.Configuration))));
                    return applicationBase;
                },

                tabsByView: function (permissions, application ) {
                    // var full = ['summary', 'models', 'sources', 'history', 'jobs', 'metrics', 'install', 'status'];
                    var full = ['summary', 'models', 'jobs', 'metrics', 'status'];
                    _.each(application.Configuration.spec.uix, function (val) {
                       full.push(val.name);
                    });
                    full.push('sources');
                    // full.push('simple');

                    var views = permissions.can('application.manage') ? full : ['summary']; //['summary', 'jobs', 'metrics'];
                    return {
                        devops: views,
                        scientist: views
                    }
                },

                viewTabs : function (viewTypes, applicationTabs, tabsByView, $localStorage, application, UserConfigService, $stateParams) {
                    var viewProject = UserConfigService.getWsItemConfig($stateParams.wsId, 'project', $stateParams.applicationName, 'viewProject');
                    var view = viewProject == 'advanced' ? tabsByView[$localStorage.viewType] : ['simple', 'jobs'];
                    var tabsView = [];
                    if(view){
                        _.each(view, function (v) {
                          const name = _.findWhere(applicationTabs, {name: v}) || _.findWhere(application.Configuration.spec.uix, {name: v});
                          tabsView.push(name);
                        })
                    }
                    var AllowedTabs = application.Configuration.allowed_tabs ;
                    if(_.keys(AllowedTabs) && _.keys(AllowedTabs).length){
                        var el = [];
                        _.each(tabsView, (v) => {
                            if(!_.isUndefined(AllowedTabs[v.name])) {
                                v.displayName = !AllowedTabs[v.name] || AllowedTabs[v.name] == "" ? v.displayName : AllowedTabs[v.name];
                                el.push(v);
                            }
                        });
                        return el;
                    }
                    return tabsView;
                },
                
                defTab : function(viewTabs){
                    return viewTabs[0].name;
                },
            },
            redirectTo: (trans) => {

                let resolvePromise = trans.injector().getAsync('defTab');
                return resolvePromise.then( ( resolveData ) => {
                    return {state: 'wsApplication.tab', params: _.extend({}, trans.params(), {tab: resolveData}) }
                } );
            },
            views : {
                "layout-page-content@" : {
                    templateProvider: function ($templateCache) {
                        return $templateCache.get('kuberlab/applications/card/applications_card_full.html');
                    },
                    controller: ApplicationsItemFullController,
                    controllerAs: '$ctrl'
                }
            }
        })

        .state('wsApplication.tab', {
            url:'/:tab/:tabItem?:page:limit:el:resourceName',
            params: {
                tab: null,
                tabItem: null,
                page: null,
                limit: null,
                el: null,
                resourceName: null
            },
            // views: {
            //     'tabContent' : {
            //         templateProvider: function ($rootScope) {
            //             $rootScope.addButtonConfig = [];
            //         }
            //     }
            // }
        })
}
