class controller  {
    constructor(ApplicationsService, $state) {
        this.$state = $state;
        this.ApplicationsService = ApplicationsService;
    }

    $onInit() {
        // this.activeList = [];
        // this.typesFull = [
        //     'string',
        //     'numeric',
        //     'interval',
        //     'list',
        //     'bool'
        // ];
        if(!this.data) this.data = {};
        // this.getParamsList();
        this.active = {};
        this.typesDataList = {
            'bool': [
                'bool'
            ],
            'string': [
                    'string',
                    'list',
                    'bool'
                ],
            'numeric':[
                    'numeric',
                    'interval',
                    'list',
                    'bool'
                ]
        };
    }

    add () {
        this.data[this.active.params] = {search_type: this.active.type};
        this.active = {};
        this.addForm = false;
    }

    delete(item) {
        delete this.data[item];
    }

    // volumeList(param){
    //     debugger;
    //     var l = _.findWhere(this.paramsList, {name: param});
    //     return l ? l.values : [];
    // }

    searchTypeChange(name) {
        this.parent.data[name] = _.pick(this.parent.data[name], 'search_type');
    }
}

export const ApplicationJobsFilterItemComponent = {
    controller: controller,
    bindings: {
        item: "=",
        parent: "="
    },
    template: `
        <div layout="row" layout-align="start center">
            <div flex="25" style="text-align: right;">{{$ctrl.item.label}}</div>
            <div style="width: 10px"></div>
            <md-input-container flex>
                <label>Filter type</label>
                <md-select ng-model="$ctrl.parent.data[$ctrl.item.name].search_type" ng-change="$ctrl.searchTypeChange($ctrl.item.name)">
                    <md-option ng-repeat="t in $ctrl.typesDataList[$ctrl.item.type]" ng-value="t">{{t}}</md-option>
                </md-select>
            </md-input-container>
            <div style="width: 10px"></div>
            <div flex ng-switch="$ctrl.parent.data[$ctrl.item.name].search_type" layout="row">
                <md-input-container ng-switch-when="string" flex>
                    <label>Value</label>
                    <input type="text" ng-model="$ctrl.parent.data[$ctrl.item.name].value">
                </md-input-container>
                <md-input-container ng-switch-when="numeric" flex>
                    <label>Value</label>
                    <input type="number" ng-model="$ctrl.parent.data[$ctrl.item.name].value">
                </md-input-container>
                <md-input-container ng-switch-when="bool" flex>
                    <md-checkbox ng-model="$ctrl.parent.data[$ctrl.item.name].value" style="margin-bottom:0;">Not empty</md-checkbox>
                </md-input-container>
                <div ng-switch-when="interval" layout="row" layout-align="start center" flex>
                    <md-input-container flex>
                        <label>min</label>
                        <input type="number" ng-model="$ctrl.parent.data[$ctrl.item.name].min">
                    </md-input-container>
                    <md-input-container flex >
                        <label>max</label>
                        <input type="number" ng-model="$ctrl.parent.data[$ctrl.item.name].max">
                    </md-input-container>
                </div>
                <md-input-container ng-switch-when="list" flex>
                    <label>Value</label>
                    <md-select ng-model="$ctrl.parent.data[$ctrl.item.name].list" multiple>
                        <md-option ng-repeat="opnt in $ctrl.parent.volumeList($ctrl.item.name)" ng-value="opnt">{{opnt}}</md-option>
                    </md-select>
                </md-input-container>
            </div>
        </div>
    `
};
