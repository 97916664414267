
import Templates from '../core/templates'
import {KuberlabController} from "./kuberlab_controller"
import {KuberlabService} from './kuberlab_service'
import {KuberlabConfigApi} from './kuberlab_config'

import AuthModule from './auth/auth_module'
import LayoutModule from  './layout/layout_module'
import UserModule from './user/user_module'
import SettingModule from './settings/setting_module'
import WorkspaceModule from './workspace/workspace_module'
import OrganizationsModule from './organizations/organizations_module'
import InviteModule from './invite/invite_module'
import StateConfig from './kuberlab_stateconfig'
import {ErrorApi} from './core/error_handler'
import CatalogModule from './catalog/catalog_module'
import {ApiErrorService} from './core/error_handler'
import HeaderModule from './header/header_module'
import FooterModule from './footer/footer_module'
import {ApiErrorServiceComponent} from './core/error_handler'
import {ShowMessageComponent} from './core/show_message'
import {PagenateComponent} from './core/pagenate/pagenate'
import {ObjectComponent} from './core/object/object_component'
import StorageModule from './storage/storage_module'
import CoreModule from './core/module'
import {CoreErrorFailed} from './core/error_failed'
import SharedModule from './shared/shared_module'
import NoteModule from './note/note_module'
import HelpModule from './help/help_module'
import ErrorModule from './error/error_module'
import DocsModule from './docs/docs_module'
import RestoreModule from './restore/restore_module'

import Billing from './billing/billing_module'
import cfgAnalytic from './kuberlab_analytics'
import MlmodelModule from './mlmodel/mlmodel_module'
import CatalogDataModule from './catalog_data/catalog_data_module'
import CatalogInference from './inference/inference_module'
import WsModule from './websocket/websocket_module'
import {LogoComponent} from './core/logo'
import {LoadStateComponent} from './core/load_state'
import Deb from './debugging/debugging_module'
import Intro from './intro/intro-module'
import Notify from './notify/notify_module'
import Setting from './setting/setting_module'

import angularNvd3 from '../core/angular-nvd3/angular-nvd3'
import markdown  from '../core/markdown'
import TemplateModule from './template/template-module'



import Layout2 from './layout_2.0.0/layout_module'
// import {} from ''

export  default angular
    .module('kuberlab', [
        angularNvd3.name,
        'angular-md5',
        'ngMaterial',
        'ngMessages',
        'ngResource',
        'ui.router',
        'ng.jsoneditor',
        'ngPassword',
        'chart.js',
        'pascalprecht.translate',
        'angular-google-analytics',
        'btford.markdown',
        'ngStorage',
        'ngSanitize',
        'ngFileSaver',
        'ui.ace',
        'angular-bind-html-compile',
        'ngTable',
        'dnd',

        Layout2.name,

        markdown.name,
        TemplateModule.name,

        Intro.name,
        Notify.name,
        HelpModule.name,
        Setting.name,
        // 'ng-showdown',

        CoreModule.name,
        WsModule.name,
        MlmodelModule.name,

        CatalogDataModule.name,
        CatalogInference.name,
        StorageModule.name,
        Templates.name,
        RestoreModule.name,
        DocsModule.name,
        AuthModule.name,
        LayoutModule.name,
        UserModule.name,
        SettingModule.name,
        SharedModule.name,
        Billing.name,
        NoteModule.name,
        ErrorModule.name,
        WorkspaceModule.name,
        OrganizationsModule.name,
        InviteModule.name,
        CatalogModule.name,

        HeaderModule.name,
        FooterModule.name,
        Deb.name

    ])
    .component('cmpLogo', LogoComponent)
    .constant('validate', {
        idPattern: '^[A-Za-z0-9][A-Za-z0-9-_]{1,30}[A-Za-z0-9]$',
        namePattern: '^[a-z0-9][a-z0-9-_]{1,33}[a-z0-9]+$',
        nameTaskPattern: '^[a-z0-9][a-z0-9-_]{0,33}[a-z0-9]+$',
        // passwordPattern: "\^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
        passwordPattern: "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d).{5,}$",
        version: "^([\\d])+.([\\d])+.([\\d])+$",
        nameMax: 35,
        nameMin: 5
    })
    .service('KuberlabConfigApi', KuberlabConfigApi)
    .service('KuberlabService', KuberlabService)
    .component('cmpPagenate', PagenateComponent)
    .component('cmpApiErrorService', ApiErrorServiceComponent)
    .component('cmpShowMessage', ShowMessageComponent)
    .component('cmpObject', ObjectComponent)
    .component('cmpCoreErrorFailed', CoreErrorFailed)
    .component('cmpLoadState', LoadStateComponent)
    .factory('myInterceptor', function ($rootScope, $q) {
            return {
                responseError: function (error) {
                    if(error.status == -1) {
                        $rootScope.$emit("api_error", error);
                        // $rootScope.$emit("apiErrorFailed", {error: error});
                    }
                    return $q.reject(error);
            }}
        })
    .config(['$httpProvider', function ($httpProvider) {
        $httpProvider.interceptors.push('myInterceptor');
    }])

    .run(function($rootScope){
      $rootScope.uiRevCommit = uiRevCommit;
    })

    .service('ApiErrorService', ApiErrorService)
    .factory('pageConfig', function ($window) {
        var contentId = 'baseContent';
        var titleBase = $window.document.title;
        var title = titleBase ;
        return {
            contentId : () => {return contentId;},
            setContentId : (name) => {contentId = name;},
            title : () => {return title;},
            setTitle : (name) => {$window.document.title = title = titleBase + " "  +  name;}

        }
    })
    .factory('CurrentUser', function (AuthService) {
        return AuthService.currentUser();
    })

    .value('currentUserInfo', false)

    .controller('KuberlabController', KuberlabController)
    .controller('KuberlabHeaderController', function ($scope, pageConfig ) {
        $scope.pageConfig = pageConfig;
    })
    .config(function($translateProvider) {
        // https://angular-translate.github.io/docs/#/guide/19_security
        $translateProvider.useSanitizeValueStrategy("escape");
        $translateProvider.useStaticFilesLoader({
            prefix: '/' + window.uiRevCommit + '/languages/',
            suffix: '.json'
        });
        $translateProvider.preferredLanguage('en');
        // $translateProvider.translations('en', {
        //     'TOP_MENU_CATALOG': 'Catalog',
        //     'TOP_MENU_HELP': 'Help'
        // });
        $translateProvider.usePostCompiling(true);
        $translateProvider.useSanitizeValueStrategy('sanitize');
    })
    .filter("htmlSafe", function($sce) {
        // debugger;
        return function(htmlCode){
            return $sce.trustAsHtml(htmlCode);
        };
    })
    .filter('arraybuffertobase64', function () {
        return function (buffer) {
            var binary = '';
            var bytes = new Uint8Array(buffer);
            var len = bytes.byteLength;
            for (var i = 0; i < len; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
            return window.btoa(binary);
        };
    })
    .filter('d3filter', function () {
        return function (data, format) {
            return format ? d3.format(format)(data) : data;
        };
    })
    .filter('jsonStringify', function () {
        return function (data) {
            return JSON.stringify(data, null,"    ")
        }
    })
    .filter('timePassed', function () {
        return function (time) {
            let cTime = new Date(time);
            let nTime = new Date();
            let i = (nTime - cTime) / 1000;

            if(i < 60) {
                return 'just now'
            }

            if( i < 3600 ) {
                let ii = i / 60;
                return  ii >= 2 ? ii.toFixed(0) + ' minutes ago' : 'a minute ago'
            }

            if( i < 86400 ) {
                let ii = i / 3600;
                return ii >= 2 ? ii.toFixed(0) + ' hours ago' : 'a hour ago'
            }

            if( i < 2592000 ) {
                let ii = i /86400;
                return ii >= 2 ? ii.toFixed(0) + ' days ago' : 'a day ago'
            }
            let ii = i / 2592000;
            return ii >= 2 ? ii.toFixed(0) + ' months ago' : 'a month ago'
        };
    })
    .config(StateConfig)
    .config(function($mdIconProvider) {
        $mdIconProvider
            .iconSet('services', '/' + uiRevCommit + '/icons/services.svg', 24)
            .iconSet('core',  '/' + uiRevCommit + '/icons/core.svg', 24)
    })
    .config(function($mdThemingProvider) {
      $mdThemingProvider
      .theme('default')
        .primaryPalette('blue')
        .accentPalette('indigo');
        // .accentPalette('red');
    })
    .config(cfgAnalytic)

    .config(['markdownConverterProvider', function (markdownConverterProvider) {
        // options to be passed to Showdown
        // see: https://github.com/coreyti/showdown#extensions
        markdownConverterProvider.config({
            extensions: ['table']
            // extensions: ['twitter']
        });
    }])
