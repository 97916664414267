export function orgIcon () {
    function link(scope, element) {
        // element.attr( {'src' : uiRevCommit + '/img/b.gif' } );

        element.addClass('icon');

        scope.$watch('data',
            () => {
            // debugger
                element.css({
                    "background": "url('" + scope.data.Picture + "') 50% 50% no-repeat",
                    "background-size" : "contain"
                });
            })

    }
    return({
        // template: `
        //         <img ng-src="{{uiRevCommit}}/img/b.gif" style="background: url('{{data.Picture}}') 50% 50% no-repeat; background-size: contain" class="icon" >
        //         `,
        scope: {
            data: '=data'
        },
        link: link,
        restrict: "AE"
    });
}
