class SharedClusterItemStorageController {

    constructor ( SharedClusterService, $scope, $state) {
        this.SharedClusterService = SharedClusterService;
        this.$scope = $scope;
        this.$state = $state;
    }

    $onInit(){
        this.getInfo();
    }

    getInfo() {
        var self = this,
            params = {ID: this.data.ID};
        if(this.data.WorkspaceName)
            params.WorkspaceName = this.data.WorkspaceName;
        // this.resource = this.SharedClusterService.getResource({type: this.data.Global ? 'global' : 'available'});
        this.resource = this.SharedClusterService.getResource({type: this.cluster.type});

        this.resource.storage(params)
            .$promise
            .then(
                function (r) {
                    self.storagesList = r;
                },
                function (err) {
                    self.storagesList = err;
                }
            )
    }
}

export const  SharedClusterItemStorageComponent = {
    bindings: {
        data: "=",
        cluster: "<"
    },
    controller: SharedClusterItemStorageController,
    template: `
        <!--<h5>Strorage</h5>-->
        <div ng-repeat="s in $ctrl.storagesList | orderBy : -WorkspaceName : Email ">
            <cmp-storage-view data="s"></cmp-storage-view>
        </div>
`
}