
import {CoreServiceResource} from '../core/service/core_service_resource'

export class ProjectService extends CoreServiceResource {
    constructor ($resource, $q, KuberlabConfigApi, $rootScope){
        super(...arguments);
    }

    getResource(wsId, clear ) {
        // debugger;
        if(!wsId) {
            throw 'getResource not wsId';
        }
        // if(this._wsId != wsId || !this.projectResource){
        //     var projectResource = this.$resource(
        //         this.cfg.APIPath + '/workspace/' + wsId + '/projects/:prName',
        //         {prName: '@Name'},
        //         _.extend({}, this.cfg.ResourceParams,
        //             {
        //                 create: {
        //                     method: "POST",
        //                     params: {prName: "@prName"}
        //                 }
        //             })
        //     );
        //     this._wsId = wsId;
        //     this.list = null;
        // }

        this.getProjectResource(wsId, clear);
        var nameApiPath = this.cfg.APIPath + '/workspace/' + wsId + '/projectname';
        this.projectNameResource = this.$resource(
            nameApiPath,
            {prName: '@Name'},
            _.extend({}, this.cfg.ResourceParams, {

                isavailable : {
                    method : 'GET',
                    url: nameApiPath + '/isavailable'
                },
                pickup : {
                    method : 'GET',
                    url: nameApiPath + '/pickup'
                }
            })
        );

        return this.projectResource;
    }

    getProjectResource(wsId, clear) {
        if(this._wsId != wsId || !this.projectResource || clear){
            var projectResource = this.$resource(
                this.cfg.APIPath + '/workspace/' + wsId + '/projects/:prName',
                {prName: '@Name'},
                _.extend({}, this.cfg.ResourceParams,
                    {
                        create: {
                            method: "POST",
                            params: {prName: "@prName"}
                        }
                    })
            );
            if(!clear) {
                this.projectResource = projectResource;
                this._wsId = wsId;
                this.list = null;
            }
        }
        if(!projectResource) projectResource = this.projectResource;
        return projectResource;
    }

    getProjects (update) {

        if(!this.list || this.projectResourceQueryPromise){
            this.list = [];
            update = true;
        }

        if(update) {
            var self = this;
            this.projectResourceQueryPromise = this.projectResource.query()
                .$promise
                .then(
                    function (r) {
                        self.list = r;
                        return r;
                    }
                )
        }

        return this.projectResourceQueryPromise;
    }

    getProject(prName) {
        if(this.list && this.list.length){
            var pr = _.findWhere(this.list, {Name : prName});

            if(pr){
                return this.$q.resolve(pr);
            }
            // var pr = _.findWhere(this.list, {Name : prName});
            // if(!pr.$promise)
            //     pr.$promise = pr.$get().$promise;
            // return pr.$promise;
        }
        return this.projectResource.get({prName : prName}).$promise;
    }

    // getProjectNew() {
    //     // debugger
    //     var pr = new this.projectResource({});
    //     return this.$q.resolve(pr);
    // }

     addProject(project) {
         if(this.list) {
             if(!_.findWhere(this.list, project)) {
                 this.list.push(project);
             }
         }
     }

}
