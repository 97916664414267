class controller {
    constructor($rootScope){
        this.$rootScope = $rootScope;
    }

    open() {
        this.$rootScope.$emit('show_docs', {doc: this.doc, title: this.title});
    }
}

export const DocsLinkComponent = {
    bindings: {
        doc: '<',
        title: '<'
    },
    controller : controller,
    template : `
            <md-button class="md-icon-button" ng-click="$ctrl.open()"><md-icon md-svg-icon="core:hint"></md-icon></md-button>
        `
}