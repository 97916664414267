
import {ControllerItem} from '../core/controller/item'
import {SharedClusterShareController, SharedClusterShareTemplate} from './shared_cluster_share_component'

export class SharedClusterItemController extends ControllerItem{
    constructor ($mdDialog, $translate, Message, SharedClusterService, $scope, $state, CurrentUser) {
        super($mdDialog, $translate, Message);
        this.SharedClusterService = SharedClusterService;
        this.$scope = $scope;
        this.$state = $state;
        CurrentUser.then((r) => {this.Admin = r.Admin;})

    }

    $onInit() {
        this.tabs = [];
        this.type = this.listController.type == 'own' ? 'own' : ( this.data.Global ? 'global' : 'available' );
        this.resource = this.SharedClusterService.getResource({type: this.type});
        if(this.type == 'own'){
            this.tabs = [
                {
                    name: 'link',
                    title: 'Links',
                    content: '<cmp-shared-cluster-item-link data="$ctrl.data" ></cmp-shared-cluster-item-link>',
                    active : false
                },
                {
                    name: 'storage',
                    title: 'Storage',
                    content: '<cmp-shared-cluster-item-storage data="$ctrl.data" cluster="$ctrl"></cmp-shared-cluster-item-storage>',
                    active : false
                }
            ];

            this.goTab('link')
        } else {
            this.tabs = [
                {
                    name: 'storage',
                    title: 'Storage',
                    content: '<cmp-shared-cluster-item-storage data="$ctrl.data" cluster="$ctrl"></cmp-shared-cluster-item-storage>',
                    active : false
                }
            ];

        }
        this.goTab(_.first(this.tabs).name);
        // this.getInfo();
    }

    goTab(tab) {
        this.currentTab = tab;
        _.findWhere(this.tabs, {name: this.currentTab}).active = true;
    }

    share(ev) {
        var then = this;
        this.installError = null;
        this.$mdDialog.show({
            locals: {
                dialog: then.$mdDialog,
                clusterResource : this.resource,
                params :{ID: this.data.ID} ,
                data : this.data,
                listController: this.listController
                //_.pick(this.data, 'DisplayName', 'Description', 'Shared')
                // data : _.pick(this.data, 'DisplayName', 'Description', 'Shared')
            },
            controller: SharedClusterShareController,
            controllerAs: '$ctrl',
            template: SharedClusterShareTemplate,
            parent: angular.element(document.querySelector('#mainBody')),
            targetEvent: ev,
            clickOutsideToClose: false,
            escapeToClose: false
        }).then(
                                                                                                                                                                                                                                                                                                                                                                                                                function(data){
                then.data.$get();
            },
            function () {
            }
        );

    }

    deleteLink(link) {
        var action = "";
        var params = {sharm};
        if(link.Email) {
            action = 'delete_link_email';
            params = {
                shareID: link.link.ID
            };
        }else {
            action = 'delete_link_ws';
            params = {
                shareID: link.link.ID,
                WorkspaceName: link.WorkspaceName
            };
        }
        this.resource[action]();
        // url: apiPath + '/workspace/:WorkspaceName/:shareID'
    }

}

export const SharedClusterItemComponent = {
    bindings: {
        data: '=',
        listController: "<"
    },
    controller: SharedClusterItemController,
    template: function ($templateCache) {
        return $templateCache.get('kuberlab/shared/card/shared_cluster_item.html');
    }
}

