/**
 * Show notification, like info and error.
 */
export default class Message {
  constructor($mdToast) {
    this.$mdToast = $mdToast;

    this.template = `
    <md-toast md-theme="{{ toast.theme }}" ng-class="{'md-capsule': toast.capsule}">
      <div class="md-toast-content">
        <span flex role="alert" aria-relevant="all" aria-atomic="true">
          {{ toast.content }}
        </span>
      </div>
    </md-toast>
    `;
  }

  /**
   * Show info notification in yellow.
   *
   * @param {string} message message
   * @param {number} [delay] delay in ms
   */
  info(message, delay) {
    this._show(message, 'info', delay);
  }


  /**
   * Show info notification in red.
   *
   * @param {string} message message
   * @param {number} [delay] delay in ms
   */
  error(message, delay) {
    this._show(message, 'error', delay);
  }

  /**
   * Show notification.
   *
   * @param {string} message message
   * @param {string} type info or error
   * @param {number} [delay=3000] delay in ms
   */
  _show(message, type, delay) {
    const toast = this.$mdToast.simple()
      .position('top left right')
      .theme(type + '-toast')
      .hideDelay(delay || 3000);

    toast['_options'].template = this.template.replace('{{ toast.content }}', message);

    this.$mdToast.show(toast);
  }

}
