
import {ApplicationsItemController} from './applications_item_controller'

export class ApplicationsItemFullController extends  ApplicationsItemController {
    constructor (
        $state,
        $mdDialog,
        $translate,
        ApplicationsService,
        ProjectService,
        ProjectClusterService,
        $scope,
        $window,
        // sctorageList,
        FileSaver,
        WorkspacePermissions,
        $rootScope,
        application,
        viewTabs,
        appRevision,
        applicationTabs,
        workspace
    )
    {
        super( ...arguments );
        this.useTabs = {};
        this.initialized = {};
        this.data = application;
        this.$rootScope = $rootScope;
        this.WS = workspace;
        this.$transitions = this.inject.get('$transitions');
        this.$localStorage = this.inject.get('$localStorage');
        this.appRevision = appRevision;

        this.viewTabs = viewTabs;

        this.full = true;

        if(appRevision){
            this.setResision(appRevision);
        }
        if(application)
            this.config();

        $scope.$on('$destroy', () => {
            this.$onDestroy();
        });

        this.watchWS('applicationDelete', this.ws_applicationDelete);
    }

    ws_applicationDelete(ev, data){
        if(this.isCurrentWs(data.WorkspaceName, this.data.WorkspaceName)){
            this.successDelete(data);
        }
    }

    actionView() {
        super.actionView();
        this.initialized = {};
        this.initialized[this.$state.params.tab] = true;
    }

    setResision(appRevision) {
        _.extend(this.data.Configuration, _.pick.apply(this, [appRevision.config, 'revision.'].concat(_.keys(this.data.Configuration))));
    }

    config() {
        var self = this;
        this.setUseTabs(this.$state.params);

        this.listenerState = this.$transitions.onStart(
            { entering: 'wsApplication.tab' },
            function(transition, state){
                self.setUseTabs(transition.params());
            });

        if(this.isNew()){
            this.isNewElement = true;
            this.newElement = true;
        }

        if(this.newElement) this.getProjects();

        this.$scope.$watch(function () {
                return self.ProjectService.list;
            },
            function (c) {
                self.projectsList = c;
            }
        );
        this.$scope.$watch(function () {
                return self.ProjectClusterService.list;
            },
            function (c) {
                self.clusterList = c;
            }
        );

        this.output = this.getVolumeTrain();
        this.repo = {};

        if(this.data){
            if(!this.data.ClusterName) this.data.ClusterName = "";
            super.$onInit();
        }
        // this.full = true;
    }


    setUseTabs(params) {
        var tab = _.findWhere(this.viewTabs, {name: params.tab});
        if(tab && params.tab) {
            this.initialized[params.tab] = true;
            this.useTabs[params.tab]  = _.clone(params);
        }
    }

    getProjects () {
        var self = this;
        this.ProjectService.getResource(this.$state.params.wsId);
        this.ProjectService.getProjects(true)
    }

    loadEnv (prName) {
        this.ProjectEnvService.getResource(_.extend({}, this.$state.params, {projectName : prName}));
        this.ProjectEnvService.getList(true);
    }

    loadClusters (prName, envName) {
        this.ProjectClusterService.getResource(_.extend({}, this.$state.params, {projectName : prName, envName : envName}));
        this.ProjectClusterService.getList(true);
    }

    changeProject() {
        this.envList = [];
        this.data.Environment = null;
        this.data.ClusterName = null;
        var pr = _.findWhere(this.projectsList, {Name: this.data.ProjectName});
        this.data.Environment =  pr.Environment;
        this.loadClusters(this.data.ProjectName, pr.Environment);
    }

    changeEnv() {
        this.clusterList = [];
        this.data.ClusterName = null;
        this.loadClusters(this.data.ProjectName, this.data.Environment);
    }

    getVolumeTrain() {
        var i = _.findWhere(this.data.Configuration.spec.volumes, {isTrainLogDir:true});
        return i ? _.indexOf(this.data.Configuration.spec.volumes, i) : 0;
    }

    pickupName (form){
        if(this.newElement) {
            var self = this;
            this.applicationsResource.pickup({wsId: this.$state.wsId, name: this.data.DisplayName})
                .$promise
                .then(
                    function (resp) {
                        self.data.Name = resp.Name;
                    },
                    function (err) {
                        debugger;
                    }
                );
        }
    }

    checkName (form) {
        var self = this;
        this.applicationsResource.isavailable({wsId: this.$state.wsId, name: this.data.Name})
            .$promise
            .then(
                function(resp) {
                    form.Name.$setValidity("errorname", true);
                },
                function (err) {
                    self.nameErrorMessage = err;
                    form.Name.$setValidity("errorname", false);
                }
            );
    }

    getVolums(filter) {
        if(_.isObject(filter)) {
            var v = _.where(this.data.Configuration.spec.volumes, filter);
            return v;
        }
        else
            return this.data.Configuration.spec.volumes;
    }

    update() {
        this.data.DoNotSave = true;
        return this.data.$update()
            .then((r) => {
                // this.data.DoNotSave = null;
            })
            .finally(() => {
                this.data.DoNotSave = null;
                this.ApplicationsService._reset('application');
            })
        ;
    }

    uixDisable (link) {
        var uix = _.findWhere(this.data.Configuration.spec.uix, {front_api: link});
        if(uix) {
            uix.disabled = !uix.disabled;
            this.update();
        }
    }

    $onDestroy(){
        super.$onDestroy();
        if(this.listenerState) this.listenerState();

    }


}
