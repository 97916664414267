
export default {
    state: {
        name: 'catalog_new.list',
        params: {
            Interface: 'chart-mlapp-v2'
        }
    },
    steps:[
        {
            element: 'cmp-catalog-item-small .chartTitle',
            intro: "Go to catalog mlmodel.",
            action: 'click'
        }
    ],
    showBullets: false,
    showButtons: false,
}
