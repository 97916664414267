
import {ControllerList} from '../core/controller/list'
import {ControllerItem} from '../core/controller/item'

export class SettingsServicesListController extends ControllerList {
    constructor (SettingsService, AuthService, $scope) {
        super();
        this.resource = AuthService.oauth;
        this.AuthService = AuthService;
        this.list = null;
        this.ServiceList;
        AuthService.ServiceList();
        var self = this;
        $scope.$watchCollection(
            function () {
                return self.AuthService.serviceList;
            },
            function (c) {
                self.ServiceList = c;
                self.conntectionList();
            }
        );
    }

    // $onInit(){
    //     this.conntectionList();
    // }

    conntectionList(update) {
        var self = this;
        if(!this.list) {
            this.list = [];
            update = true;
        }
        if(update) {
            this.resource.query(
                function(r){
                    self.list = r;
                    _.each(self.list, (v) => {
                        var av = _.findWhere( self.ServiceList, {Name: v.Service} );
                       if(av) v.scopeAvailabel = av.Scopes;
                    });
                },
                function (r) {
                    console.error("Organizations team request error", r);
                }
            );
        }
        return this.list;
    }
}

export class SettingsServicesItemController extends ControllerItem {
    constructor ($mdDialog, $translate, AuthService, KuberlabConfigApi) {
        super($mdDialog, $translate);
        this.$mdDialog = $mdDialog;
        this.AuthService = AuthService;
        this.cfg = KuberlabConfigApi;
        // this.AuthService = AuthService;
    }

    $onInit() {

        this.Scopes = _.filter(this.data.scopeAvailabel, (v) => {
            return (_.indexOf(this.data.Scopes, v) == -1)
        });

        // this.Scopes = _.map( this.data.scopeAvailabel, (v) => {
        //     return {
        //         name: v,
        //         availabel: ( _.indexOf(this.data.Scopes, v) == -1 )
        //     }
        // })
    }

    errorDelete(r) {
        var self = this;
        this.$mdDialog.show(
            this.$mdDialog.alert()
                .parent(angular.element(document.querySelector('#popupContainer')))
                .clickOutsideToClose(true)
                .title('Delete error')
                // .textContent(r.Error)
                // .ariaLabel('Alert Dialog Demo')
                .ok('Ok')
                // .targetEvent(ev)
        );
    }

    successDelete(r){
        // debugger;
        this.AuthService.ServiceList(true);
        super.successDelete(r);
    }

    addScope(scopes) {
        // debugger;
        var l = _.clone(this.data.Scopes || []);
        l.push(scopes);
        this.AuthService.authInService(this.data.Service, null, l.join(','));
        // window.location  =  this.cfg.APIPath + '/api/v0.2/oauth/connections/add/' + this.data.Service + '?scopes=' + name;
    }


}

export const SettingsServicesList = {
    template : `
            <md-list ng-if="$ctrl.ServiceList">
                <cmp-settings-connection-item ng-repeat="data in $ctrl.list" data="data" list-controller="$ctrl"></cmp-settings-connection-item>
                <md-list-item class="hovered cmp-clickable">
                    <md-icon md-svg-icon="core:plus" class="md-avatar-icon"></md-icon>
                    <md-list-item-text class="flex layout-row layout-align-start-center">
                        <md-menu>
                            <md-button class="md-raised md-primary" aria-label="Add service" ng-click="$mdMenu.open()">
                                {{"SA_ADD_BUTTON"|translate}}
                            </md-button>
                            <md-menu-content width="3">
                                <auth-service-list type="'Add'"></auth-service-list>
                            </md-menu-content>
                        </md-menu>
                         <p><md-icon md-svg-icon="core:information"></md-icon> {{"SA_DESCRIONTION_LIST"|translate}}</p>
                    </md-list-item-text>
                </md-list-item>
            </md-list>
        `,
    controller: SettingsServicesListController
};

export const SettingsServicesItem = {
    bindings: {
        data: "=",
        listController: '='
    },
    template :
        `<md-list-item class="hovered cmp-clickable">
            <img ng-src="{{$ctrl.data.ServicePicture}}" class="md-avatar" />
            <!--<md-icon md-svg-icon="$ctrl.AuthService.getIcon($ctrl.data.Service)" ></md-icon>-->
            <md-list-item-text>
                {{$ctrl.data.ServiceName}} ({{$ctrl.data.Service}} - {{$ctrl.data.ServiceEmail || $ctrl.data.ServiceID}} )
                <div ng-if="$ctrl.Scopes.length">Permissions: 
                    <span ng-repeat="item in $ctrl.Scopes" >
                        <a href="" ng-click="$ctrl.addScope(item)" > {{item}}</a>
                        <span ng-if="!$last">, </span>
                    </span>
                </div>
                <!--<div ng-if="$ctrl.Scopes.length">Permissions: -->
                    <!--<span ng-repeat="item in $ctrl.Scopes" >-->
                        <!--<a href="" ng-click="$ctrl.addScope(item.name)" ng-if="item.availabel"> {{item.name}}</a>-->
                        <!--<span ng-if="!item.availabel"> {{item.name}}</span>-->
                        <!--<span ng-if="!$last">, </span>-->
                    <!--</span>-->
                <!--</div>-->
            </md-list-item-text>
            <md-button class="md-secondary md-icon-button byHoverParent showByHoverParent" ng-click="$ctrl.deleteConfirm($event)" aria-label="invite user">
                <md-icon md-svg-icon="core:delete"></md-icon>
            </md-button>
        </md-list-item>`,
    controller: SettingsServicesItemController
};