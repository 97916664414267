import {CatalogBaseItemNewController} from '../catalog/base/item_new_controller'

export class CatalogDataItemNewController extends CatalogBaseItemNewController {
    constructor (catalog_data, CatalogDataService) {
        super();
        this.data = catalog_data;
        this.Service = CatalogDataService;
        // this.delayNameCheck;
        this.stateName = 'wsCatalogDataItem';
    }
}
