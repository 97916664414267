import {DynamicItems} from '../../core/controller/dynamic_items'

class ProjectTasksController {
    constructor(ProjectTasksService, $state, $scope, ProjectClusterService, autoUpdate, $element){

        var self = this;
        this.autoUpdate = autoUpdate;
        this.$state = $state;
        this.service = ProjectTasksService;
        this.service.getResource(this.$state.params);
        this.$scope = $scope;
        ProjectClusterService.getResource($state.params);
        this.clusters = [];
        var c = ProjectClusterService.getList()
            .then(function(r){
                self.clusters = r;
            });
        this.types = [
            {Name: 'start'},
            {Name: 'stop'},
            {Name: 'deploy'}
        ];

        this.$scope.$watch(
            function () {
                return self.service.list;
            },
            function (current) {
                if(!self.list) {
                    self.list = current;
                }else {
                    var deleted = [];
                    _.each(current, (v, k) => {
                        var el = _.findWhere(self.list, {ID : v.ID});
                        if(el) {
                            current[k] = _.extend(v, {$$hashKey: el.$$hashKey, Data: el.Data});
                        }
                    });
                    self.list = current;
                }
            }
        );
        this.rParams = {};
        this.$scope.$watchCollection (
            () => {return this.rParams},
            () => {
                this.$state.go(this.$state.current.name, _.extend({}, this.$state.params, this.rParams), {notify: false});
                this.getList();
            }
        )
    }

    $onInit() {
        this.rParams = {
            page : this.$state.params.page || '1',
            limit : this.$state.params.limit || '5'};
        this.filterParams = {
            from : this.$state.params.from,
            to : this.$state.params.to,
            type : this.$state.params.type,
        };
        this.getList(true);
    }

    getList(update) {
        clearTimeout(this.timeout);
        var self = this;
        this.isLoading = true;
        delete self.loadError;

        var data = _.extend({}, this.rParams, this.filterParams);

        this.service.getList(update, data)
            .then(
                function (current) {},
                function (err) {
                    self.loadError = err
                }
            )
            .finally(function () {
                delete self.isLoading;
                self.autoReload();

            });
    }


    autoReload() {
        if(this.timeout){
            clearTimeout(this.timeout);
        }
        this.timeout = _.delay( _.bind(this.getList, this), this.autoUpdate, true);
    }

    addFilter() {
        this.rParams.page = '1';
        this.getList(true);
    }

    clearFilter(){
        this.filterParams = {};
        this.rParams.page = '1';
        this.getList(true)
    }

    $onDestroy() {
        clearTimeout(this.timeout);
    }
}

export const ProjectTasksComponent = {
    controller: ProjectTasksController,
    template:function ($templateCache) {
        return $templateCache.get('kuberlab/projects/tasks/project_tasks_component.html');
    }
};