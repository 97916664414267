class controller {

    constructor(KuberlabService, $q) {
        this.KuberlabService = KuberlabService;
        this.submitInProgress = false;
    }

    $onInit() {
        var self = this;
        this.KuberlabService.settings()
            .then(
                (s) => {
                    self.settings = s;
                    this.initCard();
                }
            );
    }

    initCard() {
        var stripe  = this.stripe = Stripe(this.settings.stripe_key);
        var elements = stripe.elements();

        var style = {
            base: {
                color: '#32325d',
                lineHeight: '24px',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4'
                }
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        };

// Create an instance of the card Element
        var card = this.card = elements.create('card', {
            hidePostalCode: true,
            style: style
        });

        card.mount('#card-element');

        card.addEventListener('change', function(event) {
            var displayError = document.getElementById('card-errors');
            if (event.error) {
                self.cardError = event.error.message;
            } else {
                self.cardError = null;
            }
        });

    }

    checkCard(){
        var _this = this;
        this.submitInProgress = true;

        this.stripe.createToken(this.card, this.data)
            .then(function (result) {
                _this.submitInProgress = false;
                if (result.error) {
                    _this.cardError = result.error.message;
                } else {

                    var r = {
                        BillingEmail: result && result.token.email,
                        CouponCode: _this.data && _this.data.couponCode,
                        KeepOld: !result,
                        CCToken: result && result.token && result.token.id
                    };
                    _this.promise.resolve(r);
                }
            });
    }

    cancel() {
        this.promise.reject();
    }
}


export const BillingCardComponent = {
    bindings: {
        cardToken: "=",
        change: "=",
        billing: '<',
        promise: '<'
    },
    controller: controller,
    template: function ($templateCache) {
        return $templateCache.get('kuberlab/billing/stripe/card.html')
    }
}
