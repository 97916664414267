class Controller  {
    constructor(){

    }
}

export const StorageNfsComponent = {
    bindings: {
        data : "="
    },
    controller: Controller,
    template: `
        <md-input-container class="flex-50">
            <label>server</label>
            <input ng-model="$ctrl.data.server">
        </md-input-container>
        <md-input-container class="flex-50">
            <label>path</label>
            <input ng-model="$ctrl.data.path">
        </md-input-container>
`
};