import {ControllerItem} from '../core/controller/item'

export class WsaItemController extends ControllerItem{
    constructor ($mdDialog, $translate, $scope, AuthService, validate) {
        // debugger;

        super($mdDialog, $translate);
        this.validate = validate ;
        this.AuthService = AuthService;
        this.$mdDialog = $mdDialog;
        this.listController;
        this.data;
        this.meta;
        this.manualDisplayName = false;
        // this.googleServiceProject;
    }

    $onInit() {
        super.$onInit();
        if( this.data.SetCredentials == undefined ) {
            this.data.SetCredentials = {}
        }
        this.meta = "";
        if(this.data.Service == "google" && this.data.Meta) {
            if(this.data.Meta.account_email) this.meta = this.data.Meta.account_email + ": ";
            if(this.data.Meta.project_name) this.meta += this.data.Meta.project_name
        }
        if(this.data.Service == "amazon" && this.data.Meta) {
            if(this.data.Meta.account_id) this.meta = "AWS account number: " + this.data.Meta.account_id;
        }
        if(this.isNew()){
            this.listController.hasNew = true;
        }
    }

    googleProjectNameVal (serv , proj) {
        if(serv && proj)
            return serv + ";" + proj;
        else
            return false;
    }

    googleProjectName () {
        if(this.googleProjectIsReconnect()) {
            return "Reconnecting to Google...";
        }
        var self = this;
        var title = 'Google project';
        // debugger;
        if( this.listController._gp && this.listController._gp.length && this.googleServiceProject){
            _.each( this.listController._gp, function (s) {
                _.each(s.Projects, function (p) {
                    if(p.projectId == self.googleServiceProject) {
                        self.data.SetCredentials = {
                            service_id : s.ServiceId,
                            project_id : p.projectId
                        };
                        title = s.ServiceName + ": " + p.name;
                        if (!self.manualDisplayName) {
                            // debugger;
                            // self.data.DisplayName = "Google project \"" + p.name + "\"";
                            self.data.Name = p.projectId; //"Google project \"" + p.name + "\"";
                        }
                        return title;
                    }
                });
            });
        }
        return title;
    }
    
    googleManualDisplayName () {
        this.manualDisplayName = true;
    }
    
    googleProjectCheckReconnect() {
        if(this.googleProjectIsReconnect()) {
            this.AuthService.authInService("google", null, 'infrastructure');
        }
    }
    
    googleProjectIsReconnect() {
        return this.googleServiceProject && this.googleServiceProject.indexOf("_reconnect") === 0;
    }

    // save(form) {
    //     if(form.$valid){
    //         super.save(...arguments);
    //     }
    // }

    saveSuccess(r) {
        this.$onInit();
        // super.saveSuccess(r);
        this.actionView();
        this.listController.hasNew = false;
    }

    cancel(){
        super.cancel();
        this.listController.hasNew = false;
    }

    // setGoogleProject(service, project) {
    //     if(!service || !project ) {
    //         return "Select google project"
    //     }
    //     this.data.SetCredentials = {ServiceId : service, ProjectId : project};
    //     if (this.googleServiceProject !== undefined) {
    //         return "You have selected: Item " + $scope.selectedItem;
    //     } else {
    //         return "Please select an item";
    //     }
    // }
}
