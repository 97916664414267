class controller {
    searchRepo() {

    }
}

export const RepoComponent = {
    bindings: {
        url: '=',
        dir: '='
    },
    controller: controller,
    template: `
            <md-input-container>
                <label>Repo url</label>
                <input ng-model="$ctrl.url">
                <md-icon md-svg-icon="core:serach" ng-click="$ctrl.searchRepo()"></md-icon>
            </md-input-container>
            <md-input-container>
                <label>Repo dir</label>
                <input ng-model="$ctrl.dir">
            </md-input-container>
        `
}