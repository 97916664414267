class Controller  {
    constructor($state, StoragePersistentService){
        this.$state = $state;
        this.StoragePersistentService = StoragePersistentService;
        this.list = [];
    }

    $onInit() {
        this.resource = this.StoragePersistentService.getResource(this.$state.params);
        this.getWsaList();
    }

    getWsaList() {
        var self = this;
        this.resource.query(
            {},
            function(r){
                self.list = r;
            },
            function (r) {
                console.error("Organizations team users request error", r);
            }
        );

    }

}

export const StoragePersistentComponent = {
    bindings: {
        data : "="
    },
    controller: Controller,
    template: `
        <md-input-container class="flex">
            <label>Storage</label>        
            <md-select ng-model="$ctrl.data.storageName">
                <md-option ng-repeat="item in $ctrl.list" ng-value="item.Name">{{item.Name}}</md-option>
            </md-select>    
        </md-input-container>
        
`
};
