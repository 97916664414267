/**
 * view progress change status
 * send page to analytic
 * show error state
 */


class controller {
    constructor ($rootScope, $transitions, Analytics, $state) {
        this.start = false;
        this.Analytics = Analytics;
        this.$rootScope = $rootScope;
        this.$state = $state;
        $transitions.onStart({}, () => {
            this.$rootScope.addButtonConfig = null;
            this.loading = true;
        });
        $transitions.onSuccess({}, (transition) => {
            this.start = true;
            this.loading = false;
            // TODO: check command bellow: it occurs with errors (may be then google account is disabled?)
            if(window.GlobalSettings.google_analytics) this.Analytics.trackPage(transition.router.stateService.href(transition.to(),transition.params()));
        });
        $transitions.onError({}, (transition) => {
            this.loading = false;
            let error = transition.error();
            if(error && error.type === 6 && error.detail) {
                switch(error.detail.status) {
                    case 401 :
                        // debugger;
                        this.$state.go('home', {}, {location: false});
                        break;
                    // case 404 :
                    //     this.$state.go('error', {api_error: JSON.stringify(error.detail)}, {location: false});
                    //     // this.$state.go('home', {}, {location: false});
                    //     break;
                    case undefined:
                        let fromState = transition.from();
                        let toState = transition.to();
                        let err = {
                            StatusCode: 'Status error',
                            Error: 'from ' + fromState.name + ' to ' +  toState.name,
                            Reason: error.message
                        };
                        this.$state.go('error', {api_error: JSON.stringify({data: err})});
                        break;
                    default:
                        this.$state.go('error', {api_error: JSON.stringify( error.detail)}, {location: false});
                }
            }
        });
    }
}

export const LoadStateComponent = {
    controller: controller,
    template: `
        <md-progress-linear ng-if="$ctrl.loading"></md-progress-linear>
`
};
