class controller {
    constructor(AuthService, KuberlabConfigApi, $resource, $state) {
        this.AuthService = AuthService;
        this.$state = $state;
        this.link = KuberlabConfigApi.APIPath + '/oauth/choose';
        this.resource = $resource(this.link, {}, {});
    }

    $onInit() {
        this.getChooseList();
    }

    getChooseList() {
        this.resource.query().$promise
            .then(
                (r) => {
                    this.list = r;
                },
                (err) => {
                    this.$state.go('home');
                    // this.error = err;
                }
            )
    }

    goto(id) {
        window.location.href = this.link + '/' + id;
    }
}

export const AuthChooseComponent = {
    controller: controller,
    template: `
        <cmp-error-toast ng-if='$ctrl.error' error='$ctrl.error'></cmp-error-toast>
        <div ng-if="$ctrl.list" layout="row" layout-align="center center">
            <md-card>
                <md-content>
                    <md-subheader>select account</md-subheader>
                    <md-list>
                        <md-list-item class="hovered md-2-line" ng-repeat="item in $ctrl.list" ng-click="$ctrl.goto(item.ID)">
                            <img ng-src="{{item.Picture}}" class="md-avatar" >
                            <div class="md-list-item-text" flex>
                                <md-list-item-text >
                                <h3>{{item.DisplayName}} ({{item.Login}})</h3>
                                <div ng-if="item.Email">{{item.Email}}</div></md-list-item-text>
                            </div>
                        </md-list-item>
                    </md-list>    
                </md-content>
            </md-card>
        </div>
    `
}