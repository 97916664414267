/**
 * Created by ilja on 21.11.16.
 */


import {AuthRegisterEmailController} from './auth_register_email'

class AuthServiceListContoller {
    constructor(AuthService, $scope, $mdDialog, $templateCache) {

        var self = this;
        this.$templateCache = $templateCache;
        this.AuthService = AuthService;
        this.$mdDialog = $mdDialog;
        // this.ServiceList = AuthService.ServiceList();
        this.ServiceList = [];
        AuthService.ServiceList();
            // .then(function (r) {
            //     self.ServiceList = r;
            //     self.createList();
            // }, function (err) {
            // });

        $scope.$watchCollection(
            function () {
                return self.AuthService.serviceList;
            },
            function (c) {
                self.ServiceList = c;
                self.createList();
            }
        );
        this.$scope = $scope;
        this.invite;
    }

    $onInit(){
        this.createList();
    }

    createList() {
        var f = {};
        if(this.services) {
            if(_.isString(this.services)) {
                this.services = [this.services];
            }
            if(_.isArray(this.services)){
                this.list = _.filter(this.ServiceList, function (val) {
                    return _.indexOf(this.services, val.Name ) != -1;
                }, this);
            }
        }
        if(!this.services) {
            f[this.type] = true;
            this.list = _.where(this.ServiceList, f);
        }
    }

    login (service, scopes = []) {
        switch(service){
            case 'email' :
                var self = this;
                var t= self.$templateCache.get('kuberlab/auth/auth_register_email.html');
                this.$mdDialog.show({
                    template: t,
                    controller: AuthRegisterEmailController,
                    controllerAs: "$ctrl",
                    parent: angular.element(document.body),
                    clickOutsideToClose:true,
                })
                    .then(
                        function (data) {
                            if(data && data.action == 'signIn') {
                                self.login('gitlab');
                            }
                        },
                        function (data) {
                            // debugger;
                        }
                    );
                break;
            default:
                this.AuthService.authInService(service, this.invite, this.type == 'Add' ? scopes.join(',') : null);
        }

    }

    getIcon (name) {
        return this.AuthService.getIcon(name);
    }
}

export const AuthServiceList = {
    bindings: {
        type: "<",
        invite: '=',
        services: "<"
    },
    template: function ($templateCache) {
        return $templateCache.get('kuberlab/auth/auth_service_list_component.html');
    },
    controller: AuthServiceListContoller
};
