

export default function ($stateProvider) {
    $stateProvider

        .state('error',{
            parent: 'cu',
            url : '/error?:api_error:group:type:args:message',
            views: {
                'layout-page-content@' : {
                    template: `
                        <div flex layout='column' layout-align="center center" md-theme="pageHomeWelcome" class="home_welcome_block">
                            <div class="main_width_greed" >
                                <home-card-welcom >
                                    <cmp-error-page error="$ctrl.error" style="width: 500px"></cmp-error-page>
                                </home-card-welcom>
                            </div>
                        </div>
                        `,
                    controller: function ($state, $templateCache, $translate) {
                        // debugger;
                        this.error = JSON.parse($state.params.api_error);
                        // debugger;
                        // $translate( [ "MISSING_KEY", "COMMON_OK" ] )
                        //     .then(
                        //         (txt) => {
                        //             debugger;
                        //             console.log(txt); },
                        //         (txt) => {
                        //             debugger;
                        //             console.log(txt); });
                        // this.$state = $state;
                        // if($state.params.api_error) {
                        //     this.apiError = JSON.parse($state.params.api_error);
                        //     this.error = $templateCache.get('kuberlab/error/items/' + this.apiError.data.ErrorType.toLowerCase() + '.html');
                        //     if(!this.error)
                        //         this.error = $templateCache.get('kuberlab/error/items/default.html');
                        // } else {
                        //     this.error = $templateCache.get('kuberlab/error/items/' + $state.params.group + '.html');
                        //     if(!this.error)
                        //         this.error = $templateCache.get('kuberlab/error/items/default.html');
                        // }
                        // debugger;
                        // this.error = $templateCache.get('kuberlab/error/items/' + $state.params.group + '.html');
                        // if(!this.error)
                        //     this.error = $templateCache.get('kuberlab/error/items/default.html');
                    },
                    controllerAs : '$ctrl'
                }
            }
        })
}
