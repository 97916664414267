// .directive("selectNgFiles", function() {
//     return {
//         require: "ngModel",
//         link: function postLink(scope,elem,attrs,ngModel) {
//             elem.on("change", function(e) {
//                 var files = elem[0].files;
//                 ngModel.$setViewValue(files);
//             })
//         }
//     }
// });

export function SelectNgFile() {
    return {
        require: "?ngModel",
        link: function postLink(scope, elem, attrs, ngModel) {
            elem.on("change", function(e) {
                debugger;
                var files = elem[0].files;
                ngModel.$setViewValue(files);
            })
        }
    }
}
