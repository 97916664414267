export class AuthRequestController {
    constructor(dialog) {
        this.$mdDialog = dialog
    }
}

export const AuthRequestComponent = {
    controller: AuthRequestController,
    template : AuthRequestTemplateComponent
};

export const AuthRequestTemplateComponent = `
    <md-dialog aria-label="" class="installFromChart">
            <md-toolbar class="md-raised md-primary">
              <div class="md-toolbar-tools">
                 <h2>Login</h2>
                <span flex></span>
                <md-button class="md-icon-button" ng-click="$ctrl.$mdDialog.cancel()">
                  <md-icon md-svg-src="core:close" aria-label="Close dialog"></md-icon>
                </md-button>
              </div>
            </md-toolbar>
        <md-dialog-content class="flex layout-column">
            <div flex layout='column' layout-align="center center" md-theme="pageHomeWelcome" class="home_welcome_block">
                        <home-card-welcom class="md-padding">
                            <home-card-welcom-content>
                            <h2><cmp-logo></cmp-logo></h2>
                            <div class="content">This action requires authorization</div>
                            </home-card-welcom-content>
                            <home-card-welcom-actions >
                            <auth-service-list type="'Login'"></auth-service-list>
                            </home-card-welcom-actions>
                        </home-card-welcom>
                </div>
        </md-dialog-content>
    </md-dialog>`
;
