export class MlmodelFromProjectController {
    constructor($scope, application, validate, MlmodelService, $state, action, job, $mdDialog, parentController, StorageService) {
        this.StorageService = StorageService;
        this.action = action;
        this.$state = $state;
        this.job = job;
        this.$scope = $scope;
        this.$mdDialog = $mdDialog;
        this.parentController = parentController;

        this.$scope.mlmodels = [
            {
                DisplayName: "Create New"
            }
        ];

        this.$scope.newVersionName = 'New version';
        this.$scope.version = this.$scope.newVersionName;
        this.getDefVersion();

        this.$scope.asNew = true;
        this.mlResource = MlmodelService.getResource($state.params);
        this.mlResource.query()
            .$promise
            .then(
                (r) => {
                    this.$scope.mlmodels = this.$scope.mlmodels.concat(r);
                },
                (err) => {
                }
            )
            .finally(() => {
                this.complite = true;
            });

        this.$scope.setModel = (model) => {
            if (model) {
                var m = _.findWhere(this.$scope.mlmodels, {ID: model});
                // this.$scope.data = _.extend({}, _.omit(m ,'Config'), {Configuration: m.Config});
                this.$scope.data.Name = m.Name;
                this.$scope.data.DisplayName = m.DisplayName;
                this.$scope.data.Desctiption = m.Desctiption;

                this.$scope.asNew = false;
                this.$scope.Keywords = m.Keywords.join(',');
            } else {
                // this.$scope.data = this.getDefConfig(); //{};
                this.$scope.data.Name = null;
                this.$scope.data.DisplayName = null;
                this.Description = '';
                this.$scope.Keywords = '';
                this.$scope.asNew = true;
            }
            this.getVersions();
        };
        this.$scope.validate = validate;
        this.application = application;
        this.$scope.serveObj = this.servingConfig();
        var then = this;
        this.$scope.cancel = () => {
            this.$mdDialog.cancel();
        };

        this.$scope.setVersion = (item) => {
            if(this.$scope.version != this.$scope.newVersionName){
                this.$scope.data.Version = this.$scope.version;
                var ver = _.findWhere(this.$scope.versions, {Version: this.$scope.data.Version});
                // if(ver && ver.Config) {
                //     this.$scope.data.Configuration = ver.Config;
                // }else {
                //     this.$scope.data = this.getDefConfig();
                // }
            } else {
                this.$scope.data.Version = "";
                this.$scope.setModel(this.$scope.data.ID);
            }
        };

        this.$scope.data = this.getDefConfig();
    }

    servingConfig() {
        return _.first(this.application.Configuration.spec.serving) || {};
    }

    getDefConfig () {
        var sConf = this.servingConfig();
        var s = _.extend(
            {},
            sConf.resources,
            {
                command: sConf.command,
                images: sConf.images,
                // source: [{repository: "", directory: "", revision: ""}] ,
                ports: sConf.ports
            }
        );

        var volume, path;
        if(this.job.exec_info && this.job.exec_info.model_path && this.application.Configuration.spec.default_mount_path){
            var mVolume = this.job.exec_info.model_path.replace( this.application.Configuration.spec.default_mount_path + '/', '').split('/');
            volume = mVolume.shift();
            path = mVolume.join('/');
        }

        return  {
            Volume: volume,
            Path: path,
            Configuration: {
                serving:  s,
                servingSpec: sConf.spec
            }
        };
    }

    getDefVersion() {
        this.$scope.versions = [{Version: this.$scope.newVersionName}];
    }

    getVersions() {
        this.getDefVersion();
        if(!this.$scope.data.ID) return false;
        var model = _.findWhere(this.$scope.mlmodels, {ID : this.$scope.data.ID});
        // this.mlResource.versions({name: this.$scope.data.Name})
        this.mlResource.versions({name: model.Name})
            .$promise
            .then(
                (r) => {
                    this.$scope.versions = this.$scope.versions.concat(r);
                },
                (err) => {}
            )

    }

    save (form) {
        if (!form.$valid) return false;
        this.$scope.data.Keywords = this.$scope.Keywords ? this.$scope.Keywords.split(',') : [];
        this.$scope.loading = true;
        this.parentController.action('save', {task: this.job.task_name, build: this.job.build}, this.$scope.data, (r, params, isError) => {
            this.$scope.loading = false;
            if (isError) {
                this.$scope.error = r;
            } else {
                // this.$state.go('wsMlmodelItem', {wsId: r.WorkspaceName, item: r.Name});
                this.$state.go('wsCatalog.item', {wsId: r.WorkspaceName, Interface: 'mlmodel', item: r.Name});
                this.$mdDialog.cancel();
            }
        });
    }
}
