export class InstallFromChart {
    constructor(  UserService, ProjectService, ProjectEnvService, ProjectClusterService,  $scope, ChartsService, chart, dialog){
        this.dataChart = {};
        this.editorOptions = {
            mode : 'tree',
            expanded: true
        };
        this.$mdDialog = dialog;
        this.data = {};
        this.$scope = $scope;
        this.wsList = UserService.ws;
        this.projectList;
        this.ProjectService = ProjectService;
        this.ProjectEnvService = ProjectEnvService;
        this.ProjectClusterService = ProjectClusterService;
        this.selectedIndex = 0;
        if(this.wsList.length && this.wsList.length == 1) {
            this.data.workspace_name = this.wsList[0];
        }

        var self = this;
        this.chart = chart;
        this.target_applications= '';

        this.ChartsService = ChartsService;
        ChartsService._resource.values({chartName: chart.Name, chartVersion: chart.Version})
            .$promise
            .then(
                function (r) {
                    self.values = r.toJSON();
                },
                function (err) {
                    debugger
                }
            );

        if(this.wsList && this.wsList.length == 1){
            this.data.workspace_name = this.wsList[0].Name;
            this.wsChange();
        }
    }

    wsChange () {
        this.dataChart = {
            workspace_name : this.data.workspace_name
        };
        this.projectList = null;
        this.envList = null;
        this.clusterList = null;
        this.loadProjects();
    }

    loadProjects () {
        var self = this;
        this.loadingProjects = true;
        this.projectList = null;
        this.prResource = this.ProjectService.getProjectResource(this.dataChart.workspace_name, true);
        this.prResource.query()
            .$promise
            .then(
                function (r) {
                    self.data = self.dataChart;
                    if(r.length == 1) {
                        self.data.project_name = r[0].Name;
                        self.projectChange();
                    }
                    self.projectList = r;

                },
                function () {
                    self.projectList = null;
                }
            )
            .finally(function () {
                self.loadingProjects = false;
            })
    }

    projectChange () {
        this.dataChart = {
            workspace_name : this.data.workspace_name,
            project_name: this.data.project_name
        };
        this.envList = null;
        this.clusterList = null;
        this.loadEnv();
    }

    loadEnv() {
        var self = this;
        this.loadingEnv = true;
        this.envResource = this.ProjectEnvService.getResource({wsId: this.dataChart.workspace_name, projectName: this.dataChart.project_name} , true);
        this.envResource.query()
            .$promise
            .then(
                function (r) {
                    self.data = self.dataChart;
                    if(r.length == 1) {
                        self.data.environment = r[0].Name;
                        self.envChange();
                    }
                    self.envList = r;

                },
                function () {
                    self.envList = null;
                }
            )
            .finally(function () {
                self.loadingEnv = false;
            })
    }

    envChange() {
        this.dataChart = {
            workspace_name : this.data.workspace_name,
            project_name: this.data.project_name,
            environment: this.data.environment,
            clusters : [],
            values: {},
            target_applications: []
        };
        this.clusterList = null;
        this.loadClusters();
    }

    loadClusters() {
        var self = this;
        this.loadingClusters = true;
        this.clusteresource = this.ProjectClusterService.getResource({wsId: this.dataChart.workspace_name, projectName: this.dataChart.project_name, envName: this.dataChart.environment} , true);
        this.clusteresource.query()
            .$promise
            .then(
                function (r) {
                    self.data = self.dataChart;
                    self.clusterList = r;
                },
                function () {
                    self.clusterList = null;
                }
            )
            .finally(function () {
                self.loadingClusters = false;
            })
    }

    clusterToggle(item, list) {
        var idx = list.indexOf(item);
        if (idx > -1) {
            list.splice(idx, 1);
        }
        else {
            list.push(item);
        }
    }

    clusterExists (item, list) {
        return list.indexOf(item) > -1;
    }

    installAction() {
        var self = this;
        this.dataChart.values = this.values;
        this.dataChart.target_applications = this.target_applications;
        // if(this.target_applications) {
        //     this.dataChart.target_applications = _.compact(_.map(this.target_applications.split(','), function (val) { return $.trim(val); } ));
        // }
        this.instaling = true;
        this.ChartsService._resource.install({chartName: this.chart.Name, chartVersion: this.chart.Version}, this.dataChart)
            .$promise
            .then(
                function (r) {
                    self.$mdDialog.hide( {chart: r, data: self.dataChart} );
                },
                function (err) {
                    self.installError = err;
                }
            )
            .finally(function(){
                self.instaling = false;
            })
    }

    next() {
        this.selectedIndex = this.selectedIndex + 1;
    }

    isOk() {
        return !(
            this.target_applications
            && this.target_applications.length
            && this.dataChart.clusters
            && this.dataChart.clusters.length
            && this.selectedIndex == 2
        );
    }

    tabDisabled (tab) {
        switch(tab) {
            case 1:
                return !( this.dataChart.clusters && this.dataChart.clusters.length);
                break;
            case 2:
                return !( this.target_applications && this.target_applications.length && !this.targetError );
        }
        return false;
    }

};




export const InstallFromChartTemplate =
    `<md-dialog  class="installFromChart">
        <md-toolbar class="md-raised md-primary">
          <div class="md-toolbar-tools">
            <h2>Install</h2>
            <span flex></span>
            <md-button class="md-icon-button" ng-click="$ctrl.$mdDialog.cancel()">
              <md-icon md-svg-src="core:close" aria-label="Close dialog"></md-icon>
            </md-button>
          </div>
        </md-toolbar>
    <md-dialog-content style="max-width:800px;max-height:810px;">
        <md-tabs class="md-accent_" style="min-width: 600px;" md-selected="$ctrl.selectedIndex" md-border-bottom md-autoselect>
          <md-tab id="tab1">
            <md-tab-label>Select cluster</md-tab-label>
            <md-tab-body>
              <div class="selectCluster flex layout-row layout-align-space-between-start" >
                <div flex="25">
                    <h4>Workspace</h4>
                    <md-content>
                        <md-radio-group ng-model="$ctrl.data.workspace_name" ng-change="$ctrl.wsChange()">
                            <md-radio-button ng-repeat="ws in $ctrl.wsList" value="{{ws.Name}}">{{ws.DisplayName}}</md-radio-button>
                        </md-radio-group>
                    </md-content>
                </div>
                <div flex="25">
                    <h4>Projects</h4>
                    <md-content>
                        <md-radio-group ng-if="$ctrl.projectList" ng-model="$ctrl.data.project_name" ng-change="$ctrl.projectChange()">
                            <md-radio-button ng-repeat="pr in $ctrl.projectList" value="{{pr.Name}}">{{pr.DisplayName}}</md-radio-button>
                        </md-radio-group>
                        <div ng-if="$ctrl.loadingProjects" layout="row" layout-sm="column" layout-align="space-around">
                          <md-progress-circular></md-progress-circular>
                        </div>
                        <div ng-if="$ctrl.projectList && $ctrl.projectList.length == 0">
                            no item
                        </div>
                    </md-content>
                </div>
                <div flex="25">
                    <h4>Environments</h4>
                    <md-content>
                        <md-radio-group ng-if="$ctrl.envList" ng-model="$ctrl.data.environment" ng-change="$ctrl.envChange()">
                            <md-radio-button ng-repeat="env in $ctrl.envList" value="{{env.Name}}">{{env.Name}}</md-radio-button>
                        </md-radio-group>
                        <div ng-if="$ctrl.loadingEnv" layout="row" layout-sm="column" layout-align="space-around">
                          <md-progress-circular></md-progress-circular>
                        </div>

                        <div ng-if="$ctrl.envList && $ctrl.envList.length == 0">
                            no item
                        </div>
                    </md-content>
                </div>
                <div flex="25">
                    <h4>Clusters</h4>
                    <md-content>
                         <div  ng-repeat="item in $ctrl.clusterList">
                              <md-checkbox ng-checked="$ctrl.clusterExists(item.Name, $ctrl.dataChart.clusters)" ng-click="$ctrl.clusterToggle(item.Name, $ctrl.dataChart.clusters)">
                                {{ item.Name }}
                              </md-checkbox>
                            </div>
                         <div ng-if="$ctrl.loadingClusters" layout="row" layout-sm="column" layout-align="space-around">
                          <md-progress-circular></md-progress-circular>
                        </div>

                        <div ng-if="$ctrl.clusterList && $ctrl.clusterList.length == 0">
                            no item
                        </div>
                    </md-content>
                </div>
              </div>
            </md-tab-body>
          </md-tab>
          <md-tab id="tab2" ng-disabled="$ctrl.tabDisabled(1)">
            <md-tab-label>Application</md-tab-label>
            <md-tab-body>
                <cmp-install-chart-applications applications="$ctrl.target_applications"></cmp-install-chart-applications>
            </md-tab-body>
          </md-tab>
          <md-tab id="tab3" ng-disabled="$ctrl.tabDisabled(2)">
            <md-tab-label>Valums</md-tab-label>
            <md-tab-body>
                <div ng-jsoneditor="onLoad" ng-if="$ctrl.values" ng-model="$ctrl.values" options="$ctrl.editorOptions" ></div>
            </md-tab-body>
          </md-tab>
        </md-tabs>
    </md-dialog-content>
        <cmp-api-error-service ng-if="$ctrl.installError" error="$ctrl.installError"></cmp-api-error-service>
        <md-dialog-actions layout="row">
          <md-progress-linear ng-if="$ctrl.instaling" flex></md-progress-linear>
          <md-button ng-click="$ctrl.$mdDialog.cancel()">Cancel</md-button>
          <md-button ng-click="$ctrl.next()" ng-disabled="$ctrl.tabDisabled($ctrl.selectedIndex + 1)">Next</md-button>
          <md-button ng-click="$ctrl.installAction()" ng-disabled="$ctrl.isOk()">Install</md-button>
        </md-dialog-actions>
    </md-dialog>`
;

//ng-disabled="(!$ctrl.dataChart.clusters || $ctrl.dataChart.clusters.length == 0) && ( $ctrl.target_applications && $ctrl.target_applications != '') "
