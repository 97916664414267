class AuthFormComponentController {
  constructor(AuthService, $scope, $rootScope, $window, KuberlabService, vcRecaptchaService, RestoreService, validate) {
    this.validate = validate;
    this.uiRevCommit = $rootScope.uiRevCommit;
    this.RestoreService = RestoreService;
    this.$window = $window;
    this.$scope = $scope;
    // this.AuthService = AuthService;
    this.AuthService = AuthService;
    this.KuberlabService = KuberlabService;
    this.vcRecaptchaService = vcRecaptchaService;

    this.usersWithoutEmails = GlobalSettings.users_without_emails;
    this.registration_disabled = GlobalSettings.registration_disabled;

    this.action = 'login';
    this.settings = {};

    KuberlabService.settings().then((settings) => {
        this.settings = settings;
        this.initCaptcha();
      }
    );

    AuthService.ServiceList().then((data) => {
      this.ServiceList = data.filter((service) => (service.Login && service.Name !== 'email'));
      this.ServiceListComplite = true;
    });

  }

  goTo(actionName) {
    this.action = actionName;
    this.error = null;
  }

  is(action) {
    return this.action === action;
  }

  initCaptcha() {
    const $scope = this.$scope;

    $scope.response = null;
    $scope.widgetId = null;
    $scope.setResponse = (response) => {
      console.info('Response available', response);
      $scope.response = response;
    };

    $scope.setWidgetId = (widgetId) => {
      console.info('Created widget ID: %s', widgetId);
      $scope.widgetId = widgetId;
    };

    $scope.cbExpiration = () => {
      console.info('Captcha expired. Resetting response object');
      this.vcRecaptchaService.reload($scope.widgetId);
      $scope.response = null;
    };
  }

  login(form) {
    if (!form.$valid) return;
    this.error = null;

    if (this.invite) this.dataLogin.Invite = this.invite.ID;

    this.AuthService.auth.login(this.dataLogin).$promise.then(() => {
        this.$window.location = '/';
      }, (err) => {
        this.error = err;
      }
    )
  }

  register(form) {
    if (!form.$valid) return;
    this.error = null;
    this.isRegistering = true;

    this.data['g-recaptcha-response'] = this.$scope.response;
    var data = angular.copy(this.data);
    if (this.invite) data.Invite = this.invite.ID;

    this.AuthService.register.create(data).$promise.then(() => {
        this.registerComplete = true;
        this.isRegistering = false;
      }, (err) => {
        this.error = err;
        this.vcRecaptchaService.reload(this.$scope.widgetId);
        this.isRegistering = false;
      }
    )
  }

  resetPassword(form) {
    if (!form.$valid) return;
    this.error = null;
    this.resetComplete = false;
    this.dataReset['g-recaptcha-response'] = this.$scope.response;
    this.RestoreService.getResource().passwordreset(this.dataReset).$promise.then(() => {
        this.resetComplete = true;
      }, (err) => {
        this.error = err;
        this.vcRecaptchaService.reload(this.$scope.widgetId);
      }
    );
  }

  loginViaService(serviceName) {
    this.AuthService.authInService(serviceName, this.invite);
  }
}

export const AuthFormComponent = {
  bindings: {
    invite: '='
  },
  template: function($templateCache) {
    return $templateCache.get('kuberlab/auth/auth_form_component.html');
  },
  controller: AuthFormComponentController
};
