
import {CatalogBaseListController} from '../catalog/base/list_controller'

export class InferenceListController extends CatalogBaseListController {
    constructor (InferenceService, $scope,  $element) {

        super();
        this.$element = $element;
        this.$scope = $scope;
        this.Service = InferenceService;
        this.stateName = "wsInferenceItem";
        this.stateList = "wsInferenceList";
        this.stateNew = "wsInferenceAdd";
    }
}

