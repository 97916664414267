
import {ProjectService} from './projects_service'
import {ProjectEnvService} from './project_env_service'
import {ProjectsCardHomeComponent} from './card/project_card_home'
import {ProjectItemComponent} from './card/project_card_item'
import StateConfig from './projects_stateconfig'
import RepoModule from '../repo/repo_module'
import ClustersModule from './clusters/project_clusters_module'
import ApplicationsModule from './applications/project_applications_module'
import TasksModule from './tasks/project_tasks_module'
import InstanceModule from './instances/project_instances_module'
import {ProjectEnvComponent} from './card/project_env_component'
import AgularJsoneditor from '../angular-jsoneditor/module'
import Help from '../help/help_module'
import AppModule from './apps/project_apps_module'
import {ProjectAppsService} from './apps/project_apps_service'
import MlProjects from './ml_projects/project_ml_projects_module'
import ClusterStorage from './storages/projects_storages_module'
import {ProjectsList} from './projects_list_controller'

export default angular
    .module('projects', [
        'ngMaterial',
        RepoModule.name,
        ClustersModule.name,
        ApplicationsModule.name,
        TasksModule.name,
        InstanceModule.name,
        AppModule.name,
        Help.name,
        AgularJsoneditor.name,
        MlProjects.name,
        ClusterStorage.name
    ])
    .constant('autoUpdate', 60000)
    .constant('projectPageEnvDefault', 'master')
    .constant('projectPageTabDefault', 'app resource')
    .constant('projectPageTabs',
        [
            {
                title : 'app resource',
                content : '<cmp-project-cluster-apps></cmp-project-cluster-apps>'
            },
            {
                title : 'resources',
                content : '<cmp-project-cluster-instances></cmp-project-cluster-instances>'
            },
            {
                title : 'applications',
                content : '<cmp-projects-applications></cmp-projects-applications>'
            },
            {
                title : 'clusters',
                content : '<cmp-projects-clusters></cmp-projects-clusters>',
                addButtonConfig:[]
            },
            {
                title : 'tasks',
                content : '<cmp-project-tasks></cmp-project-tasks>'
            },
            {
                title: "mlapps projects",
                content: '<cmp-projects_ml></cmp-projects_ml>'
            },
            {
                title: "storage",
                content: '<cmp-projects_storages></cmp-projects_storages>'
            }

        ]
    )
    .constant('projectPageViewTabs',{
        devops: {
            title: "Devops",
            tabs: [
                'app resource',
                'resources',
                'applications',
                'clusters',
                'tasks',
                'storage'
            ]
        },
        scientist: {
            title: "scientist",
            tabs: [
                'app resource',
                'resources',
                'applications',
                'clusters',
                'tasks',
                'storage'
            ]
        }
    })
    .config(StateConfig)
    .service('ProjectService', ProjectService)
    .service('ProjectEnvService', ProjectEnvService)
    .service('ProjectAppsService', ProjectAppsService)
    .component('cmpProjectsCardHome', ProjectsCardHomeComponent)
    .component('cmpProjectItem', ProjectItemComponent)
    .component('cmpProjectEnv', ProjectEnvComponent)
    .component('cmpProjectsListShort', {
        bindings: {
            workspace: '<',
            mainPage: '<'
        },
        controller: ProjectsList,
        template: ($templateCache) => $templateCache.get('kuberlab/projects/card/projects_list_short.html')
    })
