class UserCardBigController {
    constructor($state, UserService, WorkspaceService, Utils, $scope, ErrorService){
        this.data;
        this.updData;
        this.ErrorService = ErrorService;
        this.$state = $state;
        // debugger
        this.asForm = false;
        this.UserService = UserService;
        this.WorkspaceService = WorkspaceService;
        this.Utils = Utils;
        this.loginEditing = false;
        this.$scope = $scope;
        this.loginOrig;
        this.GlobalSettings = GlobalSettings;
    }

    $onInit(){
        this.email = this.data.Email || 'Add email';
    }

    editAction () {
        this.updData = _.clone(this.data);
        this.asForm = true;
        this.uploadError = null;
    }

    viewAction () {
        this.asForm = false;
    }

    save (form) {
        var self = this;
        if(form.$valid) {
            self.uploadError = null;
            self.isLoading = true;
            this.UserService.save.update(this.updData)
                .$promise
                .then(
                    (r) => {
                        _.each(r, function(v, k) {
                            self.data[k] = v;
                        });
                        self.UserService.getWs(true);
                        if(self.file && self.file.length){
                            self.uploadFile();
                        } else {
                            self.viewAction();
                        }
                    },
                    (e) => {
                        self.uploadError = e;
                    }
            )
                .finally(() => {
                    self.isLoading = false;
                });
        }
    }

    showMy() {
        this.$state.go('settingsCurrentUser');
    }

    editLogin() {
        this.loginEditing = true;
        this.loginOrig = this.data.Login;
    }

    cancelEditLogin() {
        this.loginEditing = false;
        this.data.Login = this.updData.Login = this.loginOrig;
    }

    saveLogin() {
        if (this.updData.Login == this.loginOrig) {
            this.loginEditing = false;
            return;
        }
        var self = this;
        this.uploadError = null;
        this.UserService.login.update({login : this.updData.Login}, {login : this.updData.Login}).$promise.then(
            function(r) {
                self.data.Login = self.updData.Login = r.Login;
                self.data.Workspace = self.updData.Workspace = r.Workspace;
                self.loginEditing = false;
                self.UserService.getWs(true);
            },
            function(err) {
                self.uploadError = err;
                self.cancelEditLogin();
            }
        );
    }


    deleteAccount() {
        var cb_delete = (params = {}) => {
            this.UserService.deleteAccount.delete(params)
                .$promise
                .then(
                    function(r) {
                        window.location.href = '/';
                    },
                    (err) => {
                        this.ErrorService.error(err, cb_delete);
                    }
                );
        };
        cb_delete();
    }

    // deleteAccount() {
    //   this.Utils.confirmDialog({
    //     title: 'SETTINGS_DELETE_USER_TITLE',
    //     text: 'SETTINGS_DELETE_USER_MESSAGE',
    //     okText: 'COMMON_DELETE'
    //   }).then(() => {
    //     this.UserService.deleteAccount.delete().$promise.then(function() {
    //       window.location.href = '/';
    //     }).catch(e => {
    //       console.error(e);
    //     });
    //   });
    // }

    uploadFile (form) {
        if((form && !form.$valid) || !this.file || !this.file.length) return false;
        var file = this.file;
        var self = this;
        this.loadingError = false;
        this.isLoading = true;
        var formData = new FormData();
        this.uploadError = null;
        angular.forEach(file, (obj) => {
            if(!obj.isRemote){
                formData.append('src[]', obj.lfFile);
            }
        });
        this.UserService.save.upload({picture: file[0].lfFile})
            .$promise
            .then(
                (r) => {
                    self.data = r;
                    self.lfApi.removeAll();
                    if(!form) {
                        self.viewAction();
                    }
                },
                (err) => {
                    self.uploadError = err;
                }
            )
            .finally(() => {
                this.isLoading = false;
            });
    }

    sendEmail(form) {
        if(!form.$valid) return;
        this.editEmailError = false;
        this.UserService.getResource().email(this.dataEmail)
            .$promise
            .then(
                (r) => {
                    this.editEmailComplite = true;
                },
                (err) => {
                    this.editEmailError = err;
                }
            )
    }
}

export const UserCardBigComponent = {
    bindings: {
        data : '=',
        stateParams: '<'
    },
    template : function ($templateCache) {
        return $templateCache.get('kuberlab/user/card/user_card_big_component.html');
    },
    controller: UserCardBigController
};

export const UserCardBigNewComponent = {
    bindings: {
        data : '=',
        stateParams: '<'
    },
    template : function ($templateCache) {
        return $templateCache.get('kuberlab/user/card/user_card_big_component_new.html');
    },
    controller: UserCardBigController
};
