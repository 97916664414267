import {WsaItemController} from '../wsa_item_controller'


export const WsaCardItemComponent = {
    bindings: {
        data: '=',
        listController: '<'
    },
    template: `
        <md-list-item ng-if="$ctrl.asForm">
        <form name="addWSA" ng-submit="$ctrl.save(addWSA)" flex >
            <md-card flex>
                <md-card-title ng-switch="$ctrl.data.Service">
                    <md-card-title-text ng-switch-when="'google'">{{"WSA_TITLE_GCE"|translate}}</md-card-title-text>
                    <md-card-title-text ng-switch-when="'amazon'">{{"WSA_TITLE_AWS"|translate}}</md-card-title-text>
                    <md-card-title-text ng-switch-when="'kubernetes'">{{"WSA_TITLE_KUBERNETES"|translate}}</md-card-title-text>
                </md-card-title>
<!-- google -->
                <md-card-content class="md-padding" layout="column" ng-if="$ctrl.data.Service == 'google'" ng-include="'kuberlab/wsa/card/items/google.html'"></md-card-content>
<!-- amazon -->                
                <md-card-content ng-if="$ctrl.data.Service == 'amazon'" flex layout-wrap layout="row" layout-align="start top" ng-include="'kuberlab/wsa/card/items/amazon.html'" ></md-card-content>
<!-- CS3 -->                
                <md-card-content ng-if="$ctrl.data.Service == 'cs3'" flex layout-wrap layout="row" layout-align="start top" ng-include="'kuberlab/wsa/card/items/cs3.html'"></md-card-content>
<!-- kubernetes -->                
                <md-card-content ng-if="$ctrl.data.Service == 'kubernetes'" flex layout-wrap layout="column" layout-align="start top" ng-include="'kuberlab/wsa/card/items/kubernetes.html'"></md-card-content>
<!-- git -->                
                <md-card-content ng-if="$ctrl.data.Service == 'git'" flex layout-wrap layout="column" layout-align="start top" ng-include="'kuberlab/wsa/card/items/git.html'"></md-card-content>
<!-- docker -->                
                <md-card-content ng-if="$ctrl.data.Service == 'docker'" flex layout-wrap layout="column" layout-align="start top" ng-include="'kuberlab/wsa/card/items/docker.html'"></md-card-content>
<!-- docker -->                
                <md-card-content ng-if="$ctrl.data.Service == 'kubernetes_gke'" flex layout-wrap layout="column" layout-align="start top" ng-include="'kuberlab/wsa/card/items/kubernetes_gke.html'"></md-card-content>
<!-- end -->                
                <md-card-actions layout="row" layout-align="end center">
                    <div flex>
                        <md-progress-linear ng-if="$ctrl.saving"></md-progress-linear>
                        <cmp-api-error-service ng-if="$ctrl.sendErrorMessage" error="$ctrl.sendErrorMessage" ></cmp-api-error-service> 
                    </div>
                    <md-button type="submit"><md-icon md-svg-icon="core:plus"></md-icon> {{"COMMON_ADD"|translate}}</md-button>
                    <md-button ng-click="$ctrl.cancel()"><md-icon md-svg-icon="core:close"></md-icon> {{"COMMON_CANCEL"|translate}}</md-button>
                </md-card-actions>
            </md-card>
            </form>
        </md-list-item>
        
        <md-list-item ng-if="!$ctrl.asForm" class="hovered cmp-clickable"> 
            <md-icon md-svg-icon="{{$ctrl.AuthService.getIcon($ctrl.data.Service)}}" class="md-avatar-icon"></md-icon>
            <md-list-item-text flex>
                <h4>{{$ctrl.data.Name}} <span ng-if="$ctrl.meta.length > 0">({{$ctrl.meta}})</span></h4>
                <p ng-if="$ctrl.data.Description.length > 0">{{$ctrl.data.Description}}</p>
            </md-list-item-text>
            <md-button class="md-secondary md-icon-button byHoverParent showByHoverParent" ng-click="$ctrl.deleteConfirm($event)" aria-label="invite user">
                <md-icon md-svg-icon="core:delete"></md-icon>
            </md-button>
        </md-list-item>
        
        `,
    controller: WsaItemController
};
