import {controller} from './template-component'

export class controllerTemplateDialog extends controller{
    constructor( TemplateService, $mdPanel, $scope, $mdDialog ) {
        super(TemplateService, $mdPanel, $scope, $mdDialog);
        this.type = 'job';
    }

    $onInit() {
        super.$onInit();
        this.getList();
    }

    listComplite() {}

    setModel (template) {
        this.current = template;
    }

    add(template) {
        this.$mdDialog.hide(jsyaml.safeLoad(this.templatesYaml[this.current]));
    }

    cancel() {
        this.$mdDialog.cancel();
    }
}

export const controllerTemplateDialogTpl = `
<md-dialog aria-label="Mango (Fruit)" style="width: 500px;">
    <md-toolbar>
      <div class="md-toolbar-tools">
        <h2>Application template list</h2>
        <span flex></span>
        <md-button class="md-icon-button" ng-click="$ctrl.cancel()">
          <md-icon md-svg-icon="core:close" aria-label="Close dialog"></md-icon>
        </md-button>
      </div>
    </md-toolbar>
    <md-dialog-content layout-align="start start" layout="row" style="width: 500px;">
        <div class="" flex="20" >
            <md-list>
                <md-list-item ng-repeat="item in $ctrl.list" ng-click="$ctrl.getTemplate(item.Name)">{{item.Title}}</md-list-item>
            </md-list>
        </div>
        <div style="width: 10px;"></div>
        <div flex>
            <h5>{{$ctrl.current}}</h5>
            <div ui-ace="{mode: 'yaml'}" ng-model="$ctrl.templatesYaml[$ctrl.current]"></div>
        </div>
    </md-dialog-content>
    <md-dialog-actions layout="row" layout-align="start center">
        <div flex><cmp-error-toast ng-if="$ctrl.error" error="$ctrl.error"></cmp-error-toast></div>
        <md-button ng-click="$ctrl.add(selected)">{{"COMMON_ADD" | translate}}</md-button>
    </md-dialog-actions>
</md-dialog>
`;