import {StorageNfsComponent} from './storage_nfs/storage_nfs_component';
import {StoragePvcComponent} from './storage_pvc/storage_pvc_component';
import {StorageGitComponent} from './storage_git/storage_git_component';
import {StorageHostComponent} from './storage_host/storage_host_component';
import {StorageS3bucketComponent} from './storage_s3bucket/storage_s3bucket_component';
import {StorageComponent} from './storage_component'
import {StorageViewComponent} from './storage_view_component'
import {StorageClusterComponent} from './storage_cluster/storage_cluster_component';
import {StoragePersistentComponent} from './storage_persistent/storage_persistent_component';
import {StorageDatasetComponent} from './storage_dataset/storage_dataset_component'
import {StorageDatasetFsComponent} from './storage_datasetfs/storage_datasetfs_component'
import {StorageModelComponent} from './storage_model/storage_model_component'
import PersistentModule  from './persistent/storage_persistent_module'
import {StorageService} from './storage_service'
import {StorageEmptyDirComponent} from './storage_emptydir/storage_emptydir_component';

export default angular
    .module('kuberlab_storage', [PersistentModule.name])
    .component('cmpStorageNfs', StorageNfsComponent)
    .component('cmpStorageEmptydir', StorageEmptyDirComponent)
    .component('cmpStoragePvc', StoragePvcComponent)
    .component('cmpStorageGit', StorageGitComponent)
    .component('cmpStorageHost', StorageHostComponent)
    .component('cmpStorageS3bucket', StorageS3bucketComponent)
    .component('cmpStorage', StorageComponent)
    .component('cmpStorageView', StorageViewComponent)
    .component('cmpStorageCluster', StorageClusterComponent)
    .component('cmpStoragePersistent', StoragePersistentComponent)
    .component('cmpStorageDataset',  StorageDatasetComponent)
    .component('cmpStorageDatasetfs',  StorageDatasetFsComponent)
    .component('cmpStorageModel',  StorageModelComponent)
    .service('StorageService', StorageService)
    .constant('storageList', {
        datasetFS: "dataset",
        gitRepo: 'GIT',
        nfs: 'NFS',
        clusterStorage: 'Cluster storage',

        s3bucket: "S3 bucket",
        // dataset: "Dataset local",
        model: "Model",
            // persistentVolumeClaim: 'PVC',
            hostPath: 'Host path',
        emptyDir: 'Empty directory'
            // persistentStorage: "STORAGE_PERSISTEN_STORAGE"

    })
;
