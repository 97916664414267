import {CatalogBaseItemNewController} from '../catalog/base/item_new_controller'

export class InferenceItemNewController extends CatalogBaseItemNewController {
    constructor (inference, InferenceService) {
        super();
        this.data = inference;
        this.Service = InferenceService;
        // this.delayNameCheck;
        this.stateName = 'wsInferenceItem';
    }
}
