import {AutoList} from './auto_list'

class controller extends AutoList {
    constructor() {
        super();
        this.item = {port: '', taregtPort: '', protocol: 'TCP'};
        this.protocols = ['TCP', 'UDP'];
    }
}

export const  WizardDeployPortsComponent = {
    bindings: {
        data : "="
    },
    controller: controller,
    template : `
        <div ng-repeat="port in $ctrl.list" flex>
            <ng-form name="chartPorts" class="flex layout-row layout-align-space-between-start">
                <md-input-container flex="50">
                    <label>Port</label>
                    <input type="number" name="port" ng-model="port.port" ng-required="$ctrl.canDelete($index)" ng-change="$ctrl.change(chartPorts, $index)">
                </md-input-container>
                <div flex="5"></div>
                <md-input-container flex="50">
                    <label>Target port</label>
                    <input type="number" name="targetPort" ng-model="port.taregtPort" ng-required="$ctrl.canDelete($index)" ng-change="$ctrl.change(chartPorts, $index)">
                </md-input-container>
                <div flex="5"></div>
                <md-input-container flex="none">
                    <label>Protocol</label>
                    <md-select ng-model="port.protocol" ng-change="$ctrl.change(chartPorts, $index)">
                        <md-option ng-repeat="p in $ctrl.protocols" ng-value="p" >{{p}}</md-option>
                    </md-select>
                </md-input-container>
                <div flex="5"></div>
                <div class="flex-10">
                    <md-button class="md-icon-button" ng-show="$ctrl.canDelete($index)" ng-click="$ctrl.delete($index)"><md-icon md-svg-icon="core:delete" ></md-icon></md-button>
                </div>
            </ng-form>
        </div>
    `
};
