class controller  {
    constructor(ApplicationsService, $state, projectJobParamSeparator) {
        this.$state = $state;
        this.ApplicationsService = ApplicationsService;
        this.projectJobParamSeparator = projectJobParamSeparator;
    }

    $onInit() {
        if(!this.data) this.data = {};
        this.paramsGlobal = [];
        this.paramsAdvansed = [];
        this.getParamsList();
    }

    transformList(list) {
        // debugger;
        this.paramsGlobal = [];
        this.paramsAdvansed = [];
        _.each(list, (v) => {
            let split = v.name.split(this.projectJobParamSeparator);
            if (split.length > 1) {
                // v.label = split.slice(1).join(this.projectJobParamSeparator);
                v.label = v.name;
                this.paramsAdvansed.push(v);
            } else {
                v.label = v.name;
                this.paramsGlobal.push(v);
            }
        });
// debugger;
        if (this.paramsGlobal.length == 0) {
            this.paramsGlobal = _.clone(this.paramsAdvansed);
            this.paramsAdvansed = [];
        }
    }

    getParamsList () {
        this.mainPrefix = 'main.';
        this.loading = true;
        this.ApplicationsService.getTaskparameters(this.$state.params.applicationName, true)
            .then(
                (r) => {
                    this.transformList(r);
                    this.paramsList = r;
                    // this.hasMain = false;
                    _.each(this.paramsList, (v, k) => {
                        // if(v.name.substr(0, this.mainPrefix.length) === this.mainPrefix) {
                        //     this.paramsList[k].isMain = true;
                        //     this.hasMain = true;
                        //     this.paramsList[k].dname = v.name.substr(this.mainPrefix.length);
                        // } else {
                        //     this.paramsList[k].isMain = false;
                        //     this.paramsList[k].dname = v.name;
                        // }
                        if(!this.data[v.name]){
                            this.data[v.name] = {search_type: v.type};
                        }
                    })
                }
            )
            .finally(() => {
                this.loading = false;
                this.loadded = true;
            });
    }

    add () {
        this.data[this.active.params] = {search_type: this.active.type};
        this.active = {};
        this.addForm = false;
    }

    delete(item) {
        delete this.data[item];
    }

    search() {
        this.loading = true;
        this.apiError = false;
        let data = {};
        _.each(this.data, (v, k) => {
            // fix for interval
            if (!_.isUndefined(v.min) && !_.isNull(v.min) && (_.isUndefined(v.max) || _.isNull(v.max))) {
                this.data[k].max = v.min;
            }
            if (!_.isUndefined(v.max) && !_.isNull(v.max) && (_.isUndefined(v.min) || _.isNull(v.min))) {
                this.data[k].min = v.max;
            }
            if (
                !_.isUndefined(v.value) && !_.isNull(v.value)
                ||
                ( !_.isUndefined(v.list) && v.list.length > 0 )
                ||
                !_.isUndefined(v.min) && !_.isNull(v.min)
                ||
                !_.isUndefined(v.max) && !_.isNull(v.max)
            ) {
                data[k] = v;
            }
        } );
        this.ApplicationsService.getResource({wsId: this.$state.params.wsId, application : this.$state.params.applicationName})
            .tasks_search({application : this.$state.params.applicationName}, data).$promise
            .then(
                (r) => {
                    this.list = r;
                },
                (err) => {
                    this.apiError = err;
                }
            )
            .finally(() => {this.loading = false;});
    }

    cancelFilter() {
        this.list = null;
        this.open = false;
    }

    isMainItem(item) {
        return item.isMain;
    }

    isNotMainItem(item) {
        return !item.isMain;
    }

    volumeList(param){
        var l = _.findWhere(this.paramsList, {name: param});
        return l ? l.values : [];
    }
}

export const ApplicationJobsFilterNewComponent = {
    controller: controller,
    bindings: {
        data: "=",
        list: "=",
        open: "=",
        apiLoading: "=",
        apiError: "="
    },
    template: `
<!--{{ $ctrl.paramsGlobal}}-->
<!--{{$ctrl.paramsAdvansed}}-->
        <cmp-application-jobs-filter-item ng-repeat="item in $ctrl.paramsGlobal | orderBy:'label'" item="item" parent="$ctrl" ></cmp-application-jobs-filter-item>
        <div ng-if="$ctrl.paramsAdvansed.length" class="cmp-block-compact">
            <!--<h5 class="cmp-block-compact-title cmp-clickable" ng-click="Additional = !!!Additional" >-->
                <!--Additional-->
                <!--<md-icon md-svg-icon="core:chevron-down" ng-if="!Additional"></md-icon>-->
                <!--<md-icon md-svg-icon="core:chevron-up" ng-if="Additional"></md-icon>-->
            <!--</h5>-->
            <div class="cmp-block-compact-title cmp-clickable" ng-click="Additional = !!!Additional" layout="row"  layout-align="start center">
                        <hr flex/>
                        <div >Additional parameters</div>
                        <md-icon md-svg-icon="core:chevron-down" ng-if="!Additional"></md-icon>
                        <md-icon md-svg-icon="core:chevron-up" ng-if="Additional"></md-icon>
                        <hr flex/>
                    </div>
            <!--<div class="cmp-block-compact-content" ng-if="Additional">-->
            <div ng-if="Additional">
                <cmp-application-jobs-filter-item ng-repeat="item in $ctrl.paramsAdvansed | orderBy:'label' " item="item" parent="$ctrl" ></cmp-application-jobs-filter-item>
            </div>
        </div>
        
        <!--<cmp-application-jobs-filter-item ng-if="!$ctrl.hasMain" ng-repeat="item in $ctrl.paramsList | orderBy:'name'" item="item" parent="$ctrl" ></cmp-application-jobs-filter-item>-->
        <!--<cmp-application-jobs-filter-item ng-if="$ctrl.hasMain" ng-repeat="item in $ctrl.paramsList | orderBy:'name' | filter:$ctrl.isMainItem" item="item" parent="$ctrl" ></cmp-application-jobs-filter-item>-->
        <!--<div ng-if="$ctrl.hasMain" class="cmp-block-compact">-->
            <!--<h5 class="cmp-block-compact-title cmp-clickable" ng-click="Additional = !!!Additional" >-->
                <!--Additional-->
                <!--<md-icon md-svg-icon="core:chevron-down" ng-if="!Additional"></md-icon>-->
                <!--<md-icon md-svg-icon="core:chevron-up" ng-if="Additional"></md-icon>-->
            <!--</h5>-->
            <!--<div class="cmp-block-compact-content" ng-if="Additional">-->
                <!--<cmp-application-jobs-filter-item ng-repeat="item in $ctrl.paramsList | orderBy:'name' | filter:$ctrl.isNotMainItem" item="item" parent="$ctrl" ></cmp-application-jobs-filter-item>-->
            <!--</div>-->
        <!--</div>-->
            
        <div layout="row" layout-align="start center">
            <div flex ng-if="!$ctrl.addForm" layout="row" layout-align="end center">
                <div flex>
                    <div ng-if="!$ctrl.loading && $ctrl.loadded && $ctrl.paramsList.length == 0">Parameters is empty</div>
                    <md-progress-linear ng-if="$ctrl.loading"></md-progress-linear>
                </div>
                <md-button ng-click="$ctrl.cancelFilter()">{{"COMMON_CANCEL" | translate}}</md-button>
                <md-button class="md-raised" ng-click="$ctrl.search()">{{"COMMON_FILTER" | translate}}</md-button>
            </div>
        </div>
    `
};
