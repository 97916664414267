class controller {

    constructor($mdDialog, BillingService, Utils, $scope) {
        this.mdDialog = $mdDialog;
        this.BillingService = BillingService;
        this.Utils = Utils;
        this.$scope = $scope;
    }

    // $onInit(){
    //     var self = this;
    //     this.cost = {};
    //     this.BillingService.getCost().then((r) => {
    //             _.each(r, (v) => {
    //                 var label = v.Label ? v.MeasuredResource + ":" + v.Label : v.MeasuredResource;
    //                 self.cost[label] = v.MeasuredCost;
    //                 // this.cost[v.MeasuredResource] = v.Divider;
    //             });
    //         this.$scope.apply();
    //         // console.log(this.cost);
    //         // debugger;
    //         },
    //         (err) => {
    //         }
    //     );
    // }

    // $onInit(){
    //     debugger;
    // }

    // $onInit() {
    //     var list = [];
    //     if(this.data.Limits) {
    //         list = {};
    //         _.each(this.data.Limits, (v, k) => {
    //             if(v >= 0) {
    //                 list[k] = v;
    //             }
    //         });
    //         this.data.Limits = _.keys(list).length ? list : null;
    //     }
    //     if(this.data.Prepaid) {
    //         list = {};
    //         _.each(this.data.Prepaid, (v, k) => {
    //             if(v >= 0) {
    //                 list[k] = v;
    //             }
    //         });
    //         this.data.Prepaid =  _.keys(list).length ? list : null;
    //     }
    //
    // }

    _add(){
        this.Utils.confirmDialog({
            title: this.selected ? "BILLING_PLAN_UNSELECT_TITLE" : "BILLING_PLAN_SELECT_TITLE",
            text: this.selected ? "BILLING_PLAN_UNSELECT_MESSAGE" : "BILLING_PLAN_SELECT_MESSAGE",
            okText: 'COMMON_OK'
        }).then(() => {
            this.listController.add(this.data);
        });

    }

    add() {
        var self = this,
            title = this.selected ? "BILLING_PLAN_UNSELECT_TITLE" : "BILLING_PLAN_SELECT_TITLE",
            text = this.selected ? "BILLING_PLAN_UNSELECT_MESSAGE" : "BILLING_PLAN_SELECT_MESSAGE";

        this.mdDialog.show(
            {
                locals: {
                    title : this.selected ? "BILLING_PLAN_UNSELECT_TITLE" : "BILLING_PLAN_SELECT_TITLE",
                    text : this.selected ? "BILLING_PLAN_UNSELECT_MESSAGE" : "BILLING_PLAN_SELECT_MESSAGE"
                },
                controller: ($scope, $mdDialog, title, text) => {
                    $scope.title = title;
                    $scope.message = text;
                    $scope.close = () => {
                        $mdDialog.cancel();
                    };
                    $scope.ok = () => {
                        $mdDialog.hide();
                    };
                },
                template: usageTemplate,
                controllerAs: "$ctrl",
                parent: angular.element(document.body),
            }
        ).then(() => {
            self.listController.add(self.data);
        });
    }
}

export const BillingPlan = {
    bindings: {
        data: '<',
        canAdd: '=',
        list: '=',
        listController: '=',
        selected:'=',
        cost: '<'
    },
    controller: controller,
    template: `
        <div class="content" ng-class="{selected: $ctrl.selected}" >
            <div class="dsp" ng-if="$ctrl.data.DSP"></div>
            <h4>{{$ctrl.data.Name}}</h4>
            <div class="price" layout="column" layout-align="center center">
                <div class="amount"><md-icon md-svg-icon="core:{{$ctrl.data.Currency}}"></md-icon>{{$ctrl.data.Amount * 1}}</div>
                <div class="currency">per {{$ctrl.data.Interval}}</div>
                <div class="until" ng-if="$ctrl.data.Until" flex>{{"BILLING_active_until" | translate }} {{ $ctrl.data.Until | date : 'mediumDate' }}</div>
            </div>
            <div ng-if="$ctrl.data.Description" class="description">{{$ctrl.data.Description}}</div>
            <div class="prepaid" ng-if="$ctrl.data.Prepaid ">
                <h5>Prepaid</h5>
                <cmp-billing-plan-limits ng-model="$ctrl.data.Prepaid"></cmp-billing-plan-limits>
            </div>
            <div class="limits" ng-if="$ctrl.data.Limits">
                <h5>Limits</h5>
                <cmp-billing-plan-limits ng-model="$ctrl.data.Limits"></cmp-billing-plan-limits>
            </div>
            <div class="limits" ng-if="$ctrl.cost">
                <h5>Cost</h5>
                <cmp-billing-plan-limits ng-model="$ctrl.cost" unit="'$'"></cmp-billing-plan-limits>
            </div>
            <div ng-if="!$ctrl.data.Free || (!$ctrl.selected && $ctrl.data.Free)" class="click action" ng-click="$ctrl.add()">{{$ctrl.selected ? 'COMMON_SELECTED' : 'COMMON_SUBSCRIBE' | translate }}</div>
            <div ng-if="$ctrl.selected && $ctrl.data.Free" class="action " >{{$ctrl.selected ? 'COMMON_SELECTED' : 'COMMON_SUBSCRIBE' | translate }}</div>
        </div>
    `
}

var usageTemplate = `
<md-dialog >
    <md-dialog-content>
        <div class="md-dialog-content">
            <h3>{{title | translate}}</h3>
            <p>{{message | translate}}</p>
            <div layout="row" layout-align="start center">
                <div flex>
                    <md-input-container style="margin:0;">
                      <md-checkbox ng-model="terms" style="margin:0;" aria-label="I agree to terms">
                        I agree to <a href="https://kuberlab.com/terms" target="_blank">terms</a>
                      </md-checkbox>
                    </md-input-container>
                </div>
                <div style="width : 10px"></div>
                <md-button ng-click="close()">{{'COMMON_CANCEL' | translate}}</md-button>
                <md-button ng-click="ok()" ng-disabled="!terms" class="md-primary">{{'COMMON_OK' | translate}}</md-button>
            </div>
        </div>
    </md-dialog-content>
</md-dialog>

`;
