
import conf from './catalog_data_stateconfig'
import {CatalogDataService} from './catalog_data_service'
// import {CatalogDataReadmeComponent} from  './readme/catalog_data_readme_component'
// import {CatalogDataCommentsService} from './comments/catalog_data_comments_service'
// import {CatalogDataCommentsComponent} from './comments/catalog_data_comments_list'
import {CatalogDataCardHomeComponent} from './card_home'
import {CatalogItemVersionComponent} from '../catalog/base/version/controller'
import {DatasetVersionComponent} from './version/controller'
import {DatasetVersionItemComponent} from './version/version-item'
import {DatasetVersionTree} from './version/tree/controller'
import {DatasetversionViewer} from './version/viewer/controller'
import {DatasetVersionUploadingFiles} from './version/uploading-files'

export default angular
    .module("kuberlab.catalog_data", [])
    .config(conf)
    .service('CatalogDataService', CatalogDataService)
    .component('cmpCatalogDataCardHome', CatalogDataCardHomeComponent)
    .component('cmpCatalogDataItemVersion', DatasetVersionComponent)
    //
    // .component('cmpDatasetVersionItem', DatasetVersionItemComponent)
    // .component('cmpDatasetVersionTree', DatasetVersionTree)
    // .component('cmpDatasetVersionViewer', DatasetversionViewer)
    // .component('cmpDatasetVersionUploadingFiles', DatasetVersionUploadingFiles)

       // .constant('catalogDataPageTabs',[
    //         {
    //             title : 'readme',
    //             content : '<cmp-catalog-item-readme readme-url="$ctrl.readmeUrl"></cmp-catalog-item-readme>'
    //         },
    //         {
    //             title : 'comments',
    //             content : '<cmp-catalog-item-comments resource="$ctrl.resource" data="$ctrl.data" ></cmp-catalog-item-comments>'
    //         },
    //         {
    //             title : 'versions',
    //             content : '<cmp-catalog-data-item-version resource="$ctrl.resource" data="$ctrl.data" can-manage="$ctrl.canManage" service="$ctrl.service"></cmp-catalog-data-item-version>'
    //         },
    //         {
    //             title : 'forks',
    //             content : '<cmp-catalog-forks resource="$ctrl.resource" data="$ctrl.data" can-manage="$ctrl.canManage" service="$ctrl.service"></cmp-catalog-forks>'
    //         }
    //     ]
    // )
