import {ws_listener} from '../../core/controller/ws_listener'

class controller extends ws_listener {
    constructor(ServingService, $state, $scope) {
        super();
        this.$state = $state;
        this.ServingService = ServingService;
        $scope.$on('serving_request_complite', () => {
            this.getList();
        });
        this.watchWS('applicationTask', this.ws_applicationTask);
        this.watchWS('applicationTaskCreate', this.ws_applicationTask);
        this.watchWS('applicationTaskDelete', this.ws_applicationTask);
        this.hasUpdate = false;
        this.timeout = null;
        this.loading = false;
    }

    $onInit() {
        this.getList(true);
    }

    getList(loading) {
        let data = this.requestData ? this.requestData : this.$state.params;
        this.apiError = false;
        this.hasUpdate = false;
        this.loading = loading;
        this.ServingService.getJobs(data)
            .then(
                (r) => {
                    this.jobs = r;
                },
                (err) => {
                    this.apiError = err;
                }
            )
            .finally( () => {
                this.loading = false;
                this.update();
            });
    }

    update() {
        if (this.hasUpdate) {
            if (this.timeout) {clearTimeout(this.timeout);}
            this.timeout = _.delay(() => { this.getList(); }, 10000);
        }
    }

    ws_applicationTask(ev, data) {
        if( data.workspace == this.data.WorkspaceName && data.app_name == this.data.ApplicationName && data.exec_info && data.exec_info['#serving_job']) {
            this.hasUpdate = true;
            this.update();
        }
    }

    $ngDectroy() {
        if (this.timeout) {clearTimeout(this.timeout);}
    }
}
export const ServingJobsComponent = {
    bindings: {
        data : '='
    },
    controller: controller,
    template: `
        <div layout="row" layout-align="space-between center">
            <h5>Jobs list project <a ui-sref="wsApplication( { wsId: $ctrl.data.WorkspaceName, applicationName: $ctrl.data.ApplicationName } )"" >{{$ctrl.data.ApplicationDisplayName}}</a></h5>
            <md-button ng-click="$ctrl.getList(true)">update</md-button>
        </div>
        
        <table>
            <tr>
                <th>status</th>
                <th>name</th>
                <th>start</th>
                <th>stop</th>
                <th>results</th>
            </tr>
            <tr ng-repeat="item in $ctrl.jobs">
                <td><item-status-icon data="{Health: item.Status}" class="widthAuto"></item-status-icon></td>
                <td><a ui-sref="wsApplication.tab( { wsId: $ctrl.data.WorkspaceName, applicationName: $ctrl.data.ApplicationName, tab:'jobs', tabItem: item.Name} )">{{item.Name}}</a></td>
                <td>
                    <span class="ts" style="white-space: nowrap">{{ item.Start | date:"MM/dd/yyyy 'at' h:mma" }}</span>
                </td>
                <td>
                    <span class="ts" style="white-space: nowrap" ng-if="item.Stop">{{ item.Stop | date:"MM/dd/yyyy 'at' h:mma" }}</span>
                </td>
                <td><span ng-repeat="out in item.Outputs"><a href="{{out.Link}}" ng-disabled="!item.Completed" target="_blank">{{out.Label}}</a><span ng-if="!$last">,<br/></span></span></td>
            </tr>
        </table>
        <div>
            <md-progress-linear ng-if="$ctrl.loading"></md-progress-linear>
        </div>
    `
}
