    import {StoragePersistentService} from './storage_persistent_service'
import {StoragePersistentItemController} from './storage_persistent_item_controller'
import {StoragePersistentListController} from './storage_persistent_list_controller'
import cfg from './storage_persisten_stateconfig'

export default angular
    .module('storege.persistent', [])
    .config(cfg)
    .service('StoragePersistentService', StoragePersistentService)
    .component('cmpStoragePersistentList', {
            bindings: {
                canEdit: '<',
                workspace: '<'
            },
            controller: StoragePersistentListController,
            template: function ($templateCache) {
                return $templateCache.get('kuberlab/storage/persistent/list.html')
            }
        })
    .component('cmpStoragePersistentItem', {
            bindings: {
                data: '=',
                listController: '<',
                clusters: '<'
            },
            controller: StoragePersistentItemController,
            template: function ($templateCache) {
                return $templateCache.get('kuberlab/storage/persistent/item.html')
            }
        })


