import {InstallTab} from './install_tab'

class InstallChartWizard extends InstallTab{

    constructor($scope){
        super();
        this.$scope = $scope;
    }

    $onInit($scope) {
        super.$onInit();
        var self = this;
        this.$scope.$watchCollection(function(){
                    return self.parent.clusters;
                },
                function (c) {
                    self.enable();
                }
        );
        this.$scope.$watch(function(){
                    return self.parent.target_application;
                },
                function () {
                    self.enable();
                }
        );
        this.$scope.$watch(function(){
                    return self.parent.target_applications;
                },
                function () {
                    self.enable();
                }
        );
        this.listWizard();
    }

    listWizard() {
        this.list = _.map(this.wizards, function (val) {
            var name = 'cmp-install-chart-wizard-' + val.wizard.kind.toLowerCase();
            name = name.replace('_', '-');
            return {
                title: val.wizard.name || val.block,
                cmp: '<' + name + ' data="$ctrl.yamlData[\'' + val.block + '\']" parent="$ctrl.parent"></' + name + '>'
            }
        }, this);
    }

    enable(){
        super.enable();
    }

    canEdit() {
        return !!( ( this.parent.clusters && this.parent.clusters.length ) && ( this.parent.target_application || ( this.parent.target_applications  && this.parent.target_applications.length ) ) );
    }
}

export const InstallChartWizardComponent = {
    bindings: {
        parent: "<",
        tab: "=",
        index: "<",
        wizards: "=",
        yamlData: "="
    },
    controller : InstallChartWizard,
    template: `
        <div class="wizard" ng-if="$ctrl.parent.workspace_name && $ctrl.parent.clusters">
            <div class="layout-collumn">
                <div ng-repeat="item in $ctrl.list" class="cmp-block-compact">
                    <h5 class="cmp-block-compact-title">{{item.title}}</h5>
                    <div bind-html-compile="item.cmp" class="cmp-block-compact-content"></div>        
                </div>
            </div>
        </div>
    `
};
