export function LayoutPage() {
    function link() {

    }
    return({
        link: link,
        restrict: "E"
    });
}

