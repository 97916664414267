class controller {
    constructor($resource, KuberlabConfigApi) {
        this.$resource = $resource;
        this.cfg = KuberlabConfigApi;
        this.asForm = false;
    }

    $onInit(){
        if(this.resourceUrl){
            this.getResource(this.resourceUrl);
            this.loadText();
        }
    }

    loadText () {
        this.error = false;
        this.loading = true;
        this._resource.get().$promise
            .then(
                (r) => {
                    this.file = r.file;
                },
                (err) => {
                    this.error = err;
                }
            )
            .finally(()=>{
                this.loading = false;
            })
    }

    edit() {
        this.asForm = true;
        this._file = this.file;
    }

    cancel(){
        this.asForm = false;
        this.file = this._file;
    }

    save() {
        if(this._resource){
            this.error = false;
            this.loading = true;
            this._resource.update(this.file).$promise
                .then(
                    (r)=>{
                        this.asForm = false;
                    },
                    (err)=>{
                        this.error = err;
                    }
                )
                .finally(()=>{
                    this.loading = false;
                })
        }else{
            this.asFrom = false;
        }
    }

    getResource(url) {
        var apiPath = this.cfg.APIPath + url;
        this._resource = this.$resource(
            apiPath,
            {},
            _.extend(
                {},
                this.cfg.ResourceParams,
                {
                    get: {
                        method: 'GET',
                        transformResponse: function(data, headersGetter, status) {
                            return {file: data};
                        }
                    }
                }
            )
        );
        return this._resource;
    }
}

export const ViewerMdComponent = {
    bindings: {
        resourceUrl: '<',
        readOnly: '<'
    },
    controller: controller,
    template: `
        <div ng-if="!$ctrl.asForm" >
            <div class="markdown-body" ng-if="$ctrl.file" btf-markdown="$ctrl.file"></div>
            <div layout="row" layout-align="start center">
                <div flex>
                    <cmp-error-toast ng-if="$ctrl.error" error="$ctrl.error"></cmp-error-toast>
                    <md-progress-linear ng-if="$ctrl.loading"></md-progress-linear>
                </div>
                <md-button ng-if="!$ctrl.readOnly" ng-click="$ctrl.edit()" class="md-icon-button"><md-icon md-svg-icon="core:edit"></md-icon></md-button>
            </div>
        </div>
        <div ng-if="$ctrl.asForm && !$ctrl.readOnly">
            <div ui-ace="{mode: 'yaml', onLoad: aceLoaded}" ng-model="$ctrl.file"></div>
            <div layout="row" layout-align="start center">
                <div flex>
                    <cmp-error-toast ng-if="$ctrl.error" error="$ctrl.error"></cmp-error-toast>
                    <md-progress-linear ng-if="$ctrl.loading"></md-progress-linear>
                </div>
                <md-button ng-click="$ctrl.cancel()">{{"COMMON_CANCEL" | translate}}</md-button>
                <md-button ng-click="$ctrl.save()">{{"COMMON_SAVE" | translate}}</md-button>
            </div>
        </div>
        `
}