import  {controllerItem} from '../../applications/volumes/applications_volumes_item_component'

class controller extends controllerItem {

    constructor(
        $scope
        , ApplicationsService
        , $state
        , ClusterService
        , ErrorService
        , StorageService
    ) {
        super(...arguments);
        this.StorageService = StorageService;
        this.ApplicationsService = ApplicationsService;
        this.ClusterService = ClusterService;
        this.ErrorService = ErrorService;
        this.$state = $state;

        this.data;
        this.isNewElement;
        this.listController;
        this.saving = false;
        this.$scope = $scope;
        this.isServing = true;
        // var self = this;
        // $scope.$on('start_saving',
        //     function () {
        //         self.saving = true;
        //     }
        // );
        // $scope.$on('stop_saving',
        //     function () {
        //         self.saving = false;
        //     }
        // );
    }

    $onInit(){
        if(this.listController.serving && this.listController.serving.WorkspaceName)
            this.wsId = this.listController.serving.WorkspaceName;
        if(this.listController.serving && this.listController.serving.ClusterID)
            this.cluster = { ClusterID: this.listController.serving.ClusterID};
        if(!this.wsId){
            this.types = _.clone(this.StorageService.storageTypeList);
            delete this.types.clusterStorage;
        }

        this.types = this.types || this.StorageService.storageTypeList;
        this.data = this.data || {};
        if(this.isTrainLogDir) this.data.isTrainLogDir = this.isTrainLogDir;
        this.oldData = angular.copy(this.data);
        this.Type = this.getType();
        if(this.isNewElement){
            this.edit();
        }
    }

    save(form) {
        if(!form.$valid) return false;
        if(this.listController) {
            if(this.isNewElement) {
                this.listController.add(this.data);
            }else{
                this.listController.save(this.data);
                this.asForm = false;
            }

        } else {
            this.asForm = false;
        }
    }
}

export const ServingVolumeItemComponent = {
    bindings: {
        data: '=',
        // application: '<',
        isNewElement: '<',
        listController: '<',
        isTrainLogDir: '<',
        types: "<",
        fromApplicationForm: '<',
        workspaceName: "<"
    },
    controller: controller,
    template: function ($templateCache) {
        return $templateCache.get('kuberlab/applications/volumes/applications_volumes_item_component.html');
    },
};
