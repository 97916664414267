export class AutoList{
    constructor() {
        this.item = {}
    }

    $onInit() {
        this.list = [];
        this.data;
        if(this.data && this.data.length)
            this.list = this.data;
        this.addNew();
    }

    change(form, index){
        if(form.$valid && this.list.length == index + 1) {
            this.addNew();
        }
    }

    delete(index){
        this.list.splice(index,1);
        this.data = _.initial(this.list);
    }

    canDelete(index){
        return (this.data && this.data[index]) ? true : false;
    }

    addNew(clear) {
        if(!_.isArray(this.list) || clear)
            this.list = [];
        this.list.push(angular.copy(this.item));
        this.data = _.initial(this.list);
    }
}
