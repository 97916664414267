
import conf from './mlmodel_stateconfig'
import {MlmodelService} from './mlmodel_service'
import {MlmodelCardHomeComponent} from './card/card_home'
import {MlmodelVersionComponent} from './version/controller'
import {MlmodelVersionItemComponent, MlmodelVersionItemFullComponent} from './version/version-item'

export default angular
    .module("kuberlab.mlmodel", [])
    .config(conf)
    .service('MlmodelService', MlmodelService)
    .component('cmpMlmodelCardHome', MlmodelCardHomeComponent)
    .component('cmpMlmodelItemVersions',MlmodelVersionComponent)
    .component('cmpMlmodelVersionItem', MlmodelVersionItemComponent)
    .component('cmpMlmodelVersionItemFull', MlmodelVersionItemFullComponent)
    // .constant('mlmodelPageTabs',[
    //     {
    //         title : 'readme',
    //         content : '<cmp-catalog-item-readme readme-url="$ctrl.readmeUrl"></cmp-catalog-item-readme>'
    //     },
    //     {
    //         title : 'comments',
    //         content : '<cmp-catalog-item-comments resource="$ctrl.resource" data="$ctrl.data"></cmp-catalog-item-comments>'
    //     },
    //     {
    //         title : 'versions',
    //         content : '<cmp-mlmodel-item-versions resource="$ctrl.resource" data="$ctrl.data" can-manage="$ctrl.canManage" service="$ctrl.service"></cmp-mlmodel-item-versions>'
    //     },
    //     {
    //         title : 'forks',
    //         content : '<cmp-catalog-forks resource="$ctrl.resource" data="$ctrl.data" can-manage="$ctrl.canManage" service="$ctrl.service"></cmp-catalog-forks>'
    //     }
    //
    //
    //     ]
    // )
