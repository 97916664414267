import {ProjectMlService} from './project_ml_projects_service'
import {ProjectMlController} from './project_ml-projects_controller'

export default angular
    .module('projectMlProjectComponent', [
        // 'ng.jsoneditor'
    ])
    .service('ProjectMlService', ProjectMlService)
    .component('cmpProjectsMl', {
        controller: ProjectMlController,

        template: function ($templateCache) {
            // debugger
            return $templateCache.get('kuberlab/projects/applications/project_ml_projects.html');
        },
    })
