/**
 * Created by ilja on 16.11.16.
 */

export class AuthService {
    constructor ($resource, $location, KuberlabConfigApi,  $window, $state, UserConfigService) {
        this.cfg = KuberlabConfigApi;
        this.cfgApi = KuberlabConfigApi;
        this.$state = $state;
        this.$window = $window;
        this.UserConfigService = UserConfigService;
        this._currentUserPromise = false;
        this.auth = $resource(this.cfgApi.APIPath + '/auth/:action/:item', {},
            {
            whoami: { method: 'GET', params: {action: "whoami"}},
            login:  { method: 'POST', params: {action: "login"}},
            logout: { method: 'POST', params: {action: "logout"}},
            choose: { method: 'GET', isArray: true, params: {action: "choose"}},
            chooseSet: { method: 'GET', isArray: true, params: {action: "choose", item: '@item'}}

            }
        );

        var registerUrl = this.cfgApi.APIPath + '/register';

        this.register = $resource(
                registerUrl,
                {},
                _.extend({}, this.cfg.ResourceParams,
                    {
                        confirm : {
                            method: "GET",
                            url : registerUrl + '/confirm/:token',
                            params: {token: "@token"}
                        }
                    }
                )
            );
        this.availableResource = $resource(
            this.cfgApi.APIPath + '/oauth/available',
            {} ,
            KuberlabConfigApi.ResourceParams
        );
        this.oauth = $resource(
            this.cfgApi.APIPath + '/oauth/connections/list/:conId', {conId : "@ID"} ,
            KuberlabConfigApi.ResourceParams
        );

        this.location = $location;
        this.serviceList = [];
    }


    currentUser() {
        var self = this;
        if(!this._currentUserPromise){
            this._currentUserPromise = this.auth.whoami().$promise;
            this._currentUserPromise.then (
                () => {},
                () => {
                    this.UserConfigService.setConfig('currentWS', null);
                    // debugger
                })
            ;
        }
        return this._currentUserPromise;
    }

    authInService (service, invite, scopes) {
        var self = this,
            url = encodeURI(this.location.absUrl());
        url = url.replace(/#/g, '%23');
        this.currentUser()
            .then(
                function (r) {
                    window.location.href = self.cfgApi.APIPath + '/oauth/connections/add/' + service + '?' + (scopes ? 'scopes=' + scopes  + '&' : '') + 'return=' + url ;
                },
                function (r) {
                    if(invite) {
                        url = self.$state.href('workspace', {wsId : invite.Workspace.Name}, {absolute: true});
                        url = url.replace(/#/g, '%23');
                        url = url + "&invite=" + invite.ID;
                    }
                    window.location.href = self.cfgApi.APIPath + '/oauth/connections/login/' + service  + "?return=" + url;
                });
        return false;
    }

    logout(){
        var self = this;
        return this.auth.logout()
            .$promise
            .then(
                function (r) {
                    // debugger;
                    // self.$window.location.reload();
                    self.$window.location = '/';

                    // window.location.href = self.location.absUrl()
                },
                function () {
                    console.error('AUTH logout error');
                }
            )
    }

    login (data) {
        return this.auth.login(data)
            .$promise
            .then(
                 (r) => {

                    window.location.href = '/'
                }
            );
    }

    ServiceList (update) {
        var self = this;
        if(!this.availablePromise || update){
            this.availablePromise = this.availableResource.query().$promise;
            this.availablePromise
                .then(function (r) {
                    self.serviceList = r;
                }, function (err) {
                });
        }
        return this.availablePromise;
        // return [
        //     {
        //         Title: 'Google',
        //         Service: 'google'
        //     },
        //     {
        //         Title: 'Github',
        //         Service: 'github'
        //     },
        //     {
        //         Title: 'Bitbucket',
        //         Service: 'bitbucket'
        //     },
        //     // {
        //     //     Title: 'Amazon',
        //     //     Service: 'amazon'
        //     // }
        // ];
    }

    getIcon (name) {
        return 'services:' + name;
    }
}
