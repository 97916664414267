class controller {
    constructor($scope) {
        this.$scope = $scope;
    }
    $onInit() {
        if(!this.revision) this.revision = [];
        this.$scope.$watchCollection(
            () => {return this.volumes},
            (c) => {
                this.has = false;
            }
        );

        this.$scope.$watchCollection(
            () => {return this.defaultVolumeMapping},
            (c) => {
                this.has = false;
            }
        );

        this.$scope.$watchCollection(
            () => {return this.revision},
            (c) => {
                this.has = false;
            }
        );

        this.$scope.$watchCollection(
            () => {return  this.application.Configuration.spec.volumes},
            (c) => {
                this.has = false;
            }
        );
    }

}

export const ApplicationsTaskVolumesRevisionComponent = {
    bindings : {
        volumes: '<',
        application: '<',
        task: "=",
        defaultVolumeMapping:'<'
    },
    controller: controller,
    template: `
        <div class="cmp-block-compact" ng-show="$ctrl.has">
            <h5 class="cmp-block-compact-title">Volume revision</h5>
            <div class="cmp-block-compact-content">
                <md-list>
                    <cmp-applications-task-volumes-git 
                          default-volume-mapping="$ctrl.defaultVolumeMapping" 
                          revision="$ctrl.task.gitRevisions" 
                          application="$ctrl.application" 
                          types="['gitRepo']"
                          has="$ctrl.has"
                          volumes="$ctrl.volumes"
                          ></cmp-applications-task-volumes-git>
                    <cmp-applications-task-volumes-git 
                          default-volume-mapping="$ctrl.defaultVolumeMapping" 
                          revision="$ctrl.task.datasetRevisions" 
                          application="$ctrl.application" 
                          types="['dataset', 'datasetFS']"
                          has="$ctrl.has"
                          volumes="$ctrl.volumes"
                          ></cmp-applications-task-volumes-git>
                    <cmp-applications-task-volumes-git 
                          default-volume-mapping="$ctrl.defaultVolumeMapping" 
                          revision="$ctrl.task.modelRevisions" 
                          application="$ctrl.application" 
                          types="['model']"
                          has="$ctrl.has"
                          volumes="$ctrl.volumes"
                          ></cmp-applications-task-volumes-git>
                </md-list>
            </div>
        </div>
    `
}
