export class wsCardTitleController {
    constructor(workspace, pageConfig, $state, $scope, $rootScope, projectPageViewTabs, $localStorage) {
        // debugger;

        var self = this;

        this.asFull = false;

        this.projectPageViewTabs = projectPageViewTabs;
        this.projectViewType = $localStorage.projectViewType;
        this.ls = $localStorage;
        this.$state = $state;
        // debugger;

        // $rootScope.$on('$stateChangeSuccess',
        //             function(event, toState, toParams, fromState, fromParams, options){
        //                 // debugger;
        //                 self.getBreadcrumbs(toState, self.rootState);
        //                 console.log('$stateChangeStart argument', arguments)
        //
        //             }
        //         );
        pageConfig.setTitle('workspace ' + workspace.Name + ' home');
        this.workspace = workspace;


        this.pageTitleConfig = $rootScope.pageTitleConfig;
        $rootScope.$watch(
            function () {
                return $rootScope.pageTitleConfig;
            },
            function (c) {
                self.pageTitleConfig = c;
            }
        );

        $scope.setViewType = function(view) {
            var s = this;
            $localStorage.projectViewType = view;
            // console.info("set projectViewType", $localStorage.projectViewType);
            // $state.reload();
            // debugger;
            _.delay(function ($state) {
                // debugger;
                $state.go( $state.current.name, $state.params, {reload: true} );
            }, 100, $state);
            // $state.go($state.current, {tab: null}, {reload: true});
            // $state.go( $state.current.name, {tab: null} );
        }

    }

    info () {
        this.asFull = !this.asFull;
    }
}