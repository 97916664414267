export function Layout (LayoutService) {

    function link(scope, $element) {
        scope.$watchCollection(
            () =>  LayoutService.hasSubMenu ,
            (c) => {
                if(c)
                    $element.addClass('mainMenuSmall');
                else
                    $element.removeClass('mainMenuSmall');
            },
            true
        );
    }
    return ({
        link: link,
        restrict: "A"
    });
}
