/**
 * Created by ilja on 16.11.16.
 */

import {LayoutTitleComponent} from './layout_title_component'
import {LayoutTitleLoginComponent} from './title/layout_title_login_component'
import {LayoutTitleOwnerComponent} from './title/layout_title_owner_component'
import {LayoutTitleUserComponent} from './title/layout_title_user_component'
import {LayoutTitleUserCardComponent} from './title/layout_title_user_card_component'
import {LayputLeftMenuComponent} from './layout_left_menu_component'
import {HomeCardWelcom} from './directive/home_card_welcom/home_card_welcom'
import {KuberLogo} from './directive/logo/logo'
import {PricingIndex} from './pricing/index'

export default angular
    .module('kuberlabLayout', [
        'ngMaterial',
    ])
    .component('cmpLayoutTitle', LayoutTitleComponent)
    .component('cmpLayoutTitleLogin', LayoutTitleLoginComponent)
    .component('cmpLayoutTitleOwner', LayoutTitleOwnerComponent)
    .component('cmpLayoutTitleUser', LayoutTitleUserComponent)
    .component('cmpLayoutTitleUserCard', LayoutTitleUserCardComponent)
    .component('cmpLayoutLeftMenu', LayputLeftMenuComponent)
    .directive('homeCardWelcom', HomeCardWelcom)
    // .directive('kubeLogo', KuberLogo)
    .component('cmpPricingIndex', PricingIndex)