import {CatalogBaseItemNewController} from './base/item_new_controller'

export class CatalogItemNewController extends CatalogBaseItemNewController {
    constructor (catalog_data, CatalogService, $state) {
        super();
        this.data = catalog_data;
        this.Service = CatalogService;
        this.resource = CatalogService.getResource($state.params);
        // debugger;
        // this.delayNameCheck;
    }

    namepickup() {

        // if( this.data.DisplayName && !this.data.Name ) {
        if( this.data.DisplayName && !this.data.ID ) {

            this.resource.namepickup({name: this.data.DisplayName}).$promise
                .then(
                    (r) => {
                        this.data.Name = r.Name;
                    },
                    (err) => {
                        // debugger;
                    }
                )
        }
    }


    checkName (form) {

        if(this.data.Name == "") {
            form.Name.$setValidity("errorname", true);
            return;
        }

        this.resource.nameisavailable({name: this.data.Name}).$promise
            .then(
                (r) => {
                    form.Name.$setValidity("errorname", true);
                },
                (err) => {
                    this.nameErrorMessage = err;
                    form.Name.$setValidity("errorname", false);
                }
            );
    }

    save(form, event) {
        if(!form.$valid) return false;
        this.data = this.prepareSave(this.data);
        this.saving = true;
        this.savingError = false;
        this.data.$create()
            .then(
                (r) => {
                    this.$state.go('wsCatalog.list.item', {Interface: r.Interface, item: r.Name});
                },
                (err) => {
                    this.savingError = err;
                }
            )
            .finally(
                () => {
                    this.saving = false;
                }
            )
    }
}
