import {RepoService} from './repo_service'
import {RepoSelectComponent} from './repo_select'
import {RepoSelectWizardComponent} from './repo_select_wizard'
import Help from '../help/help_module'
import {RepoComponent} from './repo_component'
export default angular
    .module('repo', ['ngMaterial', Help.name])
    .service('RepoService', RepoService)
    .component('cmpRepoSelect', RepoSelectComponent)
    .component('cmpRepoSelectWizard', RepoSelectWizardComponent)
    .component('cmpRepo', RepoComponent)