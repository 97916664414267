
import {IntroService} from './intro-service'

export default angular
    .module('kubeklab.intro', [
        'angular-intro'])
    .service('IntroService', IntroService)
    .constant('intro_option_test',
        {
                    steps:[
                            {
                                    element: '#topmenu-catalog',
                                    intro: "step1."
                            },
                            {
                                    element: document.querySelectorAll('#step2')[0],
                                    intro: "step2",
                                    position: 'right'
                            },
                            {
                                    element: '#step3',
                                    intro: 'step3',
                                    position: 'left'
                            },
                            {
                                    element: '#step4',
                                    intro: "step4",
                                    position: 'bottom'
                            },
                            {
                                    element: '#step5',
                                    intro: 'step5'
                            }
                    ],
                    showStepNumbers: false,
                    showBullets: false,
                    exitOnOverlayClick: true,
                    exitOnEsc:true,
                    nextLabel: 'next',
                    prevLabel: '<span style="color:green">Previous</span>',
                    skipLabel: 'Exit',
                    doneLabel: 'Thanks'
            }
        )