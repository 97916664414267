/**
 * Created by ilja on 16.11.16.
 */


class LayoutTitleController {
    constructor($scope, DashboardService) {
        var self = this;
        this.currentOwner = DashboardService.currentOwner; // html bindings
        $scope.$watch(
            function () {
                return DashboardService.currentOwner;
            },
            function (current) {
                self.currentOwner = current;
            }
        );
    }
}

export const LayoutTitleComponent = {
    bindings: {
        currentOwner: "@"
    },
    template: function ($templateCache) {
        return $templateCache.get('kuberlab/layout/layout_title_component.html');
    },
    controller: LayoutTitleController
};