class WizardTypeDeployController {
    constructor() {
        this.listServices = ['None', 'Internal', 'External'];
    }

    $onInit() {
        if(_.isEmpty(this.data)) {
            this.data = {
                Service: 'None',
                Ports: null,
                Labels:[],
                EnvironmentVariables: [],
                Replicas: 1,
                CpuRequests: "25m",
                MemoryRequests: "64Mi",
                CpuLimits: "100m",
                MemoryLimits: "64Mi",
                Image:{
                    Name: '',
                    Tag: 'latest'
                }
            }
        }
    }

    changeService () {
        if(this.data.Service == 'None') this.data.Ports = null;
        else this.data.Ports = [];
    }


}

export const WizardTypeDeployComponent= {
    bindings : {
        data: '='
    },
    controller: WizardTypeDeployController,
    template: function ($templateCache) {
        return $templateCache.get('kuberlab/charts/wizard/wizard_type_deploy.html');
    }
};
