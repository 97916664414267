
import {CatalogBaseListController} from '../catalog/base/list_controller'

export class MlmodelListController extends CatalogBaseListController {
    constructor (MlmodelService, $scope,  $element) {

        super();
        this.$element = $element;
        this.$scope = $scope;
        this.Service = MlmodelService;
        this.stateName = "wsMlmodelItem";
        this.stateList = "wsMlmodelList";
        this.stateNew = "wsMlmodelAdd";
    }

}

