
class controller {
    // constructor ($state) {
    //     this.resourceUrl = '/workspace/' + $state.params.wsId + '/mlmodels/' + $state.params.mlmodelName + '/readme';
    // }
}


export const ServingReadmeComponent = {
    bindings: {
        readmeUrl: '<',
        readOnly: '<'
    },
    controller : controller,
    template: `
        <cmp-viewer-md read-only="$ctrl.readOnly" resource-url="$ctrl.readmeUrl" layout-padding  ></cmp-viewer-md>
    `
};
