class controller {
  constructor($rootScope, ApplicationsService, $state, $scope, $transitions) {
    this.$rootScope = $rootScope;
    this.$state = $state;
    this.$transitions = $transitions ;
    this.resource = ApplicationsService.getResource($state.params);
    this.ApplicationsService = ApplicationsService;
    var self = this;
    $scope.$watch(
        ()=>{return self.ApplicationsService.tasksList;},
        (c) => {self.Jobs = c;}
    );
  }

  $onInit() {
      this.init();

      this.listenerState = this.$transitions.onStart(
          { entering: 'wsApplication.tab' },
          (transition, state) => {
              this.init();
          });
  }

  init(){
    if (this.isOnCurrentPage()) {
      this.getList();
      this.ApplicationsService.startTasksUpdate('ApplicationLogsComponent');
    }
    else {
      this.ApplicationsService.stopTasksUpdate('ApplicationLogsComponent');
    }
  }

  isOnCurrentPage() {
    return this.$state.params.tab === 'logs';
  }

  getList() {
    var self = this;
    this.ApplicationsService.getTasks(this.$state.params, true)
        .then((r) => {
          _.delay(function() {
            var el;
            if (self.$state.params.tabItem && (el = $('#tabItem_' + self.$state.params.tabItem.split('@').shift())) &&
                el.length) {
              $('#main-content').animate({scrollTop: el.offset().top - $('#main-content').offset().top}, 500);
            }
          }, 200);
        },
        (err) => {
          self.uploadError = err;
        }
    );
  }

  $onDestroy() {
    super.$onDestroy();
    this.listenerState();
    clearTimeout(this.timer);
  }

}

export const ApplicationLogsComponent = {
  bindings: {
    application: '='
  },
  controller: controller,
  template: function($templateCache) {
    return $templateCache.get('kuberlab/applications/logs/applications_logs_component.html');
  }
};
