export class WsCardHomeController {
    constructor (workspace, $scope, CurrentUser, $rootScope, WorkspacePermissions, $localStorage, CatalogAddButton, UserService, ApplicationsService, $state, WorkspaceService) {

        this.ApplicationsService = ApplicationsService;
        // this.lastApps = ApplicationsService.lastApps(workspace.Name);


        this.revData = {
            uiRevCommit : window.uiRevCommit,
            uiRevAuthor : window.uiRevAuthor,
            uiRevTimeStamp: window.uiRevTimeStamp
        }

        this.permissions = WorkspacePermissions;
        this.storage = $localStorage;
        this.CatalogAddButton = CatalogAddButton;
        this.viewSettings = {infra:  !GlobalSettings.infra_only_for_admins};

        var self = this;
        this.workspace = workspace;

        $scope.$watch(
            () => { return UserService.viewSettings; },
            (c) => {
                this.viewSettings = c;
            }
        );

        this.cu;
        CurrentUser.then(
            function (r) {
                // self.viewSettings.infra = !GlobalSettings.infra_only_for_admins || ( GlobalSettings.infra_only_for_admins && r.Admin );
                self.cu = r;
            },
            function (e) {
                self.cu = false;
            }
        );

        $scope.$watch(() => this.currentNavItem,
            (name) => {
                this.storage.mainPageView = name;
                var buttons = [];
                switch(name){
                    case "application":
                        if(this.permissions.can('mlapp.manage')){ buttons.push({title :'MLAPPS_LIST_EMPTY_ACTION', icon: "core:mlapp", state: { name: 'wsApplicationsAdd' }}); }
                        break;
                    case "project":
                        if( this.viewSettings.infra && this.permissions.can('project.manage')){ buttons.push({title :'PROJECT_LIST_EMPTY_ACTION', icon: "core:project", state: { name: 'wsProjectAdd' }}); }
                        break;
                    case "chart":
                        // if(this.permissions.can('chart.manage')){ buttons.push({title :'CHART_LIST_EMPTY_ACTION', icon: "core:chart", state: { name: 'wsChartAdd' }}); }
                        if(this.permissions.can('chart.manage')){ buttons =  this.  CatalogAddButton.getBtn();}
                        break;
                    // case "mlmodel":
                    //     if(this.permissions.can('mlapp.manage')){ buttons.push({title :'MLMODEL_LIST_EMPTY_ACTION', icon: "core:mlmodel", state: { name: 'wsMlmodelAdd' }}); }
                    //     break;
                    // case "dataset":
                    //     if(this.permissions.can('dataset.manage')){ buttons.push({title :'MLMODEL_LIST_EMPTY_ACTION', icon: "core:mlmodel", state: { name: 'wsCatalogDataAdd' }}); }
                    //     break;
                }

                $rootScope.addButtonConfig = buttons;
            });

        this.currentNavItem = this.storage.mainPageView || 'application';

        $rootScope.pageTitleConfig = [
            {
                title : workspace.DisplayName,
                state: 'workspace'
            }
        ];


        this.isCan = (name) => {
          return this.permissions.can(`${name}.read`);
        };



        this.tabs = [
          {id: 'application', title: 'MLAPP_TITLE_PAGE_new'},
          {id: 'project', title: 'WS_RESOURCES'},
          {id: 'chart', title: 'WS_CHARTS'},
          // {id: 'mlmodel', title: 'MLMODEL'},
          // {id: 'dataset', title: 'CATALOG_DATA'}
        ];

        this.show = (name) => {
        };





        // this.wsResource = WorkspaceService.getResource($state.params);
        // this.wsResource.dashboard_active_tasks().$promise
        //     .then(
        //         (r) => {
        //             this.activeTasks =
        //                 [
        //                     {
        //                         "workspace": "kuberlab-demo",
        //                         "app": "21-music",
        //                         "app_name": "music",
        //                         "name": "standalone",
        //                         "build": "5",
        //                         "git_revision": "4c7e900a1fdbbe145751059a5dd9b626df35b09d",
        //                         "author": "Илья",
        //                         "branch": "master",
        //                         "comment": "Execute task standalone 2019-03-21 10:54:56",
        //                         "start_time": "2019-03-21T14:31:06.627823Z",
        //                         "status": "Pending",
        //                         "completed": false
        //                     }
        //                 ]
        //                 // r;
        //         },
        //         (err) => {}
        //     )
    }
}
