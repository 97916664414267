import {InstallChart, InstallChartTemplate} from '../../charts/install/install_chart'

export class ProjectApplicationController {
    constructor(ProjectApplicationService, $state, $scope, $mdDialog, WorkspacePermissions){
        var self = this;
        this.$scope = $scope;
        this.permissions = WorkspacePermissions;
        this.resource = ProjectApplicationService.getResource($state.params);
        this.editorOptions = {
            mode : 'tree',
            expanded: true
        };
        this.data ;
        this.yaml ="";
        this.file;
        this.$mdDialog = $mdDialog;
        this.$state = $state;
        this.dataModefy = false;
        $scope.aceLoaded =  function (_editor) {
            // Options
            // debugger;
            if(!self.permissions.can('project.manage')) _editor.setReadOnly(true);
        }

    }

    // aceLoaded (_editor) {
    //     // Options
    //     debugger;
    //     if(!this.permissions.can('project.namage')) _editor.setReadOnly(true);
    // }

    $onInit() {
        this.getData();
    }

    getData () {
        var self = this;
        this.isLoading = true;
        this.loadingError = false;

        if(this.watchData) this.watchData();
        this.dataModefy = false;
        this.resource.get_yaml()
            .$promise
            .then(
                function (r) {
                    self.bYaml= _.clone(r.yaml);
                    self.yaml = r.yaml;
                },
                function (err) {
                    self.loadingError = err;
                    self.bYaml= _.clone(self.yaml);
                }
            )
            .finally(function () {
                self.isLoading = false;
                self.watchData = self.$scope.$watch(
                    function () {
                        return self.yaml;
                    },
                    function (c) {
                        if(!_.isEqual(self.bYaml, self.yaml)){
                            self.dataModefy = true;
                        }
                    }
                );
            })
    }

    updateData() {
        var self = this;
        this.isLoading = true;
        this.loadingError = false;
        this.resource.update(this.yaml)
            .$promise
            .then(
                function (r) {
                    self.getData();
                },
                function (err) {
                    self.loadingError = err;
                }
            )
            .finally(function () {
                self.isLoading = false;
            })
    }

    uploadFile (form) {
        var self = this;
        this.loadingError = false;
        this.isLoading = true;
        var formData = new FormData();
        angular.forEach(this.files,function(obj){
            if(!obj.isRemote){
                formData.append('src[]', obj.lfFile);
            }
        });

        this.resource.upload({}, {src: this.file[0].lfFile})
            .$promise
            .then(
                function (r) {
                    self.getData();
                    this.isLoading = false;
                },
                function (err) {
                    self.loadingError = err;
                    this.isLoading = false;
                }
            );
    }


    deploy (){
        var self = this;
        this.DeployError = false;
        this.resource.deploy()
            .$promise
            .then(
                function (r) {
                    self.$state.go('wsProject.tab', _.extend({}, self.$state.params, {tab: 'tasks', tabItem: r.ID}));
                    // debugger;
                },
                function (err) {
                    self.DeployError = err;
                }
            )
    }

    installChart (ev) {
        var then = this;
        // this.$scope.chart= this.data;
        this.installError = null;
        this.$mdDialog.show({
            locals: {
                dialog: then.$mdDialog,
                chart : null,
                clusters: null,
                workspace_name : this.$state.params.wsId
                // ws: {
                //     workspace_name : this.$state.params.wsId,
                //     project_name: this.$state.params.projectName,
                //     environment: this.$state.params.envName
                // }
            },
            controller: InstallChart,
            controllerAs: '$ctrl',
            template: InstallChartTemplate,
            parent: angular.element(document.querySelector('#mainBody')), // angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose:false,
            escapeToClose: false
        }).then(
            function(data){
                // then.$state.go('wsProject.tab', {
                //     // wsId: data.data.workspace_name,
                //     // projectName: data.data.project_name,
                //     // envName: data.data.environment,
                //     tab: "tasks"
                // });
                // then.instaling = true;
                // debugger
                //
            },
            function () {
                // debugger;
            }
        );
    }


}
