
import {CoreServiceResource} from '../../core/service/core_service_resource'

export class ProjectAppsService extends CoreServiceResource {
    constructor ($resource, $q, KuberlabConfigApi, $rootScope) {
        super(...arguments);
    }

    getResource(params) {
        if( !params.wsId || !params.projectName || !params.envName || !params.clusterName ) {
            throw "error params";
        }
        if( this.wsId != params.wsId || this.projectName != params.projectName || this.envName != params.envName || this.clusterName != params.clusterName ){
            var apiPath = this.cfg.APIPath + '/workspace/' + params.wsId + '/projects/' + params.projectName + '/env/' + params.envName + '/clusters/' + params.clusterName + '/applications/:appName';
            this._resource = this.$resource(
                apiPath,
                {appName: '@Name'},
                _.extend({}, this.cfg.ResourceParams,
                    {

                        // start : {
                        //     method : 'GET',
                        //     url: apiPath + '/start'
                        // },
                        // stop : {
                        //     method : 'GET',
                        //     url: apiPath + '/stop'
                        // },
                        // reset : {
                        //     method : 'GET',
                        //     url: apiPath + '/reset'
                        // }
                    }
                )
            );
            this.wsId = params.wsId;
            this.projectName = params.projectName;
            this.envName = params.envName;
            this.clusterName = params.clusterName;
            this.list = null;
        }
        return this._resource;
    }
}
