    class controller {
    constructor(ApplicationsService, ClusterService, $scope){

    }

    $onInit() {
        // debugger
    }


}

export const ServingFormInferenceConfigComponent = {
    bindings: {
        values: "<",
        appController: "=",
        data: '=',
        api: "=",
        spec: "<",
        options: '='
    },
    controller: controller,
    template:  ($templateCache) => {
        return $templateCache.get('kuberlab/serving/form/inference_config.html');
    }
}
