import {ControllerItem} from '../core/controller/item'

export class ProjectsItemController extends ControllerItem {

    constructor (
            $state,
            $stateParams,
            $scope,
            $window,
            $translate,
            workspace,
            project,
            // envList,
            ProjectService,
            ProjectEnvService,
            projectResource,
            projectPageEnvDefault,
            projectPageTabDefault,
            projectPageTabs,
            $mdDialog,
            projectPageViewTabs,
            projectViewTabs,
            WorkspacePermissions,
            validate
    ) {
        super($mdDialog, $translate);

        this.validate = validate;
        this.$window = $window;
        this.permissions = WorkspacePermissions;

        this.$stateParams = $stateParams;
        this.$state = $state;
        this.$scope = $scope;

        this.data = project;
        this.resource = projectResource;
        this.projectService = ProjectService;

        this.nameManual = false;
        this.nameErrorMessage;

        this.delayNamePickup;
        this.delayNameCheck;

        this.projectPageEnvDefault = projectPageEnvDefault;
        this.WS = workspace;
        // this.envList = envList;
        this.envName = this.$state.params.envName;
        this.ProjectService = ProjectService;
        this.ProjectEnvService = ProjectEnvService;
        this.projectPageTabs = projectPageTabs;

        this.projectViewTabs = projectViewTabs;

        // TODO: get from project's API data
        this.canCreateEnv = this.canDeleteEnv = this.data && this.data.RepositoryURL && this.data.RepositoryURL.indexOf("github.com") >= 0;

        this.newElement = this.isNew();
        this.prName = this.data.Name;
     }

    pickupName() {
        if(this.nameManual || !this.newElement) return;
        if(this.delayNamePickup) {
            clearTimeout(this.delayNamePickup);
            delete this.delayNamePickup;
        }
        var project = this.data;
        var projectService = this.projectService;
        if(project.DisplayName.length > 2) this.delayNamePickup = _.delay(function(){
            projectService.projectNameResource.pickup({name: project.DisplayName}).$promise.then(
                    function(resp) {
                        project.Name = resp.Name;
                    }
                );
        }, 1000);
    }

    checkName(form) {
        if(this.delayNameCheck) {
            clearTimeout(this.delayNameCheck);
            delete this.delayNameCheck;
        }
        if (this.data.Name == "") {
            form.Name.$setValidity("errorname", true);
            this.nameManual = false;
            return;
        }
        this.nameManual = true;
        var self = this;
        this.delayNameCheck = _.delay(function() {
            self.projectService.projectNameResource.isavailable({name: self.data.Name}).$promise.then(
                function (resp) {
                    form.Name.$setValidity("errorname", true);
                },
                function (err) {
                    self.nameErrorMessage = err.data.Error;
                    form.Name.$setValidity("errorname", false);
                }
            );
        }, 1000);
    }

    save(form, isNew) {

        var self = this;
        this.saving= true;
        this.saveErrorMessage = null;
        // var action = this.isNew() ? '$create' : '$update';
        var action = this.newElement ? 'create' : 'update';
        // debugger;
        this.projectService.getProjectResource(this.$stateParams.wsId)[action]({prName : this.prName}, this.data)
            .$promise
        // this.data[action]()
            .then(
                function (r) {
                    // debugger;
                    if(self.newElement) {
                        self.projectService.addProject(self.data);
                        self.$state.go('wsProject', _.extend({}, self.$state.params, {projectName : r.Name}), {reload: true});
                    }
                    else {
                        self.$state.reload('wsProject', _.extend({}, self.$state.params, {projectName : r.Name}));
                    }
                },
                function (err) {
                    self.saveErrorMessage = err;
                }
            )
            .finally(
                function () {
                    self.saving = false;
                }
            )
    }

    cancel() {
        if(this.newElement)
            this.$window.history.back();
        else
            this.actionView();
    }

    goTab (tab) {
        this.$state.go('wsProject.tab', _.extend({}, this.$state.params, {tab: tab}));
    }

    successDelete() {
        this.ProjectService.getProjects(true);
        this.$state.go('workspace', this.$state.params);
    }

    deleteEnv() {
        var envName = this.$state.params.envName;
        var self = this;
        this.ProjectEnvService.getResource(this.$state.params).delete({envName : envName}).$promise.then(
            function () {
                self.successEnvDelete.call(self);
                // self.ProjectEnvService.getList(true);
                // self.$state.go('wsProject', _.extend({}, self.$state.params, {envName : self.projectPageEnvDefault}));
            }
            // , function (err) {
            //     debugger;
            // }
        );
    }

    successEnvDelete() {
        this.ProjectEnvService.getList(true);
        this.$state.go('wsProject', _.extend({}, this.$state.params, {envName : this.projectPageEnvDefault}));
    }

    isNew() {
        return (this.data && this.data.Name) ? false : true;
    }


}
