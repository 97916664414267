module.exports={
  "wsApplication.tab": {
    "{}": "https://kibernetika.readthedocs.io/en/latest/#optional-cloud-account-registration",
    "{\"tab\":\"models\"}": "https://kibernetika.readthedocs.io/en/latest/projects/working-with-projects/#task-management"
  },
  "setting.tab": {
    "{\"tab\":\"connection\"}": "https://kibernetika.readthedocs.io/en/latest/#optional-cloud-account-registration"
  },
  "wsApplicationsAdd": {
    "{}": "https://kibernetika.readthedocs.io/en/latest/#optional-cloud-account-registration"
  }
}
