class Controller  {
    constructor($state, WorkspaceService){
        this.$state = $state;
        this.WorkspaceService = WorkspaceService;
        this.wsaList = [];
        this.regions = region;
    }

    $onInit() {
        this.resource = this.WorkspaceService.Serviceacconts(this.$state.params.wsId);
        this.getWsaList();
        if(this.data.region){
            this.selectRegion = _.findWhere(this.regions, {region: this.data.region});
            if( !this.selectRegion ){
                this.searchText = this.data.region;
            }
        }
        // this.setList();
    }

    getWsaList() {
        this.resource.query(
            {},
            (r) => {
                this.wsaList = _.filter(r, (r) => { return  r.Service == 'cs3' || r.Service == 'amazon';} );
                this.loadComplite = true;
            },
            (err) => {
                this.loadComplite = true;
                this.error = err;
            }
        );

    }

    setList() {
        if(this.searchText){
            let self = this;
            this.fList = _.filter(this.regions, (v) => {
                return v.name.search(/^ + self.searchText + /)  != -1 || v.region.search(/^ + self.searchText + /) != -1
            })
            // debugger;
        } else {
            this.fList = this.regions;
        }
        return this.fList;
    }

    searchTextChange(searchText) {
        // this.setList();
        this.data.region = this.selectRegion ? this.selectRegion.region : this.searchText;
    }

    selectedItemChange (item){
        this.data.region = this.selectRegion ? this.selectRegion.region : this.searchText;
    }


}

export const StorageS3bucketComponent = {
    bindings: {
        data : "="
    },
    controller: Controller,
    template: `
        <md-input-container class="flex">
            <label>Bucket</label>
            <input ng-model="$ctrl.data.bucket" required>
        </md-input-container>
        
            <md-autocomplete
                flex
                md-no-cache="true" 
                md-selected-item="$ctrl.selectRegion" 
                md-items="item in $ctrl.regions" 
                md-search-text="$ctrl.searchText" 
                md-item-text="item.name + ' (' + item.region + ')'"
                md-search-text-change="$ctrl.searchTextChange($ctrl.searchText)"
                md-selected-item-change="$ctrl.searchTextChange(item)"
                md-floating-label="Region"
                >
              <span>{{ item.name }} ({{item.region}})</span>
            </md-autocomplete>
        <md-input-container class="flex">
            <label>Server</label>
            <input ng-model="$ctrl.data.server">
        </md-input-container  class="flex">
        <md-input-container class="flex">
            <label>Account</label>        
            <md-select ng-if="$ctrl.loadComplite && $ctrl.wsaList && $ctrl.wsaList.length" ng-model="$ctrl.data.accountId">
                <md-option ng-repeat="item in $ctrl.wsaList" ng-value="item.ID">{{item.Service}}:{{item.Name}}</md-option>
            </md-select>    
            <input ng-if="$ctrl.loadComplite && (!$ctrl.wsaList || !$ctrl.wsaList.length)" ng-model="$ctrl.data.accountId">
        </md-input-container>
        <cmp-error-toast ng-if="$ctrl.error" error="$ctrl.error"></cmp-error-toast>
        <!--{{$ctrl.data}}-->
 
`
};

let region = [
{name: 'US East (Ohio)', 	region: 'us-east-2'} ,
{name:'US East (N. Virginia)', 	region:'us-east-1'},
{name:'US West (N. California)', 	region:'us-west-1'},
{name:'US West (Oregon)', 	region:'us-west-2'},
{name:'Asia Pacific (Mumbai)', 	region:'ap-south-1'},
{name:'Asia Pacific (Seoul)', 	region:'ap-northeast-2'},
{name:'Asia Pacific (Osaka-Local) ***',	region:'ap-northeast-3'},
{name:'Asia Pacific (Singapore)', 	region:'ap-southeast-1'},
{name:'Asia Pacific (Sydney)', 	region:'ap-southeast-2'},
{name:'Asia Pacific (Tokyo)', 	region:'ap-northeast-1'},
{name:'China (Beijing)', 	region:'cn-north-1'},
{name:'China (Ningxia)', 	region:'cn-northwest-1'},
{name:'EU (Frankfurt)', 	region:'eu-central-1'},
{name:'EU (Ireland)', 	region:'eu-west-1'},
{name:'EU or', region:'eu-west-1'},
{name:'EU (London)', 	region:'eu-west-2'},
{name:'EU (Paris)', 	region:'eu-west-3'},
{name:'South America (São Paulo)', 	region:'sa-east-1'}
]      ;
