import {BillingCardComponent} from './stripe/card'
import stateCfg from './billing_stateconfig'
import {BillingWsComponent} from './ws/billing_ws_component'
import {InvoiceWsComponent} from './ws/invoice_ws_component'
import {BillingService} from './billing_service'
import {BillingPlan} from './ws/billing_plan'
import {BillingPlanList} from './ws/billing_plan_list'
import {BillingPlanLimits} from './ws/billing_plan_limits'
import {BillingPlanTableLimits} from './ws/billing_plan_table_limits'

export default angular
    .module('organization_billing', [
        // 'angular-stripe'
    ])
    // .config((stripeProvider) => {
    //     stripeProvider.setPublishableKey('pk_test_pqACySqFjs7yZbBRXwYwKpWl')
    // })
    .component('cmpBillingCard', BillingCardComponent)
    .component('cmpBillingWs', BillingWsComponent)
    .component('cmpInvoiceWs', InvoiceWsComponent)
    .component('cmpBillingPlan', BillingPlan)
    .component('cmpBillingPlanList', BillingPlanList)
    .component('cmpBillingPlanLimits', BillingPlanLimits)
    .component('cmpBillingPlanTableLimits', BillingPlanTableLimits)
    .service('BillingService', BillingService)
    .config(stateCfg);
