class ApplicationBranches {
    constructor(ApplicationsService, $state) {
        this.$state = $state;
        this.ApplicationsService = ApplicationsService;
    }

    $onInit() {
        var self = this;
        this.ApplicationsService.getExperiments(this.$state.params.applicationName)
            .then(
                (r) => {
                    let rev = this.$state.params.revision;
                    self.list = [];
                    // debugger;
                    if(r.indexOf(rev) == -1){
                        let revName = self.currentRevision.config.revision.branch + ":" + self.currentRevision.config.revision.revision;
                        self.list.push(revName);
                        self.branch = revName;
                    } else {
                        self.branch = self.currentRevision.config.revision.branch;
                    }
                    self.list = self.list.concat(r);
                },
                (err) => {
                    this.errorList = err;
                }
            );

        // this.ApplicationsService.getRevisionItem( this.$state.params.applicationName, this.$state.params.revision )
        //     .then(
        //         (r) => {

        //             self.branch = r.config.revision.branch;
        //         },
        //         (err) => {
        //             self.errorBranch = err;
        //             self.branch = null;
        //         }
        //     )
    }

    goTo(branch) {
        this.$state.go('wsApplication.tab', {revision : branch})
    }
}

export  const ApplicationBranchSelectComponent = {
    bindings: {
        currentRevision: '<'
    },
    controller: ApplicationBranches,
    template: `
    <div layout="row" layout-align="start center">
        <!--<div translate>COMMON_EXPERIMENT</div>-->
        <!--<div style="width: 10px"></div>-->
        <md-select ng-model="$ctrl.branch" ng-change="$ctrl.goTo($ctrl.branch)" style="margin: 0; max-width: 250px">
            <md-option ng-repeat="item in $ctrl.list" ng-value="item">{{item}}</md-option>
        </md-select>
    </div> 
    `
}
