export default function ($stateProvider) {
    $stateProvider
        .state('wsStorages', {
            url :  "/k-storage",
            parent: 'workspace',
            views: {
                "layout-page-content@" : {
                    template : `
                        <cmp-storage-persistent-list can-edit="true" workspace="$ctrl.WS"></cmp-storage-persistent-list>
                    `,
                    controller: function (workspace, $rootScope, permissions) {
                        this.permissions = permissions;
                        this.WS = workspace;
                    },
                    controllerAs: '$ctrl'
                }
            }
        })
}
