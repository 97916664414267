export const ApplicationClusterLinkComponent = {
    template:`
         <div class="flex">
            <span ng-switch="$ctrl.data.ClusterType">
                <md-icon ng-switch-when="infrastructure" md-svg-icon="core:link"></md-icon> 
                <md-icon ng-switch-when="shared" md-svg-icon="core:share"></md-icon>
                <md-icon ng-switch-when="global" md-svg-icon="core:share"></md-icon>
            </span> 
            [{{$ctrl.data.ClusterType}}] 
            <span ng-switch="$ctrl.data.ClusterType">
                <span ng-switch-when="infrastructure">{{$ctrl.data.ClusterID}}</span>
                <span ng-switch-when="shared">{{$ctrl.data.SharedClusterName}}</span>
                <span ng-switch-when="global">{{$ctrl.data.GlobalClusterName}}</span>
            </span>
         </div>

    `,
    bindings: {
        data: '<'
    }

}