class controller {
    // constructor(CatalogService){
    //
    // }

    $onInit() {
        this.getList();
    }

    getList() {
        this.error = null;
        this.loading = true;
        this.resource.forks({name: this.data.Name}).$promise
            .then(
                (r) => {this.list = r;},
                (err) => {this.error = err;}
            )
            .finally(() => { this.loading = null })
    }

}

export const CatalogForksComponent = {
    bindings: {
        resource: '<',
        data: '<',
        canManage: '<',
        service: '<',
        canNew: '<'
    },
    controller: controller,
    template: `
        <md-error-toast ng-if="$ctrl.error" error="$ctrl.error"></md-error-toast>
        <p ng-if="!$ctrl.list.length">{{"COMMON_LIST_EMPTY" | translate }}</p>
        <md-list ng-if="$ctrl.list.length">
            <md-list-item ng-repeat="item in $ctrl.list" ui-sref="wsCatalog.list.item({item: item.Name , Interface: $ctrl.data.Interface, wsId: item.WorkspaceName})">{{item.WorkspaceDisplayName}}/{{item.DisplayName}}</md-list-item>
        </md-list>
    `
};
