
import {ApplicationSimplyComponent} from './applications_simply_component'
import {ApplicationSimplyConfigComponent} from './applications_simply_config_component'
import {ApplicationEditTemplateComponent} from './applications_edit_template'
// import {ApplicationJobItemComponent} from './application_job_item'
// import {ApplicationJobsFilterComponent} from './application_job_filter'
// import {ApplicationJobsFilterNewComponent} from './application_job_filter_new'
// import {ApplicationJobsFilterItemComponent} from './application_job_filter_item'

export default angular
    .module('application.simply', [])
    .component('cmpApplicationsSimply', ApplicationSimplyComponent)
    .component('cmpApplicationsSimplyConfig', ApplicationSimplyConfigComponent)
    .component('cmpApplicationEditTemplate', ApplicationEditTemplateComponent)
    // .component('cmpApplicationJobItem', ApplicationJobItemComponent)
    // .component('cmpApplicationJobsFilter', ApplicationJobsFilterComponent)
    // .component('cmpApplicationJobsFilterNew', ApplicationJobsFilterNewComponent)
    // .component('cmpApplicationJobsFilterItem', ApplicationJobsFilterItemComponent)
    // .constant('projectJobParamSeparator', '.')


