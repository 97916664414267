class controller {

    constructor($scope){
        var self = this;
        $scope.$watch(
            function () {
                return self.data.value;
            },
            function (c) {
                self.parent.setYaml();
            }
        );
    }
}

export const ChartWizardSelect = {
    bindings: {
        parent: "<",
        data: "="
    },
    controller : controller,
    template: `
            <div layout="row" layout-align="start center" flex>
                <cmp-dynamic-select layout="row" layout-align="start center" flex ng-model="$ctrl.data.value" options="$ctrl.data.wizard.options" dynamic="$ctrl.data.wizard.dynamic"></cmp-dynamic-select>
            </div>
    `
};

