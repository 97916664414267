//
// function _ErrorApi ($q) {
//     this.q = $q;
//     var self = this;
//     debugger;
//     return function (error) {
//         debugger;
//         switch (error.status) {
//             case 401:
//                 ErrorApi401();
//                 break;
//         }
//         debugger;
//         return self.q.reject(error);
//     };
// }
//
// _ErrorApi.$inject = ['$q'];
//
// export function ErrorApi(){
//     return _ErrorApi();
// };
//
// export function ErrorApi401 () {
//   // console.error('401')
//     window.location.href= '/';
// };

export class ApiErrorService {

    constructor ($mdDialog, $translate) {
        this.$mdDialog = $mdDialog;
        this.$translate = $translate;
        this.error;
    }

    $onInit() {
        this.asText = (!this.format || this.format== 'text');
        if(this.format == 'dialog') {
            this.dialogError(this.error);
        }
    }

    dialogError (Error, ev) {
        if(Error.stasus){
            switch (Error.status) {
                case 400:
                case 401:
                case 403:
                case 404:
                case 405:
                case 500:
                    this.dialogAlert(Error);
                    break;
                // default:
                //     this.dialogAlert(Error);
            }
        } else {
            this.dialogAlert();
        }

    }

    dialogAlert (error, ev) {
        var self = this;
        self.$mdDialog.show({
                locals: {
                    dialog: self.$mdDialog,
                    error: self.error
                },
                controller: function ($scope, error, dialog) {
                    $scope.error = error;
                    $scope.dialog = dialog;
                },
                template: `
                        <md-dialog >
                            <cmp-error-dialog error="error"></cmp-error-dialog>
                            <md-dialog-action class="layout-row layout-align-start">
                                <span class="flex"></span>
                                <md-button ng-click="dialog.hide()" translate="COMMON_CLOSE"></md-button>
                            </md-dialog-action>
                        </md-dialog>
                    `,
                parent: angular.element(document.querySelector('#mainBody')),
                targetEvent: ev,
                clickOutsideToClose:true
            })
            .then(
                () => {
                    self.error = null;
                },
                () => {});
    }

    errorTitle () {
        if(this.error.statusText)
            return this.error.statusText ;
        else
            return "Error";
    }

    errorText (){
        if(this.error.data)
            return this.error.data.Error+ ' - URL: ' + this.error.config.url;
        else
            return this.error.message;
    }

}

export const ApiErrorServiceComponent = {
    bindings: {
        error : '=',
        format : '<'
    },
    controller : ApiErrorService,
    template:  `
        <cmp-error-page error="$ctrl.error" ng-if="$ctrl.format != 'dialog'"></cmp-error-page>
        <!--<md-card ng-if="$ctrl.asText" class="errorInfo">-->
          <!--<md-card-title>-->
            <!--<md-card-title-media>-->
              <!--<div class="md-media-sm card-media" layout>-->
                <!--<md-icon md-svg-icon="core:alert"></md-icon>-->
              <!--</div>-->
            <!--</md-card-title-media>-->
            <!--<md-card-title-text>-->
              <!--<span class="md-headline">{{$ctrl.errorTitle()}} </span>-->
              <!--<span class="md-subhead description">{{$ctrl.errorText()}}</span>-->
            <!--</md-card-title-text>-->
          <!--</md-card-title>-->
        <!--</md-card>-->`
};