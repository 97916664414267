

import Stateconfig from './workspace_stateconfig'
import {WorkspaceService} from './workspace_service'
import WsaModule from '../wsa/wsa_module'
import ClusterModule from '../clusters/clusters_module'
import ProjectsModule from '../projects/projects_module'
import ApplicationsModule from '../applications/applications_module'
import ChartsModule from '../charts/charts_module'
import {AddButtonComponent} from './add_button'
import {WorkspaceCanService} from './workspace_can_service'
import Resources from '../resources/resources_module'
import Serving from '../serving/serving_module'
import wsCatalog from '../catalog/catalog_ws_module'
import {WorkspaceCatalog} from './catalog/controller'

//2.0.0

import {WorkspaceMainMenu} from './2.0.0/workspace_main_menu'
import {WorkspaceActiveTasks} from './2.0.0/workspace_active_tasks'
import {WorkspaceLastProjects} from './2.0.0/workspace_last_projects'
import {WorkspaceLastServings} from './2.0.0/workspace_last_servings'
import {WorkspaceClusterStats} from './2.0.0/workspace_cluster_stats'
import {WorkspaceMainCard} from './2.0.0/workspace_main_card'
import {WorkspaceFaq} from './2.0.0/workspace_faq'


export default angular
    .module('workspace', [
        'ngMaterial',
        wsCatalog.name,
        WsaModule.name,
        ClusterModule.name,
        ProjectsModule.name,
        ApplicationsModule.name,
        ChartsModule.name,
        Resources.name,
        Serving.name
    ])
    .component('cmpAddButton', AddButtonComponent)
    .component('cmpWorkspaceCatalog', WorkspaceCatalog)
    .service('WorkspaceService', WorkspaceService)
    .service('WorkspacePermissions', WorkspaceCanService)
    .config(Stateconfig)



// 2.0.0
    .component('cmpWorkspaceMainMenu', WorkspaceMainMenu)
    .component('cmpWorkspaceActiveTasks', WorkspaceActiveTasks)
    .component('cmpWorkspaceLastProjects', WorkspaceLastProjects)
    .component('cmpWorkspaceLastServings', WorkspaceLastServings)
    .component('cmpWorkspaceClusterStats', WorkspaceClusterStats)
    .component('cmpWorkspaceFaq', WorkspaceFaq)
    .component('cmpWorkspaceMainCard', WorkspaceMainCard)

