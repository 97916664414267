export const WorkspaceMainCard = {
    bindings: {
        data: '<',
        permissions: '<'
    },
    controller: function() {},
    template:   `
        <md-card md-theme="default" md-theme-watch="" class="_md md-default-theme">
        <md-card-title>
          <md-card-title-text layout="row" layout-align="start ">
            <md-card-title-media>
                <div class="md-media-sm card-media">
                    <img ng-src="{{$ctrl.data.Picture}}">
                </div>
            </md-card-title-media>
            <div class="md-padding"></div>
            <div layout="column" layout-align="start" flex>
                <div class="md-headline">{{$ctrl.data.DisplayName}}</div>
                <div class="md-subhead" flex="">
                    
                </div>
                <md-card-actions layout="row" layout-align="end center" class="layout-align-end-center layout-row">
                  <button ng-if="$ctrl.permissions.can('manage')" class="md-button md-ink-ripple md-default-theme md-icon-button" style="margin: 0" type="button" ui-sref="setting({wsId: $ctrl.data.Type == 'private' ? 'my' : $ctrl.data.Name})"><md-icon md-svg-icon="core:settings"</button>
                </md-card-actions>
            </div>
                
          </md-card-title-text>
          <!--<md-card-title-media>-->
          <!--</md-card-title-media>-->
        </md-card-title>
      </md-card>
    `
}
