import {ControllerItem} from '../../core/controller/item'


export class OrganizationCardController extends ControllerItem {
    constructor (OrganizationsService, $location, $anchorScroll, $window, $state, $stateParams, $mdDialog, $translate, WorkspaceService, UserService, validate, $scope, ErrorService) {
        super($mdDialog, $translate);
        this.ErrorService = ErrorService;
        this.validate = validate;
        this.$scope = $scope;
        this.WorkspaceService = WorkspaceService;
        this.workspacename = WorkspaceService.workspacename;
        this.stateParams = $stateParams;
        this.OrganizationsService = OrganizationsService;
        this.UserService = UserService;
        this.$state = $state;
        this.$window = $window;
        this.$translate = $translate;
        this.$mdDialog = $mdDialog;
        // debugger
        this.$location = $location;
        this.$anchorScroll = $anchorScroll;
        this.data; // from binding html
        this.id = _.uniqueId('OrganizationCardController');
        this.stateParams; //bindings html

        this.nameManual = false;
        this.delayNamePickup;
        this.delayNameCheck;

        this.nameEditing = false;
        this.nameOrig;

        this.GlobalSettings = GlobalSettings;

    }

    $onInit() {
        super.$onInit();
        // if(this.data.Can) this.canManage = this.data.Can.indexOf('manage') !== -1;
        if(this.data && this.data.Name){
            this.WorkspaceService.workspace.get({wsId : this.data.Name}).$promise.then((result) => {
                this.canManage = result.Can.indexOf('manage') !== -1;
            });
        }
    }

    pickupName() {
        if(this.nameManual) return;
        if(this.delayNamePickup) {
            clearTimeout(this.delayNamePickup);
            delete this.delayNamePickup;
        }
        var data = this.data;
        var service = this.OrganizationsService;
        if(data.DisplayName && data.DisplayName.length > 2) this.delayNamePickup = _.delay(function(){
            service.orgNamePickup({name: data.DisplayName}).then(
                function(resp) {
                    data.Name = resp.Name;
                }
            );
        }, 1000);
    }

    checkName(form) {
        if(this.delayNameCheck) {
            clearTimeout(this.delayNameCheck);
            delete this.delayNameCheck;
        }
        if (this.data.Name == "") {
            form.Name.$setValidity("errorname", true);
            this.nameManual = false;
            return;
        }
        this.nameManual = true;
        var self = this;
        this.delayNameCheck = _.delay(function() {
            self.OrganizationsService.orgNameIsAvailable({name: self.data.Name}).then(
                function (resp) {
                    form.Name.$setValidity("errorname", true);
                },
                function (err) {
                    self.nameErrorMessage = err;
                    form.Name.$setValidity("errorname", false);
                }
            );
        }, 1000);
    }

    save (form) {
        var self = this;
        if(form.Name.$viewValue && form.Name.$viewValue.length && this.isNewElement){
            this.workspacename.get({name : form.Name.$viewValue})
                .$promise
                .then(
                    function (r) {
                        form.Name.$setValidity("name", true);
                        self.saveOrg(form);
                    },
                    function (err) {
                        form.Name.$setValidity("name", false);
                    }
                )
        } else {
            self.saveOrg(form);
        }
    }

    saveOrg (form) {
        if(form.$valid) {
            var self = this;
            var super_save = super.save.bind(this);
            if (self.data instanceof self.OrganizationsService.organizations) {
                super_save(form);
            } else {
                this.saving = true;
                self.OrganizationsService
                    .addOrg(self.data)
                    .then(
                        (r) => {
                            self.data = r;
                            self.saveSuccess(r);
                        },
                        _.bind(self.saveError, this)
                    )
                    .finally(() => {
                        self.saving = false;
                        self.actionView();
                    })
            }
        }
    }

    cancel () {
        if(this.data instanceof this.OrganizationsService.organizations){
            super.cancel();
        } else {
            this.$window.history.back();
        }
    }

    showMy () {
        if(this.data.ID == this.stateParams.orgId) {
            this.$state.go('settingsCurrentUser');
        }
        else {
            this.$state.go('settingsOrg', {orgId : this.data.ID});
        }
    }

    delete (ev) {
        var self = this;
        var tr = {};
        var cb_delete = (params = {}) => {
            // this.OrganizationsService.organizationCheckDelete.delete(_.extend({orgId:this.data.ID}, params)).$promise.then(
            this.data.$delete( params)
                .then((r) => {
                        this.successDelete(r);
                    },
                    (err) => {
                        this.ErrorService.error(err, cb_delete);
                    }
                );
        };
        cb_delete();
    }

    editName() {
        this.nameEditing = true;
        this.nameOrig = this.data.Name;
    }

    cancelEditName() {
        this.nameEditing = false;
        this.data.Name = this.nameOrig;
    }

    saveName() {
        if (this.data.Name == this.nameOrig) {
            this.nameEditing = false;
            return;
        }
        var self = this;
        this.OrganizationsService.namechange.update({orgId: this.data.ID, name : this.data.Name}).$promise.then(
            function(r) {
                self.data.Name = r.Name;
                self.data.Workspace = r.Workspace;
                self.nameEditing = false;
                self.UserService.getWs(true);
            },
            function(e) {
                self.cancelEditName();
            }
        );
    }

    uploadFile (form) {
        if((form && !form.$valid) || !this.file || !this.file.length) return false;
        var file = this.file;
        var self = this;
        this.sendErrorMessage = null;
        this.loadingError = false;
        this.saving = true;
        var formData = new FormData();
        angular.forEach(file, (obj) => {
            if(!obj.isRemote){
                formData.append('src[]', obj.lfFile);
            }
        });
        var super_successSave = super.saveSuccess.bind(this);
        self.OrganizationsService.organizations.upload({orgId: this.data.ID}, {picture: file[0].lfFile})
            .$promise
            .then(
                (r) => {
                    self.data.Picture = r.Picture;
                    self.lfApi.removeAll();
                    if(!form) {
                        self.saveSuccess();
                    }
                },
                (err) => {
                    self.sendErrorMessage = err;
                }
            )
            .finally(() => {
                this.saving = false;
            });
    }

    saveSuccess(r) {
        if(this.file && this.file.length) {
            this.uploadFile();
        }
        else {
            if(this.isNewElement) this.UserService.getWs(true);
            this.OrganizationsService.orgList(true); // update parent list
            this.cancel();
            this.$state.go('settingsOrg', {orgId: this.data.ID});
        }
    }

    };

export const OrganizationCardSettingsComponent = {
    bindings: {
        data: '=',
        listController: "<",
        stateParams : "<"
    },

    controller: OrganizationCardController,
    template: function ($templateCache) {
        return $templateCache.get('kuberlab/organizations/card/org_card_settings.html')
    }
};
