export class InstallTab {
    $onInit() {
        this.$scope.$watch(
            () => {
                return this.parent.selectedIndex;
            },
            (c) => {
                this.enable();
                this.parent.tabsVisitation[this.index] = true;
            }
        )
    }

    prevVisitation() {
        var r = this.index ? this.parent.tabsVisitation[this.index-1] || false : true;
        // console.log('index', this.index, r);
        return r; //this.index ? this.parent.tabsVisitation[this.index-1] : true;
    }

    enable () {
        var prev = this.parent.tabs[this.index-1] || true;
        this.tab.enable = prev  && this.canEdit() && this.prevVisitation();
        return this.tab.enable;
    }

    canEdit() {
        return true;
    }
}