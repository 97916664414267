import {CoreServiceResource} from '../../core/service/core_service_resource'

export class StoragePersistentService extends CoreServiceResource {
    constructor ($resource, $q, KuberlabConfigApi, $rootScope){
        super(...arguments);
        this.params = {};
    }

    getResource(params, clear) {
        if(this.params.wsId != params.wsId ||  clear) {
            this._resource = null;
        }

        if (!this._resource){
            // this.params = _.extend({} , params);
            var apiPath = this.cfg.APIPath + '/workspace/' + params.wsId + '/storage/:storage';
            this._resource = this.$resource(
                apiPath,
                {storage: '@Name'},
                _.extend(
                    {},
                    this.cfg.ResourceParams,
                    {
                        create: {
                            method: "POST",
                            params: {storage: '@storage'}
                        }
                    }
                )
            );
            this.params = _.extend({} , params);
            // this.params = params;
            this.list = null;
        }
        return this._resource ;
    }
}
