import {ControllerItem} from '../../core/controller/item'

export class StoragePersistentItemController extends ControllerItem {

    constructor(
        $mdDialog,
        $translate,
        WorkspaceService,
        $state,
        $scope,
        validate,
        ClusterService
    ){
        super($mdDialog, $translate);
        this.WorkspaceService = WorkspaceService;
        this.$scope = $scope;
        this.$state = $state;
        this.validate = validate;
        this.ClusterService = ClusterService;
    }

    $onInit(){
        super.$onInit();
        this.testState();
    }


    isNew() {
        return (this.data && this.data.Name) ? false : true;
    }

    prepareEditData(){
        if(!this.clusters){

            this.ClusterService.getList(this.$state.params.wsId, true)
                .then(
                    (r) => {
                        this.clusters = r;
                        if (!this.data.ClusterID && this.clusters.length) {
                            this.data.ClusterID = this.clusters[0].ClusterID;
                        }
                    },
                    (err) => {
                        this.clusters = [];
                        this.$scope.$emit('api_error', err);
                    }
                )
        }
    }

    // save(form) {
    //     debugger;
    //     super.save(...arguments);
    // }

    saveSuccess(r) {
        super.saveSuccess(r);
        if(this.listController.addComplite) {
            this.listController.addComplite(r)
        } else {

            this.testState();
        }
    }

    testState() {
        if(this.data.Status == 'pending') {
            this.listController.delayLoad();
        }
    }
}
