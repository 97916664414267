
export class controller {
    constructor(TemplateService, $mdPanel, $scope, $mdDialog) {
        this.service = TemplateService;
        this.list = [];
        this.templates = {};
        this.templatesYaml = {};
        this._mdPanel = $mdPanel;
        this.selected = {Name : ''};
        $scope.$watch(
            () => {return this.selected.Name},
            (c) => {
                if(c && c.length) {
                    this.getTemplate(c);
                    this.selected.Name = null;
                }
            }
        );
        this.$mdDialog = $mdDialog;


    }

    $onInit() {
        this.resource = this.service.getResource({type: this.type});
        this.count = this.service.count();
        this.cName = 'demo-menu-open-button' + this.count;
    }

    getList() {
        this.error = null;
        if(!this.list.length){
            this.resource.query().$promise
                .then(
                    (r) => {
                        this.list = r;
                        this.listComplite();
                    },
                    (err) => {
                        this.error = {data: {Error: r.message}}
                    }
                )
        }
    }

    listComplite() {
        this.showMenu();
    }

    getTemplate(template){
        this.error = null;
        if(!this.templates[template]) {
            this.resource.template({template: template}).$promise
                .then(
                    (r) => {
                        try{
                            // debugger
                            this.templatesYaml[template] = r.template;
                            this.templates[template] = jsyaml.safeLoad(r.template) ;
                            this.setModel(template);
                        }catch (r){
                            this.error = {data: {Error: r.message}}
                        }
                    },
                    (err) => {
                        this.error = err;
                    }
                )
        }
        else {
           this.setModel(template)
        }
    }

    setModel(template) {
        var data ;
        if(this.isModel) {
            data = _.omit(this.templates[template], 'resources', 'spec');
            data = _.extend(data, this.templates[template].resources);
            this.modelSpec = this.templates[template].spec;
        }
        else {
            var s = this.ngModel.sources || [];
            var sourceModel = _.findWhere( s, {name: 'model'} );
            data = this.templates[template];

            if (!data.spec) {
                data.spec = {};
            }

            if(sourceModel) {
                data.sources = data.sources || [];
                var dSourceModel = _.findWhere( data.sources, {name: 'model'} );

                if(dSourceModel) {
                    var index = _.indexOf( data.sources, dSourceModel);
                    data.sources[index] = sourceModel;
                }
                else {
                    data.sources.push(sourceModel);
                }
            }
        }
        this.ngModel = _.extend( {name: this.ngModel.name, displayName: this.ngModel.displayName}, _.omit(data, 'name', 'displayName'));
    }

    showMenu (ev) {
        if(!this.list.length){
            this.getList();
        } else {
            let dialog = angular.element(document).find('md-dialog');
            var position = this._mdPanel.newPanelPosition()
                .relativeTo('.' + this.cName)
                .addPanelPosition(this._mdPanel.xPosition.ALIGN_END, this._mdPanel.yPosition.BELOW);
            var self = this;
            var config = {
                attachTo: angular.element(document.body),
                controller: PanelMenuCtrl,
                controllerAs: 'ctrl',
                template: '<div class="demo-menu-example" ' +
                '     aria-label="Select your favorite dessert." ' +
                '     role="listbox">' +
                '  <div class="demo-menu-item" ' +
                '       ng-class="{selected : dessert == ctrl.favoriteDessert}" ' +
                '       aria-selected="{{dessert == ctrl.favoriteDessert}}" ' +
                '       tabindex="-1" ' +
                '       role="option" ' +
                '       ng-repeat="dessert in ctrl.desserts" ' +
                '       ng-click="ctrl.selectDessert(dessert)"' +
                '       ng-keydown="ctrl.onKeydown($event, dessert)">' +
                '    {{ dessert.Title }} ' +
                '  </div>' +
                '</div>',
                panelClass: 'demo-menu-example',
                position: position,
                locals: {
                    'selected': this.selected,
                    'desserts': this.list
                },
                clickOutsideToClose: true,
                escapeToClose: true,
                focusOnOpen: false,
                zIndex: 100
            };
            this._mdPanel.open(config);
        }
    }
}

export const TemplateComponent = {
    bindings: {
        type: '<',
        ngModel: '=',
        isModel: '<',
        modelSpec: '='
    },
    controller: controller,
    template: `
        <md-button class="md-primary md-raised {{$ctrl.cName}}" ng-click="$ctrl.showMenu($event)">use template</md-button>
    `
};

class PanelMenuCtrl {
    constructor (mdPanelRef, $timeout) {
        this._mdPanelRef = mdPanelRef;
    }

    selectDessert (dessert) {
        this.selected.Name = dessert.Name;
        this._mdPanelRef && this._mdPanelRef.close();
    }

    onKeydown ($event, dessert) {
        var handled, els, index, prevIndex, nextIndex;
        switch ($event.which) {
            case 38: // Up Arrow.
                els = document.querySelectorAll('.demo-menu-item');
                index = indexOf(els, document.activeElement);
                prevIndex = (index + els.length - 1) % els.length;
                els[prevIndex].focus();
                handled = true;
                break;

            case 40: // Down Arrow.
                els = document.querySelectorAll('.demo-menu-item');
                index = indexOf(els, document.activeElement);
                nextIndex = (index + 1) % els.length;
                els[nextIndex].focus();
                handled = true;
                break;

            case 13: // Enter.
            case 32: // Space.
                this.selectDessert(dessert);
                handled = true;
                break;

            case 9: // Tab.
                this._mdPanelRef && this._mdPanelRef.close();
        }

        if (handled) {
            $event.preventDefault();
            $event.stopImmediatePropagation();
        }

        function indexOf(nodeList, element) {
            for (var item, i = 0; item = nodeList[i]; i++) {
                if (item === element) {
                    return i;
                }
            }
            return -1;
        }
    }
}
