export function LayoutBack (LayoutService) {

    function link(scope, $element) {
    }
    return ({
        link: link,
        restrict: "E",
        template: `<a class="md-icon-button md-button backIcon" ui-sref="^"><md-icon md-svg-icon="core:back"></md-icon></a>`
    });
}
