/**
 * Created by ilja on 17.11.16.
 */

class LayoutTitleUserCardController{
    constructor () {
        // var self= this;
        // this.AuthService = AuthService;
        // this.currentUser;
        // this.Owners = [];
        // this.OwnerService = OwnerService;
        // var self = this;
        // this.currentOwnerId;
        // $rootScope.$on('currentOwner', function (event, data) {
        //     self.currentOwnerId = data.ID;
        // });
        //
        // this._owners = this.OwnerService.Owners()
        //     .then(
        //         function (r) {
        //             self.Owners = r;
        //         },
        //         function (r) {
        //             self.Owners = [];
        //         }
        //     );
    }

    isCurrent(ownerId) {
        return ownerId == this.currentOwnerId;
    }
    logout() {
        this.AuthService.logout();
    }
}

export const LayoutTitleUserCardComponent = {
    bindings: {
        currentUser: '<'
    },
    template: function ($templateCache) {
        return $templateCache.get('kuberlab/layout/title/layout_title_user_card_component.html');
    },
    controller: LayoutTitleUserCardController
};