class controller {
    constructor(ApplicationsService, $state, $scope) {
        this.$state = $state;
        this.$scope = $scope;
        this.ApplicationsService = ApplicationsService;
        this.resource = ApplicationsService.getResource( $state.params );
        this.updateTime = 10000;
        this.Pods = [];
        this.podRequest = null;
    }

    $onInit() {
        this.getJobData();
        this.$scope.$watch(
            () => { return this.data },
            (n,o) => {
                if(n.name != o.name) {
                    this.getJobData();
                }
            }
        )
    }

    grepAction() {
        this.getPods();
    }

    requestData() {
        return {
            application: this.application.Name,
            task: this.data.task_name,
            build: this.data.build,
            search: (this.grep && this.grep.length) ? this.grep : null
        }
    }

    update() {
        var self = this;
        this.ApplicationsService.getResource( this.$state.params ).task( this.requestData() )
            .$promise
            .then(function (r) {
                if( self.status != r.status ){
                    self.data = _.extend(self.data, r);
                }
                self.getPods();
            }, function (err) {
            })
            .finally(function () {
                self.getPods();
                // self.autoUpdate();
            })
    }

    autoUpdate() {
        if(this.data.status == 'Running'){
            this.timeout = _.delay(_.bind(this.update, this), this.updateTime);
        }
    }

    getPods(){
        this.uploadPods = true;
        this.uploadError = false;
        this.uploadPodsError = false;

        var self = this;
        if( this.podRequest ) {
            // debugger
            this.podRequest.$cancelRequest();
        }

        this.podRequest = this.ApplicationsService.getResource( this.$state.params ).task_pods( this.requestData() );
        this.podRequest
            .$promise
            .then(
                function (r) {
                    self.Pods = [];
                    _.each(r, function (val) {
                        if( !val.Log ) val.Log = "";
                        var job = _.findWhere(self.Pods, {Name : val.Name});
                        if(job){
                            job = _.extend(job, val);
                        }else{
                            self.Pods.push(val);
                        }
                    });
                    self.$scope.$broadcast('podUpdate');
                },
                function (err) {
                    // debugger
                    if( err.status != -1)
                        self.uploadError = err;
                }
            )
            .finally(function () {
                self.uploadPods = false;
            });
// debugger
        // console.log(this.podRequest.remove);
    }

    clickJob() {
      let sameTab = this.$state.params.tabItem === this.data.name;
      let data = {
        tabItem: sameTab ? null : this.data.name
      };

      this.$state.go("wsApplication.tab", data);

      if (sameTab) return;
      this.getJobData();
    }

    getJobData() {
        // debugger
        this.getPods();
        // this.autoUpdate();
    }

    $onDestroy() {

        clearTimeout(this.timeout);
    }
}

export const ApplicationLogsJobComponent = {
    bindings: {
        application: '<',
        data: '='
    },
    controller: controller,
    template: function ($templateCache) {
        return $templateCache.get('kuberlab/applications/logs/applications_logs_job_component.html');
    }
};
