import {CatalogBaseVersionController, CatalogItemVersionComponent} from '../../catalog/base/version/controller'

export class CatalogChartVersionController extends CatalogBaseVersionController {

    // constructor($scope, $state){
    constructor($scope, $state, $mdDialog, $templateCache, Utils, ErrorService, CatalogService){
        super(...arguments);

    }

    getVersionsComplite() {
        this.getYaml();
    }

    getYaml() {
        var self = this;
        this.yamlError = false;
        this.resource.yaml({
            name: this.data.Name,
            version: this.version.Version,
        })
            .$promise
            .then(
                function (r) {
                    self.yaml = r.yaml
                },
                function (err) {
                    self.yamlError = err;
                }
            )
    }

    aceLoaded (_editor) {
        _editor.setReadOnly(true);
    }

    install () {
        this.service.installChart(this.data.Name, this.version.Version);
    }

    download() {
        if (this.isDownloading) return;
        this.resource.version_get({name: this.data.Name, version: this.version.Version}).$promise.then(
            (r) => {
                this._download(r);
            }
        )
    }

    async _download(data) {
        try {
            this.isDownloading = true;
            this.downloadError = null;
            let file = await fetch(data.DownloadURL);

            if (file.status !== 200) {
                const error = await file.json();
                throw {data: error, status: file.status};
            }

            file = await file.blob();

            const blob = window.URL.createObjectURL(file);

            const fileName = this.data.DownloadURL.split('/').pop().split('?').shift();

            const aEl = document.createElement('a');
            aEl.download = fileName;
            aEl.href = blob;

            aEl.click();

            this.isDownloading = false;
            this.$scope.$digest();
        } catch(error) {
            // console.error(error);

            this.isDownloading = false;
            this.downloadError = error;
            this.$scope.$digest();
        }
    }

}

export const CatalogChartItemVersionComponent = _.extend({}, CatalogItemVersionComponent,  {
    controller: CatalogChartVersionController,
    template:  ($templateCache) => {
        return $templateCache.get('kuberlab/charts/version/template.html');
    }
});
