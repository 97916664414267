import {ControllerItem} from '../../core/controller/item'

export  class ProjectAppController extends ControllerItem{

    constructor (ProjectAppsService, ProjectClusterService,  $mdDialog, $translate, $state, WorkspacePermissions) {
        super($mdDialog, $translate);
        this.$state = $state;
        this.ClusterResource = ProjectClusterService._resource;
        this.ProjectAppsService = ProjectAppsService;
        this.isFull = false;
        this.permissions = WorkspacePermissions;
    }

    Notes() {
        return this.data.Notes.replace(/(?:\r\n|\r|\n)/g, '<br />');
    }

    fulInfo() {
        this.isFull = !this.isFull;
    }

    delete() {
        var self= this;
        this.deleteError = null;
        this.deliting = true;
        this.ClusterResource.delete_application({clusterName: this.cluster.Name, chartNamespace : this.data.Namespace, chartName: this.data.Name})
            .$promise
            .then(
                function (r) {
                    self.list = _.without(self.list, self.data);
                },
                function (err) {

                    self.deleteError = err;

                }
            )
            .finally(
                function () {
                    self.deliting = false;
                }
            )
    }

    restart() {
        var self= this;
        this.deleteError = null;
        this.deliting = true;
        this.ClusterResource.restart_application({clusterName: this.cluster.Name, chartNamespace : this.data.Namespace, chartName: this.data.Name})
            .$promise
            .then(
                function (r) {
                    // debugger;
                    self.data.Status = r.Status;
                    // self.list = _.without(self.list, self.data);
                },
                function (err) {
                    self.deleteError = err;
                }
            )
            .finally(
                function () {
                    self.deliting = false;
                }
            )
    }

}

export const ProjectAppComponent= {
    bindings: {
      data: '<',
        cluster: '<',
        list: "="
    },
    controller: ProjectAppController,
    template: function ($templateCache) {
        return $templateCache.get('kuberlab/projects/apps/project_app_controller.html');
    }

};