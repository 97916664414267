
import {OrganizationsService} from './organizations_service'
import {OrgController} from './organizations_controller'
import {OrganizationCardSettingsComponent} from './card/org_card_settings'
import {OrganizationCardTeamComponent, OrganizationCardTeamListCmponent} from './card/org_card_team_settings'
import TeamModule from './card/team/team_module'
import {OrganizationService} from './organization_service'
import {OrganizationFullComponent} from './organization/organization_full'
import {orgIcon} from './organization_icon'

export default angular
    .module('organizations', [
        'ngMaterial',
        TeamModule.name
    ])
    .service('OrganizationService', OrganizationService)
    .directive('orgIcon', orgIcon)
    .component('cmpOrganizationFull', OrganizationFullComponent)
    .service('OrganizationsService', OrganizationsService)
    .component('cmpOrganizationCardSettings', OrganizationCardSettingsComponent)
    .component('cmpOrganizationCardTeamList', OrganizationCardTeamListCmponent)
    .component('cmpOrganizationCardTeam', OrganizationCardTeamComponent)
    .component( 'cmpOrganizationsList', {
            bindings : {
                 stateParams: '<'
            },
            template :
            `
                <md-list class="orgatization1s_list">
                     
                    <md-list-item>
                        <h2>
                          <span>{{"SETITNGS_COMMON_TITLE_ORGANIZATIONS"|translate}}</span>
                        </h2>
                        <div class="md-secondary">
                            <a ui-sref="settingsOrgAdd" class="md-fab md-mini md-button md-ink-ripple">
                                <md-icon md-svg-icon="core:plus"></md-icon>
                             </a>
                         </div>
                    </md-list-item>
                    <md-list-item>
                       <div ui-view="new_organization" id="settings_newOrganization" flex></div>
                    </md-list-item>
                    <cmp-organization-card-settings 
                            state-params="$ctrl.stateParams" 
                            id="settnig_Org_{{data.ID}}" 
                            ng-repeat="data in $ctrl.list" 
                            data="data" 
                            list-controller="$ctrl">
                    </cmp-organization-card-settings>
                </md-list>
            `,
            controller : OrgController
        }
    )
    .component('cmpOrganizationsMenuList', {
            template :
                `
                <md-list-item ng-repeat="data in $ctrl.list" >
                    <a ui-sref="settingsOrg({orgId : data.ID})" ui-sref-active="active" class="flex layout-row layout-align-start-center">
                        <img ng-src="{{data.Picture}}" class="md-avatar" alt="" ng-if="data.Picture"/>
                        <md-icon md-svg-icon="core:org" class="md-avatar" alt="" ng-if="!data.Picture"></md-icon>
                        <span class="flex">{{data.DisplayName}}</span>
                        <md-icon md-svg-icon="core:alert" class="md-warn" alt="Billing issue" ng-if="data.billingFailed" title="Billing issue"></md-icon>
                    </a>
                </md-list-item>
                <!--<md-list-item><a ui-sref="settingsOrgAdd" class="md-raised md-button"><md-icon md-svg-icon="core:plus"></md-icon> Add organization</a></md-list-item>-->
            `,
            controller : OrgController
        }
    )
