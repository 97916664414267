import {ServingService} from './serving_service'
import Cfg from './serving_stateconfig'
import {ServingListConponent, ServingListController} from './serving_list_controller'
import {ServingListItemComponent} from  './serving_item_controller'
import {ServingLogsComponent} from './logs/serving_logs_component'
import {ServingStatusComponent} from './status/serving_status_component'
import {ServingParametersComponent} from './parameters/serving_parameters_component'
import {ServingParametersTableComponent} from './parameters/gallery/serving_parameters_table_component'
import {ServingFormSpecComponent} from './form/spec/serving_form_spec_component'
import {ServingFormConfigComponent} from './form/config'
import {ServingFormInferenceConfigComponent} from './form/inference_config'
import {ServingVolumeListComponent} from './volumes/list'
import {ServingVolumeItemComponent} from './volumes/item'
import {ServingReadmeComponent} from './readme/serving_readme_component'
import {ServingInferenceComponent} from './inference/serving_inference_component'
import {ServingStreamsComponent} from './streams/serving_streams_component'
import {GalleryServingParametersComponent, ServingParametersController} from './parameters/gallery/serving_parameters_component'
import {ServingMetricsComponent} from './metrics/serving_metrics'


import {ServingTemplateImageController} from './parameters/gallery/templates/image'
import {ServingTemplateVideoController} from './parameters/gallery/templates/video'
import {ServingTemplateWebrtcController} from './parameters/gallery/templates/webrtc'

import {ServingFormProjectConfigComponent} from './form/project/config'
import {ServingJobsComponent} from './jobs/controller'

export default angular
    .module('kuberlab.serving', [])
    .config(Cfg)
    .service('ServingService', ServingService)
    .component('cmpServingList', ServingListConponent)
    .component('cmpServingListShort', {
        controller: ServingListController,
        template: ($templateCache) => $templateCache.get('kuberlab/serving/card/serving_list_short.html')
    })
    .component('cmpServingListItem', ServingListItemComponent)
    .component('cmpServingLogs', ServingLogsComponent)
    .component('cmpServingStatus', ServingStatusComponent)
    .component('cmpServingParameters', ServingParametersComponent)
    .component('cmpServingIcon', {
        bindings: {
            data: '<'
        },
        template: ($templateCache) => { return $templateCache.get('kuberlab/serving/card/serving-item-status.html');  }
    })
    .component('cmpServingFormConfig', ServingFormConfigComponent)
    .component('cmpServingFormProjectConfig', ServingFormProjectConfigComponent)
    .component('cmpServingFormInferenceConfig', ServingFormInferenceConfigComponent)
    .component('cmpServingFormSpec', ServingFormSpecComponent)
    .component('cmpServingVolumeList', ServingVolumeListComponent)
    .component('cmpServingVolumeItem', ServingVolumeItemComponent)
    .component('cmpServingReadme', ServingReadmeComponent)
    .component('cmpServingInference', ServingInferenceComponent)
    .component('cmpServingStreams', ServingStreamsComponent)
    .component('cmpGalleryServingParameters', {
        bindings: {
            data: '=',
            autoRequest: '<'
        },
        controller: ServingParametersController,
        template:  ($templateCache) => {
            return $templateCache.get('kuberlab/serving/parameters/gallery/serving_parameters_component.html');
        },
    })
    .component('cmpServingParametersTable', {
        bindings: {
            data: '=',
            meta: '<',
            parent: '<'
        },
        controller: ServingParametersTableComponent,
        template:  ($templateCache) => {
            return $templateCache.get('kuberlab/serving/parameters/gallery/serving_parameters_table_component.html');
        },
    })
    .component('cmpServingMetrics', ServingMetricsComponent)
    .constant('servingMetricsList', [
        "cpu_usage",
        "memory_bytes",
        "gpu_usage",
        "gpu_memory_bytes",
        "gpu_powerdraw",
        "gpu_temp",
        "total_requests",
        "request_rate",
        "request_time",
        'pre_request_time',
        'post_request_time',
        'model_request_time',
        'avg_request_time',
        'avg_pre_request_time',
        'avg_post_request_time',
        'avg_model_request_time'
    ])
    .component('cmpServingTemplateImage', {
        bindings: {
            params: '=',
            parent: '<'
        },
        controller: ServingTemplateImageController,
        template:  ($templateCache) => {
            return $templateCache.get('kuberlab/serving/parameters/gallery/templates/image.html');
        },
    })
    .component('cmpServingTemplateVideo', {
        bindings: {
            params: '=',
            parent: '<'
        },
        controller: ServingTemplateVideoController,
        template:  ($templateCache) => {
            return $templateCache.get('kuberlab/serving/parameters/gallery/templates/video.html');
        },
    })
    .component('cmpServingTemplateWebrtc', {
        bindings: {
            params: '=',
            parent: '<'
        },
        controller: ServingTemplateImageController,//ServingTemplateWebrtcController,
        template:  ($templateCache) => {
            return $templateCache.get('kuberlab/serving/parameters/gallery/templates/webrtc.html');
        },
    })
    .component('cmpServingJobs', ServingJobsComponent)
