
export default {
    state: {
        name: 'catalog_new.item.tab',
        params: {
            Interface: 'chart-mlapp-v2'
        },
        dialog: true
    },
    steps:[
        {
            element: 'cmp-install-chart-ws .selectCluster',
            intro: "select workspace.",
            next: {
                action: 'click',
                actionElement: '.installFromChart .next_button'
            }
        }

        , {
            element: 'cmp-install-chart-cluster form',
            intro: "select cluster.",
            next: {
                action: 'click',
                actionElement: '.installFromChart .next_button'
            },
            back: {
                action: 'click',
                actionElement: '.installFromChart .prev_button'
            }
        }
        , {
            element: 'cmp-install-chart-mlapplication form',
            intro: "Application name",
            next: {
                action: 'click',
                actionElement: '.installFromChart .next_button'
            },
            back: {
                action: 'click',
                actionElement: '.installFromChart .prev_button'
            }
        }
        , {
            element: 'cmp-install-chart-wizard form.wizard',
            intro: "Configuration application",
            next: {
                action: 'click',
                actionElement: '.installFromChart .next_button'
            },
            back: {
                action: 'click',
                actionElement: '.installFromChart .prev_button'
            }
        }
        , {
            element: 'cmp-install-chart-volues form',
            intro: "View result config",
        }
        , {
            element: '.installFromChart .install_button',
            intro: "Install",
            showBullets: false,
            showButtons: false,
        }
    ]
}
