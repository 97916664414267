/*
configuration user interface
 */




export class UserConfigService {

    constructor($localStorage, $rootScope) {
        this.$scope = $rootScope;
        this.$localStorage = $localStorage;
        this.wsConfig = this.$localStorage.userWsConfig || {};
        this.config = this.$localStorage.userConfig || {};
        this.defVal = {
            project: {
                viewProject: 'advanced'
            },
            catalogListView: 'grid'
        }
    }

    getConfig (variable) {
        return this.config[variable] ? this.config[variable] : this.defVal[variable];
    }

    setConfig (variable, val) {
        this.config[variable] = val;
        this.$localStorage.userConfig = angular.copy(this.config);
        this.$localStorage.$apply();
        this.$scope.$emit('UserConfigService_' + variable, val);
        // console.log('UserConfigService_' + variable)
        return this.config[variable];

    }

    getWsItemConfig(wsId, itemType, itemId, variable ) {
        var v;
        try {
            v = this.wsConfig[wsId][itemType][itemId][variable];
        } catch (err) {
            if(this.defVal[itemType] && this.defVal[itemType][variable])
                v = this.defVal[itemType][variable];
            else
                v = null;
        }
        return v;
    }

    setWsItemConfig(wsId, itemType, itemId, variable, val ) {
        if (!this.wsConfig[wsId]) this.wsConfig[wsId] = {};
        if (!this.wsConfig[wsId][itemType]) this.wsConfig[wsId][itemType] = {};
        if (!this.wsConfig[wsId][itemType][itemId]) this.wsConfig[wsId][itemType][itemId] = {};
        this.wsConfig[wsId][itemType][itemId][variable] = val;
        this.$localStorage.userWsConfig = angular.copy(this.wsConfig);
        this.$localStorage.$apply();
    }


}
