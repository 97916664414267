import {ChartsListController} from '../charts_list_controller'

class controller extends ChartsListController {
    constructor (ChartsService, $scope, $state, $localStorage, $rootScope, UserService, $element, WorkspacePermissions) {
        super(...arguments);
        // this.limit = 3;
    }
}

export const ChartsCardHomeComponent = {
    controller : controller,
    template: `
        <div layout="row" layout-align="space-around" ng-if="$ctrl.isLoading">
          <md-progress-linear md-mode="indeterminate"></md-progress-linear>
        </div>
        
        <div layout="row" layout-align="space-between center" class="topline">
            <h3><a ui-sref="wsChartsList">Charts</a></h3>
            <md-button -ng-if="!$ctrl.listEmpty" ng-click="$ctrl.changeView(chartListView)" class="md-secondary md-icon-button">
                <md-icon ng-if="!$ctrl.grid" md-svg-icon="core:view-grid"></md-icon>
                <md-icon ng-if="$ctrl.grid" md-svg-icon="core:view-sequential"></md-icon>
            </md-button>
        </div>
        <div 
            class="chartList"  
            ng-class="$ctrl.grid ? 'col_3_view' : 'line_view'">
            <div 
                class="col_row_item chartList_Item"
                ng-repeat="item in $ctrl.list"  
                ng-include=" 'kuberlab/charts/card/card_chart_small.html' "></div>
        </div>
        <div ng-if="$ctrl.listEmpty"  ng-include=" 'kuberlab/charts/card/list_empty.html' "></div>
        <div style="text-align: center; margin-bottom: 2em;" ng-if="!$ctrl.listEmpty">
            <md-button ui-sref="wsChartsList">{{'COMMON_MORE'|translate}}</md-button>
        </div>
`
};
