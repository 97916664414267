
export class ItemController {
    constructor() {
        var inject = angular.element(document.body).injector();
        this.$mdDialog = inject.get('$mdDialog');
    }

    $onInit(){
        this.setLisnkData();
    }

    setLisnkData() {
        this.listData = _.extend({}, this.listController.$state.params, {tabItem: this.data.Version});
    }

    datasetName() {
        return this.listController.data.Name;
    }

    getFile(url, file){
        this.fileUrl = url;
        this.fileInfo = file;
    }

    fileDelete(url, file, cb = () => {}){
        return this.listController.resource.fileDelete({name: this.datasetName(), version: this.data.Version, path: url}).$promise
            .then (
                (r) => {
                    cb();
                },
                (err) => {
                    this.error = err;
                }
            )
    }

    cancel() {
        this.errorVersion = null;
        this.listController.removeVersion(this.data);
    }

    saveNew(form, data){
        if(!form.$valid) return  false;

        if(!data) {
            data = {
                "Version": this.newVersion,
                "Message": this.data.Message,
            };
        }
        var fullFile = [];
        if(form.file)
            _.each(form.file.$$element[0].files, (f) => { fullFile.push(f); });
        var action = 'versionCreate';
        var rData = {
            name: this.datasetName()
        };
        if(this.data.From) {
            action = 'versionClone';
            rData.toVersion = data.Version;
            rData.version = this.data.From;
        }

        this.errorVersion = null;

        this.listController.resource[action](rData, data).$promise
            .then(
                (r) => {
                    this.saveNewComplite(r, fullFile);
                },
                (err) => {

                    this.errorVersion = err;
                }
            )
            .finally(() => {

            });
    }

    saveNewComplite(r, file) {
        this.data = r;
        this.activate();
        this.setLisnkData();
    }


    commit(ev) {
        this.$mdDialog.show(
            {
                locals: {
                    data: this.data
                },
                controller: function ($mdDialog, data, $scope) {
                    $scope.data = data;
                    $scope.hide = function() {
                        $mdDialog.hide(data);
                    };

                    $scope.cancel = function() {
                        $mdDialog.cancel();
                    };
                },
                template: `
                        <md-dialog aria-label="List dialog">
                            <md-dialog-content>
                                <div class="md-padding">
                                    <h4 style="text-align: center;">Commit version {{data.Version}}</h4>
                                    <md-input-container flex>
                                        <label translate>COMMON_MESSAGE</label>
                                        <textarea ng-model="data.Message"  style="width: 350px; max-height: 200px; "></textarea>
                                    </md-input-container>
                                    <div flex layout="row" layout-alig="center center">
                                        <!--<div flex></div>-->
                                        <md-button ng-click="cancel()">{{"COMMON_CANCEL" | translate}}</md-button>
                                        <md-button ng-click="hide()">{{"COMMON_COMMIT" | translate}}</md-button>
                                    </div>
                                </div>
                            </md-dialog-content>
                        </md-dialog>
                    `,
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose:true,
                // fullscreen: $scope.customFullscreen
            }
        )
            .then(
                (r) => {
                    this._commit();
                },
                () => {}
            )

    }

    _commit() {
        this.listController.resource.versionCommit({name: this.datasetName(), version: this.data.Version}, {Message: this.data.Message})
            .$promise
            .then (
                (r) => {
                    var i = _.indexOf(this.listController.versions, this.data);
                    if(~i)this.listController.versions[i] = r;
                    this.data = r;
                },
                (err) => {
                    this.error = err;
                }
            )
    }

    activate() {
        this.listController.getItem(this.data);
        // this.listController.$state.go( this.listController.$state.current.name, this.listData, {notify:false, location: true});
        // this.listController.version = this.data;
    }

    uploadFile(form) {
        _.each(form.file.$$element[0].files, (f) => {
            this._uploadFile (f)
        });

        form.file.$$element[0].value = null;
    }

    _uploadFile (file) {

        let path = this.fileUrl ? this.fileUrl + '/' : '';
        if( !this.uploadingFiles )this.uploadingFiles = [];

        var fileInfo = {
            dir:false,
            path: path,
            listPath: this.fileUrl ? this.fileUrl.split('/') : [],
            name: file.name,
            size: file.size,
            file: file,
            Version: this.data.Version
        };

        this.uploadingFiles.push(fileInfo);
        if(this.treeApi) this.treeApi.add(fileInfo);
    }

    addFolder() {
        this.treeApi.createNewFolder();
    }

    compliteUploadFile(data){
// debugger;
        this.uploadingFiles = _.without(this.uploadingFiles, data);
    }

    deleteUploadFile(data) {
        this.treeApi.removeNewFile(data);
    }

    errorUploadFile(data){
        this.treeApi.addError(data);
    }
}

export const CatalogVersionViewItemComponent = {
    bindings: {
        data: '<',
        listController: '<',
        canManage: '<'
    },
    controller: ItemController,
    template:  ($templateCache) => {
        return $templateCache.get('kuberlab/catalog/base/version-view/version-item-template.html');
    }
}

export const CatalogVersionViewItemFullComponent = {
    bindings: {
        data: '<',
        listController: '<',
        canManage: '<'
    },
    controller: ItemController,
    template:  ($templateCache) => {
        return $templateCache.get('kuberlab/catalog/base/version-view/version-item-full-template.html');
    }
}
