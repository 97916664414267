class ShowMessage {
  $onInit() {
    this.type = this.type || 'warning';
    this.title = this.title || 'Warning';
  }
}

export const ShowMessageComponent = {
  bindings: {
    title: '@',
    message: '@',
    type: '@',
    noIcon: '@',
    compact: '@'
  },
  controller: ShowMessage,
  template: `<md-card class="{{$ctrl.type}}Info" ng-class="{'compact': $ctrl.compact}">
                  <md-card-title>
                    <md-card-title-media ng-hide="$ctrl.noIcon">
                      <div class="md-media-sm card-media" layout>
                        <md-icon md-svg-icon="core:alert"></md-icon>
                      </div>
                    </md-card-title-media>
                    <md-card-title-text>
                      <span class="md-headline">{{$ctrl.title}} </span>
                      <span class="md-subhead description">{{$ctrl.message}}</span>
                    </md-card-title-text>
                  </md-card-title>
                </md-card>`
};
