import {CoreServiceResource}  from '../core/service/core_service_resource'

export class ClusterService extends CoreServiceResource {
    constructor ($resource, $q, KuberlabConfigApi, $rootScope, $state){
        super(...arguments);
        this.$state = $state;
    }


    /**
     *
     * @param clear
     * @returns {*|Object|null}
     */



    getResource( wsId, clear){
        var wsId = wsId || this.$state.params.wsId;
        if(this.wsId != wsId || clear) this.clear();
        if(!this._resource) {
            this.wsId = wsId;
            var apiPath = this.cfg.APIPath + '/workspace/' + this.wsId + '/clusters/:ClusterID';

            this._resource = this.$resource(
                apiPath,
                {
                    ClusterID: '@ClusterID'
                },
                _.extend(
                    {},
                    {
                        storage: {
                            method: "GET",
                            url: apiPath + '/storage',
                            isArray: true
                        },
                        allocatednodes: {
                            method: "GET",
                            url: apiPath + '/allocatednodes',
                            isArray: true
                        }
                    }
                )
            );
        }
        return this._resource;
    }

    getList(wsId, params, clear) {
        if(!_.isEqual(this._clustersParams , params) || clear) this.clear();
        if(!this._clusters){
            this._clusters = this.getResource(wsId, clear).query(params).$promise ;
        }
        return this._clusters;
    }

    storage(wsId, cluster, params, clear) {
        if(!_.isEqual(this._storageParams , params) || clear) this.clear();
        if(!this._storages){
            this._storages = this.getResource(wsId, clear).storage({ClusterID: cluster.ClusterID}, params).$promise ;
        }
        return this._storages;
    }

    clear () {
        this._resource = null;
        this._storages = null;
        this.params = null;
        this._storageParams = null;
        this._clusters = null;
    }
 }