export class ServingParametersController {
    constructor(ServingService, WorkspaceService, $scope, $mdPanel) {
        this.data = {};
        this.service = ServingService;
        this.WorkspaceService = WorkspaceService;
        this.rConfig = {};
        // this.proxy = 'tfproxy';
        this.proxy = 'proxy';
        this.$scope = $scope;
        this._mdPanel = $mdPanel;
        $scope.$watch(
            () => { return this.data; },
            (c) => {
                this.init();
            },
            true
        );
        this.jsonTypes = {
            "float":        1,
            "double":       2,
            "int32":        3,
            "uint8":        4,
            "int16":        5,
            "int8":         6,
            "string":       7,
            "byte" :        7,
            "bytes":        7,
            "image":        7,
            "video":        7,
            "audio":        7,
            "complex64":    8,
            "int64":        9,
            "int":          9,
            "bool":         10,
            "qint8":        11,
            "quint8":       12,
            "qint32":       13,
            "bfloat16":     14,
            "qint16":       15,
            "quint16":      16,
            "uint16":       17,
            "complex128":   18,
            "half":         19,
            "resource":     20,
        };
        this.autoRequest = true;
        this.canUploda = true;
        $scope.$watch(
            () => { return this.config;},
            (c) => {
                this.canUploda = true;
                this.response = null;

                this.imageBuf = false;
                this.dataBuf = null;

                this.savingError = false;
                this.captionBuf = null;
                if(c && c[this.paramImageName] && this.autoRequest ){
                    this.send();
                }
            },
            true
        )
        this.webrtc = false;
    }



    init() {
        this.proxy = this.data.Type == 'project' ? 'form-data' : 'proxy';

        this.params = [];
        if(!this.config ) this.config = {};
        this.rConfig = {
            Name : this.data.Name
        };

        if( this.data.Config.spec){
            this.params = this.data.Config.spec.params;
        }
        //
        // if(this.data.Config && this.data.Config.ports && _.isArray(this.data.Config.ports) ) {
        //     var ports = _.map(this.data.Config.ports, (v) => { return v.targetPort; });
        //     this.rConfig.port = ports[0];
        // }

        this.rConfig.signature = this.data.Config.spec.signature;
        // this.rConfig.model = this.data.Config.spec.model;


        _.each(this.params, (item) => {
            if(item.type == 'byte' || item.type == 'bytes' || item.type == 'image')
                this.paramImageName = item.name;
        });

        this.responseDataFormat();

        // this.rConfig.model = 'any'
        // this.data.Config.spec.template = 'image';

        this.getRequestDesc();
        this.webrtc = _.findWhere(this.params, {type: 'webrtc'});
    }


    responseDataFormat() {
        this.rForma = {};
        // debugger;
        _.each( this.data.Config.spec.response, (v) => {
            if(!this.rForma[v.type]) this.rForma[v.type] = [];
            this.rForma[v.type].push(v.name);
        })
    }


    getRequestDesc() {
        const request = this.service.getResource( {wsId: this.data.WorkspaceName} );

        request.requestTemplates({Name: this.data.Name}).$promise.then(
            (r) => {
                let a = [];
                if(_.indexOf(r, 'curl') !== -1) a.unshift('curl');
                if(_.indexOf(r, 'md') !== -1) a.unshift('md');
                this.requestTemplates = _.uniq( a.concat(r) );

                request.requestList({Name: this.data.Name}).$promise.then(
                    (r) => {
                        this.requestList = r;

                    },
                    (err) => {
                        // debugger;
                    }
                );
            },
            (err) => {
                // debugger;
            }
        );

        if( !this.data.Config.spec.responseTemplate || this.data.Config.spec.responseTemplate == '' ) {
            request.responseExample({Name: this.data.Name}).$promise.then(
                (r) => {
                    this.responseExample = r;
                },
                (err) => {
                }
            );
        }
    }


    getData(config, params) {
        var data = {};
        if(this.data.Config.spec.rawInput)
            data.raw_input = true;
        if(this.data.Config.spec.outMimeType)
            data.out_mime_type = this.data.Config.spec.outMimeType;
        if(this.data.Config.spec.outFilter && this.data.Config.spec.outFilter.length > 0)
            data.out_filters = this.data.Config.spec.outFilter.join(",");

        if(this.data.Config.spec.options )
            data.options = this.data.Config.spec.options;

        data.inputs = {};
        var conf = config || this.config;
        var param = params || this.params;
        var rConfig = {};
        if(this.proxy == 'tfproxy') {

            _.each(param, (item) => {

                var v = !_.isUndefined(conf[item.name]) ? conf[item.name] : _.isArray(item.value) ? item.value[0] : item.value;
                var vv = v;
                if(v == null || v == undefined) return;
                if(item.type == 'string' || item.type == 'strings') vv = btoa(vv);
                if(item.type == 'byte' || item.type == 'bytes' || item.type == 'image' || item.type == 'image_webrtc' || item.type == 'audio' || item.type == 'video') vv = v.replace(/^data.*;base64,/, '');

                var d = {
                    data: this.jsonTypes[item.type] == 7 || this.jsonTypes[item.type] == 10 ? [vv] : vv,
                    dtype: this.jsonTypes[item.type]
                };

                data.inputs[item.name] = d;
            });
            rConfig = this.rConfig;
        } else if (this.proxy == 'proxy') {
            data = {};
            _.each( param, (item) => {
                var v = !_.isUndefined(conf[item.name]) ? conf[item.name] : _.isArray(item.value) ? item.value[0] : item.value;
                if(item.type == 'byte' || item.type == 'bytes' || item.type == 'image' || item.type == 'image_webrtc' || item.type == 'audio' || item.type == 'video') v = v.replace(/^data.*;base64,/, '');
                data[item.name] = v;
            } )
        }
        else {
            data = conf;
        }
        return data;
    }

    send (config, params, cb) {
        if(this.request && this.request.$cancelRequest) {
            this.request.$cancelRequest();
        }

        // var data = {};
        // if(this.data.Config.spec.rawInput)
        //     data.raw_input = true;
        // if(this.data.Config.spec.outMimeType)
        //     data.out_mime_type = this.data.Config.spec.outMimeType;
        // if(this.data.Config.spec.outFilter && this.data.Config.spec.outFilter.length > 0)
        //     data.out_filters = this.data.Config.spec.outFilter.join(",");
        //
        // if(this.data.Config.spec.options )
        //     data.options = this.data.Config.spec.options;
        //
        // data.inputs = {};
        // var conf = config || this.config;
        // var param = params || this.params;
        // var rConfig = {};
        // if(this.proxy == 'tfproxy') {
        //
        //     _.each(param, (item) => {
        //
        //         var v = !_.isUndefined(conf[item.name]) ? conf[item.name] : _.isArray(item.value) ? item.value[0] : item.value;
        //         var vv = v;
        //         if(v == null || v == undefined) return;
        //         if(item.type == 'string' || item.type == 'strings') vv = btoa(vv);
        //         if(item.type == 'byte' || item.type == 'bytes' || item.type == 'image' || item.type == 'audio' || item.type == 'video') vv = v.replace(/^data.*;base64,/, '');
        //
        //         var d = {
        //             data: this.jsonTypes[item.type] == 7 || this.jsonTypes[item.type] == 10 ? [vv] : vv,
        //             dtype: this.jsonTypes[item.type]
        //         };
        //
        //         data.inputs[item.name] = d;
        //     });
        //     rConfig = this.rConfig;
        // } else if (this.proxy == 'proxy') {
        //     data = {};
        //     _.each( param, (item) => {
        //         var v = !_.isUndefined(conf[item.name]) ? conf[item.name] : _.isArray(item.value) ? item.value[0] : item.value;
        //         if(item.type == 'byte' || item.type == 'bytes' || item.type == 'image' || item.type == 'audio' || item.type == 'video') v = v.replace(/^data.*;base64,/, '');
        //         data[item.name] = v;
        //     } )
        // }
        // else {
        //     data = conf;
        // }

        var data = this.getData(config, params);
        this.request = this.service.getResource( {wsId: this.data.WorkspaceName, Name: this.data.Name}, null,
            (e) => {
                if (e.lengthComputable) {
                    self.loadingProgres = ~~((e.loaded / e.total) * 100);
                }
            }
        )
            [this.proxy]( this.rConfig, data );

        if(!cb) {
            this.saving = true;
            this.sending = true;
            this.savingError = false;

            this.response = null;
            this.imageBuf = null;
            this.dataBuf = null;

            this.canUploda = false;
            this.captionBuf = null;
        }

        var self = this;

        this.rP = this.request
            .$promise
            .then(
                (r) => {
                    this.$scope.$broadcast('serving_request_complite', r);
                    if(cb) {
                        if(r.status >= 400) {
                            cb(null, r);
                        }else {

                            cb(r);
                        }
                    }
                    else {
                        self.response = {};
                        switch ( self.data.Config.spec.template ){
                            case 'image' :
                                var imgName = self.rForma.image && self.rForma.image.length
                                    ?
                                    self.rForma.image[0]
                                    :
                                    self.rForma.bytes && self.rForma.bytes.length
                                        ?
                                        self.rForma.bytes[0]
                                        :
                                        null;

                                if(imgName) self.response.image = r[imgName];

                                var data = r.table_output && r.table_output.length ? r.table_output : null;
                                if( data ) { //_.isString( data )){
                                    self.response.imageBufData = data; //JSON.parse(data);
                                    self.response.dsFilterList = _.uniq(_.map(self.response.imageBufData, (key) => { return key.type; }));
                                    self.response.dsFilter = self.response.dsFilterList[0];
                                    self.dsHide = true;
                                }
                                var meta = r.table_meta && r.table_meta.length ? r.table_meta : null;
                                if(_.isString( meta )){
                                    self.response.metaData = JSON.parse(meta);
                                }

                                if(r.caption_output) {
                                    if(_.isArray(r.caption_output)) self.response.captionBuf = r.caption_output;
                                    else if (_.isString(r.caption_output) ) self.response.captionBuf = [r.caption_output];
                                }
                                break;
                            default:
                                self.response = r;
                        }
                    }

                },
                (err) => {
                    if(cb) cb (null, err);
                    else {
                        this.response = null;
                        this.canUploda = true;
                        this.imageBuf = false;
                        this.dataBuf = null;
                        this.savingError = err;
                        this.captionBuf = null;
                    }
                }
            )
            .finally(() => {
                this.sending = false;

            });

        return  this.rP;
    }

    showDialog (img, type) {
        var position = this._mdPanel.newPanelPosition()
            .absolute()
            .center();

        var config = {
            attachTo: angular.element(document.body),
            locals: {
                img: img,
                type: type,
                parent: this,
                outMimeType: this.data.Config.spec.outMimeType
            },
            controller: function PanelDialogCtrl(outMimeType, img, type, parent, mdPanelRef) {
                this.data = img;
                this.type = type;
                this.outMimeType = outMimeType;
                this.parent = parent;
                this._mdPanelRef = mdPanelRef;
                if(type == 'edit') {
                    this.editData = {
                        image : this.data.image,
                        name : '',
                        names: this.data.name
                    }
                }
                this.closeDialog = function() {
                    var panelRef = this._mdPanelRef;

                    panelRef && panelRef.close().then(function () {
                        angular.element(document.querySelector('.demo-dialog-open-button')).focus();
                        panelRef.destroy();
                    });
                };
                this.save = function () {
                    var params = [
                        {
                        name: "input",
                        type: "bytes"
                        },
                        {
                        name: "name",
                        type: "string"
                        },
                        {
                        name: "action",
                        type: "string"
                        },

                    ];
                    var config = {
                        input: this.editData.image,
                        value: this.editData.name,
                        action: this.data.action //'clarify'
                    };
// debugger
                    this.parent.send(config, params, (r) => {
                        this.closeDialog();
                    })

                }
            }
            ,
            controllerAs: '$ctrl',
            template: `
                <style>
                      #servingParamsDialog img {
                     max-height: 100%; max-width: 100%; width: auto; height: auto;
                    }
                </style>
                <md-content class="" style=" min-height: 300px;" flex ng-switch="$ctrl.type">
                    <img  src="data:image/jpeg;base64,{{$ctrl.data}}" ng-switch-when="image" style="    max-width: 100%;
                                        margin: auto;
                                        display: block;">
                    <div ng-switch-when="data" style="text-align: left">
                        <pre>{{$ctrl.data | jsonStringify}}</pre>
                    </div>
                    <form ng-submit="$ctrl.save()" layout="column" layout-align="center center" ng-switch-when="edit" style="width: 100%">
                        <h4>Clarify</h4>
                        <!--<div ng-repeat=""></div>-->
                        <img  src="data:image/jpeg;base64,{{$ctrl.editData.image}}" />
                        <p>{{$ctrl.editData.names}}</p>
                        <md-input-container style="text-align: left">
                            <label>Name</label>
                            <input ng-model="$ctrl.editData.name" />
                        </md-input-container>
                        <div>
                            <md-button ng-click="$ctrl.closeDialog()">close</md-button>
                            <md-button type="submit">send</md-button>
                        </div>
                        
                    </form>
                </md-content>
            `,
            hasBackdrop: true,
            panelClass: 'demo-dialog-example layout-row layout-align-center',
            position: position,
            trapFocus: true,
            zIndex: 150,
            clickOutsideToClose: true,
            escapeToClose: true,
            focusOnOpen: true
        };

        this._mdPanel.open(config);
    };


}

