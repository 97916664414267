class controller{
    constructor(NotifyService, $scope, ErrorService){
        this.NotifyService = NotifyService;
        this.ErrorService = ErrorService;
        this.isOpen = false;
        this.NotifyService.getResource();
        $scope.$watchCollection(
            () => {
                return this.NotifyService.list;
            },
            (c) => {
                this.list = c;
                this.active = this.list.length > 0 ? true : false;
                if(!this.list.length) {
                    this.isOpen = false;
                }
            }
        )
    }

    open() {
        this.isOpen = !this.isOpen;
    }

    action(item) {
        var req;

        req = this.NotifyService.fix(item);

        // switch (item.Type){
        //     case 'repo_deploy_key':
        //         req = this.NotifyService.repoReset(item);
        //         break;
        //     case 'test':
        //         this.NotifyService.getResource().close({workspace: item.WorkspaceName, id: item.Data.object_id}).$promise.then(
        //             // (r) => {
        //             //     debugger;
        //             // },
        //             // (err) => {
        //             //     debugger;
        //             // }
        //         );
        //         break;
        //
        // }
        if(req){
            req.then(
                () => {},
                (err) => {
                    this.ErrorService.error(err);
                }
            )
        }

    }
}

export const NotifyListComponent = {
    controller: controller,
    template : ($templateCache) => { return $templateCache.get('kuberlab/notify/notify_list_component.html');}
};