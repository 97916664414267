

import {InferenceListController} from './inference_list_controller'
import {InferenceItemController} from './inference_item_controller'
import {InferenceItemNewController} from './inference_item_new_controller'

export default function ($stateProvider) {
    $stateProvider

        .state('wsInference', {
            parent: 'wsBase',
            abstract: true,
            resolve: {
                inferenceResource : function(workspace, InferenceService, $state, $stateParams, pageConfig){
                    pageConfig.setTitle('inference');
                    return InferenceService.getResource($stateParams);
                }
            }
        })

        .state('wsInferenceAdd', {
            parent: 'wsInference',
            url : '/add_inference',
            resolve: {
                currentUser : function ( CurrentUser ) {
                    return CurrentUser;
                },
                inference : function( inferenceResource, currentUser, workspace, $rootScope){
                    $rootScope.pageTitleConfig =  [
                        {
                            title: workspace.DisplayName,
                            state: 'workspace'
                        },
                        {
                            title : "add new inference",
                            state: 'wsInferenceAdd'
                        }
                    ];
                    return new inferenceResource({});
                }
            },
            views : {
                "@wsBase" : {
                    templateProvider: function ($templateCache) {
                        return $templateCache.get('kuberlab/catalog/base/card/card_new.html');
                    },
                    controller: InferenceItemNewController,
                    controllerAs: '$ctrl'
                }
            }
        })

        .state('wsInferenceList', {
            parent: 'wsInference',
            url : '/inference?:search:page:limit',
            params: {
                page: '1',
                limit: '30'
            },

            resolve: {

                addBtn : function ($rootScope, workspace) {
                    $rootScope.pageTitleConfig =  [
                        {
                            title: workspace.DisplayName,
                            state: 'workspace'
                        },
                        {
                            title : "inference",
                            state: 'wsInferenceList'
                        }
                    ];

                    return $rootScope.addButtonConfig = [
                        {
                            titile :'add new inference',
                            icon: "core:inference",
                            state: {
                                name: 'wsInferenceAdd'
                            }
                        }
                    ];
                }
            },
            views: {
                "@wsBase" : {
                    templateProvider: function ($templateCache) {
                        return $templateCache.get('kuberlab/catalog/base/card/card_list.html');
                    },
                    controller: InferenceListController,
                    controllerAs: '$ctrl'
                }
            }
        })

        .state('wsInferenceItem_base', {
            parent: 'wsInference',
            url : '/inference/:item/:tab',
            params: {
                tab: 'readme'
            },
            abstract: true,
            resolve: {
                pageTabs: function (catalogInferencePageTabs) {
                    return catalogInferencePageTabs;
                },
                inference : function (inferenceResource, $stateParams ) {
                    return inferenceResource.get({
                            name : $stateParams.item
                    })
                        .$promise;
                    },
                addBtn : function (workspace, $rootScope, inference) {
                        $rootScope.pageTitleConfig =  [
                            {
                                title: workspace.DisplayName,
                                state: 'workspace'
                            },
                            {
                                title : "inference",
                                state: 'wsInferenceList'
                            },
                            {
                                title : inference.DisplayName,
                                state: 'wsInferenceItem'
                            }
                        ];

                        return $rootScope.addButtonConfig = null;

                    }
                },
            views: {
                        "@wsBase" : {
                        templateProvider: function ($templateCache){
                        return $templateCache.get('kuberlab/catalog/base/card/card_item.html');
                    },
                    controller: InferenceItemController,
                    controllerAs: '$ctrl'
                }
            }
        })

        .state('wsInferenceItem', {
            parent: 'wsInferenceItem_base',
            url:'/:tabItem',
            resolve: {
                currentTab : function (pageTabs, $stateParams, $q, pageConfig, inference) {
                    var tab = _.findWhere(pageTabs, {title: $stateParams.tab});

                    if(tab){
                        return $q.resolve(tab);
                    }
                    else
                        return $q.reject();
                }
            },
            views: {
                tabContent : {
                    templateProvider: function (currentTab) {
                        return currentTab.content;
                    },
                    // controller: function (inference, inferenceResource, $state, pageConfig, $stateParams) {
                    //     // debugger;
                    //     pageConfig.setTitle(inference.Name + ' ' + $stateParams.tab);
                    //     this.inference = inference;
                    //     this.inferenceResource = inferenceResource;
                    //     this.$state = $state;
                    // },
                    controller: function (inference, inferenceResource, $state, pageConfig, $stateParams, permissions, InferenceService) {
                        debugger;
                        pageConfig.setTitle(inference.Name + ' ' + $stateParams.tab);
                        this.data = inference;
                        this.$state = $state;
                        this.readmeUrl = '/workspace/' + this.$state.params.wsId + '/inferences/' + this.$state.params.item + '/readme';
                        // this.readmeUrl = InferenceService.getWsUrl($state.params) + "/" + this.$state.params.item + '/readme';
                        this.resource = inferenceResource;
                        this.permissions = permissions;
                        this.canManage = permissions.can('mlapp.manage');
                        this.service = InferenceService;
                    },
                    controllerAs: '$ctrl'
                }
            }
        })



}
