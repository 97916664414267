import {ws_listener} from "../../core/controller/ws_listener";

class controller extends ws_listener  {
    constructor (ServingService, $state, WorkspaceService, $scope) {
        super();
        this.ServingService = ServingService;
        this.$state = $state;
        this.$scope = $scope;
        this.WorkspaceService = WorkspaceService;
        this.watchWS('servings', this.ws_servings);
        this.limit = 10;
    }

    $onInit() {
        this.lastProjects = this.ServingService.lastServ(this.$state.params.wsId);
        this.ServingService.getLastServ(this.$state.params.wsId)
            .then(
                (r) => {
                    this.lastProjects = r;
                },
                (err) => {}
            )
    }

    ws_servings(event, data) {
        if(data.WorkspaceName == this.$state.params.wsId) {
            var i, app = _.findWhere(this.lastProjects, {Name: data.Name});
            if(app) {
                i = _.indexOf(this.lastProjects, app);
                if(i != -1){
                    this.lastProjects[i] =_.extend(this.lastProjects[i],  data);
                    this.$scope.$apply();
                    console.log( 'this.lastServings[i]', this.lastProjects[i] );
                    this.ServingService.updateListServ(this.$state.params.wsId, angular.copy(this.lastProjects));
                }
            }
        }
    }

}

export const WorkspaceLastServings = {
    bindings: {
        limit: '='
    },
    controller: controller,
    template: `
        <md-card>
            <md-card-title>
                <md-card-title-text>
                    <span class="md-headline">Recent servings</span>
                </md-card-title-text>
            </md-card-title>
            <md-content>
                <md-list >
                    <md-list-item ng-repeat="item in $ctrl.lastProjects | limitTo: $ctrl.limit" ui-sref="wsServing({servingName: item.Name})">
                        <item-status-icon data="item"></item-status-icon>
                        <p>{{item.Name}}</p>
                    </md-list-item>
                    <md-list-item ng-if="!$ctrl.lastProjects.length">
                         <p flex >You've not visited any <a ui-sref='wsServingList'>servings</a>.</p>
                    </md-list-ite
                </md-list>
            </md-content>
          </md-card>
    `
}
