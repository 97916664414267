export class InstallChart {
    constructor( $scope,
                 chart,
                 dialog,
                 clusters,
                 ChartsService,
                 workspace_name,
                 $state,
                 $sce,
                $rootScope
    ){
        this.$sce = $sce;
        this.$mdDialog = dialog;
        this.chart = chart;
        this.clusters = clusters || [];
        this.workspace_name = workspace_name;
        // this.ws = ws;
        this.target_applications = [];
        this.ChartsService = ChartsService;
        this.ChartsResource = ChartsService.getResource({}, true);
        this.$state = $state;
        this.canInstall = true;
        this.selectedIndex = 0;
        this.tabsVisitation = {};
        this.tabs = ['Chart', 'Project', 'Cluster', 'Application', 'config', 'Values'];
        this.$rootScope = $rootScope;
        // $scope.$on(
        //     () => {return this.selectedIndex;},
        //     (c) => {
        //         this.$rootScope.$emit('can_inter')
        //     }
        // );
        var self = this;
        this.currentChartIsMlapp = false;
        // $scope.$watch(
        //     () => {return this.selectedIndex;},
        //     (c) => {
        //         this.tabsVisitation[c] = true;
        //     }
        // );
        this.tabsListBase = [
            {
                can: !this.chart,
                title: 'Chart',
                content: '<cmp-install-chart-find  chart="$ctrl.chart" tab="tab" index="$index" parent="$ctrl"></cmp-install-chart-find>'
            },
            {
                can: !this.workspace_name,
                // title: 'Project',
                title: 'Organizations',
                content: '<cmp-install-chart-ws  tab="tab" index="$index" parent="$ctrl"></cmp-install-chart-ws>'
            },
            {
                can: !this.clusters || !this.clusters.length,
                title: 'Cluster',
                content: '<cmp-install-chart-cluster  tab="tab" index="$index" parent="$ctrl"></cmp-install-chart-cluster>'
            },
            {
                can: true,
                title: 'Application',
                content: _.bind(this.applicationWidget, this)
            },
            {
                can: _.bind(this.chartInstallConfig, this, 'ClusterStorage'),
                title: 'config',
                content: '<cmp-install-chart-wizard tab="tab" index="$index"  parent="$ctrl" wizards="$ctrl.wizards" yaml-data="$ctrl.yamlData"></cmp-install-chart-storages>'
            },
            {
                can: true,
                title: 'Values',
                content: '<cmp-install-chart-volues charts-resource="$ctrl.ChartsResource" chart="$ctrl.chart" yaml="$ctrl.yaml" tab="tab" index="$index"  parent="$ctrl" ></cmp-install-chart-volues>'
            }

        ];
        this.tabsList = _.map(this.tabsListBase, function (val) {
            return _.clone(val);
        });

        this.dataChart = {};
        this.editorOptions = {
            mode : 'tree',
            expanded: true
        };
        this.$mdDialog = dialog;
        this.data = {};
        this.$scope = $scope;

        this.wsData;

        var self = this;

        $scope.$watch(
            function () {
                return self.chart;
            },
            function (c) {
                self.tabInit();
                if(c) {
                    self.getYaml();
                }

            }
        );
        // $rootScope.$emit('can_inter');
    }


    getYaml(params) {
        if(!this.chart) return false;
        var self = this;
        this.loading = true;
        this.error = false;
        var action = params ? 'values_yaml_params' : 'values_yaml';
        // this.enable();
        this.ChartsService.getResource({wsId: this.chart.WorkspaceName, Interface: this.chart.Interface}, true)[action]({
            // wsId: this.chart.WorkspaceName,
            name: this.chart.Name,
            version: this.chart.Version
        },params)
            .$promise
            .then(
                function (r) {
                    self.yaml = r.yaml;
                    self.yamlData = jsyaml.safeLoad(r.yaml);

                },
                function (err) {
                    self.error = err;
                })
            .finally(function () {
                self.loading = false;
                self.tabInit();
                // self.enable();
            })

    }

    setYaml() {
        this.yaml = jsyaml.dump(this.yamlData);
    }

    chartInstallConfig (conf) {
        this.wizards = [];
        if(this.yamlData) {
            _.each(this.yamlData, function (val, key) {
                if(val != null && val.wizard) this.wizards.push(_.extend({block: key}, val));
            }, this);
        }
        this.$rootScope.$emit('can_inter');
        return !!this.wizards.length;
        // return this.chart && this.chart.InstallConfig && this.chart.InstallConfig[conf];
    }

    applicationWidget(){
        var t = '';
        if(this.isMlapp(this.chart)) {
            t = '<cmp-install-chart-mlapplication application="$ctrl.target_application" tab="tab" index="$index"  parent="$ctrl"></cmp-install-chart-mlapplication>';
        }
        else if(this.chart){
            t = '<cmp-install-chart-applications applications="$ctrl.target_applications" tab="tab" index="$index" parent="$ctrl"></cmp-install-chart-applications>';
        }
        return t;
    }

    tabInit(){
        var self = this;
        this.tabs = _.filter(this.tabsList, function (val) {
            return _.result(val, 'can');
        });

        _.each(this.tabs, function (val) {
            var b = _.findWhere(self.tabsListBase, {title : val.title});
            val.contentParce = _.result(b, 'content');
        });
        this.tabs[0].enable = true;
    }

    installAction() {
        var self = this;
        var data = {
            workspace_name: this.workspace_name,
            cluster_id: this.clusters[0].ClusterID,
            values_yaml: this.yaml
        };

        if(this.target_applications && this.target_applications.length) {
            data.target_applications = this.target_applications;
        }
        if(this.target_application) {
            data.target_application = this.target_application;
        }
        this.installError = false;
        this.instaling = true;
        this.ChartsService.getResource({wsId: this.chart.WorkspaceName, Interface: this.chart.Interface}, true)
            .install({name: this.chart.Name, version: this.chart.Version}, data)
            .$promise
            .then(
                function (r) {
                    if(self.isMlapp(self.chart)){
                        // self.$state.go('wsApplication.tab', {
                        self.$state.go('wsApplication', {
                            wsId: self.workspace_name,
                            applicationName: self.target_application
                        });
                    }
                    else{
                        self.$state.go('wsProject.tab', {
                            wsId: self.workspace_name,
                            projectName: self.clusters[0].ProjectName,
                            envName: self.clusters[0].Environment,
                            tab: "tasks"
                        });
                    }
                    self.$mdDialog.hide( {chart: r, data: self.dataChart} );

                },
                function (err) {
                    self.installError = err;
                }
            )
            .finally(function(){
                self.instaling = false;
            })
    }

    isMlapp (item) {
        return this.ChartsService.isMlapp(item);
    }

    next() {
        var tab = _.clone(this.tabs).splice(this.selectedIndex + 1);
        var next = _.findWhere(tab, {enable: true});
        if(next) {
            this.selectedIndex = _.indexOf(this.tabs, next);
        }
    }

    prev() {
        var tab = _.clone(this.tabs).splice(0, this.selectedIndex);
        var next = _.findWhere(tab.reverse(), {enable: true});
        if(next) {
            this.selectedIndex = _.indexOf(this.tabs, next);
        }
    }

    $onDestroy(){
        // this.ChartsService.reset();
    }

    cancel() {
        // this.ChartsService.reset();
        this.$mdDialog.cancel();
    }
};




export const InstallChartTemplate =
    `<md-dialog  class="installFromChart">
        <md-toolbar class="md-raised md-primary">
          <div class="md-toolbar-tools">
             <h2>Install step {{$ctrl.selectedIndex + 1}}</h2>
            <span flex></span>
            <md-button class="md-icon-button" ng-click="$ctrl.cancel()">
              <md-icon md-svg-src="core:close" aria-label="Close dialog"></md-icon>
            </md-button>
          </div>
        </md-toolbar>
    <md-dialog-content class="flex layout-column">
        <!--<md-tabs class="md-accent_ flex" style="height: 100%;"  md-selected="$ctrl.selectedIndex" md-border-bottom md-autoselect>-->
        <md-tabs class="md-accent_ flex" style="height: 100%;"  md-selected="$ctrl.selectedIndex" md-border-bottom >
            <md-tab ng-repeat="tab in $ctrl.tabs" id="tab_{{$ctrl.title}}" ng-disabled="!tab.enable" >
                <md-tab-label>{{tab.title}}</md-tab-label>
                <md-tab-body>
                    <!--<md-tab-content ng-scrollbars ng-scrollbars-config="-->
                        <!--{-->
                            <!--autoHideScrollbar: false,-->
                            <!--theme: 'light',-->
                            <!--advanced:{-->
                                <!--updateOnContentResize: true-->
                            <!--},-->
                            <!--setHeight: 200,-->
                            <!--scrollInertia: 0-->
                        <!--}-->
                    <!--">-->
                        <div style="height: 100%; width: 100%;" bind-html-compile="tab.contentParce"></div>
                    <!--</md-tab-content>-->
                </md-tab-body>
            </md-tab>
        </md-tabs>
    </md-dialog-content>
        <cmp-api-error-service ng-if="$ctrl.installError" error="$ctrl.installError"></cmp-api-error-service>
        <md-dialog-actions layout="row">
           <md-progress-linear ng-if="$ctrl.instaling" flex></md-progress-linear>
          <md-button ng-click="$ctrl.cancel()">Cancel</md-button>
          <md-button 
                class="prev_button"
                ng-click="$ctrl.prev()" 
                ng-disabled="!$ctrl.selectedIndex ">Prev</md-button>
          <md-button 
                class="next_button"
                ng-click="$ctrl.next()" 
                ng-class="[{'md-raised md-primary': ($ctrl.tabs[$ctrl.selectedIndex +1].enable || $ctrl.tabs[$ctrl.selectedIndex + 2].enable)}]" 
                ng-disabled="!$ctrl.tabs[$ctrl.selectedIndex].enable || (!$ctrl.tabs[$ctrl.selectedIndex + 1].enable && !$ctrl.tabs[$ctrl.selectedIndex + 2].enable)">Next</md-button>
          <md-button 
                class="install_button"
                ng-click="$ctrl.installAction()" 
                ng-class="[{'md-raised md-primary': $ctrl.tabs[$ctrl.tabs.length - 1].enable && ($ctrl.selectedIndex == $ctrl.tabs.length - 1) && $ctrl.canInstall}]" 
                ng-disabled="!$ctrl.tabs[$ctrl.tabs.length - 1].enable || $ctrl.instaling || !$ctrl.canInstall">Install
                </md-button>
        </md-dialog-actions>
    </md-dialog>`
;
