export function ItemStatusIcon () {
    function link(scope, element) {
        // if(scope.data) {
        //     scope.Health = scope.data.Health || scope.data.Status || scope.data.status;
        //     element.addClass(scope.Health);
        // }

        scope.c_class
        scope.$watch('data', (c) => {
            if(c) {
                // debugger
                if(scope.c_class) {
                    element.removeClass(scope.c_class);
                }
                scope.Health = scope.data.Health || scope.data.Status || scope.data.status;
                element.addClass(scope.Health);
                scope.c_class = scope.Health;
            }
        },
        true)

    }
    return({
        template: `
                <md-button>
                    <md-icon md-svg-icon="core:status{{Health}}" class="status {{Health}}" ></md-icon>
                    <span ng-if="data.ContainersCount >= 0 ">{{data.ContainersCount}}</span>
                    <span ng-if="data.RunningTasks >= 0">|
                        <span>{{data.RunningTasks}}</span>
                    </span>
                    <span ng-if="data.GPUUsed">G</span>
                    <md-tooltip>
                        Health: {{Health}}. 
                        <span ng-if="data.ContainersCount">Containers: {{data.ContainersCount}}.</span> 
                        <span ng-if="data.RunningTasks">Running task: {{data.RunningTasks}}.</span> 
                        <span ng-if="data.GPUUsed">GPU usage: {{data.GPUUsed}}.</span>
                    </md-tooltip>
                </md-button>
                `,
        scope: {
            data: '=data'
        },
        link: link,
        restrict: "E"
    });
}
