
export class controllerList {
    constructor ($scope, ProjectStoragesService, ErrorService) {
        this.ErrorService = ErrorService;
        this.$scope = $scope;
        this.ProjectStoragesService = ProjectStoragesService;
        this.action = null;
    }
    
    // $onInit() {
    //     if( !this.list ) this.list = this.appController.data.Configuration.spec.volumes;
    // }

    getNewSource(){
        this.Source = angular.copy({});
    }

    add (data) {
        if( !_.isObject(this.list) ) this.list= [];
        var d = angular.copy(data);
        this.list.push(d);
        // this.setIsTrainLogDir(d);
        this.applicationSave(d, "add");
        this.action = 'saving';
        // this.list[this.list.length - 1].saving = 'seving';
        this.Source = null;
    }

    remove (data, save = true) {
        this.action = 'deleting';
        this.list = _.without(this.list, data);
        // data.deliting = true;
        if(save) { _.delay(()=>{
            this.applicationSave()
                // .then(
                //     r => {
                //         debugger;
                //     },
                //     err =>{
                //         data.saving = null;
                //     })
                // .finally()
            ;
            // data.saving = 'deleting';
            }, 0 )};
    }

    edit () {
        return this.save();
    }

    save(data) {
        var i =_.indexOf(this.list, _.findWhere(this.list, {$$hashKey: data.$$hashKey}));

        if( i != -1) {
            this.list[i] = data;
        }

        this.applicationSave(data);
    }

    applicationSave(data, action = 'edit') {
        var self = this;
        this.updateError = false;
        this.saving = true;
        this.$scope.$broadcast('start_saving');
        // return this.appController.data.$update()
        return this.appController.update()
            .then(function (r) {
                    // debugger;
                    // self.appController.data = r;
                },
                function (err) {
                    if(action == 'add') self.remove(data, false);
                    self.ErrorService.error(err);
                }
            )
            .finally(function () {
                self.appController.ApplicationsService._reset('revision');
                self.$scope.$broadcast('stop_saving');
                self.saving = false;
                self.action = null;
            })
    }

}

export const ApplicationsVolumesComponent = {
    bindings:{
        // application: '=',
        appController: '=',
        list: "=",
        workspaceName: '<'
    },
    controller: controllerList,
    template: function ($templateCache) {
        return $templateCache.get('kuberlab/applications/volumes/applications_volumes_component.html');
    },
};
