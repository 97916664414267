import {ProjectClusterBaseController} from '../project_cluster_base_controller'

export  class ProjectClusterAppController extends ProjectClusterBaseController{
    constructor($state, $scope, WorkspaceService, ProjectClusterService, $mdDialog, $translate, ProjectAppsService, autoUpdate, AuthService, WorkspacePermissions) {
        super( $state, $scope, WorkspaceService, ProjectClusterService, $mdDialog, $translate, WorkspacePermissions);
        this.service = ProjectAppsService;
        this.autoUpdate = autoUpdate;
        this.AuthService = AuthService;
        this.permissions = WorkspacePermissions;
    }


    getFullInfo(update){
        if(!this.appsList) {
            update = true;
        }
        if(this.isDisabled()) {}
        else {
            if(update){
                var self = this;
                this.service.getResource(_.extend({}, this.$state.params, {clusterName: this.data.Name}));
                this.appsListState = false;
                this.loading = true;
                this.loadingError = null;
                this.service.getList(update)
                    .then(
                        function (current) {
                            if(!self.appsList) {
                                self.appsList = current;
                            }else {
                                var deleted = [];
                                _.each(self.appsList, function (el, key) {
                                    var cEl  = _.findWhere(current, {Name : el.Name});
                                    if(cEl) {
                                        current = _.without(current, cEl);
                                        _.each(self.appsList[key],
                                            function (val, name) {
                                                if(name!=='$$hashKey' && name!=='Name' )
                                                    self.appsList[key][name] = null;
                                            }
                                        );
                                        self.appsList[key] =  _.extend( self.appsList[key], cEl.toJSON() );
                                    }else{
                                        deleted.push(el)
                                    }
                                });
                                _.each(deleted, function (el, key) {
                                    self.appsList = _.without(self.appsList, el);
                                });
                                if(current.length) {
                                    self.appsList.concat(current);
                                }
                            }
                            // self.appsList = r;
                        },
                        function (err) {
                            self.loadingError = err;
                            self.appsList = [];
                        }
                    )
                    .finally (
                        function () {
                            self.loading = false;
                            self.appsListState = null;
                            if(self.timeout) {
                                clearTimeout(self.timeout)
                            }
                            self.timeout = _.delay( _.bind(self.getFullInfo, self), self.autoUpdate ,true);
                        }
                    )
            }
        }
        return this.appsList;
    }

    $onDestroy() {
        if(this.timeout) {
            clearTimeout(this.timeout);
        }
    }
}


export const ProjectClusterAppComponent= {
    bindings: {
      data: '<'
    },
    controller: ProjectClusterAppController,
    template: `
          
        <div ng-include=" 'kuberlab/projects/card/project_card_title_list.html' "></div>
        
        <div ng-if="$ctrl.isActive" class="paddingListItemTabContent fullInfo">
            <md-list >
                <md-progress-linear md-mode="indeterminate" ng-if="$ctrl.loading"></md-progress-linear>
                <!--<md-list-item ng-if="$ctrl.appsListState.Loading"></md-list-item>-->
                <cmp-api-error-service ng-if="$ctrl.loadingError" error="$ctrl.loadingError" format="'text'"></cmp-api-error-service>
                <div ng-if="$ctrl.isDisabled()">
                    <p translate>{{'CLUSTER_NOT_WORKING'}}</p>
                </div>
                <cmp-project-app ng-repeat="item in $ctrl.appsList | orderBy : 'name' " data="item" cluster="$ctrl.data" list="$ctrl.appsList"></cmp-project-app>
            </md-list>
        </div>
        `
};
