import {InstallTab} from './install_tab'

class InstallChartWS extends InstallTab{
    constructor($scope, UserService, $state) {
        super();
        this.$scope = $scope;
        this.wsList = UserService.ws;
        this.$state = $state;
        this.workspace_name;
        // if($state.params.wsId && _.findWhere(this.wsList, {Name: $state.params.wsId})) {
        //     this.parent.workspace_name = $state.params.wsId;
        // }
    }

    $onInit () {
        super.$onInit();
        if(this.$state.params.wsId && _.findWhere(this.wsList, {Name: this.$state.params.wsId})) {
            this.parent.workspace_name = this.$state.params.wsId;
            this.selectedIndex = this.index;
            // _.delay( () => {
            //         this.parent.workspace_name = this.$state.params.wsId;
            //         this.selectedIndex = this.index;
            //         this.$scope.$apply();
            //     }, 1000);
        }
    }

    canEdit() {
        return !!this.parent.chart;
    }
}

export const InstallChartWSComponent = {
    bindings: {
        ws: "=",
        parent: "<",
        index: "<",
        tab: '='
    },
    controller: InstallChartWS,
    template:`
            <div class="selectCluster flex layout-row layout-align-space-between-stretch" style="width: 100%" >
                <div class="flex md-padding">
                    <h4>Workspace</h4>
                        <md-radio-group ng-model="$ctrl.parent.workspace_name " ng-change="$ctrl.wsChange()">
                            <md-radio-button ng-repeat="ws in $ctrl.wsList  | orderBy:['Type','DisplayName'] " value="{{ws.Name}}">{{ws.DisplayName}}</md-radio-button>
                        </md-radio-group>
                </div>
            </div>  
                
        `
};
