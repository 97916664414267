/**
 * Created by ilja on 06.12.16.
 */
import {ws_listener} from './ws_listener'

export class ControllerList extends ws_listener {
    constructor(){
        super();
        this.service;
        this.resource;
        this.list;
        // var inject = angular.element(document.body).injector();
        // this.$rootScope = inject.get('$rootScope');
    }

    setList(r) {
        this.list = r;
    }

    Can(perm) {
        return !!(this.can && this.can[perm]);

    }

    add (data={}) {
        if(!this.list) this.list = [];
        this.list.push(new this.resource(data));
    }

    removeNew(item) {
        this.list = _.without(this.list, item);
    }

    deleteItem(item) {
        this.list = _.without(this.list, item);
        if(!this.list.length) {this.listEmpty = true;}
    }

    changeList() {
        // debugger;
        // this.list = _.compact(this.list);

    }

    // watchWS(event, cb = () => {}) {
    //     var self = this;
    //     var _cb = cb ;
    //     this.$rootScope.$on('ws:' + event, (event, data) => {
    //         _cb.call(self, event, data);
    //     })
    // }

}