import  {scenCreateProject} from './scen/create_project'

export class IntroService {}
export class IntroService1 {
    constructor (intro_option_test, ngIntroService, $state, $rootScope, $transitions) {
        this.scens = {
            scenCreateProject : scenCreateProject
        };
        this.$transitions = $transitions;
        this.$state = $state;
        this.ngIntroService = ngIntroService;
        // debugger;
        // this.ngIntroService.onBeforeChange(_.bind(this.BeforeChangeEvent, this));
        // this.ngIntroService.onComplete(_.bind(this.CompletedEvent, this));
        // this.ngIntroService.onHintClick(_.bind(this.onHintClickEvent, this));

        // onComplete(callback: Function): void;
        // onExit(callback: Function): void;
        // onBeforeChange(callback: Function): void;
        // onAfterChange(callback: Function): void;
        // onChange(callback: Function): void;
        // onHintClick(callback: Function): void;
        // onHintClose(callback: Function): void;
        // onHintsAdded(callback: Function): void;
        // this.ShouldAutoStart = true;
        window.testIntro = this;
        var self = this;
        // $rootScope.$on("$stateChangeSuccess", (event, toState, toParams, fromState, fromParams) => {
        //     if(this.scen) {
        //         _.delay(() => {
        //             this.findSeries();
        //         } , 1000);
        //     }
        // });

        this.listenerState = this.$transitions.onStart(
            { },
            (transition, state) => {
                if(this.scen) {
                    _.delay(() => {
                        this.findSeries();
                    } , 1000);
                }
            });

        // $rootScope.$on('can_inter_step', () => {
        //     if(self.scen) {
        //         _.delay(() => {
        //             debugger;
        //             self.ngIntroService.start(self.ngIntroService.intro._currentStep);
        //         } , 1000);
        //     }
        // });

        $rootScope.$on('can_inter', () => {
            if(this.scen) {
                _.delay(() => {
                    this.findSeries();
                } , 1000);
            }
        });
        this.complite = 0;
    }

    start(name) {
        if(!name || !this.scens[name]) {
            console.error('not intro scen ' + name);
        }
        else {

            this.scenName = name;
            this.scen = this.scens[name];
            this.findSeries(name);
        }
    }


    findSeries(name){
        if( !_.isNumber(this.currentSeriesNumber) ) {
            this.currentSeriesNumber = 0;
        };
        var d = document.getElementsByClassName('md-dialog-container');
        var isDialog = (d && d.length);

        var currentSeries = this.scen[this.currentSeriesNumber];
            this.currentSeriesNumber = 0;
        _.each(this.scen, function (v, k) {
            if(v.state && this.$state.current.name == v.state.name && this._isParams(v.state.params, this.$state.params) && ( ( !isDialog && !v.state.dialog ) || ( v.state.dialog && isDialog ) ) ){
                currentSeries = v;
                this.currentSeriesNumber = k;
            }
        }, this);
        this.setSeries(_.clone(currentSeries));
    }

    setSeries(series, name, num) {
        this.addComplite();
        this.ngIntroService.clear();
        // this.ngIntroService.setOption('showStepNumbers', false);
        this.ngIntroService.intro.setOption('showStepNumbers', false);
        this.ngIntroService.setOptions(series);
        this.initInter();
        this.ngIntroService.start();
    }

    _isParams(p1 = {}, p2 = {}) {
        var rez = true;
        _.each(p1, (v, k) => {
            if(p2[k] != v) rez = false;
        });
        return rez;
    }



    initInter() {
        this.ngIntroService.onBeforeChange(_.bind(this.BeforeChangeEvent, this));
        this.ngIntroService.onAfterChange(_.bind(this.AfterChangeEvent, this));
        this.ngIntroService.onChange(_.bind(this.ChangeEvent, this));
        this.ngIntroService.onComplete(_.bind(this.CompletedEvent, this));
        this.ngIntroService.onHintClick(_.bind(this.onHintClickEvent, this));
        this.ngIntroService.onExit(_.bind(this.ExitEvent, this));
    }

    resetSeries() {
        // debugger;
        this.scenName = null;
        this.currentSeriesNumber = null;
        this.scen = null;
        console.log('reset scen');
        this.introStep = null;
        // this.ngIntroService.clear();
    }

    onHintClickEvent () {
        debugger;
    }

    CompletedEvent (){
        console.log('[directive] completed Event');
        var step = this.getStepInfo();
        if(step) {
            this.stepAction(step);
        }
        // if( this.currentSeriesNumber > this.scen.length ){
        //     this.resetSeries();
        // }
        this.addComplite();
    }

    addComplite() {
        this.complite = this.complite > 0 ?  this.complite + 1 : 1;
    }

    ExitEvent(){
        if(this.complite){
            this.complite = this.complite - 1;
        }else{
            this.resetSeries();
        }
        console.log('[directive] exit Event')
    }

    ChangeEvent (){
        // debugger;
        console.log('[directive] change Event');
        this.introStep = this.getStepInfo();
        // debugger;
        // if( (this.currentSeriesNumber + 1) == this.scen.length && ( this.ngIntroService.intro._currentStep > this.ngIntroService.intro._introItems.length)){
        //         console.log('reset series by end');
        //         this.resetSeries();
        //     }
    }

    BeforeChangeEvent(){
        console.log('[directive] beforeChange Event');
        if(this.introStep) {
            var cStep = this.getStepInfo();
            var action = this.introStep.step < cStep.step ? 'next' : 'back';
            if(this.introStep[action]){
                this.stepAction(this.introStep[action]);
            }
        }
    }

    AfterChangeEvent(){
        console.log('[directive] after change Event');
        if( (this.currentSeriesNumber + 1) == this.scen.length && ( this.ngIntroService.intro._currentStep >= this.ngIntroService.intro._introItems.length)){
            console.log('reset series by end');
            this.resetSeries();
        }
    }

    getStepInfo(num) {
        num = num || this.ngIntroService.intro._currentStep;
        return this.ngIntroService.intro._introItems[num];
    }

    stepAction(step){
        if(step && step.action){
            var element = step.actionElement ? $(step.actionElement) : step.element;
            switch ( step.action ){
                case 'click':
                    element[step.action]();
                    break;
            }
        }
    }

}
