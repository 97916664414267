import {ControllerItem}  from '../../core/controller/item'

export class CatalogBaseItemNewController extends ControllerItem{
    constructor (mlmodel, MlmodelService) {
        super();
        var inject = angular.element(document.body).injector();
        this.validate = inject.get('validate');
        this.$rootScope = inject.get('$rootScope');
        this.$window = inject.get('$window');
        this.$state = inject.get('$state');
        this.$mdConstant = inject.get('$mdConstant');

        this.$rootScope.addButtonConfig = null;
        this.saving = false;

        this.data; //= mlmodel;
        this.Service // ;
        this.delayNameCheck;
        // this.stateName = '';
    }

    // $onInit(){
    //     // this.Keywords = _.isArray(this.data.Keywords) ? this.data.Keywords.join(',') : this.data.Keywords;
    // }

    canSave(form) {
        return !this.saving && form.$valid;
    }

    prepareSave(data = {}){
        // data.Keywords = this.Keywords ? this.Keywords.split(',') : [];
        return data;
    }

    save(form, event) {
        if(!form.$valid) return false;
        this.data = this.prepareSave(this.data);
        this.saving = true;
        this.savingError = false;
        this.data.$create()
            .then(
                (r) => {
                    this.$state.go('wsCatalog.list.item', {item: this.data.Name});
                },
                (err) => {
                    this.savingError = err;
                }
            )
            .finally(
                () => {
                    this.saving = false;
                }
            )
    }

    _save() {
        return this.data.$create();
    }

    cancel() {
        this.$window.history.back();
    }

    isNew() {
        return (this.data && this.data.Name) ? false : true;
    }
}
