import {ControllerItem}  from '../core/controller/item'
import {InstallFromChart, InstallFromChartTemplate} from './install/install_from_chart'
import {InstallChart, InstallChartTemplate} from './install/install_chart'
import {AuthRequestTemplateComponent, AuthRequestController} from '../auth/auth_request_component'


export class ChartsItemNewController {
    constructor (chart, $state, $window, ChartsService, $rootScope, $mdDialog, validate) {
        this.validate = validate;
        $rootScope.addButtonConfig = null;
        this.$state = $state;
        this.data = chart;
        this.$window = $window;
        this.ChartsService = ChartsService;
        this.delayNameCheck;
        this.saving = false;
        this.scanAction = false;
        this.$mdDialog = $mdDialog;
    }

    checkName (form) {
        debugger;
        if(!form.$valid){return false;}
        if(this.delayNameCheck) {
            clearTimeout(this.delayNameCheck);
            delete this.delayNameCheck;
        }
        if(this.data.Name == "") {
            form.Name.$setValidity("errorname", true);
            return;
        }
        var self = this;
        this.delayNameCheck = _.delay(function(){
            self.ChartsService.getResource({}).isavailable({wsId: self.$state.params.wsId, name: self.data.Name})
                .$promise.then(
                    function(resp) {
                        form.Name.$setValidity("errorname", true);
                    }, function(err) {
                        self.nameErrorMessage = err;
                        form.Name.$setValidity("errorname", false);
                    }
                );
        }, 1000);
    }

    canSave(form) {
        return !this.saving && this.data.RepositoryUrl && form.$valid
    }

    save (form, ev) {
        if( !this.canSave(form) ) { return false; }
        this.saving = true;
        this.savingError = false;
        var self = this;
        var promise = this.scanAction ? this.scan() : this._save();
        promise
            .then(
                function (r) {
                    if(_.isArray(r)){
                        self.$mdDialog.show({
                            locals: {list: r, dialog: self.$mdDialog},
                            controller: function(list , $scope, dialog) {
                                this.list = list;
                                $scope.dialog = dialog;
                                $scope.go = function (name) {
                                    this.dialog.hide(name);
                                };
                                $scope.cancel = function () {
                                    this.dialog.cancel();
                                };
                            },
                            template: `
                                <md-dialog aria-label="Mango (Fruit)">
                                    <md-toolbar class="md-raised md-primary" >
                                      <div class="md-toolbar-tools">
                                        <h2>Charts</h2>
                                        <span flex></span>
                                        <md-button class="md-icon-button" ng-click="cancel()">
                                          <md-icon md-svg-src="core:close" aria-label="Close dialog"></md-icon>
                                        </md-button>
                                      </div>
                                    </md-toolbar>
                                
                                    <md-dialog-content>
                                      <div class="md-dialog-content">
                                        <md-list>
                                            <md-list-item ng-repeat="item in $ctrl.list" class="secondary-button-padding">
                                                <img alt="{{ item.Chart.DisplayName }}" ng-src="{{ item.Chart.Picture }}" class="md-avatar" />
                                                <div class="md-list-item-text">
                                                    <h4>{{item.Chart.DisplayName}}</h4>
                                                    <p ng-if="item.Error" class="md-warn">{{item.Error}}</p>
                                                </div>
                                                <md-button ng-if="item.Error" class="md-secondary md-warn" ng-click="go(item.Chart.Name)" ng-disabled="true">Error</md-button>
                                                <md-button ng-if="item.Success" class="md-raised md-primary" ng-click="go(item.Chart.Name)" >Added</md-button>
                                            </md-list-item>
                                        </md-list>
                                       </div>
                                   </md-dialog-content>
                                   <md-dialog-actions layout="row">
                                      <span flex></span>
                                      <md-button ng-click="go()">
                                        to chart list
                                      </md-button>
                                    </md-dialog-actions>
                                </md-dialog>
                            `,
                            controllerAs : '$ctrl',
                            parent: angular.element(document.body),
                            targetEvent: ev,
                            clickOutsideToClose:true
                        })
                            .then(function(name) {
                                debugger;
                                if(!name) {
                                    self.$state.go('wsChartsList', self.$state.params);
                                } else {
                                    self.$state.go('wsChartItem', _.extend({}, self.$state.params, {chartName : name}));
                                }
                            }, function() {
                                // $scope.status = 'You cancelled the dialog.';
                            });

                    }else {

                        self.$state.go('wsChartItem', {chartName : r.Name});
                        // self.savingError = false;
                    }
                },
                function (err) {
                    self.savingError = err;
                }
            )
            .finally(
                function() {
                    // self.savingError = false;
                    self.saving = false;
                }
            );
    }

    _save() {
        return this.data.$create();
    }

    scan () {
        return this.ChartsService.getScanResource(this.$state.params).scan(this.data)
            .$promise;
    }
    cancel() {
        this.$window.history.back();
    }
}



export class ChartsItemController extends ControllerItem{
    constructor ($mdDialog, chart, $state, chartPageTabs, chartResource, /* chartVersions,*/ ChartsService, $scope, $translate,  CU) {
        super($mdDialog, $translate);
        this.CU = CU;
        this.chartPageTabs = chartPageTabs;
        this.$state = $state;
        this.data = chart;
        this.version = $state.params.chartVersion;
        this.resource = chartResource;

        var self = this;
        chartResource.chartVersions({chartName : chart.Name})
            .$promise
            .then(
                function (r) {
                    self.chartVersions = r
                }
            );
        this.ChartsService = ChartsService;
        this.$scope = $scope;

    }

    // $onInit () {
    //     // debugger;
    //     super.$onInit();
    //     // if(!this.data.Keywords.length) {
    //     //     this.data.Keywords = ['kub', 'test', 'key','word'];
    //     // }
    // }

    successDelete(r) {
        this.$state.go('wsChartsList');
    }

    errorDelete(r) {
        this.deleteError = r;
    }

    goTab (name) {
        this.$state.go('wsChartItem', {tab: name});
    }

  async download() {
    if (this.$scope.isDownloading) return;

    try {
      this.$scope.isDownloading = true;
      this.$scope.downloadError = null;
      let file = await fetch(this.data.DownloadURL);

      if (file.status !== 200) {
        const error = await file.json();
        throw {data: error, status: file.status};
      }

      file = await file.blob();

      const blob = window.URL.createObjectURL(file);

      const fileName = this.data.DownloadURL.split('/').pop().split('?').shift();

      const aEl = document.createElement('a');
      aEl.download = fileName;
      aEl.href = blob;

      aEl.click();

      this.$scope.isDownloading = false;
      this.$scope.$digest();
    } catch(error) {
      console.error(error);

      this.$scope.isDownloading = false;
      this.$scope.downloadError = error;
      this.$scope.$digest();
    }
  }

    star() {
        var self = this;
        var data = this.data;
        var action = data.Star ? "unstar" : "star";
        this.resource[action]({wsId: this.data.WorkspaceName, chartName: this.data.Name})
        // data[action]()
            .$promise
            .then(
                function (r) {
                    self.data.Stars = r.Stars;
                    self.data.Star = r.Star;
                },
                function (err) {
                    // debugger;
                }
            )
    }

     install(ev) {
        var then = this;
        // this.$scope.chart= this.data;
        this.installError = null;
        this.$mdDialog.show({
            locals: {
                dialog: then.$mdDialog,
                chart : this.data,
                clusters: null,
                workspace_name: null
                // ws: null
            },
            controller: this.CU ? InstallChart : AuthRequestController,
            controllerAs: '$ctrl',
            template: this.CU ? InstallChartTemplate : AuthRequestTemplateComponent,
            parent: angular.element(document.querySelector('#mainBody')), // angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: false,
            escapeToClose: false
        }).then(
            function(data){
                // then.$state.go('wsProject.tab', {
                //     // wsId: data.data.workspace_name,
                //     // projectName: data.data.project_name,
                //     // envName: data.data.environment,
                //     tab: "tasks"
                // });
                // then.instaling = true;
                // debugger
                //
            },
            function () {
                // debugger;
            }
        );

    }

    isMlapp(){
        return this.ChartsService.isMlapp(this.chart);
    }
}
