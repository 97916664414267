import {ProjectsStoragesComponent} from './projects_storages_component'
import {ProjectStoragesService} from './projects_storages_service'
import {ProjectClusterStorageComponent} from './project_cluster_storages_component'
import {ClusterStorageComponent} from './cluster_storage_component'
import {ClusterStoragesComponent} from './cluster_storages_component'

export default angular
    .module("projects_storages", [])
    .service('ProjectStoragesService', ProjectStoragesService)
    .component('cmpProjectsStorages', ProjectsStoragesComponent)
    .component('cmpProjectClusterStorage', ProjectClusterStorageComponent)
    .component('cmpClusterStorage', ClusterStorageComponent)
    .component('cmpClusterStorages', ClusterStoragesComponent)
;