
import {wsBaseStateConfig} from './workspace/workspace_stateconfig'
import {settngsBaseStateConfig, settingPageConfig} from './settings/settings_stateconfig'

export default function ($stateProvider, $urlRouterProvider, $urlServiceProvider, $locationProvider) {

    $urlRouterProvider.when('', '/');
    $urlServiceProvider.rules.otherwise((matchValue, urlParts, router) => {
        router.stateService.go('404', null, { location: false });
    });

    $stateProvider
        .state('gallery', {
            url : '/gallery',
            controller: function () {
                window.location.reload();
            }
        })

        .state('404', {
            url: '/404',
            views : {
                "layout-page-content@" : {
                    template : `
                        <div flex layout='column' layout-align="center center" class="login-form-wrapper">
                            <h3>Page not found</h3>
                        </div>
                    `
                }
            }
        })

        .state('403', {
            url: '/403',
            views : {
                "layout-page-content@" : {
                    template : `
                        <div flex layout='column' layout-align="center center" class="login-form-wrapper">
                            <h3>Error 403</h3>
                        </div>
                    `
                }
            }
        })

        .state('500', {
            url: '/500',
            views : {
                "layout-page-content@" : {
                    template : `
                        <div flex layout='column' layout-align="center center" class="login-form-wrapper">
                            <h3>Error 500</h3>
                        </div>
                    `,
                    controller: function ($scope, $state, $stateParams) {
                        $scope.state = $state;
                    }
                }
            }
        })


        .state('cu', {
            abstract: true,
            resolve : {
                CU : function (CurrentUser, $q) {
                    var defer = $q.defer();
                    if(CurrentUser) {
                        defer.resolve(CurrentUser);
                    } else {
                        defer.resolve(false)
                    }
                    return defer.promise;
                }
            }
        })

        .state('content', {
            parent: 'cu',
            abstract : true,
            data : {
                idState: 'baseContent'
            },
            views : {
                '@' : {
                    template : `
                        <div class="contentPage" flex layout="column" layout-align="start start" ui-view="" id="{{pageConfig.contentId()}}"></div> 
                        <cmp-add-button class="fix_bottom_right" style="z-index: 2"></cmp-add-button>
                    `,
                    controller : function (pageConfig, $scope, CU, $state) {
                        $scope.pageConfig = pageConfig;
                    }
                }
            }
        })

        .state('home', {
            url : '/',
            onEnter: (CurrentUser, UserConfigService, $state) => {
                CurrentUser.then(
                    (cu) => {
                        if(cu) {
                            let wsId,  ws =  UserConfigService.getConfig('currentWS');

                            if( ws )
                                wsId = ws.Name;
                            else
                                wsId = (cu.Workspace);
                            $state.go('workspace',  {wsId : wsId})
                        }
                    }
                );
            },

            views : {
                "home" : {
                    controllerAs: '$ctrl',
                    template: `
                            <md-content  flex layout='column' 
                                        layout-align="center center" 
                                        class="login-form-wrapper"
                                        ng-if="$ctrl.userComplite && !$ctrl.currentUser" >
                                    <div class="home-card-welcom" layout="column" layout-align="space-between center" >
                                        <home-card-welcom-content flex>
                                          <h2>
                                          <cmp-logo></cmp-logo></h2>
                                          <div class="content"><span class="select">Machine Learning</span> Application Platform</div>
                                        </home-card-welcom-content>
                                        <home-card-welcom-actions >
                                          <cmp-auth-form></cmp-auth-form>
                                        </home-card-welcom-actions>
                                        <!--<div>-->
                                            <!--<a target="_blank" href="{{$window.documentLink}}/en/latest/" class="blockMenuItem">-->
                                            <!--<md-icon md-svg-src="core:help-circle"></md-icon>-->
                                            <!--<span class="label">Documentation</span>-->
                                            <!--</a>-->
                                        </div>
                                    </div>
                            </md-content>
                        `,

                    controller: function ( $transition$ ) {
                        let CurrentUser = $transition$.injector().get('CurrentUser');
                        this.currentUser;
                        this.userComplite;
                        CurrentUser.then(
                            (r) => {
                                this.currentUser = r;
                            },
                            () => {
                                this.currentUser = false;
                            }
                        )
                        .finally(() => {
                            this.userComplite = true;
                        })
                    },
                }
            }
        })
        // .state('firstPage', {
        //     url : '?w',
        //     // resolve:{
        //     //     Worckspace : function ($stateParam) {
        //     //         return {};
        //     //     }
        //     // },
        //     views : {
        //         "" : {
        //             template: `fp`,
        //             controller: function () {
        //                 debugger
        //             },
        //             controllerAs: '$ctrl'
        //         }
        //     }
        // })
        .state('authError', {
            url: '/autherror',
            resolve : {
                // authError: function(AuthService) {
                //     return AuthService.oautherror.get().$promise
                // }
            },
            views : {
                "home": {
                    template: `
                        <div flex layout='column' 
                                        layout-align="center center" 
                                        class="login-form-wrapper"
                                        >
                                    <div class="home-card-welcom" layout="column" layout-align="space-between center" >
                                        <home-card-welcom-content flex>
                                          <cmp-auth-error auth-error='$ctrl.authError' style="max-width: 400px; display: flex"></cmp-auth-error>
                                          <md-button ui-sref="home">home</md-button>
                                        </home-card-welcom-actions>
                                    </div>
                            </div>
`,
                    controller: function($state, $location) {
                        this.authError = $location.search().with_error;
                        if(this.authError == undefined) {
                            $state.go('home')
                        }
                    },
                    controllerAs: "$ctrl"
                }
            }
        })

        .state('authChoose', {
            url: '/authchoose',
            views : {
                "home": {
                    template: `
                        <div flex layout='column' 
                                layout-align="center center" 
                                class="login-form-wrapper"
                                >
                                <div class="home-card-welcom" layout="column" layout-align="space-between center" >
                                    <home-card-welcom-content flex>
                                      <cmp-auth-choose ></cmp-auth-choose>
                                    </home-card-welcom-actions>
                                </div>
                        </div>`

                }
            }
        })

        .state('authComplete', {
            url: '/authcomplete',
            views : {
                "home": {
                    template: `
                        <div flex layout='column' 
                                layout-align="center center" 
                                class="login-form-wrapper"
                                >
                                <div class="home-card-welcom" layout="column" layout-align="space-between center" >
                                    <home-card-welcom-content flex>
                                      <cmp-auth-complete ></cmp-auth-complete>
                                    </home-card-welcom-actions>
                                </div>
                        </div>`

                }
            }
        })


        .state('settings', settngsBaseStateConfig)

        .state('wsBase', wsBaseStateConfig);

    $locationProvider.html5Mode({
        enabled: true,
        requireBase: false
    });
}
