export function AppFilereaderDec ($q, $mdDialog) {
    var slice = Array.prototype.slice;

    return {
        restrict: 'A',
        template: `
                <spam class="label">{{label}}</spam>
                <div layout="row" layout-align="start">
                    <div flex layout="row" layout-align="start" layout-wrap >
                        <div class="iconImg" ng-repeat="item in values" ng-class="{current: item.file == current}" ng-click="select($index)" ng-switch="itemType">
                            <div ng-switch-when="image"  style=' width:100%; height:100%; background: url("{{item.file}}") center center no-repeat; background-size: contain;")'></div>
                            <div ng-switch-default  style='    
                                width: 100%;
                                height: 100%;
                                text-align: center;
                                vertical-align: middle;
                                display: flex;
                                align-items: center;'
                            >{{item.name}}</div>
                        </div>
                        <label class="iconImg" class="md-raised">choose file
                            <input class="file" type="file" accept="{{acceptFile}}" >
                        </label>
                        <label class="iconImg" class="md-raised">
                            <span><md-icon md-svg-icon="core:photo"></md-icon><br/>snapshot</span>
                            <input class="file" ng-click="openModal($event)" >
                        </label>
                        <label class="iconImg" class="md-raised" ng-if="type == 'image_webrtc'" style="color: rgb(255,255,255); background-color: rgb(33,150,243);" >
                            <span><md-icon md-svg-icon="core:liveVideo"></md-icon><br/>live video</span>
                            <button class="file" ng-click="startStream()" style="display:none">
                        </label>
                    </div>
                </div>
                `,
        require: '?ngModel',
        scope: {
            value: '=value',
            label: '=label',
            type: '=type',
            snapshot: '=snapshot',
            action: '&'
        },
        link: function(scope, element, attrs, ngModel) {

            if (!ngModel) return;
            scope.values = [];
            _.each(scope.value, (item) => {
                scope.values.push({file: item, type: "url"});
            });

            scope.startStream = () => {
                scope.action({ $event: {type: 'start_stream', data: {}}});
            };

            switch(scope.type) {
                case 'image':
                case 'image_webrtc':
                    scope.acceptFile = 'image/x-png,image/gif,image/jpeg';
                    scope.itemType = 'image';
                    break;
                case 'audio':
                    scope.acceptFile = 'audio/mp3';
                    scope.itemType = 'audio';
                    break;
                default:
                    scope.acceptFile = '';
                    scope.itemType = scope.type;
            }


            this.scope = scope;

            ngModel.$render = function() {};
            var fileElement = element.find('input');

            scope.select = function(index) {
                // debugger
                var img = scope.values[index];
                if(img.type == 'file') {
                    current(img.file, img.file);
                } else {
                    convertImgToBase64URL(img.file, function (data) {
                            current(img.file,  data);
                        }
                    )
                }
            };

            if(scope.values.length) scope.select(0);

            function current(url, data) {
                ngModel.$setViewValue(data);
                scope.current = url;
            }

            function convertImgToBase64URL(url, callback, outputFormat){
                var img = new Image();
                img.crossOrigin = 'Anonymous';
                img.onload = function(){
                    var canvas = document.createElement('CANVAS'),
                        ctx = canvas.getContext('2d'), dataURL;
                    canvas.height = img.height;
                    canvas.width = img.width;
                    ctx.drawImage(img, 0, 0);
                    dataURL = canvas.toDataURL(outputFormat);
                    callback(dataURL);
                    canvas = null;
                };
                img.src = '/external/' + url.replace(/:\/\//, '/');
            }

            fileElement.bind('change', (e) => {
                var element = e.target;
                if(!element.files.length) return false;

                $q.all(slice.call(element.files, 0).map(readFile))
                    .then(function(values) {

                        var v;
                        if (element.multiple)    v = values.value;
                        else                     v = values.length ? values[0] : null;
                        current(v.value, v.value);
                        scope.values.push({file: v.value, type: 'file', name: v.file.name})
                    });

                    function readFile(file) {
                        var deferred = $q.defer();

                        var reader = new FileReader();
                        reader.onload = function(e) {
                            deferred.resolve({value: e.target.result, file: file});
                        };
                        reader.onerror = function(e) {
                            deferred.reject(e);
                        };
                        reader.readAsDataURL(file);

                        return deferred.promise;
                    }
                }
            );


            scope.openModal = (ev) => {
                $mdDialog.show({
                    controller: getSnapashot,
                    template:  `
                        <md-dialog class="snapshotContent" layout="column">
                            <md-content flex layout-align="center">
                                <video id="snapshotvideo" ></video>
                            </md-content>
                            <md-dialog-action layout="row" layout-align="start center">
                                <div flex></div>
                                <md-button ng-click="continue()">cancel</md-button>
                                <md-button ng-click="takeSnapshot()" class="md-raised md-primary">snapshot</md-button>
                            </md-dialog-action>
                        </md-dialog>
                    `,
                    parent: angular.element(document.body),
                    targetEvent: ev,
                    clickOutsideToClose:false,
                })
                .then(
                    (img) => {
                        if(img) {
                            scope.values.push({file: img, type: 'snapshot', name: Date()});
                            current('snapshot',  img);
                        }

                    },
                    function() {}
                )
                ;
            }
        }
    };
}





function getSnapashot ($scope, $mdDialog) {
    $scope.stream = null;

    $scope.videoEl = null;

    _.delay(() => {
        $scope.video();
    }, 300);
    $scope.video = () => {
        $scope.videoEl = document.querySelector('#snapshotvideo');
        var constraints = {
            // audio: true,
            video: true //{ width: 1280, height: 720 }
        };
        $scope.stream = navigator.mediaDevices.getUserMedia(constraints)
            .then(function (mediaStream) {
                $scope.stream = mediaStream;
                $scope.videoEl.srcObject = mediaStream;
                $scope.videoEl.onloadedmetadata = function (e) {
                    $scope.videoEl.play();
                };
            })
            .catch(function (err) {
                console.log(err.name + ": " + err.message);
            }); // always check for errors at the end.
    };

    $scope.takeSnapshot = () => {

        var hidden_canvas = document.createElement('CANVAS'),
            // video = document.querySelector('video.camera_stream'),
            // image = document.querySelector('img.photo'),

            // Получаем размер видео элемента.
            width = $scope.videoEl.videoWidth,
            height = $scope.videoEl.videoHeight,

            // Объект для работы с canvas.
            context = hidden_canvas.getContext('2d');


        // Установка размеров canvas идентичных с video.
        hidden_canvas.width = width;
        hidden_canvas.height = height;

        // Отрисовка текущего кадра с video в canvas.
        context.drawImage($scope.videoEl, 0, 0, width, height);

        // Преобразование кадра в изображение dataURL.
        var imageDataURL = hidden_canvas.toDataURL('image/png');
        $scope.continue(imageDataURL);
        // Помещение изображения в элемент img.
        // image.setAttribute('src', imageDataURL);

    };

    $scope.continue = (img) => {
        $scope.stream.getTracks().forEach(function (track) {
            track.stop();
        });
        $mdDialog.hide(img);
    }

};
