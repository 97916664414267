import {d3Metrics, d3Metric, d3Serie, controller} from '../../applications/metrics2/applications_metrics_component'


class ApplicationJobMetrics extends controller {

    constructor(ServingService, servingMetricsList, $state, $localStorage, $mdSidenav, $filter, $scope, $rootScope, $transitions) {
        super(...arguments);
        this._inited = false;
        this.resource = ServingService.getResource($state.params);
        this.ChartOptions = {legend: {display: false}};
    }

    $onInit() {
        var self = this;
        this._init(this.$state.params);
        this.$transitions.onStart(
            { entering: 'wsServing.tab' },
            (transition, state) => {
                let params =  transition.params();
                this._init(params);
            });
    }


    _init(params) {
        if(params.tab == 'metrics' && !this._inited) {
            this.getTaskGraph();
            this._inited = true;
        }

    }

    changeMetricsList(update) {
        var newlist = this.getMetricsList();
        var oldList = this.$localStorage.applicationMetricsList || [];
        this.$localStorage.applicationMetricsList = newlist;
        if(oldList.length < newlist.length) {
            // this.updateMetrics();
            this.getTaskGraph()
        }
    }

    setTimeInterval(interval){
        this.timeInterval = interval;
        // this.updateMetrics();
        this.getTaskGraph();

    }

    getTaskGraph(item, name = '') {
        var respData = {
            Name: this.data.Name,
            // build: item.build,
            // interval: this.interval,
            metrics: this.getMetricsList().join(','),
            details: this.details,
            // application: this.appController.data.Name
        };
        _.extend(respData, this.requestTimeInterval());
        var elements; // = [this.data.Name];
        this.loading = true;
        this.apiError= false;
        var self = this;
        // debugger;
        // this.mResource.taskGraph(respData).$promise


        this.resource.servingGraph(respData).$promise
            .then(
                (r) => {
                    let d3M = {};
// debugger
                    _.each(r.metrics, (m) => {
                        _.each(m.metrics, (mm) => {
                            if( !d3M[mm.name] ) { d3M[mm.name] = { metrics: {}} }
                            if( !d3M[mm.name].metrics[m.component_name] ) { d3M[mm.name].metrics[m.component_name] = {component_name: m.component_name, component_name_paremt : name}; }
                            d3M[mm.name].metrics[m.component_name].values = self.mapData(mm.values);
                            d3M[mm.name].unit = mm.unit;
                        })
                    });
                    self.d3Metrics.addMetris(d3M, elements, self.getMetricsList()  );
                    console.info(d3M)

                },
                (err) => {
                    this.apiError=err;
                    this.$scope.$emit('api_error', err);
                }
            )
            .finally(()=>{
                this.loading = false;
            })
    }

    $onDestroy() {
        // this.listenerState();
    }

    mapData(list){
        return _.map(list, (v, k) => {
            return {x: v.ts * 1000, y: v.value}
        })
    }

    nvd3Options(title) {
        return {
            legend: {display: false},
            "chart": {
                "type": "lineChart",
                // "height": 450,
                "margin": {
                    "top": 20,
                    "right": 40,
                    "bottom": 40,
                    "left": 55
                },
                "useInteractiveGuideline": true,
                "dispatch": {},
                "xAxis": {
                    tickFormat: function(d) {
                        // if(!d) d = new Date();
                        // return d3.time.format('%x')(new Date(d)) + '<br/>' +
                        return d3.time.format('%X')(new Date(d))

                    },
                },
                "yAxis": {
                    "axisLabelDistance": -10,
                    tickFormat: function (d) {
                        return d3.format(',.1f')(d);
                    }
                }
            }
        }
    }

}

export const ServingMetricsComponent = {
    bindings: {
        appController: '<',
        task: '<',
        active:'<',
        data: '<'
    },
    controller: ApplicationJobMetrics,
    template: ($templateCache) => {
        return $templateCache.get('kuberlab/serving/metrics/serving_metrics.html')
    }

};
