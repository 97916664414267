
import {controller} from './application_job_item'

class controllerFull extends controller{
    constructor(projectJobParamSeparator, $scope, WorkspacePermissions){
        super(...arguments)
        this.watchWS('applicationTask', this.ws_applicationTask);
        this.watchWS('applicationTaskDelete', this.ws_applicationTaskDelete);
    }

    close() {
        this.listController.job = null;
        var state = this.listController.$state;
        state.go(state.current.name, {tabItem: null});
    }

    ws_applicationTask(event, data) {

        if(this.listController.ApplicationsService.isWsApplication(data) && this.data.name == data.name) {
            this.data = _.extend(this.data, {status: data.status, exec_info: data.exec_info, stop_time: data.stop_time}) ;
            this.$rootScope.$digest();
        }
    }

    ws_applicationTaskDelete (event, data) {
        if(this.listController.ApplicationsService.isWsApplication(data) && this.data.name == data.name) {
            this.listController.job = null;
            this.listController.$state.go(this.listController.$state.current.name, {tabItem: null});
        }
    }
}

export const ApplicationJobItemFullComponent = {
    bindings: {
        data: '=',
        appController: '=',
        listController: '<'
    },
    controller: controllerFull,
    template: function ($templateCache) {
        return $templateCache.get('kuberlab/applications/jobs/application_job_item_full.html');
    }
};
