export default class Utils {
  constructor($window, $mdDialog, $translate) {
    this.$window = $window;
    this.$mdDialog = $mdDialog;
    this.$translate = $translate;
  }

  openWindow(url) {
    this.$window.open(url);
  }

  /**
   * Show Confirm Dialog.
   *
   * @param {object} params
   * @param {string} params.title Dialog title
   * @param {string} params.text Dialog text
   * @param {string} [params.okText=COMMON_OK] OK button text
   * @param {string} [params.cancelText=COMMON_CANCEL] Cancel button text
   */
  async confirmDialog(params) {
    let tr = await this.$translate([
      params.title,
      params.text,
      params.okText,
      params.cancelText,
      'COMMON_OK',
      'COMMON_CANCEL'
    ]);

    let title = tr[params.title];
    let text = tr[params.text];
    let okText = params.okText ? tr[params.okText] : tr.COMMON_OK;
    let cancelText = params.cancelText ? tr[params.cancelText] : tr.COMMON_CANCEL;

    return new Promise((resolve) => {
      let confirm = this.$mdDialog.confirm()
        .title(title)
        .textContent(text)
        .ariaLabel(title)
        .ok(okText)
        .cancel(cancelText);

      this.$mdDialog.show(confirm).then(resolve, () => {
      });
    });
  }
}
