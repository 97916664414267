/**
 * Created by ilja on 16.11.16.
 */

import {AuthService} from './auth_service';
import {AuthServiceList} from './auth_service_list_component';
import {AuthFormComponent} from './auth_form_component';
import {AuthLoginComponent} from './auth_login';
import AuthStateconfig from './auth_stateconfig';
import {AuthCardHeaderComponent} from './card/auth_card_header';
import {AuthErrorComponent} from './auth_error_component';
import {AuthRegisterEmailComponent} from './auth_register_email'
import {AuthChooseComponent} from './choose/auth_choose_component'
import {AuthCompleteComponent} from './complete/auth_complete_component'

export default angular
    .module('auth', [
        'vcRecaptcha'
            // 'ngMaterial',
            // 'ngResource'
        ]
    )
    .config(AuthStateconfig)
    .service("AuthService", AuthService)
    .component('authServiceList', AuthServiceList)
    .component('cmpAuthForm', AuthFormComponent)
    .component('cmpAuthRegisterEmail', AuthRegisterEmailComponent)
    .component('cmpAuthLogin', AuthLoginComponent)
    .component('cmpAuthCardHeader', AuthCardHeaderComponent)
    .component('cmpAuthError', AuthErrorComponent)
    .component('cmpAuthChoose', AuthChooseComponent)
    .component('cmpAuthComplete', AuthCompleteComponent)
