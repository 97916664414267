import {ControllerItem}  from '../../core/controller/item'
// import {AuthRequestTemplateComponent, AuthRequestController} from '../auth/auth_request_component'

export class CatalogBaseItemController extends ControllerItem{
    constructor () {
    // constructor (mlmodel, $state, mlmodelPageTabs, mlmodelResource, MlmodelService, $scope) {
        super();
        var inject = angular.element(document.body).injector();
        this.ErrorService = inject.get('ErrorService');
        this.UserService = inject.get('UserService');
        this.$state = inject.get('$state');
        this.$mdConstant = inject.get('$mdConstant');
        this.stateName = this.$state.current.name;

        this.data;
        this.service;
        this.resource;
        this.mlmodelPageTabs;

        this.$scope;
        this.permissions; // = permissions
        this.canManage; //this.permissions.can('mlapp.manage');
        this.stateParentName = "";
        this.stateCatalog = "";
        this.versions = [];
        this.readmeUrl = '';
        this.pageTabs = []

    }

    $onInit () {
        super.$onInit();
        this.pic = this.data.Picture ? this.data.Picture : `/${uiRevCommit}/img/${this.data.Interface}.png`;
        if( (this.data.Interface == 'mlmodel' || this.data.Interface == 'dataset') && this.$scope){
            this.$scope.$watch(
                () => { return this.UserService.ws; },
                (c) => {
                    if(c) {
                        this.listFork();
                    }
                },
                true
            )

        }
        this.itemState = this.itemState ? this.itemState : '.item';
        // debugger;
        this.link =  this.itemState + '({wsId: $ctrl.data.WorkspaceName, Interface:$ctrl.data.Interface, item : $ctrl.data.Name})'
    }

    // getVersions(){
    //     this.resource.versions({name: this.data.Name})
    //         .$promise
    //         .then(
    //             (r) => {
    //                 this.versions = r;
    //             },
    //             (err) => {
    //                 debugger;
    //             }
    //         )
    // }

    successDelete(r) {
        // debugger
        this.$state.go("^");
        // this.$state.go(this.stateParentName);
    }

    // errorDelete(r) {
    //     this.deleteError = r;
    // }

    goTab (name) {
        this.$state.go(this.stateName , {tab: name});
    }

    async download() {
        if (this.$scope.isDownloading) return;

        try {
            this.DownloadURL = this.data.DownloadURL;
            this.$scope.isDownloading = true;
            this.$scope.downloadError = null;
            let file = await fetch(this.DownloadURL);

            if (file.status !== 200) {
                const error = await file.json();
                throw {data: error, status: file.status};
            }

            file = await file.blob();

            const blob = window.URL.createObjectURL(file);

            const fileName = this.DownloadURL.split('/').pop().split('?').shift();
            const aEl = document.createElement('a');
            aEl.download = fileName;
            aEl.href = blob;

            aEl.click();

            this.$scope.isDownloading = false;
            this.$scope.$digest();
        } catch(error) {
            console.error(error);

            this.$scope.isDownloading = false;
            this.$scope.downloadError = {data: {Error: error}};
            this.$scope.$digest();
        }
    }

    uploadFile (form, file, action, paramName, lfApi) {
        if(!form.$valid) return false;
        var self = this;
        var $scope = this;
        this.loadingError[paramName] = false;
        this.isLoading[paramName] = true;
        this.loadingProgres = 0;
        var dataSendFile = {};
        dataSendFile[paramName] = file[0].lfFile;
        this.requestUploadFile = this.Service.getResource(
            this.$state.params,
            true,
            (e) => {
                if (e.lengthComputable) {
                    this.loadingProgres = ~~((e.loaded / e.total) * 100);
                }
            }
        )
            [action](
                {name: this.data.Name},
                dataSendFile
            );
        this.requestUploadFile
            .$promise
            .then(
                function (r) {
                    self.data = r;
                    // self.addFile = false;
                    file = false;
                    self.isLoading[paramName] = false;
                    lfApi.removeAll();
                },
                function (err) {
                    self.loadingError = err;
                    self.isLoading[paramName] = false;
                }
            );
    }

    publish() {
        var action = this.data.Published ? '$unpublish' : '$publish';
        this.data[action]()
            .then(
                (r) => {
                    // debugger;
                },
                (err) => {
                    this.ErrorService.error(err);
                }
            )
    }

    global() {
        var action = this.data.Global ? '$unglobal' : '$global';
        this.data[action]()
            .then(
                (r) => {
                    // debugger;
                },
                (err) => {
                    this.ErrorService.error(err);
                }
            )
    }

    canSave(form) {
        return !this.saving && form.$valid;
    }

    isNew() {
        return (this.data && this.data.Name) ? false : true;
    }

    saveSuccess(r) {
        this.isNewElement = false;
        this.actionView();
        // this.listController.changeList(this.data);
    }

    listFork() {
        // if(!this.forkList || !this.forkList.length){
            let forkList = [];
            let ws = this.$state.params.wsId;
            _.each(this.UserService.ws, (i) => {
                forkList.push({ws: i, can: i.Can.indexOf(this.data.Interface + '.manage') != -1, current: i.Name == ws });
            });
            this.forkList = forkList;
        // }
        return this.forkList;
    }

    fork(item){
        this.forking = true;
        var req;
        var data = {Name: this.data.Name, toWsId: item.ws.Name};
        if(this.forkName) {
            data.newName = this.forkName;
        }

        this.resource.fork(data)
            .$promise
            .then(
                (r) => {
                    this.$state.go(this.stateName , _.extend({}, this.$state.params, {wsId: r.WorkspaceName, item: r.Name} ));
                    this.errorForkName = null;
                },
                (err) => {
                    if(err.status == 409) {
                        this.errorForkName = err;
                        this.forkItem = item;
                    }else {
                        this.ErrorService.error(err);
                        this.errorForkName = null;
                    }
                }
            )
            .finally(() => {
                this.forking = false;
                this.forkName = null;
            })
    }

    forkWithName (form) {
        if(!form.$valid) return false;
        this.fork(this.forkItem);

    }

    cancelForkName() {
        debugger;
        this.errorForkName = null;
        this.forkName = null;
    }
}
