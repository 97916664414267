export class DynamicItems {
    constructor(resource) {
        this.resource = resource;
        debugger;
    }


    getItemAtIndex(index) {
        debugger;
        if (this.pages[index]) {
            return this.pages[index];
        } else {
            // This is an asynchronous action and does not return any value.
            this.loadPage(index);
        }
    }


    loadPage (index) {
        var self = this;
        debugger;
        var Data = [];

        // Trigger a new digest by using $timeout
        $timeout(function() {
            self.pages[index] = Data;
        });
    }


}