/**
 * Created by ilja on 17.11.16.
 */

class LayoutTitleOwnerController{
    constructor (AuthService, OwnerService, DashboardService) {
        this.DashboardService = DashboardService;

        // this.dc;
        this.whoami;
// debugger
        this.Owners = [];
        // this.OwnersService = OwnerService;
        this.AuthService = AuthService;
        var self = this;
        // this._owners = this.OwnersService.Owners()
        //     .then(
        //         function (r) {
        //             self.Owners = r;
        //         },
        //         function (r) {
        //             self.Owners = [];
        //         }
        //     );
    }
}

export const LayoutTitleOwnerComponent = {
    bindings: {
        // dc: '<',
        whoami: '<'
    },
    template: function ($templateCache) {
        return $templateCache.get('kuberlab/layout/title/layout_title_owner_component.html');
    },
    controller: LayoutTitleOwnerController
};