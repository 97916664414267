class controller {
    constructor(UserService, $window, CurrentUser, validate, KuberlabService, RestoreService, $scope){
        this.validate = validate;
        this.resource = UserService.getResource();
        this.$window = $window;
        CurrentUser
            .then((r) => {
                this.HasPassword = r.HasPassword;
                this.currentUser = r;
            });
        this.KuberlabService = KuberlabService ;
        this.RestoreService = RestoreService;
        this.$scope = $scope;
    }

    submit(form){
        if( !form.$valid ) return;
        this.error = null;
        this.resource.password(this.data)
            .$promise
            .then(
                (r) => {
                    if(this.setPassword){
                        this.setPassword = false;
                    }else {
                        this.$window.location = '/'
                    }
                },
                (err) => {
                    this.error = err;
                }
            )
    }

    initCaptcha() {
        const $scope = this.$scope;
        $scope.response = null;
        $scope.widgetId = null;
        $scope.setResponse = (response) => {
            console.info('Response available', response);
            $scope.response = response;
        };

        $scope.setWidgetId = (widgetId) => {
            console.info('Created widget ID: %s', widgetId);
            $scope.widgetId = widgetId;
        };

        $scope.cbExpiration = () => {
            console.info('Captcha expired. Resetting response object');
            this.vcRecaptchaService.reload($scope.widgetId);
            $scope.response = null;
        };
    }

    removePassForm(cancel) {
        if(cancel){
            this.$scope.removePassForm = false;
        } else {

            this.KuberlabService.settings().then((settings) => {
                    this.settings = settings;
                    this.initCaptcha();
                    this.$scope.removePassForm = true;
                }
            );
        }
    }

    resetPass(form) {

        if (!form.$valid) return;
        this.errorReset = null;
        this.resetComplete = false;
        var dataReset = {
            'g-recaptcha-response' : this.$scope.response,
            'Email' : this.currentUser.Email
        };
        this.RestoreService.getResource().passwordreset(dataReset).$promise.then(() => {
                this.resetComplete = true;
            }, (err) => {
                this.errorReset = err;
                this.vcRecaptchaService.reload(this.$scope.widgetId);
            }
        );
    }
}


export const UserPassword = {
    bindings: {
        oldPassword: '<',
        setPassword: '='
    },

    controller: controller,
    template: `
        <md-card >
          <md-card-title>
            <md-card-title-text><span class="md-headline" translate >USER_PASS_CHANGE</span></md-card-title-text>
          </md-card-title>
          <md-card-content layout="column">
                <form name="pass" ng-submit="$ctrl.submit(pass)" layout="column" layout-align="start" ng-if="!removePassForm">
                    <md-input-container ng-if="$ctrl.HasPassword">
                        <label translate>USER_PASS_CURRENT_PASS</label>
                        <input type="password" ng-model="$ctrl.data.OldPassword" name="oldPassword" required>
                        <div ng-messages="pass.oldPassword.$error" ng-messages-multiple="ng-messages-multiple" class="error-messages slide-right">
                            <div ng-message="required" class="message slide-left" translate>FORM_ERROR_REQUIRED</div>
                        </div>
                    </md-input-container>
                    <md-input-container>
                        <label translate >USER_PASS_NEW</label>
                        <input 
                            type="password" 
                            name="password" 
                            ng-model="$ctrl.data.NewPassword" 
                            required
                            ng-pattern="$ctrl.validate.passwordPattern"
                            >
                        <div ng-messages="pass.password.$error" ng-messages-multiple="ng-messages-multiple" class="error-messages slide-right">
                            <div ng-message="required" class="message slide-left" translate>FORM_ERROR_REQUIRED</div>
                            <div ng-message="pattern" translate>FORM_ERROR_PASS_PATTERN</div>
                        </div>
                    </md-input-container>
                    <md-input-container>
                        <label translate >USER_PASS_NEW_CONFIRM</label>
                        <input 
                            match-password="password" 
                            name="confirmPassword" 
                            ng-model="password.confirm"
                            type="password" 
                            required>
                            <div ng-messages="pass.confirmPassword.$error" ng-messages-multiple="ng-messages-multiple" class="error-messages slide-right">
                                <div ng-message="required" class="message slide-left" translate>FORM_ERROR_REQUIRED</div>
                                <div ng-message="passwordMatch" class="message slide-left" translate="">FORM_ERROR_PASSWORD_MATCH</div>
                            </div>
                    </md-input-container>
                    <div class="layout-row layout-align-end-center">
                        <div class="flex">
                            <cmp-error-toast ng-if="$ctrl.error" error="$ctrl.error"></cmp-error-toast>
                        
                        </div>
                        <md-button ng-if="$ctrl.error" ng-click="$ctrl.removePassForm()"> reset password </md-button>
                        <md-button ng-if="$ctrl.setPassword" ng-click="$ctrl.setPassword = false">{{"COMMON_CANCEL" | translate}}</md-button>
                        <md-button type="submit" class="md-raised md-primary">{{"COMMON_SAVE" | translate}}</md-button>
                    </div>
                </form>
                
                <form name="reset" ng-submit="$ctrl.resetPass(reset)" ng-if="removePassForm" layout="column" >
                    <h3 translate>USER_PASS_RESET</h3>
                    <div ng-if="!$ctrl.resetComplete" layout="row" layout-align="start center" >
                        <div flex ng-if="$ctrl.settings.recaptcha_key"
                             vc-recaptcha
                             theme="'light'"
                             key="$ctrl.settings.recaptcha_key"
                             on-create="setWidgetId(widgetId)"
                             on-success="setResponse(response)"
                             on-expire="cbExpiration()"
                        ></div>
                        <md-button ng-click="$ctrl.removePassForm(true)">{{"COMMON_CANCEL" | translate}}</md-button>
                        <md-button type="submit">{{"COMMON_RESET" | translate}}</md-button>
                     </div>
                     <cmp-error-toast ng-if="$ctrl.errorReset" error="$ctrl.errorReset"></cmp-error-toast>
                     <div ng-if="$ctrl.resetComplete" layout="row" layout-align="start center">
                        <div flex translate>USER_PASS_REMOVE_SUCCESS</div>
                        <md-button ng-if="$ctrl.setPassword" ng-click="$ctrl.setPassword = false">{{"COMMON_CLOSE" | translate}}</md-button>
                     </div>
                </form>
            </md-card-content>
        </md-card>
    `
}
