
import {CoreServiceResource} from '../core/service/core_service_resource'

export class SharedClusterService extends CoreServiceResource {
    constructor ($resource, $q, KuberlabConfigApi, $rootScope){
        super(...arguments);
    }

     getResource(params, clear) {
        var resource;
        if (!this._resource || (params && this.rType != params.type) || clear){
            this.list = null;
            this.listPromise = null;
            this.rType = params.type;
            var apiPathBase = this.cfg.APIPath + '/sharedclusters/' ;
            var apiPath = apiPathBase + this.rType + (this.rType == "own" ? '/:ID' : (this.rType == "available" ?'/:WorkspaceName/:ID' : '/:ID' ) );
            resource = this.$resource(
                apiPath,
                {
                    ID: '@ID',
                    WorkspaceName: "@WorkspaceName"
                },
                _.extend(
                    {},
                    this.cfg.ResourceParams,
                    {
                        storage: {
                            isArray : true,
                            url: apiPath + '/storage'
                        },
                        add_share: {
                            method: "POST",
                            url: apiPath + '/share'

                        },
                        delete_link_email : {
                            method: 'DELETE',
                            url: apiPath + '/email/:shareID',
                            params: {shareID: "@shareID"}
                        },
                        delete_link_ws : {
                            method: 'DELETE',
                            url: apiPath + '/workspace/:WorkspaceName/:shareID',
                            params: {shareID: '@shareID', WorkspaceName: '@WorkspaceName'}
                        },
                        token_info : {
                            method: 'GET',
                            url: apiPathBase + "token/:token",
                            params: {token : "@token"}
                        },
                        token_accept : {
                            method: 'POST',
                            url: apiPathBase + "token/:token/accept",
                            params: {token : "@token"}
                        },
                        token_delete : {
                            method: 'DELETE',
                            url: apiPathBase + "token/:token",
                            params: {token : "@token"}
                        }
                    }
                )
            );
            this._resource = resource;
        }
        return resource || this._resource ;
    }
}