import {ws_listener} from '../controller/ws_listener'

export class CoreServiceResource extends ws_listener {
    constructor ($resource, $q, KuberlabConfigApi, $rootScope){
        super();
        var inject = angular.element(document.body).injector();

        this.cfg = KuberlabConfigApi || inject.get('KuberlabConfigApi');
        this.$q = $q || inject.get('$q');
        this.$resource = $resource || inject.get('$resource');
        this.WS = inject.get('WS');
        this.$scope = $rootScope ;
        this.list = null;
    }

    getResource(params) {
        this._resource = this.$resource('error-resource');
        return this._resource;
    }

    getList (update, params = {}) {
        if( update || !this.list || !this.listPromise || !_.isEqual(params, this._listParams) ){
            this.listPromise = null;
        }
        this._listParams = params;

        if(!this.listPromise) {
            var self = this;
            this.listError = null;
            this.getListPromise(update, params)
                .then(
                    function (r) {
                        self.list = r ;
                        return r;
                    }
                )
        }

        return this.listPromise; //this.list;
    }

    getListPromise (update, params) {
        if(update) {
            this.listPromise = null;
        }
        if(!this.listPromise) {
            this.listPromise = this._resource.query(params).$promise;
        }
        return this.listPromise;
    }

    new (data={}) {
        var pr = new this._resource(data);
        return this.$q.resolve(pr);
    }

    add(el) {
        if(this.list) {
            if(!_.findWhere(this.list, el)) {
                this.list.push(el);
            }
        }
    }

    remove (data) {
        this.list = _.without(this.list, data);
    }

    reset () {
        debugger;
        this._resource = null;
        this.list = null;
        this.listPromise = null;
    }

    // watchWS(event, cb = () => {}){
    //     // this.$scope.$on('ws:' + event, (ev, data) => { cb(ev, data); } )
    // }
}
