export class applicationEditTemplateController {
    constructor(appController){
        this.appController = appController;
        this.getTemplate();
    }

    getTemplate() {
        this.appController.applicationsResource.simply_template({application: this.appController.data.Name})
            .$promise
            .then(
                (r) => {
                    this.template = r.template;
                },
                (err) => {
                    this.error = err;
                }
            )
            .finally(
                () => {
                    this.saving = null;
                }
            )
    }

    saveTemplate() {
        this.appController.applicationsResource.simply_template_save({application: this.appController.data.Name}, this.template)
            .$promise
            .then(
                (r) => {
                    this.appController.$mdDialog.hide();
                },
                (err) => {
                    this.error = err;
                }
            )
            .finally(
                () => {
                    this.saving = null;
                }
            )
    }

    cancel () {
        this.appController.$mdDialog.cancel();
    };

}

