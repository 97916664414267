import {CatalogBaseItemController} from '../catalog/base/item_controller'
import {MlmodelServingController} from './serving/controller'

export class MlmodelItemController extends CatalogBaseItemController{
    constructor (mlmodel, mlmodelPageTabs, mlmodelResource, MlmodelService, $scope, permissions, pageTabs, $mdDialog, $templateCache) {
        super();
        this.$templateCache = $templateCache;
        this.$mdDialog = $mdDialog;
        this.permissions = permissions;
        this.canManage = permissions.can('mlapp.manage');
        this.mlmodelPageTabs = mlmodelPageTabs;
        this.data = mlmodel;
        this.resource = mlmodelResource;
        this.Service = MlmodelService;
        this.$scope = $scope;
        this.isLoading = {};
        this.loadingError = {};
        this.stateName = 'wsMlmodelItem';
        this.stateParentName = "wsMlmodelList";
        this.stateCatalog = "mlcatalog";
        this.pageTabs = pageTabs;
    }

    serveDialog() {
        var self = this;
        this.$mdDialog.show({
            locals: {
                application: this.application,
                action: this.action,
                job: job,
                parentController: this
            },
            controllerAs: '$ctrl',
            controller: MlmodelServingController,
            template: this.$templateCache.get('kuberlab/mlmodel/serving/template.html'),
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose:false,
        })
            .then(function(params) {
                // self.action('serving', j, params);

            }, function() {
                // debugger;
            });

    }
}
