import {ControllerList} from '../../core/controller/list'

class controller extends ControllerList {
    constructor(UserTokenService, $scope, $state) {
        super();
        this.service = UserTokenService;
        // this.resource = UserTokenService.getResource();
        this.list;
    }

    $onInit() {
        // this.list = [];
        this.resource = this.service.getResource({org:this.org, team: this.team});
        this.getList();

    }

    getList() {
        this.resource.query().$promise
            .then(
                (r)=>{
                    this.list = r;
                },
                (err) => {
                    this.error = err;
                }
            )
    }
}

export const UserTokenComponent = {
    controller: controller,
    bindings: {
        org: '<',
        team: '<'
    },
    template: function ($templateCache) {
        return $templateCache.get('kuberlab/user/token/user_token_component.html')
    }
}
