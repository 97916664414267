
import config from  './error_stateconfig'
import {ErrorDialogComponent, ErrorPageComponent, ErrorToastComponent} from './error_controller'
import {ErrorService} from './error_service'
import dependence from '../dependence/dependence_module'

export default angular
    .module('kuberlabError', [dependence.name])
    .config(config)
    .service('ErrorService', ErrorService)
    .component('cmpErrorDialog', ErrorDialogComponent)
    .component('cmpErrorPage', ErrorPageComponent)
    .component('cmpErrorToast', ErrorToastComponent)

