class controller {
    $onInit() {
        this.list = [];
        _.each(this.ngModel, (v, k) => {
            var l = k.split(':');
            this.list.push({
                limit: l[0],
                label: l[1] ? l[1].replace(/_/g,' ') : null ,
                value: v
            })   
        });
        // debugger;
    }
}
export const BillingPlanLimits = {
    bindings: {
        ngModel: "=",
        unit: "<"
    },
    controller: controller,
    template: `
         <table class="plan_limits">
            <!--<tr ng-repeat="(limit, v) in $ctrl.ngModel" >-->
            <tr ng-repeat="item in $ctrl.list" >
                <td class="name" style="width: 50%">
                    <div layout="row" layout-align="end center" flex>
                        <div layout="column" layout-align="center end"><span>{{'PLAN_LIMIT_NAME_' + item.limit| translate}}</span><span ng-if="item.label" style="font-size: 80%" translate>{{item.label}}</span></div>
                        <help-hint hint="billing.limit.{{item.limit}}"></help-hint>
                    </div>
                </td>
                <td style="width: 1em"></td>
                <td class="limit" ng-if="item.value< 0"  style="text-align: left;">
                       <span translate>PLAN_LIMIT_Unlimited</span>
                </td>
                <td class="limit"  ng-if="item.value>= 0" style="text-align: left;">
                    {{$ctrl.unit}}<span ng-switch="item.limit">
                    <span ng-switch-when="cpu_mi"><span class="value">{{ (item.value/ 1000).toFixed(0) }}</span> <span class="unit">{{'PLAN_LIMIT_UNIT_' + item.limit | translate}}</span></span>
                    <span ng-switch-when="gpu_seconds">
                        <span ng-if="(item.value/ 3600) >= 1 "><span class="value" >{{ (item.value/ 3600).toFixed(0) }}</span> <span class="unit">{{'PLAN_LIMIT_UNIT_' + item.limit + '_h' | translate}}</span></span>
                        <span ng-if="(item.value/ 3600) < 1 "><span class="value" >{{ (item.value/ 60).toFixed(0) }}</span> <span class="unit">{{'PLAN_LIMIT_UNIT_' + item.limit + '_min' | translate}}</span></span>
                    </span> 
                    <span ng-switch-when="gpu_item_seconds">
                        <span ng-if="(item.value/ 3600) >= 1 "><span class="value" >{{ (item.value/ 3600).toFixed(0) }}</span> <span class="unit">{{'PLAN_LIMIT_UNIT_' + item.limit + '_h' | translate}}</span></span>
                        <span ng-if="(item.value/ 3600) < 1 "><span class="value" >{{ (item.value/ 60).toFixed(0) }}</span> <span class="unit">{{'PLAN_LIMIT_UNIT_' + item.limit + '_min' | translate}}</span></span>
                    </span>
                     <span ng-switch-when="execution_time">
                        <span ng-if="(item.value/ 3600) >= 1 "><span class="value" >{{ (item.value/ 3600).toFixed(0) }}</span> <span class="unit">{{'PLAN_LIMIT_UNIT_' + item.limit + '_h' | translate}}</span></span>
                        <span ng-if="(item.value/ 3600) < 1 "><span class="value" >{{ (item.value/ 60).toFixed(0) }}</span> <span class="unit">{{'PLAN_LIMIT_UNIT_' + item.limit + '_min' | translate}}</span></span>
                    </span>
                    <span ng-switch-when="memory_mb">
                        <span class="value" >{{(item.value/  1024).toFixed(0) }}</span> <span class="unit">{{'PLAN_LIMIT_UNIT_' + item.limit| translate}}</span>
                    </span>
                    <span ng-switch-when="memory_mb_seconds">
                        <span class="value" >{{ (item.value/  ( 3600 * 1024)).toFixed(0) }}</span> <span class="unit">{{'PLAN_LIMIT_UNIT_' + item.limit| translate}}</span>
                    </span>
                    <span ng-switch-when="cpu_seconds">
                        <span class="value" >{{(item.value/  3600).toFixed(0) }}</span> <span class="unit">{{'PLAN_LIMIT_UNIT_' + item.limit| translate}}</span>
                    </span>
                    <span ng-switch-when="storage_gb_seconds">
                        <span class="value" >{{(item.value/  86400).toFixed(0)}}</span> <span class="unit">{{'PLAN_LIMIT_UNIT_' + item.limit| translate}}</span>
                    </span>
                    <span ng-switch-default>
                        <span class="value" >{{ item.value}}</span> <span class="unit">{{'PLAN_LIMIT_UNIT_' + item.limit | translate}}</span>
                    </span></span>
                </td>
            </tr>
        </table>
    `
}