import {CoreServiceResource} from '../../core/service/core_service_resource'

export class ChartsCommentsService extends CoreServiceResource {
    constructor ($resource, $q, KuberlabConfigApi, $rootScope){
        super(...arguments);
    }

    getResource(params) {
        if (!this._resource || this.wsId != params.wsId || this.chartName != params.chartName ){
            var apiPath = this.cfg.APIPath;
            apiPath = apiPath + '/workspace/' + params.wsId + '/charts/' + params.chartName + '/comments' ;

            this._resource = this.$resource(
                apiPath,
                {chartName: '@chartName'},
                _.extend(
                    {},
                    this.cfg.ResourceParams,
                    {
                    }
                )
            );
            this.wsId = params.wsId;
            this.chartName = params.chartName;
            this.list = null;
            this.listPromise = null;
        }
        return this._resource;
    }

}