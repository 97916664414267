export default angular
  .module('header', ['ngMaterial'])
  .component('cmpHeaderMenu', {
    controller: ($scope, IntroService) => {
        $scope.IntroService = IntroService;
      $scope.reload = (path) => location.reload(path);
        $scope.CallIntro = (name, step) => {
            $scope.IntroService.start(name, step);
        }
    },
    template: `
            <div class="kuberlab-logo flex layout-row layout-align-start-center">
                <div id="headMenu" class="topMenu" >
                    <a ui-sref="home" ui-sref-opts="{reload: true}" ><cmp-logo></cmp-logo></a>
                    <!--<a ui-sref="home" ui-sref-opts="{reload: true}" ><kube-logo>Kuber<span class="color_in">Lab</span></kube-logo></a>-->
                    <!--<a href="/" ng-click="reload('/')"><kube-logo>Kuber<span class="color_in">Lab</span></kube-logo></a>-->
                    <a class="item" ui-sref="catalog_newMain" id="topmenu-catalog">{{ 'TOP_MENU_CATALOG' | translate }}</a>
                    <!--<a class="item" ui-sref="mlcatalog">{{ 'MLMODEL' | translate }}</a>-->
                    <!--<a class="item" ui-sref="datasets">{{ 'CATALOG_DATA' | translate }}</a>-->
                    <a class="item" target="_blank" href="https://kibernetika.readthedocs.io/en/latest/">{{ 'TOP_MENU_HELP' | translate }}</a>
                    <!--<a class="item" href="" ng-intro-disable-button ng-click="CallIntro('scenCreateProject');">Demo</a>-->
                    <!--<a class="item" target="_blank" href="https://github.com/kuberlab/dev.kuberlab.io/wiki/Quick-Start-Guide">{{ 'TOP_MENU_HELP' | translate }}</a>-->
                </div>
            </div>
        `
  });
