
import {ApplicationLogsComponent} from './applications_logs_component'
import {ApplicationLogsJobComponent} from './applications_logs_job_component'
import {ApplicationsLogsJobPodComponent} from './applications_logs_job_pod_component'

export default angular
    .module('application_logs', [])
    .component('cmpApplicationsLogs', ApplicationLogsComponent)
    .component('cmpApplicationsLogsJob', ApplicationLogsJobComponent)
    .component('cmpApplicationsLogsJobPod', ApplicationsLogsJobPodComponent)

