class SharedClusterItemLinkController {
    constructor(SharedClusterService){
        this.SharedClusterService = SharedClusterService;
    }

    delete(item){
        var action , params = {ID: this.data.ID, shareID: item.ID}, self = this;
        this.error = null;
        if(!item.Active){
            action = "delete_link_email";
        } else {
            action = "delete_link_ws";
            params.WorkspaceName = item.WorkspaceName;
        }

        this.resource = this.SharedClusterService.getResource({type: this.data.Global ? 'global' : 'available'});
        this.resource = this.SharedClusterService.getResource({type: 'own'});
        this.resource[action](params)
            .$promise
            .then(
                (r) => {
                    self.data.$get();
                },
                (err) => {
                    self.error = err;
                }
            )
    }

}

export const  SharedClusterItemLinkComponent = {
    bindings: {
        data: "="
    },
    controller: SharedClusterItemLinkController,
    template: `
        <md-list ng-if="!$ctrl.data.Global">
            <md-list-item ng-repeat="item in $ctrl.data.Links | orderBy : ['WorkspaceName', 'Email']">
                <md-icon md-svg-icon="core:link" class="md-avatar-icon"></md-icon>
                <div class="md-list-item-text flex">
                    <div ng-if="item.WorkspaceName">WS: {{item.WorkspaceName}}</div>
                    <div ng-if="item.Email">Email: {{item.Email}}</div>
                </div>
                <md-button ng-click="$ctrl.delete(item)" class="md-icon-button md-secondary">
                    <md-icon md-svg-icon="core:delete"></md-icon>
                </md-button>
            </md-list-item>
        </md-list>
        <md-list ng-if="$ctrl.data.Global">
            <md-list-item >
                <md-icon md-svg-icon="core:link" class="md-avatar-icon"></md-icon>
                <div class="md-list-item-text flex">
                    All organizations
                </div>
            </md-list-item>
        </md-list>
        <cmp-api-error-service ng-if="$ctrl.error" error="$ctrl.error" format="'dialog'"></cmp-api-error-service>
`
}
