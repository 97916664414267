class controller {
    constructor($state, CatalogInterface){
        this.$state = $state;
        this.items = CatalogInterface;

    }

    $onInit() {
        // debugger
        this.listState =  this.listState ? this.listState : '';
        this.link = this.listState + '({Interface: item.Interface, keyword: null})';
        if(this.isItem) {
            this.link = '^({Interface: item.Interface, keyword: null})';
        }
        if(!this.listState) {
            this.listState = this.$state.params.wsId ? "wsCatalog.list" : "catalog_new.list"
        }
    }
}


export const CatalogMenuComponent = {
    bindings: {
        listState: '<',
        interface: '<',
        isItem: '<'
    },
    controller: controller,
    template: `

        <div class="blockMenu">
            <a  class="blockMenuItem"  ng-repeat="item in $ctrl.items" ui-sref="{{$ctrl.link}}" ui-sref-active="current" >
                <md-icon md-svg-icon="core:{{item.Interface}}" class="status {{$ctrl.data.Health}}" ></md-icon>
                <span class="label" layout="row" layout-align="space-between center" translate >{{item.label}}</span>
            </a>
      </div>
`
}
