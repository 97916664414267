export function KuberLogo($mdTheming) {
    return {
        restrict: 'EA',
        template: 'Kuber<span class="color_in">Lab</span>',
        link: function ($scope, $element, attr) {
            $element.addClass('projerctLogo');
            $mdTheming($element);

        }
    };
}