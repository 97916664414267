import {ControllerList} from '../../../core/controller/list'


class controller extends  ControllerList {

    constructor($scope, ProjectStoragesService, Message, SharedClusterService, StoragePersistentService, ClusterService, $resource, KuberlabConfigApi){
        super();
        this.$scope = $scope;
        this.KuberlabConfigApi = KuberlabConfigApi;
        this.$resource = $resource;
        this.ProjectStoragesService = ProjectStoragesService;
        this.SharedClusterService = SharedClusterService;
        this.StoragePersistentService = StoragePersistentService;
        this.Message = Message;
        this.ClusterService = ClusterService;
        this.current = 0;
        var self = this;
        $scope.$watch(
            function () {
                return self.data.value;
            },
            function (c) {
                self.parent.setYaml();
            }
        );
        this.storages = [];

        $scope.$watch(
            ()=>{
                this.parent.clusters;
            },
            () => {
                this.removeNew();
            }
        )


    }

    $onInit() {
        var self = this;
        this.$scope.$watchCollection(function(){
                return self.parent.clusters;
            },
            function (c) {
                if(c && c.length){
                    self.loadStorages();
                }
            }
        );
        this.$scope.$watch(function(){
                return self.parent.workspace_name;
            },
            (c) => {
                if(c){
                    this.storages = [];
                }
            }
        );
    }

    loadStorages() {
        this.storages = null;
        this.complite = null;
        var self = this;
        var param = null;
        _.each(this.parent.clusters, function (cluster) {
            this.ClusterService.storage(this.parent.workspace_name, cluster)
                .then(
                    (r) => {
                        if(!this.storages) this.storages = [];
                        this.storages = _.sortBy(this.storages.concat(r), 'Name');
                        this.current = null;
                        this.setDef();
                    },
                    (err) => {
                        if(!this.storages) this.storages = [];
                    }
                )
                .finally(() => {this.complite = true;})
        }, this);

    }

    setDef() {
        if (!this.storages.length) {
            this.parent.canInstall = false;
            return;
        }
        if(!this.current && this.storages && this.storages.length) {
            var def = _.findWhere(this.storages, {Default: true}) || this.storages[0];
            this.current = def.Name;
        }
        this.toogle();
        this.parent.canInstall = true;
    }

    canEdit() {
        return !!(this.parent.clusters && this.parent.clusters.length && (this.parent.target_application || this.parent.target_applications));
    }

    toogle (index) {
        this.data.value =  this.current; //.pref + '/' + this.current.Name;
        this.parent.canInstall = true;
    }

    getType(storage) {
        if(storage.Meta.nfs) return 'nfs';
        if(storage.Meta.kuberlabStorage) return 'kuberlabStorage';
    }

    getNewStore() {
        this.isNewElement = true;
        var apiPath = this.KuberlabConfigApi.APIPath + '/workspace/' + this.parent.workspace_name + '/clusters/:ClusterID/storage';

        var r = this.$resource(
            apiPath,
            {
                ClusterID: '@ClusterID'
            },
            _.extend(
                {},
                {
                    create:{ method: "POST"}
                }
            )
        );
        this.newStore = new r({ClusterID: this.parent.clusters[0].ClusterID});
    }

    addComplite(r) {
        this.storages.push(angular.copy(this.newStore));
        this.current = this.newStore.Name;
        this.newStore = null;
        this.toogle();
    }

    removeNew() {
        this.newStore = null;
    }
}

export const ChartWizardClusterStorageComponent = {
    bindings: {
        parent: "<",
        data: "="
    },
    controller : controller,
    template: `
            <div class="layout-collumn">
            
                <md-card ng-if="$ctrl.complite && !$ctrl.storages.length" class="warningInfo" ng-class="{'compact': $ctrl.compact}">
                  <md-card-title>
                    <md-card-title-media ng-hide="$ctrl.noIcon">
                      <div class="md-media-sm card-media" layout>
                        <md-icon md-svg-icon="core:alert"></md-icon>
                      </div>
                    </md-card-title-media>
                    <md-card-title-text>
                      <span class="md-headline">Warning</span>
                      <span class="md-subhead description">There are no configured storage available. <a ng-click="$ctrl.getNewStore()">Please click here to configure new one.</a>
                    </md-card-title-text>
                  </md-card-title>
                </md-card>
            
                <!--<cmp-show-message ng-if="$ctrl.complite && !$ctrl.storages.length" message="Cluster has no any storage. You will be unable to install Application"></cmp-show-message>-->
                <!--<cmp-show-message ng-if="$ctrl.complite " message="Cluster has no any storage. You will be unable to install Application"></cmp-show-message>-->
                <md-radio-group ng-model="$ctrl.current" class="layout-column" ng-change="$ctrl.toogle()" ng-if="$ctrl.storages.length">
                    <div ng-repeat="(key, item) in $ctrl.storages | orderBy:['-Type','Name'] ">
                        <md-radio-button  
                                    ng-value="item.Name" 
                                    class="layout-column">
                            <div class="layout-row layout-align-start-center">
                                <md-icon md-svg-icon="core:{{item.Type}}" class="md-avatar-icon" ></md-icon>
                                <div style="padding: 0 1em;" class="flex">
                                    <div><b>{{item.Name}}<span ng-if="item.Meta.SizeGb"> ({{item.Meta.SizeGb}}GB)</span></b></div>
                                </div>
                                <div ng-if="item.Meta.nfs"><span>Server: {{item.Meta.nfs.server}}</span>&nbsp;<span>Path: {{item.Meta.nfs.path}}</span></div>
                            </div>
                            <p ng-if="item.Description">{{item.Description}}</p>
                        </md-radio-button>
                    </div>
                </md-radio-group>
            </div>
            <md-button class="md-raised" ng-if="!$ctrl.newStore" ng-click="$ctrl.getNewStore()" >{{'COMMON_ADD' | translate }}</md-button>
            <cmp-storage-persistent-item ng-if="$ctrl.newStore" data="$ctrl.newStore" list-controller="$ctrl" clusters="[$ctrl.parent.clusters[0]]" ></cmp-storage-persistent-item>
    `
};

