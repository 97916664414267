import {ControllerList} from '../core/controller/list'

export class ProjectsList extends ControllerList {
    constructor (ProjectService, $scope, $state, WorkspacePermissions) {
        super();
        this.list = null;
        this.$scope = $scope;
        this.workspace ; //html bindings
        this.ProjectService = ProjectService;
        this.isWs = !!$state.params.wsId;
        this.permissions = WorkspacePermissions;
        this.$state = $state;
    }

    $onInit () {
        var wsName = this.workspace ? this.workspace.name : this.$state.params.wsId;
        this.ProjectService.getResource(wsName);

    this.ProjectService.getProjects().then((projects) => {
      this.listEmpty = projects && !projects.length;
      this.list = projects;
    });
  }

  limitHeigth() {
      return false;
    return this.mainPage && this.list && this.list.length > 6;
  }
}
