import {SharedClusterListController} from './shared_cluster_list_controller'
import {SharedClusterAcceptController, SharedClusterAcceptTemplate} from './shared_cluster_accept'

export default function ($stateProvider) {
    $stateProvider

        .state('wsClusters', {
            url :  "/clusters",
            parent: 'workspace',
            views: {
                "layout-page-content@" : {
                    template : `
                        <layout-page-content-title class="md-whiteframe-1dp" layout="row" layout-align="start center">
                            <layout-back></layout-back>
                            <h3>{{$ctrl.WS.DisplayName}} {{'SHARED_CLUSTER_AVAILABEL' | translate}}</h3>
                            
                            <div flex></div>
                            <md-button ng-if="$ctrl.viewSettings.infra" ui-sref="sharedCluster.tab({tab: 'available'})" class="md-raised md-primary">
                                <md-icon md-svg-icon="core:share"></md-icon>
                                <span class="label no-float">Shared clusters</span>
                            </md-button>
                            
                        </layout-page-content-title>
                        <md-content class="md-padding" flex>
                            <cmp-shared-cluster-list class="innerContentBorder" type="'available'" params="{WorkspaceName: $ctrl.WS.Name}"></cmp-shared-cluster-list>
                        </md-content>
                    `,
                    controller: function (workspace, $rootScope, permissions, $scope, UserService) {
                        this.permissions = permissions;
                        this.WS = workspace;
                        $scope.$watch(
                            () => { return UserService.viewSettings; },
                            (c) => {
                                this.viewSettings = c;
                            }
                        );

                    },
                    controllerAs: '$ctrl'
                }
            }
        })

        .state('sharedClusterAccept', {
            url: '/shared_cluster_accept/:token',
            parent: 'cu',
            views : {
                "layout-page-content@" : {
                    template: SharedClusterAcceptTemplate,
                    controller: SharedClusterAcceptController,
                    controllerAs: '$ctrl'
                }
            }
        })

        .state('sharedCluster',{
            parent: 'content',
            url : '/shared_cluster',
            redirectTo: "sharedCluster.tab",
            views : {
                "layout-page-content@" : {
                    template: `
                        <layout-page-content-title class="md-whiteframe-1dp" layout="row" layout-align="start center">
                            <h3 translate>SHARED_CLUSTER_AVAILABEL</h3>
                        </layout-page-content-title>
                         <md-tabs class="itemContent" md-dynamic-height >
                            <md-tab ng-repeat="tab in $ctrl.tabs track by $index"
                                    md-active="$ctrl.$state.params.tab == tab.link"
                                    ng-click="$ctrl.goTab(tab.link)">
                                <md-tab-label>
                                    {{tab.title}}
                                </md-tab-label>
                            </md-tab>
                        </md-tabs>
                        <md-content class="md-padding" ui-view="tabContent" flex>
                        </md-content>
                    `
                ,
                    controller: function ($state, $rootScope) {
                        this.$state = $state;
                        $rootScope.addButtonConfig = [];
                        this.tabs = [
                            {title: 'My share', link: 'my'},
                            {title: "Available share", link: 'available'}
                        ];
                        var self = this;
                        this.goTab = function(tab){
                            self.$state.go("sharedCluster.tab", {tab: tab});
                        }

                    },
                    controllerAs: '$ctrl'
                }
            }
        })


        .state('sharedCluster.tab',{
            url : '/:tab/:tabItem',
            params: {
                tab: 'my',
                tabItem: null
            },

            resolve: {},
            views: {
                'tabContent' : {
                    template: `<cmp-shared-cluster-list type="$ctrl.rType"></cmp-shared-cluster-list>`,
                    controller: function ($state) {
                        this.rType = 'own';
                        switch($state.params.tab){
                            case 'my' :
                                this.rType = 'own';
                                break;
                            case 'available' :
                                this.rType = 'available';
                                break
                        }
                    },
                    controllerAs: '$ctrl'
                }
            }
        })

}
