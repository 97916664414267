import {ApplicationsTaskTeplateController, ApplicationsTaskTeplateDialog} from './applications_task_template_component'
import {controllerTemplateDialog, controllerTemplateDialogTpl} from '../../template/template-dialog-component'


class controller{

    constructor(ApplicationsService, $state , $mdDialog, $translate, $scope, $rootScope, ErrorService, $templateCache, $filter, $transitions, TemplateService, validate, $element) {
                    this.validatePattern = validate;
        this.$scope = $scope;
        this.$state = $state;
        this.$translate = $translate;
        this.$mdDialog = $mdDialog;
        this.$templateCache = $templateCache;
        this.service = ApplicationsService;
        this.$transitions = $transitions;
        this.resourceApp = ApplicationsService.getResource( $state.params );
        this.ErrorService = ErrorService;
        this.Allocatednodes;
        this.$filter = $filter;
        this.$rootScope = $rootScope;
        this.TemplateService = TemplateService;
        var self = this;
        this.spaceCh = "_";
        this.newResource = {};
        this.taskTypesIcon = {
            'general': 'progress-wrench',
            'init': 'progress-download',
            'export': 'progress-upload'
        };
        this.taskTypes = _.keys(this.taskTypesIcon); // ['general', 'init', 'export'];
        this.taskTypeDef = 'general';

        this.task = null;
        this.resource = null;
        this.resourceID = null;
        // $element.ready(() => {
        //     _.delay(() => { window.dispatchEvent(new Event('resize')); }, 10);
        // });
    }

    $onInit(){

        if( !this.appController.data.Configuration.spec.tasks) this.appController.data.Configuration.spec.tasks = [];

        this.getTaskResource(this.$state.params.tabItem, this.$state.params.resourceName);
        this.first = true;
        // this.$transitions.onFinish({ entering: 'wsApplication.tab' },
        this.listenerState = this.$transitions.onSuccess({ entering: 'wsApplication.tab' },
            (transition, state) => {
                let p = this.$state.params;
                let to =  _.extend({}, transition.params('to'));
                // debugger
                if ( to.tab == 'models' ) {
                    // let from = transition.params('from');
                    // if( from.tab != to.tab) {
                    //     this.getTaskResource(this.task.name, this.request.name, transition);
                    // } else {
                    //
                    //     this.getTaskResource(to.tabItem, to.resourceName, transition);
                    // }
                    this.getTaskResource(to.tabItem, to.resourceName, transition);
                    if(!this.first) {
                    }
                    this.first = null;
                }
        });
    }

    $onDestroy(){
        if( _.isFunction( this.listenerState ) )
            this.listenerState();
    }

    saveAction(form, action = 'save') {
        // debugger;
        if ( !form.$valid) return false;
        var self = this;
        var comment = action == 'save' ? 'Save' : 'Execute task ' + this.task.name;
        comment = comment + ' ' + this.$filter('date')(new Date(), 'yyyy-MM-dd HH:mm:ss');
        this.$mdDialog.show({
            locals: {
                task : this.task,
                application: this.appController.data,
                action: action,
                branch: this.appController.data.Configuration.revision ? this.appController.data.Configuration.revision.branch : 'master',
                comment: comment
            },
            controllerAs: '$ctrl',
            controller: function($scope, application, action, branch, comment, task) {
                this.application = application;
                $scope.task = task;
                $scope.action = action;
                $scope.branch = branch;
                $scope.data = {
                    comment : comment,
                    DoNotSave: false,
                } ;
                $scope.comment = comment;
                $scope.notSave = false;

                var then = this;

                $scope.changeCreateNew = (newBranch) => {
                    if(newBranch) {
                        then._DoNotSave = $scope.data.DoNotSave;
                        $scope.data.DoNotSave = false;
                    } else {
                        $scope.data.DoNotSave = then._DoNotSave;
                    }
                };

                $scope.cancel = function () {
                    self.$mdDialog.cancel();
                };

                $scope.continue = function (form) {
                    if(!form.$valid) return false;

                    $scope.data.newBranch = $scope.createNew ? $scope.newBranch : null;
                    self.$mdDialog.hide($scope.data);
                }

            },
            template: this.$templateCache.get('kuberlab/applications/models/applications_save_dialog.html'),
            parent: angular.element(document.body),
            clickOutsideToClose:false,
        })
            .then( (params) => {
                this[action](params.comment, params.newBranch, params.DoNotSave);

            }, () => {
                // debugger;
            });
    }

    saveConfig(new_branch) {
        var revision = this.appController.data.Configuration.revision || {};
        revision.new_branch = new_branch;
        this.appController.data.Configuration.revision = revision;
        return this.appController.data.Configuration;
    }


    build (comment, new_branch, DoNotSave) {
        var self = this;
        var nb = new_branch;
        self.updateError = null;
        self.saving = true;
        this.resourceApp.build({application: this.appController.data.Name, taskName: this.task.name}, _.extend({Comment: comment, NewBranch: new_branch, DoNotSave: DoNotSave}, this.appController.data))
            .$promise
            .then(
                (r) => {
                    this.service._reset('experimants');
                    this.service.addTask(this.$state.params, r);
                    this.$state.go('wsApplication.tab', {revision: nb ? nb : this.appController.data.Configuration.revision.branch, tab : 'jobs', tabItem: r.name})
                },
                (err) => {
                    this.ErrorService.error(err);
                }
            )
            .finally(
                () => {
                    this.saving = false;
                }
            )
    }


    save(comment, new_branch) {
        // debugger
        var self = this;
        var nb = new_branch;
        this.updateError = false;
        self.saving = true;
        this.appController.data.Comment = comment;
        if(new_branch) {
            this.appController.data.NewBranch = new_branch;
        }

        // return this.appController.data.$update()
        return this.appController.update()
            .then(
                (r) =>{
                    this.getTaskResource(this.task ? this.task.name : null, (this.task && this.resource ) ? this.resource.name : null );
                },
                function (err) {
                    self.updateError = err;
                    self.ErrorService.error(err);
                }
            )
            .finally(
                 () => {
                     this.service._reset('revision');
                     this.saving = false;
                }
            )
    }

    onSortEnd() {
        // if(!this.isMenuEditing) this.editTaskMenu();
    }

    addResource() {
        if(this.newResource.name){
            if(!this.appController.data.Configuration.spec.tasks[this.taskID].resources) this.appController.data.Configuration.spec.tasks[this.taskID].resources = [];
            this.appController.data.Configuration.spec.tasks[this.taskID].resources.push(angular.copy(this.newResource));
            // this.chnageResource(this.newResource.name, true);
            this.$state.go('wsApplication.tab',
                { tab: 'models', tabItem: this.task.name, resourceName: this.newResource.name },
                {
                    location: true,
                    inherit: true,
                    notify: false
                }
            );

            this.newResource = {};
        }
    }

    canselAddResource() {
        this.newResource = {};
    }

    deleteResource(resource){
        // debugger;
        this.appController.data.Configuration.spec.tasks[this.taskID].resources = _.without(this.appController.data.Configuration.spec.tasks[this.taskID].resources, resource);
        let length = this.appController.data.Configuration.spec.tasks[this.taskID].resources.length;
        this.resourceID = this.resourceID ? this.resourceID - 1 : 0;
        // this.resourceID = length ? length - 1 : 0;
    }

    listAddTask() {
        this.newTaskTemplateList = [
            {name: 'new', data: {name: 'new_task'}}
        ];
        _.each(this.appController.data.Configuration.spec.tasks, (t) => {
            var name = t.name + '_copy';
            var taskName = this.chackTaskName(name) ? name : name + " " + new Date() * 1;
            this.newTaskTemplateList.push({name: name, data: _.extend({}, t, {name: taskName})})
        });

        var resource = this.TemplateService.getResource({type: 'job'});
        resource.query().$promise
            .then(
                (r) => {
                    this.extTaskTemplateList = r;
                },
                (err) => {
                    // this.error = {data: {Error: r.message}}
                }
            )
    }

    addTaskTemplate(data, template){
        if(data) {
            var d = angular.copy(data);
            d.isNew = true;
            this.appController.data.Configuration.spec.tasks.push(d);
            this.taskID = this.appController.data.Configuration.spec.tasks.length - 1;
            this.task = this.appController.data.Configuration.spec.tasks[this.taskID];
            this.resource = false;
            this.resourceID = false;
            this.getTaskResource(this.task.name);
            this.newTaskName = this.task.name;
            if(!this.task.type) this.task.type = this.taskTypeDef;
        }
        else {
            var resource = this.TemplateService.getResource({type: 'job'});
            resource.template({template: template.Name}).$promise
                .then(
                    (r) => {
                        try{
                            var task = jsyaml.safeLoad(r.template);
                            var name = task.name + '_template';
                            task.name = this.chackTaskName(name) ? name : name + " " + new Date() * 1;

                            this.addTaskTemplate(task, template);

                        }catch (r){
                            this.error = {data: {Error: r.message}}
                        }
                    },
                    (err) => {
                        this.error = err;
                    }
                )
        }
    }


    getTaskResource( taskName, resourceName, byEvent ) {

        let tasks = this.appController.data.Configuration.spec.tasks,
            task, resource
        ;

        if( !tasks || !tasks.length) {
            this.task = null;
            this.taskID = null;
            this.resource = null;
            this.resourceID = null;
            return false;
        }

        if( !taskName || !( task = _.findWhere(tasks, {name: taskName})) ) {
            let name = this.task ? this.task.name : tasks[0].name;
            this.$state.go('wsApplication.tab',
                { tab: 'models', tabItem: name, resourceName: null}, {  location: true, inherit: true, notify: false }
            );
            return false;
        }
        this.task = task;
        this.taskID = _.indexOf(tasks, this.task);
        this.getTaskVolume();


        if( !this.task.resources || !this.task.resources.length ) {
            this.resourceID = null;
            this.resource = null;
            return false;
        }


        if ( !resourceName || !(resource = _.findWhere(this.task.resources, {name: resourceName}))) {
            let name = this.resource ? this.resource.name : this.task.resources[0].name;
            this.$state.go('wsApplication.tab',
                { tab: 'models', tabItem: this.task.name, resourceName: name}, {  location: true, inherit: true, notify: false }
            );
            return false;

        }
        this.resource = resource;
        _.delay(
            () => {
                this.resourceID = _.indexOf(this.task.resources, this.resource);
            },
            100
        );
        // this.resourceID = _.indexOf(this.task.resources, this.resource);

    }


    // _getTaskResource(t, r, byEvent) {
    //     // debugger
    //     var taskName = t ? t : this.task ? this.task.name : null,
    //         resourceName = r,
    //         tasks = this.appController.data.Configuration.spec.tasks,
    //         task,
    //         resource,
    //         def,
    //         p = [];
    //
    //
    //     if(taskName) {
    //         // this.task = null;
    //         this.taskID = null;
    //         // this.resource = null;
    //         this.resourceID = null;
    //     } else if( !taskName && this.task ) {
    //         taskName = this.task.name;
    //         resourceName = ( this.task.resources && this.task.resources.length ) ? this.task.resources[this.resourceID].name : null ;
    //     }
    //
    //     if (_.isArray(tasks) && tasks.length) {
    //         if ( !taskName ) {
    //             taskName = tasks[0].name;
    //             def = true;
    //         }
    //         var task = _.findWhere(tasks, {name: taskName});
    //         if( task ) {
    //             this.task = task;
    //         }
    //         var self = this;
    //         if (this.task) {
    //             this.taskID = _.indexOf(tasks, this.task);
    //             if (_.isArray(this.task.resources) && this.task.resources.length) {
    //                 resource =  _.findWhere(this.task.resources, { name: resourceName });
    //                 if ( !resource ){
    //                     resource = _.first(this.task.resources); // _.findWhere(this.task.resources, { name: resourceName });
    //                 }
    //                 if (resource){
    //                     resourceName = resource.name;
    //                     this.resourceID = _.indexOf(this.task.resources, resource);
    //                 }
    //             }
    //         }else{
    //             this.task = null;
    //             this.resource = null;
    //             taskName = tasks[0].name;
    //         }
    //     }
    //
    //     if(this.task) this.getTaskVolume();
    //
    //     // console.log('this.resourceID', this.resourceID);
    //     // if( (this.$state.params.tabItem != taskName || this.$state.params.resourceName != resourceName) && !byEvent ) {
    //     var params;
    //     if(byEvent)  params = byEvent.params();
    //     // if( !params || params.tabItem != taskName || params.resourceName != resourceName ) {
    //     if( t != taskName || r != resourceName ) {
    //         // console.log('getTaskResource go', { tabItem: taskName, resourceName: resourceName});
    //         this.$state.go('wsApplication.tab',
    //             { tab: 'models', tabItem: taskName, resourceName: resourceName },
    //             {
    //                 location: true,
    //                 inherit: true,
    //                 notify: false
    //             }
    //         );
    //     }
    // }


    getTaskVolume() {
        this.taskDefMapping = false;

        _.each(this.task.resources, (v) => {
            if(v.default_volume_mapping) this.taskDefMapping = true;
        });

        this.taskVolumes = [];

        if(!this.taskDefMapping) {
            var taskVolums = [];
            _.each(this.task.resources, (v) => {
                taskVolums = _.union(taskVolums,
                    _.map(
                        v.default_volume_mapping ? this.appController.data.Configuration.spec.volumes :  v.volumes, (v) => { return v.name }
                    )
                );
            });
            this.taskVolumes = _.map(taskVolums, (v) => {
                return {name: v};
            })
        }
    }

    cancelNewName(){
        if(this.task.isNew) {
            this.deleteTask(this.task);
            // debugger
            // this.$state.go( this.$state.previous.name, this.$state.previous.params );
        }else{
            delete this.task.isEdit;
        }
    }

    setNewName(form, name) {
        // debugger;
        this.checkTaskName(form.newTask);
        if(!form.newTask.$valid) return false;
        // debugger;
        if(this.chackTaskName(name, this.task)){
            this.task.name = name;
            delete this.task.isNew;
            delete this.task.isEdit;
            this.getTaskResource(name);
            this.taskNameError = null;
        } else {
            this.taskNameError = {data: {Error : 'task name already use'}}
        }
    }

    chackTaskName(name, data = {}){
        var task = _.findWhere(this.appController.data.Configuration.spec.tasks, {name: name});
        return task && !_.isEqual(task , data) ? false : true;
    }

    editTask(){
        if(this.task) {
            this.task.isEdit = true;
            this.newTaskName = this.task.name;
        }
    }

    deleteTask(task){
        if(task) {
            var i = _.indexOf(this.appController.data.Configuration.spec.tasks, task);
            this.appController.data.Configuration.spec.tasks = _.without(this.appController.data.Configuration.spec.tasks, task);
            if(this.appController.data.Configuration.spec.tasks.length) {
                i = i ? i - 1 : 0;
                this.getTaskResource(this.appController.data.Configuration.spec.tasks[i].name);
            } else {
                this.getTaskResource();
            }
        }
    }

    cancelAddResource() {
        if(this.task.resources.length) {
            this.getTaskResource(this.$state.params.tabItem, this.$state.params.resourceName)
        }
    }

    checkTaskName(field) {
        // debugger;
        var tasks = _.map(_.without(this.appController.data.Configuration.spec.tasks, this.task), (i) => {return i.name; } );
        if(_.indexOf(tasks, field.$viewValue) == -1) {
            field.$setValidity("errorname", true);
        } else {
            field.$setValidity("errorname", false);
        }
        // form.Name.$setValidity("errorname", true);
    }

}


export const ApplicationsModelsComponent = {
    bindings: {
        appController: "="
    },
    controller: controller,
    template: function ($templateCache) {
        return $templateCache.get('kuberlab/applications/models/applications_models_component.html');
    },
};
