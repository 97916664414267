import {CoreServiceResource} from '../core/service/core_service_resource'
import {InstallChart, InstallChartTemplate} from '../charts/install/install_chart'
import {AuthRequestTemplateComponent, AuthRequestController} from '../auth/auth_request_component'

export class CatalogService extends CoreServiceResource {
    constructor($resource, $q, KuberlabConfigApi, $rootScope, $mdDialog) {
        super(...arguments);
        this.$mdDialog = $mdDialog;
        this._params = {};
        this.Interface = '';
    }

    getWsUrl (params, link) {
        // console.log(params)
        return this.cfg.APIPath + '/workspace/' + (params.wsId  ? params.wsId  : ':wsId') + ( params.Interface ? '/' + params.Interface : '/catalog' );
    }

    getResourceUrl(params) {
        return params.wsId
            ? this.getWsUrl(params) + '/:name'
            : this.cfg.APIPath + '/catalog' + ( params.Interface ? '/' + params.Interface : '' );
    }

    resourceAction (url, prCb) {
        var data = {};
        switch(this._params.Interface){
            case "chart-app":
            case "chart-mlapp-v2":
                data = {
                    'yaml': {
                        params: {version: '@version'},
                        method: "GET",
                        transformResponse: function (data, headersGetter, status) {
                            return {yaml: data};
                        },
                        url: url + '/versions/:version/yaml'
                    },
                    'values_yaml': {
                        params: {version: '@version'},
                        method: "GET",
                        transformResponse: function(data, headersGetter, status) {
                            return {yaml: data};
                        },
                        url: url + '/versions/:version/values/yaml'
                    },
                    'values_yaml_params': {
                        params: {version: '@version'},
                        method: "POST",
                        transformResponse: function(data, headersGetter, status) {
                            return {yaml: data};
                        },
                        url: url + '/versions/:version/values/yaml'
                    },
                    "install" : {
                        params: {version: '@version'},
                        method: "POST",
                        url: url + '/versions/:version/install'
                    },
                };
                break;
            case 'mlmodel':
                data = {
                    'serve': {
                        params: {
                            version: "@version"
                        },
                        url: url + '/versions/:version/serving',
                        method: "POST"
                    }
                };
            case 'dataset':
            case 'inference':
                data = _.extend(data, {
                    tree: {
                        params: {
                            version: '@version',
                            path: '@path'
                        },
                        method: "GET",
                        url: url + '/versions/:version/dir/:path',
                        isArray: true
                    },
                    fileGet: {
                        params: {
                            version: '@version',
                            path: '@path'
                        },
                        method: "GET",
                        // responseType: "arraybuffer",
                        url: url + '/versions/:version/file/:path',
                        transformResponse: function(data, headersGetter, status) {
                            if(status == 200)
                                return {header: headersGetter, file: data};
                            else
                                return angular.fromJson(data);
                        }
                    },
                    fileUpload: {
                        params: {
                            version: '@version',
                            path: '@path'
                        },
                        method: "POST",
                        url: url + '/versions/:version/file/:path',
                        transformRequest: function (data) {
                            var fd = new FormData();
                            angular.forEach(data, function(value, key) {
                                fd.append(key, value);
                            });
                            return fd;
                        },
                        uploadEventHandlers: {
                            progress: prCb
                        },
                        headers: {'Content-Type':undefined, enctype:'multipart/form-data'}
                    },
                    fileDelete: {
                        params: {
                            version: '@version',
                            path: '@path'
                        },
                        method: "DELETE",
                        url: url + '/versions/:version/file/:path'
                    },
                    versionCreate: {
                        method: "POST",
                        url: url + '/versions'
                    },
                    versionClone: {
                        method: "POST",
                        params: {
                            version: '@version',
                            toVersion: '@toVersion'
                        },
                        url: url + '/versions/:version/clone/:toVersion'
                    },
                    versionCommit: {
                        params: {
                            version: '@version'
                        },
                        method: "POST",
                        url: url + '/versions/:version/commit'
                    },

                    versionSave: {
                        params: {
                            version: '@version'
                        },
                        method: "PUT",
                        url: url + '/versions/:version'
                    },
                    'serve': {
                        params: {
                            version: "@version"
                        },
                        url: url + '/versions/:version/start',
                        method: "POST"
                    },
                    fork: {
                        params: {
                            toWsId: '@toWsId',
                            newName: '@newName'
                        },
                        method: "POST",
                        url: url + '/fork/:toWsId?name=:newName'
                        // url: url + '/fork/:toWsId'
                    },
                    forks: {
                        method: "GET",
                        url: url + '/forks',
                        isArray: true
                    }

                });
                break;
        }
        return data;
    }

    getResource(params = {}, clear, prCb = () => {}) {
        if( this._params.wsId != params.wsId || this._params.Interface != params.Interface || !this._resource || clear) {
            // TODO check is copy correct?
            this._params = angular.copy(params);
            // this._params = params;
            var url = this.getResourceUrl(params);
            this._resource = this.$resource(
                url,
                { name: '@Name' },
                _.extend(
                    {},
                    this.cfg.ResourceParams, {
                        'namepickup': {
                            method: "GET",
                            // url: this.getWsUrl(params) + '-namepickup'
                            // url: url + '-namepickup'
                            url: this.cfg.APIPath + ( params.wsId ? '/workspace/' + (params.wsId  ? params.wsId  : ':wsId') + '/' : '/' ) + params.Interface + '-namepickup',
                        },
                        'nameisavailable': {
                            method: "GET",
                            // url: this.getWsUrl(params) + '-nameisavailable'
                            // url: url + '-nameisavailable'
                            url: this.cfg.APIPath + ( params.wsId ? '/workspace/' + (params.wsId  ? params.wsId  : ':wsId') + '/' : '/' ) + params.Interface + '-nameisavailable',
                        },
                        'create' : {
                            method: 'POST',
                            params: {name: '@createName'}
                        },
                        'tag': {
                            url: this.cfg.APIPath + ( params.wsId ? '/workspace/' + (params.wsId  ? params.wsId  : ':wsId') + '/' : '/' ) + 'tag',
                            method: 'GET',
                            isArray: true
                        },

                        'publish': {
                            url: url + '/publish',
                            method: "PUT"
                        },
                        'unpublish': {
                            url: url + '/unpublish',
                            method: "PUT"
                        },

                        'global': {
                            url: url + '/global',
                            method: "PUT"
                        },
                        'unglobal': {
                            url: url + '/unglobal',
                            method: "PUT"
                        },

                        'star' : {
                            method : 'POST',
                            params : {wsId : '@wsId', name : '@Name', Interface: '@Interface'},
                            // url : this.getWsUrl (params)  + '/:name/star'
                            // url : this.getWsUrl (params) + (!params.Interface ? '/:Interface' : '') + '/:name/star'
                            url: this.cfg.APIPath + '/workspace/:wsId/:Interface/:name/star'
                        },
                        'unstar': {
                            method: 'POST',
                            url: url + '/unstar'

                        },
                        'versions': {
                            method: 'GET',
                            isArray: true,
                            url: url + '/versions'
                        },
                        'version_get': {
                            method: 'GET',
                            url: url + '/versions/:version'
                        },
                        'version_delete': {
                            method: 'DELETE',
                            url: url + '/versions/:version'
                        },
                        'version_save': {
                            method: 'PUT',
                            url: url + '/versions/:version'
                        },
                        // 'yaml': {
                        //     params: {version: '@version'},
                        //     method: "GET",
                        //     transformResponse: function(data, headersGetter, status) {
                        //         return {yaml: data};
                        //     },
                        //     url: url + '/versions/:version/yaml'
                        // },
                        // 'update_yaml': {
                        //     params: {version: '@version'},
                        //     method: "PUT",
                        //     url: url + '/versions/:version/yaml'
                        // },

                        'comments': {
                            method: 'GET',
                            isArray: true,
                            url: url + '/comments',
                        },
                        'comments_add': {
                            method: 'POST',
                            url: url + '/comments',
                        },
                        'comments_update': {
                            method: 'PUT',
                            url: url + '/comments/:cID',
                            params: {cID : '@cID'}
                        },
                        'comments_delete': {
                            method: 'DELETE',
                            url: url + '/comments/:cID',
                            params: {cID : '@cID'}
                        },

                        'upload': {
                            method: 'POST',
                            params: {
                                version: "@version"
                            },
                            url: url + '/versions/:version/upload',
                            transformRequest: function (data) {
                                var fd = new FormData();
                                angular.forEach(data, function (value, key) {
                                    fd.append(key, value);
                                });
                                return fd;
                            },
                            uploadEventHandlers: {
                                progress: prCb
                            },
                            headers: {'Content-Type': undefined, enctype: 'multipart/form-data'}

                        },
                        'picture': {
                            method: 'POST',
                            url: url + '/picture',
                            transformRequest: function (data) {
                                var fd = new FormData();
                                angular.forEach(data, function (value, key) {
                                    fd.append(key, value);
                                });
                                return fd;
                            },
                            uploadEventHandlers: {
                                progress: prCb
                            },
                            headers: {'Content-Type': undefined, enctype: 'multipart/form-data'}

                        }
                    },
                    this.resourceAction(url, prCb)
                )
            );
            // this._params = {wsId : params.wsId};
        }
        return this._resource;
    }

    getVersions(data) {
        return this._resource.versions({name: data.Name}).$promise;
    }

    installChart(name, version) {
        this._resource.version_get({name: name, version: version}).$promise.then(
            (r) => {
                this._installChart(r);
            }
        )
    }

    _installChart(data) {
        var then = this;
        this.installError = null;
        this.$mdDialog.show({
            locals: {
                dialog: then.$mdDialog,
                chart : data,
                clusters: null,
                workspace_name: null
            },
            controller: InstallChart,
            controllerAs: '$ctrl',
            template: InstallChartTemplate ,
            parent: angular.element(document.querySelector('#mainBody')),
            // targetEvent: ev,
            clickOutsideToClose: false,
            escapeToClose: false
        }).then(
            (data) => {},
            () => {}
        );
    }

    datasetVersionSize(ds) {
        // debugger;
        // var dsGB = (ds.SizeBytes / ( 1024*1024*1024 )).toFixed(1);
        // var dsMB = (ds.SizeBytes / ( 1024*1024 )).toFixed(1);
        // return dsGB > 1 ? '<span class="value">' + dsGB + '</span><span class="unit">GB</span>' : '<span class="value">' + dsMB + '</span><span class="unit">MB</span>';
        //
        var fixed = 1,
            val = 'GB',
            size = (ds.SizeBytes / ( 1024*1024*1024 ));

        if(size < 1) {
            size = (ds.SizeBytes / ( 1024*1024 ));
            val = 'MB';
            if(size < 1) {
                size = (ds.SizeBytes / ( 1024 ));
                val = 'KB';
                if(size < 1) {
                    size = ds.SizeBytes;
                    val = 'bytes';
                    fixed = 0;
                }
            }
        }
        size = size.toFixed(fixed);
        return '<span class="value">' + size + '</span> <span class="unit">' + val + '</span>';

    }

}
