class controller {
    constructor(ServingService, $state, $scope) {
        this.$state = $state;
        this.ServingService = ServingService;
        $scope.$on('servingChangeStatus',
            () => {
                this.getList();
            }
        );

    }

    $onInit() {

        this.getList();
    }

    getList() {
        let data = this.requestData ? this.requestData : this.$state.params;
        this.apiError = false;
        this.loading = true;
        this.ServingService.getStatus(data).then(
            (r) => {
                this.list = r;
            },
            (err) => {
                this.apiError = err;
            }
        )
            .finally(() => { this.loading = false; })
    }
}
 export const ServingStatusComponent = {
     bindings: {
         requestData : '='
     },
     controller: controller,
     template: ($templateCache) => {
         return $templateCache.get('kuberlab/serving/status/serving_status_component.html');
     }
 }
