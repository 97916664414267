import {ControllerList} from '../../../core/controller/list'
import {ControllerItem} from '../../../core/controller/item'

export class TeamUserListController extends ControllerList {
    constructor (OrganizationsService, $scope, ErrorService) {

        super();
        this.ErrorService = ErrorService;
        this.$scope = $scope;
        var self = this;

        // $scope.$on('updateUserList', function () {
        //     self.userList(true);
        // });
        this.resource = OrganizationsService.users;
        this.list = null;
        this.organizationId;
        this.teamId;
        this.inviteResource = OrganizationsService.invite;
    }

    $onInit(){
        this.userList(true);
    }


    addInvite(){
        this.inviteForm = true;
        this.inviteData = {};
        this.inviteSendError = null;
    }

    cancelInvite() {
        this.inviteForm = false;
    }

    sendInvite(form) {
        if(!form.$valid) return false;
        var self =this;
        this.inviteSendError = false;
        this.saving = true;

        var cb_send_invite = (params = {}) => {
            var dataParams = _.extend({}, this.inviteData, params);
            this.inviteResource.create(
                {orgId: this.organizationId, teamId : this.teamId},
                dataParams //this.inviteData
            )
                .$promise
                .then(
                    function (r) {
                        self.cancelInvite();
                        self.userList(true);
                        // self.$scope.$broadcast('updateUserList');
                    },
                    (err) => {
                        this.ErrorService.error(err, cb_send_invite, {Confirm: true});
                    }
                )
                .finally(function () {
                    self.saving = false;
                });
        };
        cb_send_invite();
    }

    userList (update) {
        var self = this;
        if(!this.list) {
            this.list = [];
            update = true;
        }
        if(update) {
            this.resource.query(
                {orgId : this.organizationId, teamId : this.teamId},
                function(r){
                    self.list = r;
                },

                function (r) {
                    console.error("Organizations team users request error", r);
                }
            );
        }
        return this.list;
    }
}


export class TeamUserController extends ControllerItem {

    constructor (OrganizationsService, $mdDialog, $translate, $scope) {
        super($mdDialog, $translate);
        this.teamId;
        this.organizationId;
        this.$scope = $scope;
    }
    delete () {
        super.delete({orgId : this.organizationId, teamId: this.teamId});
    }
    errorDelete(err){
        this.$scope.$emit('api_error', err);
    }
}

