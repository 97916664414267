export class ProjectEnvService {
    constructor ($resource, $q, KuberlabConfigApi){
        this.cfg = KuberlabConfigApi;
        this.$q = $q;
        this.$resource = $resource;
        this.list = null;
    }

    getResource(params, clear) {
        var resource;
        if(!params.wsId ) {
            throw 'getResource: no wsId';
        }
        if(!params.projectName) {
            throw 'getResource: no projectName';
        }
        if(this._wsId != params.wsId || this._projectName != params.projectName  || !this._resource || clear){
            resource = this.$resource(
                this.cfg.APIPath + '/workspace/' + params.wsId + '/projects/' + params.projectName + '/env/:envName',
                {envName : "@Name"},
                _.extend({}, this.cfg.ResourceParams,
                    {
                        create: {
                            method: "POST",
                            params: {envName: "@envName"}
                        }
                    })
            );

            if(!clear) {
                this._wsId = params.wsId;
                this._projectName = params.projectName;
                this.list = null;
                this.listPromise = null;
                this._resource = resource;
            }
        }
        if (!resource) resource = this._resource;
        return resource;
    }

    getList (update) {
        var update , setlist;
        if(!this.list && !this.listPromise){
            // this.list = [];
            update = true;
        }
        else if (!this.list && this.listPromise) {
            setlist = true;
        }

        if(update || setlist ) {
            var self = this;
            this.getListPromise(update)
                .then(
                    function (r) {
                        self.list = r;
                    }
                )
        }

        return this.list;
    }

    getListPromise (update) {
        if(update) {
            this.listPromise = null;
        }
        if(!this.listPromise) {
            this.listPromise = this._resource.query().$promise;
        }
        return this.listPromise;
    }

    getByName(envName) {
        if(this.list && this.list.length){
            var el = _.findWhere(this.list, { Name: envName});
            if(el){
                return this.$q.resolve(el);
            }
        }
        return this._resource.get({envId : envId}).$promise;
    }

    getNew(data = {}) {
        var pr = new this._resource(data);
        return this.$q.resolve(pr);
    }

    //
    // addProject(project) {
    //     if(this.list) {
    //         if(!_.findWhere(this.list, project)) {
    //             this.list.push(project);
    //         }
    //     }
    // }

}