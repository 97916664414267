import {CoreServiceResource} from '../../core/service/core_service_resource'

export class ProjectTasksService extends CoreServiceResource {
    constructor($resource, $q, KuberlabConfigApi, $rootScope) {
        super(...arguments);
        this.list = [];
    }

    getResource(params) {
        if (!params.wsId || !params.projectName || !params.envName
        // || !params.clusterName
        ) {
            throw "error params";
        }
        if (this.wsId != params.wsId || this.projectName != params.projectName || this.envName != params.envName) {
            // var apiPath = this.cfg.APIPath + '/workspace/' + params.wsId + '/projects/' + params.projectName + '/env/' + params.envName + '/clusters/' + params.clustersName + '/tasks/:tasksName';
            var apiPath = this.cfg.APIPath + '/workspace/' + params.wsId + '/projects/' + params.projectName + '/env/' + params.envName + '/tasks/:taskId';
            // var apiPath = this.cfg.APIPath + '/workspace/' + params.wsId + '/projects/' + params.projectName + '/tasks/:taskId';
            this._resource = this.$resource(
                apiPath,
                {taskId: '@ID'},
                this.cfg.ResourceParams
            );
            this.wsId = params.wsId;
            this.projectName = params.projectName;
            this.envName = params.envName;
            this.listPromise = null;
            // this.clusterName = params.clusterName;
            this.list = [];
        }
        return this._resource;
    }
}
