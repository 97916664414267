class controller {

    constructor(BillingService, $mdDialog, ErrorService){
        this.ErrorService = ErrorService;
        this.BillingService = BillingService;
        this.mdDialog = $mdDialog;
    }

    $onInit(){
        this.cols = this.plans.length + 1;
        this.current = false;
        if(this.currentPlans && this.currentPlans.length){
            this.current = true;
        }

        _.each(this.plans, (v, k) => {
            if(!this.plans[k].Cost) this.plans[k].Cost = angular.copy(this.cost);
        });

        this.limits = {
            Prepaid: this.getTitles('Prepaid'),
            Limits: this.getTitles('Limits'),
            Cost: this.getTitles('Cost')
        };
    }

    sendAdd(item){
        var plan = this.billingController.addPlan(item);
        plan
            .then(
                (r)=>{
                    // debugger;
                },

                (err) => {
                    this.ErrorService.errorDialog(err);
                }
            )
    }

    add(plan) {

        if( $ctrl.freeSelected(item) ) return false;


        var selected = this.selectedPlans[plan.ID];
        var self = this,
            title = selected ? "BILLING_PLAN_UNSELECT_TITLE" : "BILLING_PLAN_SELECT_TITLE",
            text = selected ? "BILLING_PLAN_UNSELECT_MESSAGE" : "BILLING_PLAN_SELECT_MESSAGE";

        this.mdDialog.show(
            {
                locals: {
                    title : selected ? "BILLING_PLAN_UNSELECT_TITLE" : "BILLING_PLAN_SELECT_TITLE",
                    text :  selected ? "BILLING_PLAN_UNSELECT_MESSAGE" : "BILLING_PLAN_SELECT_MESSAGE"
                },
                controller: ($scope, $mdDialog, title, text) => {
                    $scope.title = title;
                    $scope.message = text;
                    $scope.close = () => {
                        $mdDialog.cancel();
                    };
                    $scope.ok = () => {
                        $mdDialog.hide();
                    };
                },
                template: usageTemplate,
                controllerAs: "$ctrl",
                parent: angular.element(document.body),
            }
        ).then(
            () => {
            self.sendAdd(plan);
            }
        );
    }

    getTitles(title) {
        var list = [];
        _.each(this.plans, (p) => {
            list.push( p[title] ? p[title] : {} );
        });
        var listTitles = [];
        _.each(list, (i) => {
            listTitles =  _.union(listTitles, _.keys(i) );
        });
        var labels = {};
        _.each(listTitles, (k) => {
            var l = k.split(':');
            labels[k] = {
                limit: l[0],
                label: l[1] ? l[1].replace(/_/g,' ') : 0
            }
        });
        // debugger
        return {list: list, listTitles : listTitles, labels: labels};
    }

    value(v, l){
        if(v === undefined) return '-';
        if(v < 0) return '*';

        switch(l) {
            case 'cpu_mi':
                var vv = v / 1000;
                return (vv ^ 0) === vv ? vv.toFixed(0) : vv.toFixed(1);
                // return (v/ 1000).toFixed(1);
            case 'gpu_seconds':
            case 'gpu_item_seconds':
            case 'execution_time':
            case 'cpu_seconds':
                var vv = v / 3600;
                return (vv ^ 0) === vv ? vv.toFixed(0) : vv.toFixed(1);
            case 'memory_mb':
                return (v/1024).toFixed(0);
            case 'memory_mb_seconds':
                return (v/( 3600 * 1024)).toFixed(0);
            case 'storage_gb_seconds':
                return (v/86400).toFixed(0);
            default:
                return v;
        }
        return v;
    }


    freeSelected(item) {
        return !Object.keys(this.selectedPlans).length && item.Free;
    }
}

export const BillingPlanList = {
    bindings: {
        plans: '=',
        currentPlans: '=',
        canAdd: '=',
        billingController: '=',
        selectedPlans: '=',
        isAdmin: '=',
        cost: '<'
    },
    controller: controller,
    template: `
        <md-content>
        <table class="cmp-billing-plan md-table">
            <tbody  class="header">
                <tr>
                    <td></td>
                    <td ng-repeat="item in $ctrl.plans" class="planName" ng-class="{selected: ($ctrl.selectedPlans[item.ID] || $ctrl.freeSelected(item))}">
                        <div class="dsp" ng-if="item.DSP"></div>
                        <h4>{{item.Name}}</h4>
                        <div class="price" layout="column" layout-align="center center">
                            <div class="amount"><md-icon md-svg-icon="core:{{item.Currency}}"></md-icon>{{item.Amount * 1}}</div>
                            <div class="currency">per {{item.Interval}}</div>
                            <div class="until" ng-if="item.Until" flex>{{"BILLING_active_until" | translate }} {{ item.Until | date : 'mediumDate' }}</div>
                        </div>
                    </td>
                </tr>
                <tr class="desc">
                    <td class="title"></td>
                    <td ng-repeat="item in $ctrl.plans" ng-class="{selected: $ctrl.selectedPlans[item.ID]}">{{item.Description}}</td>
                </tr>
            </tbody>
            
            <tbody ng-if="$ctrl.limits['Prepaid'].listTitles.length">
                <tr>
                    <th colspan="{{$ctrl.cols}}">Prepaid</th>
                </tr>
                <tr ng-repeat="(t, v) in $ctrl.limits['Prepaid'].labels ">
                    <td class="title">
                        <span>{{'PLAN_LIMIT_NAME_' + v.limit | translate}}</span>
                        <span ng-if="v.label" style="font-size: 80%" translate>{{v.label}}</span>
                        <span class="unit">({{'PLAN_LIMIT_UNIT_' + v.limit | translate}})</span>
                        <help-hint hint="billing.limit.{{v.limit}}"></help-hint>
                    </td>
                    <td ng-repeat="i in $ctrl.limits['Prepaid'].list">{{ $ctrl.value(i[t], v.limit) }}</td>
                </tr>
            </tbody> 
            
            <tbody ng-if="$ctrl.limits['Limits'].listTitles.length">
                <tr>
                    <th colspan="{{$ctrl.cols}}">Limits</th>
                </tr>
                <tr ng-repeat="(t, v) in $ctrl.limits['Limits'].labels ">
                    <td class="title">
                        <span>{{'PLAN_LIMIT_NAME_' + v.limit | translate}}</span>
                        <span ng-if="v.label" style="font-size: 80%" translate>{{v.label}}</span>
                        <span class="unit"  ng-if="v.limit != 'replicas' && v.limit != 'parallel_runs' && v.limit !='gpu' ">({{'PLAN_LIMIT_UNIT_' + v.limit | translate}})</span>
                        <help-hint hint="billing.limit.{{v.limit}}"></help-hint>
                    </td>
                    <td ng-repeat="i in $ctrl.limits['Limits'].list">{{ $ctrl.value(i[t], v.limit) }}</td>
                </tr>
            </tbody> 
            
            <tbody ng-if="$ctrl.limits['Cost'].listTitles.length">
                <tr>
                    <th colspan="{{$ctrl.cols}}">Cost</th>
                </tr>
                <tr ng-repeat="(t, v) in $ctrl.limits['Cost'].labels ">
                    <td class="title">
                        <span>{{'PLAN_LIMIT_NAME_' + v.limit | translate}}</span>
                        <span ng-if="v.label" style="font-size: 80%" translate>{{v.label}}</span>
                        <!--{{$ctrl.cost[v.limit].from}}-->
                        
                        <span ng-if="$ctrl.cost[v.limit].from"> {{$ctrl.cost[v.limit].from}}+ </span>
                        
                        <!--<span ng-if="$ctrl.cost[v.limit].from"> {{$ctrl.cost[v.limit].from}}+ </span>-->
                        <span class="unit">({{'PLAN_LIMIT_UNIT_' + v.limit | translate}})</span>
                        <help-hint hint="billing.limit.{{v.limit}}"></help-hint>
                    </td>
                    <td ng-repeat="i in $ctrl.limits['Cost'].list">&#36;{{ $ctrl.value(i[t].value, v.limit) }}</td>
                </tr>
            </tbody> 
            
            
            <tr>
                <td></td>
                <td ng-repeat="item in $ctrl.plans" ng-class="{selected: ($ctrl.selectedPlans[item.ID] || $ctrl.freeSelected(item)) }" >
                    <div ng-if="(!item.Free || (!$ctrl.selectedPlans[item.ID] && item.Free)) && !$ctrl.freeSelected(item) " class="action click"   ng-click="$ctrl.add(item)">
                        {{ ($ctrl.selectedPlans[item.ID] || $ctrl.freeSelected(item)) ? 'COMMON_SELECTED' : 'COMMON_SUBSCRIBE' | translate }}
                    </div>
                    <div ng-if="(!item.Free || (!$ctrl.selectedPlans[item.ID] && item.Free)) &&  $ctrl.freeSelected(item) " class="action" >
                        {{ 'COMMON_SELECTED' | translate }}
                    </div>
                    <div ng-if="$ctrl.selectedPlans[item.ID] && item.Free" class="action " >{{$ctrl.selectedPlans[item.ID] ? 'COMMON_SELECTED' : 'COMMON_SUBSCRIBE' | translate }}</div>
                </td>
            </tr>
            
           
        </table>
    <md-content>
<!--{{$ctrl.limits}}-->

       <!--<div ng-include="'kuberlab/billing/ws/table-plans.html'"></div>-->

        <!--<div layout="row" layout-align="center start" layout-wrap >-->
            <!--<cmp-billing-plan ng-repeat="item in $ctrl.plans" cost="$ctrl.cost" data="item" can-add="true" list-controller="$ctrl" selected="$ctrl.selectedPlans[item.ID]" ng-if="item.Group || $ctrl.isAdmin" flex="50"></cmp-billing-plan>-->
        <!--</div>-->
    `
}

var usageTemplate = `
<md-dialog >
    <md-dialog-content>
        <div class="md-dialog-content">
            <h3>{{title | translate}}</h3>
            <p>{{message | translate}}</p>
            <div layout="row" layout-align="start center">
                <div flex>
                    <md-input-container style="margin:0;">
                      <md-checkbox ng-model="terms" style="margin:0;" aria-label="I agree to terms">
                        I agree to <a href="https://kuberlab.com/terms" target="_blank">terms</a>
                      </md-checkbox>
                    </md-input-container>
                </div>
                <div style="width : 10px"></div>
                <md-button ng-click="close()">{{'COMMON_CANCEL' | translate}}</md-button>
                <md-button ng-click="ok()" ng-disabled="!terms" class="md-primary">{{'COMMON_OK' | translate}}</md-button>
            </div>
        </div>
    </md-dialog-content>
</md-dialog>
`;
