    class controller {
    constructor($scope) {

    }

    $onInit() {
        // debugger
        if(_.isArray(this.data)){
            this.a = true;
        }
    }
}

export const FormConstructorStreamComponent = {
    bindings: {
        data: '='
    },
    template: ($templateCache) => { return $templateCache.get('kuberlab/core/form-constructor/controls/stream/tpl.html'); },
    controller: controller
};
