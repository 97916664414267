
import {ws_listener} from '../../core/controller/ws_listener'

export class controller extends  ws_listener {
    constructor(projectJobParamSeparator, $scope, WorkspacePermissions, $state){
        super();
        this.state = $state;
        this.permissions = WorkspacePermissions;
        this.projectJobParamSeparator = projectJobParamSeparator;
        this.data = null;
        $scope.Additional = {};
        $scope.$watchCollection(
            () => {return this.data.exec_info},
            () => {
                if(this.data){
                    this.execInfo(this.data.exec_info);
                }
            }
        )
    }

    execInfo(list){
        this.paramsDoc = null;
        this.paramsGlobal = [];
        this.paramsAdvansed = {};
        this.hesAd = false;
        _.each(list, (v, k) => {
            let split = k.split(this.projectJobParamSeparator);
            if (split.length > 1) {
                if( !this.paramsAdvansed[split[0]] ) this.paramsAdvansed[split[0]] = [];
                this.hesAd = true;
                this.paramsAdvansed[split[0]].push({
                    label : split.slice(1).join(this.projectJobParamSeparator),
                    value: this.execInfoValue(v,k, split[0] == '#documents')
                });
            } else {
                this.paramsGlobal.push({
                    label : k,
                    value: this.execInfoValue(v,k)
                });
            }
        });

        if(this.paramsAdvansed['#documents']) {
            this.paramsDoc = this.paramsAdvansed['#documents'];
            delete this.paramsAdvansed['#documents'];
        }
    }

    execInfoValue(v, title, doc) {
        if(!_.isString(v)) return v;
        if(v.indexOf('#/') == 0 || doc) {
            var workspace_name = this.appController.$state.params.wsId,
                project_name = this.appController.$state.params.applicationName,
                task_name = this.data.task_name,
                build_id = this.data.build;
            var url = `/api/v0.2/workspace/${workspace_name}/application/${project_name}/tasks/${task_name}/build/${build_id}/docs/`;
            var link = doc ? url + v : v.slice(2),
            title = doc ? v : v.slice(1);
            return `<a href='${link}' target='_blank'>${title}</a>`
        }
        else {
            return v;
        }
    }

    listTask() {
        return this.listController.getTaskExport();
    }

    openMenu ($mdMenu, ev) {
        $mdMenu.open(ev);
    };

    jobSelect(event) {
        // debugger
        this.listController.initDeleteList(this.data)
    }

    goto (event) {
        event.stopPropagation();
        this.state.go('wsApplication.tab', { tabItem: this.data.name});
    }
}

export const ApplicationJobItemComponent = {
    bindings: {
        data: '=',
        appController: '=',
        listController: '<',
        currentJob: "<"
    },
    controller: controller,
    template: function ($templateCache) {
        return $templateCache.get('kuberlab/applications/jobs/application_job_item.html');
    }
};
