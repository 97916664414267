import {MlmodelListController} from '../mlmodel_list_controller'

class controller extends MlmodelListController {
    // constructor (ChartsService, $scope, $state, $localStorage, $rootScope, UserService, $element, WorkspacePermissions) {
    //     super(...arguments);
    //     // this.limit = 3;
    // }
}

export const MlmodelCardHomeComponent = {
    controller : MlmodelListController,
    template:  ($templateCache) => {
        return $templateCache.get('kuberlab/catalog/base/card/card_home.html');
    }
};
