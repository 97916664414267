export  class ProjectInstanceController {

    constructor (ProjectInstancesService, WorkspacePermissions) {
        this.ProjectInstancesService = ProjectInstancesService;
        this.permissions = WorkspacePermissions;
    }

    data_ca () {
        // debugger;
        return new Date(this.data.created_at);
    }


    action(action) {

        var self = this;
        this.isAction = action;
        this.errorAction = null;
        this.data['$' + action]()
            .then(
                function (r) {
                    self.ProjectInstancesService.getList(true);
                },
                function (err) {
                    self.errorAction = err;
                }
            )
            .finally(function () {
                delete self.isAction;
            });
    }
}


export const ProjectInstanceComponent= {
    bindings: {
      data: '<'
    },
    controller: ProjectInstanceController,
    template: `
        <md-list-item >
            <md-list-item-text flex>
           
                <md-card>
                    <md-card-header>
                        <md-card-avatar>
                        <md-icon class="md-avatar-icon" md-svg-icon="core:instance"></md-icon>
                        </md-card-avatar>
                        <md-card-header-text>
                            <span class="md-title">{{$ctrl.data.name}}</span>
                            <span class="md-subhead">Status: <span class="clusterStatus {{$ctrl.data.status.toLowerCase()}}">{{$ctrl.data.status}}</span></span>
                            <span class="md-subhead">Zone: {{$ctrl.data.zone}}</span>
                        </md-card-header-text>
                        
                        <div class="layout-column layout-align-start-end" style="font-size: 90%;">
                            <div><md-icon md-svg-icon="core:calendar"></md-icon> {{ $ctrl.data_ca() | date:"MM/dd/yyyy 'at' h:mma" }}</div>
                            <div ng-if="$ctrl.data.public_ip">public_ip: {{$ctrl.data.public_ip}}</div>
                            <div ng-if="$ctrl.data.private_ip">private_ip: {{$ctrl.data.private_ip}}</div>
                        </div>
                    </md-card-header>
                    
                    
                    <md-card-content>
                          <cmp-error-toast ng-if="$ctrl.errorAction" error="$ctrl.errorAction"></cmp-error-toast>
                         <table class="clusterVolumes" ng-if="$ctrl.data.volumes && $ctrl.data.volumes.length">
                            <tr> <th style="width: 40%;">name</th><th>interface</th><th>mode</th><th>type</th></tr>
                            <tr ng-repeat="vol in $ctrl.data.volumes">
                                <td>{{vol.name}}</td>
                                <td>{{vol.interface}}</td>
                                <td>{{vol.mode}}</td>
                                <td>{{vol.type}}</td>
                            </tr>
                        </table>
                    </md-card-content>
                    <md-card-actions ng-if="$ctrl.permissions.can('project.manage')">
                        <div flex layout="row" layout-align="start center" >
                            <cmp-error-toast ng-if="$ctrl.errorAction && !$ctrl.isAction"" error="$ctrl.errorAction"></cmp-error-toast>
                            <md-progress-linear ng-if="$ctrl.isAction" md-mode="indeterminate"></md-progress-linear>
                        </div>
                        <div>
                            <md-button ng-disabled="$ctrl.isAction" ng-click="$ctrl.action('start')"><md-icon md-svg-icon="core:play"></md-icon> start</md-button>
                            <md-button ng-disabled="$ctrl.isAction" ng-click="$ctrl.action('stop')"><md-icon md-svg-icon="core:stop"></md-icon> stop</md-button>
                            <md-button ng-disabled="$ctrl.isAction" ng-click="$ctrl.action('delete')"><md-icon md-svg-icon="core:delete"></md-icon> delete</md-button>
                            <md-button ng-disabled="$ctrl.isAction" ng-click="$ctrl.action('reset')"><md-icon md-svg-icon="core:reload"></md-icon> reset</md-button>
                        </div>
                    </md-card-actions>
                  </md-card>
            </md-list-item-text>
        </md-list-item>
        
`
};