export function ProjectItemStatus() {
    function link() {

    }
    return({
        template: `
                <md-button class="projectStatus {{data.Health}}" >
                    <md-icon md-svg-icon="core:status{{data.Health}}" class="status {{data.Health}}" ></md-icon>
                    <span>{{data.ContainersCount}}|{{data.RunningTasks}}</span>
                    <span ng-if="data.GPUUsed">G</span>
                    <md-tooltip>Health: {{data.Health}}. Containers: {{data.ContainersCount}}. <span ng-if="data.RunningTasks">Running task: {{data.RunningTasks}}.</span> <span ng-if="data.GPUUsed">GPU usage: {{data.GPUUsed}}.</span></md-tooltip>
                </md-button>
                `,
        scope: {
            data: '=data'
        },
        link: link,
        restrict: "E"
    });
}
