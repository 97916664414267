class controller {
    constructor( $resource, KuberlabConfigApi, $state, UserService, validate ) {
        this.KuberlabConfigApi = KuberlabConfigApi;
        this.$resource = $resource;
        this.$state = $state;
        this.UserService = UserService;
        this.validate = validate;
    }

    $onInit() {
        this.url = this.KuberlabConfigApi.APIPath + '/' + this.$state.params.note + '/token/' + this.token;
        this.resource = this.$resource(this.url, {},
            _.extend(
                {},
                this.KuberlabConfigApi.ResourceParams,
                {
                    confirm: {
                        method: "POST"
                    }
                }
            )
        );

        // this.tokenInfo = {
        //     "Type": "email",
        //     "OldEmail": "monstarnn@gmail.com",
        //     "NewEmail": "monstarnn@mail.ru"
        // };
        //
        // this.tokenInfo = {
        //     "Type": "register",
        //     "DisplayName": "user's name",
        //     "Organization": "",
        //     "Phone": "",
        //     "Login": "user-s-name",
        //     "Email": "user-s@name.mail",
        //     "CreateGitlabAccount": false,
        //     "Confirmed": false,
        //     "AgreeToTerms": true
        // };
        //
        // this.confirmInfo = {"Action": "confirm-success"};

        this.resource.get()
            .$promise
            .then(
                (r) => {
                    this.tokenInfo = r;
                    if(this.tokenInfo.Type == 'register') {
                        this.confirm();
                    }
                },
                (err) => {
                    this.error = err;
                }
            )
    }

    confirm() {
        this.loading = true;
        this.error = null;
        this.resource.confirm()
            .$promise
            .then(
                (r) => {
                    if(this.tokenInfo.Type == 'register'){
                        this.confirmInfo = r;
                    }
                    else if ( this.tokenInfo.Type == 'password' ) {
                        this.createPass = true;
                    }
                    else {
                        this.$state.go('home');
                    }
                },
                (err) => {
                    this.error = err;
                    this.loading = false;
                }
            )
            .finally(() => {
                this.loading = false;
            }
        )
    }

    createPassAction(form) {
        if (!form.$valid) return;
        this.loading = true;
        this.apiPassError = false;
        this.UserService.getResource().password({NewPassword: this.newPass.Password}).$promise.then(
            (r) => {
                window.location.href = '/';
            },
            (err) => {
                this.apiPassError = err
            }
        )
            .finally(() => {
                this.loading = false;
            })
    }
}

export const NoteTokenInfoComponent = {
    controller: controller,
    bindings:{
        token: '<'
    },
    template: `
        <!--<home-card-welcom-content ng-if="$ctrl.confirmInfo">-->
        <!--<div class="main_width_greed">-->
        <home-card-welcom-content >
            <h2><cmp-logo></cmp-logo></h2>
            <div class="content" ng-switch="$ctrl.tokenInfo.Type">
                <div ng-switch-when="password" >{{"NOTE_RESTORE_PASSWORD" | translate }}</div>
                <div ng-switch-when="email" >{{ $ctrl.tokenInfo.OldEmail ? "NOTE_RESTORE_EMAIL" : "NOTE_RESTORE_EMAIL_NEW" | translate:$ctrl.tokenInfo}}</div>
                <div ng-switch-when="register">
                    <div ng-if="$ctrl.confirmInfo.Action == 'confirm-success' ">You’ve just confirmed your email, now you can login.</div>
                    <div ng-if="$ctrl.confirmInfo.Action == 'wait-admin' ">Your email has been confirmed. Wait for administrator will accept your registration. Thank you!</div>
                </div>
            </div>
            <cmp-error-toast ng-if="$ctrl.error" error="$ctrl.error" style="font-size:18px;"></cmp-error-toast>
        </home-card-welcom-content>
        <home-card-welcom-actions >
            <md-progress-linear ng-if="$ctrl.loading"></md-progress-linear>
            <div ng-if="!$ctrl.createPass && $ctrl.tokenInfo.Type == 'password'  || $ctrl.tokenInfo.Type == 'email' ">
                <md-button ng-if="!$ctrl.loading" ng-click="$ctrl.confirm()" class="md-raised md-primary">{{"COMMON_CONFIRM" | translate}}</md-button>
            </div>
            <div ng-if="$ctrl.tokenInfo.Type == 'register' && $ctrl.confirmInfo.Action == 'confirm-success'"><md-button ui-sref="home" class="md-raised md-primary">Sign In</md-button></div>
            <md-card ng-if="$ctrl.tokenInfo.Type == 'password' && $ctrl.createPass" >
                <md-card-content>
                <form ng-submit="$ctrl.createPassAction(createPass)" name="createPass" layout="column" layout-align="center center">
                
                 <md-input-container flex>
                      <label>New password</label>
                      <input
                          type="password"
                          name="password"
                          ng-model="$ctrl.newPass.Password"
                          required
                          ng-pattern="$ctrl.validate.passwordPattern"
                      >
                      <div ng-messages="createPass.password.$error" ng-messages-multiple="ng-messages-multiple"
                           class="error-messages slide-right">
                        <div ng-message="required" class="message slide-left">You did not enter a field name</div>
                        <div ng-message="pattern" translate>FORM_ERROR_PASS_PATTERN</div>
                      </div>
                    </md-input-container>
                    <md-input-container flex>
                      <label>Confirm password</label>
                      <input
                          match-password="password"
                          name="confirmPassword"
                          ng-model="$ctrl.newPass.confirm"
                          type="password"
                          required>
                      <div ng-messages="createPass.confirmPassword.$error" ng-messages-multiple="ng-messages-multiple"
                           class="error-messages slide-right">
                        <div ng-message="required" class="message slide-left">You did not enter a field name</div>
                        <div ng-message="passwordMatch" class="message slide-left">Your passwords did not match</div>
                      </div>
                    </md-input-container>
                    <cmp-error-toast ng-if="$ctrl.apiPassError" error="$ctrl.apiPassError" ></cmp-error-toast>
                    <md-button type="submit" class="md-raised md-primary" >{{"COMMON_OK" | translate }}</md-button>
                </form>
            </md-card-content>
            </md-card>
        </home-card-welcom-actions>
        <!--</div>-->
    `
}