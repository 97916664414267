export class ChartsItemChartNewController {
    constructor (catalog_data, $state, $window, ChartsService, $rootScope, $mdDialog, validate, catalog_dataResource) {
        this.validate = validate;
        $rootScope.addButtonConfig = null;
        this.$state = $state;
        this.data = catalog_data;
        this.$window = $window;
        this.ChartsService = ChartsService;
        this.delayNameCheck;
        this.saving = false;
        this.scanAction = false;
        this.catalog_dataResource = catalog_dataResource;
        this.$mdDialog = $mdDialog;
    }

    checkName (form) {
        if(!form.$valid){return false;}
        if(this.delayNameCheck) {
            clearTimeout(this.delayNameCheck);
            delete this.delayNameCheck;
        }
        if(this.data.Name == "") {
            form.Name.$setValidity("errorname", true);
            return;
        }
        var self = this;
        this.delayNameCheck = _.delay(function(){
            // self.ChartsService.getResource({}).isavailable({wsId: self.$state.params.wsId, name: self.data.Name})
            self.catalog_dataResource.nameisavailable({ name: self.data.Name})
                .$promise.then(
                function(resp) {
                    form.Name.$setValidity("errorname", true);
                }, function(err) {
                    self.nameErrorMessage = err;
                    form.Name.$setValidity("errorname", false);
                }
            );
        }, 1000);
    }

    canSave(form) {
        return !this.saving && this.data.RepositoryUrl && form.$valid
    }

    save (form, ev) {
        if( !this.canSave(form) ) { return false; }
        this.saving = true;
        this.savingError = false;
        var self = this;
        var promise = this.scanAction ? this.scan() : this._save();
        promise
            .then(
                function (r) {
                    if(_.isArray(r)){
                        self.$mdDialog.show({
                            locals: {list: r, dialog: self.$mdDialog},
                            controller: function(list , $scope, dialog) {
                                this.list = list;
                                $scope.dialog = dialog;
                                $scope.go = function (name) {
                                    this.dialog.hide(name);
                                };
                                $scope.cancel = function () {
                                    this.dialog.cancel();
                                };
                            },
                            template: `
                                <md-dialog aria-label="Mango (Fruit)">
                                    <md-toolbar class="md-raised md-primary" >
                                      <div class="md-toolbar-tools">
                                        <h2>Charts</h2>
                                        <span flex></span>
                                        <md-button class="md-icon-button" ng-click="cancel()">
                                          <md-icon md-svg-src="core:close" aria-label="Close dialog"></md-icon>
                                        </md-button>
                                      </div>
                                    </md-toolbar>
                                
                                    <md-dialog-content>
                                      <div class="md-dialog-content">
                                        <md-list>
                                            <md-list-item ng-repeat="item in $ctrl.list" class="secondary-button-padding">
                                                <img alt="{{ item.Chart.DisplayName }}" ng-src="{{ item.Chart.Picture }}" class="md-avatar" />
                                                <div class="md-list-item-text">
                                                    <h4>{{item.Chart.DisplayName}}</h4>
                                                    <p ng-if="item.Error" class="md-warn">{{item.Error}}</p>
                                                </div>
                                                <md-button ng-if="item.Error" class="md-secondary md-warn" ng-click="go(item.Chart.Name)" ng-disabled="true">Error</md-button>
                                                <md-button ng-if="item.Success" class="md-raised md-primary" ng-click="go(item.Chart.Name)" >Added</md-button>
                                            </md-list-item>
                                        </md-list>
                                       </div>
                                   </md-dialog-content>
                                   <md-dialog-actions layout="row">
                                      <span flex></span>
                                      <md-button ng-click="go()">
                                        to chart list
                                      </md-button>
                                    </md-dialog-actions>
                                </md-dialog>
                            `,
                            controllerAs : '$ctrl',
                            parent: angular.element(document.body),
                            targetEvent: ev,
                            clickOutsideToClose:true
                        })
                            .then(function(name) {
                                if(!name) {
                                    self.$state.go('wsChartsList', self.$state.params);
                                } else {
                                    self.$state.go('wsChartItem', _.extend({}, self.$state.params, {chartName : name}));
                                }
                            }, function() {
                                // $scope.status = 'You cancelled the dialog.';
                            });

                    }else {


                        self.$state.go('wsCatalog.list.item', {Interface: r.Interface, item: r.Name});

                        // self.$state.go('wsChartItem', {chartName : r.Name});
                        // self.savingError = false;
                    }
                },
                function (err) {
                    self.savingError = err;
                }
            )
            .finally(
                function() {
                    // self.savingError = false;
                    self.saving = false;
                }
            );
    }

    _save() {
        return this.data.$create();
    }

    scan () {
        return this.ChartsService.getScanResource(this.$state.params).scan(this.data)
            .$promise;
    }
    cancel() {
        this.$window.history.back();
    }
}

