
export default function ($stateProvider) {
    $stateProvider

        .state('billing',{
            parent: 'content',
            url : '/billing',
            views: {
                '' : {
                    template : `
                        <cmp-billing-card></cmp-billing-card>
                    `
                }
            }
        })

}
