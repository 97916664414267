import {ProjectApplicationService} from './project_application_service'
import {ProjectApplicationController} from './project_application_controller'

export default angular
    .module('projectApplications', [
    ])
    .service('ProjectApplicationService', ProjectApplicationService)
    .component('cmpProjectsApplications', {
        controller: ProjectApplicationController,

        template: function ($templateCache) {
            return $templateCache.get('kuberlab/projects/applications/project_applications.html');
        },
    })
