import {ProjectClusterBaseController} from '../project_cluster_base_controller'

export  class ProjectClusterInstanceController extends ProjectClusterBaseController{
    constructor($state, $scope, WorkspaceService, ProjectClusterService, $mdDialog, $translate, ProjectInstancesService, autoUpdate, AuthService, WorkspacePermissions) {
        super( $state,  $scope, WorkspaceService,  ProjectClusterService, $mdDialog, $translate, WorkspacePermissions );
        this.service = ProjectInstancesService;
        this.autoUpdate = autoUpdate;
        this.AuthService = AuthService;
    }

    getFullInfo(update){
        if(!this.instancesList) {
            update = true;
        }
        if(this.isDisabled()) {}
        else {
            if (update) {
                var self = this;
                this.service.getResource(_.extend({}, this.$state.params, {clusterName: this.data.Name}));
                this.instancesListState = false;
                this.loadingError = null;
                this.service.getList(update)
                    .then(
                        function (r) {
                            self.instancesList = r;
                        },
                        function (err) {
                            self.loadingError = err;
                        }
                    )
                    .finally(
                        function () {
                            self.instancesListState = null;
                            if (self.timeout) {
                                clearTimeout(self.timeout)
                            }
                            self.timeout = _.delay(_.bind(self.getFullInfo, self), self.autoUpdate, true);
                        }
                    )
            }
        }

        return this.instancesList;
    }

    $onDestroy() {
        if(this.timeout) {
            clearTimeout(this.timeout);
        }
    }
}


export const ProjectClusterInstanceComponent= {
    bindings: {
      data: '<'
    },
    controller: ProjectClusterInstanceController,
    template: `
          
        <div ng-include=" 'kuberlab/projects/card/project_card_title_list.html' "></div>
        
        <div ng-if="$ctrl.isActive" class="paddingListItemTabContent fullInfo">
            <md-list >
                <md-progress-linear md-mode="indeterminate" ng-if="$ctrl.instancesListState.Loading"></md-progress-linear>
                <!--<md-list-item ng-if="$ctrl.instancesListState.Loading"></md-list-item>-->
                <cmp-api-error-service ng-if="$ctrl.loadingError" error="$ctrl.loadingError" format="'text'"></cmp-api-error-service>
                <div ng-if="$ctrl.isDisabled() ">
                    <p translate>{{'CLUSTER_NOT_WORKING'}}</p>
                </div>
                <cmp-project-instance ng-repeat="item in $ctrl.instancesList | orderBy : 'name' " data="item"></cmp-project-instance>
            </md-list>
        </div>
        `
};