
class controller {
    constructor ($state, $scope) {

        $scope.$watch(
            () => this.data,
            () => {
                this.Form = this.data.InferenceForm
            }
        )

        // this.resourceUrl = '/workspace/' + $state.params.wsId + '/mlmodels/' + $state.params.mlmodelName + '/readme';
    }

    $onInit () {
        // debugger
        // this.values = jsyaml.dump(this.data.InferenceValues);
    }

    save() {
        this.loading = true;
        this.error = null;
        this.data.$update().then(
            r => {
                this.data = r;
            },
            err => {
                this.error = err;
            }
        ).finally( f => {
            this.loading = false;
        })
    }
}


export const ServingInferenceComponent = {
    bindings: {
        data: '<'
        // readmeUrl: '<',
        // readOnly: '<'
    },
    controller : controller,
    template: `
        <p>Serving inference: <a ui-sref="wsCatalog.list.item.tab({wsId: $ctrl.data.InferenceWorkspaceName, Interface: 'inference', item: $ctrl.data.InferenceName, tab:'versions', tabItem:  $ctrl.data.InferenceVersion})" >{{$ctrl.data.InferenceWorkspaceDisplayName}}/{{$ctrl.data.InferenceName}}:{{$ctrl.data.InferenceVersion}}</a></p>
        <form ng-submit="$ctrl.save()">
        
            <cmp-form-constructor
                            ng-model="$ctrl.data.InferenceValues"
                            api="$ctrl.apiForm"
                            options="$ctrl.Form"
                            cluster-id="$ctrl.data.ClusterID"
                            workspace="$ctrl.data.WorkspaceName"
                            app-volumes="$ctrl.appController.data.Configuration.spec.volumes"
                    ></cmp-form-constructor >
            <div flex layout="row" layout-align="start-center" style="min-height: fit-content;">
                <div flex>
                    <md-progress-linear ng-if="$ctrl.loading"></md-progress-linear>
                    <cmp-error-toast ng-if="$ctrl.error" error="$ctrl.error"></cmp-error-toast>
                </div>
                <div>
                    <!--<md-button ng-click="cancel()">-->
                        <!--{{"COMMON_CANCEL" | translate}}-->
                    <!--</md-button>-->
                    <md-button type="submit">
                        {{"COMMON_SAVE" | translate}}
                    </md-button>
                </div>        
            </div>
            
        </form>
        <!--<div ui-ace="{mode: 'yaml', onLoad: aceLoaded}" readonly ng-model="$ctrl.values"></div>-->
        
        <!---->
        <!--InferenceVersion: "1.0.0"-->
<!--InferenceWorkspaceName: "ilja-org"-->
<!--InferenceName: "just-for-test"-->
    `
};
