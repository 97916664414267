
import {ProjectInstancesService} from './project_instances_service'
import {ProjectClusterInstancesComponent} from './project_cluster_instances_controller'
import {ProjectClusterInstanceComponent} from './project_cluster_instance_controller'
import {ProjectInstanceComponent} from  './project_instance_controller'


export default angular
    .module('projectInstance' , [])
    .service('ProjectInstancesService', ProjectInstancesService)
    .component('cmpProjectClusterInstances', ProjectClusterInstancesComponent)
    .component('cmpProjectClusterInstance', ProjectClusterInstanceComponent)
    .component('cmpProjectInstance', ProjectInstanceComponent)