class controller {
    constructor($scope, StorageService, CatalogService) {
        this.$scope = $scope;
        this.CatalogService = CatalogService;
        this.StorageService = StorageService;
        this.types = ['gitRepo'];
        this.loading = {};
    }

    $onInit() {
        if(!this.revision) this.revision = [];
        this.$scope.$watchCollection(
            () => {return this.volumes},
            (c) => {
                this.initList();
            }
        );

        this.$scope.$watchCollection(
            () => {return this.defaultVolumeMapping},
            (c) => {
                this.initList();
            }
        );

        this.$scope.$watchCollection(
            () => {return this.revision},
            (c) => {
                this.initList();
            }
        );

        this.$scope.$watchCollection(
            () => {return  this.application.Configuration.spec.volumes},
            (c) => {
                this.initList();
            }
        );
    }

    initList() {
        this.list = [];
        var list = this.defaultVolumeMapping ? this.application.Configuration.spec.volumes : this.volumes;
        if(list && list.length    ){
            _.each(list, (v) => {
                var storage = _.findWhere(this.application.Configuration.spec.volumes, {name: v.name});
                var type = this.StorageService.getType(storage);
                if(storage && ( _.indexOf(this.types, type ) != -1)){
                    var revision = _.findWhere(this.revision, {volumeName: v.name}) ;
                    if(!revision && (type == 'dataset' || type == 'datasetFS' || type == 'model')) {
                        revision = { revision: storage[type].version};
                    } else if(!revision && type == 'gitRepo') {
                        revision = { revision: storage[type].revision };
                    }
                    if(type == 'dataset' || type == 'datasetFS' || type == 'model'){
                        this.getDatasetVersion(v.name, storage[type], type );
                    }
                    this.list.push({storage: storage, type: type, volume: v, revision: revision ? revision.revision : ''});
                }
            }, this);

            if(this.list && this.list.length){
                this.has = true;
            }
        }
    }

    chengeRevision(name, revision) {
        var rev = _.findWhere(this.revision, {volumeName: name}) ;
        var index = -1;
        if(rev) index = _.indexOf(this.revision, rev);
        if(revision) {
            if( index != -1 ) this.revision[index].revision = revision;
            else {
                if(!this.revision) this.revision = [];
                this.revision.push({volumeName: name, revision: revision});
            }
        }else {
            if(index != -1) {
                this.revision = _.without(this.revision, rev);
            }
            // if(this.revision.length == 0) {
            //     this.revision = null;
            // }
        }

    }

    getDatasetVersion (name, data, type, update) {
    var self = this;
        if( !this.versions ){
            this.versions = {};
        }
        this.loading[name] = true;
        var link = data.workspace + '/' + data.dataset;
        if(!this.versions[name] || this.versions[name].__datalink !== link || update ){
            this.CatalogService.getResource({wsId: data.workspace, Interface: type=='model' ? 'mlmodel' : 'dataset'});
            this.CatalogService.getVersions({Name: type=='model' ? data.model : data.dataset, wsId: data.workspace})
                .then(
                    (r) => {
                        // self.versions[name] = _.map(r, (v)=> { return v.Version; });
                        self.versions[name] = r;
                        self.versions[name].__datalink = link;
                    },
                    (err) => { debugger; }
                )
                .finally( () => {
                    this.loading[name] = null;
                })
        }
    }

    openList (item) {
        // this.getDatasetVersion(item.volume.name, item.volume[item.type], item.type, true)
        var data = item.storage ? item.storage[item.type] : item.volume[item.type];
        this.getDatasetVersion(item.volume.name, data, item.type, true)
    }
}

export const ApplicationsTaskVolumesGitComponent = {
    bindings : {
        volumes: '<',
        application: '<',
        revision: "=",
        defaultVolumeMapping:'<',
        types: '<',
        has: '='
    },
    controller: controller,
    template: `
        <md-list-item ng-repeat="item in $ctrl.list" layout="row" layout-align="start center">
            <md-icon md-svg-icon="core:{{item.type}}" class="md-avatar-icon"></md-icon>
            
            <div flex="20">{{item.volume.name}}</div>
            <div style="width:10px"></div>
            <div flex layout="row" layout-align="start center" ng-if="$ctrl.StorageService.getType(item.storage) == 'gitRepo'">
                <div flex style="text-overflow: ellipsis; overflow: hidden;">{{item.storage.gitRepo.repository}}</div>
                <div style="width:10px"></div>
                <md-input-container flex="33">
                    <label>Revision</label>
                    <input type="text" ng-model="item.revision" ng-model-options="{ debounce: 1000 }" ng-change="$ctrl.chengeRevision(item.volume.name, item.revision)">
                </md-input-container>
            </div>
            <div  flex layout="row" layout-align="start center" ng-if="$ctrl.StorageService.getType(item.storage) == 'dataset' " >
                <div flex style="text-overflow: ellipsis; overflow: hidden;">{{item.storage.dataset.workspace}}/{{item.storage.dataset.dataset}}</div>
                <div style="width:10px"></div>
                <md-input-container flex="33">
                    <label>Version</label>
                    <md-select ng-model="item.revision" ng-model-options="{ debounce: 1000 }" ng-change="$ctrl.chengeRevision(item.volume.name, item.revision)" ng-click="$ctrl.openList(item)" >
                        <md-option ng-if="$ctrl.loading[item.valume.name]"><md-progress-linear></md-progress-linear></md-option>
                        <md-option 
                            ng-if="!$ctrl.loading[item.valume.name]" 
                            ng-repeat="v in $ctrl.versions[item.volume.name]" 
                            ng-value="v.Version"
                        >{{v.Version}} (<span ng-bind-html="$ctrl.CatalogService.datasetVersionSize(v)"></span>)</md-option>
                    </md-select>
                </md-input-container>
            </div>
            <div flex layout="row" layout-align="start center" ng-if="$ctrl.StorageService.getType(item.storage) == 'datasetFS' " >
                <div flex style="text-overflow: ellipsis; overflow: hidden;">{{item.storage.datasetFS.workspace}}/{{item.storage.datasetFS.dataset}}</div>
                <div style="width:10px"></div>
                <md-input-container flex="33">
                    <label>Version</label>
                    <md-select ng-model="item.revision"  ng-change="$ctrl.chengeRevision(item.volume.name, item.revision)"  md-on-open="$ctrl.openList(item)">
                        <md-option ng-if="$ctrl.loading[item.valume.name]"><md-progress-linear></md-progress-linear></md-option>
                        <md-option ng-if="!$ctrl.loading[item.valume.name]"  ng-repeat="v in $ctrl.versions[item.volume.name]" ng-value="v.Version">{{v.Version}} (<span ng-bind-html="$ctrl.CatalogService.datasetVersionSize(v)"></span>)</md-option>
                    </md-select>
                </md-input-container>
            </div>
            <div flex layout="row" layout-align="start center" ng-if="$ctrl.StorageService.getType(item.storage) == 'model' " >
                <div flex style="text-overflow: ellipsis; overflow: hidden;">{{item.storage.model.workspace}}/{{item.storage.model.model}}</div>
                <div style="width:10px"></div>
                <md-input-container flex="33">
                    <label>Version</label>
                    <md-select ng-model="item.revision" ng-model-options="{ debounce: 1000 }" ng-change="$ctrl.chengeRevision(item.volume.name, item.revision)" ng-click="$ctrl.openList(item)">
                        <md-option ng-repeat="v in $ctrl.versions[item.volume.name]" ng-value="v.Version">{{v.Version}} (<span ng-bind-html="$ctrl.CatalogService.datasetVersionSize(v)"></span>)</md-option>
                    </md-select>
                </md-input-container>
            </div>
        </md-list-item>
    `
}
