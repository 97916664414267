
import {SettingsController} from './settings_controller'
import {speedScroll} from '../kuberlab_config'


export const settngsBaseStateConfig = {
    url : '/settings_old',
    resolve : {
        currentUser : function ( CurrentUser ) {
            return CurrentUser;
        },

        orgsList : function ( OrganizationsService, currentUser ) {
            if(currentUser)
                return OrganizationsService.requestOrgList();
            else
                return [];
        }
    },
    onEnter: function () {
        $('html, body').animate({
            scrollTop: 0
        }, speedScroll);
    },
    views : {
        "" : {
            templateProvider: function ($templateCache) {
                return $templateCache.get('kuberlab/settings/settings.html');
            },
            controller: SettingsController,
            controllerAs: '$ctrl'
        }
    }
};



export const settingPageConfig = {
    url: '/setting_new/:org/:tab',
    // abstract : true,
    parent: 'content',
    data : {
        org: 'my'
    },
    view: {
        "": {
            templateProvider: ($templateCache) => {
                return $templateCache.get('kuberlab/settings/settings.html');
            },
            controller: function($stateParams){
                debugger;
            },
            controllerAs: '$ctrl'
        }
    }
};

export default function ($stateProvider, $urlRouterProvider) {
    $stateProvider

        // .state("settingOrg", {
        //     parent : "settingsBase",
        //     url: '/:org/:tab',
        //     controller: function () {
        //         debugger;
        //     }
        // })

        .state('settingsCurrentUser', {
            parent: 'settings',
            url: "/my",
            onEnter: function (SettingsService) {
                SettingsService.stateParams = {cu : true};
                $('#main-content').animate({
                    scrollTop: 0
                }, 2000);
            }
        })

        .state('settingsOrg', {
            parent: 'settings',
            url: "/org/:orgId",
            resolve : {
                orgList : function (OrganizationsService)  {
                    return OrganizationsService.requestOrgList();
                }
            },
            onEnter: function ($timeout, $stateParams, SettingsService) {
                SettingsService.stateParams = $stateParams;
                $timeout(function goto () {
                    // $('html, body').animate({
                    var header_heaght = $('#header').height();
                    $('#main-content').animate({
                        scrollTop: ($('#settnig_Org_' + $stateParams.orgId).offset().top - header_heaght)
                    }, speedScroll);
                }, 0);
            },
        })

        .state('settingsOrgAdd', {
            parent: 'settings',
            url: '/neworg',
            onEnter: function ($timeout, $stateParams, SettingsService) {
                SettingsService.stateParams = $stateParams;
                $timeout(function goto () {
                    var header_heaght = $('#header').height();
                    $('#main-content').animate({
                        scrollTop: ($('#settings_newOrganization').offset().top - header_heaght)
                    }, speedScroll);
                }, 0);
            },
            views: {
                'new_organization': {
                    template: `
                        <h4>New organization</h4>
                        <cmp-organization-card-settings data="{}"></cmp-organization-card-settings>
                        `,

                }
            }
        })
}
