import {UserCardController} from '../card/user_card_controller'

class controller extends UserCardController {
    constructor($scope, CurrentUser, AuthService, UserService, $state) {
        super(...arguments);
        this.$state = $state;
    }

    $onInit() {
        this.WsID = this.$state.params.wsId;
    }

    selectWs () {
        this.sMenu = true;
    }

    goto() {
        let wsId = this.currentUser.Workspace == this.$state.params.wsId ? 'my' : this.$state.params.wsId;
        this.$state.go('setting', {wsId: wsId});
    }

}

export const UserProjectMenuComponent = {
    controller: controller,
    template: `
    
        <!--<cmp-notify-list></cmp-notify-list>-->
        
        <div layout="column">
            <div class="userCard">          
                <img ng-src="{{$ctrl.currentUser.Picture}}" class="icon" alt="{{$ctrl.userData.DisplayName}}"/>
                <div class="name">{{$ctrl.currentUser.DisplayName}}</div>
                <div layout="row" layout-align="center" class="options">
                    <!--<md-button class="md-icon-button test-tooltip" ui-sref="setting({ wsId: $ctrl.WsID })">-->
                    <md-button class="md-icon-button test-tooltip" ng-click="$ctrl.goto()">
                        <md-tooltip md-direction="bottom">settings</md-tooltip>
                        <md-icon md-svg-src="core:settings"></md-icon>
                     </md-button>
                    <md-button class="md-icon-button test-tooltip" ng-click="$ctrl.AuthService.logout()">
                        <md-tooltip md-direction="bottom">logout</md-tooltip>
                        <md-icon md-svg-src="core:exit"></md-icon>
                     </md-button>
                </div>
            </div>
            <md-divider></md-divider>
            <!--<div class="blockMenu" layout="row" layout-align="start">-->
                <!--<md-menu md-position-mode="target-right target" style="width: 100%;">-->
                    <!--<div class="blockMenuItem" flex layout="row" ng-click="$mdMenu.open()" ><md-icon md-svg-icon="core:wss"></md-icon><span class="label">Workspaces <md-icon md-menu-origin md-svg-icon="core:menu-down"></md-icon></span></div>-->
                    <!--<md-menu-content width="4" class="userWsList" >-->
                        <!--<md-menu-item  ng-repeat="data in $ctrl.ws  | orderBy:['Type','DisplayName'] " class="userWsListItem">-->
                            <!--<a ui-sref="workspace({wsId : data.Name})">-->
                                <!--<img ng-src="{{data.Picture}}" class="orgIcon" ng-if="data.Picture"/>-->
                                <!--<md-icon md-svg-icon="core:org" ng-if="!data.Picture"></md-icon>-->
                                <!--<span class="menuLevelItemLabel">{{data.DisplayName}}</span>-->
                            <!--</a>-->
                        <!--</md-menu-item>-->
                    <!--</md-menu-content>-->
                <!--</md-menu>-->
            <!--</div>-->
            <cmp-workspace-main-menu ></cmp-workspace-main-menu>
            <!--<div ui-view="menuLevel2"</div>-->
        </div>
    `
};
