import {ProjectClusterBaseController} from '../project_cluster_base_controller';

class controller extends ProjectClusterBaseController {
    constructor($state, $scope, WorkspaceService, ProjectClusterService, $mdDialog, $translate, AuthService) {
        super($state, $scope, WorkspaceService, ProjectClusterService, $mdDialog, $translate);
        this.AuthService = AuthService;
        this.isLoading = false;
    }

    $onInit() {
        this.addCluster();
    }

    addCluster(data = {}) {
        delete this.newClusterError;
        var self = this;
        this.getWsaList();
        this.service.new(data)
            .then(
                function (r) {
                    self.newCluster = r;
                }
            )
    }

    saveNewCluster (form) {
        if(!form.$valid) return false;

        delete this.newClusterError;
        this.saving = true;
        var self = this;

        this.newCluster
            .$create()
            .then(
                function (r) {
                    self.service.add(self.newCluster);
                    self.$state.go(self.$state.$current.name, {tabItem: r.Name});
                },
                function (err) {
                    // debugger;
                    self.newClusterError = err;
                }
            )
            .finally(function(){
                self.saving = false;
            })
    }

    cancelNewCluster () {
        this.$state.go(this.$state.$current.name, {tabItem: null});
    }

    checkName (form) {
        var self = this;
        this.service._resource.clusterscheckname({name: this.newCluster.Name})
            .$promise
            .then(
                function(resp) {
                    form.Name.$setValidity("errorname", true);
                },
                function (err) {
                    self.nameErrorMessage = err;
                    form.Name.$setValidity("errorname", false);
                }
            );
    }

    checkSrc(form) {
        var self = this;
        self.srcErrorMessage = false;
        if(this.newCluster.Src){
            this.service._resource.clusterschecksrc({src: this.newCluster.Src})
                .$promise
                .then(
                    function(resp) {
                        form.Src.$setValidity("errorname", true);
                    },
                    function (err) {
                        self.srcErrorMessage = err;
                        form.Src.$setValidity("errorname", false);
                    }
                );
        } else {
            form.Src.$setValidity("errorname", true);
        }
    }
}

export const ProjectClusterNewComponent = {
    bindings: {
        service: '<'
    },
    controller: controller,
    template: `
        <form ng-if="$ctrl.newCluster" name="newCluster" ng-submit="$ctrl.saveNewCluster(newCluster)" >
        <md-card>
            <md-card-content>
                    <div flex layout="row">
                        <md-input-container flex="50">
                            <label>Cluster's name</label>
                            <input type="text" 
                                    ng-model="$ctrl.newCluster.Name" 
                                    name="Name" 
                                    required
                                    minlength="2"  
                                    ng-model-options='{ debounce: 1000 }'
                                    ng-change="$ctrl.checkName(newCluster)"
                                >
                            <help-hint hint="cluster.name"></help-hint>
                            <div ng-messages="newCluster.Name.$error">
                                <div ng-message="errorname"><cmp-error-toast ng-if="$ctrl.nameErrorMessage" error="$ctrl.nameErrorMessage"></cmp-error-toast></div>
                                <!--<div ng-message="minlength">App's name is too short</div>-->
                            </div>
                        </md-input-container>
                        <md-input-container flex="50">
                            <label>Service account</label>
                            <md-select ng-model="$ctrl.newCluster.ServiceAccount" required ng-disabled="!$ctrl.wsaList || !$ctrl.wsaList.length">
                                <md-option ng-repeat="wsa in $ctrl.wsaList" value="{{wsa.ID}}" ng-disabled="wsa.Service == 'git' ">
                                    <md-icon md-svg-icon="services:{{wsa.Service}}"></md-icon> {{wsa.Name}}
                                </md-option>
                            </md-select>
                            <help-hint hint="cluster.serviceaccount"></help-hint>
                            <p ng-if="!$ctrl.wsaList || !$ctrl.wsaList.length">There's no any service accounts. <a href="/settings">Connect?</a></p>
                        </md-input-container>
                    </div>
                    <div flex layout="row">
                        <md-input-container flex="50">
                            <label>Source file name</label>
                            <input type="text" 
                                    ng-model="$ctrl.newCluster.Src" 
                                    name="Src"
                                    ng-model-options='{ debounce: 1000 }'
                                    ng-change="$ctrl.checkSrc(newCluster)"
                                    >
                            <help-hint hint="cluster.src"></help-hint>
                            <div ng-messages="newCluster.Src.$error">
                                <div ng-message="errorname"><cmp-error-toast ng-if="$ctrl.srcErrorMessage" error="$ctrl.srcErrorMessage" ></cmp-error-toast></div>
                                <!--<div ng-message="minlength">App's name is too short</div>-->
                            </div>
                        </md-input-container>
                        <md-input-container class="layout-row layout-align-start-center">
                            <md-checkbox ng-model="$ctrl.newCluster.AutoDeploy">{{"CLUSTER_AUTODEPLOY"|translate}}</md-checkbox>
                            <help-hint hint="cluster.autodeploy"></help-hint>
                        </md-input-container>
                    </div>
                    
                </md-card-content>
                <md-card-action flex layout="row" layout-align="end center">
                    <div flex>
                        <md-progress-linear ng-if="$ctrl.saving"></md-progress-linear>
                        <cmp-api-error-service ng-if="$ctrl.newClusterError" error="$ctrl.newClusterError"></cmp-api-error-service>
                    </div>
                    <md-button type="submit"><md-icon md-svg-icon="core:check"></md-icon> Save</md-button>
                    <md-button ng-click="$ctrl.cancelNewCluster()"><md-icon md-svg-icon="core:close"></md-icon> Cancel</md-button>
                </div>
            </md-card-action>
        </md-card>
    </form>
    `
};