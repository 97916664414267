import {ws_listener} from '../../core/controller/ws_listener'

class controller extends ws_listener {
    constructor(WorkspaceService, $state, $scope) {
        super();
        this.$scope = $scope;
        this.$state = $state;
        this.ws = $state.params.wsId;
        this.WorkspaceService = WorkspaceService;
        this.watchWS('applicationTask', this.ws_applicationTask);
        this.watchWS('applicationTaskCreate', this.ws_applicationTaskCreate);
        this.watchWS('applicationTaskDelete', this.ws_applicationTaskDelete);
        this.list = [];
    }

    $onInit() {
        this.wsResource = this.WorkspaceService.getResource(this.$state.params);
        this.wsResource.dashboard_active_tasks().$promise
            .then(
                (r) => {
                    this.list = r;
                },
                (err) => {}
            )
    }

    wsCurrentTask(data) {
        if( this.ws == data.workspace) {
            return _.findWhere(this.list, {name: data.name, workspace: data.workspace});
        }
    }

    ws_applicationTask (event, data ) {
        var task;
        if(data.workspace == this.ws) {
            if(data.status == "Succeeded") {
                this.ws_applicationTaskDelete(event, data)
            } else {
                if( task = this.wsCurrentTask(data) ) {
                    var i = _.indexOf(this.list, task);
                    this.list[i] = task;

                    console.log('change task', i, this.list[i]);
                    this.$scope.$apply();
                }else {
                    this.ws_applicationTaskCreate(event, data);
                }
            }
        }
    }

    ws_applicationTaskCreate ( event, data ) {
        if(data.workspace == this.ws) {
            this.list.push(data);
            this.$scope.$apply();
        }
    }

    ws_applicationTaskDelete ( event, data ){
        var task;
        if( task = this.wsCurrentTask(data) ) {
            this.list = _.without(this.list, task);
            this.$scope.$apply();
        }
    }
}

export const WorkspaceActiveTasks = {
    template: `
        <md-card>
            <md-card-title>
                <md-card-title-text>
                    <span class="md-headline">Active tasks</span>
                </md-card-title-text>
            </md-card-title>
            <md-content>
                 <md-list>
                      <md-list-item ng-repeat="item in $ctrl.list" ui-sref="wsApplication.tab({wsId: item.workspace, applicationName: item.app_name, tab: 'jobs', tabItem: item.name})">
                        <item-status-icon data="{ Health: item.status}" class="widthAuto"></item-status-icon>
                        <p>{{item.app_name}}/{{item.task_name}}-{{item.build}}</p>
                      </md-list-item>
                      <md-list-item ng-if="!$ctrl.list.length"><p>No task active</p></md-list-item>
                    </md-list>
            </md-content>
          </md-card>
    `,
    controller: controller
}
