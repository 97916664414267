export class ProjectClustersBaseController {
    constructor($scope, $state, ProjectClusterService, WorkspaceService, AuthService, WorkspacePermissions, autoUpdate) {
        this.$state = $state;
        this.service = ProjectClusterService;
        this.$scope = $scope;
        this.AuthService = AuthService;
        this.permissions = WorkspacePermissions;
        this.autoUpdate = autoUpdate;
    }

    $onInit() {
        var self = this;
        this.service.getResource(this.$state.params);
        this.update();
        this.$scope.$watch(
            function () {
                return self.service.list;
            },
            function (current) {
                if(!current) return;
                if(!self.list) {
                    self.list = current;
                }else {
                    var deleted = [];
                    _.each(self.list, function (el, key) {
                        var cEl  = _.findWhere(current, {Name : el.Name});
                        if(cEl) {
                            current = _.without(current, cEl);
                            _.each(self.list[key],
                                function (val, name) {
                                    if(name!=='$$hashKey' && name!=='Name' )
                                        self.list[key][name] = null;
                                }
                            );
                            self.list[key] =  _.extend( self.list[key], cEl.toJSON() );
                        }else{
                            deleted.push(el)
                        }
                    });
                    _.each(deleted, function (el, key) {
                       self.list = _.without(self.list, el);
                    });
                    if(current.length) {
                        self.list.concat(current);
                    }
                }
            }
        )
    }

    update() {
        var self = this;
        if(this.timeout) clearTimeout(this.timeout);
        this.service.getList(true)
            .then(
                () => {
                    self.timeout = _.delay(_.bind(self.update, self), 60000);
                },
                (err) => {
                    self.clusterError = err;
                    self.$scope.$emit('api_error', err);
                    }
                );
            // .finally(function () {
            //     self.timeout = _.delay(_.bind(self.update, self), 60000);
            // });
    }

    addCluster() {
      this.$state.go('wsProject.tab', {tab: 'clusters', tabItem: 'new'});
    }

    $onDestroy() {
        // debugger;
        if(this.timeout) clearTimeout(this.timeout);
    }
}
