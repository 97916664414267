// import {ServingItemController} from './serving_item_controller'
// import {ServingListController} from './serving_list_controller'


export default function ($stateProvider, $urlRouterProvider) {

    $stateProvider
        .state('settingBase', {
            url: "/settings",
            parent: 'content',
            resolve: {
                orgList: (OrganizationService) => {
                    OrganizationService.getResource();
                    return OrganizationService.getList();
                },
                pageTabs: ($rootScope) => {
                    $rootScope.addButtonConfig = [];
                    var tabs = [
                        {title: 'teams', content: 'Teams'},
                        {title: 'accounts', content: 'Accounts'}
                    ];

                    if(GlobalSettings.billing_enabled) {
                        tabs.push({title: 'billing', content: 'Billing'})
                    }
                    return tabs;
                }

            },
            views: {
                // "" : {
                "layout-page-content@" : {
                    templateProvider: function ($templateCache) {
                        return $templateCache.get('kuberlab/setting/card/setting_index.html');
                    },
                    controller: function ( orgList, $state, CU, $scope, OrganizationService) {
                        this.CU = CU;
                        $scope.$watchCollection(
                            () => { return OrganizationService.list; },
                            (c) => {
                                this.orgList = c;
                            }
                        );
                        $state.go('setting')

                    },
                    controllerAs: '$ctrl'
                }
            }
        })

        .state('setting', {
            url: '/:wsId',
            parent: 'settingBase',
            params: {
                wsId: 'my'
            },
            resolve: {
                orgInfo : (CU, $stateParams, OrganizationService, $q ) => {
                    var d = $q.defer();
                    OrganizationService.getResource();
                    OrganizationService.getList().then(
                        (r) => {
                            var orgList = r;
                            var resource = OrganizationService.getResource();

                            var org = $stateParams.wsId == 'my'
                                ? CU
                                : $stateParams.wsId == 'new'
                                ? new resource({})
                                :  _.findWhere( orgList, {Workspace: $stateParams.wsId});
                            d.resolve(org);
                        }
                    );
                    return d.promise;

                },
                pageTabs: (CU, orgInfo, $q, $stateParams) => {
                    var d = $q.defer();
                    var rez = [];
                    if($stateParams.wsId == 'new'){

                    }
                    else if( orgInfo && orgInfo.Name == CU.Name ){
                        var tabs = [
                            { title: "connection", content: "<cmp-settings-connection-list id='settings_userServices'></cmp-settings-connection-list>" },
                            { title: "accounts", content: "<cmp-wsa-card-list ws-id='$ctrl.orgInfo.Workspace' id='settings_userWsServices'></cmp-wsa-card-list>" },
                        ];
                        if(GlobalSettings.billing_enabled) {
                            tabs.push({title: 'billing', content: '<cmp-billing-ws ws-id="$ctrl.orgInfo.Workspace" ></cmp-billing-ws><cmp-invoice-ws ws-id="$ctrl.orgInfo.Workspace"></cmp-invoice-ws>'});
                        }
                        tabs.push({title: 'token', content: `<cmp-user-token></cmp-user-token>`});
                        // tabs.push({title: 'delete', content: `<md-button class="md-warn" ng-click="$ctrl.deleteAccount()" layout-align="center center">Delete Account</md-button>`});
                        rez = tabs;
                    } else if ( orgInfo ) {
                        var tabs = [
                            {title: 'teams', content: '<cmp-organization-card-team-list organization-id="$ctrl.orgInfo.ID"></cmp-organization-card-team-list>'},
                            {title: 'accounts', content: '<cmp-wsa-card-list ws-id="$ctrl.orgInfo.Workspace"></cmp-wsa-card-list>'}
                        ];
                        if(GlobalSettings.billing_enabled) {
                            tabs.push({title: 'billing', content: '<cmp-billing-ws ws-id="$ctrl.orgInfo.Name" ></cmp-billing-ws><cmp-invoice-ws ws-id="$ctrl.orgInfo.Name"></cmp-invoice-ws>'})
                        }
                        rez = tabs;
                    }
                    d.resolve(rez);
                    return d.promise;
                },

            },
            redirectTo: (trans) => {
                let tabs = trans.injector().getAsync('pageTabs');
                return tabs.then( ( resolveData ) => {
                    return resolveData.length
                        ? {state: 'setting.tab', params: _.extend({}, trans.params(), {}) }
                        : null;
                    // return resolveData.length
                    //     ? {state: 'setting.tab', params: _.extend({}, trans.params(), {tab: resolveData[0].title}) }
                    //     : null;
                } );
            },
            views: {
                "": {
                    templateProvider: function ($templateCache, $stateParams) {
                        if($stateParams.wsId == 'my') {
                            return `<cmp-user-card-big-new data="$ctrl.org" flex  layout="column" layout-align="start"></cmp-user-card-big-new>
                                    `
                        }else{
                            return `
                                <cmp-organization-full ng-if="$ctrl.org" data="$ctrl.org" flex  layout="column" layout-align="start"></cmp-organization-full>
                                <div ng-if="!$ctrl.org">Not found</div>
                            `
                        }
                    },

                    controller: function ( orgInfo, $state, pageTabs, CU, $stateParams, OrganizationService ) {
                        this.org = orgInfo;
                    },
                    controllerAs: '$ctrl'
                }
            }
        })

        .state('setting.tab', {
            url: '/:tab',
            params: {
                tab: null
            },
            resolve: {
                tab: (pageTabs, $stateParams, $state) => {
                    return _.findWhere(pageTabs, {title: $stateParams.tab});

                    // debugger
                    // let tab;
                    // if ( !$stateParams.tab || !(tab = _.findWhere(pageTabs, {title: $stateParams.tab})) ) {
                    //     $state.go('setting.tab', {tab: pageTabs[0].title});
                    //     return null;
                    // }
                    // return tab;
                }
            },
            //
            // redirectTo: (trans, $stateParams, $state) => {
            //     debugger
            //     console.log(trans, $stateParams, $state);
            //         let tabs = trans.injector().getAsync('pageTabs');
            //         return tabs.then( ( resolveData ) => {
            //             // return resolveData.length
            //             //     ? {state: 'setting.tab', params: _.extend({}, trans.params(), {}) }
            //             //     : null;
            //             return resolveData.length
            //                 ? {state: 'setting.tab', params: _.extend({}, trans.params(), {tab: resolveData[0].title}) }
            //                 : null;
            //         } );
            // },
            views: {
                "" : {

                    templateProvider: function (pageTabs, $state, $stateParams) {
                        // debugger
                        var current = _.findWhere(pageTabs, {title: $stateParams.tab});
                        var content = current ? current.content : 'tab undefined';
                        var t = `<md-tabs md-border-bottom md-dynamic-height  >
                                <md-tab ng-repeat="tab in $ctrl.pageTabs"
                                        md-active="$ctrl.$state.params.tab == tab.title"
                                        ui-sref="{tab: tab.title}"
                                        >
                                    <md-tab-label>
                                        {{tab.title}}
                                    </md-tab-label>
                                </md-tab>
                            </md-tabs>
                            <md-content class="fullInfo">${content}</md-content>`;
                        return t;
                    },

                    controller: function (pageTabs, tab, $state, orgInfo) {
                        if(!tab){
                            $state.go('setting.tab', {tab: pageTabs[0].title});
                            return false;
                        }
                        this.pageTabs = pageTabs;
                        this.$state = $state;
                        this.tab = tab;
                        this.orgInfo = orgInfo;
                    },
                    controllerAs: '$ctrl'
                }
            }
        })
}
