window.Deb;

export class DebuggingService {
    constructor($localStorage) {
        this.maxStack = 30;

        window.Deb = this;
        this.$localStorage = $localStorage;
        $localStorage.$default({
            deb_on: false
        });
        this._on = $localStorage.deb_on;
        this.stack = [];
    }

    on() {
        this.$localStorage.deb_on = true;
        this._on = this.$localStorage.deb_on;
    }

    off() {
        this.$localStorage.deb_on = false;
        this._on = this.$localStorage.deb_on;
    }

    setStack(m, params) {
        if(this.stack.length > this.maxStack) {
            this.stack.shift();
        }
        this.stack.push({method:m, params: params});
    }

    getStack(){
        _.each(this.stack, (v) => {
            console[v.method].apply(console, v.params);
        })
    }

    log () {
        if(this._on) console.log(...arguments);
        else this.setStack('log', [...arguments])
    }

    info () {
        if(this._on) console.info(...arguments);
        else this.setStack('info', [...arguments])
    }

    error () {
        if(this._on) console.error(...arguments);
        else this.setStack('error', [...arguments])
    }

}