import {CoreServiceResource} from '../core/service/core_service_resource'

export class RestoreService extends CoreServiceResource {
    constructor ($resource, $q, KuberlabConfigApi, $rootScope){
        super(...arguments);
    }

    getResource(params, clear) {
        var resource;
        if (!this._resource || clear){
            var apiPath = this.cfg.APIPath + '/restore/:action';

            resource = this.$resource(
                apiPath,
                {action: '@action'},
                _.extend(
                    {},
                    this.cfg.ResourceParams,
                    {
                        'passwordreset' : {
                            method : 'POST',
                            params: {action: 'passwordreset'},
                        },
                        'confirm' : {
                            method : 'GET',
                            params: {action: 'confirm'},
                        }
                    }

                )

            );
            if(!clear){

                this.list = null;
                this._resource = resource;
            }
        }
        return resource || this._resource ;
    }
}