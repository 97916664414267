
import {UserCardController} from './user_card_controller'

export const UserCardHomeComponent = {
    controller: UserCardController,
    template : `

        <div class="contentPageTitle">
            <div class="main_width_greed inner">
                <div class="title flex layout-row layout-align-start-center">
                    <span class="item">{{$ctrl.currentUser.DisplayName}} home page</span>
                </div>
            </div>
        </div>
        <div class="main_width_greed">
            <h2>workspaces</h2>
            <div class="col_3_row chartList_Item">
                <div class="col_row_item" ng-repeat="data in $ctrl.ws | orderBy:['Type','DisplayName'] " >
                    <md-card class="chartCardSmall">
                         <md-card-title>
                            <md-card-title-media>
                                <div class="md-media-sm card-media" layout>
                                    <img ng-src="{{data.Picture}}" alt="{{data.DisplayName}}">
                                </div>
                            </md-card-title-media>
                            <md-card-title-text>
                                <a class="md-headline" ui-sref="workspace({wsId : data.Name})"  >{{data.DisplayName}}</a>
                                <span class="md-subhead">Type: {{data.Type}}</span>
                            </md-card-title-text>
                        </md-card-title>
                    </md-card>
                </div>
            </div>
            
        </div>
    `
};
