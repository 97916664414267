import {CatalogBaseItemController} from '../catalog/base/item_controller'

export class CatalogDataItemController extends CatalogBaseItemController{
    constructor (catalog_data, pageTabs, catalog_dataResource, CatalogDataService, $scope, permissions) {
        super();
        this.permissions = permissions;
        this.canManage = permissions.can('mlapp.manage');
        this.data = catalog_data;
        this.resource = catalog_dataResource;
        this.Service = CatalogDataService;
        this.$scope = $scope;
        this.isLoading = {};
        this.loadingError = {};
        this.stateName = 'wsCatalogDataItem';
        this.stateParentName = "wsCatalogDataList";
        this.stateCatalog = "datasets";
        this.pageTabs = pageTabs;
        debugger;
    }
}
