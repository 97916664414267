import {ServingItemController} from './serving_item_controller'
import {ServingItemFullController} from './serving_item_full_controller'
import {ServingListController} from './serving_list_controller'


export default function ($stateProvider, $urlRouterProvider) {
    $stateProvider

        .state('wsServingList', {
            parent: 'workspace',
            url :  "/serving",
            resolve: {
                servingResource : function( $stateParams, ServingService, CU ){
                    return ServingService.getResource($stateParams);
                },
            },

            views: {
                "layout-page-content@" : {
                    template: `
                        <layout-page-content-title class="md-whiteframe-1dp" layout="row" layout-align="start center">
                            <layout-back></layout-back>
                            <h3 flex>{{$ctrl.WS.DisplayName}} servings</h3>
                        </layout-page-content-title>
                        <md-content flex class="md-padding">
                            <cmp-serving-list flex layout="column" layout-align="start"></cmp-serving-list>
                        </md-content>
                    `,
                    controller: function(workspace, permissions) {
                        this.WS = workspace;
                        this.permissions = permissions;
                    },
                    controllerAs: '$ctrl'
                }
            }


        })

        .state('wsServing', {
            url : '/:servingName',
            parent: 'wsServingList',
            resolve: {
                serving : function(servingResource, ServingService, $stateParams){
                    let serv = ServingService.getItem($stateParams.servingName);
                    serv.then(
                        (r) => {
                            ServingService.addLastServ($stateParams.wsId, r);
                        }
                    );

                    return serv;
                },
                pageTabs: function ( serving ) {
                    if (serving.Type == 'inference') {
                        return [
                            {
                                title : 'parameters',
                                content : '<cmp-serving-inference data="$ctrl.data"></cmp-serving-inference>'
                            }
                        ]
                    }
                    else {

                        return [
                            {
                                title : 'parameters',
                                content : '<cmp-serving-parameters data="$ctrl.data"></cmp-serving-parameters>'
                            },
                            {
                                title : 'log',
                                content : '<cmp-serving-logs></cmp-serving-logs>'
                            },
                            {
                                title:'readme',
                                content:'<cmp-serving-readme read-only="!$ctrl.canManage" readme-url="$ctrl.readmeUrl" ></cmp-serving-readme>'
                            },
                            {
                                title:'status',
                                content:'<cmp-serving-status data="$ctrl.data" ></cmp-serving-status>'
                            },
                            {
                                title:'metrics',
                                content:'<cmp-serving-metrics data="$ctrl.data" ></cmp-serving-metrics>'
                            }
                        ];

                        // return [
                        //     {
                        //         title : 'log',
                        //         content : '<cmp-serving-logs></cmp-serving-logs>'
                        //     },
                        //     {
                        //         title : 'Parameters',
                        //         content : '<cmp-serving-parameters data="$ctrl.data"></cmp-serving-parameters>'
                        //     }
                        // ]
                    }

                    // return [
                    //     {
                    //         title : 'log',
                    //         content : '<cmp-serving-logs></cmp-serving-logs>'
                    //     },
                    //     {
                    //         title : 'Parameters',
                    //         content : '<cmp-serving-parameters data="$ctrl.data"></cmp-serving-parameters>'
                    //     }
                    // ]
                },
            },

            redirectTo:'wsServing.tab',
            views : {
                "layout-page-content@" : {
                    templateProvider: function ($templateCache, pageTabs) {
                        return $templateCache.get('kuberlab/serving/card/serving_card_full.html');
                    },
                    controller: ServingItemFullController,
                    controllerAs: '$ctrl'
                }
            }
        })

        .state('wsServing.tab', {
            url:'/:tab',
            params: {
                tab: 'parameters'
                // tab: 'log'
            }
        })
}
