class Controller  {
    constructor($scope, ClusterService) {
        $scope.$watchCollection(
            () => {return this.clusterStorage},
            (c) => {this.getCurrent()}
        );
        this.ClusterService = ClusterService;
    }
    $onInit(){
        if(!this.data || _.isEmpty(this.data)){
            var d = _.findWhere(this.clusterStorage, {Default: true});
            if(d) this.data = d.Name;
        }

        if(!this.clusterStorage && this.workspaceName && this.clusterId){
            var req = this.ClusterService.getResource(this.workspaceName).storage({ClusterID: this.clusterId})
            req.$promise.then(
                (r) => {this.clusterStorage = r;}
            )
        }

        // this.getCurrent();
    }

    getCurrent() {
        var storage;
        if( storage =_.findWhere(this.clusterStorage, {Name: this.data})){
            this.current = storage.nfs;
        };
    }

    add() {
        debugger;
    }
}

export const StorageClusterComponent = {
    bindings: {
        data : "=",
        clusterStorage: "<",
        cluster: "<",
        workspaceName: "<",
        clusterId: "<"
    },
    controller: Controller,
    template: `
        <md-input-container class="flex" ng-if="!ctrl.addForm">
            <label>Storage</label>
            <md-select ng-model="$ctrl.data" ng-change="$ctrl.getCurrent()" required>
                        <md-option ng-value="item.Name" ng-repeat="(key, item) in $ctrl.clusterStorage">{{item.Name}}</md-option>
                        <!--TODO add new Kuberlab storage-->
                        <!--<md-option ng-if="$ctrl.workspaceName" ng-click="$ctrl.addForm = t</div>
rue">Add</md-option>-->
            </md-select>
        </md-input-container>
        <div ng-if="$ctrl.addForm">
        
        </div>
        <!--<md-input-container class="flex-33">-->
            <!--<label>Server</label>-->
            <!--<input type="text" ng-model="$ctrl.current.server" ng-disabled="true"> -->
        <!--</md-input-container>-->
        <!--<md-input-container class="flex-33">-->
            <!--<label>path</label>-->
            <!--<input type="text" ng-model="$ctrl.current.path" ng-disabled="true"> -->
        <!--</md-input-container>-->
        
`
};
