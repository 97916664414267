export function LayoutPageContentTitle(LayoutService, UserConfigService) {
    function link(scope, element) {

        if(LayoutService.hasSubMenu) {
            element.prepend( `<md-button ng-click="submenu()" class="md-icon-button"><md-icon md-svg-icon="core:{{wsHideSubMenu ? 'menu': 'menu-open'}}"></md-icon></md-button>` )
        }

        // function setSumMenu(hide) {
        //     let ac = hide ? 'addClass' : 'removeClass';
        //     element[ac]('wsHideSubMenu');
        //     scope.wsHideSubMenu = hide
        // };
        //
        // scope.submenu = function () {
        //     let hide = UserConfigService.getConfig('wsHideSubMenu');
        //     hide = hide ? false : true;
        //     UserConfigService.setConfig('wsHideSubMenu', hide);
        //     setSumMenu(hide)
        // };
        //
        // setSumMenu( UserConfigService.getConfig('wsHideSubMenu'))
    }

    return ({
        link: link,
        restrict: "E"
    });
}
