import {CoreServiceResource} from '../core/service/core_service_resource'

export class HelpService extends CoreServiceResource {
    constructor($resource, $q, KuberlabConfigApi, $rootScope ) {
        super(...arguments);
        // this.watchWS('workspaceNotify', this.ws_workspaceNotify);
        // this.watchWS('workspaceNotifyDelete', this.ws_workspaceNotifyDelete);
        this.list = [];
    }

    // ws_workspaceNotify(event, data) {
    //     this.deleteNotify(data);
    //     this.list.unshift(data);
    //     this.$rootScope.$digest();
    // }
    //
    // deleteNotify(data){
    //     var item = _.findWhere(this.list, {ID: data.ID, WorkspaceName: data.WorkspaceName});
    //     this.list = _.without(this.list, item);
    // }
    //
    // ws_workspaceNotifyDelete(event, data) {
    //     this.deleteNotify(data);
    //     this.$rootScope.$digest();
    // }
    //
    // getResource() {
    //     if( !this._resource ){
    //         var url = this.cfg.APIPath + '/try/';
    //         this._resource = this.$resource(
    //             url,
    //             {},
    //             _.extend({}, this.cfg.ResourceParams,
    //                 {
    //                     'close': {
    //                         method: 'DELETE',
    //                         url: url + 'wsnotify/:workspace/:id',
    //                         params: {
    //                             workspace: '@workspace',
    //                             id: '@id'
    //                         }
    //                     },
    //
    //                     'repository_reset': {
    //                         method: 'POST',
    //                         url: this.cfg.APIPath + '/workspace/:workspace/repository/:id/reset',
    //                         params: {
    //                             workspace: '@workspace',
    //                             id: '@id'
    //                         }
    //                     },
    //
    //                     'fix': {
    //                         method: 'POST',
    //                         url: this.cfg.APIPath + '/workspace/:workspace/notify/:id/fix',
    //                         params: {
    //                             workspace: '@workspace',
    //                             id: '@id'
    //                         }
    //                     }
    //                 }
    //             )
    //         );
    //         this.list = [];
    //     }
    //     return this._resource;
    // }
    //
    // repoReset(item) {
    //    return  this.getResource().repository_reset({workspace: item.WorkspaceName, id: item.Data.id}).$promise;
    // }
    //
    // fix(item) {
    //    return  this.getResource().fix({workspace: item.WorkspaceName, id: item.ID}).$promise;
    // }

    // close(item) {
    //    return  this.getResource().close({workspace: item.WorkspaceName, id: item.ID});
    // }
}
