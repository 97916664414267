export class AuthRegisterEmailConfirmController {
    constructor(AuthService, $state){
        this.authService = AuthService;
        this.$state = $state;
        this.confirm();
        debugger
    }

    confirm() {
        if(this.$state.params.token){
            var self = this;
            this.authService.register.confirm({token: this.$state.params.token})
                .$promise
                .then(
                    function (r) {
                        self.confirmSuccess = r;
                    },
                    function (err) {
                        self.confirmError = err;
                    }
                )
        }else{
            this.tokenUndefined = true;
        }
    }
}


export const AuthRegisterEmailConfirmTemplate = ` 
<div flex layout='column' layout-align="center center" md-theme="pageHomeWelcome" class="home_welcome_block">
    <div class="main_width_greed" >
        <home-card-welcom>
            <home-card-welcom-content>
            <h2><cmp-logo></cmp-logo></h2>
            <div class="content" ng-if="$ctrl.confirmSuccess">
                <span class="select">Confirm Email success.</span>
                 <p>Wait for administrator confirmation to email.</p>
            </div>
            <div class="content" ng-if="$ctrl.confirmError">
                Confirm Email error.
                <cmp-api-error-service ng-if="$ctrl.confirmError" error="$ctrl.confirmError"></cmp-api-error-service>
            </div>
            <div class="content" ng-if="!$ctrl.confirmError && !$ctrl.confirm">Confirm Email.</div>
            <div class="content" ng-if="$ctrl.tokenUndefined">
                Token undefined.
            </div>
            
            </home-card-welcom-content>
            <home-card-welcom-actions >
            
            <!--<div ng-if="$ctrl.confirmSuccess" class="layout-column layout-align-center-start">-->
                <!--<h4>Sign in</h4>-->
                <!--<auth-service-list  services="['gitlab']"></auth-service-list>-->
            <!--</div>-->
            </home-card-welcom-actions>
        </home-card-welcom>
    </div>
</div>`;
//
// export const AuthRegisterEmailComponent= {
//     template: AuthRegisterEmailTemplate,
//     controller: AuthRegisterEmailController
// }

