export function LayoutButtonSubmenu (LayoutService) {

    function link(scope, $element) {
        // debugger
        scope.submenu = function () {
            scope.$emit('LayoutButtonSubmenu')
        }
    }
    return ({
        link: link,
        restrict: "E",
        template: `<md-button ng-click="submenu()" class="md-icon-button"><md-icon md-svg-icon="core:{{wsHideSubMenu ? 'menu': 'menu-open'}}"></md-icon></md-button>`
    });
}
