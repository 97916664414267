class controller {
   constructor($state, WorkspacePermissions){
       this.$state = $state;
       this.permissions = WorkspacePermissions;
       this.full = true;
   }
   $onInit(){
       this.data = this.appController.data;
       this.resourceUrl = '/workspace/' + this.$state.params.wsId + '/application/' + this.$state.params.applicationName + '/readme';
   }

}

export const ApplicationSummaryComponent = {
    bindings: {
        // application: '=',
        appController: '='
    },
    controller: controller,
    template: function ($templateCache) {
        return $templateCache.get('kuberlab/applications/summary/application_summary_component.html');
    }
}