class ProjectTaskController {
    constructor (ProjectTasksService, $state, $scope, autoUpdate, $element) {
        this.data;
        this.$scope = $scope;
        this.service = ProjectTasksService;
        this.$state = $state;
        this.autoUpdate = autoUpdate;
        this.$element = $element;
    }

    $onInit() {
        this.resource = this.service.getResource(_.extend({}, this.$state.params, {clusterName: this.data.Name}));
        if(this.data.ID == this.$state.params.tabItem){
            this.getFullInfo();
            this.isActive = true;
        } else {
            this.autoReload();
        }
    }

    getFullInfo(update){
        if(this.timeout){
            clearTimeout(this.timeout);
        }
        if(!this.taskList) {
            update = true;
        }
        if(update){
            this.isLoading = true;
            this.errorLoading = null;
            var self = this;
            this.taskList = this.resource.get({taskId : this.data.ID})
                .$promise
                .then(
                    function (r) {
                        self.data = _.extend(self.data, r);
                        // self.$scope.$evalAsync(_.delay(
                        //     function () {
                        //         self.scroll()
                        //     }
                        // ), 500);
                    },
                    function (err) {
                        self.errorLoading = err
                    }
                )
                .finally(function () {
                    self.isLoading = null;
                    if(self.data.Status != 'Completed'){
                        self.autoReload();
                    }
                    self.$scope.$emit('tabItemComplite');

                });
        }
        return this.taskList;
    }

    scroll() {
        var pre = $(this.$element).find('.log-view');
        if(pre && pre.length) pre.scrollTop( pre[0].scrollHeight );
    }

    autoReload() {
        if(this.timeout){
            clearTimeout(this.timeout);
        }
        if(this.data.Active){
            this.timeout = _.delay( _.bind(this.getFullInfo, this), this.autoUpdate ,true);
        }
    }

    goItem () {
        if(this.isActive) {
            this.$state.go(this.$state.current.name, _.extend({}, this.$state.params, {tabItem : null}));
        }
        else {
            this.$state.go(this.$state.current.name, _.extend({}, this.$state.params, {tabItem : this.data.ID}));
        }
    }

    getDate (date) {
        return new Date(date);
    }

    $onDestroy() {
        if(this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    // $postLink() {
    //     debugger;
    // }
}

export const ProjectTaskComponent = {
    bindings: {
        data : '<'
    },
    controller: ProjectTaskController,
    template:function ($templateCache) {
        return $templateCache.get('kuberlab/projects/tasks/project_task_component.html');
    },
    o_template: `
            <md-list-item ng-click="$ctrl.goItem()" class="paddingListItemTabContent" id="tabItem_{{$ctrl.data.ID}}">
            
                <md-icon md-svg-icon="core:task" ng-class="md-avatar-icon"></md-icon>
                <!--<div class="md-list-item-text flex layout-row layout-align-space-between-center" >-->
                    <h4 class="flex-40">{{$ctrl.data.Cluster}}</h4>
                    <div class="flex layout-column latout-align-center" style="font-size: 90%; line-height: 1.2em   " >
                        <div><md-icon md-svg-icon="core:calendar"></md-icon> {{ $ctrl.getDate($ctrl.data.StartTime) | date:"MM/dd/yyyy 'at' h:mma" }}</div>
                        <div><md-icon md-svg-icon="core:calendar"></md-icon> {{ $ctrl.getDate($ctrl.data.LastTime) | date:"MM/dd/yyyy 'at' h:mma" }}</div>
                    </div>
                    <!--<div flex="20">Active : {{ $ctrl.data.Active }}</div>-->
                <!--</div>-->
                <div class="flex-20 " style="text-align: right">
                    <span ng-if="$ctrl.data.Active">is active </span>
                    <span class="clusterStatus {{$ctrl.data.Status.toLowerCase()}}">{{ $ctrl.data.Status }}</span>
                </div>
            </md-list-item>
            <md-progress-linear ng-if="$ctrl.isLoading"></md-progress-linear>
            <div ng-if="$ctrl.isActive" class="fullInfo paddingListItemTabContent"> 
                <md-card>
                    <md-card-action layout="row" ng-if="$ctrl.data.Status != 'Failed'" layout-align="end center">
                        <md-button ng-click="$ctrl.getFullInfo(true)">
                            <md-icon md-svg-icon="core:reload" aria-hidden="true"></md-icon>
                            Update
                        </md-button>
                    </md-card-action>
                    <md-card-content class="flex">
                        <md-progress-linear ng-if="$ctrl.isLoading" flex></md-progress-linear>
                        <pre ng-if="$ctrl.errorLoading">{{$ctrl.errorLoading}}</pre>
                        <p ng-if="$ctrl.data.Description"><b>Description:</b> {{$ctrl.data.Description}}</p>
                        <p ng-if="$ctrl.data.Error"><b>Error:</b> <span>{{$ctrl.data.Error}}</span></p> 
                        <h5>Log</h5>
                        <pre class="log-view">{{$ctrl.data.Data}}</pre>
                    </md-card-content>
                </md-card>
            </div>
        `
};
