export class KuberlabService {
    constructor($mdSidenav, AuthService, $resource, KuberlabConfigApi){
        this.AuthService = AuthService;
        this.currentUser;
        this.userComplite;
        this.$resource = $resource;
        this.cfg = KuberlabConfigApi;

    }

    settings() {
        if(!this._settings) {
            this._settings = this.$resource(this.cfg.APIPath + '/settings').get().$promise;
        }
        return this._settings;

    }

    toggleSidenav(toogleID){
        this.$mdSidenav(toogleID)
            .toggle()
            .then(function () {
                // $log.debug("toggle " + navID + " is done");
            });
    }

    whoami() {
        var self = this;
        this.AuthService.currentUser()
            .then(function (r) {
                    self.currentUser = r;
                }
                , function (r) {
                    self.currentUser = false;
                }
                , function () {
                    self.userComplite = true;
                }
            );
    }
}