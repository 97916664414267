class controller {
  constructor(WorkspaceService, BillingService, $scope, Message, Utils , ErrorService, CurrentUser, $q) {
    this.$q = $q;
    this.WorkspaceService = WorkspaceService;
    this.BillingService = BillingService;
    this.Message = Message;
    this.Utils = Utils;
    this.ErrorService = ErrorService;
    this.cardToken = '1';
    this.selectedPlans = {};
    this.$scope = $scope;

    this.DEFAULT_PLAN = 'base';

    $scope.$watchCollection(() => {
      return this.cardToken;
    }, (c) => {
      if (c && c.token) {
        this.addToken(c);
      }
    });

    this.currentPlansView = false;

    CurrentUser.then(
        (r) => {
          this.currentUser = r;
        }
    );
  }

  $onInit() {
    // this.billingResource = this.BillingService.getResource().plans().$promise.then((r) => {
      this.groupPlans = {};
      var self = this;
        this.billingResource = this.BillingService.getPlans().then((r) => {
            self.plans = r;
            _.each(self.plans, (p) => {
                let g = p.Group ? p.Group : "admins";
                if(!self.groupPlans[g]) self.groupPlans[g] = [];
                self.groupPlans[g].push(p);
            });
            // debugger;

          }, (err) => {
          }
        );

    var self = this;
    this.BillingService.getCost().then((r) => {
          this.cost = {};
          _.each(r, (v) => {
            var label = v.Label ? v.MeasuredResource + ":" + v.Label : v.MeasuredResource;
            self.cost[label] = {value: v.MeasuredCost, from: v.From};
          });
        },
        (err) => {
        }
    );

    this.wsResource = this.WorkspaceService.getResource({wsId: this.wsId});
    this.loading = true;
    this.wsResource.customer().$promise.then((r) => {
        this.customer = r;
        this.initCustomer();
      }, (err) => {
        this.error = err;
      }
    )
        .finally(()=>{ this.loading = false; })
  }

  initCustomer() {

    this.currentPlans = [];

    _.each(this.customer.Subscription, (v) => {
      var c = _.findWhere(this.plans, {ID: v.Plan.ID});
      if(c) {this.currentPlans.push(c)}
    });

    this.customerComplite = true;

    this.currentPlansView = (this.currentPlans && this.currentPlans.length);

    this.selectedPlans = {};

    _.each(this.customer.Subscription, (v) => {
      this.selectedPlans[v.Plan.ID] = true;
    });

    if (this.customer.Subscription && this.customer.Subscription.length) {
      this.subscribedPlans = this.customer.Subscription.map(v => v.Plan.ID);
    }
  }

  addPlan(plan){
    // debugger;

    if(plan.Group){
      var cp = _.findWhere(this.currentPlans, {Group: plan.Group});
      if(cp) {
        this.currentPlans = _.without(this.currentPlans, cp);
      }
      if(!cp || (cp && cp.ID != plan.ID)) {
        this.currentPlans.push(plan);
      }
    } else {
      var cp = _.findWhere(this.currentPlans, {ID: plan.ID});
      if(cp) {
        this.currentPlans = _.without(this.currentPlans, cp);
      } else {
        this.currentPlans.push(plan);
      }
    }
    return this.customer.Card ? this.addToken() : this.getPromiseCard();
  }

  getPromiseCard() {
      // debugger;
      this.cardPromise = this.$q.defer();
      // this.submitInProgress = true;
      // this.loading = true;
      this.cardPromise.promise
          .then(
              (r) => {
                  this.addToken(r)
              },
              (err)=>{
                  this.cardPromise = null;
              }
          )
          // .finally(() => {
          //     this.submitInProgress = false;
          //     this.loading = false;
          // })
      ;
      this.$scope.$digest();
      return this.cardPromise.promise;
  }

  addToken(req = {}, formData, planId) {
    this.cardError = null;
    this.submitInProgress = true;
    this.loading = true;

    req.Plans = _.map(this.currentPlans, (v) => { return v.ID; });

    return this.wsResource.customerAdd(req).$promise.then(
        (r) => {
          this.customer = r;
        },
        (err) => {
          this.cardError = err;
        }
      )
        .finally(() => {
          this.cardPromise = null;
          this.submitInProgress = false;
          this.initCustomer();
          this.loading = false;
        })
  }

  unsubscibe() {
    var del = (param) => {
      this.loading = true;
      this.wsResource.unsubscribe(param).$promise
        .then(() => {
          this.$onInit();
          this.selectedPlans = {};
          this.subscribedPlans = null;
          this.cardError = null;
        }, (err) => {
          this.ErrorService.error(err, del);
        })
          .finally(() => {this.loading = false;})
      ;
    };

    this.Utils.confirmDialog({
      title: 'SETTINGS_BILLING_DELETE_TITLE',
      text: 'SETTINGS_BILLING_DELETE_MESSAGE',
      okText: 'COMMON_DELETE'
    }).then(() => {
      del();
      // this.wsResource.unsubscribe({dependencies:true}).$promise.then(() => {
      //   this.$onInit();
      // });
    });
  }

  plansShowTitle() {
      return  (_.keys(this.groupPlans).length > 1 );
  }
}

export const BillingWsComponent = {
  bindings: {
    wsId: '<'

  },
  controller: controller,
  template: function($templateCache) {
    return $templateCache.get('kuberlab/billing/ws/billing_ws_component.html')
  }
};
