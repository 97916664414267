

import  {ControllerList} from '../core/controller/list'

export class OrgController extends  ControllerList {

    constructor ($scope, OrganizationsService, UserService, WorkspaceService) {
        super();
        this.$scope = $scope;
        this.UserService = UserService;
        this.OrganizationsService = OrganizationsService;
        this.WorkspaceService = WorkspaceService;
        this.resource = OrganizationsService.organizations;
        this.list;
        this.stateParams; //binding html
        $scope.$watch(
            () => { return this.OrganizationsService._organizations},
            (c) => { this.list = c ;}
        )
    }

    $onInit() {
        // this.list =
            this.OrganizationsService.orgList();
        // this.orgList();
    }
    //
    // orgList (update) {
    //     var self = this;
    //     if(!this.list) {
    //         var self = this;
    //         this.list = this.OrganizationsService.orgList();
    //         // this.$scope.$watch(
    //         //     function () {
    //         //         return self.OrganizationsService._organizations;
    //         //     },
    //         //     async function (current) {
    //         //         self.list = current;
    //         //
    //         //         for (let ws of self.list) {
    //         //           const result = await self.WorkspaceService.getResource({wsId: ws.Name}).customer().$promise;
    //         //           ws.billingFailed =  result.Failed;
    //         //         }
    //         //     }
    //         // );
    //     }
    //
    //     if(update) {
    //         this.list = this.OrganizationsService.orgList(true);
    //     }
    //
    //     return this.list;
    // }

    changeList() {
        this.UserService.getWs(true);
    }

    add (data) {
        this.addOrg(data);
    }

    deleteItem(item) {
        // debugger;
        this.OrganizationsService._organizations = _.without(this.OrganizationsService._organizations, item);
        this.changeList();
    }

}
