import {CatalogVersionController} from '../../catalog/base/version-view/controller'
import {ServingFormController} from '../../serving/form/controller'

class controller extends CatalogVersionController{
    constructor($scope, $state, $mdDialog, $templateCache, Utils, ErrorService, CatalogService) {
        super($scope, $state, $mdDialog, $templateCache, Utils, ErrorService);
        this.Utils = Utils;
        this.CatalogService = CatalogService;
        this.ErrorService = ErrorService;
        this.$mdDialog = $mdDialog;
        this.$templateCache = $templateCache;
        this.canNew = true;
        this.edit = false;
    }
}

export const DatasetVersionComponent = {
    bindings: {
        resource: '<',
        data: '<',
        canManage: '<',
        service: '<',
        canNew: '<'
    },
    controller: controller,
    template:  ($templateCache) => {
        return $templateCache.get('kuberlab/catalog_data/version/version_template.html');
    }
}
