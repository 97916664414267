class controller {
    constructor($element){
        this.model = {};
        // debugger;
        // $element.find('ng-form')[0].addEventListener('submit', function(e) {
        //     // elem.css('background-color', 'red');
        //     debugger;
        // })
    }

    // $onInit () {
    //     if(!this.data) this.data = {};
    // }

    add (form) {
        // debugger;
        if(!this.data) this.data = {};
        if(!this.model.key) return ;
        this.data[this.model.key] = this.model.value;
        this.model = {}
    }

    delete (key){
        if(this.data[key]) delete this.data[key];
    }
}

export const ObjectComponent = {
    bindings: {
        data: '=',
        keys: '<'
    },
    controller: controller,
    template : `
            <!--<div class="layout-row layout-align-start-center"><b class="flex-33">Key</b><b class="flex-33">Value</b></div> -->
            <div ng-repeat="(key, val) in $ctrl.data" class="layout-row layout-align-start-center">
                <span class="flex" style="height: 30px;  margin: 32px 0; display: inline-block;">{{key}}</span>
                <span class="flex" style="height: 30px;  margin: 32px 0; display: inline-block;"><md-input-container style="margin: 0"><input ng-model="$ctrl.data[key]"  /></md-input-container></span>
                <md-button class="md-icon-button" ng-click="$ctrl.delete(key)"><md-icon md-svg-icon="core:delete"></md-icon></md-button>
            </div>
            <ng-form name="object_list" ng-submit="$ctrl.add(object_list)" class="layout-row layout-align-start-center"">
            
                <!--<md-input-container class="flex-33">-->
                    <!--<label>Key</label>-->
                    <!--<input ng-model="$ctrl.model.key"  />-->
                <!--</md-input-container>-->
                
                <md-input-container class="flex" ng-if="!$ctrl.keys">
                    <label>Key</label>
                    <input ng-model="$ctrl.model.key"  />
                </md-input-container>
        
                <cmp-select class="flex" ng-if="$ctrl.keys" ng-model="$ctrl.model.key" options="$ctrl.keys" md-floating-label="Key" flex label="'Key'" ></cmp-select>
        
                <md-input-container class="flex">
                    <label>Value</label>
                    <input ng-model="$ctrl.model.value"  />
                </md-input-container>
                <md-button ng-click="$ctrl.add()" class=""><md-icon md-svg-icon="core:plus"></md-icon></md-button>
            </ng-form>
    `
};
