        class controller  {
    constructor(ApplicationsService, $state) {
        this.$state = $state;
        this.ApplicationsService = ApplicationsService;
    }

    $onInit() {
        this.activeList = [];
        this.typesFull = [
            'string',
            'numeric',
            'interval',
            'list',
            'bool'
        ];
        if(!this.data) this.data = {};
        this.getParamsList();
        this.active = {};
        this.addForm = _.keys(this.data) <= 0;
    }

    getParamsList () {

        this.ApplicationsService.getTaskparameters(this.$state.params.applicationName, true)
            .then(
                (r) => {
                    this.paramsList = r;
                }
            );
    }

    add () {
        this.data[this.active.params] = {search_type: this.active.type};
        this.active = {};
        this.addForm = false;
    }

    delete(item) {
        delete this.data[item];
    }

    search() {
        this.apiLoading = true;
        this.apiError = false;
        this.ApplicationsService.getResource({wsId: this.$state.params.wsId, application : this.$state.params.applicationName})
            .tasks_search({application : this.$state.params.applicationName}, _.extend({}, this.data)).$promise
            .then(
                (r) => {
                    this.list = r;
                },
                (err) => {
                    this.apiError = err;
                }
            )
            .finally(() => {this.apiLoading = false;});
    }

    cancelFilter() {
        this.list = null;
        this.open = false;
    }

    volumeList(param){
        var l = _.findWhere(this.paramsList, {name: param});
        return l ? l.values : [];
    }

    changeActive(){
        var p = _.findWhere(this.paramsList, {name: this.active.params});

        switch(p.type){
            case 'string':
                this.types = [
                    'string',
                    'list',
                    'bool'
                ];
                break;
            case 'numeric':
                this.types =  [
                    'numeric',
                    'interval',
                    'list',
                    'bool'
                ];
                break;
        }
        this.active.type = p.type;
    }
}

export const ApplicationJobsFilterComponent = {
    controller: controller,
    bindings: {
        data: "=",
        list: "=",
        open: "=",
        apiLoading: "=",
        apiError: "="
    },
    template: `
        <div ng-repeat="(key,item) in $ctrl.data" layout="row" layout-align="start center">
            <div flex="25" style="text-align: right;">{{key}}</div>
            <div style="width: 10px"></div>
            <div flex ng-switch="$ctrl.data[key].search_type" layout="row">
                <md-input-container ng-switch-when="string" flex>
                    <label>volue</label>
                    <input type="text" ng-model="$ctrl.data[key].value">
                </md-input-container>
                <md-input-container ng-switch-when="numeric" flex>
                    <label>volue</label>
                    <input type="number" ng-model="$ctrl.data[key].value">
                </md-input-container>
                <md-input-container ng-switch-when="bool" flex>
                    <md-checkbox ng-model="$ctrl.data[key].value">Volue</md-checkbox>
                </md-input-container>
                <div ng-switch-when="interval" layout="row" layout-align="start center" flex>
                    <md-input-container flex>
                        <label>min</label>
                        <input type="number" ng-model="$ctrl.data[key].min">
                    </md-input-container>
                    <md-input-container flex >
                        <label>max</label>
                        <input type="number" ng-model="$ctrl.data[key].max">
                    </md-input-container>
                </div>
                <md-input-container ng-switch-when="list" flex>
                    <label>value</label>
                    <md-select ng-model="$ctrl.data[key].list" multiple>
                        <md-option ng-repeat="opnt in $ctrl.volumeList(key)" ng-value="opnt">{{opnt}}</md-option>
                    </md-select>
                </md-input-container>
            </div>
            <md-button ng-click="$ctrl.delete(key)">remove</md-button>
        </div>
        <div layout="row" layout-align="start center">
            <form flex ng-if="$ctrl.addForm" layout="row" layout-align="start center" ng-submit="$ctrl.add()">
                <md-input-container flex>
                    <label>Select params</label>
                    <md-select ng-model="$ctrl.active.params" ng-change="$ctrl.changeActive()" >
                        <md-option ng-repeat="item in $ctrl.paramsList | orderBy:'name' " ng-value="item.name" ng-disabled="$ctrl.data[item.name]">{{item.name}}</md-option>
                    </md-select>
                </md-input-container flex>
                <md-input-container flex>
                    <label>Type volume</label>
                    <md-select ng-model="$ctrl.active.type">
                        <md-option ng-repeat="t in $ctrl.types" ng-value="t">{{t}}</md-option>
                    </md-select>
                </md-input-container>
                <md-button ng-click="$ctrl.addForm = false">{{"COMMON_CANCEL" | translate }}</md-button>
                <md-button class="md-raised" type="submite">{{"COMMON_ADD" | translate }}</md-button>
            </form>
            <div flex ng-if="!$ctrl.addForm" layout="row" layout-align="end center">
                <md-button ng-click="$ctrl.addForm = true">add params</md-button>
                <md-button ng-click="$ctrl.cancelFilter()">{{"COMMON_CANCEL" | translate}}</md-button>
                <md-button class="md-raised" ng-click="$ctrl.search()">{{"COMMON_FILTER" | translate}}</md-button>
            </div>
        </div>
    `
}
