import {ItemController, CatalogVersionViewItemComponent} from '../../catalog/base/version-view/version-item'

class controller extends ItemController {
    saveNewComplite(r, file) {
        this.data = r;

        if(file && file.length){
            this.uploadFullFile = true;
            _.each(file, (f) => { this._uploadFile(f)});
        }
        else {

            this.activate();
            this.setLisnkData();
        }
    }

    saveConfig( version) {
        this.saving = true;
        this.saveError = null;
        this.listController.CatalogResource.version_save({name: this.listController.data.Name, version: version.Version}, version.Config).$promise
            .then(
                (r) => {
                    // this.versions = _.without(this.versions, this.version);
                    // this.version = _.first(this.versions);
                },
                (err) => {
                    this.saveError=err;
                }
            )
            .finally(() => {
                this.saving = false;
            })
    }


    compliteUploadFile(data){
        this.uploadingFiles = _.without(this.uploadingFiles, data);
        if(this.uploadFullFile) {
            this.uploadFullFile = null;
            this.activate();
            this.setLisnkData();
        }
    }

    deleteUploadFile(data) {
        if(this.treeApi) this.treeApi.removeNewFile(data);
    }

    errorUploadFile(data){
        if(this.treeApi) this.treeApi.addError(data);
    }
}

export const MlmodelVersionItemComponent = {
    bindings : CatalogVersionViewItemComponent.bindings,
    controller: controller,
    template:  ($templateCache) => {
        return $templateCache.get('kuberlab/mlmodel/version/version-item-tpl.html');
    }
}

export const MlmodelVersionItemFullComponent = {
    bindings : CatalogVersionViewItemComponent.bindings,
    controller: controller,
    template:  ($templateCache) => {
        return $templateCache.get('kuberlab/mlmodel/version/version-item-full-tpl.html');
    }
}