class controller {
    constructor(ServingService, $state) {
        this.$state = $state;
        this.ServingService = ServingService;

    }

    $onInit() {

        this.getLogs();
    }

    getLogs() {
        let data = this.requestData ? this.requestData : this.$state.params;
        this.apiError = false;
        this.ServingService.getLogs(data).then(
            (r) => {
                this.logs = r;
            },
            (err) => {
                this.apiError = err;
            }
        )
    }
}
 export const ServingLogsComponent = {
     bindings: {
         requestData : '='
     },
     controller: controller,
     template: `
     <div class="md-padding">
        <div layout="row" layout-align="start center">
            <h3 translate flex>COMMON_LOGS</h3>
            <md-button ng-click="$ctrl.getLogs()"><md-icon md-svg-icon="core:reload"></md-icon> {{ 'COMMON_UPDATE' | translate }}</md-button>      
        </div>
        <div ng-repeat="(key, log) in $ctrl.logs" class="podLog" >
            <h4>{{key}}</h4>
            <pre class="log-view log">{{log}}</pre>  
            <md-button ng-click="$ctrl.getLogs()">
                <md-tooltip md-direction="left">Update log</md-tooltip>
                <md-icon md-svg-icon="core:reload"></md-icon></md-button>  
        </div>
        <cmp-error-toast ng-if="$ctrl.apiError" error="$ctrl.apiError"></cmp-error-toast>
    </div>
    `
 }
