export default function ($stateProvider) {
    $stateProvider
        .state('wsResources', {
            parent: 'wsBase',
            url : '/resources',
            resolve: {
                currentUser : function ( CU, CurrentUser ) {
                    return CU;
                }
            },
            views : {
                "" : {
                    templateProvider: function ($templateCache) {
                        return $templateCache.get('kuberlab/resources/page.html');
                    },
                    // controller: ProjectsItemController,
                    controllerAs: '$ctrl'
                }
            }
        })
}
