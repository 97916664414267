class controller {
    constructor($state, CatalogService, CatalogInterface, UserConfigService, $rootScope, $scope) {
        this.$state = $state;
        this.CatalogService = CatalogService;
        this.resource = CatalogService.getResource($state.params);
        this.CatalogInterface = CatalogInterface;
        this.catalogListView = UserConfigService.getConfig('catalogListView');
        $rootScope.$on('UserConfigService_catalogListView',
            (event, data) => {
                this.catalogListView = data;
            }
        )
    }

    $onInit() {
        this.search = this.$state.params.search;
        this.getList();
        this.listState = 'wsCatalog.list' ; // this.listState ? this.listState : '';
        this.link = this.listState + '({Interface: item.Interface, keyword: null})';
        if(this.isItem) {
            this.link = '^({Interface: item.Interface, keyword: null})';
        }
    }

    getList() {
        var data = {search :  this.search};
        this.resource.query(data).$promise.then(
            (r) => {
                this.list = r;
            },
            (err) => {
                debugger;
            }
        );
    }

    getItems(inter) {
        var i = _.findWhere(this.list, {Interface: inter});
        return i ? i.Items : [];
    }
}

export const WorkspaceCatalog = {
    bindings: {
        itemState : '<',
        search: '<'

    },
    controller: controller,
    template: `

        <!--<div layout-align="start" layout="row" class="catalog-common">-->
            <!--&lt;!&ndash;<div flex="20" ui-view="menu" class="catalog-common-menu">&ndash;&gt;-->
            <!--<div flex="20" ui-view="menu" class="layoutMenu">-->
                <!--<cmp-catalog-menu list-state="'wsCatalog.list'" interface="'chart-mlapp-v2'" ></cmp-catalog-menu>-->
            <!--</div>-->
            <!--<div style="width: 10px"></div>-->
            <!--<div flex ui-view >-->
            
                <div ng-repeat="item in $ctrl.CatalogInterface">
                    <h4 layout="row" style="margin-bottom: 0">  
                        <a  class="blockMenuItem"  ui-sref="{{$ctrl.link}}" ui-sref-active="current" layout="row">
                            <!--<md-icon md-svg-icon="core:status{{item.Health}}" class="status {{$ctrl.data.Health}}" ></md-icon>-->
                            <span class="label" layout="row" layout-align="space-between center" translate >{{item.label}}</span>
                        </a>
                        <div flex></div>
                         <md-button ui-sref="wsCatalog.list.add({Interface: item.Interface, keyword: null})" ><md-icon md-svg-icon="core:plus"></md-icon>{{'COMMON_ADD' | translate}}</md-button> 
                    </h4>
                
                    <!--<h4>{{item.Interface}}</h4>-->
                    <md-divider></md-divider>
                    <div class="chartList {{$ctrl.catalogListView}}" ng-if="$ctrl.getItems(item.Interface).length">
                        <cmp-catalog-item-small 
                            is-catalog="$ctrl.isCatalog" 
                            item-state="$ctrl.itemState"
                            list-state="$ctrl.listState" 
                            resource="$ctrl.resource"
                            class="item_view  chartList_Item"  
                            ng-repeat="ci in $ctrl.getItems(item.Interface)" 
                            data="ci">
                            </cmp-catalog-item-small>
                    </div>
                    <div ng-if="$ctrl.getItems(item.Interface).length" layout-align="center center" layout="row" class="md-padding">
                        <a  class=" md-button"  ui-sref="{{$ctrl.listState}}({Interface: item.Interface, keyword: null, search: $ctrl.search })" >
                        <!--<a  class=" md-button"  ui-sref="{{$ctrl.link}}({ search: $ctrl.search })" >-->
                            <!--<md-icon md-svg-icon="core:status{{item.Health}}" class="status {{$ctrl.data.Health}}" ></md-icon>-->
                                <span class="label" translate >more {{item.label | translate}}</span> 
                                <span ng-if="$ctrl.search">"{{$ctrl.search}}"</span>
                        </a>
                    </div>
                    <div ng-if="!$ctrl.getItems(item.Interface).length">
                        <p translate>COMMON_LIST_EMPTY</p>
                    </div>
                    
                    <!--<div>{{$ctrl.getItems(item.Interface)}}</div>-->
                </div>
            <!--</div>-->
        <!--</div>-->
    `
}
