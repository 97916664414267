class controller{
    constructor(ApplicationsService, $state, $scope, $mdDialog) {
        this.$mdDialog = $mdDialog;
        this.$state = $state;
        this.$scope = $scope;
        this.resource = ApplicationsService.getResource( $state.params );
        this.list = [];
        $scope.$on('applicationChangeStatus',
            () => {
                this.getList();
            }
        );
        $scope.$on('applicationChangeContainersCount',
            () => {
                this.getList();
            }
        )
    }

    $onInit(){
        this.getList();
    }

    getList() {
        this.packagesList = [];
        var self = this;
        this.loading = true;
        this.resource.status({application: this.application.Name})
            .$promise
            .then(
                function (r) {
                    self.list = r;
                },
                function (err) {
                    self.$scope.$emit('api_error', err);})
            .finally(() => {
                this.loading = false;
            })
    }

    openDialog(ev, data) {
        this.$mdDialog.show({
            locals: {
                data: data
            },
            controller: function ($scope, data, $mdDialog) {
                $scope.data = data;
                $scope.$mdDialog = $mdDialog;
            },
            template: `
                    <md-dialog>
                         <md-toolbar>
                          <div class="md-toolbar-tools">
                            <h2 class="flex">Resource States {{data.name}}</h2>
                            <md-button class="md-icon-button"  ng-click="$mdDialog.hide()"><md-icon md-svg-icon="core:close" ></md-icon></md-button>
                          </div>
                        </md-toolbar>
                        <md-dialog-content >
                            <div class="md-dialog-content">
                                 <!--<h5>Resource States</h5>-->
                                 <p ng-if="!data.resource_states.length">List empty.</p>
                                <div class="layout-column layout-align-start" ng-repeat="r in data.resource_states">
                                    <div class="layout-row layout-align-start">
                                        <div class="flex" style="min-width:0; ">{{r.name}}</div>
                                        <div class="flex-5"></div>
                                        <div class="status {{r.status}}" style="min-width:0; ">{{r.status}}</div>
                                    </div>
                                    <h4>Events</h4>
                                    <div ng-if="!r.events.length">
                                        List events empty
                                    </div>
                                    <div ng-if="r.events.length">
                                        <table class="resource_states">
                                            <tr>
                                                <!--<th>#</th>-->
                                                <th>Message</th>
                                                <th>Source</th>
                                                <th>Count</th>
                                                <th>First seen</th>
                                                <th>Last seen</th>
                                            </tr>
                                            <tr ng-repeat="event in r.events">
                                                <!--<td></td>-->
                                                <td style="width: 30%">{{event.message}}</td>
                                                <td>{{event.source.component}}</td>
                                                <td>{{event.count}}</td>
                                                <td class="ts">{{ event.firstTimestamp | date:"MM/dd/yyyy 'at' h:mma" }}</td>
                                                <td class="ts">{{ event.lastTimestamp | date:"MM/dd/yyyy 'at' h:mma" }}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>    
                            </div>
                        </md-dialog-content>
                    </md-dialog>
                `,
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: true
            // ,
            // fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
        })
            .then()
    }
}


export const ApplicationsStatusComponent = {
    bindings: {
        application: '='
    },
    controller: controller,
    template: function ($templateCache) {
        return $templateCache.get('kuberlab/applications/status/applications_status_component.html');
    }
};
