window.KWS;

export class WebSocketService {
    constructor(CurrentUser, KuberlabConfigApi, $rootScope, $http, Deb) {

        this.wsConnectionCONNECTING   = 0;	//Соединение ещё не открыто.
        this.wsConnectionOPEN	        = 1;	//Соединение открыто и готово к обмену данными.
        this.wsConnectionCLOSING	    = 2; 	//Соединение в процессе закрытия.
        this.wsConnectionCLOSED	    = 3;	///Соединение закрыто или не может открыться.
        this._delayConnect = 500;
        this._delayPing = 45000;
        this.delayConnectStep = 5000;
        this._maxDelayConnect = 60000;
        this.connect;
        window.KWS = this;
        this.sendBuffer = [];
        this.CU = false;
        this.$http = $http;
        this.Deb = Deb;

        CurrentUser.then(
            (r) => {
                this.CU = r;
                if(r) {
                    this.open();
                }
            }
        );

        var link = '//' +
            document.location.host +
            KuberlabConfigApi.APIPath +
            '/ws';

        this.testLink = document.location.protocol + link;
        this.wsLink = ( document.location.protocol == 'http:' ? 'ws:' : 'wss:') + link;

        this.$rootScope = $rootScope;

        $rootScope.$on('ws:pong', (data) => { this.pingPong(data) });
        this.setDef();
        this._disconnectTimes = [];

    }

    setDef () {
        this.delayConnect = this._delayConnect;
        this.delayPing = this._delayPing;
    }

    stop() {
        this.connect.close();
    }

    open(url){
        // debugger;
        if(true || this.CU) {
            if( WebSocket || url ) {
                try {
                    var connect = new WebSocket(url || this.wsLink);
                    this._initConnection(connect);
                } catch (e) {
                    // debugger;
                    console.error(e);
                    this.wsOnClose(e)
                }
            }
        }
    }

    _initConnection(c) {
        this.connect = c;
        this.connect.onopen = _.bind(this.wsOnOpen, this);
        this.connect.onclose = _.bind(this.wsOnClose, this);
        this.connect.onmessage = _.bind(this.wsOnMessage, this);
        this.connect.onerror = _.bind(this.wsOnError, this);
    }

    wsOnOpen (ev) {
        // console.info('open', ev);
        var buffer = _.clone(this.sendBuffer);
        this.setDef();

        this.sendBuffer = [];
        _.each(this.buffer, (ev) => {
            this.send(ev);
        });
        if(this.timeStop) {
            var t = new Date();
            var d = {date: t.toDateString() + ' ' + t.toTimeString(), interval : t - this.timeStop};
            this._disconnectTimes.push({date: t.toDateString() + ' ' + t.toTimeString(), interval : t - this.timeStop});
            this.timeStop = null;
            this.Deb.error('disconnect time', d);
        }
        this.ping();
    }

    wsOnClose (ev) {
        // console.info('close', ev);
        this.timeStop = new Date();
        if(this.connect){
            this.connect.onopen = null;
            this.connect.onclose = null;
            this.connect.onmessage = null;
            this.connect.onerror = null;
            delete this.connect;
        }

        if(this.hasError) {
            this.$http.get(this.testLink).then(
                (r) => {
                    _.delay(() => { this.open(); } , this.delayConnect);
                },
                (err) => {
                    switch (err.status){
                        case 401:
                            window.location.reload();
                            break;
                        default:
                            if(this.delayConnect < this._maxDelayConnect) this.delayConnect = this.delayConnect + this.delayConnectStep;
                    }
                    this.delayOpen();
                }
            );
        } else {
            this.delayOpen();
        }
        this.hasError = false;
    }

    delayOpen(){
        _.delay(() => { this.open(); } , this.delayConnect);
    }

    wsOnMessage (ev) {
        var data = JSON.parse(ev.data);
        this.Deb.info('mess', data.type, data.content);
        this.$rootScope.$emit('ws:' + data.type, data.content);
        this.ping();
    }

    wsOnError(err) {
        // debugger;
        this.hasError = true;
        // if(this.delayConnect < this._maxDelayConnect) this.delayConnect = this.delayConnect + this.delayConnectStep;
        // console.info('ws.error' , err);
    }

    send(type, data) {
        var ev = {type: type, data: data};
        if(this.connect && this.connect.readyState == this.wsConnectionOPEN) {
            this.connect.send(JSON.stringify(ev));
            this.ping();
        } else {
            this.sendBuffer.push(ev);
        }
    }

    ping(){
        clearTimeout(this.dping);
        this.dping = _.delay(()=>{this.send('ping')}, this._delayPing)
    }

    pingPong() {
        // if(this.timeoutPing) clearTimeout(this.timeoutPing);
        // this.timeoutPing =  _.delay(()=>{
        //     this.send('ping');
        // }, this.delayPing )
    }
}
