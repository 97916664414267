export class InviteController {
    // constructor (inviteInfo, InviteService, CurrentUser, $state, UserService) {
    constructor ( InviteService, CurrentUser, $state, UserService) {
        var self = this;
        this.$state = $state;
        // this.invite = inviteInfo;
        this.inviteService = InviteService.invite;
        this.UserService = UserService;
        CurrentUser.then(
            function (r) {
                self.currentUser = r;
            },
            function (r) {
                self.currentUser = false;
            }
        );
        this.accepted = false;
    }

    $onInit() {
        this.getInviteInfo();
    }

    getInviteInfo () {
        var self = this;
        // var r = this.inviteService.invite;
        self.loading = true;
        // debugger;
        var i = this.inviteService.get({inviteId : this.$state.params.inviteId})
            .$promise
            .then(
                function (r) {
                    self.invite = r;
                },
                function (err) {
                    // debugger
                    self.inviteError = err;
                }
            )
            .finally(function () {
                self.loadding = false;
            })
    }
    accept () {
        var self = this;
        var ws = this.invite.Workspace.Name;
        this.errorAccept = false;
        this.sending = true;

        this.invite.$accept()
            .then(
                function (r) {
                    self.accepted = true;
                    self.UserService.getWs(true);
                    self.$state.go('workspace', {wsId : ws});
                },
                function (r) {
                    self.errorAccept = r;
                }
            )
            .finally(() => {
                this.sending = false;
            })
        ;
    }
}
