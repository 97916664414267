export class controller{
    constructor(StorageService, ApplicationsService, $state){
        this.StorageService = StorageService;
        this.ApplicationsService = ApplicationsService;
        this.$state = $state;

    }

    $onInit(){
        this.types = {};
        if(!this.application){
            let resource = this.ApplicationsService.getResource(this.$state.params);
            resource.task(this.linkApp).$promise.then(
                (r) => {
                    resource.checkout({application: this.linkApp.application, revision: r.git_revision}).$promise.then(
                        (r) => {
                            this.application = {Configuration: r.config};
                            this.setTypes();
                        },
                        (err) => {
                        }
                    )
                },
                (err) => {
                }
            )
        }else{
            this.setTypes();
        }

    }

    setTypes() {
        _.each(this.application.Configuration.spec.volumes, (v)=>{
            this.types[v.name] = this.StorageService.getType(v);
        })
    }

    delete (item) {
        this.volumes = _.without(this.volumes, item);
    }

    changeVolume(){
        var v = _.findWhere(this.application.Configuration.spec.volumes, {name: this.newVolume.name});
        this.newVolume.type = this.StorageService.getType(v);
        delete this.newVolume.gitRevision;
    }

    add(item){
        if(!_.isArray(this.volumes)) this.volumes = [];
        this.volumes.push(_.clone(_.omit(this.newVolume, 'type')));
        this.newVolume = null;
    }

    getNew() {
        this.newVolume = {name: "", mountPath: "", subPath: ""};
    }
}


export const ApplicationsTaskVolumesComponent = {
    bindings: {
        application: '<',
        linkApp: '<',
        volumes: '=',
        workspaceName: '<'
    },
    controller: controller,
    template: function ($templateCache) {
        return $templateCache.get('kuberlab/applications/models/applications_task_volumes_component.html');
    },
};
