class controller {

    constructor(ApplicationsService, $state, $scope ){
        this.resource = ApplicationsService.getResource($state.params);
        this.$state = $state;
        this.$scope = $scope;
    }

    enabled($event){
        this.appController.action('enable', { uix: this.$state.params.tab})
            .then(
                () => {
                    let i = _.indexOf(this.appController.data.Configuration.spec.uix, _.findWhere(this.appController.data.Configuration.spec.uix, {name: this.$state.params.tab}))
                    if (i >= 0 ) {
                        this.appController.data.Configuration.spec.uix[i].disabled = false;
                    }
                }
            );
    }
}

export const ApplicationsDisabledComponent = {
    bindings: {
        application : '=',
        appController: "<"
    },
    template: `<md-card >
                    <md-card-content class="layout-column layout-align-start-center ">
                        <div translate>{{"MLAPP_DISABLE_MESSAGE"}}</div>
                        <md-button ng-click="$ctrl.enabled($event)" class="md-raised md-primary">{{"COMMON_ENABLE" | translate }}</md-button>
                    </md-card-content>
                </md-card>
                <cmp-api-error-service error="$ctrl.apiError" ng-if="$ctrl.apiError" format="'dialog'"></cmp-api-error-service>
            `,
    controller: controller
};
