
// import {ChartsListController} from '../charts/charts_list_controller'

export default function ($stateProvider) {
    $stateProvider

        .state('note',{
            // parent: 'cu',
            url : '/note/:note',
            params: {
                note: null
            },
            views: {
                'home@' : {
                    // template: `
                    //     <div flex layout='column' layout-align="center center" class="home_welcome_block">
                    //         <div class="main_width_greed"><home-card-welcom bind-html-compile="$ctrl.note"></home-card-welcom></div>
                    //     </div>
                    //     `,

                    template: `
                            <div flex layout='column'
                                    layout-align="center center"
                                    class="login-form-wrapper"
                                    >
                                <div class="home-card-welcom" layout="column" layout-align="space-between center" bind-html-compile="$ctrl.note"></div>
                            </div>
                        `,

                    controller: function ($state, $templateCache) {
                        this.$state = $state;
                        this.note = $templateCache.get('kuberlab/note/items/' + $state.params.note + '.html');
                        if(!this.note)
                            this.note = $templateCache.get('kuberlab/note/items/404.html');
                    },
                    controllerAs : '$ctrl'
                }
            }
        })

        .state('note.token',{
            url : '/:token',
            params: {
                token: null
            },
            views: {
                '' : {
                    template: `
                        <div ng-if="!$ctrl.$state.params.token">
                            <cmp-error-toast error="{data: {error: 'Token is empty.'}}"></cmp-error-toast>
                        </div>
                        <cmp-note-token-info token="$ctrl.$state.params.token" ng-if="$ctrl.$state.params.token" layout='row' layout-align="start center"></cmp-note-token-info>
                        `,
                    controller: function ($state) {
                        this.$state = $state;
                    },
                    controllerAs : '$ctrl'
                }
            }
        })

}
