
import {ControllerList} from '../../core/controller/list'

class ChartsCommentsList extends ControllerList{
    constructor ($scope, $state, ChartsCommentsService, CurrentUser) {
        super();
        // this.chartResource; // = ChartsService;
        // this.chart; // bindings html


        var self = this;

        CurrentUser.then(
            function (r) {
                self.cu = r;
            },
            function () {
                self.cu = false;
            }
        );
        // this.cu = CurrentUser;
        this.$state = $state;
        this.service = ChartsCommentsService;

        this.loading;
        var self = this;

        $scope.$watch(function () {
                return self.service.list;
            },
            function (c) {
                self.list = c;
            }
        )

    }

    $onInit () {
        var self = this;
        this.resource = this.service.getResource(this.$state.params);
        this.getList();
        this.getNewComment();
    }

    getNewComment (data ) {
        var self = this;
        this.service.new()
            .then(
                function (r) {
                    self.newComment = r;
                }
            )
    }



    getList () {
        var self = this;
        this.service.getList();
        // this.loading = true;
        // this.resource.query()
        //     .$promise
        //     .then(
        //         function (r) {
        //             debugger;
        //             self.list = r;
        //             self.loading = null;
        //         },
        //         function (err) {
        //             debugger;
        //             self.loading = null;
        //         }
        //     )

    }

    addComment () {
        // debugger;
        var self = this;
        this.newComment.$create()
            .then(
                function (r) {
                    self.list.push(r);
                    delete self.newComment;
                    self.getNewComment();
                    // self.chart.Comments = self.chart.Comments + 1;
                },
                function (err) {
                    debugger
                }
            )
    }
}

export const ChartsCommentsComponent = {
    bindings: {
        chart : '=',
    },
    controller: ChartsCommentsList,
    template: `
        <md-list class="md-dense flex">
            <md-list-item class="md-2-line" ng-repeat="item in $ctrl.list">
              <img ng-src="{{item.User.Picture}}" class="md-avatar" alt="{{item.User.DisplayName}}" />
              <div class="md-list-item-text flex layout-column">
                <h3><a ui-sref="workspace({wsId: item.User.Workspace})">{{item.User.DisplayName}}</a></h3>
                <p>{{ item.Text }}</p>
              </div>
            <md-divider ></md-divider>
            </md-list-item>

            <md-list-item ng-if="!$ctrl.cu">
                <p>only reg user</p>
            </md-list-item>
                
            <md-list-item class="md-2-line md-no-proxy" ng-if="$ctrl.cu">
              <img ng-src="{{$ctrl.cu.Picture}}" class="md-avatar" alt="{{$ctrl.cu.DisplayName}}" />
                <!--<pre>{{$ctrl.cu}}</pre>-->
                <form class="md-list-item-text flex layout-column " ng-submit="$ctrl.addComment()" flex style="padding-top: 1em">
                    <h3><a ui-sref="workspace({wsId: $ctrl.cu.Workspace})">{{$ctrl.cu.DisplayName}}</a></h3>
                    <p>
                        <textarea name="text" ng-model="$ctrl.newComment.Text" flex style="min-height: 3em; width: 100%;"></textarea>
                    </p>
                    <div flex layout="row" layout-align="end center">
                        <md-button type="submit" ng-disabled="!$ctrl.newComment.Text">send</md-button>
                    </div>
                </form>
            </md-list-item>

        </md-list>
        `
};