import {ChartsService} from './charts_service'
import ChartStateconfig from './charts_stateconfig'
import {ChartsCardHomeComponent} from './card/charts_card_home'
import {ChartsVersionsListComponent} from './versions/charts_versions_list'
import {ChartsCommentsService} from './comments/charts_comments_service'
import {ChartsCommentsComponent} from './comments/charts_comments_list'
import AgularJsoneditor from '../angular-jsoneditor/module'
import {ChartRatingComponent} from './rating'
import Help from '../help/help_module'
import {InstallChartFindComponent} from './install/install_chart_find'
import {InstallChartApplicationsComponent} from './install/install_chart_applications'
import {InstallChartMlapplicationsComponent} from './install/install_chart_mlapplication'
import {InstallChartWSComponent} from './install/install_chart_ws'
import {InstallChartClusterComponent} from './install/install_chart_cluster'
import {InstallChartVoluesComponent} from './install/install_chart_volues'
import WizardModule from './wizard/chart_wizard_module'
import {InstallChartStoragesComponent} from './install/install_chart_storage'
import {ChartsRecommendedComponent} from './recommended/chart_recommended_component'
import {InstallChartWizardComponent} from './install/install_chart_wizard'

import {ChartWizardClusterStorageComponent} from './install/wizard/cluster_storage'
import {ChartWizardDatasetComponent} from './install/wizard/dataset'
import {ChartWizardModelComponent} from './install/wizard/model'

import {ChartWizardGit} from './install/wizard/git'
import {ChartWizardSelect} from './install/wizard/select'
import {ChartWizardBoolean} from './install/wizard/boolean'
import {ChartWizardText} from './install/wizard/text'
import {ChartsReadmeComponent} from './readme/chart_readme_component'
import {CatalogChartItemVersionComponent} from './version/chart_version_controller'


export default angular
    .module('charts', [
        AgularJsoneditor.name,
        Help.name,
        WizardModule.name
    ])
    .constant('chartVersionDefault', 'latest')
    .service('ChartsService', ChartsService)
    .service('ChartsCommentsService', ChartsCommentsService)
    // .config(ChartStateconfig)
    .component('cmpChartsCardHome', ChartsCardHomeComponent)
    .component('cmpChartsVersionsList', ChartsVersionsListComponent)
    .component('cmpChartsComments', ChartsCommentsComponent)
    .component('cmpChartRating', ChartRatingComponent)
    .component('cmpInstallChartFind',InstallChartFindComponent )
    .component('cmpInstallChartApplications', InstallChartApplicationsComponent)
    .component('cmpInstallChartMlapplication', InstallChartMlapplicationsComponent)
    .component('cmpInstallChartWs', InstallChartWSComponent)
    .component('cmpInstallChartCluster', InstallChartClusterComponent)
    .component('cmpInstallChartVolues', InstallChartVoluesComponent)
    .component('cmpInstallChartStorages', InstallChartStoragesComponent)
    .component('cmpInstallChartWizard', InstallChartWizardComponent)
    .component('cmpInstallChartWizardClusterStorage', ChartWizardClusterStorageComponent)
    .component('cmpInstallChartWizardDataset', ChartWizardDatasetComponent)
    .component('cmpInstallChartWizardModel', ChartWizardModelComponent)
    .component('cmpInstallChartWizardGit', ChartWizardGit)
    .component('cmpInstallChartWizardSelect', ChartWizardSelect)
    .component('cmpInstallChartWizardBoolean', ChartWizardBoolean)
    .component('cmpInstallChartWizardText', ChartWizardText)
    .component('cmpChartRecomended', ChartsRecommendedComponent)
    .component('cmpChartsReadme', ChartsReadmeComponent)
    .component('cmpCatalogChartItemVersion', CatalogChartItemVersionComponent)
    .constant('chartTypes', [
        {title: 'applications', type: "mlapp-v2"},
        {title: 'Charts', type: "app"}
    ])
    .constant('chartPageTabs',[
            {
                title : 'readme',
                content : '<cmp-charts-readme></cmp-charts-readme>'
            },
            {
                title : 'comments',
                content : '<cmp-charts-comments></cmp-charts-comments>'
            },
            {
                title : 'config',
                content :
                    `<cmp-charts-versions-list
                        chart="$ctrl.chart" 
                        chart-resource="$ctrl.chartResource"
                        ></cmp-charts-versions-list>
            `
            }
            // ,
            // {
            //     title : 'wizard',
            //     content :
            //         `<cmp-chart-wizard
            //             chart="$ctrl.chart"
            //             chart-resource="$ctrl.chartResource"
            //             ></cmp-chart-wizard>
            // `
            // }


        ]
    )