

import {ProjectsItemController} from './projects_item_controller'
import {ProjectsList} from './projects_list_controller'

export default function ($stateProvider) {

    $stateProvider

        .state('wsProjectsList', {
            url :  "/projects",
            parent: 'workspace',
            views: {

                "layout-page-content@" : {
                    template: `
                        <layout-page-content-title class="md-whiteframe-1dp" layout="row" layout-align="start center">
                            <layout-back></layout-back>
                            <h3 flex>{{$ctrl.WS.DisplayName}} infrastructure</h3>
                            <md-button ng-if="$ctrl.permissions.can('project.manage')" ui-sref="wsProjectAdd" class="md-raised md-primary">{{'PROJECT_LIST_EMPTY_ACTION' | translate}}</md-button>
                        </layout-page-content-title>
                        <md-content class="md-padding">
                            <cmp-projects-card-home flex ></cmp-projects-card-home>
                        </md-content>
                    `,
                    controller: function(workspace, permissions) {
                        this.WS = workspace;
                        this.permissions = permissions;
                    },
                    controllerAs: '$ctrl'
                },
            }
        })


        .state('wsProjectAdd', {
            parent: 'wsProjectsList',
            url : '/create_new',
            resolve: {
                currentUser : function ( CU, CurrentUser ) {
                    return CU;
                },
                projectResource : function(workspace, ProjectService, currentUser){
                    return ProjectService.getResource(workspace.Name);
                },

                project : function( projectResource , ProjectService, $rootScope, workspace){
                    return new projectResource ({Environment:"", RepositoryDir: "/"});
                },

                envList : function () {
                    return null;
                }
            }
            ,
            views : {
                "layout-page-content@" : {
                    templateProvider: function ($templateCache) {
                        return $templateCache.get('kuberlab/projects/card/project_card_new.html');
                    },

                    controller: ProjectsItemController,
                    controllerAs: '$ctrl'
                }
            }
        })



        .state('wsProject', {
            parent: 'wsProjectsList',
            url : '/:projectName/:envName',
            params: {
                projectName: null,
                envName: null
            },
            resolve: {
                projectResource : function(workspace, ProjectService){
                    return ProjectService.getResource(workspace.Name);
                },

                project : function(projectResource, ProjectService, $stateParams){
                    return ProjectService.getProject($stateParams.projectName);
                },

                defTab : function(projectViewTabs){
                    return projectViewTabs[0].title;
                },

                env: function ($stateParams, project, $q, defTab, $state, $rootScope, workspace) {
                    return project.Envoronment;
                }

            },
            redirectTo: (trans) => {
                // debugger
                if(!trans.params().envName){
                    let $q = trans.injector().get('$q');
                    let defTab = trans.injector().getAsync('defTab');
                    let project = trans.injector().getAsync('project');
                    return $q.all([project, defTab]).then((r)=> {
                       return {state: 'wsProject.tab', params: _.extend({}, trans.params(), {envName: r[0].Environment, tab: r[1]})};
                    })
                } else {
                    return {state: 'wsProject.tab', params: _.extend({tab: 'app resource'}, trans.params())};
                }
            },
            views : {
                "layout-page-content@" : {
                    templateProvider: function ($templateCache, env) {
                        return $templateCache.get('kuberlab/projects/card/project_card_full.html');
                    },
                    controller: ProjectsItemController,
                    controllerAs: '$ctrl'
                }
            }
        })
        .state('wsProject.tab', {
            url:'/:tab/:tabItem?:page:limit',
            params: {
                tab: null,
                tabItem: null,
                page: null,
                limit: null
            },
            resolve: {
                cTab : function ($state, $q,  projectViewTabs, $stateParams, defTab) {
                    var deferred = $q.defer();
                    var tab = _.findWhere(projectViewTabs, {title: $stateParams.tab});
                    if (tab) {
                        deferred.resolve(tab);
                    }
                    else {
                        deferred.reject();
                    }
                    return deferred.promise.catch(function () {
                        $state.go( 'wsProject.tab', _.extend({}, $stateParams, {tab: defTab}), {reload: true});
                    });
                }
            },
            views: {
                tabContent : {
                    templateProvider: function ($rootScope, cTab) {
                        if(cTab){
                            $rootScope.addButtonConfig = cTab.addButtonConfig;
                            return cTab.content;
                        }
                    },
                    controller: function ($scope, $state) {
                        $scope.$on('tabItemComplite', function (ev, data) {
                            var el;
                            if ( $state.params.tabItem && (el = $('#tabItem_' + $state.params.tabItem)) && el.length ) {
                                $('#main-content').animate({
                                    scrollTop: el.offset().top - $('#main-content').offset().top
                                }, 500);
                            }
                        })
                    }
                }
            }
        })
}
