class controller {
    constructor ($scope, $timeout, $element, Utils) {
        this.frameName = 'Iframe';
        var self = this;
        this.$scope = $scope;
        this.$element = $element;
        this.utils = Utils;
        $timeout(function () { console.log(window.frames[self.frameName]); }, 1000);
    }

    $onInit() {
        _.delay(() => {
            var hh = $('#header').height() || 0;
            var hf = $('footer').height() || 0;
            var hw = $(window).height();
            var hi = hw - hh - hf - 20;
            var iframe = $('iframe');
            var self = this;
            if(hi < 720 ) hi = 720;
            iframe.height(hi);
            iframe.one('load', _.bind(this.loadComplete, this));
        }, 10);

        this.frameEnable = !this.disabledAction || (this.disabledAction && !this.disabled);

        if(this.frameEnable) {
            this.loading = true;
            this.$scope.loading = true;
        }
    }

    loadComplete() {
        this.loading = false;
        this.$scope.loading = false;
        this.$scope.$apply();
    }

    frameDisable() {
        this.loading = false;
        this.$scope.loading = false;
        this.controller[this.disabledAction](this.link);
        this.uix.di
    }
}

export const IframeComponent = {
    bindings : {
        mlapp: "<",
        link: "<",
        openInNewTab: '@',
        disabled: '=',
        controller: '<',
        disabledAction: '<'
    },
    controller: controller,
    template: `
            <md-progress-linear ng-if="loading"></md-progress-linear>

            <div layout="row" layout-align="end center" ng-if="$ctrl.openInNewTab">
              <md-button ng-if="$ctrl.disabledAction" ng-click="$ctrl.frameDisable()">
                <md-tooltip md-direction="top">{{'MLAPP_UIX_DISABLE' | translate}}</md-tooltip>
                <md-icon md-svg-icon="core:edit"></md-icon>
                {{$ctrl.disabled ? 'ENABLE' : 'DISABLE' | translate}}
              </md-button>
              <md-button ng-if="$ctrl.frameEnable" aria-label="{{'COMMON_OPEN_NEW_TAB' | translate}}" ng-click="$ctrl.utils.openWindow($ctrl.link)" target="_blank">
                <md-tooltip md-direction="top">{{'COMMON_OPEN_NEW_TAB' | translate}}</md-tooltip>
                <md-icon md-svg-icon="core:link"></md-icon>
                {{'COMMON_OPEN_NEW_TAB' | translate}}
              </md-button>
            </div>
            <div ng-if="$ctrl.frameEnable" >
                <iframe width="100%" name="{{$ctrl.frameName}}" ng-src="{{$ctrl.link}}" ></iframe>
            </div>
            `
};
