
export default function() {
    // for more info on how to customize a link function
    // for a d3 render see also https://github.com/3DGenomes/angular-d3js
    var link = function postLink(scope, element, attrs) {
        var component = element[0].parentNode;
        scope.safeApply = function(fn) {
            var phase = this.$root.$$phase;
            if (phase === '$apply' || phase === '$digest') {
                if (fn && (typeof(fn) === 'function')) {
                    fn();
                }
            } else {
                this.$apply(fn);
            }
        };
        debugger;
        var graph = new Rickshaw.Graph({
            element: element[0].querySelector('.chart'),
            // element: element[0],
            width: component.clientWidth,
            height: parseInt(attrs.height, 10) || 400,
            series: scope.data,
            renderer: scope.renderer
        });
        var yAxis = new Rickshaw.Graph.Axis.Y({ graph: graph });
        var xAxis =  new Rickshaw.Graph.Axis.Time({ graph: graph });
        var hover = new Rickshaw.Graph.HoverDetail({ graph: graph });
        var legend = new Rickshaw.Graph.Legend({
            element: element[0].querySelector('.legend'),
            graph: graph });

        scope.render = function() {
            scope.safeApply(function() {
                graph.width = component.clientWidth;
                graph.setRenderer(scope.renderer);
                graph.setSeries(scope.data);
                yAxis.render();
                xAxis.render();
                legend.render();
                graph.render();
            });
        };
        scope.$watchCollection('[data, renderer]', function(newVal) {
            if (newVal[0] && scope.data === newVal[0]) {
                scope.data = newVal[0];
            }
            if (newVal[1] && scope.renderer === newVal[1]) {
                scope.renderer = newVal[1];
            }
            scope.render();
        });
    };
    return {
        scope: {
            data: '=',
            renderer: '='
        },
        template:  `
            <div class="y_axis"></div>
            <div class="chart"></div>
            <div class="legend"></div>
        `,
        restrict: 'E',
        link: link
    };
}