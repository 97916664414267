class Controller {
    constructor($rootScope, $state, $mdDialog, $scope) {
        this.$state = $state;
        $rootScope.$on('apiErrorFailed', _.bind(this.showError, this));
        this.errors = [];
        this.$mdDialog = $mdDialog;
        this.$scope = $scope;
        this.more = false;
    }

    showError (error, data) {
        this.errors.push(data.error);
        this.showDialog();

    }

    reload() {
        this.close();
        this.$state.go(this.$state.current, {}, {reload: true});
    }

    close() {
        this.errors = [];
        this.dialog = false;
    }

    showDialog () {
        var self = this;
        if(!this.dialog){

            this.dialog = this.$mdDialog.show({
                controller: function ($scope, $mdDialog) {
                    $scope.errors = self.errors;

                    $scope.close = function () {
                        $mdDialog.cancel();
                    };
                    $scope.hide= function () {
                        $mdDialog.hide();
                    };
                },
                template: `
                    <md-dialog aria-label="Connect error">
                        <md-toolbar>
                            <div class="md-toolbar-tools">
                                <h4 class="flex" translate>CONNECTION_ERROR_TITLE</h4>
                                <md-button class="md-icon-button" ng-click="close()">
                                    <md-icon md-svg-icon="core:close" aria-label="Close dialog"></md-icon>
                                </md-button>
                            </div>
                        </md-toolbar>
                        <md-dialog-content>
                            <div class="md-dialog-content">
                                <p translate>CONNECTION_ERROR_MESSAGE</p>
                                <a ng-click="$ctrl.more = !$ctrl.more">{{"COMMON_MORE" | translate}}<md-icon md-svg-icon="core:chevron-up" ng-if="ctrl.more"></md-icon><md-icon md-svg-icon="core:chevron-down" ng-if="!ctrl.more"></md-icon> </a>
                                <ul class="errors" ng-if="$ctrl.more">
                                    <li class="item" ng-repeat="item in errors">{{item.config.method}} {{item.config.url}}</li>
                                </ul>
                            </div>
                        </md-dialog-content>
                        <md-dialog-actions layout="row">
                            <span flex></span>
                            <md-button ng-click="hide()">{{"CONNECTION_ERROR_BUTTON" | translate}}</md-button>
                        </md-dialog-actions>
                    </md-dialog>
                `,
                parent: angular.element(document.body),
                // targetEvent: ev,
                clickOutsideToClose:true,
                // fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
            })
                .then(function() {
                    self.reload();
                }, function() {
                    self.close()
                });
        }
    }

}

export const CoreErrorFailed = {
    controller : Controller,
    template: ``
}