
import {AuthRegisterEmailConfirmController, AuthRegisterEmailConfirmTemplate} from './auth_register_email_confirm'

export default function ($stateProvider, $urlRouterProvider) {
    $stateProvider
        .state('login', {
            url : '/login',
            parent: 'cu',
            // parent: "content",
            // resolve:{
            //     currentUser: function (CurrentUser) {
            //         return CurrentUser;
            //     }
            // },
            views : {
                "layout-page-content@" : {
                    template: `<cmp-auth-login current-user="$ctrl.currentUser"></cmp-auth-login>`,
                    controller: function (CU) {
                        this.currentUser = CU;

                    },
                    controllerAs: '$ctrl'
                }
            }
        })
        .state('email_confirm', {
            url : '/confirm/:token',
            params: {token: null},
            parent: "cu",
            views : {
                "layout-page-content@" : {
                    template: AuthRegisterEmailConfirmTemplate,
                    controller: AuthRegisterEmailConfirmController,
                    controllerAs: '$ctrl'
                }
            }
        })
}
