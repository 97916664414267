import {stateCatalogConfig} from './catalog_state_cfg'
import {CatalogItemNewController} from './catalog_item_new_controller'
import {ChartsItemChartNewController} from './catalog_item_chart_new_component'
import {CatalogItemController, CatalogItemChartController} from './catalog_item_controller'


export default function ($stateProvider) {
    $stateProvider

        .state('wsCatalog',  {
            parent: 'workspace',
            url: '/catalog?:search',
            // resolve: {
            // },
            views: {
                "layout-page-content@" : {
                    template: `
                        <layout-page-content-title class="md-whiteframe-1dp" layout="row" layout-align="start center">
                            <layout-back></layout-back>
                            <h3 flex>{{$ctrl.WS.DisplayName}} catalog</h3>
                            <div flex></div>
                            <input ng-model="search" ng-model-options='{ debounce: 1000 }' placeholder="search" ng-change="$ctrl.searchGo(search)">
                            <md-button ng-click="$ctrl.changeView()" class="md-icon-button">
                                <md-icon md-svg-icon="{{$ctrl.catalogListView == 'grid' ? 'core:view-sequential' : 'core:view-grid'}}"></md-icon>
                            </md-button>
                            <!-- <md-button ui-sref=".add" class="md-raised md-primary"><md-icon md-svg-icon="core:plus"></md-icon>{{'COMMON_ADD' | translate}}</md-button> -->
                         </layout-page-content-title>
                         <md-content class="md-padding">
                            <cmp-workspace-catalog item-state="'wsCatalog.list.item'" ></cmp-workspace-catalog>
                        </md-content>
                    `,
                    controller: function (workspace, $state, UserConfigService, $scope) {
                        this.WS = workspace;
                        this.$state = $state;
                        this.UserConfigService = UserConfigService;
                        this.catalogListView = 'grid';
                        $scope.search = $state.params.search;
                        this.searchGo = (c) => {
                            this.$state.go('wsCatalog', {search: c})
                        };

                        this.changeView = function () {
                            this.catalogListView = this.UserConfigService.setConfig('catalogListView', this.catalogListView == 'grid' ? 'sequential' : 'grid');
                        }
                    },
                    controllerAs: '$ctrl'
                }
            }
        })


        .state('wsCatalog.list',  {
            url: '/:Interface?:page:limit:type:keyword',
            params: {
                Interface:'chart-mlapp-v2',
                limit: '30',
                page: '1'
            },
            resolve: {
                // isCatalog: () => { return false; },
            },
            views: {
                // "" : {
                "layout-page-content@" : {
                    template: `
                    <layout-page-content-list class="menuLevel layoutMenu" >
                        <layout-ws></layout-ws>
                        <h4><a ui-sref="wsCatalog"><md-icon md-svg-icon="core:wsCatalog"></md-icon><span class="label">Catalog</span></a></h4>
                        <md-divider></md-divider>
                        <cmp-catalog-menu list-state="$ctrl.listState"></cmp-catalog-menu>
                    </layout-page-content-list>
                    <layout-page-content-item>
                        <layout-page-content-title class="md-whiteframe-1dp" layout="row" layout-align="start center">
                            <layout-button-submenu></layout-button-submenu>
                            <layout-back></layout-back>
                            <h3 flex>{{$ctrl.WS.DisplayName}} catalog <span translate>{{$ctrl.$state.params.Interface}}</span></h3>
                            <md-button ui-sref=".add" class="md-raised md-primary"><md-icon md-svg-icon="core:plus"></md-icon>{{'COMMON_ADD' | translate}}</md-button>
                         </layout-page-content-title>
                        <cmp-catalog-list flex class="catalog-common" layout="column" layout-align="start"></cmp-catalog-list>
                    </layout-page-content-item>
                        `,
                    controller: function (workspace, $state) {
                        this.WS = workspace;
                        this.$state = $state;
                        // this.isCatalog = isCatalog;
                    },
                    controllerAs: '$ctrl'
                }
            }
        })

        .state('wsCatalog.list.add', _.extend({}, stateCatalogConfig.add, {
            resolve: {
                catalog_dataResource: (CatalogService, $stateParams) => {
                    return CatalogService.getResource($stateParams);
                },
                catalog_data: (catalog_dataResource, $stateParams) => {
                    switch($stateParams.Interface){
                        case "chart-app":
                        case "chart-mlapp-v2":
                            return new catalog_dataResource({RepositoryDir: "/" });
                        default:
                            return new catalog_dataResource({Keywords: []});
                    }
                }
            },
            views: {
                "layout-page-content@" : {
                    templateProvider: function ($templateCache, $stateParams) {
                        var tpl;

                        switch($stateParams.Interface){
                            case "chart-app":
                            case "chart-mlapp-v2":
                                // return $templateCache.get('kuberlab/charts/card/card_chart_new.html');
                                tpl = $templateCache.get('kuberlab/charts/card/card_chart_new.html');
                                break;
                            default:
                                tpl = $templateCache.get('kuberlab/catalog/base/card/card_new.html');
                        }

                        return `
                        
                            <layout-page-content-list class="menuLevel layoutMenu" >
                                <layout-ws></layout-ws>
                                <!--<h3><a ui-sref="workspace"><md-icon md-svg-icon="core:wss"></md-icon><span class="label">{{$ctrl.WS.DisplayName}}</span></a></h3>-->
                                <h4><a ui-sref="wsCatalog"><md-icon md-svg-icon="core:wsCatalog"></md-icon><span class="label">Catalog</span></a></h4>
                                <md-divider></md-divider>
                                <cmp-catalog-menu is-item="true"></cmp-catalog-menu>
                            </layout-page-content-list>
                            
                            
                            <layout-page-content-item flex layout="column" layout-align="start">
                            
                                <layout-page-content-title class="md-whiteframe-1dp" layout="row" layout-align="start center">
                                    <layout-button-submenu></layout-button-submenu>
                                    <layout-back></layout-back>
                                    <h3 flex>{{$ctrl.WS.DisplayName}} catalog create <span translate>{{$ctrl.$state.params.Interface}}</span></h3>
                                    <div flex></div>
                                </layout-page-content-title>
                                ${tpl}
                            </layout-page-content-item>
                        `
                    },
                    controllerAs: '$ctrl',
                    controllerProvider: ($stateParams, catalog_data, CatalogService) => {
                        switch($stateParams.Interface){
                            case "chart-app":
                            case "chart-mlapp-v2":
                                return ChartsItemChartNewController;
                            default:
                                return CatalogItemNewController;
                        }
                    }
                }
            }
        }))

        .state('wsCatalog.list.item', _.extend({}, stateCatalogConfig.item, {
            resolve: {

                catalog_dataResource: (CatalogService, $stateParams) => {
                    return CatalogService.getResource($stateParams);
                },

                pageTabs: (catalogPageTabs, catalogPageMlModelTabs, catalogPageChartTabs, catalogPageInferenceTabs,  $stateParams) => {
                    switch($stateParams.Interface) {
                        case "chart-app":
                        case "chart-mlapp-v2":
                            return catalogPageChartTabs;
                        case 'mlmodel':
                            return catalogPageMlModelTabs;
                        case 'inference':
                            return catalogPageInferenceTabs;
                        default:
                            return catalogPageTabs;
                    }

                },

                catalog_data : function (catalog_dataResource, $stateParams ) {
                    return catalog_dataResource.get({
                        name : $stateParams.item
                    })
                        .$promise;
                }
            },
            url: '/:item',
            params:{Interface: null, item: null},
            redirectTo: 'wsCatalog.list.item.tab',
            views: {
                "layout-page-content@" : {
                    templateProvider: ($templateCache) => { return $templateCache.get('kuberlab/catalog/card/card_item.html');  },
                    controllerAs: '$ctrl',
                    controllerProvider: function ($stateParams, catalog_data, CatalogService) {
                        switch($stateParams.Interface){
                            case "chart-app":
                            case "chart-mlapp-v2":
                                return CatalogItemChartController;
                            default:
                                return CatalogItemController;
                        }
                    }
                }
            }
        }))

        .state('wsCatalog.list.item.tab', {
            url: '/:tab/:tabItem',
            params: {
                tab: 'versions',
                tabItem: null
            },

            views: {
                "" : {

                    templateProvider: function (pageTabs, $state, $stateParams) {
                        var current = _.findWhere(pageTabs, {title: $stateParams.tab});
                        var t = `<md-tabs md-border-bottom md-dynamic-height  >
                                <md-tab ng-repeat="tab in $ctrl.pageTabs"
                                        md-active="$ctrl.$state.params.tab == tab.title"
                                        ui-sref="{tab: tab.title}"
                                        >
                                    <md-tab-label>
                                        {{tab.title}}
                                    </md-tab-label>
                                </md-tab>
                            </md-tabs>
                            <md-content class="fullInfo padding-content">${current.content}</md-content>`;
                        return t;
                    },

                    controller: function (pageTabs, catalog_data, catalog_dataResource, $state, pageConfig, $stateParams, permissions, CatalogService) {
                        // debugger
                        this.pageTabs = pageTabs;
                        pageConfig.setTitle(catalog_data.Name + ' ' + $stateParams.tab);
                        this.data = catalog_data;
                        this.$state = $state;
                        this.readmeUrl = '/workspace/' + this.$state.params.wsId + '/' + this.$state.params.Interface + '/' + this.$state.params.item + '/readme';
                        this.resource = catalog_dataResource;
                        this.permissions = permissions;
                        this.canManage = permissions.can('mlapp.manage');
                        this.service = CatalogService;
                    },
                    controllerAs: '$ctrl'
                }
            }
        })
}
