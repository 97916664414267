import StateConfig from './catalog_stateconfig'
import ChartsModule from '../charts/charts_module'
import {CatalogItemReadmeComponent} from  './base/readme/cataog_readme_component'
import {CatalogItemVersionComponent} from  './base/version/controller'
import {CatalogItemCommentComponent} from  './base/comments/catalog_comments_list'
import {CatalogCommentItem} from './base/comments/catalog_comment_item'
import {CatalogRatingComponent} from './base/rating'

import {CatalogMenuComponent} from './card/menu'
import {CatalogService} from './catalog_service'
import {CatalogListComponent} from './catalog_list_controller'
import {CatalogItemComponent, CatalogItemSmallComponent} from './catalog_item_controller'
import CatalogTagModule from './tag/catalog_tag_module'


import {CatalogVersionViewItemComponent, CatalogVersionViewItemFullComponent} from './base/version-view/version-item'
import {CatalogVersionTree} from './base/version-view/tree/controller'
import {CatalogVersionTreeV2} from './base/version-view/tree_v2/controller'
import {CatalogVersionViewer} from './base/version-view/viewer/controller'
import {CatalogVersionUploadingFiles} from './base/version-view/uploading-files'
import {CatalogForksComponent} from './base/forks/catalog-forks-component'
import {CatalogSearchComponent} from './catalog_search'

export default angular
    .module('catalog', [
        ChartsModule.name,
        CatalogTagModule.name
    ])
    .config(StateConfig)
    .component('cmpCatalogItemReadme', CatalogItemReadmeComponent)
    .component('cmpCatalogItemVersions', CatalogItemVersionComponent)
    .component('cmpCatalogRating', CatalogRatingComponent)
    .component('cmpCatalogItemComments', CatalogItemCommentComponent)
    .component('cmpCatalogCommentItem', CatalogCommentItem)
    .component('cmpCatalogMenu', CatalogMenuComponent)
    .component('cmpCatalogList', CatalogListComponent)
    .component('cmpCatalogItemSmall', CatalogItemSmallComponent)
    .component('cmpCatalogItem', CatalogItemComponent)
    .component('cmpCatalogForks', CatalogForksComponent)


    .component('cmpCatalogVersionViewItem', CatalogVersionViewItemComponent)
    .component('cmpCatalogVersionViewItemFull', CatalogVersionViewItemFullComponent)
    .component('cmpCatalogVersionTree', CatalogVersionTree)
    .component('cmpCatalogVersionTreeV2', CatalogVersionTreeV2)
    .component('cmpCatalogVersionViewer', CatalogVersionViewer)
    .component('cmpCatalogVersionUploadingFiles', CatalogVersionUploadingFiles)
    .component('cmpCatalogSearch', CatalogSearchComponent)


    .service('CatalogService', CatalogService)
    .constant('CatalogInterface', [
        {label: 'chart-mlapp-v2', Interface: 'chart-mlapp-v2'},
        {label: 'mlmodel', Interface: 'mlmodel'},
        {label: 'inference', Interface: 'inference'},
        {label: 'dataset', Interface: 'dataset'},
        {label: 'chart-app', Interface: 'chart-app'}
    ])
    .factory('CatalogAddButton', function (CatalogInterface) {
        return {
            list :  _.map (CatalogInterface, (v) => {
                return {
                    title :'add_' + v.Interface,
                    icon: "core:" + v.Interface,
                    interface: v.Interface,
                    state: {
                        name: 'wsCatalog.add',
                        params: {Interface: v.Interface}
                    }
                };
            }),
            getBtn : function (Interface) {
                var btnList = [];
                if(Interface)  {
                    var btn = _.findWhere(this.list, {interface : Interface});
                    if(btn) btnList.push(btn);

                }
                else {
                    btnList = this.list;
                }
                return btnList;
            }
        };
    })
