export class InviteService {
    constructor($resource, KuberlabConfigApi) {
        this.invite = $resource(KuberlabConfigApi.APIPath + '/invite/:inviteId/:action',
            {inviteId : '@ID'},
            {
                accept : { method: 'POST', params: {action: "accept"} }
            },
            KuberlabConfigApi.ResourceParams);
    }
}
