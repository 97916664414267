import {ControllerItem} from '../core/controller/item'
import {applicationEditSimplyController} from './simply/applications_edit_simply_dialog'
import {applicationPipController} from './pip/applications_pip_dialog'
import {applicationHistoryController} from './history/applications_history_dialog'
import {applicationEditTemplateController} from './applications_edit_template'


export class ApplicationsItemController extends ControllerItem {

    constructor (
        $state,
        $mdDialog,
        $translate,
        ApplicationsService,
        ProjectService,
        ProjectClusterService,
        $scope,
        $window,
        FileSaver,
        WorkspacePermissions,
        $rootScope,
        application,
        viewTabs
    ) {
        super($mdDialog, $translate);
        var inject = this.inject = angular.element(document.body).injector();
        this.ErrorService = inject.get('ErrorService');
        this.$templateCache = inject.get('$templateCache');
        this.UserConfigService = inject.get('UserConfigService');

        this.WorkspaceService = inject.get('WorkspaceService');
        this.permissions = WorkspacePermissions;
        this.FileSaver = FileSaver;
        this.$state = $state;
        this.$window = $window;
        this.ApplicationsService = ApplicationsService;
        this.ProjectService = ProjectService;
        this.projectsList = [];
        this.$scope = $scope;
        this.applicationsResource = ApplicationsService.getResource($state.params);
        this.ProjectClusterService = ProjectClusterService;
        this.watchWS('application', this.ws_application);
        var view = this.UserConfigService.getWsItemConfig($state.params.wsId, 'project', $state.params.applicationName, 'viewProject');
        this.isAdvanced  =  view == 'advanced' ? true : false;
    }

    setViewProject (){
        this.UserConfigService.setWsItemConfig(this.$state.params.wsId, 'project', this.$state.params.applicationName, 'viewProject', this.isAdvanced ? 'advanced' : 'simply');
        this.$state.transitionTo('wsApplication', _.omit(this.$state.params, 'tab', 'tabItem', 'page', 'limit', 'el') , {
            reload: true, inherit: false, notify: true
        });
    }

    actionForm() {
        super.actionForm();
        this.getWsaList();
    }

    getWsaList() {
        var self = this;
        this.WorkspaceService.Serviceacconts(this.$state.params.wsId).query(
            {},
            function(r){
                self.wsaList = _.filter( r, (v) => { return v.Service == 'docker'; });
            },
            function (r) {
                console.error("Organizations team users request error", r);
            }
        );
    }

    goTo (ev, tab, params = {}) {
        // var params = _.extend({applicationName: this.data.Name}, _.omit(this.$state.params, 'tabItem'), params);')
// debugger
        var params = _.extend({applicationName: this.data.Name}, _.pick(this.$state.params, 'wsId', 'applicationName'), params);
        if(tab){
            if(this.$state.params.tab ==  tab) {
                params.tabItem = this.$state.params.tabItem;
            }
            params.tab = tab;
        }
        // console.log('goTo', params, this.$state.params)
        this.$state.go('wsApplication.tab', params,
            {
                location: true,
                inherit: false,
                notify: false
            }
        );
    }

    saveSuccess(r) {
        this.ApplicationsService._reset('application');
        var data = {applicationName: r.Name};
        if(this.$state.params.tab){
            data.tab = this.$state.params.tab;
        }
        var sName = 'wsApplication.tab';
        if(this.$state.current.name == sName) {

        }
            // this.$state.go(this.$state.current.name, this.$state.params);
            // this.$state.go(this.$state.current.name, this.$state.params, {reload: true, notify: false});
        else
            this.$state.go(sName, data);
        this.actionView();
        this.$mdDialog.hide();
    }

    successDelete (r) {
        // if(!this.isCurrentWs(r.))
        if(this.listController) super.successDelete(r);
        this.ApplicationsService.remove(this.data);
        this.$state.go('wsApplicationsList');
    }

    cancel() {
        // debugger
        if(this.newElement) {
            this.$mdDialog.cancel();
        }
        else
            super.cancel();
            // this.actionView();
    }

    isNew() {
        return (this.data && this.data.Name) ? false : true;
    }

    action(action, params){
        var self = this;
        var ErrorService = this.ErrorService;
        this.apiError = false;
        this.data.DoNotSave = true;
        return this.applicationsResource[action]({wsId: this.$state.wsId, application: this.data.Name}, params)
            .$promise
            .then(
                (resp) => {
                    switch(action){
                        case  'reset' :
                            this.ApplicationsService._reset('experimants');
                            this.$state.go(this.$state.current, { revision: null }, {reload: true});
                            break;
                        case 'enable':
                            self.data.DisableReason = null;
                        case 'disable':
                            self.data.Enabled = (action == 'enable');
                            break;
                    }
                },
                (err) => {
                    ErrorService.error(err);
                }
            )
            .finally(() => {
                delete this.data.DoNotSave;
            });
    }

    scan(repo) {
        var self = this;
        this.scanning = true;
        this.scanError = false;
        this.applicationsResource.scanapplication( repo )
            .$promise
            .then(
                function (r) {
                    self.data = r;
                },
                function (err) {
                    self.scanError = err;
                }
            )
            .finally(function () {
                self.scanning = false;
            })
    }

    downloadURL () {
        return this.ApplicationsService.cfg.APIPath + '/workspace/' + this.$state.params.wsId + '/applications/' + this.data.Name + '/chart';
    }

    download () {
        var self = this;
        this.applicationsResource.download({wsId: this.$state.wsId, application: this.data.Name})
            .$promise
            .then(
                function (r) {
                    self.FileSaver.saveAs(r.response, r.filename);
                },
                function (err) {
                    // debugger;
                    self.$scope.$emit('api_error', err);
                }
            )
            .finally(function () {
                self.scanning = false;
            })
    }

    // _errorDelete(r) {
    //     var tr = {};
    //     var self = this;
    //     this.$translate(["APPLICATION_DELETE_FORCE_TEXT"]).then(function(translations){
    //         tr = translations;
    //     }).finally(function() {
    //         var text =
    //                 '<p>' + r.data.Error + '</p>' +
    //                 '<p>' + tr["APPLICATION_DELETE_FORCE_TEXT"] + '</p>'
    //             ;
    //         // debugger;
    //         var rData = {};
    //         rData[r.data.RegistryData.DeleteAction] = true;
    //         self.deleteConfirm({}, text, 'APPLICATION_DELETE_FORCE_TITLE', null, rData);
    //         // self.deleteConfirm({}, text, 'APPLICATION_DELETE_FORCE_TITLE', null, {force: true});
    //     });
    //
    // }

    ws_application(ev, data) {
        if( this.isCurrentWs(data.WorkspaceName, this.data.WorkspaceName) && this.data.Name == data.Name) {
            if(data.Health != this.data.Health) {this.$scope.$broadcast('applicationChangeStatus')}
            if(data.ContainersCount != this.data.ContainersCount) {this.$scope.$broadcast('applicationChangeContainersCount')}
            if ( data.Uix ) {
                _.each(data.Uix, (uix) => {
                    let i = _.indexOf(this.data.Configuration.spec.uix, _.findWhere(this.data.Configuration.spec.uix, {name: uix.name}));
                    if( i >= 0 ) {
                        _.extend(this.data.Configuration.spec.uix[i], uix);
                    }
                });
            }
            this.data = _.extend(this.data, _.omit(data, 'Uix'));
            this.$scope.$apply();
        }
    }

    isActive () {
        if( this.data.Enabled ) {
            return true;
        } else {
            if( this.data.ContainersCount ) {
                return true;
            } else {
                return false;
            }
        }
    }

    dialog(action) {
        var template, controller;
        switch (action) {
            case 'simply':
                template = 'kuberlab/applications/simply/applications_edit_simply_dialog.html';
                controller = applicationEditSimplyController;
                break;
            case 'pip':
                template = 'kuberlab/applications/pip/applications_pip_dialog.html';
                controller = applicationPipController;
                break;
            case 'history':
                template = 'kuberlab/applications/history/applications_history_dialog.html';
                controller = applicationHistoryController;
                break;
            case 'serve':
                template = 'kuberlab/applications/card/applications_serve_dialog.html';
                controller = function($scope, application, appController, $state) {
                    this.application = application;
                    this.appController = appController;
                    // var source = application.Configuration ? _.fiend(application.Configuration.spec.volumes, {name: 'data'}) : null;
                    // this.data = {
                    //     WorkspaceName: application.WorkspaceName,
                    //     ApplicationName: application.Name
                    // };
                    this.config = {
                        WorkspaceName: application.WorkspaceName,
                        ApplicationName: application.Name,
                        "ApplicationSource": null, //source ? "data" : application.Configuration.spec.volumes[0].name,
                        "ApplicationSourceSubPath": "", // "sub/dir",
                        "Name": application.Name,
                        "Config": {
                            "spec": {
                                "params": [
                                    {
                                        "name": "input",
                                        "type": "bytes"
                                    },
                                    {
                                        "name": "email",
                                        "type": "string"
                                    }
                                ]
                            }
                        }
                    };

                    var self = this;
                    $scope.cancel = function () {
                        self.appController.$mdDialog.cancel();
                    };

                    $scope.serve = () => {
                        // debugger
                        $scope.error = false;
                        self.loading = true;

                        self.appController.applicationsResource.serving({wsId: self.application.WorkspaceName, application: self.application.Name}, self.config)
                            .$promise
                            .then(
                                (resp) => {
                                    self.appController.$mdDialog.cancel();
                                    $state.go('wsServing.tab', { wsId: self.application.WorkspaceName, servingName: resp.Name})
                                },
                                (err) => {
                                    ErrorService.error(err);
                                }
                            );
                    };
                }
        }

        this.$mdDialog.show({
            locals: {
                appController : this,
                application: this.data
            },
            controllerAs: '$ctrl',
            // controller:  applicationEditTemplateController,
            controller:  controller,
            template: this.$templateCache.get(template),
            parent: angular.element(document.body),
            clickOutsideToClose:false,
        })
            .then( (params) => {
                // this[action](params.comment, params.newBranch, params.DoNotSave);

            }, () => {
                // debugger;
            });
    }

    desableConfirm (ev, text, title, success, successData){
        success = success || this.delete;
        this.confirmMessage(ev, 'Project will be disabled. All active jobs will be stopped.', 'Disable project', 'COMMON_DISABLE', 'COMMON_CANCEL', this.action, ['disable']);
    }
}


class item extends ApplicationsItemController {
    constructor(
            $state,
            $mdDialog,
            $translate,
            ApplicationsService,
            ProjectService,
            ProjectClusterService,
            $scope,
            $window,
            FileSaver,
            WorkspacePermissions) {
        super(...arguments)
    }
}

export const ApplicationsListItemComponent = {
    bindings: {
        data: '=',
        listController: '<'
    },
    controller: item,
    template: function ($templateCache) {
        return $templateCache.get('kuberlab/applications/card/applications_list_item.html');
    }
}
