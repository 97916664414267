
class controller {

    $onInit(){
        this.setLisnkData();
    }

    setLisnkData() {
        this.listData = _.extend({}, this.listController.$state.params, {tabItem: this.data.Version});
    }

    datasetName() {
        return this.listController.data.Name;
    }

    getFile(url, file){
        this.fileUrl = url;
        this.fileInfo = file;
    }

    fileDelete(url, file, cb = () => {}){
        return this.listController.resource.fileDelete({name: this.datasetName(), version: this.data.Version, path: url}).$promise
            .then (
                (r) => {
                    cb();
                },
                (err) => {
                    this.error = err;
                }
            )
    }

    cancel() {
        this.listController.removeVersion(this.data);
    }

    saveNew(){
        var data = {
            "Version": this.newVersion,
            "From": this.data.From
        };
        this.listController.resource.versionCreate({name: this.datasetName()}, data).$promise
            .then(
                (r) => {
                    this.data = r;
                    this.activate();
                    // this.listController.version = this.data;
                    this.setLisnkData();
                },
                (err) => {}
            )
            .finally(() => {

            })
    }

    commit() {
        this.listController.resource.versionCommit({name: this.datasetName(), version: this.data.Version}, {})
            .$promise
            .then (
                (r) => {
                    var i = _.indexOf(this.listController.versions, this.data);
                    debugger
                    this.listController.versions[i] = r;
                    this.data = r;
                },
                (err) => {
                    this.error = err;
                }
            )
    }

    activate() {
        this.listController.getItem(this.data);
        // this.listController.$state.go( this.listController.$state.current.name, this.listData, {notify:false, location: true});
        // this.listController.version = this.data;
    }

    uploadFile(form) {
        _.each(form.file.$$element[0].files, (f) => { this._uploadFile(f)})
    }

    _uploadFile (file) {

        let path = this.fileUrl ? this.fileUrl + '/' : '';
        if( !this.uploadingFiles )this.uploadingFiles = [];

        var fileInfo = {
            dir:false,
            path: path,
            listPath: this.fileUrl ? this.fileUrl.split('/') : [],
            name: file.name,
            size: file.size,
            file: file,
            Version: this.data.Version
        };

        this.uploadingFiles.push(fileInfo);
        if(this.treeApi) this.treeApi.add(fileInfo);
    }

    addFolder() {
        this.treeApi.createNewFolder();
    }
}

export const DatasetVersionItemComponent = {
    bindings: {
        data: '=',
        listController: '<'
    },
    controller: controller,
    template:  ($templateCache) => {
        return $templateCache.get('kuberlab/catalog_data/version/version-item-template.html');
    }
}
