
import {ControllerList} from '../core/controller/list'

export class ChartsListController extends ControllerList {
    constructor (ChartsService, $scope, $state, $localStorage, $rootScope, $element, $mdDialog, UserService, WorkspacePermissions, chartTypes) {

        super();
        this.ws = UserService.ws;
        this.storage = $localStorage;
        this.$element = $element;
        this.chartTypes = chartTypes;
        this.list = [];
        this.$scope = $scope;
        this.$state = $state;
        this.ChartsService = ChartsService;
        this.isWs = !!this.$state.params.wsId;
        this.search = $state.params.search;
        this.page = this.$state.params.page || 1;
        this.limit = this.$state.params.limit || 30;
        this.type = this.$state.params.type;
        $localStorage.$default({
            chartView: true
        });
        this.grid = this.storage.chartView;
        this.listEmpty = false;
        this.permissions = WorkspacePermissions;
    }

    $onInit () {
        this.resource = this.ChartsService.getResource(this.$state.params);
        var self = this;
        this.$scope.$watch(
            function () {
                return self.page;
            },
            function () {
                self.getList(true);
            }
        );
        // this.setView();
    }

    getList (update, page, clear) {
        var self = this;
        var data = {
            page: this.page,
            limit: this.limit,
            type: this.type
        };
        data.search = this.search;
        if (this.withGlobal) {
            data.with_global = true;
        }

        this.isLoading = true;
        this.ErrorList = false;
        this.listPromise = this.ChartsService.getList(update, data, clear);
        this.listPromise
            .then(
                function (r) {
                    self.list = r;
                    self.listEmpty = (r && r.length == 0);
                    self.$state.go( self.$state.current.name, data, {notify:false, location: true})
                },
                function (err) {
                    self.ErrorList = err;
                }
            )
            .finally(function () {
                self.isLoading = false;
            });

    }

    find () {
        this.page = 1;
        this.getList(true, this.page);
    }

    star(data) {
        var action = data.Star ? "$unstar" : "$star";
        data[action]({wsId: data.WorkspaceName, chartName: data.Name})
            .then(
                function (r) {
                    // debugger;
                },
                function (err) {
                    // debugger;
                }
            )
    }

    changeView (list  ) {
        this.grid = !this.grid;
        this.storage.chartView = this.grid;
        // this.setView();
    }

    isMlapp(item){
        return this.ChartsService.isMlapp(item);
    }

    goTo(type) {
        // debugger;
        this.$state.go(this.$state.current.name, {type: type});
    }

    setView () {
        // debugger;
        // if(this.grid) {
        //     $(this.$element).find('.chartList').removeClass('line_view');
        //     $(this.$element).find('.chartList').addClass('col_3_view');
        // } else {
        //     $(this.$element).find('.chartList').addClass('line_view');
        //     $(this.$element).find('.chartList').removeClass('col_3_view');
        // }
    }
}

