class controller {

    constructor($scope, $mdDialog){
        this.$scope = $scope;
        this.isOpen = false;
        this.deleting = [];
        this.limit = 50;
        this.limitStep = 50;

        this.currentDir = [{}];
        this.$mdDialog = $mdDialog;
        // this.topIndex = 0;
    }

    $onInit() {
        this.getTree();
    }

    getTree(){
        let path = this.parentPath();
        if(this.currentDir[this.currentDir.length - 1].new ){
            this.chield = [];
        } else {
            this.loading = true;
            this.error = false;
            this.resource.tree(
                {
                    name: this.ctrl.datasetName(),
                    version: this.ctrl.data.Version,
                    path: path,
                    filter: ( this.treeFilter && this.treeFilter.length ) ? this.treeFilter: undefined
                }).$promise
                .then (
                    (r) => {
                        this.chield = r;
                        _.each(this.chield, (v) => {
                            if( !v.dir ) {
                                v.sSize = this.setSize(v.size);
                            }
                        });
                    },
                    (err) => {
                        this.error = err;
                    }
                )
                .finally(() => {
                    this.loading = null;
                })
        }
    }

    setSize(size) {
        return this.ctrl.listController.CatalogService.datasetVersionSize({SizeBytes: size})
    }

    selectParent(index) {
        this.currentDir = this.currentDir.slice(0, index);
        this.getTree();
    }

    selectItem(item, ev) {
        if(item.dir) {
            this.currentDir.push(item);
            this.getTree();
        } else {
            this.$mdDialog.show({
                locals: {
                    pc: this,
                    item: item
                },
                controllerAs: '$ctrl',
                controller: function($scope, pc, item, $mdDialog) {
                    $scope.item = item;
                    $scope.pc = pc;
                    $scope.path = pc.parentPath();
                    $scope.path = $scope.path ?  $scope.path  + item.name : item.name;
                    $scope.folderName = '';
                    $scope.cancel = function () {
                        $mdDialog.cancel();
                    };
                    $scope.complite = function (data) {
                        $mdDialog.hide(data);
                    }
                },
                template: `
                <md-dialog style="max-height: 95%;">
                    <md-toolbar>
                        <div class="md-toolbar-tools">
                            <h4 translate>File {{item.name}}</h4>
                            <span flex></span>
                            <md-button class="md-icon-button" ng-click="cancel()">
                                <md-icon md-svg-src="core:close" aria-label="Close dialog"></md-icon>
                            </md-button>
                        </div>
                    </md-toolbar>
                    <md-dialog-content class="md-padding" style="min-width: 500px">
                        <cmp-catalog-version-viewer ctrl="pc.ctrl" file-url="path" file-info="item" flex layout="column" layout-align="start"></cmp-catalog-version-viewer>
                    </md-dialog-content>
                </md-dialog>
            `,
                parent: angular.element(document.body),
                targetEvent: ev,
            })
                .then(
                    (params) => {},
                    () => {}
                );
        }
    }

    more(){
        this.limit = this.limit + this.limitStep;
    }

    hasMore() {
        if(this.chield)
            return this.chield.length > this.limit;
        else return false;
    }

    parentPath() {
        let p = _.map(this.currentDir.slice(1), (v) => v.name ).join('/');
         p = p + (p.length > 0 ? "/" : "");
         return p;
        //
        // return _.map(this.currentDir.slice(1), (v) => v.name ).join('/') + '/';
    }

    addFolder(ev) {
        this.$mdDialog.show({
            locals: {
                pc: this
            },
            controllerAs: '$ctrl',
            controller: function($scope, pc, $mdDialog) {
                $scope.path = pc.parentPath();
                // $scope.path = $scope.path ? '/' + $scope.path + '/' : '/';
                $scope.folderName = '';
                $scope.cancel = function () {
                    $mdDialog.cancel();
                };
                $scope.complite = function (data) {
                    $mdDialog.hide(data);
                }
            },
            template: `
                <md-dialog>
                    <form name="addfolder" ng-submit="complite(folderName)">
                        <md-toolbar>
                            <div class="md-toolbar-tools">
                                <h4 translate>Create folder</h4>
                                <span flex></span>
                                <md-button class="md-icon-button" ng-click="cancel()">
                                    <md-icon md-svg-src="core:close" aria-label="Close dialog"></md-icon>
                                </md-button>
                            </div>
                        </md-toolbar>
                        <md-dialog-content>
                            <div class="layout-row layout-align-start-center md-padding"   >
                                <md-input-container>{{path}}</md-input-container>
                                <md-input-container>
                                    <input ng-model="folderName" focus-me required />
                                </md-input-container>
                            </div>
                        </md-dialog-content>
                        <md-dialog-actions layout-align="center">
                            <md-button type="submit" ng-disabled="!addfolder.$valid" class="md-primary md-raised">{{"COMMON_ADD" | translate}}</md-button>
                        </md-dialog-actions>
                    </form>
                </md-dialog>
            `,
            parent: angular.element(document.body),
            targetEvent: ev,
        })
        .then(
            (params) => {
                this.selectItem(this.newFolder(params));
            },
            () => {}
        );
    }

    addFiles(ev) {
        this.$mdDialog.show({
            locals: {
                pc: this
            },
            controllerAs: '$ctrl',
            controller: function($scope, pc, $mdDialog) {
                $scope.pc = pc;
                $scope.path = pc.parentPath();
                // $scope.path = $scope.path ? '/' + $scope.path + '/' : '/';
                $scope.folderName = '';
                $scope.cancel = function () {
                    $mdDialog.cancel();
                };
                $scope.complite = function (data) {
                    $mdDialog.hide(data);
                }
            },
            template: `
                <md-dialog>
                        <md-toolbar>
                            <div class="md-toolbar-tools">
                                <h4 translate>Add file</h4>
                                <span flex></span>
                                <md-button class="md-icon-button" ng-click="cancel()">
                                    <md-icon md-svg-src="core:close" aria-label="Close dialog"></md-icon>
                                </md-button>
                            </div>
                        </md-toolbar>
                        <md-dialog-content class="md-padding">
                            <form name="upload" ng-submit="pc.uploadFile(upload)"  layout="column" layout-wrap layout-align="center">
                                <md-input-container flex="100"><label>upload path</label><input name="path" ng-model="path" ng-disabled="true"></md-input-container>
                                <input type="file" name="file" ng-model="pc.file" multiple ng-change="debugger; upload.submit()" required click-me />
                                <md-button type="submit" class="md-primary"  >upload</md-button>
                            </form>
                            <md-content flex ng-if="pc.uploadingFiles && pc.uploadingFiles.length">
                                <md-divider></md-divider>
                                <cmp-catalog-version-uploading-files  ng-repeat="f in pc.uploadingFiles" data="f" ctrl="pc.ctrl" on-update="pc.fileUoload($event)" ></cmp-catalog-version-uploading-files>
                            </md-content>
                        </md-dialog-content>
                </md-dialog>
            `,
            parent: angular.element(document.body),
            targetEvent: ev,
        })
        .then(
            (params) => {},
            () => {
                this.uploadingFiles = null;
            }
        );
    }

    newFolder(name) {
        return {dir: true, new: true, name: name}
    }

    uploadFile(form) {
        _.each(form.file.$$element[0].files, (f) => {
            this._uploadFile (f)
        });

        form.file.$$element[0].value = null;
    }

    _uploadFile (file) {
        if( !this.uploadingFiles )this.uploadingFiles = [];
        var fileInfo = {
            dir:false,
            path: this.parentPath(),
            name: file.name,
            size: file.size,
            file: file,
            modtime: new Date(),
            sSize: this.setSize(file.size),
            Version: this.ctrl.data.Version
        };
        console.log(fileInfo);
        this.uploadingFiles.push(fileInfo);
    }

    fileUoload(event) {
        switch (event.type) {
            case 'file upload':
                let i, item = _.omit(event.data, 'file', 'Version', 'path');
                if( (i = _.indexOf(this.chield, _.findWhere(this.chield, {name: event.data.name, dir: event.data.dir}))) > -1 ){
                    this.chield[i] = item;
                } else {
                    this.chield.push(item);
                }
                if(this.currentDir[ this.currentDir.length - 1 ].new){
                    _.each(this.currentDir, (i) => {  i.new = false; })
                }
                break;
            case 'file complite':
                this.uploadingFiles = _.without(this.uploadingFiles, event.data);
                break;
        }
    }

    getRequestPath(item) {
        debugger;
        return (this.path ? this.path + '/' : '') + item.name ;
    }

    // selectItem(item, open){
    //     if(!this.edit || (this.edit && item.dir)){
    //         if(item.dir){
    //             item.api.click(open, item);
    //         }
    //         this.ctrl.getFile( this.getRequestPath(item) , item);
    //     }
    // }

    // click(open, item) {
    //     if(this.isOpen && item && this.ctrl.fileInfo != item) open = true;
    //
    //     if(open)
    //         this.isOpen = true;
    //     else
    //         this.isOpen = !this.isOpen;
    //
    //     if(!this.chield){
    //         this.getTree();
    //     }
    // }
    //
    // add(file){
    //     this.isOpen = true;
    //     if(!this.chield) this.chield = [];
    //     let chDir = file.listPath[0];
    //     if( chDir ) {
    //         let chTreeDir = _.findWhere(this.chield, {dir:true, name: chDir});
    //         file.listPath.splice(0,1);
    //         if(chTreeDir) {
    //             if(!chTreeDir.api){
    //             // if(!chTreeDir.api && chTreeDir.new){
    //                 chTreeDir.chield.push(file);
    //             }else{
    //                 chTreeDir.api.add(file);
    //             }
    //         } else {
    //             chTreeDir = _.findWhere(this.chield, { name: chDir});
    //             if(chTreeDir){
    //                 let item = _.indexOf(this.chied, chTreeDir);
    //                 this.chield[item] = file;
    //             } else {
    //                 this.chield.push({
    //                     dir: true,
    //                     name: chDir,
    //                     chield:[file],
    //                     new: true
    //                 });
    //             }
    //         }
    //     }
    //     else {
    //         let chTreeDir = _.findWhere(this.chield, { name: file.name});
    //         // debugger;
    //         if(chTreeDir){
    //             let item = _.indexOf(this.chied, chTreeDir);
    //             this.chield[item] = file;
    //         }else{
    //             this.chield.push(file);
    //         }
    //     }
    // }

    delete (item, event) {
        event.stopPropagation();
        if(!this.deleting ) this.deleting = [];
        this.deleting.push(item.name);
        this.delError = null;
        // var req = this.ctrl.fileDelete( this.getRequestPath(item) , item, () => {
        //     // this.removeFile(item);
        //     // this.ctrl.getFile()
        // });
         var req = this.ctrl.fileDelete( this.parentPath() + '/' + item.name, () => {
            // this.removeFile(item);
            // this.ctrl.getFile()
        });

        req.then(
            (r) => {
                this.removeFile(item);
                this.ctrl.getFile()
            },
            (err) => {
                this.delError = err;
            }
        )
            .finally( () => {
                this.deleting = _.without(this.deleting, item.name);
            })
    }

    removeFile(item){
        this.chield = _.without(this.chield, item);
    }

    findFile(file) {
        var item,
            path = file.path;

        if(path[path.length - 1] == '/') {
            path = path.substring(0, path.length - 1)
        }

        if(path == this.path && this.chield) {
            item = _.findWhere(this.chield, {name: file.name});
        }
        return item;
    }

    removeNewFile (file) {
        var item = this.findFile(file);
        if(item)  {
            this.removeFile(item);
        }
        else {
            if(this.chield){
                _.each(this.chield, (c) => {
                    if(c.dir) c.api.removeNewFile(file);
                })
            }
        }
    }

    addError(file){
        var item = this.findFile(file);
        if(item)  {
            var index = _.indexOf(this.chield, item);
            if(index != -1) {
                this.chield[index].error = true;
            }
        }
        else {
            if(this.chield){
                _.each(this.chield, (c) => {
                    if(c.dir) c.api.addError(file);
                })
            }
        }
    }

    createNewFolder() {
        if(this.data == this.ctrl.fileInfo){
            this.newFolderCreating = {dir: true, new: true, name: '', chield: []}

        }else{
            if(this.chield){
                _.each(this.chield, (c) => { if(c.api) c.api.createNewFolder() });
            }
        }
    }

    resetNewFolder() {
        this.newFolderCreating = null;
    }

    newFolderChange (form) {
        if(!this.chield) this.chield = [];
        var i = _.findWhere(this.chield, {name: this.newFolderCreating.name});
        if( !this.newFolderCreating.name || i) {
            form.Name.$setValidity("errorname", false);
        }else{
            form.Name.$setValidity("errorname", true);
        }
    }


    saveFolderCreating(form) {
        if(!form.$valid || !this.newFolderCreating.name.length) return false;
        this.chield.push(this.newFolderCreating);
        var name = this.newFolderCreating.name;
        this.newFolderCreating = null;
        _.delay(()=>{
            var item = _.findWhere(this.chield, {name: name, dir: true});
            this.selectItem(item, true);

        }, 500)
    }

}

export const CatalogVersionTreeV2 = {
    bindings: {
        data: '=',
        path: '<',
        ctrl: '<',
        resource: '<',
        api: '=',
        edit: '<',
        listController: '<',
        treeFilter: '<'

    },
    controller: controller,
    template:  ($templateCache) => $templateCache.get('kuberlab/catalog/base/version-view/tree_v2/tpl.html')

};
