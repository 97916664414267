
import rTasks from './tasks'
import rUi from './ui'


function stringToColour (str) {
    var hash = 0;
    var inject = angular.element(document.body).injector();
    var md5 = inject.get('md5');
    str= md5.createHash(str);
    for (var i = 0; i < str.length; i++) {
        let code = str.charCodeAt(i);
        hash = (code * code) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (var i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
}


export class d3Serie {
    constructor(key, color, values) {
        this.key = key;
        this.color = color ? color : stringToColour(key);
        this.setVolues(values);
    }

    setVolues (values) {
        this.values = values ? values : [];
    }
}

export class d3Metric {
    constructor(name, unit){
        this.series = [];
        this.name = name;
        this.unit = unit;
        this.count = 0;
    }

    addSerie(name, values){
        let s = this.seriesByName(name, true);
        if( !s.length ){
            this.series.push( new d3Serie( name, null, values ));
        } else {
            _.each(s, (si) => {
                si.setVolues(values);
            })
        }
    }

    addSeries(metrics, elements){

        let add = {};
        let added = {};
        let hasSeries = {};
        _.each( metrics.metrics, (m) => {
            _.each(this.seriesByName(m.component_name_paremt), (s) => {
                hasSeries[s.key] = true;
            });
            let name = this.nameSeries(m.component_name, m.component_name_paremt);
            this.addSerie(name, m.values);
            add[ m.component_name_paremt ] = true;
            added[ m.component_name ] = true;
            added[ name ] = true;
            add[ m.component_name ] = true;
        });

        _.each( elements, (e) => {
            if( !add[e] ) {
                this.addSerie(e);
                added[e] = true;
            }
        });
        _.each(hasSeries, (v,k) => {
            if(!added[k])
                this.removeSerie(k, true);
        });

        this.count = this.count + 1;
    }

    seriesByName(name, strictly) {
        return _.filter( this.series, (s) => {
            return strictly ? s.key == name : s.key.search(name) != -1;
            return strictly ? s.key == name : s.key.search('^' + name) != -1;
        })
    }

    nameSeries(n, pn) {
        return (!pn || n == pn) ? n : pn  + ":"  + n;
    }

    removeSerie(name, strictly) {
        var s = this.seriesByName(name, strictly);
        _.each(s, (ss) => {
            this.series = _.without(this.series, ss);
        });
        this.count = this.count + 1;
    }
}

export class d3Metrics {
    constructor () {
        this.clear();
    }

    addMetris (metrics, elements, metricsList) {
       _.each( metricsList , ( mn ) => {
           var  m = metrics[mn] ? metrics[mn] : {unit: ''};
           if( !this.metrics[mn] ) this.metrics[mn] = new d3Metric(mn, m.unit);
           this.metrics[mn].addSeries(m, elements)
       })
    }

    removeSevies(name){
        _.each(this.metrics, (m) => {
            m.removeSerie(name);
        })
    }

    removeMetric(name){
        delete this.metrics[name];
    }

    clear() {
        this.metrics = {};
    }

}

export class controller {
    constructor(
        // ApplicationsMetricsService,
        ApplicationsService, applicationMetricsList, $state, $localStorage, $mdSidenav, $filter, $scope, $rootScope, $transitions) {
        this.$rootScope = $rootScope;
        this.$state = $state;
        this.$scope = $scope;
        this.$localStorage = $localStorage;
        this.$filter = $filter;
        this.$mdSidenav = $mdSidenav;
        this.$transitions = $transitions;
        this.appResource = ApplicationsService.getResource($state.params);
        this.ApplicationsService = ApplicationsService;
        this.details = false;
        var self = this;
        $scope.$watch(
            ()=>{
                return self.ApplicationsService.tasksList;
            },
            (c) => {
                self.tasks = c;
            }
        );
        this.mElement = {};
        this.tasks = [];
        this.staticElements = [
            {
                name: 'ui'
            },
            {
                name: 'serving'
            }
        ];
        this.Metrics = {};
        this.ChartOptions = {legend: {display: true}};
        this.ChartSeries = [];
        this.ChartLabels = [];

        this.activeMetrics = {};
        this.initMetricList(applicationMetricsList);

        this.intervals = [
            { value: '10s', title: '10s' , s : 10},
            { value: '30s', title: '30s', s : 30},
            { value: '1m', title: '1m', s : 60},
            { value: '5m', title: '5m', s : 300},
            { value: '30m', title: '30m', s: 1800},
            { value: '1h', title: '1h', s: 3600 }
        ];

        this.timeIntervals = [
            {value: 5 * 60 *1000, title: '5 minutes'},
            {value: 15 * 60 *1000, title: '15 minutes'},
            {value: 30 * 60 *1000, title: '30 minutes'},
            {value: 60 * 60 *1000, title: '1 hour'},
            {value: 3 * 60 * 60 *1000, title: '3 hour'},
            {value: 6 * 60 * 60 *1000, title: '6 hour'},
            {value: 12 * 60 * 60 *1000, title: '12 hour'},
            {value: 24 * 60 * 60 *1000, title: '1 day'},
        ];

        this.interval = '5m';
        this.timeInterval = 60 *60*1000;

        this.responsData = {};
        var bd = new Date().setSeconds(0,0);
        this.startTime = new Date( new Date(bd) - (60*60*1000));
        this.endTime = new Date(bd);

        this.timeInterval = {
            start: null,
            stop: null,
            interval: 60 * 60 * 1000
        };

        this.equal = [];
        this.orderMetric = [];

        this.d3Data = {};
        this.chartUpdateCount = 0;
        this.d3Metrics = new d3Metrics();
        this.taskListShow = false;
        window.d3MetricData = this.d3Metrics;
        window.prMetrics = this;
    }

    initMetricList (list, unlabel) {
        var useMetrics = this.$localStorage.applicationMetricsList;
        _.each(list, (i, k) => {
            if(!this.Metrics[i]) {
                this.Metrics[i] = {
                    active: _.indexOf(useMetrics, i) != -1,
                    labels: [],
                    series: [],
                    data: [],
                    name: i,
                    title: ~i.indexOf(unlabel) ? i.replace(unlabel, '').replace('_', ' ') : 'MLAPP_METRIC_' + i,
                    uploadStep: this.uploadStep,
                    options: this.nvd3Options('MLAPP_METRIC_' + i)
                };
            }
        });
        // console.info(this.Metrics)
    }


    $onInit(){
        this.init();
        this.nvd3Options();
        var self = this;
        this.listenerState = this.$transitions.onStart(
            { entering: 'wsApplication.tab' },
            (transition, state) => {
                let params =  transition.params();
                if(params.tab == 'metrics') {
                    this.init();
                    _.delay(() => {
                        _.each(self.Metrics, (v, k) => {
                            if(self.Metrics[k].api && self.Metrics[k].api.refresh) self.Metrics[k].api.refresh();
                        });
                    }, 200);
                }
            });
    }

    $onDestroy() {
        this.listenerState();
    }

    init(){

        if (this.isOnCurrentPage()) {
            this.getTasks();
        }
    }

    changeMetricsList(update) {
        // debugger;
        var newlist = this.getMetricsList();
        var oldList = this.$localStorage.applicationMetricsList || [];
        this.$localStorage.applicationMetricsList = newlist;
        if(oldList.length < newlist.length) {
            this.updateMetrics();
        }
    }

    updateMetrics() {
        this.ChartLabels = [];

        switch (this.currentAction){
            case "applicationGraph":
                this.getApplicationGraph();
                break;
            case "taskGraph":
                // _.each(_.keys(this.mElement), (v) => {
                // debugger;
                _.each( this.orderMetric, (v) => {
                    var build = v.split('-').slice(-1)[0];
                    var task_name = v.replace('-' + build, '');
                    var item = _.findWhere(this.tasks, {task_name: task_name, build: build});
                    if(item)
                        this.getTaskGraph(item, v);
                });

                break;
        }
    }

    isOnCurrentPage() {
        return this.$state.params.tab === 'metrics';
    }

    getTasks() {
        this.apiError= false;
        this.ApplicationsService.getTasks(this.$state.params)
            .then(
                () => {},
                (err) => {
                    this.apiError = err;
                }
            );
    }

    setMetricElement(item, action){
        var elName;
        switch(action){
            case 'applicationGraph':
                elName = item.name;
                break;
            case 'taskGraph':
                elName = item.task_name + '-' + item.build;
                break;

        }
        if(this.currentAction != action){
            _.each(this.mElement, (v, k) => { this.removeSeries(k); } );

            this.d3Metrics.clear();

            this.mElement = {};
            this.mElement[elName] = true;
            this.currentAction = action;
            this.ChartLabels = [];
            this.orderMetric = [];
        }

        if(this.mElement[elName]){
            this.orderMetric.push(elName);
            switch (action){
                case 'applicationGraph':
                    this.getApplicationGraph(item, elName);
                    break;
                case 'taskGraph' :
                    this.getTaskGraph(item, elName);
                    break;
            }
        }else{
            this.orderMetric = _.without(this.orderMetric, elName);
            this.removeSeries(elName);
            this.removeD3Series(elName, true);
            this.d3Metrics.removeSevies(elName);


            // this.Metrics[elName].api.refresh();
            _.delay(() => {
                _.each(this.Metrics, (v, k) => {
                    if(this.Metrics[k].api && this.Metrics[k].api.refresh) this.Metrics[k].api.refresh();
                });
            }, 200);

            delete this.mElement[elName];

        }
    }

    getSeriesByName(name, strictly) {
        return _.filter(this.ChartSeries, (n) => {
            return strictly ? n == name : n.search('^' + name) != -1;
        });
    }

    removeSeries(name, strictly) {
        var series = this.getSeriesByName(name, strictly);
        _.each(series, (n) => {
            var p = _.indexOf(this.ChartSeries, n);

            if(p != -1 ){
                _.each(this.Metrics, (v, k) => {
                    this.Metrics[k].data.splice(p, 1);
                });
                this.ChartSeries.splice(p,1);
            }
        });
        this.ChartLabels = [];
        _.each(this.ChartSeries, (s)=>{
            var data;
            if(data = this.responsData[s]){
                this.addSeries(data.data,data.name, data.labelsAsIteration);
            }
      })
    }

    addSeries(data, name, labelsAsIteration, task){
        if(!name) return ;
        this.responsData[name] = {data: data, labelsAsIteration: labelsAsIteration, name: name};
        var metrics = data;
        var p = _.indexOf(this.ChartSeries, name);

        _.each(this.getMetricsList(), (s, k) => {
            var met = _.findWhere(data, {name: s}) || {};
            var m = _.map(met.values || [], (r) => {
                return r.value;
            });

            if(p == -1) {
                this.Metrics[s].data.push(m);
            }else{
                this.Metrics[s].data[p] = m;
            }
            this.Metrics[s].unit = met.unit || this.Metrics[s].unit;

            var i = 0;
            var l = _.map(met.values || [], (r) => {
               return labelsAsIteration ? i++ : this.$filter('date')(r.ts * 1000, 'h:mma');
            });

            if(this.ChartLabels.length <= l.length) this.ChartLabels = l;

        });

        if(p == -1){
            this.ChartSeries.push(name);
        }
    }

    getMetricsList(){
        return _.map(_.filter(this.Metrics, {active: true}), (m) => {return m.name});
    }

    getApplicationGraph(item, name) {
        var elements = [];
        if(this._mRequest) this._mRequest.$cancelRequest();

        _.each(this.mElement, (v, k) => {
            if(v) elements.push(k);
        });

        var respData = {
            // details: this.details,
            metrics: this.getMetricsList().join(','),
            component_type: elements.join(','),
            application: this.appController.data.Name
        };

        _.extend(respData, this.requestTimeInterval());
        this.loading = true;

        // this._mRequest = this.mResource.applicationGraph(respData);
        this._mRequest = this.appController.applicationsResource.applicationGraph(respData);
        this.apiError= false;
        this._mRequest.$promise
            .then(
                (r) => {
                    // this.addMultyMetric( r.metrics, null, elements );
                    let d3M = {};
                    _.each(r.metrics, (m) => {
                        _.each(m.metrics, (mm) => {
                            if( !d3M[mm.name] ) { d3M[mm.name] = { metrics: {}} }
                            // if( !d3M[mm.name].metrics[m.component_name] ) { d3M[mm.name].metrics[m.component_name] = {component_name: m.component_name, component_name_paremt : name}; }
                            if( !d3M[mm.name].metrics[m.component_name] ) { d3M[mm.name].metrics[m.component_name] = {component_name: m.component_name, component_name_paremt : m.component_type}; }
                            d3M[mm.name].metrics[m.component_name].values = this.mapData(mm.values);
                            d3M[mm.name].unit = mm.unit;
                        })
                    });
                    this.d3Metrics.addMetris(d3M, [], this.getMetricsList()  );
                },
                (err) => {
                    if(err.status != -1) {
                        this.apiError = err;
                    }
                }
            )
            .finally(()=>{
                this.loading = false;
            })
    }

    addMultyMetric( metrics, labelsAsIteration, elements, name ){
        let add = {};
        let added = {};
        let metric = this.$filter('orderBy')( metrics, ['component_type', 'component_name'] );
        let seriesByName = this.getSeriesByName(name);

        _.each(metrics, (el) => {
            var type = el.component_type == el.component_name ? el.component_name : el.component_type + ":" + el.component_name;
            if(el.component_name != "-") {
                this.addSeries(el.metrics, type, labelsAsIteration, name);
                added[type] = true;
                add[el.component_type] = true;
            }
        });

        _.each(elements, (v) => {
            if (!add[v]) {
                this.addSeries({metrics: []}, v, labelsAsIteration);
                added[v] = true;
            }
        });

        _.each( seriesByName, (v) => {
            if( !added[v] ) {
                this.removeSeries(v, true);
            }
        });

        this.addD3Series(...arguments);
    }




    getTaskGraph(item, name) {
        var respData = {
            taskName: item.task_name,
            build: item.build,
            interval: this.interval,
            metrics: this.getMetricsList().join(','),
            details: this.details,
            application: this.appController.data.Name
        };
        var elements = [name];
        this.loading = true;
        this.apiError= false;
        // debugger;
        // this.mResource.taskGraph(respData).$promise

        this.appController.applicationsResource.taskGraph(respData).$promise
            .then(
                (r) => {
                    let d3M = {};
                    _.each(r.metrics, (m) => {
                        _.each(m.metrics, (mm) => {
                            if( !d3M[mm.name] ) { d3M[mm.name] = { metrics: {}} }
                            if( !d3M[mm.name].metrics[m.component_name] ) { d3M[mm.name].metrics[m.component_name] = {component_name: m.component_name, component_name_paremt : name}; }
                            d3M[mm.name].metrics[m.component_name].values = this.mapData(mm.values);
                            d3M[mm.name].unit = mm.unit;
                        })
                    });
                    this.d3Metrics.addMetris(d3M, elements, this.getMetricsList()  );
                    // console.info(d3M,this.d3Metrics)

                },
                (err) => {
                    this.apiError=err;
                    this.$scope.$emit('api_error', err);
                }
            )
            .finally(()=>{
                this.loading = false;
            })
    }

    isOpenSetting (){
        return this.$mdSidenav('setting').isOpen();
    }

    toogleSetting(){
        this.$mdSidenav('setting')
            .toggle()
            .then(function () {
                $log.debug("toggle " + navID + " is done");
            });
    }

    toISOString (date) {
        return this.$filter('date')(date, 'yyyy-MM-ddTHH:mm:ss', 'UTC');
    }

    setTimeInterval(interval){
        this.timeInterval = interval;
        this.updateMetrics();
    }

    timeIntervanTitle() {
        var title = '';
        if(this.timeInterval.start){
            title = this.$filter('date')(this.timeInterval.start, 'yyyy-MM-dd HH:mm:ss') +
                ' - ' +
                this.timeInterval.end ? this.$filter('date')(this.timeInterval.end, 'yyyy-MM-dd HH:mm:ss') : 'now'
        } else {

            title = 'for the last ' + _.findWhere(this.timeIntervals, {value: this.timeInterval.interval}).title
        }
        return title;
    }

    requestTimeInterval() {
        var time = {};
        if(this.timeInterval.start){
            time.start = this.toISOString(this.timeInterval.start);
            if(this.timeInterval.end)
                time.start = this.toISOString(this.timeInterval.end);
        } else if (this.timeInterval.interval) {
            time.start = this.toISOString( new Date() - this.timeInterval.interval);
        } else {
            return null;
        }

        return time;
    }

    setInterval(interval) {
        this.interval = interval;
        this.updateMetrics();
    }

    intervanTitle() {
        return 'time interval ' + this.interval;
    }



    addD3Series(metrics, labelsAsIteration, elements, name){
        let d3data = _.clone(this.d3Data);
        var removeSeries = {};
        let add = {};
        let added = {};
        _.each( metrics, (m) => {
            _.each(m.metrics, (mm) => {
                if( !d3data[mm.name] ) { d3data[mm.name] = {series: [], unit: mm.unit}; }
                let s = _.findWhere(d3data[mm.name].series, {key: name});
                if(!s) {
                    d3data[mm.name].series = _.filter(d3data[mm.name].series, (s) => {
                        return  s.key.search('^' + name) == -1;
                    });
                    s = { key: name, values: [], color: stringToColour(name) };
                    d3data[mm.name].series.push(s);
                }
                else {
                    this.chartUpdateCount = new Date();
                }
                s.t = new Date();
                s.values = this.mapData(mm.values)
            });
        });

        this.d3Data = _.clone(d3data);

    }

    removeD3Series( name, strictly ){
        var self = this;
        var data = {};

        _.each( this.d3Data, (m, k) => {
            data[k] = m;
            data[k].series = _.filter(m.series, (s) => {
                return !( strictly ? s.key == name : s.key.search('^' + name) != -1);
            });
        });
        this.d3Data = data;
    }

    mapData(list){
        return _.map(list, (v, k) => {
            return {x: k, y: v.value}
        })
    }

    nvd3Options(title) {
        // debugger;

        return  {
            "chart": {
                "type": "lineChart",
                // "height": 450,
                "margin": {
                    "top": 20,
                    "right": 20,
                    "bottom": 40,
                    "left": 55
                },
                x: function (d) {
                        // debugger
                        return  d ? d.x : null;
                    },
                y: function (d) {
                        // debugger
                        return  d ? d.y : null;
                    },

                "useInteractiveGuideline": true,
                "dispatch": {},
                "xAxis": {
                    // "axisLabel": "Time (ms)"
                },
                "yAxis": {
                    // "axisLabel": "Voltage (v)",
                    "axisLabelDistance": -10,
                    tickFormat: function(d){
                        return d3.format(',.1f')(d);
                    }
                },

                // noData: function (noDataText) {
                //     svg.datum([{
                //         values: [[]]
                //     }]).call(chart);
                //     svg.datum([]).call(chart.noData(noDataText));
                //     chart.tooltip.data([]);
                // }

                // yAxis: {
                //     axisLabel: 'Quantity',
                //     axisLabelDistance: -10,
                //     tickFormat: function(d){
                //         return d3.format(',.1f')(d);
                //     }
                // },
            },
            // "title": {
            //     "enable": true,
            //     "text": title
            // }
        }
    }


}


export const ApplicationsMetrics2Component = {
    bindings: {
        // application: '<'
        appController: '<'
    },
    controller: controller,
    template: function ($templateCache) {
        return $templateCache.get('kuberlab/applications/metrics2/applications_metrics_component.html')
    }
};
