export function BlockMenu () {
    function link(scope, element) {
        element.ready( function(){

            _.delay(() => {
                var current = $(element[0].getElementsByClassName('blockMenuItem current'));
                // debugger;

                // var header_heaght = $('#header').height();
                // debugger
                if( !current.length ) return;

                var list = $(element[0]);
                if(list.height() < current.offset().top - current.height() ) {

                    if( current.length - current.height() ){
                        $(list).animate({scrollTop: current.offset().top - list.offset().top + list.scrollTop()}, '1000')
                    }
                }
            }, 1000);


        } );

    }
    return({
        link: link,
        restrict: "C"
    });
}
