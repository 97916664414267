export function LayoutWs (UserConfigService) {

    function link(scope, $element) {
        scope.WS = UserConfigService.getConfig('currentWS');
    }
    return ({
        link: link,
        restrict: "E",
        template: `<h3><a ui-sref="workspace({wsId: WS.Name})"><md-icon md-svg-icon="core:wss"></md-icon><span class="label">{{WS.DisplayName}}</span></a></h3>`
    });
}
