import {WsaListController} from '../wsa_list_controller'


export const WsaCardListComponent = {
    bindings: {
        wsId : '<'
    },

    template: `
            <md-list>
                <md-list-item ng-if="!$ctrl.saList().length" class="md-2-line md-long-text cmp-clickable" >
                    <md-icon md-svg-icon="core:information" ></md-icon>
                    <div class="md-list-item-text" flex layout="row" layout-align="end end">
                        <p flex translate>WSA_LIST_EMPTY</p>
                    </div>
                </md-list-item>
                <cmp-wsa-card-item ng-repeat="data in $ctrl.saList()" data="data" list-controller="$ctrl"></cmp-wsa-card-item>
                
                 <md-list-item class="hovered" ng-click="false" ng-hide="$ctrl.hasNew">
                    <md-icon md-svg-icon="core:plus" class="md-avatar-icon"></md-icon>
                    <md-list-item-text class="flex layout-row layout-align-start-center">
                       <md-menu>
                        <md-button class="md-raised md-primary" aria-label="Add service" ng-click="$mdMenu.open()" ng-disabled="$ctrl.hasNew">
                            {{"WSA_ADD_BUTTON"|translate}}
                        </md-button>
                        <md-menu-content width="3">
                            <md-menu-item>
                                <md-button ng-click="$ctrl.add({Service : 'google'})">
                                    <md-icon md-svg-icon="services:google"></md-icon>
                                    Google
                                </md-button>
                            </md-menu-item>
                            <md-menu-item>
                                <md-button ng-click="$ctrl.add({Service : 'amazon'})">
                                    <md-icon md-svg-icon="services:amazon"></md-icon>
                                    Amazon
                                </md-button>
                            </md-menu-item>
                             <md-menu-item>
                                <md-button ng-click="$ctrl.add({Service : 'kubernetes', SetCredentials : ''})">
                                    <md-icon md-svg-icon="services:kubernetes"></md-icon>
                                    Kubernetes
                                </md-button>
                            </md-menu-item>
                            <md-menu-item>
                                <md-button ng-click="$ctrl.add({Service : 'git', SetCredentials : {}})">
                                    <md-icon md-svg-icon="services:git"></md-icon>
                                    GIT
                                </md-button>
                            </md-menu-item>
                            <md-menu-item>
                                <md-button ng-click="$ctrl.add({Service : 'cs3', SetCredentials : {}})">
                                    <md-icon md-svg-icon="services:cs3"></md-icon>
                                    Custom S3
                                </md-button>
                            </md-menu-item>
                            <md-menu-item>
                                <md-button ng-click="$ctrl.add({Service : 'docker', SetCredentials : {}})">
                                    <md-icon md-svg-icon="services:docker"></md-icon>
                                    Docker
                                </md-button>
                            </md-menu-item>

                            <md-menu-item>
                                <md-button ng-click="$ctrl.add({Service : 'kubernetes_gke', SetCredentials : {}})">
                                    <md-icon md-svg-icon="services:kubernetes_gke"></md-icon>
                                    Kubernetes GKE
                                </md-button>
                            </md-menu-item>

                        </md-menu-content>
                    </md-menu>
                         <p><md-icon md-svg-icon="core:information"></md-icon>  {{"WSA_DESCRIONTION_LIST"|translate}}</p>
                    </md-list-item-text>
                </md-list-item>
            </md-list>
        `,
    controller: WsaListController
};
