import {MlmodelFromProjectController} from '../../mlmodel/mlmodel_from_project/comtroller'
import {ws_listener} from '../../core/controller/ws_listener'

export class controller extends  ws_listener {
    constructor($rootScope, ApplicationsService, $state, $localStorage, $mdDialog, $templateCache, WorkspacePermissions, $scope, $anchorScroll, $transitions, $window) {
        super();
        this.$transitions = $transitions;
        this.$anchorScroll = $anchorScroll;
        this.$rootScope = $rootScope;
        this.$templateCache = $templateCache;
        this.$mdDialog = $mdDialog;
        this.$state = $state;
        this.storage = $localStorage;
        this.permissions = WorkspacePermissions;
        this.resource = ApplicationsService.getResource( $state.params );
        this.ApplicationsService = ApplicationsService;
        this.$scope = $scope;

        this.win = angular.element($window);
        var inject = angular.element(document.body).injector();
        this.ErrorService = inject.get('ErrorService');

        this.deleteList = [];

        $localStorage.$default({
            applicationJobtView: true
        });
        this.grid = this.storage.applicationJobtView;
        var self = this;
        $scope.$watchCollection(
            ()=>{ return self.ApplicationsService.tasksList; },
            (c) => {
                self.Jobs = c;
                this.getStats();
                // debugger
                // if(!this.$scope.$$phase) {
                //     this.$scope.$apply()
                // }
            }
        );
        this.items = {};
        this.watchWS('serving', this.ws_serving);
        this.watchWS('servingCreate', this.ws_serving);
        this.watchWS('servingDelete', this.ws_servingDelete);
        this.search = {};


        this.activeTaskParams = [];

        this.viewFilter = false;
        this.multiActionDo = false;
    }

    $onInit() {
        this.taskparams = [];
        this.init(this.$state.params, undefined, true);

        this.listenerState = this.$transitions.onSuccess({ entering: 'wsApplication.tab' },
            (transition, state) => {
                let params =  _.extend({}, transition.params('to'));
                let paramsFrom =  _.extend({}, transition.params('from'));
                this.init(params, paramsFrom);
            });


        this.ApplicationsService.getExperiments(this.$state.params.applicationName)
            .then(
                (r) => {
                    this.experiments = r;
                }
            );
        this.viewFullFilter = false;
    }

    viewFullFilterAction() {
        if(!this.viewFullFilter ){
            this.viewFullFilter = true;
            // this.getTasksparameters();
        } else {
            this.cancelFullFilter();
        }
    }

    init(params, paramsFrom, update) {
        if (this.isOnCurrentPage(params)) {
            if(!this.Jobs || !this.Jobs.length){
                this.getList(update, params, null, paramsFrom)
                    .then( () => {
                        this.getCurrentJob(params, paramsFrom);
                    }) ;
            } else {
                this.getCurrentJob(params, paramsFrom);
            }


            this.listTaskExport = null;

        } else {
            if(this.stateInterval) clearTimeout(this.stateInterval);
        }
    }


    getCurrentJob(params={}, from={}) {
        if(!params.tabItem && this.job) {
            _.delay(() => {this.$state.go('wsApplication.tab', {tab: 'jobs', tabItem: this.job.name});}, 0);
            return false;
        }
        else if(params.tabItem) {
            var job = _.findWhere(this.Jobs, {name:  params.tabItem});
            if(job) {
                this.job = job;
            }
            else {
                if (this.job) {
                    if(job = _.findWhere(this.Jobs, {name: this.job.name})){
                        _.delay(() => {this.$state.go('wsApplication.tab', {tab: 'jobs', tabItem: this.job.name});}, 0);
                        return false;
                    }
                }
                else {
                    _.delay(() => {this.$state.go('wsApplication.tab', {tab: 'jobs', tabItem: null});}, 0);

                }
            }

        }
        else {
            this.job = null;
        }

        if( params.tabItem != from.tabItem ){
            this.scrollListToJob(params.tabItem || from.tabItem, this.job);
        }
    }

    isOnCurrentPage(params) {
        var tab = params ? params.tab : this.$state.params.tab;
      return tab === 'jobs';
    }



    getStats() {
        var load = false;
        var thet = this;

        var onWinFocus = function() {
            // console.log("win off focus ", this.win);
            thet.win.off('focus', onWinFocus);
            thet.getStats();
        };

        if(this.stateInterval) clearTimeout(this.stateInterval);
        if(document.hasFocus()) {
            // console.log("requset status ", this.$rootScope.windowFocus);
            _.each(this.Jobs, function (job) {
                if(job.status == 'Running'){
                    this.action('stats', {task: job.task_name, build: job.build});
                    load = true;
                }
            }, this);

            if(load) this.stateInterval = _.delay(() => {this.getStats()}, 60000);

        } else {
            // console.log("win on focus ", this.win);
            this.win.on('focus', onWinFocus);
        }
    }



    setFilter() {
        this.apiLoading = true;
        this.apiError = false;
        this.ApplicationsService.getResource({wsId: this.$state.params.wsId, application : this.$state.params.applicationName})
            .tasks_search(_.extend({}, {application: this.$state.params.applicationName}, this.requestTaskParams)).$promise
            .then(
                (r) => {
                    this.fJobs = r;
                },
                (err) => {
                    this.apiError = err;
                }
            )
            .finally(() => {this.apiLoading = false;});

    }

    cancelFullFilter() {
        this.reuestTaskParams = {};
        this.viewFullFilter = false;
        if( this.search ) delete this.search.exec_info;
        this.fJobs = null;
        this.apiError = null;
    }

    filterList(){
        var exec_info = this.requestTaskParams;
        var list = [];
        _.each(this.Jobs, (v) => {
            if(this.isMatch(v.exec_info, exec_info)) {
                list.push(v);
            }
        });
        return list;
    }

    isMatch(o , e ) {
        if(_.isObject(e) && !_.isObject(o)) return false;
        var r = true;
        _.each(e, (v,k) => {
            if(o[k] && o[k].toString() == v)
                {
                    // debugger;
                }
            else
                {
                    // debugger;
                    r = false;
                }
        });
        return r;
    }


    getList(update, params, filter, from = {}) {
        if (!this.isOnCurrentPage()) return;
        params = params || this.$state.params;
        this.apiError = false;
        this.apiLoading = true;
        return this.ApplicationsService.getTasks(this.$state.params, update, params)
            .then(
                (r) => {
                    this.getStats();
                    this.Jobs = r;
                    this.scrollListToJob(params.tabItem || from.tabItem, params.tabItem);
                },
                (err) => {
                    this.apiError = err;
                }
            )
            .finally(() => {
                this.apiLoading = false;

            });
    }


    scrollListToJob(jobName, full){
        if(jobName){
            _.delay(()=>{
                var header_heaght = $('#header').height();
                var el = $('#' + jobName);
                if( !el.length ) return;

                var list = full ? $('#applicationJobsList') : $('#applicationFullContent');
                if(list.height() < el.offset().top - el.height() ) {

                    if( el && el.length - el.height() ){
                        $(list).animate({scrollTop: el.offset().top - list.offset().top + list.scrollTop()}, '1000')
                    }
                }
            }, 200);
        }
    }

    wsCurrentTask(data) {
        if( this.appController.data.WorkspaceName == data.WorkspaceName  && this.appController.data.Name == data.ApplicationName) {
            return  _.findWhere(this.Jobs, {task_name: data.Task, build : data.Build});
        }
    }

    ws_serving (ev, data) {
        var task = this.wsCurrentTask(data);
        if(task) {
            task.serve = {
                "name": "serving",
                "serving": data.Task,
                "build": data.Build,
                "status": data.Status
            },
            task.serving = data;

            this.$scope.$apply();
        }
    }

    ws_servingDelete (ev, data) {
        var task = this.wsCurrentTask(data);
        if(task) {
            delete task.serving;
            delete task.serve;
            this.$scope.$apply();
        }
    }

    getServeDialog(ev, job) {
        var self = this;
        this.$mdDialog.show({
            locals: {
                application: this.appController.data
            },
            controllerAs: '$ctrl',
            controller: function($scope, application) {
                this.application = application;
                $scope.serveObj = {Config: _.first(application.Configuration.spec.serving) || {}};
                $scope.serveObj.Config.taskName = job.task_name;
                $scope.serveObj.Config.build = job.build;
                var then = this;
                $scope.cancel = function () {
                    self.$mdDialog.cancel();
                };
                $scope.isCurrent = function (item) {
                  return _.isEqual($scope.serveObj, item);
                };
                $scope.serve = function () {
                    $scope.error = false;
                    then.loading = true;
                    self.action('serving', {task: job.task_name, build: job.build}, $scope.serveObj, function (r, params, isError) {
                        then.loading = false;
                            if(isError){
                                $scope.error = r;
                            }else{
                                // self.getList(true);
                                $scope.cancel();
                            }
                        }
                    )
                };
                $scope.continue = function () {
                    self.$mdDialog.hide($scope.params, $scope.res);
                }

            },
            template: this.$templateCache.get('kuberlab/applications/jobs/applications_job_serve_dialog.html'),
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose:true,
        })
        .then(function(params) {
            // self.action('serving', j, params);

        }, function() {
            // debugger;
        });
    }

    getSaveDialog(ev, job) {
        var self = this;
        this.$mdDialog.show({
            locals: {
                application: this.appController.data,
                action: this.action,
                job: job,
                parentController: this
            },
            controllerAs: '$ctrl',
            controller: MlmodelFromProjectController,
            template: this.$templateCache.get('kuberlab/mlmodel/mlmodel_from_project/template.html'),
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose:false,
        })
        .then(function(params) {
            // self.action('serving', j, params);

        }, function() {
            // debugger;
        });
    }

    changeView (list  ) {
        this.grid = !this.grid;
        this.storage.applicationJobtView = this.grid;
        // this.setView();
    }

    action(action, params = {}, data, cb) {
        var self = this;
        this.errorRequest = false;

        try {
            var r_action = "task_" + action;
            var cb_success = "action_" + action + "_success";
            var cb_error= "action_" + action + "_error";
            params = _.extend(params, {application : this.appController.data.Name});
            var job = _.findWhere(this.Jobs, {build: params.build});
            this.loading = true;
            this.resource[r_action]( params, data )
                .$promise
                .then(
                    function (r) {
                        if(r && r.name){
                            self.updateJob(job, r);
                        }
                        if(_.isFunction(cb)) {
                            cb(r, job, false);
                        }
                        else if(self[cb_success]){  self[cb_success](r, job);  }
                    },
                    function (err) {
                        if(action == 'stats' && err.status == '404') {
                            this.getList(true);
                        }else{
                            // debugger;
                            // if(action != 'save' && action != 'serving') self.errorRequest = err;
                            switch(action){
                                case 'save':
                                case 'serving':
                                    break;
                                default:
                                    self.errorRequest = err;
                            }

                            if(_.isFunction(cb)) {
                                cb(err, job, true);
                            }
                            else if(self[cb_error]){
                                self[cb_error](r, job);
                            }
                        }
                    }
                )
                .finally(() => {
                    this.loading = false;
                })

        } catch (err) {
            console.error(err);
        }
    }

    action_delete_success (r, job) {
        job.toDelete = false;
        this.initDeleteList(job);
        this.ApplicationsService.removeTask(job);
        // this.Jobs = _.without(this.Jobs, job);
    }

    action_stats_success (r, job) {
        job.Stats = {};
        var statMetrics;
        if(statMetrics = _.first(r.metrics)){
            _.each(statMetrics.metrics, function(metric){
                job.Stats[metric.name] = {Measure: metric.unit};
                var v = metric.values[0];
                if (v) job.Stats[metric.name].Value = v.value;
            }, this);
        }

    }

    updateJob (job, newJob){
        var j = _.findWhere(this.Jobs, {task_name: newJob.name, build: newJob.build});
        j = _.extend(j, newJob);
        return j;
    }

    action_stop_serving_success(r, job) {
        if(job.serving) { job.serving.Status = 'Stopping'; }
    }

    action_serving_success (r, job) { debugger; }

    action_stop_success (r, job) {
      // this.updateJob(job, r);
    }

    action_restart_success(r, job) {
        // this.getList(true);
    } ;

    action_export_success(r, job){
        this.ApplicationsService.addTask(this.$state.params, job);
        _.delay(() => {this.$state.go('wsApplication.tab', {tabItem: r.name} );}, 500);
    }

    $onDestroy() {
        super.$onDestroy();
        this.listenerState();
        clearTimeout(this.updateJobsTime);
        clearTimeout(this.stateInterval);
        // if(this.stateInterval) clearTimeout(this.stateInterval);

    }



    getTaskExport() {
        if(!this.listTaskExport )
            this.listTaskExport =  _.where(this.appController.data.Configuration.spec.tasks, {type : 'export'} );
        return this.listTaskExport;
    }

    initDeleteList(item) {
        if(item.toDelete) {
            this.deleteList.push(item);
        } else {
            this.deleteList = _.without(this.deleteList, item);
        }
    }

    multiAction(action) {
        if(!action) return;
        let b = _.map(this.deleteList, item => {
            return `${item.task_name}:${item.build}`;
        });
        const a = `build_muliti_${action}`;
        this.multiActionDo = true;
        this.resource[a]( {application : this.appController.data.Name, builds: b.join(',')} )
            .$promise
            .then(
                r => {
                    _.each(this.deleteList, item => {
                        item.toDelete = false;
                    });
                    this.deleteList = [];
                },
                err => {
                    this.ErrorService.errorDialog(err);
                }
            )
            .finally(() => {
                this.multiActionDo = false;
            });
    }
}

export const ApplicationJobsComponent = {
    bindings: {
        // application: '=',
        appController: '='
    },
    controller: controller,
    template: function ($templateCache) {
        return $templateCache.get('kuberlab/applications/jobs/applications_jobs_component.html');
    }

};
