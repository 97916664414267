export default {
    "metrics": [
    {
        "component_name": "standalone-3",
        "component_type": "task",
        "metrics": [
            {
                "name": "memory_bytes",
                "values": [
                    {
                        "ts": 1507028407,
                        "value": 4415488
                    },
                    {
                        "ts": 1507028417,
                        "value": 4415488
                    },
                    {
                        "ts": 1507028427,
                        "value": 4415488
                    },
                    {
                        "ts": 1507028437,
                        "value": 4169728
                    },
                    {
                        "ts": 1507028447,
                        "value": 4169728
                    },
                    {
                        "ts": 1507028457,
                        "value": 4169728
                    },
                    {
                        "ts": 1507028467,
                        "value": 4169728
                    },
                    {
                        "ts": 1507028477,
                        "value": 4169728
                    },
                    {
                        "ts": 1507028487,
                        "value": 4169728
                    },
                    {
                        "ts": 1507028497,
                        "value": 4169728
                    },
                    {
                        "ts": 1507028507,
                        "value": 4169728
                    },
                    {
                        "ts": 1507028517,
                        "value": 4169728
                    },
                    {
                        "ts": 1507028527,
                        "value": 4169728
                    },
                    {
                        "ts": 1507028537,
                        "value": 4169728
                    },
                    {
                        "ts": 1507028547,
                        "value": 4169728
                    },
                    {
                        "ts": 1507028557,
                        "value": 4169728
                    },
                    {
                        "ts": 1507028567,
                        "value": 4169728
                    },
                    {
                        "ts": 1507028577,
                        "value": 4169728
                    },
                    {
                        "ts": 1507028587,
                        "value": 4169728
                    },
                    {
                        "ts": 1507028597,
                        "value": 4169728
                    },
                    {
                        "ts": 1507028607,
                        "value": 4169728
                    },
                    {
                        "ts": 1507028617,
                        "value": 4169728
                    },
                    {
                        "ts": 1507028627,
                        "value": 4169728
                    },
                    {
                        "ts": 1507028637,
                        "value": 4169728
                    },
                    {
                        "ts": 1507028647,
                        "value": 4169728
                    },
                    {
                        "ts": 1507028657,
                        "value": 4169728
                    },
                    {
                        "ts": 1507028667,
                        "value": 4169728
                    },
                    {
                        "ts": 1507028677,
                        "value": 4169728
                    }
                ],
                "labels": {
                    "app": "kube-state-metrics",
                    "instance": "10.244.0.95:8080",
                    "job": "kubernetes-endpoints",
                    "kubernetes_name": "kube-state-metrics",
                    "kubernetes_namespace": "kuberlab",
                    "label_component": "standalone-worker",
                    "label_kuberlab_io_component_type": "task",
                    "label_kuberlab_io_job_id": "3",
                    "label_kuberlab_io_task": "standalone",
                    "label_kuberlab_io_workspace": "kuberlab-demo",
                    "label_kuberlab_io_workspace_id": "21",
                    "label_workspace": "delete-tensorflow-py3",
                    "namespace": "21-delete-tensorflow-py3",
                    "pod": "standalone-worker-3-0",
                    "pod_name": "standalone-worker-3-0"
                }
            },
            {
                "name": "cpu_usage",
                "values": [
                    {
                        "ts": 1507028437,
                        "value": 0
                    },
                    {
                        "ts": 1507028447,
                        "value": 0
                    },
                    {
                        "ts": 1507028457,
                        "value": 0
                    },
                    {
                        "ts": 1507028467,
                        "value": 0
                    },
                    {
                        "ts": 1507028477,
                        "value": 0
                    },
                    {
                        "ts": 1507028487,
                        "value": 0
                    },
                    {
                        "ts": 1507028497,
                        "value": 0
                    },
                    {
                        "ts": 1507028507,
                        "value": 0
                    },
                    {
                        "ts": 1507028517,
                        "value": 0
                    },
                    {
                        "ts": 1507028527,
                        "value": 0
                    },
                    {
                        "ts": 1507028537,
                        "value": 0
                    },
                    {
                        "ts": 1507028547,
                        "value": 0
                    },
                    {
                        "ts": 1507028557,
                        "value": 0
                    },
                    {
                        "ts": 1507028567,
                        "value": 0
                    },
                    {
                        "ts": 1507028577,
                        "value": 0
                    },
                    {
                        "ts": 1507028587,
                        "value": 0
                    },
                    {
                        "ts": 1507028597,
                        "value": 0
                    },
                    {
                        "ts": 1507028607,
                        "value": 0
                    },
                    {
                        "ts": 1507028617,
                        "value": 0
                    },
                    {
                        "ts": 1507028627,
                        "value": 0
                    },
                    {
                        "ts": 1507028637,
                        "value": 0
                    },
                    {
                        "ts": 1507028647,
                        "value": 0
                    },
                    {
                        "ts": 1507028657,
                        "value": 0
                    },
                    {
                        "ts": 1507028667,
                        "value": 0
                    },
                    {
                        "ts": 1507028677,
                        "value": 0
                    }
                ],
                "labels": {
                    "app": "kube-state-metrics",
                    "instance": "10.244.0.95:8080",
                    "job": "kubernetes-endpoints",
                    "kubernetes_name": "kube-state-metrics",
                    "kubernetes_namespace": "kuberlab",
                    "label_component": "standalone-worker",
                    "label_kuberlab_io_component_type": "task",
                    "label_kuberlab_io_job_id": "3",
                    "label_kuberlab_io_task": "standalone",
                    "label_kuberlab_io_workspace": "kuberlab-demo",
                    "label_kuberlab_io_workspace_id": "21",
                    "label_workspace": "delete-tensorflow-py3",
                    "namespace": "21-delete-tensorflow-py3",
                    "pod": "standalone-worker-3-0",
                    "pod_name": "standalone-worker-3-0"
                }
            }
        ]
    }
],
    "start": "2017-10-03T10:59:37",
    "end": "2017-10-03T11:04:43"
}
