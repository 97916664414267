import {ProjectsList} from '../projects_list_controller'

export const ProjectsCardHomeComponent = {
  bindings: {
    workspace: '<',
    mainPage: '<'
  },
  controller: ProjectsList,
  template: `
    <div layout="row" layout-align="space-around" ng-if="!$ctrl.list">
      <md-progress-linear md-mode="indeterminate"></md-progress-linear>
    </div>

    <div ng-class="{'limitHeagth' : $ctrl.limitHeigth(), 'innerContentBorder' : true} ">
        <md-list ng-if="$ctrl.list.length" >
            <div ng-repeat="data in $ctrl.list | orderBy: 'DisplayName'">
                <cmp-project-item data="data" list-controller="$ctrl"></cmp-project-item>
                <md-divider ng-if="!$last"></md-divider>
            </div>
        </md-list>
        <md-button class="allList" ng-if="$ctrl.limitHeigth()" ui-sref="wsProgectsList()">{{"COMMON_ALL_LIST" | translate}} ({{$ctrl.list.length}})</md-button>
    </div>

    <div ng-if="$ctrl.listEmpty" ng-include=" 'kuberlab/projects/card/list_empty.html' "></div>
`
};
