

class WizardController {
    constructor() {

        this.data = {
            Name: '', //The name of the chart (required)
            Version: '0.0.0', //A SemVer 2 version (required)
            Description:'', // A single-sentence description of this project (optional)
            Keywords: [], // - A list of keywords about this project (optional)
            Home: '', //The URL of this project's home page (optional)
            Sources: [], // - A list of URLs to source code for this project (optional)
            Maintainers:{ //  # (optional)
                Name: '', //The maintainer's name (required for each maintainer)
                Email: '', //The maintainer's email (optional for each maintainer),
            },
            Engine: 'gotpl', //, gotpl # The name of the template engine (optional, defaults to gotpl)
            Icon: '', // A URL to an SVG or PNG image to be used as an icon (optional).
            AppVersion: '', //The version of the app that this contains (optional). This needn't be SemVer.
            Deprecated: false, //Whether or not this chart is deprecated (optional, boolean)
            Type: '',
            Values: {}
        }
    }

    send(form){
        // debugger
        if(form.$valid){
            var self = this;
            this.saving = true;
            this.savingError = false;
            this.chartResource.config({wsId: this.chart.WorkspaceName, chartName: this.chart.Name}, this.data)
                .$promise
                .then(
                    function (r) {
                        debugger;
                    },
                    function (err) {
                        self.savingError = err;
                    }
                )
                .finally(
                    function () {
                        self.saving = false;
                    }
                )
        }
    }
}

export const WizardComponent = {
    bindings : {
        chart: '<',
        chartResource: '<'
    },
    controller: WizardController,
    template: `

        <form name="chartWizard" ng-submit="$ctrl.send(chartWizard)">
            <div class="item ">
                <md-input-container class="form">
                    <label>Name</label>
                    <input type="text" name="name" ng-model="$ctrl.data.Name" required>
                    <div ng-messages="chartWizard.name.$error" ng-show="chartWizard.name.$dirty">
                      <div ng-message="required">This is required!</div>
                    </div>
                </md-input-container>
                <div class="info"><p>An 'app' label with this value will be added to the Deployment and Service that get deployed. Learn more open_in_new</p></div>
            </div>
            <div class="item">
                <md-input-container class="form">
                    <label>Version</label>
                    <input type="text" name="version" ng-model="$ctrl.data.Version" required>
                    <div ng-messages="chartWizard.version.$error" ng-show="chartWizard.version.$dirty">
                      <div ng-message="required">This is required!</div>
                    </div>
                </md-input-container>
                <div class="info"><p>A SemVer 2 version </p></div>
            </div>
            <div class="item">
                <md-input-container class="form">
                    <label>Description</label>
                    <textarea ng-model="$ctrl.data.Description"></textarea>
                </md-input-container>
                <div class="info"><p>A single-sentence description of this project</p></div>
            </div>
            
            <div class="item">
                <md-chips class="form"
                        ng-model="$ctrl.data.Keywords" 
                        name="keywords" readonly="$ctrl.readonly"
                        md-removable="$ctrl.removable" 
                        md-max-chips="5" 
                        placeholder="+ Keyword"
                        >
                    <label>Keywords</label>
                    <md-chip-template>
                      <strong>{{$chip}}</strong>
                    </md-chip-template>
                </md-chips>
                <div class="info"><p>A list of keywords about this project </p></div>
            </div>
            
            <div class="item">
                <md-input-container class="form">
                    <label>Type</label>
                    <md-select ng-model="$ctrl.data.Type" required>
                        <md-option ng-value="'deploy'">Deplay</md-option>
                    </md-select>
                </md-input-container>
                 <div class="info"><p>Type chart template</p></div>
            </div>
            
            <div ng-switch="$ctrl.data.Type">
                <div ng-switch-when="deploy">
                    <cmp-wizard-type-deploy  data="$ctrl.data.Values"></cmp-wizard-type-deploy>
                </div>
            </div> 
             <div class="flex layout-row">
                <div class="flex">
                    <md-progress-linear ng-if="$ctrl.saving"></md-progress-linear>
                    <cmp-api-error-service ng-if="$ctrl.savingError" error="$ctrl.savingError"></cmp-api-error-service>
                </div>
                <md-button type="submit">save</md-button>
            </div>
        </form>

<code>{{$ctrl.data}}</code>

`
};