import help_cfg from './help.json';

class controller{
    constructor(HelpService, $transitions, $state, $mdDialog, CurrentUser, $localStorage){
        this.api = HelpService;
        this.$transitions = $transitions;
        this.$state = $state;
        this.currentState = null;
        this.currentParams = null;
        this.$mdDialog = $mdDialog;
        this.link = null;
        this.isEdit = false;
        this.$localStorage = $localStorage;

        this.configRepo = help_cfg;
        this.config = this.$localStorage.helpConfig || help_cfg;
        // debugger
        // if (!this.config) this.config = config;

        CurrentUser.then(
            (r) => {
                this.CurrentUser = r;
            }
        )
    }

    $onInit() {
            this.listenerState = this.$transitions.onSuccess({ },
                (transition, state) => {
                    this.link = this.getLink(transition.to().name, transition.params('to'));
                    if(this.isEdit) this.setParams();
                });
    }

    getLink(stateName, stateParams = {}) {
        let state, params = {}, link;
        if( (state = this.config[stateName])){
            if ( _.isString(state) ) return s;
            if ( _.isObject(state) ) {
                _.each(state, (v,k) => {
                    let p = JSON.parse(k);
                    // debugger
                    if(_.isMatch(stateParams, p) && (_.keys(p) > _.keys(params) || (_.keys(p) >= _.keys(params) && !link )) ) {
                        params = p;
                        link = v;
                    }
                });
                if (link) return link;
            }
        }
        return null;
    }


    edit() {
        this.isEdit = !this.isEdit;
        this.newStateParams = {};
        this.newStateParamsKey = {};
        this.newStateParamsLink = '';
        this.$mdDialog.show({
                controller: function ($scope, $mdDialog, help) {
                    $scope.help = help;
                    $scope.hide = function() {
                        $mdDialog.hide();
                    };

                    $scope.cancel = function() {
                        $mdDialog.cancel();
                    };

                    $scope.save = function() {
                        $mdDialog.hide(true);
                    };

                    $scope.printConfig = function (config) {
                        return JSON.stringify(config, undefined, 2)
                    };

                    $scope.hasChange = function () {
                        // debugger
                        return !_.isEqual(help.config, help.configRepo);
                    };
                },
                template: editTemplate,
                clickOutsideToClose:false,
                // controllerAs: '$ctrl',
                locals: {
                  help: this
                }
            })
                .then((answer) => {
                    if ( answer ) {
                    }
                }, () => {
                });
    }

    setParams() {
        let p = [];
        _.each(this.newStateParams, (v,k) => {
            if(v) p.push(k);
        });
        this.newStateParamsKey = _.pick(this.$state.params, ...p);
    }

    add(state,  k, v ) {
        if(!this.config[state]) { this.config[state] = {}; }
        this.config[state][JSON.stringify(k)] = v;
        this.toStorage();
    }

    delete(state, k) {
        if( this.config[state] ){
            delete this.config[state][k];
        }
        this.toStorage();
    }

    applyFromRepo () {
        this.config = this.configRepo;
        this.toStorage();
    }

    toStorage(config) {
        this.$localStorage.helpConfig = config || this.config;
        this.$localStorage.$apply();
        this.getLink();
    }
}


let editTemplate = `
<md-dialog >
  <form ng-cloak>
    <md-toolbar>
      <div class="md-toolbar-tools">
        <h2>Edit help</h2>
        <span flex></span>
        <md-button class="md-icon-button" ng-click="cancel()">
          <md-icon md-svg-src="core:close" aria-label="Close dialog"></md-icon>
        </md-button>
      </div>
    </md-toolbar>

    <md-dialog-content>
      <div class="md-dialog-content" style="min-width: 500px">
          <md-tabs md-dynamic-height md-border-bottom>
              <md-tab label="form">
                <md-content class="md-padding">
                       <h5>state name : {{help.$state.current.name}}</h5>
                        <div ng-repeat="(k,v) in help.config[help.$state.current.name]">
                            {{k}} : <input ng-model="help.config[help.$state.current.name][k]" ng-change="help.toStorage()"> <button ng-click="help.delete( help.$state.current.name, k)"> - </button>
                        </div>
                        <div>{{help.newStateParamsKey}} : <input ng-model="newStateParamsLink" /><button ng-click="help.add(help.$state.current.name, help.newStateParamsKey, newStateParamsLink)">+</button></div>
                        
                        <h6>params</h6>
                        <div ng-repeat="(k,v) in help.$state.current.params">
                            <md-checkbox ng-change="help.setParams()" ng-model="help.newStateParams[k]" >{{k}}: {{help.$state.params[k]}}</md-checkbox>
                        </div>
                        
                </md-content>
              </md-tab>
              <md-tab label="config">
                <md-content class="md-padding">
                       <pre>{{printConfig(help.config)}}</pre>
                </md-content>
              </md-tab>
              <md-tab label="config repo">
                <md-content class="md-padding">
                       <pre>{{printConfig(help.configRepo)}}</pre>
                </md-content>
              </md-tab>
          </md-tabs>
          </div>
        
        </md-dialog-content>
        <md-dialog-actions layout="row" ng-if="hasChange()">
            <div> repo config not equal current</div>
          <span flex></span>
          
          <md-button ng-click="help.applyFromRepo()">
            apply from repo
          </md-button>
        </md-dialog-actions>
    </form>
</md-dialog>
`;

export const HelpListComponent = {
    controller: controller,
    template : ($templateCache) => { return $templateCache.get('kuberlab/help/help_list_component.html');}
};
