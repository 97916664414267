
class controller {

    constructor($log, $scope){
        this.$log = $log;
        $scope.$watch(
            () => { return this.ngModel; },
            () => { this.setDef(); }
        )
    }

    $onInit(){
        this.setDef();
        this.states = this.loadAll();
        if(this.cmpRequired){
            this.selectedItem = 0;
        }
    }

    setDef() {
        this.searchText = this.ngModel;
    }

    querySearch (query) {
        return this.states;
        var results = query ? this.states.filter( this.createFilterFor(query) ) : this.states,
            deferred;
        if (this.simulateQuery) {
            // deferred = $q.defer();
            // $timeout(function () { deferred.resolve( results ); }, Math.random() * 1000, false);
            // return deferred.promise;
        } else {
            return results;
        }
    }

    searchTextChange(text) {
        this.ngModel = text;
    }

    selectedItemChange(item) {
        if(item)
            this.ngModel = item.value;
    }

    /**
     * Build `states` list of key/value pairs
     */
    loadAll() {
        // var allStates = this.options

        return this.options.map( function (state) {
            return {
                value: state.toLowerCase(),
                display: state
            };
        });
    }

    /**
     * Create filter function for a query string
     */
    createFilterFor(query) {
        var lowercaseQuery = query.toLowerCase();

        return function filterFn(state) {
            return (state.value.indexOf(lowercaseQuery) === 0);
        };

    }
}




export const SelectComponent = {
    bindings: {
        options: '<',
        label: '<',
        ngModel: '=',
        cmpRequired: '<'
    },
    controller: controller,
    template: `
        <md-autocomplete
                ng-disabled="$ctrl.isDisabled"
                md-no-cache="$ctrl.noCache"
                md-selected-item="$ctrl.selectedItem"
                md-search-text-change="$ctrl.searchTextChange($ctrl.searchText)"
                md-search-text="$ctrl.searchText"
                md-selected-item-change="$ctrl.selectedItemChange(item)"
                md-items="item in $ctrl.querySearch($ctrl.searchText)"
                md-item-text="item.display"
                md-min-length="0"
                md-floating-label="{{$ctrl.label}}"
                ng-required="$ctrl.cmpRequired"
                md-input-name="{{$ctrl.label}}"
                >
            <md-item-template>
                <span md-highlight-text="$ctrl.searchText" md-highlight-flags="^i">{{item.display}}</span>
            </md-item-template>
            <!--<md-not-found>-->
                <!--No states matching "{{$ctrl.searchText}}" were found.-->
                <!--<a ng-click="$ctrl.newState(ctrl.searchText)">Create a new one!</a>-->
            <!--</md-not-found>-->
        </md-autocomplete>
`
}