
class controller {
    constructor(storageList, StorageService){
        this.types = _.keys(storageList);
        this.StorageService = StorageService;
    }

    $onInit() {
        this.type = this.StorageService.getType(this.data);
    }
}


export const StorageViewComponent = {
    bindings: {
        data: '<'
    },
    controller: controller,
    template: `
        <span ng-switch="$ctrl.type">
            <span ng-switch-when="gitRepo">repository: <a href="{{$ctrl.data.gitRepo.repository}}" target="_blank'">{{$ctrl.data.gitRepo.repository}}</a>, directory: {{$ctrl.data.gitRepo.directory}},<span ng-if="$ctrl.data.gitRepo.revision">revision: {{$ctrl.data.gitRepo.revision}}, </span>subPath: {{$ctrl.data.subPath}}</span>
            <span ng-switch-when="nfs">Server: {{$ctrl.data.nfs.server}}, path: {{$ctrl.data.nfs.path}}, subPath: {{$ctrl.data.subPath}}</span>
            <span ng-switch-when="persistentVolumeClaim">Claim Name: {{$ctrl.data.persistentVolumeClaim.claimName}}, subPath: {{$ctrl.data.subPath}}</span>
            <span ng-switch-when="hostPath">Path: {{$ctrl.data.hostPath.path}}, <span ng-if="$ctrl.data.hostPath.type">type: {{$ctrl.data.hostPath.type}},</span> subPath: {{$ctrl.data.subPath}}</span>
            <span ng-switch-when="clusterStorage">{{$ctrl.data.clusterStorage}}<span ng-if="$ctrl.data.subPath">, subPath: {{$ctrl.data.subPath}}</span></span>
            <span ng-switch-when="s3bucket">{{$ctrl.data.s3bucket.bucket}}<span ng-if="$ctrl.data.s3bucket.accountId">: {{$ctrl.data.s3bucket.accountId}}</span><span ng-if="$ctrl.data.s3bucket.server">, server: {{$ctrl.data.s3bucket.server}}</span><span ng-if="$ctrl.data.s3bucket.region">, region: {{$ctrl.data.s3bucket.region}}</span></span>
            <span ng-switch-when="persistentStorage">{{$ctrl.data.persistentStorage.storageName}}<span ng-if="$ctrl.data.subPath">, subPath: {{$ctrl.data.subPath}}</span></span>
            <span ng-switch-when="dataset"><a target="_blank" ui-sref="wsCatalog.list.item.tab({wsId:$ctrl.data.dataset.workspace, Interface: 'dataset', item: $ctrl.data.dataset.dataset, tab: 'versions', tabItem: $ctrl.data.dataset.version })" >{{$ctrl.data.dataset.workspace}}/{{$ctrl.data.dataset.dataset}} version: {{$ctrl.data.dataset.version}}</a><span ng-if="$ctrl.data.subPath">, subPath: {{$ctrl.data.subPath}}</span></span>
            <span ng-switch-when="datasetFS"><a target="_blank" ui-sref="wsCatalog.list.item.tab({wsId:$ctrl.data.datasetFS.workspace, Interface: 'dataset', item: $ctrl.data.datasetFS.dataset, tab: 'versions', tabItem: $ctrl.data.datasetFS.version })" >{{$ctrl.data.datasetFS.workspace}}/{{$ctrl.data.datasetFS.dataset}} version: {{$ctrl.data.datasetFS.version}}</a><span ng-if="$ctrl.data.subPath">, subPath: {{$ctrl.data.subPath}}</span></span>
            <span ng-switch-when="model"><a target="_blank" ui-sref="wsCatalog.list.item.tab({wsId:$ctrl.data.model.workspace, Interface: 'mlmodel', item: $ctrl.data.model.model, tab: 'versions', tabItem: $ctrl.data.model.version })" >{{$ctrl.data.model.workspace}}/{{$ctrl.data.model.model}} version: {{$ctrl.data.model.version}}</a><span ng-if="$ctrl.data.subPath">, subPath: {{$ctrl.data.subPath}}</span></span>
            <span ng-switch-when="emptyDir"><span ng-if="$ctrl.data.emptyDir.medium">Medium: {{$ctrl.data.emptyDir.medium}}</span> <span ng-if="$ctrl.data.emptyDir.sizeLimit">size limit:{{$ctrl.data.emptyDir.sizeLimit}}</span></span>
            <span ng-switch-default class="worning">{{$ctrl.data}}</span>
        </span>
    `
}
