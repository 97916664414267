import {CoreServiceResource} from '../core/service/core_service_resource'

export class OrganizationService extends CoreServiceResource {
    constructor ($resource, $q, KuberlabConfigApi, $rootScope){
        super(...arguments);
    }

    getTeamsResource() {
        var teamsUrl = this.cfg.APIPath + '/org/:org/teams/:teamId';
        return this.$resource(
            teamsUrl,
            {org : '@org', teamId: '@ID'},
            _.extend(
                {},
                this.cfg.ResourceParams,
                {
                    permissions: {
                        method: "GET",
                        url: teamsUrl + '/permissions'
                    },
                    update_permissions: {
                        method: "PUT",
                        url: teamsUrl + '/permissions'
                    }
                }
            )
        );
    }

    getResource(clear) {
        if (!this._resource || clear){
            var apiPath = this.cfg.APIPath + '/org/:org';
            this._resource = this.$resource(
                apiPath,
                {org: '@ID'},
                _.extend(
                    {},
                    this.cfg.ResourceParams,
                    {
                        'set_name' : {
                            method : 'PUT',
                            url: apiPath + '/name?name=:name',
                            params:{name: '@name'}
                        },
                        'picture' : {
                            method : 'POST'
                        },
                        'teams' : {
                            method : 'GET',
                            url: apiPath + '/temas',
                            isArray: true,
                        },
                        'team_add' : {
                            method : 'POST',
                            url: apiPath + '/temas'
                        },
                        'team_delete' : {
                            method : 'DELETE',
                            url: apiPath + '/temas/:team',
                            params:  {
                                team: '@team'
                            }
                        },
                        'pickup' : {
                            url: this.cfg.APIPath + '/name/pickup',
                            method: "GET"
                        },
                        'isavailable' : {
                            url: this.cfg.APIPath + '/name/isavailable',
                            method: "GET"
                        },
                        'upload': {
                            method: 'POST',
                            url: apiPath + '/picture',
                            transformRequest: function (data) {
                                var fd = new FormData();
                                angular.forEach(data, function(value, key) {
                                    fd.append(key, value);
                                });
                                return fd;
                            },
                            headers: {'Content-Type':undefined, enctype:'multipart/form-data'}
                        }
                    }
                )
            );
            this.list = null;
        }
        return this._resource ;
    }

}