class controller {

    $onInit() {
        this.prevent = _.where(this.list, {DeleteAction: 'prevent'});
        this.links = _.filter(this.list, (v) => { return !(v.DeleteAction == 'prevent') });
        this.groupPrevent = this.group(this.prevent);
        this.groupLinks = this.group(this.links);
    }

    group (list) {
        var group = {};
        _.each(list, (v) => {
            if(!group[v.Type]) group[v.Type] = [];
            group[v.Type].push(v);
        });
        return group;
    }

}

export const DependenceComponent = {
    bindings: {
        list: '<',
        reasone: '<'
    },
    controller: controller,
    template: `
        <p ng-if="$ctrl.reasone">{{$ctrl.reasone}}</p>
        <div class="cmp-block-compact" ng-if="$ctrl.prevent.length">
            <h4 class="cmp-block-compact-title">Lock list</h4>
            <div class="cmp-block-compact-content cmp-block-compact" ng-repeat="(group, list) in $ctrl.groupPrevent"> 
                <h5 class="cmp-block-compact-title">{{group}}</h5>
                <div class="cmp-block-compact-content" ng-repeat="item in list" layout="row" layout-align="start center"> 
                    <span>{{item.Name}}</span>
                    <span flex></span>
                    <div ng-if="item.DeleteAction == 'prevent' " class="md-secondary warningInfo" ><md-icon md-svg-icon="core:lock"></md-icon></div>
                </div>
            </div>
        </div>
        <div class="cmp-block-compact" ng-if="$ctrl.links.length">
            <h4 class="cmp-block-compact-title">References</h4>
            <div class="cmp-block-compact-content cmp-block-compact" ng-repeat="(group, list) in $ctrl.groupLinks"> 
                <h5 class="cmp-block-compact-title">{{group}}</h5>
                <div class="cmp-block-compact-content" ng-repeat="item in list"> {{item.Name}}</div>
            </div>
        </div>        
    `
};