
export default {
    state: {
        name: 'catalog_new.item.tab',
        params: {
            Interface: 'chart-mlapp-v2'
        }
    },
    steps:[
        {
            element: '#application-install-button',
            intro: "Install current application.",
            action: 'click'
        }
    ],
    showBullets: false,
    showButtons: false,
}
