
import {ChartsListController} from '../charts/charts_list_controller'
import {MlmodelListController} from '../mlmodel/mlmodel_list_controller'
import {CatalogDataListController} from '../catalog_data/catalog_data_list_controller'
import {CatalogListController} from './catalog_list_controller'
import {stateCatalogConfig} from './catalog_state_cfg'

import {CatalogItemController, CatalogItemChartController} from './catalog_item_controller'



export default function ($stateProvider) {
        $stateProvider
        .state('catalog', {
            parent: 'content',
            url: '/catalog',
            params: {
                Interface:'chart-mlapp-v2',
                limit: '30',
                page: '1'
            },
            resolve: {
                permissions: function () {
                    return {
                        can: () => {return false;}
                    }
                },
                // isCatalog: () => { return true; }
            },
            views: {
                "layout-page-content@" : {
                    template: `
                        <layout-page-content-title class="md-whiteframe-1dp" layout="row" layout-align="start center">
                            
                            <md-icon md-svg-icon="core:catalog"></md-icon>
                            <h3 flex>Catalog <span translate>{{$ctrl.$state.params.Interface}}</span></h3>
                            <input ng-model="search" ng-model-options='{ debounce: 1000 }' placeholder="search" ng-change="toSearch(search)">
                         </layout-page-content-title>
                        <cmp-catalog-tag-list-main class="catalog-common" flex></cmp-catalog-tag-list-main>
                    `,
                    controller: function ($scope, $state) {
                        $scope.toSearch = function (search) {
                            $state.go('catalog.search', {search: search});
                        }
                    }
                }
            }
        })

        .state ('catalog.search', {
            // url: '/search',
            url: '/search?:search:page:limit',
            params: {
                Interface: null,
                limit: '30',
                page: '1'
            },
            views: {
                "layout-page-content@" : {
                    template: `
                        <layout-page-content-title class="md-whiteframe-1dp" layout="row" layout-align="start center">
                            <md-icon md-svg-icon="core:catalog"></md-icon>
                            <h3 flex>Catalog search<span translate>{{$ctrl.$state.params.Interface}}</span></h3>
                         </layout-page-content-title>
                         <cmp-catalog-list flex class="catalog-common" layout="column" layout-align="start" item-state="'catalog.list.item'" multy="true"></cmp-catalog-list>
                    `,
                    controller: function ($scope, $state) {
                        this.serach = $state.params.search;
                    }
                }
            }
        })

        .state('catalog.list', {
            url: '/:Interface?:search:page:limit:type:keyword',
            params: {
                limit: '30',
                page: '1'
            },
            resolve: {
                catalog_dataResource: (CatalogService, $stateParams) => {
                    return CatalogService.getResource($stateParams);
                },
            },
            views: {
                "layout-page-content@" : {
                    template: `
                    <layout-page-content-list class="menuLevel layoutMenu" >
                        <h3><a ui-sref="catalog"><md-icon md-svg-icon="core:catalog"></md-icon><span class="label">Catalog</span></a></h3>
                        <cmp-catalog-menu ></cmp-catalog-menu>
                    </layout-page-content-list>
                    <layout-page-content-item>
                        <layout-page-content-title class="md-whiteframe-1dp" layout="row" layout-align="start center">
                            <layout-button-submenu></layout-button-submenu>
                            <layout-back></layout-back>
                            <h3 flex>Catalog <span translate>{{$ctrl.$state.params.Interface}}</span></h3>
                         </layout-page-content-title>
                        <cmp-catalog-list flex class="catalog-common" layout="column" layout-align="start"></cmp-catalog-list>
                    </layout-page-content-item>`,
                    controller: function ($state ) {
                        this.$state = $state;
                    },
                    controllerAs: '$ctrl'
                }
            }
        })

        .state('catalog.list.item', {
            url: '/:wsId/:item',
            params: {
                wsId: null,
                item: null
            },
            resolve: {
                catalog_dataResource: (CatalogService, $stateParams) => {
                    return CatalogService.getResource($stateParams);
                },

                pageTabs: (catalogPageTabs, catalogPageMlModelTabs, catalogPageChartTabs, catalogPageInferenceTabs, $stateParams) => {
                    switch($stateParams.Interface) {
                        case "chart-app":
                        case "chart-mlapp-v2":
                            return catalogPageChartTabs;
                        case 'mlmodel':
                            return catalogPageMlModelTabs;
                        case 'inference':
                            return catalogPageInferenceTabs;
                        default:
                            return catalogPageTabs;
                    }

                },

                catalog_data : function (catalog_dataResource, $stateParams ) {
                    return catalog_dataResource.get({
                        name : $stateParams.item
                    })
                        .$promise;
                },
            },
            views: {
                "layout-page-content@" : {
                    templateProvider: ($templateCache) => { return $templateCache.get('kuberlab/catalog/card/card_item.html');  },
                    controllerAs: '$ctrl',
                    controllerProvider: function ($stateParams, catalog_data, CatalogService) {
                        switch($stateParams.Interface){
                            case "chart-app":
                            case "chart-mlapp-v2":
                                return CatalogItemChartController;
                            default:
                                return CatalogItemController;
                        }
                    }
                }
            }
        })

        .state('catalog.list.item.tab', {
            url: '/:tab/:tabItem',
            params: {
                tab: 'versions',
                tabItem: null
            },

            views: {
                "" : {

                    templateProvider: function (pageTabs, $state, $stateParams) {
                        var current = _.findWhere(pageTabs, {title: $stateParams.tab});
                        var t = `<md-tabs md-border-bottom md-dynamic-height  >
                                <md-tab ng-repeat="tab in $ctrl.pageTabs"
                                        md-active="$ctrl.$state.params.tab == tab.title"
                                        ui-sref="{tab: tab.title}"
                                        >
                                    <md-tab-label>
                                        {{tab.title}}
                                    </md-tab-label>
                                </md-tab>
                            </md-tabs>
                            <md-content class="fullInfo padding-content">${current.content}</md-content>`;
                        return t;
                    },

                    controller: function (pageTabs, catalog_data, catalog_dataResource, $state, pageConfig, $stateParams, permissions, CatalogService) {
                        this.pageTabs = pageTabs;
                        pageConfig.setTitle(catalog_data.Name + ' ' + $stateParams.tab);
                        this.data = catalog_data;
                        this.$state = $state;
                        this.readmeUrl = '/workspace/' + this.$state.params.wsId + '/' + this.$state.params.Interface + '/' + this.$state.params.item + '/readme';
                        this.resource = catalog_dataResource;
                        this.permissions = permissions;
                        this.canManage = permissions.can('mlapp.manage');
                        this.service = CatalogService;
                    },
                    controllerAs: '$ctrl'
                }
            }
        })






























































       //
       //
       //
       //
       //
       //
       //  .state('catalog_base',{
       //      parent: 'content',
       //      abstract: true,
       //      url : '/catalog_old?:search:page:limit:type',
       //      params: {type : 'mlapp-v2'},
       //      resolve: {
       //          chartResource : function(ChartsService, $stateParams, pageConfig){
       //              pageConfig.setContentId('catalog');
       //              pageConfig.setTitle('catalog');
       //              return ChartsService.getResource($stateParams);
       //          }
       //      },
       //      views: {
       //          '' : {
       //              template : `
       //                  <div class="contentPageTitle">
       //                      <div class="main_width_greed inner">
       //                          <div class="title flex layout-row layout-align-start-center">
       //                              <span class="item">CATALOG</span>
       //                          </div>
       //                      </div>
       //                  </div>
       //                  <div class="main_width_greed">
       //                      <div ui-view class=" innerContent"></div>
       //                  </div>
       //              `
       //          }
       //      }
       //  })
       //
       //  .state('catalog', {
       //      parent: 'catalog_base',
       //      url: '',
       //      resolve: {
       //          // chartList : function (ChartsService, chartResource) {
       //          //     return ChartsService.getList(true);
       //          // }
       //      },
       //      views: {
       //          "" : {
       //              templateProvider: function ($templateCache) {
       //                  return $templateCache.get('kuberlab/charts/card/card_chart_list.html');
       //              },
       //              controller: ChartsListController,
       //              controllerAs: '$ctrl'
       //          }
       //      }
       //  })
       //
       //  .state('mlcatalog_base',{
       //      parent: 'content',
       //      abstract: true,
       //      url : '/mlcatalog?:search:page:limit:type',
       //      resolve: {
       //          mlmodelResource : function(MlmodelService, $stateParams, pageConfig){
       //              pageConfig.setContentId('mlcatalog');
       //              pageConfig.setTitle('mlcatalog');
       //              return MlmodelService.getResource($stateParams);
       //          }
       //      },
       //      views: {
       //          '' : {
       //              template : `
       //                  <div class="contentPageTitle">
       //                      <div class="main_width_greed inner">
       //                          <div class="title flex layout-row layout-align-start-center">
       //                              <span class="item" translate>MLMODEL</span>
       //                          </div>
       //                      </div>
       //                  </div>
       //                  <div class="main_width_greed">
       //                      <div ui-view class=" innerContent"></div>
       //                  </div>
       //              `
       //          }
       //      }
       //  })
       //
       //  .state('mlcatalog', {
       //      parent: 'mlcatalog_base',
       //      url: '',
       //      views: {
       //          "" : {
       //              templateProvider: function ($templateCache) {
       //                  return $templateCache.get('kuberlab/catalog/base/card/card_list.html');
       //              },
       //              controller: MlmodelListController,
       //              controllerAs: '$ctrl'
       //          }
       //      }
       //  })
       // .state('datasets_base',{
       //      parent: 'content',
       //      abstract: true,
       //      url : '/datasets?:search:page:limit:type',
       //      resolve: {
       //          mlmodelResource : function(CatalogDataService, $stateParams, pageConfig){
       //              pageConfig.setContentId('mlcatalog');
       //              pageConfig.setTitle('mlcatalog');
       //              return CatalogDataService.getResource($stateParams);
       //          }
       //      },
       //      views: {
       //          '' : {
       //              template : `
       //                  <div class="contentPageTitle">
       //                      <div class="main_width_greed inner">
       //                          <div class="title flex layout-row layout-align-start-center">
       //                              <span class="item" translate>CATALOG_DATA</span>
       //                          </div>
       //                      </div>
       //                  </div>
       //                  <div class="main_width_greed">
       //                      <div ui-view class=" innerContent"></div>
       //                  </div>
       //              `
       //          }
       //      }
       //  })
       //
       //  .state('datasets', {
       //      parent: 'datasets_base',
       //      url: '',
       //      views: {
       //          "" : {
       //              templateProvider: function ($templateCache) {
       //                  return $templateCache.get('kuberlab/catalog/base/card/card_list.html');
       //              },
       //              controller: CatalogDataListController,
       //              controllerAs: '$ctrl'
       //          }
       //      }
       //  })

}
