export class controller {

    constructor($scope, CatalogDataService){
        this.$scope = $scope;
        this.Service = CatalogDataService;
        this.list = [];
        this.Interface = 'dataset';
        this.label = 'Dataset';
        this.datasetStorage = {};
    }

    $onInit() {
        if(!this.data) this.data = {};
        this.setDataset(this.data);
        this.resource = this.Service.getResource({Interface: this.Interface});
        this.$scope.$watch(
            ()=>{ return  this.datasetStorage; },
            (c) => {
                this.setYaml(this.datasetStorage);
            },
            true
        );

        if(!this.data.value || !this.data.value.length){
            this.disable  = true;
            this.dataset = null;
        }
    }

    setDataset(data = {}) {
        // this.datasetStorage = {
        //     dataset: data.value || '',
        //     workspace: data.workspace || '',
        //     version: data.version || ''
        // };
        this.datasetStorage = {
            dataset: data ? data.value || '' : '',
            workspace: data ? data.workspace || '' : '',
            version: data ? data.version || '' : ''
        };


        // if(data){
        //     this.datasetStorage = {
        //         dataset: data.value,
        //         workspace: data.workspace,
        //         version: data.version
        //     };
        // } else {
        //     this.datasetStorage = {workspace: "", dataset: "", version: ""};
        // }
    }

    setData(dataset) {
        if (dataset) {
            this.data.value = dataset.dataset;
            this.data.workspace = dataset.workspace;
            this.data.version = dataset.version;
        }else{
            this.data.value = '';
            this.data.workspace = '';
            this.data.version = '';
        }

    }

    setYaml(dataset) {
        this.setData(dataset);
        this.parent.setYaml();
    }

    disabled(disable) {
        if(disable || this.disable){
            this.oldDataset = _.clone(this.datasetStorage);
            this.setDataset();
        } else {
            this.datasetStorage = this.oldDataset;
        }
        this.setYaml(this.datasetStorage);
    }



}

export const template = `
        <div flex layout="row" layout-align="start center">
            <cmp-storage-dataset data="$ctrl.datasetStorage" ng-if="!$ctrl.disable" workspace-name="$ctrl.parent.workspace_name" flex></cmp-storage-dataset>
            
            <div ng-if="$ctrl.disable" flex layout="row" layout-align="start center">
                <md-input-container flex>
                    <label translate>{{$ctrl.label}}</label>        
                    <md-select ng-model="$ctrl.dataset.dataset" ng-disabled="$ctrl.disable" >
                        <!--<md-option ng-repeat="item in $ctrl.list" ng-value="item" >{{item.Name}} [{{item.WorkspaceDisplayName}}]</md-option>-->
                    </md-select>    
                </md-input-container>
                <md-input-container flex>
                    <label translate>COMMON_VERSION</label>        
                    <md-select ng-model="$ctrl.dataset.version" ng-disabled="$ctrl.disable" >
                        <!--<md-option ng-repeat="item in $ctrl.versions" ng-value="item.Version" >{{item.Version}} (<span ng-bind-html="$ctrl.Service.datasetVersionSize(item)"></span>)</md-option>-->
                    </md-select>    
                </md-input-container>
            </div>
            <md-checkbox ng-change="$ctrl.disabled()" ng-model="$ctrl.disable">{{'COMMON_DISABLE' | translate }}</md-checkbox>
        </div>
    `;

export const ChartWizardDatasetComponent = {
    bindings: {
        parent: "<",
        data: "="
    },
    controller : controller,
    template: template
};

