export class LayoutService {
    constructor() {
        this.level = 1;
        this.urls = [];
    }

    setLevel (level) {
        this.level = level;

    }

    /**
     * description position in ui
     * @param data {pic/icon, title, state, params}
     * @param level
     */
    addUrlPoint(data, level) {
        // debugger;
        level = _.isUndefined(level) ? this.urls.length : level ;
        this.urls = this.urls.slice(0,level);
        this.setLevel(level);
        if(data) this.urls[level] = data;
    }
}
