import toCatalog from '../serines/to_catalog'
import toCatalogProject from '../serines/to_catalog_project'
import toCatalogProjectItem from '../serines/to_catalog_project_item'
import toCatalogProjectItemInstall from '../serines/to_catalog_project_item_install'
import toCatalogProjectItemInstallAction from '../serines/to_catalog_project_item_installaction'


export const scenCreateProject = [
    toCatalog,
    toCatalogProject,
    toCatalogProjectItem,
    toCatalogProjectItemInstall,
    toCatalogProjectItemInstallAction
];