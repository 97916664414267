class controller {
//'float', 'float_list', 'int', 'int_list', 'bytes', 'bytes_list'
    constructor(ServingService, $state, $scope) {
        this.data = {};
        this.service = ServingService;
        this.$state = $state;
        this.rConfig = {};
        // this.proxy = 'tfproxy';
        this.proxy = 'proxy';
        this.$scope = $scope;

        $scope.$watch(
            () => { return this.data; },
            (c) => {
                this.init();
            },
            true
        );
        this.jsonTypes = {
            "float":  1,
            "double": 2,
            "int32":  3,
            "uint8":  4,
            "int16":  5,
            "int8":   6,
            "string": 7,
            "bytes": 7,
            "complex64": 8,
            "int64":     9,
            "int": 9,
            "bool":       10,
            "qint8":      11,
            "quint8":     12,
            "qint32":     13,
            "bfloat16":   14,
            "qint16":     15,
            "quint16":    16,
            "uint16":     17,
            "complex128": 18,
            "half":       19,
            "resource":   20,
        };

        this.canUploda = true;
        $scope.$watch(
            () => { return this.config;},
            () => {
                this.canUploda = true;
                this.imageBuf = false;
                this.databuf = null;
                this.savingError = false;
            },
            true
        )
    }

    init() {
        this.params = [];
        this.config = {};
        this.rConfig = {
            Name : this.data.Name
        };
        if( this.data.Config.spec){
            this.params = this.data.Config.spec.params;
        } else {
            // this.params = [
            //     {name: 'float', type: 'float'},
            //     {name: 'int', type: 'int'},
            //     {name: 'bytes', type: 'bytes'},
            //     {name: 'byte', type: 'byte'}
            // ]
        }
        if(this.data.Config && this.data.Config.ports && _.isArray(this.data.Config.ports) ) {
            var ports = _.map(this.data.Config.ports, (v) => { return v.targetPort; });
            this.rConfig.port = ports[0];
        }

        this.rConfig.signature = this.data.Config.spec.signature;
        this.rConfig.model = this.data.Config.spec.model;
        this.setUrl();

        _.each(this.params, (item) => {
            if(item.type == 'byte' || item.type == 'bytes')
                this.paramImageName = item.name;
        })
    }


    setUrl(){
        this.requestUrl = document.location.origin + this.service.getWsUrl(this.$state.params) + '/' + this.rConfig.Name + '/' + this.proxy;

        if(this.rConfig.port){
            this.requestUrl = this.requestUrl + '/' + this.rConfig.port;
            if(this.rConfig.model) {
                this.requestUrl = this.requestUrl + '/' + this.rConfig.model;
                if (this.rConfig.signature) {
                    this.requestUrl = this.requestUrl + '/' + this.rConfig.signature;
                    if (this.rConfig.version) {
                        this.requestUrl = this.requestUrl + '/' + this.rConfig.version;
                    }
                }
            }
        }
    }

    send () {
        debugger;
        var data = {};
        if(this.data.Config.spec.rawInput)
            data.raw_input = true;
        if(this.data.Config.spec.outMimeType)
            data.out_mime_type = this.data.Config.spec.outMimeType;
        if(this.data.Config.spec.outFilter && this.data.Config.spec.outFilter.length > 0)
            data.out_filters = this.data.Config.spec.outFilter.join(",");
        this.isImage = this.data.Config.spec.outMimeType && this.data.Config.spec.outMimeType.substr(0,6) === "image/";

        data.inputs = this.params;

        // data.inputs = {};
        //
        // _.each(this.params, (item) => {
        //     var v = !_.isUndefined(this.config[item.name]) ? this.config[item.name] : _.isArray(item.value) ? item.value[0] : item.value;
        //     var vv = v;
        //     if(item.type == 'string' || item.type == 'strings') vv = btoa(vv);
        //     if(item.type == 'byte' || item.type == 'bytes') vv = v.replace(/data:image\/.*;base64,/, '');
        //
        //     var d = {
        //         data: this.jsonTypes[item.type] == 7 || this.jsonTypes[item.type] == 10 ? [vv] : vv,
        //         dtype: this.jsonTypes[item.type]
        //     };
        //
        //     data.inputs[item.name] = d;
        // })


        this.request = this.service.getResource( {wsId: this.data.WorkspaceName, Name: this.data.Name}, null,
            (e) => {
                if (e.lengthComputable) {
                    self.loadingProgres = ~~((e.loaded / e.total) * 100);
                }
            }
        )
            [this.proxy]( this.rConfig, data );

        this.saving = true;
        this.sending = true;
        this.savingError = false;

        this.imageBuf = null;
        this.dataBuf = null;
        this.canUploda = false;
        var self = this;
        this.request
            .$promise
            .then(
                (r) => {
                    if(self.isImage)
                        self.imageBuf = r.output[0];
                    else
                        self.dataBuf = r;

                    this.$scope.$emit('serving_request_complite', r);
                },
                (err) => {
                    this.savingError = err;
                }
            )
            .finally(() => {
                this.sending = false;


            });
    }
}


export const ServingParametersComponent = {
    bindings: {
        data: '='
    },
    controller: controller,
    template:  ($templateCache) => {
        return $templateCache.get('kuberlab/serving/parameters/serving_parameters_component.html');
    },
};
