

import {ChartsListController} from './charts_list_controller'
import {ChartsItemController, ChartsItemNewController} from './charts_item_controller'

export default function ($stateProvider) {
    $stateProvider

        .state('wsCharts', {
            parent: 'wsBase',
            abstract: true,
            // url : '/charts?:search:page',
            resolve: {
                chartResource : function(workspace, ChartsService, $state, $stateParams, pageConfig){
                    pageConfig.setTitle('charts');
                    return ChartsService.getResource($stateParams);
                }
            }
        })

        .state('wsChartAdd', {
            parent: 'wsCharts',
            url : '/add_chart',
            resolve: {
                currentUser : function ( CurrentUser ) {
                    return CurrentUser;
                },
                chart : function( chartResource, currentUser, workspace, $rootScope){
                    $rootScope.pageTitleConfig =  [
                        {
                            title: workspace.DisplayName,
                            state: 'workspace'
                        },
                        {
                            title : "new chart",
                            state: 'wsChartAdd'
                        }
                    ];
                    return new chartResource({RepositoryDir: "/"});
                }
            },
            views : {
                "@wsBase" : {
                    templateProvider: function ($templateCache) {
                        return $templateCache.get('kuberlab/charts/card/card_chart_new.html');
                    },
                    controller: ChartsItemNewController,
                    controllerAs: '$ctrl'
                }
            }
        })

        .state('wsChartsList', {
            parent: 'wsCharts',
            url : '/charts?:search:page:limit:type',
            params : {
                type: "mlapp-v2"
            },

            resolve: {

                addBtn : function ($rootScope, workspace) {
                    $rootScope.pageTitleConfig =  [
                        {
                            title: workspace.DisplayName,
                            state: 'workspace'
                        },
                        {
                            title : "charts",
                            state: 'wsChartsList'
                        }
                    ];

                    return $rootScope.addButtonConfig = [
                        {
                            titile :'Add chart',
                            icon: "core:chart",
                            state: {
                                name: 'wsChartAdd'
                            }
                        }
                    ];
                }
            },
            views: {
                "@wsBase" : {
                    templateProvider: function ($templateCache) {
                        return $templateCache.get('kuberlab/charts/card/card_chart_list.html');
                    },
                    controller: ChartsListController,
                    controllerAs: '$ctrl'
                }
            }
        })

        .state('wsChartItem_base', {
            parent: 'wsCharts',
            url: '/:chartName/:chartVersion/:tab',
            params: {
                tab: 'readme',
                chartVersion : 'latest'},
            abstract: true,
            resolve: {
                chartCommentResource: function (ChartsCommentsService, $stateParams) {
                    return ChartsCommentsService.getResource($stateParams);
                },

                chart : function (chartResource, $stateParams, chartCommentResource) {
                    return chartResource.chartByVersion({
                            chartName : $stateParams.chartName,
                            chartVersion : $stateParams.chartVersion
                        })
                        .$promise;
                    },

                // chartVersions: function (chartResource, chart, $rootScope) {
                //         return chartResource.chartVersions({chartName : chart.Name})
                //         .$promise;
                //     },

                addBtn : function (workspace, $rootScope, chart) {
                        $rootScope.pageTitleConfig =  [
                            {
                                title: workspace.DisplayName,
                                state: 'workspace'
                            },
                            {
                                title : "charts",
                                state: 'wsChartsList'
                            },
                            {
                                title : chart.DisplayName,
                                state: 'wsChartItem'
                            }
                        ];

                        return $rootScope.addButtonConfig = null;

                    }
                },
            views: {
                        "@wsBase" : {
                        templateProvider: function ($templateCache){
                        return $templateCache.get('kuberlab/charts/card/card_chart_item.html');
                    },
                    controller: ChartsItemController,
                    controllerAs: '$ctrl'
                }
            }
        })

        .state('wsChartItem', {
            parent: 'wsChartItem_base',
            url:'/:tabItem',
            resolve: {
                currentTab : function (chartPageTabs, $stateParams, $q, pageConfig, chart) {
                    var tab = _.findWhere(chartPageTabs, {title: $stateParams.tab});

                    if(tab){
                        return $q.resolve(tab);
                    }
                    else
                        return $q.reject();
                }
            },
            views: {
                tabContent : {
                    templateProvider: function (currentTab) {
                        return currentTab.content;
                    },
                    controller: function (chart, chartResource, $state, pageConfig, $stateParams) {
                        // debugger;
                        pageConfig.setTitle(chart.Name + ' ' + $stateParams.tab);
                        this.chart = chart;
                        this.chartResource = chartResource;
                        this.$state = $state;
                    },
                    controllerAs: '$ctrl'
                }
            }
        })




}
