export function LayoutPageTitleUrl(LayoutService) {

    function link(scope) {
        scope.$watchCollection(
            () =>  LayoutService.urls ,
            (urls) => {
                scope.urls = urls;
            },
            true
        );
    }
    return({
        link: link,
        restrict: "E",
        template: `
            <span ng-repeat="item in urls " >
                <img class="icon" ng-src="{{item.pic}}" ng-if="item.pic">
                <a ng-if="!$last" ui-sref="{{item.state}}({{item.params}})" class="item" translate>{{item.title}}</a>
                <span ng-if="$last" class="item" translate>{{item.title}}</span>
                <span ng-if="!$last">/</span>
            </span>
        `
    });
    
}
