
class controller {
    constructor ($state) {
        this.resourceUrl = '/workspace/' + $state.params.wsId + '/charts/' + $state.params.chartName + '/readme';
    }
}


export const ChartsReadmeComponent = {
    controller : controller,
    template: `
        <cmp-viewer-md resource-url="$ctrl.resourceUrl" layout-padding  read-only="true"></cmp-viewer-md>
    `
};
