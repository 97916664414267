import {CatalogBaseItemController} from '../catalog/base/item_controller'

export class InferenceItemController extends CatalogBaseItemController{
    constructor (inference, pageTabs, inferenceResource, InferenceService, $scope, permissions) {
        super();
        this.permissions = permissions;
        this.canManage = permissions.can('dataset.manage');
        this.data = inference;
        this.resource = inferenceResource;
        this.Service = InferenceService;
        this.$scope = $scope;
        this.isLoading = {};
        this.loadingError = {};
        this.stateName = 'wsInferenceItem';
        this.stateParentName = "wsInferenceList";
        this.stateCatalog = "inferences";
        this.pageTabs = pageTabs;
        debugger;
    }
}
