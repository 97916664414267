class TeamRightsController {
    constructor (OrganizationService, $q, $scope) {
        this.$q = $q;
        this.isOwnerTeam = false; //bindings html
        this.resource = OrganizationService.getTeamsResource();
        this.$scope = $scope;
    }

    $onInit() {
        this.listRights()
    }

    listRights (data) {
        var self = this;
        var action = 'permissions';
        if(_.isObject(data)) action = 'update_permissions';
        this.resource[action]({org: this.orgId, teamId:this.teamId}, data)
            .$promise
            .then(
                function (r) {
                    self.list = r;
                    self.bList = _.clone(r);
                    // debugger;
                    if(r.owner) {
                        self.isOwnerTeam = true;
                        self.$scope.disabled = true;
                        _.each(self.list, function (v, k) {
                            self.list[k] = true;
                        });
                    }

                    delete self.list.owner;
                },
                function (err) {
                    debugger;
                }
            );
    }

    toggle (item) {
        if(!this.isOwnerTeam){
            this.listRights(this.list);
        }else{
            this.list[item] = !this.list[item];
        }
    }
}


export const TeamRightsComponent = {
    bindings: {
        isOwnerTeam : '<',
        teamId: '<',
        orgId: '<'
    },
    controller : TeamRightsController,
    template : `
        <div  class="title"><h5>{{"SETTINGS_TEAM_PERMISSION_TITLE"|translate}}</h5></div>
        <md-list flex>
            <md-list-item ng-repeat="(key, item) in $ctrl.list">
                <p>{{ key.replace('.', ' ') }}</p>
                <md-checkbox class="md-secondary" ng-attr="{disabled : disabled}" ng-model="$ctrl.list[key]" ng-click="$ctrl.toggle(key)"></md-checkbox>
            </md-list-item>
        </md-list>`
};