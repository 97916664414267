import {CoreServiceResource} from '../core/service/core_service_resource'

export class ServingService extends CoreServiceResource {
    constructor($resource, $q, KuberlabConfigApi, $rootScope, UserConfigService, WorkspaceService) {
        super(...arguments);
        this._params = {};
        this.apiDir = '/serving';
        this.UserConfigService = UserConfigService;
        this.WorkspaceService = WorkspaceService;

        this.watchWS('serving', this.ws_serving);
        this.watchWS('servingCreate', this.ws_servingCreate);
        this.watchWS('servingDelete', this.ws_servingDelete);
    }

    getWsUrl (params, link) {
        return this.cfg.APIPath + '/workspace/' + (params.wsId  ? params.wsId  : ':wsId')  + this.apiDir;
    }

    getResourceUrl(params) {
        return this.getWsUrl(params) + '/:Name';
    }

    getResource(params = {}, clear, prCb ) {
        if( this._params.wsId != params.wsId || !this._resource || clear || prCb) {

            this._itemPromise = null;

            if((prCb && clear) || clear || this._params.wsId != params.wsId ) {
                this.list = null;
                this.listPromise = null;
            }

            if(!prCb ) {
                prCb= () => {};
            }

            this._params = {wsId : params.wsId};
            // console.info('serving params', this._params);
            var url = this.getResourceUrl(params);
            this._resource = this.$resource(
                url,
                { Name: '@Name' },
                _.extend(
                    {},
                    this.cfg.ResourceParams,
                    {
                        'create' : {
                            method: 'POST',
                            params: {name: '@createName'}
                        },
                        'restart' : {
                            method: 'POST',
                            url: url + '/restart'
                        },
                        'disable' : {
                            method: 'POST',
                            url: url + '/disable'
                        },
                        'enable' : {
                            method: 'POST',
                            url: url + '/enable'
                        },
                        'logs' : {
                            method: 'GET',
                            url: url + '/logs'
                        },
                        'jobs' : {
                            isArray: true,
                            method: 'GET',
                            url: url + '/projresult/jobs'
                        },
                        'status' : {
                            method: 'GET',
                            url: url + '/status'
                        },

                        'requestList' : {
                            // isArray: true,
                            method: 'GET',
                            url: url + '/request'
                        },
                        'requestTemplate' : {
                            params:{
                                template: '@template'
                            },
                            method: 'GET',
                            url: url + '/request/:template'
                        },
                        'responseExample' : {
                            method: 'GET',
                            url: url + '/response/example'
                        },
                        'requestTemplates' : {
                            isArray: true,
                            method: 'GET',
                            url: url + '/request_templates'
                        },

                        'kproxy': {
                            method: 'POST',
                            url: url + '/kproxy/:port/:version/:signature',
                            params: {
                                port: '@port',
                                version: '@version',
                                signature : '@signature'
                            },
                            transformRequest: function (data) {
                                var fd = new FormData();
                                angular.forEach(data, function (value, key) {
                                    fd.append(key, value);
                                });
                                return fd;
                            },
                            uploadEventHandlers: {
                                progress: prCb
                            },
                            headers: {'Content-Type': undefined, enctype: 'multipart/form-data'}
                        },
                        'tfproxy': {
                            method: 'POST',
                            url: url + '/tfproxy/:port/:model/:signature/:version',
                            params: {
                                port: '@port',
                                version: '@version',
                                signature : '@signature',
                                model: '@model'
                            },
                        },
                        'projcall': {
                            url: url + '/projcall',
                            method: 'POST',
                            transformRequest: this.formDataObject,
                            headers: { 'Content-Type': undefined, enctype: 'multipart/form-data' }
                        },
                        'form-data': {
                            url: url + '/form-data',
                            method: 'POST',
                            transformRequest: this.formDataObject,
                            headers: { 'Content-Type': undefined, enctype: 'multipart/form-data' }
                        },
                        'proxy': {
                            url: url + '/proxy',
                            method: 'POST'
                        }
                        , 'toGallery': {
                            method: 'PUT',
                            url: url + '/gallery'
                        }
                        , 'fromGallery': {
                            method: 'DELETE',
                            url: url + '/gallery'
                        }
                        , 'addPicture': {
                            method: 'POST',
                            url: url + '/picture',
                            transformRequest: function (data) {
                                var fd = new FormData();
                                angular.forEach(data, function (value, key) {
                                    fd.append(key, value);
                                });
                                return fd;
                            },
                            uploadEventHandlers: {
                                progress: prCb
                            },
                            headers: {'Content-Type': undefined, enctype: 'multipart/form-data'}

                        }
                        , servingGraph: {
                            method: "GET",
                            url: url + "/graph",
                            asArray: true,
                            cancellable: true,
                            params: {
                                Name: "@Name"
                            }
                        }
                    }
                )
            );
            // this._params = {wsId : params.wsId};
        }
        return this._resource;
    }

    getItem(Name){
        // console.info('service item', Name, this._params);
        if(this._params.itemName != Name){
            this._itemPromise = null;
        }
        if(!this._itemPromise ){
            this._params.itemName = Name;
            this._itemPromise = this._resource.get({Name:Name}).$promise;
        }
        return this._itemPromise;
    }

    getLogs(params) {
        return this.getResource(params).logs({Name : params.servingName}).$promise;
    }

    getJobs(params) {
        return this.getResource(params).jobs({Name : params.servingName}).$promise;
    }



    getStatus(params) {
        return this.getResource(params).status({Name : params.servingName}).$promise;
    }

    Name (data) {
        return data.Name ? data.Name : data.Task + ':' + data.Build;
    }

    getServingItem(data) {
        if(this.wsServingMyEvent(data)) {
            return _.findWhere(this.list, {Name : data.Name});
        }
    }

    wsServingMyEvent (data) {
        return (this._params.wsId == data.WorkspaceName && _.isArray(this.list))
    }

    ws_serving(ev, data) {
        var item = this.getServingItem(data);
        if(item) {
            item = _.extend(item, data);
            this.$rootScope.$digest();
        }
    }

    ws_servingCreate(ev, data) {
        var item = this.getServingItem(data);
        if(this.wsServingMyEvent(data) && !item ) {
            this.list.push(new  this._resource(data));
            this.listEmpty = false;
            this.$rootScope.$digest();
        }
    }

    ws_servingDelete(ev, data) {
        var item = this.getServingItem(data);
        this._itemPromise = null;
        if(item) {
            this.list = _.without(this.list, item);
            if(!this.list.length) this.listEmpty = true;
            this.$rootScope.$digest();
        }
    }


    lastServ (wsId) {
        return this.UserConfigService.getWsItemConfig(wsId, 'lastServ', 0, 'list');
    }

    getLastServ (wsId) {
        this.lastProjects = this.lastServ(wsId);
        var deferred = this.$q.defer();
        var l = _.map(this.lastProjects, (i) => {
            return i.Name;
        });
        if(l.length) {
            var data = {
                names: l.join(',')
            };

            this.WorkspaceService.getResource({wsId: wsId})
                .dashboard_servings(data)
                .$promise
                .then(
                    (r) => {
                        deferred.resolve(r);
                        this.updateListServ( wsId, angular.copy(r) );
                    },
                    (err) => {
                        deferred.reject(err)
                    }
                )
        } else {
            deferred.resolve( [] );
        }
        return deferred.promise;
    }

    addLastServ (wsId, app) {
        if(app && app.Name){
            var list = this.lastServ(wsId) || [];
            var hasApp = _.findWhere(list, {Name: app.Name});
            if (hasApp) {
                list = _.without(list, hasApp);
            }
            list.unshift(app);
            this.updateListServ(wsId, _.first(list, 10));
        }
    }

    updateListServ (wsId, apps) {
        this.UserConfigService.setWsItemConfig(wsId, 'lastServ', 0, 'list', apps);
    }

    formDataObject (data){
        var fd = new FormData();
        _.each(data, (v, k) => {
            fd.append(k, v);
        });
        return fd;
    }
}
