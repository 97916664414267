
export class SharedClusterShareController {
    constructor (clusterResource, dialog, params, data, listController) {
        this.clusterResource = clusterResource;
        this.$mdDialog = dialog;
        this.params = params;
        this.data = data;
        this.share_cluster_resource = GlobalSettings.share_cluster_resource;
        if(this.data && this.data.DisplayName) {
            this.addShare = true;
        }
        angular.element(document.body).injector().get('CurrentUser')
            .then((r) => {
                this.CurrentUser = r;
            });

        this.limitsList = {
            // "cpu_mi": 0,
            "cpu": '',
            "gpu": 0,
            // "memory_mb": 0,
            "memory": '',
            "disk_gb": 0,
            "parallel_runs": 0,
            "replicas": 0,
            "execution_time": 0
        };

        if(listController && listController.type == 'available' && data.HasResourceLabel){
            this.reshare = true;
        }
        // var inject = angular.element(document.body).injector();
        // var cService = inject.get('ClusterService');

        this.storage = {};

        this.clusterResource.storage({clusterName: params.clusterName})
            .$promise
            .then(
                (r) => {
                    var Storage = (this.data && this.data.Storage) ? this.data.Storage : false;
                    _.each(r, (i) => {
                        this.storage[i.Name] = Storage ? _.indexOf(Storage, i.Name) != -1 : true;
                    });
                },
                err => {
                    debugger
                }
            )
    }

    share (form) {
        if(form.$valid){
            this.sharing = true;
            var self = this;
            this.sharingError = false;
            var data = this.data;
            data.Storage = [];
            if(this.storage) {
                _.each( this.storage, (v, k) => {
                    if(v) data.Storage.push(k);
                })
            }
            if(this.data.Global){
                data = _.omit(this.data, 'WorkspaceNames', 'Emails');
            }

            this.clusterResource.add_share(this.params, this.data)
                .$promise
                .then(
                    function (r) {
                      self.$mdDialog.hide(r);
                    },
                    function (err) {
                        self.sharingError = err;
                    }
                )
                .finally(
                    function () {
                        self.sharing = false;
                    }
                )

        }
    }

    isNumber(n, k) {

        // console.log(k, _.isNumber(n));
        return _.isNumber(n);
    }
}

export const SharedClusterShareTemplate = `
<md-dialog aria-label="" class="Share cluster">
    <form name="shareCluater" ng-submit="$ctrl.share(shareCluater)">
        <md-toolbar class="md-primary">
            <div class="md-toolbar-tools">
                <h2>Share cluster</h2>
                <span flex></span>
                <md-button class="md-icon-button" ng-click="$ctrl.$mdDialog.cancel()">
                    <md-icon md-svg-src="core:close" aria-label="Close dialog"></md-icon>
                </md-button>
            </div>
        </md-toolbar>
        <md-dialog-content >
            <div class="flex layout-column layout-align-start md-padding" style="width: 350px;">
                <md-input-container class="flex" ng-if="!$ctrl.reshare">
                    <label translate>COMMON_DISPLAYNAME</label>
                    <input type="text" name="DisplayName" ng-model="$ctrl.data.DisplayName" required ng-disabled="$ctrl.addShare">
                </md-input-container>

                <md-input-container class="flex" ng-if="!$ctrl.reshare">
                    <label translate>COMMON_DESCRIPTION</label>
                    <textarea type="text" name="Description" ng-model="$ctrl.data.Description" ng-disabled="$ctrl.addShare"></textarea>
                </md-input-container>

                <md-input-container class="flex" ng-if="$ctrl.CurrentUser.Admin && !$ctrl.addShare"  ng-if="!$ctrl.reshare">
                    <md-checkbox ng-model="$ctrl.data.Global">{{"COMMON_GLOBAL_CALUSTER" | translate}}</md-checkbox>
                    <md-checkbox ng-model="$ctrl.data.Free" ng-disabled="!$ctrl.data.Global">{{"COMMON_GLOBAL_FREE_CALUSTER" | translate}}</md-checkbox>
                </md-input-container>

                <md-input-container class="flex">
                    <label translate>SHARED_CLUSTER_WORKSPACE_ID</label>
                    <textarea type="text" name="Description" ng-model="$ctrl.data.WorkspaceNames" ng-disabled="$ctrl.data.Global"></textarea>
                    <help-hint hint="shared.cluster.workspace.id"></help-hint>
                </md-input-container>

                <md-input-container class="flex">
                    <label translate>SHARED_CLUSTER_INVATE_EMAIL</label>
                    <textarea name="Description" ng-model="$ctrl.data.Emails" ng-disabled="$ctrl.data.Global"></textarea>
                    <help-hint hint="shared.cluster.invate.email"></help-hint>
                </md-input-container>
                
                <md-input-container ng-if="$ctrl.share_cluster_resource && !$ctrl.reshare" flex >
                    <label>Resource Label</label>
                    <input type="text" name="ResourceLabel" ng-model="$ctrl.data.ResourceLabel" ng-pattern="'^[A-Za-z0-9][A-Za-z0-9-_]{1,60}[A-Za-z0-9]$'">
                    <div ng-messages="shareCluater.ResourceLabel.$error">
                        <div ng-message="pattern">Pattern error ^[A-Za-z0-9][A-Za-z0-9-_]{1,60}[A-Za-z0-9]$ </div>
                    </div>
                </md-input-container>
                
                
                <div class="cmp-block-compact" class="flex"  ng-if="!$ctrl.reshare" style="padding: 10px 0">
                    <label class="cmp-block-compact-title" translate>Share storage:</label>
                    <div class="cmp-block-compact-content layout-column layout-align-start">
                        <div ng-repeat="(k,v) in $ctrl.storage">
                            <md-checkbox ng-model="$ctrl.storage[k]">{{k}}</md-checkbox>
                        </div>
                    </div>
                </div>

                <div class="cmp-block-compact" class="flex"  ng-if="!$ctrl.reshare">
                    <label class="cmp-block-compact-title" translate>COMMON_LIMITS</label>
                    <div class="cmp-block-compact-content layout-column layout-align-start">
                        <md-input-container class="flex" ng-repeat=" (k, v) in $ctrl.limitsList" >
                            <label >{{'PLAN_LIMIT_NAME_' + k | translate }} {{'PLAN_LIMIT_CLUSTER_UNIT_' + k | translate }}</label>
                            <input ng-if="!$ctrl.isNumber(v, k)" type="text" ng-model="$ctrl.data.Shared.limits[k]" ng-disabled="$ctrl.addShare">
                            <input ng-if="$ctrl.isNumber(v, k)" type="number" step="1" min="0" ng-model="$ctrl.data.Shared.limits[k]" ng-disabled="$ctrl.addShare">
                            <help-hint hint="billing.limit.{{k}}"></help-hint>
                        </md-input-container>
                    </div>
                    <!--{{$ctrl.limitsList}}-->
                </div>
                
            </div>
        </md-dialog-content>

        <md-dialog-actions layout="row">
            <div class="flex">
                <md-progress-linear ng-if="$ctrl.sharing" flex></md-progress-linear>
                <cmp-api-error-service ng-if="$ctrl.sharingError" error="$ctrl.sharingError"></cmp-api-error-service>
            </div>
            <md-button ng-click="$ctrl.$mdDialog.cancel()">Cancel</md-button>
            <md-button type="submit" class="md-raised md-primary">{{"COMMON_SHARE" | translate}}</md-button>
        </md-dialog-actions>
    </form>
</md-dialog>
`;
