export function HlsVideo ($q) {
    var slice = Array.prototype.slice;

    return {
        restrict: 'AE',
        template: `
            <video ng-if="!mjpg"controls autoplay style="    width: 720px;
            margin-left: auto;
            margin-right: auto;
            display: block;"
            id="video"></video>
            <img ng-if="mjpg" src="{{src}}" 
                style="    
                min-width: 720px;
                margin-left: auto;
                margin-right: auto;
                display: block;
                min-height: 360px;
                "
                
                />                
        `,
        scope: {
            src: '=src',
        },
        link: function(scope, elem, attrs) {
            var hls = new Hls();
                scope.$on('$destroy', function() {
                    hls && hls.destroy();
                });
            scope.mjpg = /.mjpg?/.test(scope.src);
            if ( !scope.mjpg ) {
                _.delay(
                    () => {
                        var self = this;
                        self.video = document.getElementById('video');
                        if(Hls.isSupported()) {
                            hls.loadSource(scope.src);
                            hls.attachMedia(this.video);

                            hls.on(Hls.Events.MANIFEST_PARSED,function() {
                                self.video.play();
                            });
                        }
                        else if (video.canPlayType('application/vnd.apple.mpegurl')) {
                            self.video.src = scope.src;
                            self.video.addEventListener('loadedmetadata',function() {
                                self.video.play();
                            });
                        }
                    },
                    1000
                )
            }
            }
    };
}
