import {InstallTab} from './install_tab'

class controller extends InstallTab {
    constructor($state, $scope){
        super();
        this.$state = $state;
        this.$scope = $scope;
    }

    $onInit() {
        super.$onInit();
        var self= this;
        this.$scope.$watch(
            function () {
                return self.parent.target_application;
            },
            function (c) {
                self.enable();
            }
        );

        this.$scope.$watchCollection(
            function () {
                return self.parent.selectedIndex;
            },
            function (c) {
                self.enable();
            }
        );

        this.$scope.$watchCollection(
            function () {
                return self.workspace_name;
            },
            function (c) {
                self.enable();
            }
        );

        this.$scope.$watchCollection(
            function () {
                return self.parent.target_applications;
            },
            function (c) {
                self.enable();
            }
        );
    }

    canEdit() {
        if(!this.parent.tabs[this.index-1].enable) return false;
        if(!(this.parent.target_application || (this.parent.target_applications && this.parent.target_applications.length) )) return false;
        if(this.loading) return false;
        return true;
    }

}

export const InstallChartVoluesComponent = {
    bindings: {
        chartsResource: '<',
        chart: '<',
        yaml: '=',
        parent: '<',
        index: '<',
        tab: '='
    },
    controller: controller,
    template: `
        <form>
            <cmp-api-error-service ng-if="$ctrl.error" error="$ctrl.error"></cmp-api-error-service>
            <div ui-ace="{mode: 'yaml'}" ng-model="$ctrl.yaml"></div>
        </form>
    `
}