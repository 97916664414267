export class UserCardController{
    constructor ($scope, CurrentUser, AuthService, UserService) {
        var self = this;
        this.ws = UserService.ws;
        $scope.$watch(function () {
                return UserService.ws;
            },
            function (current) {
                self.ws = current;
            }
        );
        $scope.$watch(
            () => { return UserService.viewSettings; },
            (c) => {
                this.viewSettings = c;
            }
        );
        this.AuthService = AuthService;
        this.currentUser = false;
        CurrentUser.then(
            function (r) {
                self.currentUser = r;
            }
        );
        UserService.getWs();
    }
}
