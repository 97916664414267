import {InstallTab} from './install_tab'

class InstallChartCluster extends InstallTab{
    constructor(WorkspaceService, ClusterService, ProjectClusterService, $scope, ChartsService)  {
        super();
        this.WorkspaceService = WorkspaceService;
        this.$scope = $scope;
        this.ClusterService = ClusterService;
        this.ProjectClusterService = ProjectClusterService;
        this.ChartsService = ChartsService;
        $scope.$watch(
            () => { return this.parent.workspace_name; } ,
            (c) => {
                if(c){
                    this.loadClusters();
                    this.enable();
                }
            }
        );
        $scope.$watch(
            () => { return this.parent.chart; } ,
            (c) => {
                if(c){
                    this.enable();
                }
            }
        )
    }

    loadClusters() {
        var data = this.parent.isMlapp(this.parent.chart) ? null : {infrastructure : true};
        this.ClusterService.getList(this.parent.workspace_name, data, true)
            .then(
                (r) => {
                    this.clusterList = r;
                    if(this.clusterList && this.clusterList[0]) {
                        this.clusterToggle(this.clusterList[0], this.clusterList);
                    }
                },
                (err) => {
                    this.clusterList = null;
                    this.parent.clusters = [];
                }
            )
    }

    clusterToggle(item, list) {
        list = [item];
        this.parent.clusters = list;
    }

    clusterExists (item) {
        // var params = _.pick(item, "Type", "ID", "ProjectID");
        var cluster = _.findWhere(this.parent.clusters, {ClusterID : item.ClusterID});
        return !!cluster;
    }

    canEdit(){
        return !!(this.parent.workspace_name && this.parent.chart);
    }
}

export const InstallChartClusterComponent = {
    bindings: {
        tab:'=',
        parent: '<',
        index: '<'
    },

    controller: InstallChartCluster,
    template: `
        <form>
            <div class="flex layout-row title_list">
                <b class="flex">Cluster name</b>
                <!--<b style="text-align:right">Status</b>-->
            </div>
            <md-checkbox 
                ng-repeat="item in $ctrl.clusterList" 
                ng-checked="$ctrl.clusterExists(item)" 
                ng-click="$ctrl.clusterToggle(item, $ctrl.parent.clusters)">
                <div class="flex layout-row layout-align-start-center">
                    <div class="flex">
                        <div class="title"><span ng-if="item.ProjectDisplayName">{{item.ProjectDisplayName}}/</span>    {{ item.Name }}
                            <span class="help-hint">
                                <md-icon md-svg-icon="core:hint">
                                    <md-tooltip md-direction="left" class="help-hint" style="font-size: 110%">
                                        <span class="">Cluster ID: {{item.ClusterID}}</span>
                                        <div class="desk" ng-switch="item.ClusterType"> 
                                            <div ng-switch-when="infrastructure" >Provider: {{item.Meta.Provider}} - {{item.Meta.Account}}</div>
                                            <div ng-switch-default>
                                                <div ng-if="item.Meta.From">
                                                    <span ng-if="item.Meta.Global">{{item.Meta.Free ? "COMMON_GLOBAL_FREE" : "COMMON_GLOBAL" | translate}} </span>From: {{item.Meta.From.WorkspaceName}}/{{item.Meta.From.ProjectName}}/{{item.Meta.From.ClusterName}}</div>
                                            </div>       
                                        </div>
                                    </md-tooltip>
                                </md-icon>
                            </span>
                        </div>
                        <div class="desk" ng-if="item.Meta.Shared.limits">Limits - <span ng-repeat="(k, v) in item.Meta.Shared.limits">{{k}}: {{v}}<span ng-if="!$last">, </span></span> </div>
                            <!--<div class="desk" ng-switch="item.ClusterType">-->
                                <!--&lt;!&ndash;<div ng-switch-when="infrastructure" >Provider: {{item.Meta.Provider}} - {{item.Meta.Account}}</div>&ndash;&gt;-->
                                <!--&lt;!&ndash;<div ng-switch-default>&ndash;&gt;-->
                                    <!--&lt;!&ndash;<div ng-if="item.Meta.From">&ndash;&gt;-->
                                        <!--&lt;!&ndash;<span ng-if="item.Meta.Global">{{item.Meta.Free ? "COMMON_GLOBAL_FREE" : "COMMON_GLOBAL" | translate}} </span>From: {{item.Meta.From.WorkspaceName}}/{{item.Meta.From.ProjectName}}/{{item.Meta.From.ClusterName}}</div>&ndash;&gt;-->
                                    <!--&lt;!&ndash;&lt;!&ndash;<div ng-if="item.Meta.Shared">Limits: <span ng-repeat="(k,v) in  item.Meta.Shared.limits">{{k}} - {{v}}<span ng-if="!$last">, </span></span></div>&ndash;&gt;&ndash;&gt;-->
                                <!--&lt;!&ndash;</div>&ndash;&gt;-->
                                <!--&lt;!&ndash;<div ng-switch-default>{{item.Name}}</div>&ndash;&gt;-->
                            <!--</div>-->
                    </div>
                    <div>{{item.Status}}</div>          
                </div>
            </md-checkbox>
        </form>
        <div ng-if="$ctrl.loadingClusters" layout="row" layout-sm="column" layout-align="space-around">
            <md-progress-circular></md-progress-circular>
        </div>
        <div ng-if="$ctrl.clusterList && $ctrl.clusterList.length == 0">
                no item
        </div>
        
    `
};
