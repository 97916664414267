import {ws_listener} from "../../core/controller/ws_listener";

class controller extends ws_listener  {
    constructor (ApplicationsService, $state, WorkspaceService, $scope) {
        super();
        this.ApplicationsService = ApplicationsService;
        this.$state = $state;
        this.$scope = $scope;
        this.WorkspaceService = WorkspaceService;
        this.watchWS('application', this.ws_application);
        this.limit = 10;
    }

    $onInit() {
        this.lastProjects = this.ApplicationsService.lastApps(this.$state.params.wsId);
        this.ApplicationsService.getLastApp(this.$state.params.wsId)
            .then(
                (r) => {
                    this.lastProjects = r;
                },
                (err) => {}
            )
    }

    ws_application(event, data) {
        if(data.WorkspaceName == this.$state.params.wsId) {
            var i, app = _.findWhere(this.lastProjects, {Name: data.Name});
            if(app) {
                i = _.indexOf(this.lastProjects, app);
                if(i != -1){
                    this.lastProjects[i] =_.extend(this.lastProjects[i],  data);
                    this.$scope.$apply();
                    console.log( 'this.lastProjects[i]', this.lastProjects[i] );
                    this.ApplicationsService.updateListApp(this.$state.params.wsId, angular.copy(this.lastProjects));
                }
            }
        }
    }

}

export const WorkspaceLastProjects = {
    bindings: {
        limit: '='
    },
    controller: controller,
    template: `
        <md-card>
            <md-card-title>
                <md-card-title-text>
                    <span class="md-headline">Recent projects</span>
                </md-card-title-text>
            </md-card-title>
            <md-content>
                <md-list >
                    <md-list-item ng-repeat="item in $ctrl.lastProjects | limitTo:$ctrl.limit" ui-sref="wsApplication({applicationName: item.Name})">
                        <item-status-icon data="item"></item-status-icon>
                        <p>{{item.DisplayName}}</p>
                    </md-list-item>
                    <md-list-item ng-if="!$ctrl.lastProjects.length">
                         <p flex >You've not visited any <a ui-sref='wsApplicationsList'>projects</a>.</p>
                    </md-list-ite
                </md-list>
            </md-content>
          </md-card>
    `
}
