class ChartsVersionsList {
    constructor ($state, ChartsService) {
        this.chartResource = ChartsService.getResource($state);
        this.chart; // bindings html
        this.$state = $state;
        // this.editorOptions = {
        //     mode : 'tree'
        // };

        this.editorOptions = {
            mode : 'tree',
            expanded: true
        };
        this.yaml = '';
    }

    $onInit() {
        this.getYaml();
    }

    getYaml() {
        var self = this;
        this.yamlError = false;
        this.chartResource.yaml({
                wsId: this.$state.params.wsId,
                chartName : this.$state.params.chartName,
                chartVersion: this.$state.params.chartVersion
            })
            .$promise
            .then(
                function (r) {
                    self.yaml = r.yaml
                },
                function (err) {
                    self.yamlError = err;
                }
            )

    }


    aceLoaded (_editor) {
    // Options
        _editor.setReadOnly(true);
    }

}

export const ChartsVersionsListComponent = {
    bindings: {
        chart : '<',
        chartResource: '<'

    },
    controller: ChartsVersionsList,
    template: `
        <div ng-if="$ctrl.chart.Broken">Broken</div>
        <cmp-api-error-service ng-if="$ctrl.yamlError" error="$ctrl.yamlError"></cmp-api-error-service>
        <div 
            ui-ace="{mode: 'yaml', onLoad: $ctrl.aceLoaded}" 
            ng-model="$ctrl.yaml" 
            ></div>
        <!--<div -->
            <!--ng-if="!$ctrl.chart.Broken" -->
            <!--ng-jsoneditor="onLoad" -->
            <!--ng-model="$ctrl.chart.Info" -->
            <!--options="$ctrl.editorOptions" -->
            <!--&gt;-->
        <!--</div>-->
`
};