import {CoreServiceResource} from '../core/service/core_service_resource'

export class RepoService extends CoreServiceResource {

    constructor ($resource, $q, KuberlabConfigApi, $rootScope) {
        super(...arguments);
        this.listProviders = ['github', 'bitbucket', 'gitlab'];
        this.providerNames = {
            github: "Github",
            bitbucket: "Bitbucket",
            gitlab: "Gitlab"
        };
    }
    
    // getResource(params) {
    //     var apiPath = this.cfg.APIPath + '/:provider';
    //     this._resource = this.$resource(
    //         apiPath,
    //         {provider: '@provider'},
    //         _.extend({}, this.cfg.ResourceParams,
    //             {
    //                 orgs : {
    //                     method : 'GET',
    //                     isArray: true,
    //                     url: apiPath + '/orgs',
    //                     transformResponse: function (data) {
    //                         data = JSON.parse(data);
    //                         var ret = [{org: "", name: "My own repositories"}];
    //                         _.each(data, function(o){
    //                             ret[ret.length] = {org: o.name, name: "Organization: " + o.display_name};
    //                         });
    //                         return ret;
    //                     }
    //                 },
    //                 repos : {
    //                     method : 'GET',
    //                     isArray: true,
    //                     url: apiPath + '/repos/external'
    //                 },
    //                 orgrepos : {
    //                     method : 'GET',
    //                     isArray: true,
    //                     url: apiPath + '/orgs/:org/repos/external',
    //                     params: {org: '@org'}
    //                 }
    //                 ,
    //                 branches : {
    //                     method : 'GET',
    //                     isArray: true,
    //                     url: apiPath + '/repos/external/:repo/branches',
    //                     params: {repo: '@repo'}
    //                 },
    //                 org_branches : {
    //                     method : 'GET',
    //                     isArray: true,
    //                     url: apiPath + '/orgs/:org/repos/external/:repo/branches',
    //                     params: {org: '@org', repo: '@repo'}
    //                 }
    //             }
    //         )
    //     );
    //     return this._resource;
    // }


    getResource(params) {
        var apiPath = this.cfg.APIPath + '/:provider';
        this._resource = this.$resource(
            apiPath,
            {provider: '@provider'},
            _.extend({}, this.cfg.ResourceParams,
                {

                    orgs : {
                        method : 'GET',
                        isArray: true,
                        url: apiPath + '/orgs',
                        transformResponse: function (data) {
                            data = JSON.parse(data);
                            var ret = [{name: "", display_name: "My own repositories"}];
                            _.each(data, function(o){
                                ret[ret.length] = {name: o.name, display_name: "Organization: " + o.display_name};
                            });
                            return ret;
                        }
                    },
                    repos : {
                        method : 'GET',
                        isArray: true,
                        url: apiPath + '/repos'
                    },
                    orgrepos : {
                        method : 'GET',
                        isArray: true,
                        url: apiPath + '/orgs/:owner/repos',
                        params: {owner: '@owner'}
                    }
                    ,
                    branches : {
                        method : 'GET',
                        isArray: true,
                        url: apiPath + '/repo/:owner/:repo/branches',
                        params: {
                            owner: '@owner',
                            repo: '@repo'
                        }
                    }
                }
            )
        );
        return this._resource;
    }


}