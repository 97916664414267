
import {CatalogBaseService} from '../catalog/base/service'
import {CatalogService} from '../catalog/catalog_service'

export class MlmodelService extends CatalogService {
    constructor() {
        super(...arguments);
        this._params = {};
        // this.apiDir = '/mlmodel';
        this.Intrface = 'mlmodel'
    }

    getResource(params = {}, clear, prCb = () => {}) {
        params.Interface = this.Intrface;
        return super.getResource(params, clear, prCb );
    }

    // resourceAction (url) {
    //     return {
    //         'serve': {
    //             params: {
    //                 version: "@version"
    //                 },
    //             url: url + '/versions/:version/serving',
    //             method: "POST"
    //         }
    //     }
    // }
}


