class controller {
    constructor($rootScope, $templateCache, $element){
        this.$templateCache = $templateCache;
        this.$element = $element;
        var self = this;
        $rootScope.$on('show_docs', (event, data) => {
            // debugger
            // self.link = link;
            self.title = data.title || 'help';
            self.open(data.doc);
        });
    }

    open(link) {
        // debugger;
        this.md = this.$templateCache.get('docs/' + link + '.md');
        if(!this.md)
            // this.md = this.$templateCache.get('docs/index.md');
            this.md = 'not found `' + 'docs/' + link + '.md`';
        this.$element.addClass('show');
    }

    hide() {
        // this.link = null;
        this.title = null;
        this.md = null;
        this.$element.removeClass('show');
    }
}

export const DocsComponent = {
    controller : controller,
    template : `
            <md-toolbar class="layout-row layout-align-start-center">
                <div class="flex" style="padding: 5px">{{$ctrl.title}}</div>
                <md-button ng-click="$ctrl.hide()" class="md-icon-button">
                    <md-icon md-svg-icon="core:close"></md-icon>
                </md-button>
            </md-toolbar>
            <md-content ><div btf-markdown="$ctrl.md" style="padding: 5px"></div></md-content>
        `
}