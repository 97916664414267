export class AutoListSingle{
    constructor($scope) {
        this.item = {};
        $scope.$watch(
            () => { return this.data; },
            () => { this.initList(); }
        );
        $scope.$watchCollection(
            () => {return this.list; },
            () => {this.data = this.clearData()}
        )
    }

    $onInit() {
        var self = this;
        this.item = '';
        this.list;
        this.data;
        if(!this.data) {
            this.data = [];
        }
        if(!this.attr) this.attr = {};
        if(!this.attr.type) this.attr.type = {};
    }

    initList(){
        if(_.isArray(this.data) && (!this.list || !_.isEqual(this.clearData(), this.data)) || !this.data) {
            this.list = this.data  ? _.clone(this.data ) :  [];
            this.addNew();
        }
    }

    change(form, index){
        var list = _.compact(this.list);
        // if(this.list.length == index + 1) {
        if(list.length == index + 1) {
            if( form.$valid && this.list.length == index + 1 ) {
                this.addNew();
            }
        }
    }

    delete(index){
        this.list.splice(index,1);
    }

    canDelete(index){
        return (this.data && (this.data[index] || this.data.length > index )) ? true : false;
    }

    addNew(clear) {
        if(!_.isArray(this.list) || clear)
            this.list = [];
        this.list.push(angular.copy(this.item));
    }

    clearData () {
        var data = _.initial(this.list);
        return data;
    }
}

export const  AutolistSingleComponent = {
    bindings: {
        data : "=",
        labels: "<",
        pattern: '<',
        form: '<',
        attr: '<',
        type: '<'
    },
    controller: AutoListSingle,
    template : `
        <div ng-repeat="label in $ctrl.list track by $index">
            <ng-form name="chartLabels" class="flex layout-row layout-align-space-between-start">
                <md-input-container class="flex" >
                    <div ng-if="$ctrl.attr.type != 'select'" ng-switch="$ctrl.type">
                        <input type="text" ng-model="$ctrl.list[$index]" ng-switch-when="string" ng-change="$ctrl.change(chartLabels, $index)" >
                        <input type="number" step="1" ng-model="$ctrl.list[$index]" ng-switch-when="int" ng-change="$ctrl.change(chartLabels, $index)" >
                        <input type="number" step="1" min="0" ng-model="$ctrl.list[$index]" ng-switch-when="uint" ng-change="$ctrl.change(chartLabels, $index)" >
                        <input type="number" step="any" ng-switch-when="float"  ng-model="$ctrl.list[$index]" ng-change="$ctrl.change(chartLabels, $index)" >
                        <input  ng-switch-default=""
                                type="{{ $ctrl.attr.type || 'text' }}" 
                                ng-model="$ctrl.list[$index]" 
                                name="{{nameField = 'l' + $index}}" 
                                ng-change="$ctrl.change(chartLabels, $index)" 
                                ng-pattern="$ctrl.pattern.pattern" 
                                ng-required="$ctrl.canDelete($index)"
                                >
                    </div>
                    
                    <md-select ng-if="$ctrl.attr.type == 'select'"
                            ng-model="$ctrl.list[$index]"
                            name="{{nameField = 'l' + $index}}"
                            ng-required="$ctrl.canDelete($index)"
                            >
                            <md-option ng-repeat="v in $ctrl.attr.volumes" ng-value="v" >{{v}}</md-option>
                    </md-select>
                            
                    <div ng-messages="chartLabels[nameField].$error">
                        <div ng-message="pattern">{{$ctrl.pattern.message}}</div>
                    </div>
                </md-input-container>

                <md-button class="md-icon-button" ng-show="$ctrl.canDelete($index)" ng-click="$ctrl.delete($index)"><md-icon md-svg-icon="core:delete" ></md-icon></md-button>
                <div  ng-show="!$ctrl.canDelete($index)"  style="width: 52px"></div>
            </ng-form>
        </div>
    `
};

