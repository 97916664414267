class controller {

    constructor($scope){
        var self = this;
        $scope.$watch(
            function () {
                return self.data.value;
            },
            function (c) {
                self.parent.setYaml();
            }
        );
    }
}

export const ChartWizardText = {
    bindings: {
        parent: "<",
        data: "="
    },
    controller : controller,
    template: `
            <div layout="row" layout-align="start center" flex>
                <md-input-container flex>
                    <input type="text" ng-model="$ctrl.data.value" />
                </md-input-container>
            </div>
    `
};

