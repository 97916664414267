
class controller {
    constructor ($state) {
    }

    $onInit () {
        this.images = [];
        this.init();
    }

    $onDestroy() {
        if ( this.timeInterval ) clearTimeout(this.timeInterval);
    }

    init() {
        if ( this.timeInterval ) clearTimeout(this.timeInterval);

        if (_.isArray(this.data.InferenceStreams)) {
            this.images = _.map( this.data.InferenceStreams, item => this.getUrl(item.key, item))
        }


        this.timeInterval = _.delay( () => {this.init();}, 60000 );
    }

    getUrl(key, item) {
        return { key: key, link: location.origin + '/api/v0.2/workspace/' + this.data.WorkspaceName + '/serving/' + this.data.Name + '/stream/' + key + '/mjpeg', stream_url: item.stream_url }
    }

    urlToBuf (url) {
        window.navigator.clipboard.writeText(url)
            .then(() => {
                // Получилось!
            })
            .catch(err => {
                // console.log('Something went wrong', err);
            });
    }
}


export const ServingStreamsComponent = {
    bindings: {
        data: '<'
    },
    controller : controller,
    template: `

        <md-tabs md-dynamic-height="{{true}}" md-border-bottom>
            <md-tab  label="{{img.key}}" ng-repeat="img in $ctrl.images">
                <div flex layout="row" layout-align="start center">
                    <div>output:</div>
                    <div flex style="margin: 0 1rem;     word-wrap: break-word;"> {{img.link}}</div>
                    <md-button ng-click="$ctrl.urlToBuf(img.link)">copy</md-button>
                </div>
                <div ng-if="img.stream_url" flex layout="row" layout-align="start center">
                    <div>input:</div>
                    <div flex style="margin: 0 1rem;     word-wrap: break-word;"> {{img.stream_url}}</div>
                    <md-button ng-click="$ctrl.urlToBuf(img.stream_url)">copy</md-button>
                </div>
                <img src="{{img.link}}" style="width: 640px"/>
            </md-tab>
        </md-tabs>
    `
};
