export function ClickMe  ($timeout, $parse) {
    return {
        restrict: 'AC',
        link: function(_scope, _element) {
            $timeout(() => {
                _element[0].click();
            }, 0);
        }
    };
}
