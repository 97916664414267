import {ProjectClusterBaseController} from '../project_cluster_base_controller'



class ProjectClusterController extends ProjectClusterBaseController{
    constructor ($state, $scope, WorkspaceService, ProjectClusterService, $mdDialog, $translate, AuthService,WorkspacePermissions) {
        super($state, $scope, WorkspaceService, ProjectClusterService, $mdDialog, $translate, WorkspacePermissions);

        this.AuthService = AuthService;

        this.editorOptions = {
            // mode : 'tree',
            expanded: true
        };
        this.$state = $state;
        this.yaml = '';
        var self = this;
        $scope.aceLoaded =  function (_editor) {
            // Options
            // debugger;
            if(!self.permissions.can('project.manage')) _editor.setReadOnly(true);
        }
    }


    uploadFile (form) {
        var self = this;
        var formData = new FormData();
        angular.forEach(this.files,function(obj){
            if(!obj.isRemote){
                formData.append('src[]', obj.lfFile);
            }
        });

        this.clusterResource.upload({clusterName : this.data.Name}, {src: this.file[0].lfFile})
            .$promise
            .then(
                function (r) {
                    // debugger;
                    self.getFullInfo(true);
                },
                function (err) {
                    debugger;
                }
            );
    }

    getYaml() {
        var self = this;
        if(this.watchYaml) this.watchYaml();
        this.yamlError = false;
        this.clusterResource.get_yaml({clusterName: this.data.Name})
            .$promise
            .then(
                function (r) {
                    self.yaml = r.yaml;
                    self.bYaml = _.clone(r.yaml);
                    self.watchData = self.$scope.$watch(
                        function () {
                            return self.yaml;
                        },
                        function (c) {
                            if(!_.isEqual(self.bYaml, self.yaml)){
                                self.yamlModefy = true;
                            }
                        }
                    );
                },
                function (err) {
                    self.yamlError = err;
                }
            )
    }

    updateYaml() {
        var self = this;
        this.clusterResource.update_yaml({clusterName: this.data.Name}, this.yaml)
            .$promise
            .then(
                function (r) {
                    self.bYaml = _.clone(r.yaml);
                    self.yamlModefy = false;
                },
                function (err) {
                    self.$scope.$emit('api_error', err);
                }
            )
    }

    getFullInfo(update){
        this.getYaml();
        if(!this.dataFull) {
            update = true;
        }
        if(update){
            this.isLoading = true;
            delete this.loadingError;
            if(this.watchData) this.watchData();
            this.dataModefy = false;

            var self = this;
            this.clusterResource.get({clusterName: this.data.Name})
                .$promise
                .then(
                    function (r) {
                        self.dataFull = r;
                        self.bData = _.clone(r.Data);
                        self.watchData = self.$scope.$watch(
                            function () {
                                return self.dataFull.Data;
                            },
                            function (c) {
                                if(!_.isEqual(self.bData, self.dataFull.Data)){
                                    self.dataModefy = true;
                                }
                            }
                        );

                    },
                    function (err) {
                        self.loadingError = err;
                    }
                )
                .finally(
                    function () {
                        delete self.isLoading;
                    }
                )
        }
        return this.dataFull;
    }

    updateData() {
        var self = this;
        this.isLoading = true;
        this.loadingError = false;
        this.clusterResource.update_json({clusterName: this.data.Name}, this.dataFull)
            .$promise
            .then(
                function (r) {
                    self.getFullInfo(true);
                },
                function (err) {
                    self.loadingError = err;
                }
            )
            .finally(function () {
                self.isLoading = false;
            })
    }

}


export const ProjectClusterComponent = {
    bindings:{
        data: '<',
        listController: '='
    },
    controller : ProjectClusterController,
    template :`
            <div ng-include=" 'kuberlab/projects/card/project_card_title_list.html' "></div>
            
            <div ng-if="$ctrl.isActive" class="fullInfo paddingListItemTabContent"> 
                <md-card >
                    <md-card-actions layout="row" layout-align="end center" style="padding: 8px 16px;" ng-if="$ctrl.permissions.can('project.manage')">
                        <md-input-container class="layout-row layout-align-start-center">
                            <md-checkbox ng-model="$ctrl.data.AutoDeploy" ng-change="$ctrl.actionRequest('update', $ctrl.data)" style="margin-bottom: 0px;">{{"CLUSTER_AUTODEPLOY"|translate}}</md-checkbox>
                            <help-hint hint="cluster.autodeploy" style="top:0;"></help-hint>
                        </md-input-container>
                        <div class="flex">
                            <md-progress-linear ng-if="$ctrl.isAction" md-mode="indeterminate" flex></md-progress-linear>
                        </div>
                        <!--<div ng-if="$ctrl.actionError" flex>{{$ctrl.actionError}}</div>-->
                        <md-button ng-click="$ctrl.action('start', $event)" ng-disabled="$ctrl.isAction"><md-icon md-svg-icon="core:play"></md-icon> {{"COMMON_START"|translate}}</md-button> 
                        <md-button ng-click="$ctrl.action('stop', $event)" ng-disabled="$ctrl.isAction"><md-icon md-svg-icon="core:stop"></md-icon> {{"COMMON_STOP"|translate}}</md-button>
                        <md-button ng-click="$ctrl.action('delete', $event)" ng-disabled="$ctrl.isAction"><md-icon md-svg-icon="core:delete"></md-icon> {{"COMMON_DELETE"|translate}}</md-button>
                    </md-card-actions>
                    <md-card-content flex>
                    
                        <md-progress-linear ng-if="$ctrl.isLoading" md-model="indeterminate" flex></md-progress-linear>
                        <cmp-api-error-service ng-if="$ctrl.loadingError" error="$ctrl.loadingError" format="'text"></cmp-api-error-service>
                        <div ng-if="$ctrl.dataFull && (!$ctrl.dataFull.Data || $ctrl.dataFull.Data == '')"  class="errorInfo">{{"CLUSTER_CONFIG_EMPTY"|translate}}</div>
                        <cmp-api-error-service ng-if="$ctrl.yamlError" error="$ctrl.yamlError"></cmp-api-error-service>
                        <div ui-ace="{ mode: 'yaml', onLoad: aceLoaded}" ng-model="$ctrl.yaml" ></div>
                        <div ng-if="$ctrl.yamlModefy && $ctrl.permissions.can('project.manage')" class="layout-row layout-align-start-center">
                            <div class="flex"></div>
                            <md-button ng-click="$ctrl.updateYaml()">{{"COMMON_SAVE"|translate}}</md-button>
                            <md-button ng-click="$ctrl.getYaml()">{{"COMMON_CANCEL"|translate}}</md-button>
                        </div>

                        <!--<jsoneditor ng-jsoneditor="onLoad" ng-if="$ctrl.dataFull.Data" ng-model="$ctrl.dataFull.Data" options="$ctrl.editorOptions" ></jsoneditor>-->
                        <!--<div ng-if="$ctrl.dataModefy" class="layout-row layout-align-start-center">-->
                            <!--<div class="flex"></div>-->
                            <!--<md-button ng-click="$ctrl.updateData()">{{"COMMON_SAVE"|translate}}</md-button>-->
                            <!--<md-button ng-click="$ctrl.getFullInfo(true)">{{"COMMON_CANCEL"|translate}}</md-button>-->
                        <!--</div>-->

                        <cmp-api-error-service ng-if="$ctrl.loadingError" error="$ctrl.loadingError" format="'dialog'"></cmp-api-error-service>
                        <form ng-if="!$ctrl.dataModefy && !$ctrl.yamlModefy  && $ctrl.permissions.can('project.manage')" name="uploadYaml" ng-submit="$ctrl.uploadFile()" layout="row" layout-align="center center">
                            <div style="padding-right: 20px">{{"COMMON_SRC"|translate}}: {{$ctrl.data.Src}}</div>
                            <lf-ng-md-file-input lf-files="$ctrl.file" flex></lf-ng-md-file-input>
                            <md-button type="submite">{{"COMMON_UPLOAD"|translate}}</md-button>
                        </form>
                    </md-card-content>
                                
                </md-card>
            </div>
            `
};
