    class controller {
    constructor(ApplicationsService, ClusterService, $scope){
        this.imgList = ApplicationsService.images;
        this.ClusterService = ClusterService;
        this.$scope = $scope;
        this.allParams = {};
        this.allocatednodes = [];
    }

    $onInit() {

        if(!this.data.autoscale) {
            this.data.autoscale = {};
        }

        _.defaults(
            this.data.autoscale,
            {
                "enabled": false,
                "max_replicas": 0,
                "min_replicas": 0,
                "target_average_utilization": 0
            }
        );
        // debugger

        if(this.serving) {
            this.$scope.$watch(
                () => { return this.serving.ClusterID; },
                (c) => { this.getAllocatednodes(this.serving.WorkspaceName, this.serving.ClusterID); }
            );
        }
        if(this.application) {
            this.$scope.$watch(
                () => { return this.application.ClusterID; },
                (c) => { this.getAllocatednodes(this.application.WorkspaceName, this.application.ClusterID); }
            )
        }
    }

    getAllocatednodes (wsId, clusterId, clear) {
        var params = {
            wsId: wsId,
            clusaterId: clusterId
        };

        if ( !_.isEqual(this.allParams, params) ) {
            clear = true;
        }

        if(clear && wsId && clusterId) {
            this.allParams = params;
            this.ClusterService.getResource(wsId).allocatednodes({ClusterID: clusterId}).$promise
                .then(
                    (r) => {
                        this.allocatednodes = r;

                        // if(!this.data.nodes || this.allocatednodes.indexOf(this.data.nodes) == -1){
                        //     this.data.nodes = this.allocatednodes[0];
                        // }
                    },
                    (err) => {
                        this.allocatednodes = [];
                        this.data.nodes= undefined;
                    }
                )
        }
    }
}

export const ServingFormConfigComponent = {
    bindings: {
        data: "=",
        serving: "=",
        application:"<",
        isModel: '<',
        workspaceName: '<',
        modelSpec: '=',
        clusterId: '<',
        wsId: '<'
    },
    controller: controller,
    template:  ($templateCache) => {
        return $templateCache.get('kuberlab/serving/form/config.html');
    }
}
