import {ProjectClustersBaseController} from '../project_clusters_base_controller'

class controller extends ProjectClustersBaseController  {
    constructor($scope, $state, ProjectClusterService, WorkspaceService, AuthService, WorkspacePermissions, autoUpdate) {
        super(...arguments);
    }
}

export const ProjectsStoragesComponent = {
    template: `
        <md-progress-linear ng-if="$ctrl.service.isLoading" md-mode="indeterminate" flex></md-progress-linear>
        <md-list>
            <div ng-include=" 'kuberlab/projects/card/project_head_title_list.html' "></div>
            <cmp-project-cluster-storage ng-repeat="item in $ctrl.list" data="item" id="tabItem_{{item.Name}}"></cmp-project-cluster-storage>
        </md-list>
        <div layout="row" class="md-padding" ng-if="!$ctrl.service.isLoading && (!$ctrl.list || !$ctrl.list.length)">
            <span translate>PROJECT_NO_CLUSTERS_RESOURCES</span>
        </div>
    `,
    controller: controller
};