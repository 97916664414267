import StateConfig from './catalog_ws_stateconfig'

export default angular
    .module('catalog-ws.kuberlab', [])
    .config(StateConfig)
    .constant('catalogPageTabs',[
            {
                title : 'versions',
                content : '<cmp-catalog-data-item-version resource="$ctrl.resource" data="$ctrl.data" can-manage="$ctrl.canManage" service="$ctrl.service"></cmp-catalog-data-item-version>'
            },
            {
                title : 'forks',
                content : '<cmp-catalog-forks resource="$ctrl.resource" data="$ctrl.data" can-manage="$ctrl.canManage" service="$ctrl.service"></cmp-catalog-forks>'
            },
            {
                title : 'readme',
                content : '<cmp-catalog-item-readme read-only="!$ctrl.canManage" readme-url="$ctrl.readmeUrl"></cmp-catalog-item-readme>'
            },
            {
                title : 'comments',
                content : '<cmp-catalog-item-comments resource="$ctrl.resource" data="$ctrl.data" ></cmp-catalog-item-comments>'
            }
        ]
    )
    .constant('catalogPageMlModelTabs',[
            {
                title : 'versions',
                content : '<cmp-mlmodel-item-versions resource="$ctrl.resource" data="$ctrl.data" can-manage="$ctrl.canManage" service="$ctrl.service"></cmp-mlmodel-item-versions>'
            },
            {
                title : 'forks',
                content : '<cmp-catalog-forks resource="$ctrl.resource" data="$ctrl.data" can-manage="$ctrl.canManage" service="$ctrl.service"></cmp-catalog-forks>'
            },
            {
                title : 'readme',
                content : '<cmp-catalog-item-readme read-only="!$ctrl.canManage" readme-url="$ctrl.readmeUrl"></cmp-catalog-item-readme>'
            },
            {
                title : 'comments',
                content : '<cmp-catalog-item-comments resource="$ctrl.resource" data="$ctrl.data" ></cmp-catalog-item-comments>'
            }
        ]
    )
    .constant('catalogPageInferenceTabs',[
            {
                title : 'versions',
                content : '<cmp-inference-item-versions resource="$ctrl.resource" data="$ctrl.data" can-manage="$ctrl.canManage" service="$ctrl.service"></cmp-inference-item-versions>'
            },
            {
                title : 'readme',
                content : '<cmp-catalog-item-readme read-only="!$ctrl.canManage" readme-url="$ctrl.readmeUrl"></cmp-catalog-item-readme>'
            },
            {
                title : 'comments',
                content : '<cmp-catalog-item-comments resource="$ctrl.resource" data="$ctrl.data" ></cmp-catalog-item-comments>'
            }
        ]
    )
    .constant('catalogPageChartTabs',[
            {
                title : 'versions',
                content : '<cmp-catalog-chart-item-version resource="$ctrl.resource" data="$ctrl.data" can-manage="$ctrl.canManage" service="$ctrl.service"></cmp-catalog-chart-item-version>'
            },
            {
                title : 'readme',
                content : '<cmp-catalog-item-readme read-only="true" readme-url="$ctrl.readmeUrl"></cmp-catalog-item-readme>'
            },
            {
                title : 'comments',
                content : '<cmp-catalog-item-comments resource="$ctrl.resource" data="$ctrl.data" ></cmp-catalog-item-comments>'
            }
        ]
    )
