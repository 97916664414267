class controller {
    constructor(ApplicationsService, ClusterService, $scope){
        this.ApplicationsService = ApplicationsService;
        this.imgList = ApplicationsService.images;
        this.ClusterService = ClusterService;
        this.$scope = $scope;
        this.allParams = {};
        this.allocatednodes = [];
    }

    $onInit() {
        if (!this.application){
            this.getApp();
        }
    }

    getApp() {

        this.ApplicationsService.getResource({wsId: this.data.WorkspaceName}).get({application: this.data.ApplicationName})
            .$promise
            .then(
                (r) => {
                    this.application = r;
                    var source = this.application.Configuration ? _.findWhere(this.application.Configuration.spec.volumes, {name: 'data'}) : null;
                    if (!this.data.ApplicationSource) {
                        this.data.ApplicationSource = source ? "data" : this.application.Configuration.spec.volumes[0].name;
                    }
                },
                (err) => {

                }
            )
    }

    setValues() {

    }
}

export const ServingFormProjectConfigComponent = {
    bindings: {
        data: "=",
        serving: "=",
        application:"<",
        isModel: '<',
        workspaceName: '<',
        modelSpec: '=',
        clusterId: '<',
        wsId: '<',
        appId: '<'
    },
    controller: controller,
    template:  ($templateCache) => {
        return $templateCache.get('kuberlab/serving/form/project/config.html');
    }
}
