
import {CoreServiceResource} from '../../core/service/core_service_resource'

export class UserTokenService extends CoreServiceResource {
    constructor($resource, $q, KuberlabConfigApi, $rootScope) {
        super(...arguments);
    }

    getResource(params = {}, clear) {
        if(!this._resource || clear){
            var place = params.org ? `/org/${params.org}/teams/${params.team}`  : '/cu';
            var url = this.cfg.APIPath + place +  '/token/:token/:action';
            this._resource = this.$resource(url,
                {token: '@ID'},
                _.extend({}, this.cfg.ResourceParams,
                    {
                        disable: {
                            method: "PUT",
                            params: {action: 'disable'}
                        },
                        enable: {
                            method: "PUT",
                            params: {action: 'enable'}
                        }
                    }));
        }
        return this._resource
    }
}
