import {ControllerItem}  from '../core/controller/item'
import {MlmodelServingController} from '../mlmodel/serving/controller'

export class ServingItemController extends ControllerItem{
    constructor (WorkspacePermissions, ServingService, $scope, $state, $mdDialog, $templateCache) {
        super();
        this.$mdDialog = $mdDialog;
        this.$templateCache = $templateCache;
        this.$state = $state;
        this.$scope = $scope;
        this.permissions = WorkspacePermissions;
        this.ServingService = ServingService;

        var inject = angular.element(document.body).injector();
        var CurrentUser = inject.get('CurrentUser');
        CurrentUser.then((r) => {this.Admin = r.Admin;});

        // if(!this.$state.params.tab) this.$state.go('.tab');
        // this.watchWS('serving', this.ws_serving);
    }

    $onInit() {
        this.resource = this.ServingService.getResource(this.$state.params);
        if(!this.data) {
            this.getItem();
        }


    }

    getItem() {
        this.ServingService.getItem( this.$state.params.servingName )
            .then(
                (r) => {

                    this.data = r;
                },
                (err) => {

                });
    }

    successDelete (r) {
        if(this.listController) super.successDelete(r);
        this.ServingService.remove(this.data);
        this.$state.go('wsServingList');
    }

    action(action){
        var self = this;
        this.apiError = false;
        this.resource[action]({wsId: this.$state.wsId, Name: this.data.Name})
            .$promise
            .then(
                (resp) => {
                    switch(action){
                        // case  'reset' :
                        //     this.$state.go(this.$state.current, {}, {reload: true});
                        //     break;
                        // case 'enable':
                        //     self.data.DisableReason = null;
                        // case 'disable':
                        //     self.data.Enabled = (action == 'enable');
                        //     break;
                    }
                },
                (err) => {
                    self.ErrorService.error(err);
                    // self.$scope.$emit('api_error', err);
                }
            );
    }

    ws_serving(ev, data) {
        // if(this.data.Name == data.Name) {
        if(this.data.WorkspaceName == data.WorkspaceName && this.data.Name == data.Name) {
            // debugger
            if(data.Health != this.data.Health || data.ContainersCount != this.data.ContainersCount) {this.$scope.$broadcast('servingChangeStatus')}
            // if(data.ContainersCount != this.data.ContainersCount) {this.$scope.$broadcast('applicationChangeContainersCount')}
            this.data = _.extend(this.data, data);
            this.$scope.$apply();
        }
    }

    editDialog(ev) {
        var self = this;
        this.$mdDialog.show({
            locals: {
                data: angular.copy(this.data)
            },
            controllerAs: '$ctrl',
            controller: function(data, $scope,  $mdDialog, InferenceService){
                this.data  = data;
                if(this.data.Type == 'inference'){
                    InferenceService.getResource({wsId: data.InferenceWorkspaceName}).version_get({name: data.InferenceName, version: data.InferenceVersion})
                        .$promise
                        .then(
                            (r) => {
                                this.vData = r;
                            },
                            (err) => {
                                this.vError = err;
                            }
                        )
                }
                $scope.cancel = () => {
                    $mdDialog.hide();
                };
                $scope.save = () => {
                  data.$update().then(
                      (r)=>{
                          $mdDialog.hide(r);
                      },
                      (err)=>{
                          this.error = err;
                      }
                  )
                }
            },
            template: this.$templateCache.get('kuberlab/serving/form/edit.html'),
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose:false,
        })
            .then(
                (params) => {
                    if(params)
                        this.data = params;
                },
                () => {}
            );
    }

    toGallery () {
        if(this.data.Gallery) {
            this.toGalleryRequest();
        } else {
            this.toGlobalDialog( true );
        }
    }

    toGalleryRequest () {
        var action = this.data.Gallery ? 'fromGallery' : 'toGallery';
        this.resource[action]({Name : this.data.Name}).$promise
            .then(
                (r) => { this.data = r},
                (err) => {}
            )
    }

    toGlobalDialog(publish) {
        var self = this;
        this.$mdDialog.show({
            locals: {
                publish: publish,
                data: angular.copy(this.data),
                resource : this.resource
            },
            controllerAs: '$ctrl',
            controller: function(data, $scope, resource, publish, $mdDialog){
                this.data  = data;
                if(!this.data.DisplayName) this.data.DisplayName = this.data.Name;

                $scope.$watch('pic', (c) => {
                    if(c) {
                        resource.addPicture({Name : this.data.Name}, {picture: c})
                            .$promise
                            .then(
                                (r) => {
                                    this.data.Picture = r.Picture;
                                },
                                (err) => {
                                    this.error = err;
                                }
                            );
                    }
                });
                $scope.cancel = () => {
                    $mdDialog.hide();
                };
                $scope.save = () => {
                    data.$update().then(
                        (r)=>{
                            $mdDialog.hide(r);
                        },
                        (err)=>{
                            this.error = err;
                        }
                    )
                }
            },
            template: this.$templateCache.get('kuberlab/serving/card/gallery.html'),
            parent: angular.element(document.body),
            clickOutsideToClose:false,
        })
            .then(
                (params) => {
                    if(params) {
                        this.data = params;
                        if(publish) this.toGalleryRequest();
                    }
                },
                () => {}
            );
    }
}

export const ServingListItemComponent = {
    bindings: {
        data: '=',
        listController: '<'
    },
    controller: ServingItemController,
    template: ($templateCache) => { return $templateCache.get('kuberlab/serving/card/serving_list_item.html'); }
};
