class Controller  {
    constructor() {
        this.types = ['DirectoryOrCreate', 'Directory', 'FileOrCreat', 'File', 'Socket', 'CharDevice', 'BlockDevice'];
    }
}


export const StorageHostComponent = {
    bindings: {
        data : "="
    },
    controller: Controller,
    template: `
        <md-input-container flex>
            <label>Path</label>
            <input type="text" ng-model="$ctrl.data.path"/> 
        </md-input-container>
        <md-input-container flex>
            <label>Type</label>
            <md-select ng-model="$ctrl.data.type">
                <md-option ng-repeat="item in $ctrl.types" ng-value="item">{{item}}</md-option>
            </md-select>
        </md-input-container>
`
};