import {ServingItemController}  from './serving_item_controller'

export class ServingItemFullController extends ServingItemController{
    constructor (WorkspacePermissions, ServingService, $scope, $state, $mdDialog, $templateCache, pageTabs, workspace) {
        super(...arguments);
        this.WS = workspace;
        this.watchWS('serving', this.ws_serving );
        this.watchWS('servingDelete', this.ws_servingDelete );
        $scope.$watch(
            () => { return this.data},
            (c) => {


                if ( c.Type == 'inference' ) {
                    this.pageTabs = [
                        {
                            title : 'parameters',
                            content : '<cmp-serving-inference data="$ctrl.data"></cmp-serving-inference>'
                        },
                        {
                            title : 'streams',
                            content : '<cmp-serving-streams data="$ctrl.data"></cmp-serving-streams>'
                        },
                        {
                            title:'readme',
                            content:'<cmp-serving-readme read-only="!$ctrl.canManage" readme-url="$ctrl.readmeUrl" ></cmp-serving-readme>'
                        },
                        {
                            title : 'log',
                            content : '<cmp-serving-logs></cmp-serving-logs>'
                        }
                    ];
                    if ( this.data.ClusterID !== 'edge' ) {
                        // debugger
                        this.pageTabs = this.pageTabs.concat([
                            // {
                            //     title : 'log',
                            //     content : '<cmp-serving-logs></cmp-serving-logs>'
                            // },
                            {
                                title:'status',
                                content:'<cmp-serving-status data="$ctrl.data" ></cmp-serving-status>'
                            },
                            {
                                title:'metrics',
                                content:'<cmp-serving-metrics data="$ctrl.data" ></cmp-serving-metrics>'
                            }
                        ])
                    }
                } else if ( c.Type == 'project' ) {
                    this.pageTabs = [
                        {
                            title : 'parameters',
                            content : '<cmp-serving-parameters data="$ctrl.data"></cmp-serving-parameters>'
                        },
                        // {
                        //     title : 'log',
                        //     content : '<cmp-serving-logs></cmp-serving-logs>'
                        // },
                        {
                            title:'readme',
                            content:'<cmp-serving-readme read-only="!$ctrl.canManage" readme-url="$ctrl.readmeUrl" ></cmp-serving-readme>'
                        },
                        // {
                        //     title:'status',
                        //     content:'<cmp-serving-status data="$ctrl.data" ></cmp-serving-status>'
                        // },
                        // {
                        //     title:'metrics',
                        //     content:'<cmp-serving-metrics data="$ctrl.data" ></cmp-serving-metrics>'
                        // }
                    ];
                }
                else {
                    this.pageTabs = [
                        {
                            title : 'parameters',
                            content : '<cmp-serving-parameters data="$ctrl.data"></cmp-serving-parameters>'
                        },
                        {
                            title : 'log',
                            content : '<cmp-serving-logs></cmp-serving-logs>'
                        },
                        {
                            title:'readme',
                            content:'<cmp-serving-readme read-only="!$ctrl.canManage" readme-url="$ctrl.readmeUrl" ></cmp-serving-readme>'
                        },
                        {
                            title:'status',
                            content:'<cmp-serving-status data="$ctrl.data" ></cmp-serving-status>'
                        },
                        {
                            title:'metrics',
                            content:'<cmp-serving-metrics data="$ctrl.data" ></cmp-serving-metrics>'
                        }
                    ];

                }
            }
        );

        this.canManage = WorkspacePermissions.can('mlapp.manage');
    }

    $onInit () {
        super.$onInit();
        this.readmeUrl = '/workspace/' + this.$state.params.wsId + '/serving/' + this.$state.params.servingName + '/readme';
    }

    // ws_serving(ev, data) {
    //     if(this.data.WorkspaceName == data.WorkspaceName && this.data.Name == data.Name) {
    //         this.data = _.extend(this.data, data);
    //         this.$scope.$apply();
    //     }
    // }

    ws_servingDelete(ev, data) {
        if(this.data.WorkspaceName == data.WorkspaceName && this.data.Name == data.Name) {
            this.successDelete()
        }
    }

    $onDestroy() {
        super.$onDestroy();
        ServingService._itemPromise = null;
    }
}
