// this.options = [
//     {labrel: 'Num replicas', name: 'replicas', type: 'int', width: 50},
//     {label: "Use GPU", name: "GPU", type: 'boolean', width:50},
//     {label: "Data source", name: "Data source", type: 'storage', available: ['dataset', 'clusterStorage']}, // или куберлаб каталог или куберлаб сторадж
//     {label: "Max iterations", name: "Maxiterations", type: "int"},
//     {label: 'test',  name: 'test', type: 'group', width: 50, elements: [
//         {name: 'g1', type: 'select', options: [1,2,3]},
//         {name: 'g2', type: 'text'}
//     ]},
//     {name: "List", type: "list", width: 50, types: ['int','int8','int16','int32','int64','float','double','byte','bytes','string','strings','uint8','uint16']},
//     {name: "String", type: "string"},
// ];


class controller {
    constructor(ApplicationsService, $state ){
        this.apiForm;
        this.$state = $state;
        this.data = {};
        this.options = {};
    }

    $onInit() {
        this.getFormValue();
    }

    getFormValue() {
        this.appController.applicationsResource.simply_form_request({application: this.appController.data.Name})
            .$promise
            .then(
                (r) => {
                    this.data = r;

                },
                (err) => {
                    // this.error = err;
                }
            )
            .finally(
                () => {
                    this.getFormConfig();
                }
            )
    }

    send(form) {
        if (!form.$valid) return false;
        this.error = null;
        this.saving = true;
        this.appController.applicationsResource.build_simply({application: this.appController.data.Name}, this.data )
            .$promise
            .then(
                (r) => {
                    this.appController.ApplicationsService._reset('experimants');
                    // this.appController.ApplicationsService.addTask(this.$state.params, r);
                    this.$state.go('wsApplication.tab', {tab : 'jobs'})
                },
                (err) => {
                    this.error = err;
                }
            )
            .finally(
                () => {
                    this.saving = null;
                }
            )
    }

    // edit() {
    //     this.error = null;
    //     this.asEdit = true;
    //     this.template = jsyaml.dump(angular.copy(this.options));
    // }
    //
    // cancelEdit() {
    //     this.asEdit = false;
    // }




    getFormConfig() {
        this.error = null;
        this.options = null;
        this.appController.applicationsResource.simply_form({application: this.appController.data.Name})
            .$promise
            .then(
                (r) => {
                    this.options = r;
                },
                (err) => {
                    this.error = err;
                }
            )
            .finally(
                () => {
                    this.saving = null;

                }
            )
    }

    // saveConfig() {
    //     this.error = null;
    //     this.saving = true;
    //     var options = jsyaml.safeLoad(this.template);
    //     this.appController.applicationsResource.simply_form_save({application: this.appController.data.Name}, options)
    //         .$promise
    //         .then(
    //             (r) => {
    //                 this.options = r;
    //                 if(this.asEdit) this.edit();
    //                 // this.asEdit =false;
    //
    //             },
    //             (err) => {
    //                 this.error = err;
    //             }
    //         )
    //         .finally(
    //             () => {
    //                 this.saving = null;
    //             }
    //         )
    // }
}

export const ApplicationSimplyComponent = {
    controller: controller,
    template: ($templateCache) => { return $templateCache.get('kuberlab/applications/simply/applications_simply_component.html'); },
    bindings: {
        appController: '=',
        // asEdit: '<',
        // data: '=',
        options: '='
    }
}
