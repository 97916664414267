
class controller {
    constructor($scope) {
        this.pc = null;
        this.dc = null;
        this.dcInterval = null;
        this.status = null;
        this.use_datachannel = false;
        this.datachannel_parameters = `{"ordered": true}`;
        this.audio = {
            active: false,
            codec: 'default'
        };
        this.video = {
            active: true,
            resolution: null,
            transform: 'none',
            codec: 'default'
        };
        this.$scope = $scope;
        $scope.$watch(
            () => this.video,
            () => {
                // debugger
                // if( this.status ) {
                //     this.stop();
                //     this.start();
                // }
            },
            true
        );

        this.use_stun = false;
        this.dataChannelLog = "";
        this.iceConnectionLog = "";
        this.iceGatheringLog = "";
        this.signalingLog = "" ;


    }

    $onInit() {
        if(this.autoStart) {
            this.start();
        }
    }

    $onDestroy () {
        this.stop();
    }

    setError(error) {
        this.error = error;
        this.$scope.$apply();
    }

    openFullscreen() {
        let elem = document.getElementById('video');
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { /* Firefox */
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE/Edge */
            elem.msRequestFullscreen();
        }
    }

    setStatus(s) {
        this.status = s;
        console.log(this.status);
        this.onChange({$event : {type: 'status', data: this.status}});
    }

    start() {
        this.error = null;
        window.webrtcClient = this;
        this.setStatus('starting');
        return fetch(this.link + '/webrtc', {
            body: JSON.stringify({
                // sdp: offer.sdp,
                // type: offer.type,
                // serving_params: this.params
                // video_transform: this.video.transform
            }),
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST'
        })
            .then((response) => {
                return response.json();
            })
            .then((answer) => {
                if(answer.Status != 'error'){
                    this._start(answer.ice_servers);
                } else {
                    this.setError({data: answer});
                }
            })
            .catch((e) => {
                this.setError({status: e.toString()});
            });

    }

    _start(iceServers) {

        this.pc = this.createPeerConnection(iceServers);

        var time_start = null;

        var constraints = {
            audio: this.audio.active,
            video: false
        };

        if (this.video.active) {
            // var resolution = document.getElementById('video-resolution').value;
            if (this.video.resolution  && this.video.resolution != "") {
                let resolution = this.video.resolution.split('x');
                constraints.video = {
                    width: parseInt(resolution[0], 0),
                    height: parseInt(resolution[1], 0)
                };
            } else {
                constraints.video = true;
            }
        }

        if (constraints.audio || constraints.video) {
            this.mainStream = navigator.mediaDevices.getUserMedia(constraints)
                .then(
                    (stream) => {
                        this.mainStream = stream;
                        stream.getTracks().forEach((track) => {
                            this.pc.addTrack(track, stream);
                        });
                        return this.negotiate();
                    },
                    (err) => {
                        this.setError({data: {Error: err}});
                    })

            // .then(() => { return this.pc.getStatus();})
            // .then((stats) => {
            //     // debugger
            //     var candidate = stats.get(findSelected(stats).localCandidateId);
            //     if (candidate.candidateType == "relayed") {
            //         consile.log("Uses TURN server: " + candidate.ipAddress, candidate);
            //     } else {
            //         consile.log("Does not use TURN (uses " + candidate.candidateType + ").");
            //     }
            // })
            ;
        } else {
            this.negotiate();
        }
        this.setStatus('connecting');
    }

    createPeerConnection(iceServers) {
        var config = {
            sdpSemantics: 'unified-plan'
        };
        if(iceServers) {

            // config.iceServers = [{urls: ['stun:stun.l.google.com:19302']},
            //     {
            //         urls: ['turn:34.69.218.226:3478'],
            //         'credential': 'D0dgy_BladderF1sh' ,
            //         'username': 'turn'
            //     }
            // ];
            config.iceServers = iceServers;
        }

        this.pc = new RTCPeerConnection(config);

        // register some listeners to help debugging
        this.pc.addEventListener('icegatheringstatechange', () => {
            this.iceGatheringLog += ' -> ' + this.pc.iceGatheringState;
        }, false);
        this.iceGatheringLog = this.pc.iceGatheringState;

        this.pc.addEventListener('iceconnectionstatechange', () => {
            this.iceConnectionLog += ' -> ' + this.pc.iceConnectionState;
        }, false);
        this.iceConnectionLog = this.pc.iceConnectionState;

        this.pc.addEventListener('signalingstatechange', () => {
            this.signalingLog += ' -> ' + this.pc.signalingState;
        }, false);
        this.signalingLog = this.pc.signalingState;

        // connect audio / video
        this.pc.addEventListener('track', (evt) => {
            this.setStatus('track');
            this.$scope.$apply();
            if (evt.track.kind == 'video')
                document.getElementById('video').srcObject = evt.streams[0];
            else{
                // document.getElementById('audio').srcObject = evt.streams[0];
            }
        });

        return this.pc;
    }

    negotiate() {
        return this.pc.createOffer()
            .then((offer) => {
                return this.pc.setLocalDescription(offer);
            })
            .then(() => {
                // wait for ICE gathering to complete
                return new Promise((resolve) => {
                    if ( this.pc.iceGatheringState === 'complete') {
                        resolve();
                    } else {
                        const checkState = () => {
                            if ( this.pc.iceGatheringState === 'complete') {
                                this.pc.removeEventListener('icegatheringstatechange', checkState);
                                resolve();
                            }
                        }
                        this.pc.addEventListener('icegatheringstatechange', checkState);
                    }
                });
            })
            .then(() => {
                var offer = this.pc.localDescription;
                var codec;

                codec = this.audio.codec;
                if ( codec !== 'default') {
                    offer.sdp = this.sdpFilterCodec('audio', codec, offer.sdp);
                }

                codec = this.video.codec;
                if (codec !== 'default') {
                    offer.sdp = this.sdpFilterCodec('video', codec, offer.sdp);
                }
                // document.getElementById('offer-sdp').textContent = offer.sdp;
                this.offer_sdp = offer.sdp;

                // return fetch('/offer', {
                let params =  {};
                _.each(this.params, (i ,k) => {
                    if(i && i.type != 'image_webrtc' && i.type != 'webrtc') {
                        params[k] = i;
                    }
                });

                // this.params ?
                //     _.filter(this.params, (i) => {
                //         return  i && i.type != 'image_webrtc' && i.type != 'webrtc'
                //     }) : {};

                return fetch(this.link + '/offer', {
                    body: JSON.stringify({
                        sdp: offer.sdp,
                        type: offer.type,
                        serving_params: params
                        // video_transform: this.video.transform
                    }),
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    method: 'POST'
                });
            })
            .then((response) => {
                return response.json();
            })
            .then((answer) => {
                // document.getElementById('answer-sdp').textContent = answer.sdp;
                // this.answer_sdp = answer.sdp;
                // return this.pc.setRemoteDescription(answer);
                if(answer.Status != 'error'){
                    this.answer_sdp = answer.sdp;
                    return this.pc.setRemoteDescription(answer);
                } else {
                    this.setError({data: answer});
                }

            })
            .catch((e) => {
                this.setError({status: e.toString()});
            });
    }

    stop() {
        this.error = null;
        if (this.dc) {
            this.dc.close();
        }

        this.mainStream.getTracks().forEach(function (track) {
            track.stop();
        });
        if(this.pc) {
            // close transceivers
            if (this.pc.getTransceivers) {
                this.pc.getTransceivers().forEach((transceiver) => {
                    if (transceiver.stop) {
                        transceiver.stop();
                    }
                });
            }

            // close local audio / video
            this.pc.getSenders().forEach((sender) => {
                sender.track.stop();
            });

            // close peer connection
            setTimeout(() => {
                this.pc.close();
            }, 500);
        }
        this.setStatus(null);
    }

    sdpFilterCodec(kind, codec, realSdp) {
        var allowed = [];
        var rtxRegex = new RegExp('a=fmtp:(\\d+) apt=(\\d+)\r$');
        var codecRegex = new RegExp('a=rtpmap:([0-9]+) ' + this.escapeRegExp(codec));
        var videoRegex = new RegExp('(m=' + kind + ' .*?)( ([0-9]+))*\\s*$');

        var lines = realSdp.split('\n');

        var isKind = false;
        for (var i = 0; i < lines.length; i++) {
            if (lines[i].startsWith('m=' + kind + ' ')) {
                isKind = true;
            } else if (lines[i].startsWith('m=')) {
                isKind = false;
            }

            if (isKind) {
                var match = lines[i].match(codecRegex);
                if (match) {
                    allowed.push(parseInt(match[1]));
                }

                match = lines[i].match(rtxRegex);
                if (match && allowed.includes(parseInt(match[2]))) {
                    allowed.push(parseInt(match[1]));
                }
            }
        }

        var skipRegex = 'a=(fmtp|rtcp-fb|rtpmap):([0-9]+)';
        var sdp = '';

        isKind = false;
        for (var i = 0; i < lines.length; i++) {
            if (lines[i].startsWith('m=' + kind + ' ')) {
                isKind = true;
            } else if (lines[i].startsWith('m=')) {
                isKind = false;
            }

            if (isKind) {
                var skipMatch = lines[i].match(skipRegex);
                if (skipMatch && !allowed.includes(parseInt(skipMatch[2]))) {
                    continue;
                } else if (lines[i].match(videoRegex)) {
                    sdp += lines[i].replace(videoRegex, '$1 ' + allowed.join(' ')) + '\n';
                } else {
                    sdp += lines[i] + '\n';
                }
            } else {
                sdp += lines[i] + '\n';
            }
        }

        return sdp;
    }

    escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }


}


export const WebrtcStream = {
    bindings: {
        link: '<',
        params: '<',
        autoStart: '<',
        onChange: "&"
    },
    controller: controller,
    template: ($templateCache) => { return $templateCache.get('kuberlab/core/webrtc_stream/tpl.html'); }
};
