class controller {
    constructor($scope) {
        this.open = true;
        this.$scope = $scope;
    }

    $onInit() {
        if(this.pod.Name == "master") { this.open = false; }
        this.$scope.$watch(
            () => { return this.pod.Log;},
            (c) => {
                this.Log = {output: this.pod.Log};
            }
        )
    }

    getLog(){

        this.uploadError = false;
        this.uploadLog = true;
        this.uploadLogError = false;

        var self = this;
        this.resource.task_pod_log({
                application: this.application.Name,
                task: this.job.task_name,
                build: this.job.build,
                pod: this.pod.Name,
                search: this.grep
            })
            .$promise
            .then(
                function (r) {
                    self.pod.truncated = false;
                    self.Log = r;
                    var pre = $(self.$element).find('.log-view');
                    _.delay(function(){
                        if(pre && pre.length){
                            _.delay(function(){
                                pre.scrollTop( pre[0].scrollHeight );
                            }, 500);
                        }

                    }, 500);
                },
                function (err) {
                    self.uploadError = err;
                }
            )
            .finally(function () {
                self.uploadLog = false;
            })
    }

    toogle() {
        this.open = !this.open;
    }
}

export const ApplicationsLogsJobPodComponent={
    bindings: {
        pod: '<',
        resource: '<',
        application: '<',
        job: '<',
        grep: '<'
    },
    controller : controller,
    template: function ($templateCache) {
        return $templateCache.get('kuberlab/applications/logs/applications_logs_job_pod_component.html');
    }
}
