import {CoreServiceResource} from '../core/service/core_service_resource'

export class TemplateService  extends CoreServiceResource {
    constructor($resource, $q, KuberlabConfigApi, $rootScope, storageList) {
        super(...arguments);
        this.params = {};
        this.storageTypeList = storageList;
        this.params = {};
        this.c = 0;
    }

    count(){
        this.c = this.c + 1;
        return this.c;
    }

    // GET /api/v0.2/templates/{type} - список по типу (ща доступны только 2 типа - task и serving)
    // DELETE /api/v0.2/templates/{type}/{template} - похерить темплейт
    // GET /api/v0.2/templates/{type}/{template} - вернуть описание темплейта (но не сам темплейт)
    // POST /api/v0.2/templates/{type}/{template} - добавить или заапдейтить описание темплейта
    // GET /api/v0.2/templates/{type}/{template}/template - сам темплейт
    // PUT /api/v0.2/templates/{type}/{template}/template - апдейтить темплейт

    getResource(params, clear) {
        if( this.params.type != params.type || !this._resource || clear ) {
            this.params.type = params.type;
            var url = this.cfg.APIPath + '/templates/' + params.type;
            this._resource = this.$resource(
                url,
                {},
                _.extend(
                    {},
                    this.cfg.ResourceParams,
                    {
                        'templateInfo': {
                            method: 'GET',
                            url: url  + '/:template',
                            params: {
                                template: "@template"
                            }
                        },

                        'template': {
                            method: 'GET',
                            url: url  + '/:template/template',
                            params: {
                                template: "@template"
                            },
                            transformResponse: function(data, headersGetter, status) {
                                if(status == 200)
                                    return {template: data};
                                else
                                    return angular.fromJson(data);
                            }
                        }
                    }
                )
            );
        }
        return this._resource;
    }
}