// class errorModel {
//     data = {};
//
// }


export class ErrorControllerBase {
    constructor ($translate, $mdDialog,  error, $scope) {
        this.$translate = $translate;
        this.ConfirmDelete = ConfirmDelete;
        this.FroceDelete = ForceDelete;

        this.RegistryData = RegistryData;
        this.confirmAction = confirmAction;
        this.more = false;
    }

    $onInit() {
        // debugger
        // this.errorType = ( this.error.data.RegistryData && this.error.data.RegistryData.DeleteAction )
        //     ? this.error.data.RegistryData.DeleteAction
        //     : this.error.data.ErrorType
        //         ?  this.error.data.ErrorType
        //         : 'def';

        if(this.error.data) {

            this.errorType = ( this.error.data.RegistryData && this.error.data.RegistryData.DeleteAction )
                ? this.error.data.RegistryData.DeleteAction
                : this.error.data.ErrorType
                    ?  this.error.data.ErrorType
                    : 'def';

            if(this.error.data.RegistryData && this.error.data.RegistryData.DeleteAction == 'force') {

                this.force = true;
                this.confirm = this.error.data.RegistryData.ConfirmString ? this.error.data.RegistryData.ConfirmString : null;

            }

            var t = this.$translate.getTranslationTable();
            if(_.isObject(this.error.data)){

                var errorType = this.error.data.ErrorType || this.error.ErrorType ;
                var errorGroup = this.error.data.ErrorGroup || this.error.ErrorGroup ;
                var error = this.error.data.Error || this.error.Error;
                var statusCode = this.error.data.StatusCode || this.error.status;

                var text1 = 'ERROR_TEXT_' + errorType + '_' + errorGroup;
                var text2 = 'ERROR_TEXT_' + errorType;

                if(t[text1]){
                    this.text = t[text1];
                } else if(t[text2]) {
                    this.text = t[text2];
                } else if(error){
                    this.text = error;
                } else {
                    this.text = errorGroup + " : " +  errorType;
                }

                this.title = t['ERROR_'  + errorType] || 'ERROR ' +  statusCode;

                if(this.title.indexOf('{{arguments}}') || this.text.indexOf('{{arguments}}') ) {
                    var tplStr = (this.error.data.ErrorArguments && this.error.data.ErrorArguments.length) ? this.error.data.ErrorArguments.join(', ') : '';
                    this.title = this.title.replace('{{arguments}}', tplStr);
                    this.text = this.text.replace('{{arguments}}', tplStr);
                }

            } else {
                var titleName = 'ERROR_' + this.error.status;
                this.title = t[titleName] || 'Error ' + this.error.status;
                var text = this.error.data ?  this.error.statusText :  'Error: ' + this.error.data;
                this.text = t[text] || text;

            }

            this.moreText = this.moreInfo();
        }
        if(!this.text) { this.text = `Error status ${this.error.status}`; }
    }

    moreInfo() {
        if(this.error.data.Reason) return this.error.data.Reason;
        var ErrorArguments = this.error.data.ErrorArguments ? this.error.data.ErrorArguments.join(',') : undefined;
        var text = '';
        if(this.error.data.ErrorGroup) text = text + "<li >ErrorGroup - " + this.error.data.ErrorGroup + "</li>";
        if(this.error.data.ErrorType) text = text + "<li >ErrorType - " + this.error.data.ErrorType + "</li>";
        if(ErrorArguments) text = text + "<li >ErrorArguments - " + ErrorArguments + "</li>";
        if(this.error.data.External) text = text + "<li >External - " + this.error.data.External + "</li>";
        if(this.error.data.ExternalError) text = text + "<li >ExternalError - " + this.error.data.ExternalError + "</li>";
        if(this.error.data.ExceptedFormat) text = text + "<li >ExceptedFormat - " + this.error.data.ExceptedFormat + "</li>";
        return text.length ? '<ul>' + text + '</ul>' : '';
    }
};


export class ErrorController extends ErrorControllerBase {
    constructor ($translate) {
        super($translate);
    }
}

export const ErrorPageComponent = {
    bindings: {
        error: "<",
        compact: '@'
    },
    controller: ErrorController,
    template: `
        <md-card class="errorInfo" ng-class="{'compact': $ctrl.compact}">
          <md-card-title>
            <md-card-title-media ng-hide="$ctrl.compact">
              <div class="md-media-sm card-media" layout>
                <md-icon md-svg-icon="core:alert"></md-icon>
              </div>
            </md-card-title-media>
            <md-card-title-text class="flex" style="min-width: 0">
                <div class="md-headline" >{{$ctrl.title}}</div>
                {{$ctrl.text}}
                <md-button md-no-ink class="md-icon-button" ng-if="$ctrl.moreText.length" ng-click="$ctrl.more = !$ctrl.more">
                  <md-icon md-svg-icon="core:dots-horizontal"></md-icon>
                </md-button>
                <div ng-if="$ctrl.more  " style="margin: 1em 0; overflow: auto" ng-bind-html="$ctrl.moreText"></div>
            </md-card-title-text>
          </md-card-title>
        </md-card>
    `
};

export const ErrorToastComponent = {
    scope: {
      more: false
    },
    bindings: {
      error: '<'
    },
    controller: ErrorController,
    template: `<div>
                {{$ctrl.text}}
                <md-button md-no-ink class="md-icon-button" ng-if="$ctrl.moreText.length" ng-click="$ctrl.more = !$ctrl.more">
                  <md-icon md-svg-icon="core:dots-horizontal"></md-icon>
                </md-button>
                <div ng-if="$ctrl.more  " style="margin: 1em 0; overflow: auto" ng-bind-html="$ctrl.moreText"></div>
               </div>`
};

export const ErrorDialogTemplate = `
    <md-toolbar>
      <div class="md-toolbar-tools"><h2 >{{$ctrl.title}}</h2></div>
    </md-toolbar>
    <md-dialog-content ng-switch="$ctrl.error.data.ErrorType">
        <div ng-switch-when="registryConfirmDelete" class="md-dialog-content" bind-html-compile="$ctrl.ConfirmDelete"></div>        
        <div ng-switch-when="registryPreventDelete" class="md-dialog-content" bind-html-compile="$ctrl.PreventDelete"></div>        
        <div ng-switch-default class="md-dialog-content">
            <p>
                <span>{{$ctrl.text}}</span> 
                <md-button class="md-icon-button" ng-if="$ctrl.moreText.length" ng-click="$ctrl.more = !$ctrl.more"><md-icon md-svg-icon="core:dots-horizontal"></md-icon></md-button>
            </p>
            <div ng-if="$ctrl.more" style="margin: 1em 0; overflow: auto" ng-bind-html="$ctrl.moreText"></div>
            
        </div>
    </md-dialog-content>
`;

export const ErrorDialogComponent = {
    bindings: {
        error: "<"
    },
    controller: ErrorController,
    template: ErrorDialogTemplate
};

var confirmAction = `
     <p>{{$ctrl.error.data.Error}}</p>
    <form ng-submit="$ctrl.sendConfirm(Confirm)" name="Confirm" flex layout="column">
        <!--<div>For confrim enter <b>"{{$ctrl.error.data.RegistryData.ConfirmString}}"</b>.</div>-->
        <!--<input type="hidden" name="Confirm" ng-model="$ctrl.confirm" value="true" required>-->
        <div ng-if="$ctrl.more" style="margin: 1em 0; overflow: auto" ng-bind-html="$ctrl.moreText"></div>
        <div layout="row" layout-align="start start">
            <span flex></span>
            <md-button ng-if="$ctrl.moreText" ng-click="$ctrl.more = !$ctrl.more">{{$ctrl.more ? "COMMON_LESS" : "COMMON_MORE" | translate}}</md-button>
            <md-button type="submite">{{"COMMON_CONFIRM" | translate }}</md-button>
        </div>
    </form>
`;

var ConfirmDelete = `
    <p>{{$ctrl.error.data.Error}}</p>
    <form ng-submit="$ctrl.sendConfirm(ConfirmDelete)" name="ConfirmDelete" flex layout="column">
        <div>For confrim enter <b>"{{$ctrl.error.data.RegistryData.ConfirmString}}"</b>.</div>
        <md-input-container flex style="width: 100%;">
            <label>Confirm key</label>
            <input type="text" name="confirm" ng-model="$ctrl.confirm" required>
        </md-input-container>
        <div layout="row" layout-align="start start">
            <span flex></span>
            <md-button ng-click="$ctrl.more = !$ctrl.more">{{$ctrl.more ? "COMMON_LESS" : "COMMON_MORE" | translate}}</md-button>
            <md-button type="submite">{{"COMMON_DELETE" | translate }}</md-button>
        </div>
    </form>
    <div ng-if="$ctrl.more">
        <h5>Will be deleted</h5>
        <cmp-dependence list="$ctrl.error.data.RegistryData.Delete"></cmp-dependence>
    </div>

    `;

var ForceDelete = `
    <p>{{$ctrl.error.data.Error}}</p>
    <form ng-submit="$ctrl.sendConfirm(ConfirmDelete)" name="ConfirmDelete" flex layout="column">
        <md-list>
            <md-list-item ng-repeat="item in $ctrl.error.data.RegistryData.Delete">
                {{item.Type}} {{item.Name}}
                <!--<div class="md-secondary warningInfo" ><md-icon md-svg-icon="core:lock"></md-icon></div>-->
            </md-list-item>
        </md-list>
        <div layout="row" layout-align="start start">
            <span flex></span>
            <md-button ng-click="$ctrl.more = !$ctrl.more">{{$ctrl.more ? "COMMON_LESS" : "COMMON_MORE" | translate}}</md-button>
            <md-button type="submite">{{"COMMON_DELETE" | translate }}</md-button>
        </div>
    </form>
    <div ng-if="$ctrl.more">
        <h5>Will be deleted</h5>
        <cmp-dependence list="$ctrl.error.data.RegistryData.Delete"></cmp-dependence>
    </div>
    `;


var PreventDelete = `
    <p>{{$ctrl.error.data.Error}}
        <md-button md-no-ink class="md-icon-button" ng-click="$ctrl.more = !$ctrl.more"><md-icon md-svg-icon="core:dots-horizontal"></md-icon></md-button>
    </p>
    <cmp-dependence ng-if="$ctrl.more" reasone="$ctrl.error.data.Reason" list="$ctrl.error.data.RegistryData.Delete"></cmp-dependence>
`;

var RegistryData = `
    <md-list>
        <md-list-item ng-repeat="item in $ctrl.error.data.RegistryData | orderBy:'DeleteAction'">
            {{item.Type}} {{item.Name}}
            <div ng-if="item.DeleteAction == 'prevent' " class="md-secondary warningInfo" ><md-icon md-svg-icon="core:lock"></md-icon></div>
            <!--<div ng-if="item.DeleteAction == 'confirm' " class="md-secondary" >deleting</div>-->
        </md-list-item>
    </md-list>
`;
