import {ProjectClusterBaseController} from '../project_cluster_base_controller'

export  class ProjectClusterStorageController extends ProjectClusterBaseController{
    constructor($state, $scope, WorkspaceService, ProjectClusterService, $mdDialog, $translate, WorkspacePermissions,  ProjectStoragesService, AuthService) {
        super( ...arguments );
        this.service = ProjectStoragesService;
        this.AuthService = AuthService;
    }

    $onInit() {
        super.$onInit();
        this.getNew();

    }

    getNew() {
        this.newStorage = {
            nfs: {}
        };
    }

    // getFullInfo(update){
    //     // debugger;
    //     if(!this.list) {
    //         update = true;
    //     }
    //     debugger;
    //     if (update) {
    //         var self = this;
    //         debugger;
    //         this.service.getResource(_.extend({}, this.$state.params, {clusterName: this.data.Name}));
    //         // this.instancesListState = false;
    //         this.loadingError = null;
    //         this.service.getList(update)
    //             .then(
    //                 function (r) {
    //                     self.list = r;
    //                 },
    //                 function (err) {
    //                     self.loadingError = err;
    //                 }
    //             )
    //             .finally(
    //                 function () {
    //                     // self.instancesListState = null;
    //                     // if (self.timeout) {
    //                     //     clearTimeout(self.timeout)
    //                     // }
    //                     // self.timeout = _.delay(_.bind(self.getFullInfo, self), self.autoUpdate, true);
    //                 }
    //             )
    //     }
    //     return this.list;
    // }
    //
    // $onDestroy() {
    //     if(this.timeout) {
    //         clearTimeout(this.timeout);
    //     }
    // }
}


export const ProjectClusterStorageComponent= {
    bindings: {
      data: '<'
    },
    controller: ProjectClusterStorageController,
    template: `
          
        <div ng-include=" 'kuberlab/projects/card/project_card_title_list.html' "></div>
        
        <div ng-if="$ctrl.isActive" class="paddingListItemTabContent fullInfo">
           <cmp-cluster-storages ></cmp-cluster-storages>
        </div>
        `
};