
/*
nvd3.org
 prototype:  https://github.com/sattarab/ng-nvd3/blob/master/examples/pie.html
 */


var nvd3Template = `<div id="{{$ctrl.chartId}}" class="{{$ctrl.divClass}}" flex layout-align="center center">
                        <svg ng-show="$ctrl.data && $ctrl.data.length "></svg>
                        <div class="dataEmpry" ng-if="!($ctrl.data && $ctrl.data.length) " style="position:absolute; top:45%; height: 10%; width: 100%; text-align: center;">No Data Available</div>
                    </div>`;

class controller {
    constructor ($scope, element) { this.$scope = $scope; this.$eleemnt = element; }
    $onInit() {
        this.svg = this.$eleemnt[0].querySelector('svg');
        this.$scope.$watchCollection( () => this.data, (data) => { this.init(data)} ) ;
        this.$scope.$watch( () => this.updateCount, (data) => { this.init(true)} );
        if(this.height){
            this.$eleemnt[0].style.height = this.height + 'px';
        }
        // this.chart = this.initChart();

    }
    init(data) {}
    initChart() {}
}

class controllerPieChart extends controller {
    constructor($scope, $element) {super(...arguments)}
    init(data) {
        var self = this;
        if (true){
            nv.addGraph( () => {
                var chart = nv.models.pieChart()
                    .x((d) => d.label )
                    .y((d) => d.value )
                    .showLegend(self.hideLegend === 'false' ? false : true)
                    .labelType(self.labelType)
                    .donut(self.donut === 'true' ? true : false)
                    .donutRatio(0.35);

                if (self.colors){
                    chart.color(self.colors);
                }

                if (self.half === 'true'){
                    chart.pie
                        .startAngle( (d) => { return d.startAngle / 2 - Math.PI / 2; })
                        .endAngle( (d) => { return d.endAngle / 2 - Math.PI / 2; });
                }

                if (self.responsive !== 'true'){
                    chart.width(self.width).height(self.height);
                }

                d3.select(self.svg)
                    .datum(self.data)
                    .attr('width', self.width)
                    .attr('height', self.height)
                    .transition().duration(self.duration == null ? 250 : self.duration)
                    .call(chart);
                nv.utils.windowResize(chart.update);
                return chart;
            });
        }
    }
}

export const nvd3PieChart = {
    template: nvd3Template,
    controller: controllerPieChart,
    bindings: {
        chartId: '@',
        data: '=',
        divClass: '@',
        duration: '@',
        donut: '@',
        guide: '@',
        height: '@',
        responsive: '@',
        width: '@',
        xlabel: '@',
        xformat: '@',
        ylabel: '@',
        yformat: '@',
        half: "@"

    }
}

class controllerLineGraph extends controller {
    constructor($scope, $element) {super(...arguments);         this.controller = this; }
    // initChart() {
    //     var chart = nv.models.lineChart()
    //         .useInteractiveGuideline(this.guide === 'true' ? true : false)
    //         .margin({right: 35});
    //
    //     chart.xAxis
    //         .axisLabel(this.xlabel)
    //         .tickFormat(d3.format(this.xformat));
    //
    //     chart.yAxis
    //         .axisLabel(this.ylabel)
    //         .axisLabelDistance(42)
    //         .tickFormat(d3.format(this.yformat));
    //
    //     if (this.responsive !== 'true') {
    //         chart.width(this.width).height(this.height);
    //
    //         d3.select( this.svg )
    //             .attr('viewBox', '0 0 ' + this.width + ' ' + this.height);
    //     }
    //
    //     nv.utils.windowResize(chart.update);
    //     return chart;
    // }
    //
    // init(data) {
    //     var self = this;
    //     nv.addGraph(() => {
    //         let chart = self.chart;
    //         var d3Chart = d3.select( self.svg )
    //             .datum( data )
    //             .attr('width', self.width)
    //             .attr('height', self.height)
    //             .attr('perserveAspectRatio', 'xMinYMid')
    //             .transition()
    //             .duration(self.duration == null ? 250 : self.duration)
    //         d3Chart.call(chart);
    //
    //         return self.chart;
    //     });
    // }


    init(data) {
        if ( true ) {
            var self = this;
            nv.addGraph(() => {
                var data = self.data ? self.data : [];
                var chart = nv.models.lineChart()
                    .useInteractiveGuideline(self.guide === 'true' ? true : false)
                    .margin({right: 35});

                chart.xAxis
                    .axisLabel(self.xlabel)
                    .tickFormat(d3.format(self.xformat));

                chart.yAxis
                    .axisLabel(self.ylabel)
                    .axisLabelDistance(42)
                    .tickFormat(d3.format(self.yformat));

                if (self.responsive !== 'true') {
                    chart.width(self.width).height(self.height);

                    d3.select( self.svg )
                        .attr('viewBox', '0 0 ' + self.width + ' ' + self.height);
                }

                self.d3Chart = d3.select(self.svg)
                    .attr('width', self.width)
                    .attr('height', self.height);

                if(!data.length) {
                    nv.utils.noData(chart, self.d3Chart);
                } else {
                    self.d3Chart
                        .datum(data)
                        .attr('perserveAspectRatio', 'xMinYMid')
                        .transition()
                        .duration(self.duration == null ? 250 : self.duration)
                        .call(chart);
                }

                nv.utils.windowResize(chart.update);
                self.chart = chart;


                return chart;
            });
        }
    }
}

export const nvd3LineGraph = {
    template: nvd3Template,
    controller: controllerLineGraph,
    bindings: {
        chartId: '@',
        data: '=',
        divClass: '@',
        duration: '@',
        guide: '@',
        height: '@',
        responsive: '@',
        width: '@',
        xlabel: '@',
        xformat: '@',
        ylabel: '@',
        yformat: '@',
        updateCount: '='
        , controller: '='
    }
}

