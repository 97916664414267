import {CoreServiceResource} from '../core/service/core_service_resource'

export class StorageService  extends CoreServiceResource {
    constructor($resource, $q, KuberlabConfigApi, $rootScope, storageList) {
        super(...arguments);
        this.params = {};
        this.storageTypeList = storageList;
    }

    getResource() {

    }

    getType(storage) {
        return _.first(_.keys(_.pick.apply(this, [storage].concat(_.keys(this.storageTypeList))))) || 'undefined';
    }

    changeType(storage, type) {
        storage = _.omit.apply(this, [storage].concat(_.keys(this.storageTypeList)));
        if(type) storage[type] = {};
        return storage;
    }
}