 export class MlmodelServingController {
    constructor(data, version, resource, UserService, ClusterService, MlmodelService, $scope, $mdDialog, $state) {
        this.data = data;
        this.$state = $state;
        this.version = version;
        this.resource = resource;
        this.$mdDialog = $mdDialog;

        this.ClusterService = ClusterService;
        this.wsList = UserService.ws;
        this.serveData = {
            WorkspaceName: _.first(this.wsList).Name,
            Name: this.data.Name + '-' + this.version.Version.replace(/\./g,'-'),
            Config: _.omit(angular.copy(version.Config.serving), "accelerators", "limits", "requests")
        };

        this.serveData.Config.resources = _.pick(angular.copy(version.Config.serving), "accelerators", "limits", "requests");
        this.serveData.Config.spec = angular.copy(version.Config.servingSpec);

        this.setWs(this.serveData.WorkspaceName);
        this.MlmodelService = MlmodelService;
        $scope.cancel = () => {
            this.$mdDialog.hide();
        }
    }

    setWs(wsId) {
        this.error = false;
        this.serveData.ClusterID = null;
        this.ClusterService.getList(wsId, {}, true)
            .then(
                (r) => {
                    this.clusters = r;
                    // TODO: blank value validation needed, set prefill instead
                    if (this.clusters) {
                        this.serveData.ClusterID = _.first(this.clusters).ClusterID;
                    }
                },
                (err) => {
                    this.error = err;
                }
            )

    }

    serve(form){
        debugger
        if(!form.$valid) return false;
        this.loading = true;
        this.error = false;
        this.resource.serve({name: this.data.Name, version: this.version.Version}, this.serveData).$promise
            .then(
                (r)=> {
                    this.$mdDialog.hide();
                    this.$state.go('wsServing.tab', {wsId: this.serveData.WorkspaceName, servingName: r.Name});
                },
                (err) => {
                    this.error = err;
                }
            )
            .finally(
                ()=>{this.loading = false;}
            )
    }
}
