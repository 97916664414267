/**
 * Created by ilja on 10.03.17.
 */

import {ChartsListController} from '../charts_list_controller'

class ChartsListControllerFind extends  ChartsListController{
    constructor (ChartsService, $scope, $state, $localStorage, $rootScope, $element, $mdDialog, UserService, WorkspacePermissions) {

        super(...arguments);
    }

    $onInit(){
        this.parent.tabsVisitation[this.index] = true;
        this.page = 1;
        this.limit = 10;
        this.resource = this.ChartsService.getResource({Interface: 'chart-app'}, true);
        var self = this;
        this.$scope.$watch(
            function () {
                return self.page;
            },
            function () {
                self.getList(true, self.page);
            }
        );
    }

    getList(update, page) {
        var self = this;
        var data = {
            page: this.page,
            limit: this.limit
        };
        if(this.search) data.search = this.search;
        this.isLoading = true;
        this.ErrorList = false;
        this.listPromise = this.resource.query( data ).$promise;
        this.listPromise
            .then(
                function (r) {
                    self.list = r;
                },
                function (err) {
                    self.ErrorList = err;
                }
            )
            .finally(function () {
                self.isLoading = false;
            });
    }

    selectChart(chart) {
        this.chart = chart;

    }

    goPage(page){
        this.page = page;
        this.getList(true, page);
    }
}

export const InstallChartFindComponent = {
    controller: ChartsListControllerFind,
    bindings: {
        chart: "=",
        tab:'=',
        parent: '<',
        index: '<'
    },
    template: `
        <div layout="row" layout-align="space-between center" class="topline">
            <div>
                <cmp-pagenate page="$ctrl.page" limit="$ctrl.limit" list="$ctrl.list"></cmp-pagenate>
                <div ng-if="$ctrl.listEmpty" ng-include=" 'kuberlab/charts/card/pagenate.html' "></div>
            </div>
            <div class="layout-row">
                <form class="search" ng-submit="$ctrl.find()"><input type="text" ng-model="$ctrl.search"><md-button type="submit" class="md-icon-button"><md-icon md-svg-icon="core:search"></md-icon></md-button></form>
            </div>
        </div>
        <md-progress-linear ng-if="$ctrl.isLoading"></md-progress-linear>
        
        
  
        
        <md-list class="md-dense" flex>
           <md-radio-group ng-model="$ctrl.chart">
                <div ng-repeat="item in $ctrl.list">
                     <md-radio-button
                          ng-value="item"
                           style="margin-bottom: 0;"
                           >
                             <md-list-item class="md-3-line" style="padding-right:0;">
                                <img ng-src="{{item.Picture}}" class="md-avatar" alt="{{item.Name}}" />
                                <div class="md-list-item-text" layout="column">
                                    <h3>{{item.DisplayName}}</h3>
                                    <h4>Repository: {{item.RepositoryURL}}, Dir: {{item.RepositoryDir}}</h4>    
                                    <p ng-if="item.Description">{{ item.Description }}</p>
                                </div>
                            </md-list-item>
            
                      </md-radio-button>
                </div>
            </md-radio-group>
        </md-list>
    `
};