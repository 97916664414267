export class SharedClusterAcceptController {
    constructor(SharedClusterService, UserService, $state, CU, $scope) {
        // this.Ws = UserService.ws;
        var self = this;
        this.CU = CU;
        this.UserService = UserService;
        $scope.$watch(
            () => {return self.UserService.ws},
            (c) => {self.Ws = c;}
        );

        this.$state = $state;
        this.SharedClusterService = SharedClusterService;
        this.resource = SharedClusterService.getResource({type: 'available'});
        // if(this.CU) {
        // }
        this.info();
    }

    info() {
        var self = this;
        self.tokenInfoError = null;
        this.resource.token_info({token: this.$state.params.token})
            .$promise
            .then(
                (r) => {
                    self.tokenInfo = r;
                },
                (err) => {
                    self.tokenInfoError = err;
                }
            )
    }

    accept(form) {
        if(form.$valid){
            var self = this;
            self.error = null;
            this.resource.token_accept({token: this.$state.params.token}, {workspace: this.sharedWs})
                .$promise
                .then(
                    (r) => {
                        // debugger;
                        self.$state.go("workspace", {wsId : self.sharedWs});
                    },
                    (err) => {
                        self.error = err;
                    }
                )
        }
    }

    cancel(){

        var self = this;
        self.error = null;
        this.resource.token_delete({token: this.$state.params.token})
            .$promise
            .then(
            (r) => {
                // debugger;
                self.$state.go('home');
            },
            (err) => {
                self.error = err;
            }
        )
    }
}

export const SharedClusterAcceptTemplate = `
    <div flex layout='column' layout-align="center center" md-theme="pageHomeWelcome" class="home_welcome_block">
        <div class="main_width_greed" >
            <home-card-welcom>
                <home-card-welcom-content>
                    <h2><cmp-logo></cmp-logo></h2>
                    <div class="content">
                        Invited to shared cluster.
                        <div ng-if="$ctrl.tokenInfo">
                            <span class="select">Cluster "{{$ctrl.tokenInfo.DisplayName}}"</span> 
                            <p style="font-size: 20px; padding-left: 2em; margin: 0;">
                                <span ng-if="$ctrl.tokenInfo.Description">{{$ctrl.tokenInfo.Description}}<br/></span>
                                <span>From: {{$ctrl.tokenInfo.From.WorkspaceName}}/{{$ctrl.tokenInfo.From.ProjectName}}/{{$ctrl.tokenInfo.From.ProjectName}}</span>
                                <span ng-if="$ctrl.tokenInfo.Shared.limits"><br/>Limit: 
                                    <span ng-repeat="(k,i) in $ctrl.tokenInfo.Shared.limits">{{k}}-{{i}}<span ng-if="!$last">, </span></span> 
                                </span>
                            </p>
                        </div>
                    </div>
                    <cmp-api-error-service ng-if="$ctrl.error" error="$ctrl.error" format="'dialog'"></cmp-api-error-service>
                </home-card-welcom-content>
                <home-card-welcom-actions >
                    <auth-service-list ng-if="!$ctrl.CU" type="'Login'"></auth-service-list>
                    <div ng-if="$ctrl.CU && $ctrl.tokenInfoError">
                        <h4 class="warning">Token error</h4>
                    </div>
                    <form ng-if="$ctrl.tokenInfo && $ctrl.CU" ng-submit="$ctrl.accept(accept)" name="accept" class="layout-column layout-align-start" >
                        <h4 translate>SHARED_CLUSTER_ACCEPT_TITLE</h4>
                        <md-input-container>
                            <label translate>COMMON_WORKSPACE</label>
                            <md-select ng-model="$ctrl.sharedWs" required>
                                <md-option ng-repeat="item in $ctrl.Ws" ng-value="item.Name">{{item.DisplayName}}</md-option>
                            </md-select>
                        </md-input-container>
                        <md-button type="submite" class="md-primary md-raised">Accept</md-button>
                        <md-button ng-click="$ctrl.cancel()" class="md-raised">{{"COMMON_CANCEL" | translate}}</md-button>
                    </form>
                </home-card-welcom-actions>
            </home-card-welcom>
        </div>
    </div>
`