/**
 * Created by ilja on 16.11.16.
 */


// import {ErrorApi} from './core/error_handler'




// export const KuberlabConfigApi = {
//     APIPath: '/api/v0.2',
//     ResourceParams: {
//         query: {
//             method: 'GET',
//             isArray: true,
//             interceptor : {responseError : ErrorApi()}
//         },
//         get: {
//             method: 'GET' ,
//             params: {id: '@ID'},
//             interceptor : {responseError : ErrorApi()}
//         },
//         create: {
//             method: 'POST',
//             // interceptor : {responseError : ErrorApi}
//         },
//         update: {
//             method: 'PUT',
//             params: {id: '@ID'},
//             // interceptor : {responseError : ErrorApi}
//         },
//         delete: {
//             method: 'DELETE',
//             params: {id: '@ID'},
//             // interceptor : {responseError : ErrorApi}
//         }
//     },
//     ApiError : ErrorApi
// };



export class KuberlabConfigApi {
    constructor($q, $state, $rootScope) {
        this.q = $q;

        this.$state = $state;
        this.$scope = $rootScope;
        var self = this;
        this.APIPath = '/api/v0.2';
        this.ResourceParams = {
            query: {
                method: 'GET',
                isArray: true,
                interceptor : {responseError : _.bind(this.ErrorApi, this)}
            },
            get: {
                method: 'GET' ,
                // params: {id: '@ID'},
                interceptor : {responseError : _.bind(this.ErrorApi, this)}
            },
            create: {
                method: 'POST',
                interceptor : {responseError : _.bind(this.ErrorApi, this)}
            },
            update: {
                method: 'PUT',
                // params: {id: '@ID'},
                interceptor : {responseError : _.bind(this.ErrorApi, this)}
            },
            delete: {
                method: 'DELETE',
                // params: {id: '@ID'},
                interceptor : {responseError : _.bind(this.ErrorApi, this)}
            }
        };


    }

    ErrorApi (error) {
        console.info('api error', error);
            switch (error.status) {
                // case -1:
                //     // debugger;
                //     // this.$scope.$emit('show_toast', {message: 'connection failed'});
                //     // this.apiErrorFailed(error);
                //     break;
                // case 401:
                //     debugger;
                //     this.ErrorApi401(error);
                //     break;
                // case 404:
                //     // debugger;
                //     this.$state.go('error', {api_error: JSON.stringify(error)}, {location: false});
                //     // this.$state.go('404', {}, {location: false});
                //     break
            }
            return this.q.reject(error);
    }

    apiErrorFailed (error) {
        if(error.status == -1) {
            this.$scope.$emit("api_error", error);
            // this.$scope.$emit("apiErrorFailed", {error: error});
        }
    }


    ErrorApi401 () {
        window.location.href= '/';
    }
}




export const speedScroll = 300;