import {ControllerDataset, StorageDatasetComponent} from '../storage_dataset/storage_dataset_component'

class Controller  extends ControllerDataset {
    constructor($state, CatalogService, ErrorService, $scope){
        super(...arguments);
        this.label = 'Dataset';
    }
}

export const StorageDatasetFsComponent = {
    // bindings: {
    //     data : "=",
    //     workspaceName: '<'
    // },
    bindings: StorageDatasetComponent.bindings,
    controller: Controller,
    template: StorageDatasetComponent.template
//         `
//         <div flex="50" layout="row" layout-align="start center">
//             <md-input-container flex>
//                 <label translate>Dataset FS</label>
//                 <md-select ng-model="$ctrl.dataset" ng-change="$ctrl.changeDataset()" required>
//                     <md-option ng-repeat="item in $ctrl.list" ng-value="item" >{{item.Name}} [{{item.WorkspaceName}}]</md-option>
//                 </md-select>
//             </md-input-container>
//             <md-button ng-if="$ctrl.workspaceName &&  $ctrl.dataset.WorkspaceName != $ctrl.workspaceName" ng-click="$ctrl.fork()" ng-disabled="$ctrl.forking">fork</md-button>
//         </div>
//         <md-input-container flex="50">
//             <label translate>COMMON_VERSION</label>
//             <md-select ng-model="$ctrl.data.version" required>
//                 <md-option ng-repeat="item in $ctrl.versions" ng-value="item.Version" >{{item.Version}} (<span ng-bind-html="$ctrl.Service.datasetVersionSize(item)"></span>)</md-option>
//             </md-select>
//         </md-input-container>
//
// `
};