class Controller {
    constructor(RepoService, AuthService, $scope, $mdDialog, $translate, $mdPanel, WorkspaceService) {
        this.$mdDialog = $mdDialog;
        this.$translate = $translate;
        this._mdPanel = $mdPanel;
        this.WorkspaceService = WorkspaceService;
        this.$scope = $scope;
        this.repositoryAc = '';
    }

    $onInit() {
        // debugger;
        // if(this.wsId){
        //     this.resource = this.WorkspaceService.Serviceacconts(this.wsId);
        //     this.getWsaList();
        // }

        this.$scope.$watch(
            () => {
                return this.wsId;
            },
            () => {
                if(this.wsId) this.getWsaList();
            }
        )
    }

    getWsaList() {
        var self = this;
        this.WorkspaceService.Serviceacconts(this.wsId).query(
            {},
            function(r){
                self.wsaList = r;
            },
            function (r) {
                console.error("Organizations team users request error", r);
            }
        );
    }

    openWizard() {
        this.wizard = true;
        this.oldData = {
            url: this.repositoryUrl,
            env: this.env,
            dir: this.repositoryDir,
            rev: this.repositoryRev,
            ac: this.repositoryAc,
        }
    }

    okWizard() {
        this.wizard = false;
        this.oldData = null;
    }

    cancelWizard() {
        if(this.oldData){
            this.repositoryUrl = this.oldData.url;
            this.env = this.oldData.env;
            this.repositoryDir = this.oldData.dir;
            this.repositoryRev = this.oldData.rev;
            this.repositoryAc = this.oldData.ac;
        }
        this.wizard = false;
        this.oldData = null;
    }
}


export const RepoSelectComponent = {

    controller : Controller,

    bindings : {
        'repositoryUrl': '=',
        'repositoryDir' : '=',
        'repositoryRev' : '=',
        'repositoryAc' : '=',
        'env': '=',
        required:"<",
        'withoutRevision': '<',
        'wsId' : "<"
    },

    template : `
    <h4 translate>REPO_WIZARD</h4>
    <div layout-wrap ng-show="!$ctrl.wizard" layout="row" layout-align="start center" style="position: relative">
        <md-input-container class="flex">
            <label translate>REPO_URL</label>
            <input type="text" ng-model="$ctrl.repositoryUrl" ng-required="$ctrl.required">
            <help-hint hint="repo.url"></help-hint>
        </md-input-container>
        <md-input-container class="flex" ng-if="$ctrl.env !== undefined">
            <label translate>REPO_ENV</label>
            <input type="text" ng-model="$ctrl.env">
            <help-hint hint="repo.environment"></help-hint>
        </md-input-container>
        <div style="position: relative">
            <md-button ng-click="$ctrl.openWizard()" class="md-raised"><md-icon md-svg-icon="core:search-web" ></md-icon> Expand</md-button>
        </div>
        
        <md-input-container class="flex" ng-if="$ctrl.repositoryDir !== undefined">
            <label translate>REPO_DIR</label>
            <input type="text" ng-model="$ctrl.repositoryDir">
            <help-hint hint="repo.dir"></help-hint>
        </md-input-container>
        
        <!--<md-input-container class="flex" ng-if="$ctrl.repositoryRev !== undefined">-->
        <md-input-container class="flex" ng-if="!$ctrl.withoutRevision" >
            <label translate>REPO_REV</label>
            <input type="text" ng-model="$ctrl.repositoryRev">
            <help-hint hint="repo.rev"></help-hint>
        </md-input-container>
        <md-input-container flex="20" ng-if="$ctrl.wsId">
                <label>Account</label>        
                <md-select ng-model="$ctrl.repositoryAc">
                    <md-option ng-value="''">without account</md-option>
                    <md-option ng-repeat="item in $ctrl.wsaList | filter:{Service: 'git'} " ng-value="item.ID">{{item.Service}}:{{item.Name}}</md-option>
                </md-select>   
                 <help-hint hint="wsa.git.account"></help-hint>
            </md-input-container>
        
    </div>
    <md-card         
        ng-if="$ctrl.wizard">
        <md-card-content>
            <cmp-repo-select-wizard 
                repository-url="$ctrl.repositoryUrl"
                env="$ctrl.env"
                repository-dir="$ctrl.repositoryDir"
            ></cmp-repo-select-wizard>
        </md-card-content>
        <md-card-action class="layout-row layout-align-start-center">
            <span class="flex"></span>
            <md-button ng-click="$ctrl.cancelWizard()">{{"COMMON_CANCEL" | translate}}</md-button>
            <md-button ng-click="$ctrl.okWizard()" class="md-raised md-primary">{{"COMMON_OK" | translate}}</md-button>
        </md-card-action>
    </md-card>
    
    `
};
