class Controller  {
    constructor(){

    }

    // $onInit () {
    //     if(!this.data.type) {
    //         this.data.type = 'fast';
    //     }
    // }
}

export const StoragePvcComponent = {
    bindings: {
        data : "="
    },
    controller: Controller,
    template: `
        <md-input-container flex>
            <label>Claim Name</label>
            <input type="text" ng-model="$ctrl.data.claimName"/> 
        </md-input-container>
`
};