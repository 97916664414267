import {ProjectClustersBaseController} from '../project_clusters_base_controller'

export const ProjectClusterAppsComponent= {
    controller: ProjectClustersBaseController,
    template: `
        <md-progress-linear ng-if="$ctrl.service.isLoading" md-mode="indeterminate" flex></md-progress-linear>
        <md-list>
            <div ng-include=" 'kuberlab/projects/card/project_head_title_list.html' "></div>
            <cmp-project-cluster-app ng-repeat="item in $ctrl.list" data="item" id="tabItem_{{item.Name}}"></cmp-project-cluster-app>
        </md-list>
`
};