// import config from './docs_stateconfig'

import {DocsComponent} from './docs_component'
import {DocsLinkComponent} from './docs_link_component'

export default angular
    .module('docs', [])
    .component('cmpDocs', DocsComponent)
    .component('cmpDocsLink', DocsLinkComponent)
    // .config(config)
