
import {WsaCardListComponent} from './card/wsa_card_list'
import {WsaCardItemComponent} from './card/wsa_card_item'
import {WsaService} from './wsa_service'

export default angular
    .module('wsa', ['ngMaterial'])
    .service('WsaService', WsaService)
    .component('cmpWsaCardList', WsaCardListComponent)
    .component('cmpWsaCardItem', WsaCardItemComponent)
;
