
import conf from './inference_stateconfig'
import {InferenceService} from './inference_service'
import {InferenceCardHomeComponent} from './card_home'
import {InferenceVersionComponent} from './version/controller'
import {InferenceVersionItemComponent, InferenceVersionItemFullComponent} from './version/version-item'

export default angular
    .module("kuberlab.inference", [])
    .config(conf)
    .service('InferenceService', InferenceService)
    .component('cmpInferenceCardHome', InferenceCardHomeComponent)
    .component('cmpInferenceItemVersions', InferenceVersionComponent)
    .component('cmpInferenceVersionItem', InferenceVersionItemComponent)
    .component('cmpInferenceVersionItemFull', InferenceVersionItemFullComponent)
    // .constant('catalogInferencePageTabs',[
    //         {
    //             title : 'readme',
    //             content : '<cmp-catalog-item-readme readme-url="$ctrl.readmeUrl"></cmp-catalog-item-readme>'
    //         },
    //         {
    //             title : 'comments',
    //             content : '<cmp-catalog-item-comments resource="$ctrl.resource" data="$ctrl.data" ></cmp-catalog-item-comments>'
    //         },
    //         {
    //             title : 'versions',
    //             content : '<cmp-inference-item-version resource="$ctrl.resource" data="$ctrl.data" can-manage="$ctrl.canManage" service="$ctrl.service"></cmp-inference-item-version>'
    //         }
    //     ]
    // )
