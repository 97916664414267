import {ControllerItem} from '../../core/controller/item'


export class OrganizationFull extends ControllerItem {
    constructor (OrganizationService, $mdDialog, $translate, WorkspaceService, ErrorService, UserService, $state) {
        super($mdDialog, $translate);
        this.OrganizationService = OrganizationService;
        this.GlobalSettings = GlobalSettings;
        this.WorkspaceService = WorkspaceService;
        this.ErrorService = ErrorService;
        this.UserService = UserService;
        this.$state = $state;
    }

    $onInit() {
        super.$onInit();
        if(this.data && this.data.Name){
            this.WorkspaceService.workspace.get({wsId : this.data.Name}).$promise.then((result) => {
                this.canManage = result.Can.indexOf('manage') !== -1;
            });
        }
    }

    pickupName(form) {
        if(!form.DisplayName.$valid || !this.isNewElement) return false;
        this.OrganizationService.getResource().pickup({name: this.data.DisplayName}).$promise.then(
            (resp) => {
                this.data.Name = resp.Name;
            })
    }

    checkName(form) {
        // if( !form.Name.$valid ) return false;
        this.nameErrorMessage = null;
        this.OrganizationService.getResource().isavailable({name: this.data.Name}).$promise.then(
            (resp) => {
                        form.Name.$setValidity("errorname", true);
                    },
            (err) => {
                        this.nameErrorMessage = err;
                        form.Name.$setValidity("errorname", false);
                    }
            );
    }

    save (form) {
        var r = super.save(form);
    }

    saveOrg (form) {
        if(form.$valid) {
            var self = this;
            var super_save = super.save.bind(this);
            if (self.data instanceof self.OrganizationsService.organizations) {
                super_save(form);
            } else {
                this.saving = true;
                self.OrganizationsService
                    .addOrg(self.data)
                    .then(
                        (r) => {
                            self.data = r;
                            self.saveSuccess(r);
                        },
                        _.bind(self.saveError, this)
                    )
                    .finally(() => {
                        self.saving = false;
                        self.actionView();
                    })
            }
        }
    }

    cancel () {
        if(!this.isNewElement){
            super.cancel();
        } else {
            this.$window.history.back();
        }
    }

    // showMy () {
    //     if(this.data.ID == this.stateParams.orgId) {
    //         this.$state.go('settingsCurrentUser');
    //     }
    //     else {
    //         this.$state.go('settingsOrg', {orgId : this.data.ID});
    //     }
    // }

    delete (ev) {
        var self = this;
        var tr = {};
        var cb_delete = (params = {}) => {
            this.data.$delete( params)
                .then(
                    (r) => {
                        self.OrganizationService.getList(true);
                        self.UserService.getWs(true);
                        self.$state.go('setting', {wsId: 'my'});
                    },
                    (err) => {
                        self.ErrorService.error(err, cb_delete);
                    }
                );
        };
        cb_delete();
    }

    editName() {
        this.nameEditing = true;
        this.nameOrig = this.data.Name;
    }

    cancelEditName(form) {
        this.nameEditing = false;
        form.Name.$setValidity("errorname", true);
        this.data.Name = this.nameOrig;
    }

    saveName() {
        if (this.data.Name == this.nameOrig) {
            this.nameEditing = false;
            return;
        }
        var self = this;
        this.OrganizationService.getResource().set_name({ID: this.data.ID, name : this.data.Name}).$promise.then(
            function(r) {
                self.data.Name = r.Name;
                self.data.Workspace = r.Workspace;
                self.nameEditing = false;
                self.UserService.getWs(true);
            },
            function(e) {
                self.cancelEditName();
            }
        );
    }

    uploadFile (form) {
        if((form && !form.$valid) || !this.file || !this.file.length) return false;
        var file = this.file;
        var self = this;
        this.sendErrorMessage = null;
        this.loadingError = false;
        this.saving = true;
        var formData = new FormData();
        angular.forEach(file, (obj) => {
            if(!obj.isRemote){
                formData.append('src[]', obj.lfFile);
            }
        });
        var super_successSave = super.saveSuccess.bind(this);
        // self.OrganizationsService.organizations.upload({orgId: this.data.ID}, {picture: file[0].lfFile})
        this.OrganizationService.getResource().upload({org: this.data.ID}, {picture: file[0].lfFile})
            .$promise
            .then(
                (r) => {
                    // debugger
                    self.data.Picture = r.Picture;
                    self.lfApi.removeAll();
                    if(!form) {
                        self.saveSuccess(r);
                    } else {
                        this.OrganizationService.getList(true);
                        this.UserService.getWs(true);
                    }
                },
                (err) => {
                    self.sendErrorMessage = err;
                }
            )
            .finally(() => {
                this.saving = false;
            });
    }

    saveSuccess(r) {
        if(this.file && this.file.length) {
            this.uploadFile();
        }
        else {
            this.UserService.getWs(true);
            this.OrganizationService.getList(true);
            if(this.isNewElement){
                this.$state.go('setting', {wsId: r.Workspace});
            } else {
                this.$state.go('setting', {wsId: r.Workspace});
                this.actionView();
            }
        }
    }

};

export const OrganizationFullComponent = {
    bindings: {
        data: '=',
        listController: "<",
        stateParams : "<"
    },

    controller: OrganizationFull,
    template: function ($templateCache) {
        return $templateCache.get('kuberlab/organizations/organization/organization_full.html')
    }
};
