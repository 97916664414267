class controller {

    constructor($scope){
        this.$scope = $scope;
        $scope.$watch(
            () => {return this.fileInfo},
            (c) => {
                if (c && !c.dir) this.initView(); }
        );
        this.textFile = ['txt', 'cvs'];
        this.imgFile = ['jpg', 'jpeg', 'png'];
    };

    getFileFormat (exec) {
        if( this.textFile.indexOf(exec) != -1) return 'txt';
        if( this.imgFile.indexOf(exec) != -1) return 'img';
    }

    initView () {
        this.bigFile = false;
        if(this.fileInfo.size < 5000000){
            var l = this.fileInfo.name.split('.');
            this.fileType = l[l.length - 1].toLocaleLowerCase();
            this.fileFormat = this.getFileFormat(this.fileType);

            this.getFile(this.fileUrl);
        } else {
            this.bigFile = true;
        }
    }

    getFile(url, file){

        this.file = null;
        this.error = null;
        this.fileContentType = null;
        // debugger;
        if(this.fileFormat && this.fileFormat == 'txt') {
            this.loading = true;
            this.ctrl.listController.resource.fileGet({
                name: this.ctrl.datasetName(),
                version: this.ctrl.data.Version,
                path: url
            }).$promise
                .then(
                    (r) => {
                        this.fileContentType = r.header()['content-type'];
                        if (this.fileFormat == 'img') {
                            // debugger;
                            this.file = btoa(String.fromCharCode(...new Uint8Array(r.file)));
                        } else {
                            this.file = r.file; // String.fromCharCode.apply(null, new Uint8Array(r.file));
                        }
                    },
                    (err) => {
                        this.error = err;
                    }
                )
                .finally(() => {
                    this.loading = null;
                })
        }
    }

    downloadLink( download){
        return this.ctrl.listController.CatalogService.cfg.APIPath +
            '/workspace/' + this.ctrl.listController.$state.params.wsId +
            '/dataset/' + this.ctrl.listController.data.Name +
            '/versions/' + this.ctrl.data.Version +
            '/file/' + this.fileUrl + (download ? '?download=true' : "");
    }
}

export const DatasetversionViewer = {
    bindings: {
        fileUrl: '<',
        fileInfo: '<',
        ctrl: '<'
    },
    controller: controller,
    template:  ($templateCache) => {
        return $templateCache.get('kuberlab/catalog_data/version/viewer/tpl.html');
    }
}
