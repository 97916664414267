import {ItemController, CatalogVersionViewItemComponent} from '../../catalog/base/version-view/version-item'

class controller extends ItemController {

    constructor($scope) {
        super();
        this.$scope = $scope;
        $scope.$watch(
            () => this.data,
            () => {
                // debugger
                this.jsonToYaml();
            }
        )
    }


    $onInit() {
        super.$onInit();
        this.asForm = false;
        if(this.data.isNew) {
            this.edit();
        }
        this.jsonToYaml();
    }
    saveNewComplite(r, file) {
        this.data = r;

        if(file && file.length){
            this.uploadFullFile = true;
            _.each(file, (f) => { this._uploadFile(f)});
        }
        else {

            this.activate();
            this.setLisnkData();
        }
    }

    edit (notSetChange) {
        this._oldData = angular.copy(this.data);
        this.jsonToYaml();
        this.asForm = !this.asForm;
    }

    cEdit() {
        // debugger;
        this.data = this._oldData;
        this.edit(true);
    }

    saveNew(form) {
        this.yamlToJson();
        var  data = {
            "Version": this.newVersion,
            "Form": this.data.Form,
            "ServingConfigTemplate": this.data.ServingConfigTemplate,
            "EdgeConfigTemplate": this.data.EdgeConfigTemplate
            // ,
            // "StartSh": this.data.StartSh
        };

        super.saveNew(form, data);
    }

    save(form) {
        this.errorVersion = null;

        try {
            this.yamlToJson();
            this.loading = true;
            this.listController.resource.versionSave({
                name: this.listController.data.Name,
                version: this.data.Version
                }, this.data).$promise
                .then(
                    (r) => {
                        this.data = r;
                        this.edit();
                    },
                    (err) => {
                        this.errorVersion = err;
                    }
                )
                .finally(() => {
                    this.loading = false;
                });
        } catch (e) {
            debugger;
            this.errorVersion = { data : {Error : e } };
        }
    }

    serve(form){
        debugger;
    }

    yamlToJson() {
        this.data.Form = jsyaml.load(this.yamlForm);
    }

    jsonToYaml() {
        this.yamlForm = this.data.Form ? jsyaml.dump( this.data.Form ) : '';
    }
}

export const InferenceVersionItemComponent = {
    bindings : CatalogVersionViewItemComponent.bindings,
    controller: controller,
    template:  ($templateCache) => {
        return $templateCache.get('kuberlab/inference/version/version-item-tpl.html');
    }
}

export const InferenceVersionItemFullComponent = {
    bindings : CatalogVersionViewItemComponent.bindings,
    controller: controller,
    template:  ($templateCache) => {
        return $templateCache.get('kuberlab/inference/version/version-item-full-tpl.html');
    }
}
