export class AutoList{
    constructor($scope) {
        this.item = {};
        $scope.$watch(
            () => { return this.data; },
            () => { this.initList(); }
        );
        $scope.$watchCollection(
            () => {return this.list; },
            () => {this.data = this.clearData()}
        );
        this.asList = {};
    }

    $onInit() {
        this.labels = this.labels || ['key', 'value'];
        var self = this;
        _.each(this.labels, function (val) {
            self.item[val] = "";
        });
        // this.item = this.item || {key: '', value: ''};

        this.list;
        this.data;
        if(!this.data) {
            this.data = [];
        }
        if(!this.attr) this.attr = {};
        if(!this.attr.type) this.attr.type = {};

        _.each(this.data, (v, line) => {
            _.each(v, (data, item) => {
                if(_.isArray(data) ) this.asList[line + ' ' + item] = true;

            });
        });

        // this.initList();
        // this.addNew();
    }

    initList(){
        // if(_.isArray(this.data) && (!this.list || !_.isEqual(_.initial(this.list), this.data)) || !this.data) {
        if(_.isArray(this.data) && (!this.list || !_.isEqual(this.clearData(), this.data)) || !this.data) {
            this.list = this.data  ? _.clone(this.data ) :  [];
            this.addNew();
        }
    }

    change(form, index, label, type, valueType){
        if(type == 'array' || this.asList[index + ' ' + label]) {
            this.list[index][label] = _.map(this.list[index][label].split(','), (i) => {
                return valueType == 'number' ? Number(i) : i ;
            } );
        }
        if(this.list.length == index + 1) {
            if(form.$valid && this.list.length == index + 1 && _.indexOf(this.labels, label) == 0 ) {
                this.addNew();
            }
        }
    }

    delete(index){
        this.list.splice(index,1);
        // this.data = this.clearData();
    }

    canDelete(index){
        return (this.data && this.data[index]) ? true : false;
    }

    addNew(clear) {
        if(!_.isArray(this.list) || clear)
            this.list = [];
        this.list.push(angular.copy(this.item));
        // this.data = this.clearData();
    }

    clearData () {
        var data = _.initial(this.list);
        // var data = _.filter(this.list, (v) => {
        //     return !_.isEqual(v, this.item)
        // });
        return data;
    }
}

export const  AutolistComponent = {
    bindings: {
        data : "=",
        labels: "<",
        pattern: '<',
        form: '<',
        attr: '<'
    },
    controller: AutoList,
    template : `
        <div ng-repeat="(line, label) in $ctrl.list">
            <ng-form name="chartLabels" class="flex layout-row layout-align-space-between-start">
                <md-input-container class="flex" ng-repeat="item in $ctrl.labels" ng-switch="$ctrl.attr.type[item]" >
                    <label translate>{{ $ctrl.attr.label[item] || item }}</label>
                   
                    <md-select ng-switch-when="select"
                            ng-model="label[item]"
                            name="{{nameField = item+line}}"
                            >
                            <md-option ng-repeat="v in $ctrl.attr.volumes[item]" ng-value="v" >{{v}}</md-option>
                    </md-select>
                    
                    <textarea ng-switch-when="text" ng-model="label[item]"></textarea>
                     
                    <div ng-switch-when="array">
                        <input 
                                ng-model="label[item]" 
                                name="{{nameField = item+line}}" 
                                ng-change="$ctrl.change(chartLabels, line, item, $ctrl.attr.type[item],  $ctrl.attr.volumes[item])" 
                                ng-model-options="{ debounce: 1000 }"
                                >
                    </div>
                     
                    <div ng-switch-default layout="row" layout-align="start">
                        <input 
                            type="{{ $ctrl.attr.type[item] || 'text' }}" 
                            ng-model="label[item]" 
                            name="{{nameField = item+line}}" 
                            ng-change="$ctrl.change(chartLabels, line, item)" 
                            ng-pattern="$ctrl.pattern[item].pattern" 
                            ng-model-options="{ debounce: 1000 }"
                            style="order: unset;"
                            >
                                
                        <md-checkbox 
                            ng-if="$ctrl.attr.list[item]" 
                            ng-model="$ctrl.asList[line + ' ' + item]"
                            ng-change="$ctrl.change(chartLabels, line, item)" 
                            ng-model-options="{ debounce: 1000 }"
                            style="margin: 0;"
                            >
                            
                        </md-checkbox>
                        <md-tooltip md-direction="bottom" ng-if="$ctrl.attr.list[item]" >select checkbox if need value as list, and split ','</md-tooltip>
                        
                    </div>
                            
                    <div ng-messages="chartLabels[nameField].$error">
                        <div ng-message="pattern">{{$ctrl.pattern[item].message}}</div>
                    </div>
                    
                    
                                
                </md-input-container>

                <md-button class="md-icon-button" ng-show="$ctrl.canDelete(line)" ng-click="$ctrl.delete(line)"><md-icon md-svg-icon="core:delete" ></md-icon></md-button>
                <div  ng-show="!$ctrl.canDelete(line)"  style="width: 52px"></div>
            </ng-form>
        </div>
    `
};

