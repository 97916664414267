
import {ControllerList} from '../../core/controller/list'
import {ControllerItem} from '../../core/controller/item'
import {TeamUserListController} from './team/team_users'

export class OrganizationCardTeamListController extends ControllerList {
    constructor (OrganizationService) {
        super();
        this.resource = OrganizationService.getTeamsResource();
        this.list = null;
        this.organizationId;
    }

    $onInit() {
        this.teamList(true);
    }

    teamList (update) {
        var self = this;
        if(update) {
            this.promise = null;
        }
        if(!this.promise) {
            this.promise = this.resource.query(
                {org : this.organizationId},
                function(r){

                    var list = _.map(r, (v) => {
                        var t = _.findWhere(self.list , {ID : v.ID});
                        return t ? _.extend({}, t, v) : v;
                    });
                    self.list = list;
                },
                function (r) {
                    self.error = r;
                }
            );
        }
        return this.list;
    }};

export const OrganizationCardTeamListCmponent = {
    bindings: {
        organizationId: "="
    },

    controller: OrganizationCardTeamListController,
    template: `
       
        <md-list  class="md-dense">
            <cmp-organization-card-team 
                ng-repeat="data in $ctrl.list" 
                data="data" 
                organization-id="$ctrl.organizationId" 
                list-controller="$ctrl"></cmp-organization-card-team>
        </md-list>
        <md-button class="md-raised md-primary" ng-click="$ctrl.add({org: $ctrl.organizationId})"><md-icon md-svg-icon="core:plus"></md-icon>Add team</md-button>
`
};



export class OrganizationCardTeamController extends ControllerItem {
    constructor ( $scope, $mdDialog, $translate, Message, Utils) {
        super($mdDialog, $translate, Message);
        this.$scope = $scope;
        this.Utils = Utils;
        this.data; // from binding html
        this.organizationId; // from binding html
        this.inviteForm = false;
    }

    // $onInit() {
    //     super.$onInit();
    //     if (this.data.Permissions.owner) this.IsOwnerTeam = true;
    // }

    save(newTeam) {
        if(this.organizationId) this.data.org = this.organizationId;
        super.save(newTeam);
    }

    delete () {
      this.Utils.confirmDialog({
        title: 'SETTINGS_INVITE_ACTION_DELETE_TITLE',
        text: 'SETTINGS_INVITE_ACTION_DELETE_MESSAGE',
        okText: 'COMMON_DELETE'
      }).then(() => {super.delete({org : this.organizationId})});
    }

};

export const OrganizationCardTeamComponent = {
    bindings: {
        data: '=',
        organizationId: '=',
        listController: '<'
    },
    controller: OrganizationCardTeamController,
    template: function ($templateCache) {
        return $templateCache.get('kuberlab/organizations/card/org_card_team_settings.html')
    }
};
