
export default {
    state: null,
    steps:[
        {
            element: '#topmenu-catalog',
            intro: "Go to catalog.",
            action: 'click'
        }
    ],
    showBullets: false,
    showButtons: false,
    tooltipClass: 'tooltipClass_test',
    highlightClass: 'highlightClass_test'
}
