window.gitGraph;


class controller {


    constructor($rootScope, ApplicationsService, $state, $localStorage, $mdDialog, $templateCache, WorkspacePermissions, $scope, $translate) {
        this.$rootScope = $rootScope;
        this.$templateCache = $templateCache;
        this.$mdDialog = $mdDialog;
        this.$state = $state;
        this.storage = $localStorage;
        this.permissions = WorkspacePermissions;
        this.resource = ApplicationsService.getResource( $state.params );
        this.ApplicationsService = ApplicationsService;
        $localStorage.$default({
            applicationJobtView: true
        });
        this.grid = this.storage.applicationJobtView;
        var self = this;
        this.items = [];
        this.defCommitMessage = "";

        var tr;
        $translate(["PROJECT_HISTORY_DEFOULT_MESSAGE"]).then(function(translations){
                tr = translations;
            }).finally(() => {
                this.defCommitMessage = tr["PROJECT_HISTORY_DEFOULT_MESSAGE"];
            }
        );
    }

    $onInit() {
        this.initGraph();
        var self = this;
        this.init();
    }

    commitMouseover (event) {
        this.style.cursor = "pointer";
    }

    commitMouseout(evenet) {
        this.style.cursor = "auto";
    }

    getHistory() {
        this.loading = true;
        this.error = null;
        this.resource.history({application: this.$state.params.applicationName})
            .$promise
            .then(
                (r) => {
                    this.items = r;
                    this.gitGraphView();
                    // _.delay(() => { this.gitGraphView();} ,0);
                },
                (err) => {
                    this.error = err;
                }
            )
            .finally(() => {
                this.loading = false;
            })
    }

    initGraph() {

        var template = new GitGraph.Template({
            // colors: ["#F00", "#0F0", "#00F"], // branches colors, 1 per column
            branch: {
                lineWidth: 3,
                spacingX: 12
            },
            commit: {
                spacingY: -20,
                dot: {
                    size: 6
                },
                message: {
                    displayAuthor: true,
                    displayBranch: true,
                    displayHash: false,
                    font: "normal 12pt Arial"
                },
            }
        });

        var isUpdate = this.gitGraph ? true : false;

        window.gitGraph = this.gitGraph = new GitGraph(
            {
                // template: "metro",
                template: template,
                elementId: 'gitGraph',
                // orientation: "vertical"
                orientation: "vertical-reverse",

                // responsive: true,
                // maintainAspectRatio: false,
            }
        );
        this.branches = {};
        var self = this;
        this.gitGraph.canvas.addEventListener( "graph:render", function ( event ) {
            self.loading = false;
        } );

        this.gitGraph.canvas.addEventListener( "commit:mouseover", function ( event ) {
            this.style.cursor = "pointer";
        } );

        this.gitGraph.canvas.addEventListener("commit:mouseout", function (event) {
            this.style.cursor = "auto";
        });
        if( !isUpdate ) {
            this.gitGraph.canvas.addEventListener( "commit:mouseover", this.commitMouseover);
            this.gitGraph.canvas.addEventListener("commit:mouseout", this.commitMouseout);
        }
    }

    gitGraphView () {
        var items = this.items.reverse();
        this._lastItem = _.clone(items);
        _.each(items, (t) => {
            this._lastItem = _.without(this._lastItem, t);
            this.addGitPoint(t);
        });
    }

    addGitPoint(task){
        task.branch = task.branch || 'master';
        var parent, branch = task.branch;

        if( !this.branches[branch] ){
            this.branches[branch] = this.gitGraph.branch(branch);
        }
        var commitWithDetailsConfig = {
            sha1: task.ID,
            message: task.Message || this.defCommitMessage,
            author: task.AuthorName
            , onClick: (commit) => {
                this.commitInfo(commit);
            }
        };
        this.branches[branch].checkout();
        this.branches[branch].commit(commitWithDetailsConfig);
        this.cuccentBranch = branch;

        var child = [];
        _.each(this._lastItem, (r) => {
                if((_.isArray(r.Parents) && r.Parents[0] == task.ID)) {
                    child.push(r);
                    var branch = r.branch;
                    if( !this.branches[branch] ){
                        this.branches[branch] = this.gitGraph.branch(branch);
                    }
                }
            }
        );

    }

    checkout (ev, data, branch){
        this.$state.go('wsApplication.tab', {applicationName: this.appController.data.Name, tab:'models', tabItem : data.task_name, revision: data.ID});
    }

    init() {
        this.getHistory();
        // if (this.isOnCurrentPage()) {
        //     this.getHistory();
        // }
        // else {
        // }
    }

    isOnCurrentPage() {
      return this.$state.params.tab === 'history';
    }

    commitInfo(revision) {
        var self = this;
        var commit =_.findWhere(this.items, {ID: revision.sha1});
        this.$mdDialog.show({
            locals: {
                commit: commit
            },
            controllerAs: '$ctrl',
            controller: function (commit, $scope, $mdDialog) {
                this.$mdDialog = $mdDialog;
                $scope.commit = commit;
                $scope.cancel = () => {
                    this.$mdDialog.cancel();
                };
                $scope.load = (commit) => {
                    this.$mdDialog.hide(commit);
                };
            },
            template: this.$templateCache.get('kuberlab/applications/history/commit_info.html'),
            parent: angular.element(document.body),
            clickOutsideToClose:false,
        })
            .then(
                (commit) => {
                    let data = {applicationName: self.appController.data.Name, tab:'models', revision: commit.ID};
                    if(commit.task_name && commit.task_name.length) data.tabItem = commit.task_name;
                    self.$state.go('wsApplication.tab', data);
                },
                function() {
            });
    }


}

export const ApplicationHistoryComponent = {
    bindings: {
        // application: '=',
        appController: '='
    },
    controller: controller,
    template: function ($templateCache) {
        return $templateCache.get('kuberlab/applications/history/applications_history_component.html');
    }
};
