class controller {

    constructor($scope){
        var self = this;
        $scope.$watch(
            function () {
                return self.data.value;
            },
            function (c) {
                self.parent.setYaml();
            }
        );
        $scope.$watch(
            function () {
                return self.data.accountId;
            },
            function (c) {
                self.parent.setYaml();
            }
        );
        $scope.$watch(
            function () {
                return self.data.revision;
            },
            function (c) {
                self.parent.setYaml();
            }
        );
    }
}

export const ChartWizardGit = {
    bindings: {
        parent: "<",
        data: "="
    },
    controller : controller,
    template: `
        <div class="layout-collumn">
            <cmp-repo-select repository-url="$ctrl.data.value" repository-rev="$ctrl.data.revision" repository-ac="$ctrl.data.accountId" ws-id="$ctrl.parent.workspace_name"></cmp-repo-select>
        </div>
    `
};

