export class AuthRegisterEmailController {
    constructor(AuthService, $mdDialog, validate, $scope, vcRecaptchaService, KuberlabService, $window){
        this.$window = $window;
        this.validate = validate;
        this.authService = AuthService;
        this.$mdDialog = $mdDialog;
        this.data = {};
        this.dataLogin = {};
        this.$scope = $scope;
        this.vcRecaptchaService = vcRecaptchaService;
        var self = this;
        this.selectedIndex = 0 ;
        $scope.$watch(
            () => {return self.selectedIndex;},
            () => {self.error = null;}
        );
        this.settings = {};

        KuberlabService.settings()
            .then(
                (s) => {
                    self.settings = s;
                    self.initCaptcha();
                }
            );
    }

    initCaptcha() {
        var $scope = this.$scope,
            self = this;

        $scope.response = null;
        $scope.widgetId = null;
        $scope.setResponse = function (response) {
            console.info('Response available');
            $scope.response = response;
        };

        $scope.setWidgetId = function (widgetId) {
            console.info('Created widget ID: %s', widgetId);

            $scope.widgetId = widgetId;
        };

        $scope.cbExpiration = function() {
            console.info('Captcha expired. Resetting response object');
            self.vcRecaptchaService.reload($scope.widgetId);
            $scope.response = null;
        };
    }

    cancel() {
        this.$mdDialog.cancel();
    }

    submit(form){
        debugger;
        if(form.$valid) {
            switch(this.selectedIndex) {
                case 1:
                    this.reg();
                    break;
                case 0:
                    this.login();
                    break
            }
        }
    }

    reg() {
        this.error = false;
        var self = this;
        this.data['g-recaptcha-response'] = this.$scope.response;
        this.authService.register.create(this.data)
            .$promise
            .then(
                function (r) {
                    self.registerComplite = true;
                },
                function (err) {
                    self.error = err;
                    self.vcRecaptchaService.reload(self.$scope.widgetId);
                }
            )
    }

    login(){
        var self= this;
        this.error = false;
        this.authService.auth.login(this.dataLogin)
            .$promise
            .then(
                function (r) {
                    self.$window.location = '/';
                },
                function (err) {
                    self.error = err;
                }
            )
    }

    signIn() {
        this.$mdDialog.hide({action: 'signIn'});
    }

    passConfirm(form) {
        debugger;
        if(form.PasswordConfirm.$viewValue == form.Password.$viewValue){
            form.PasswordConfirm.$setValidity("confirm", true)
        }
        else {
            form.PasswordConfirm.$setValidity("confirm", false)
        }
    }
}


export const AuthRegisterEmailComponent= {
    template: function ($templateCache) {
        return $templateCache.get('kuberlab/auth/authr_register_email.html')
    },
    controller: AuthRegisterEmailController
};


// export const AuthRegisterEmailConmponent = {
//     bindings: {
//         currentUser: '='
//     },
//     template : `
//         <cmp-user-card-medium ng-if="$ctrl.currentUser">Current user</cmp-user-card-medium>
//         <md-card ng-if="!$ctrl.currentUser">
//             <md-card-title>Login</md-card-title>
//             <md-card-content layout="row" layout-align="space-between center">
//                 <md-input-container flex="50">
//                     <label>Email</label>
//                     <input ng-model="$ctrl.$ctrl.data.email" type="email" name="email">
//                 </md-input-container>
//                 <md-input-container flex="50">
//                     <label>Password</label>
//                     <input ng-model="$ctrl.$ctrl.data.password" type="password">
//                 </md-input-container>
//             </md-card-content>
//             <md-card-actions>
//                 <md-button ng-click="$ctrl.login()" class="md-raised">login</md-button>
//             </md-card-actions>
//         </md-card>
//         `,
//     controller: AuthLogin
// };
