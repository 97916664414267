export function LayoutPageContentItem() {
    function link() {

    }

    return ({
        link: link,
        restrict: "E"
    });
}
