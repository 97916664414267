// import {ws_listener} from '../../core/controller/ws_listener'

class controller {
    constructor(WorkspaceService, $state, $scope) {
        this.$scope = $scope;
        this.$state = $state;
        this.ws = $state.params.wsId;
        this.WorkspaceService = WorkspaceService;
    }

    $onInit() {
        this.wsResource = this.WorkspaceService.getResource(this.$state.params);
        this.wsResource.dashboard_cluster_stats().$promise
            .then(
                (r) => {
                    // r.push({
                    //     "ClusterName": "kibernetika",
                    //     "GlobalClusterID": "f9707a3b-80a9-4160-9ec4-5e883bf6a5a6",
                    //     "ProjectName": "kuberlab",
                    //     "ProjectDisplayName": "kuberlab",
                    //     "Environment": "shared",
                    //     "GlobalClusterName": "Public",
                    //     "ClusterID": "global/f9707a3b-80a9-4160-9ec4-5e883bf6a5a6",
                    //     "ClusterType": "global",
                    //     "Name": "Public",
                    //     "Meta": {
                    //         "ID": "f9707a3b-80a9-4160-9ec4-5e883bf6a5a6",
                    //         "DisplayName": "Public",
                    //         "From": {
                    //             "WorkspaceName": "kibernetika-ai",
                    //             "ProjectName": "kibernetika-ai",
                    //             "ClusterName": "kibernetika"
                    //         },
                    //         "Global": true,
                    //         "Free": false
                    //     },
                    //     "Host": "https://34.107.198.109:443",
                    //     "Stats": {
                    //         "task_count": 0,
                    //         "container_count": 11,
                    //         "gpu": {
                    //             "capacity": 0,
                    //             "used": 0,
                    //             "consumers": []
                    //         }
                    //     }
                    // });
                    this.list = r;
                    this.initData(r)
                },
                (err) => {}
            )


    }

    initData (list) {
        this.listMetrics = _.map (list, (m) => {
            var data;
            data = _.map(m.Stats.gpu.consumers, (r) => {
                var link, key;
                switch (r.type) {
                    case "Serving":
                    case "Serving-Model":
                        key = r.name + ' serving from ' + r.workspace;
                        link = {
                            name: 'wsServing',
                            params: {
                                wsId: r.workspace,
                                servingName: r.name,
                            }
                        };
                        break;
                    case "Task":
                        key = r.name + ' job from ' + r.workspace;
                        link = {
                            name: 'wsApplication.tab',
                            params: {
                                wsId: r.workspace,
                                applicationName: r.project,
                                tab: 'jobs',
                            }
                        };
                        break;
                    case "Ui":
                        key = r.name + ' Uix from ' + r.workspace;
                        link = {
                            name: 'wsApplication.tab',
                            params: {
                                wsId: r.workspace,
                                applicationName: r.project,
                                tab: r.name,
                            }
                        };
                        break;
                }
                return {

                    key: key,
                    y: r.consumed || 1,
                    link: link
                }
            });

            // var free =  m.Stats.gpu.capacity - m.Stats.gpu.used;
            // if( free > 0 || data.length > 0 )
            //     data.push({key: 'free', y: free, color: '#aaa'});
            // else
            //     data.push({key: 'no available, used', y: m.Stats.gpu.capacity, color: '#aaa'});


            var noAvailable = m.Stats.gpu.used - data.length;
            if(  noAvailable )
                data.push({key: 'no available, used', y: noAvailable, color: '#aaa'});

            var free =  m.Stats.gpu.capacity - m.Stats.gpu.used;
            if( free > 0 )
                data.push({key: 'free', y: free, color: '#ccc'});

            if(!data.length) {
                data = [{key: 'no gpu', y: 0, color: '#ccc'}]
            }

            var t = m.ProjectDisplayName + '/' + m.Name;
            return {
                data: data,
                options: this.nvd3Options(m.Stats.gpu.used + '/' + m.Stats.gpu.capacity,  t),
                title: t,
                ProjectDisplayName : m.ProjectDisplayName,
                Name: m.Name
            }
        })
    }

    nvd3Callback () {

    }

    nvd3Options(title, chartTitle) {

        var self = this;

        return {

            chart: {
                type: 'pieChart',
                height: 200,
                donut: true,
                x: function(d){return d.key;},
                y: function(d){return d.y;},
                showLabels: false,
                // "yAxis": {
                //         tickFormat: function (d) {
                //             debugger
                //
                //             return d3.format(',.1f')(d);
                //         }
                //     },
                pie : {
                    dispatch : {
                        elementClick : function(e) {
                            if(e.data.link && e.data.link.name)
                                self.$state.go(e.data.link.name, e.data.link.params);
                        },
                    }
                },
                // pie: {
                //     startAngle: function(d) { return d.startAngle/2 -Math.PI/2 },
                //     endAngle: function(d) { return d.endAngle/2 -Math.PI/2 }
                // },
                duration: 500,
                // legend: {
                //     margin: {
                //         top: 5,
                //         right: 70,
                //         bottom: 5,
                //         left: 0
                //     }
                // },
                showLegend: false,
                title: title,
                noData: '0 GPU Available.',
                // labelFormat: (d,i) => {
                //     // debugger
                //     return 'werewrwerwerewrw '
                // },
                tooltip: {
                    keyFormatter: (d, i) => {
                        return d;
                        // debugger
                    }
                },
                valueFormat: function(n){
                    // debugger
                    return n;
                }
            },

            "title": {
                "enable": chartTitle ? true : false,
                "text": chartTitle,
                "className": "h4",
                "css": {
                    "width": "nullpx",
                    "textAlign": "center"
                }
            },



            // "chart": {
            //     "type": "lineChart",
            //     // "height": 450,
            //     "margin": {
            //         "top": 20,
            //         "right": 20,
            //         "bottom": 40,
            //         "left": 55
            //     },
            //     "useInteractiveGuideline": true,
            //     "dispatch": {},
            //     "xAxis": {
            //         // "axisLabel": "Time (ms)"
            //     },
            //     "yAxis": {
            //         // "axisLabel": "Voltage (v)",
            //         "axisLabelDistance": -10,
            //         tickFormat: function (d) {
            //             return d3.format(',.1f')(d);
            //         }
            //     },
            // },
        }
    }


}

export const WorkspaceClusterStats= {

    template: `

        <md-card>
            <md-card-title>
                <md-card-title-text>
                    <span class="md-headline">Cluster resource usage</span>
                </md-card-title-text>
            </md-card-title>
            <md-content layout="row" layout-align="center" layout-wrap>
                <nvd3 
                    ng-repeat="item in $ctrl.listMetrics | orderBy : ['ProjectDisplayName', 'Name'] " 
                    options='item.options' 
                    data="item.data" 
                    api="item.api" 
                    flex 
                    on-ready="$ctrl.nvd3Callback" 
                    id="{{item.title}}" 
                    style=" margin: 10px; display: block; min-width: 200px" 
                ></nvd3>
            </md-content>
          </md-card>
          <!--{{$ctrl.listMetrics}}-->


        <!--<md-list>-->
            <!--<md-subheader>cluster resource usage</md-subheader>-->
            <!--<div layout="row" layout-align="center" layout-wrap>-->
                <!--<nvd3 ng-repeat="item in $ctrl.listMetrics | orderBy : ['ProjectDisplayName', 'Name'] " options='item.options' data="item.data" api="item.api" flex on-ready="$ctrl.nvd3Callback" id="{{item.title}}" style="min-width: 200px" ></nvd3>-->
            <!--</div>-->
        <!--</md-list>-->
    `,
    controller: controller
}
