

import {MlmodelListController} from './mlmodel_list_controller'
import {MlmodelItemController} from './mlmodel_item_controller'
import {MlmodelItemNewController} from './mlmodel_item_new_controller'

export default function ($stateProvider) {
    $stateProvider

        .state('wsMlmodel', {
            parent: 'wsBase',
            abstract: true,
            resolve: {
                mlmodelResource : function(workspace, MlmodelService, $state, $stateParams, pageConfig){
                    pageConfig.setTitle('mlmodel');
                    return MlmodelService.getResource($stateParams);
                }
            }
        })

        .state('wsMlmodelAdd', {
            parent: 'wsMlmodel',
            url : '/add_mlmodel',
            resolve: {
                currentUser : function ( CurrentUser ) {
                    return CurrentUser;
                },
                mlmodel : function( mlmodelResource, currentUser, workspace, $rootScope){
                    $rootScope.pageTitleConfig =  [
                        {
                            title: workspace.DisplayName,
                            state: 'workspace'
                        },
                        {
                            title : "new mlmodel",
                            state: 'wsMlmodelAdd'
                        }
                    ];
                    return new mlmodelResource({});
                }
            },
            views : {
                "@wsBase" : {
                    templateProvider: function ($templateCache) {
                        return $templateCache.get('kuberlab/catalog/base/card/card_new.html');
                    },
                    controller: MlmodelItemNewController,
                    controllerAs: '$ctrl'
                }
            }
        })

        .state('wsMlmodelList', {
            parent: 'wsMlmodel',
            url : '/mlmodels?:search:page:limit',
            params: {
                page: '1',
                limit: '30'
            },

            resolve: {

                addBtn : function ($rootScope, workspace, permissions) {
                    $rootScope.pageTitleConfig =  [
                        {
                            title: workspace.DisplayName,
                            state: 'workspace'
                        },
                        {
                            title : "mlmodel",
                            state: 'wsMlmodelList'
                        }
                    ];

                  $rootScope.addButtonConfig = [];

                  if (permissions.can('mlmodel.manage')) {
                    $rootScope.addButtonConfig.push({
                      title :'Add Model',
                      icon: "core:mlmodel",
                      state: {
                        name: 'wsMlmodelAdd'
                      }
                    })
                  }

                  return $rootScope.addButtonConfig;
                }
            },
            views: {
                "@wsBase" : {
                    templateProvider: function ($templateCache) {
                        return $templateCache.get('kuberlab/catalog/base/card/card_list.html');
                    },
                    controller: MlmodelListController,
                    controllerAs: '$ctrl'
                }
            }
        })

        .state('wsMlmodelItem_base', {
            parent: 'wsMlmodel',
            url : '/mlmodels/:item/:tab',
            params: {
                tab: 'readme'
            },
            abstract: true,
            resolve: {
                // mlCommentResource: function (MlmodelCommentsService, $stateParams) {
                //     return MlmodelCommentsService.getResource($stateParams);
                // },
                pageTabs: function (mlmodelPageTabs) {
                    debugger;
                    return mlmodelPageTabs;
                },
                mlmodel : function (mlmodelResource, $stateParams) {
                    return mlmodelResource.get({
                            name : $stateParams.item
                        })
                        .$promise;
                    },

                addBtn : function (workspace, $rootScope, mlmodel) {
                        $rootScope.pageTitleConfig =  [
                            {
                                title: workspace.DisplayName,
                                state: 'workspace'
                            },
                            {
                                title : "mlmodel",
                                state: 'wsMlmodelList'
                            },
                            {
                                title : mlmodel.DisplayName,
                                state: 'wsMlmodelItem'
                            }
                        ];

                        return $rootScope.addButtonConfig = null;
                    }
                },

            views: {
                        "@wsBase" : {
                        templateProvider: function ($templateCache){
                        return $templateCache.get('kuberlab/catalog/base/card/card_item.html');
                    },
                    controller: MlmodelItemController,
                    controllerAs: '$ctrl'
                }
            }
        })

        .state('wsMlmodelItem', {
            parent: 'wsMlmodelItem_base',
            url:'/:tabItem',
            resolve: {

                currentTab : function (pageTabs, $stateParams, $q, pageConfig, mlmodel) {
                    var tab = _.findWhere(pageTabs, {title: $stateParams.tab});

                    if(tab){
                        return $q.resolve(tab);
                    }
                    else
                        return $q.reject();
                }
            },
            views: {
                tabContent : {
                    templateProvider: function (currentTab) {
                        return currentTab.content;
                    },
                    controller: function (mlmodel, mlmodelResource, $state, pageConfig, $stateParams, permissions, MlmodelService) {
                        // debugger;
                        pageConfig.setTitle(mlmodel.Name + ' ' + $stateParams.tab);
                        this.$state = $state;
                        this.readmeUrl = '/workspace/' + this.$state.params.wsId + '/mlmodels/' + this.$state.params.item + '/readme';
                        this.resource = mlmodelResource;
                        this.data = mlmodel;
                        this.permissions = permissions;
                        this.canManage = permissions.can('mlapp.manage');
                        this.service = MlmodelService;
                    },
                    controllerAs: '$ctrl'
                }
            }
        })
}
