export class CatalogBaseVersionController {
    constructor($scope, $state, $mdDialog, $templateCache, Utils, ErrorService, CatalogService){
        this.$state = $state;
        this.CatalogService = CatalogService;
        // this.CatalogResource = CatalogService.getResource($state.params);
        this.Utils = Utils;
        this.$scope = $scope;
        this.loadingError = {};
        this.isLoading = {};
        var inject = angular.element(document.body).injector();
        this.$mdDialog = inject.get('$mdDialog');
        this.validate = inject.get('validate');
        this.newStateName = 'new';
    }

    $onInit() {
        this.CatalogResource = this.CatalogService.getResource(this.$state.params);
        this.getVersions();
    }

    getVersions(){
        // this.service.getVersions(this.data)
        this.CatalogResource.versions({name: this.data.Name}).$promise
            .then(
                (r) => {
                    // debugger;
                    this.versions = r; //.reverse();
                    // this.versions = _.sortBy (r, 'Created'); //.reverse();


                    // if(!this.version) this.getDefault(); //this.version = _.first(r);
                    this.getDefault();

                    this.getVersionsComplite();
                },
                (err) => {
                    this.versions = [];
                    this.ErrorService.error(err);
                    // debugger;
                }
            )
            .finally(()=>{
                this.complite = true;
            })
    }

    getDefault () {
        if(this.$state.params.tabItem) {
            this.version = _.findWhere(this.versions, {Version: this.$state.params.tabItem })
        } else {
            this.version = _.first(this.versions)
        }
    }

    getVersionsComplite() {}

    addVersion (form) {
        if(!form.$valid) return false;
        this.version = {Version: this.newVersionName, Status: this.newStateName};
        this.versions.push(this.version);
        this.newVersionName = '';
    }

    cancelAddVersion(){
        this.getDefault();
        // this.version = _.last(this.versions);
    }

    async download() {
        if (this.$scope.isDownloading) return;

        try {
            this.DownloadURL = this.version.DownloadURL;
            this.$scope.isDownloading = true;
            this.$scope.downloadError = null;
            let file = await fetch(this.DownloadURL, {credentials: 'include'});

            if (file.status !== 200) {
                const error = await file.json();
                throw {data: error, status: file.status};
            }

            file = await file.blob();

            const blob = window.URL.createObjectURL(file);

            const fileName = this.DownloadURL.split('/').pop().split('?').shift();
            const aEl = document.createElement('a');
            aEl.download = fileName;
            aEl.href = blob;

            aEl.click();

            this.$scope.isDownloading = false;
            this.$scope.$digest();
        } catch(error) {
            console.error(error);

            this.$scope.isDownloading = false;
            this.$scope.downloadError = {data: {Error: error}};
            this.$scope.$digest();
        }
    }

    uploadFile (form, file, action, paramName, lfApi) {
        if(!form.$valid) return false;
        var self = this;
        var $scope = this;
        this.loadingError[paramName] = false;
        this.isLoading[paramName] = true;
        this.loadingProgres = 0;
        var dataSendFile = {};
        dataSendFile[paramName] = file[0].lfFile;


        this.requestUploadFile = this.service.getResource(
            this.$state.params,
            true,
            (e) => {
                // debugger;
                if (e.lengthComputable) {
                    // self.loadingProgres = ~~(e.loaded / e.total) * 100;
                    self.loadingProgres = ~~((e.loaded / e.total) * 100);
                }
            }
        )
            [action](
            {name: this.data.Name, version: this.version.Version},
            dataSendFile
        );
        this.requestUploadFile
            .$promise
            .then(
                function (r) {
                    // debugger;
                    self.data = r;
                    // self.addFile = false;
                    file = false;
                    self.isLoading[paramName] = false;
                    lfApi.removeAll();
                    self.getVersions();
                },
                function (err) {
                    self.loadingError[paramName] = err;
                    self.isLoading[paramName] = false;
                }
            );
    }


    delete (version) {
        this.Utils.confirmDialog({
            title: 'MLMODEL_VERSION_DELETE_TITLE',
            text: 'MLMODEL_VERSION_DELETE_MESSAGE',
            okText: 'COMMON_DELETE'
        }).then(() => {this._delete(version)});
    }

    _delete(version){
        if(!version ) version = this.version;
        if(this.version.Status == this.newStateName) {
            // debugger;
            this.versions = _.without(this.versions, version);
            this.getDefault();
            this.$scope.$digest();
        }else{
            this.service._resource.version_delete({name: this.data.Name, version: version.Version }).$promise
                .then(
                    (r) => {
                        this.versions = _.without(this.versions, version);
                        if(version.Version == this.version.Version) {
                            this.version = _.first(this.versions);
                            this.$state.go(this.$state.current.name, {tabItem: this.version.Version})
                        }
                    },
                    (err) => {
                        this.ErrorService.error(err);
                    }
                )
        }
    }

}

export const CatalogItemVersionComponent = {
    bindings: {
        resource: '<',
        data: '<',
        canManage: '<',
        service: '<',
        canNew: '<'
    },
    controller: CatalogBaseVersionController,
    template:  ($templateCache) => {
        return $templateCache.get('kuberlab/catalog/base/version/template.html');
    }
}
