import {ControllerDataset, StorageDatasetComponent} from '../storage_dataset/storage_dataset_component'

class Controller  extends ControllerDataset {
    constructor($state, CatalogService, ErrorService, $scope){
        super(...arguments);
        this.Interface = 'mlmodel';
        this.dataName = 'model';
        this.label = 'Model';
    }

    getVersionList(r){

        let base = [];
        if( this.isServing ) base =  [{Version: 'latest'}];

        var list = base.concat( _.filter(r, (i) => {
                return i.Editing != true && i.Status != 'error';
            })
        );
        return list;
    }
}

export const StorageModelComponent = {
    bindings: _.extend({isServing: '<'}, StorageDatasetComponent.bindings),
    controller: Controller,
    template: StorageDatasetComponent.template
};
