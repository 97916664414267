import {CatalogBaseItemNewController} from '../catalog/base/item_new_controller'

export class MlmodelItemNewController extends CatalogBaseItemNewController {
    constructor (mlmodel, MlmodelService) {
        super();
        this.data = mlmodel;
        this.Service = MlmodelService;
        // this.delayNameCheck;
        this.stateName = 'wsMlmodelItem';
    }
}
