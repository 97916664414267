class controller {
    constructor(AuthService, KuberlabConfigApi, $resource, $state, vcRecaptchaService, $scope, KuberlabService) {
        this.AuthService = AuthService;
        this.$state = $state;
        this.$scope = $scope;
        this.vcRecaptchaService = vcRecaptchaService;
        this.link = KuberlabConfigApi.APIPath + '/oauth/complete';
        this.formLink = this.link + '';
        this.resource = $resource(this.link, {}, {
            complite: {
                method:'POST'
            },
            complite_email: {
                method:'POST',
                url: this.link + '/email'
            },
            info : {
                method: "GET",
                url: this.link + '/info'
            }
        });
        this.KuberlabService = KuberlabService;
    }

    $onInit() {
        this.KuberlabService.settings().then((settings) => {
                this.settings = settings;
                this.initCaptcha();
            }
        );
        this.resource.info().$promise.then(
            (r) => {
                this.info = r;
                this.formLink = this.info.Email ? this.formLink + '/email' : this.formLink;
            },
            (err) => {
                this.errorInfo = err;
            }
        );

    }

    initCaptcha() {
        const $scope = this.$scope;
        $scope.response = null;
        $scope.widgetId = null;
        $scope.setResponse = (response) => {
            console.info('Response available', response);
            $scope.response = response;
        };

        $scope.setWidgetId = (widgetId) => {
            console.info('Created widget ID: %s', widgetId);
            $scope.widgetId = widgetId;
        };

        $scope.cbExpiration = () => {
            console.info('Captcha expired. Resetting response object');
            this.vcRecaptchaService.reload($scope.widgetId);
            $scope.response = null;
        };
    }

    send(form) {
        if (!form.$valid) return;
        this.sending = true;
        this.error = null;
        this.isRegistering = true;
        this.data['g-recaptcha-response'] = this.$scope.response;
        if(this.info.Invite) this.data.Invate = this.info.Invite.ID;
        var action = this.data.Email ? 'complite_email' : 'complite';
        this.resource[action](this.data).$promise.then(
            () => {
                // debugger;
                this.registerComplete = true;
                // this.isRegistering = false;
            }, (err) => {
                this.error = err;
                this.vcRecaptchaService.reload(this.$scope.widgetId);
                // this.isRegistering = false;
            }
        )
            .finally(() => {
            this.sending = false;
        })
    }

    goto(id) {
        window.location.href = this.link + '/' + id;
    }
}

/**
 * {
  "Service": "github",
  "ServiceID": "17584655",
  "ServiceName": "Vadim",
  "ServicePicture": "https://avatars3.githubusercontent.com/u/17584655?v=4",
  "ServiceUrl": "https://github.com/monstarnn",
  "ServiceEmail": "monstarnn@gmail.com",
  "Scopes": []
}
 * @type {{controller: controller, template: string}}
 */

export const AuthCompleteComponent = {
    controller: controller,
    template: `
        
        <div layout="row" layout-align="center center" style="min-width: 300px">
            <md-card style="min-width: 350px;">
                <md-content>
                    <div class="md-padding">
                        <cmp-error-toast ng-if="$ctrl.errorInfo" error="$ctrl.errorInfo"></cmp-error-toast>
                        <form name="complite" method="post" action="{{$ctrl.link}}" ng-if="!$ctrl.registerComplete && $ctrl.info && !$ctrl.errorInfo">
                            <h3>Complete registration</h3>
                            <p>
                                Hello, {{$ctrl.info.ServiceName}}!<br/>
                            </p>
                            <p ng-if="!$ctrl.info.ServiceEmail">Your {{$ctrl.info.Service}} account has not returned your email, so you should type it here:</p>
                            <div layout="column" layout-align="start">
                                <md-input-container ng-if="!$ctrl.info.ServiceEmail">
                                    <label translate>COMMON_EMAIL</label>
                                    <input type="email" ng-model="$ctrl.data.Email" required >
                                </md-input-container>
                                
                                <p>To complete registration you should agree to  <a href="https://kuberlab.com/terms" target="_blank">terms</a>:</p>
                                <md-input-container >
                                  <md-checkbox ng-model="$ctrl.data.AgreeToTerms" aria-label="I agree to terms" required>
                                    I agree to terms
                                  </md-checkbox>
                                </md-input-container>
                            </div>
                            <!--{{$ctrl.data.AgreeToTerms}}-->
                            <!--<inpute type='hidden' name="g-recaptcha-response" ng-model="response" >-->
                            <input type='hidden' name="AgreeToTerms"  ng-value="$ctrl.data.AgreeToTerms" >
                             <div ng-if="$ctrl.settings.recaptcha_key"
                                 vc-recaptcha
                                 theme="'light'"
                                 key="$ctrl.settings.recaptcha_key"
                                 on-create="setWidgetId(widgetId)"
                                 on-success="setResponse(response)"
                                 on-expire="cbExpiration()"
                            ></div>
                            <cmp-error-toast ng-if='$ctrl.error' error='$ctrl.error'></cmp-error-toast>
                            
                            <div layout="row" layout-align="start" ng-if="$ctrl.info.ServiceEmail">
                                <div flex><md-progress-linear ng-if="$ctrl.sending"></md-progress-linear></div>
                                <md-button type="submit" ng-disabled="!complite.$valid" >{{ 'COMMON_OK' | translate}}</md-button>
                            </div>
                            <div layout="row" layout-align="start" ng-if="!$ctrl.info.ServiceEmail">
                                <div flex><md-progress-linear ng-if="$ctrl.sending"></md-progress-linear></div>
                                <md-button ng-click="$ctrl.send(complite)" ng-disabled="!complite.$valid" >{{ 'COMMON_OK' | translate}}</md-button>
                            </div>
                        </form> 
                        <div ng-if="$ctrl.registerComplete">
                            <p>Please click on the link that has been sent to your email to complete the registration.</p>
                            <p>If you didn't receive verification email, please contact us at support@kuberlab.com.</p>
                        </div>
                    </div>
                </md-content>
            </md-card>
        </div>
    `
}
