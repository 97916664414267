class ProjectEnvController {
    constructor($scope, ProjectEnvService, $state, projectPageEnvDefault) {

        var self = this;
        this.projectPageEnvDefault = projectPageEnvDefault;
        this.$state = $state;
        this.envName = $state.params.envName;
        this.ProjecEnvService = ProjectEnvService;
        ProjectEnvService.getResource($state.params);
        this.list = ProjectEnvService.getList();

        $scope.$watch(
            function () {
                return self.ProjecEnvService.list;
            },
            function (current) {
                self.list = current;
            }
        );
        this.addNew();

    }

    go (envName) {
        this.$state.go('wsProject.tab',_.extend({}, this.$state.params, {envName : envName}));
    }

    addNew() {
        var self = this;
        this.ProjecEnvService.getNew({origin: this.envName})
            .then(function (r) {
                self.newEnv = r;
            });
        return false;
    }

    addEnv (form) {
        var self = this;
        if(form.$valid){
            this.newEnv.$create()
                .then(
                    function (r) {
                        self.ProjecEnvService.getList(true);
                        self.addNew();
                        self.$state.go('wsProject', _.extend({}, self.$state.params, {envName : r.Name}));
                    },
                    function (err) {
                        self.saveError = err;
                    }
                );
        }
        return false;
    }

}

export const ProjectEnvComponent = {
    controller: ProjectEnvController,
    bindings: {
        canCreate : '<'
    },
    template: `
        <!--<md-menu-bar id="projectENV">-->
            <md-menu md-position-mode="target-right target">
                <button ng-click="$mdMenu.open()"
                        layout="row"
                        layout-align="start center">
                        <span>environment:</span>&nbsp;<strong>{{$ctrl.envName}}</strong>
                    <md-icon md-svg-icon="core:chevron-down" class="icon s16" hide-xs="" aria-hidden="true"></md-icon>
                </button>
                <md-menu-content width="4">
                    <md-menu-item ng-repeat="data in $ctrl.list">
                        <a href ng-click="$ctrl.go(data.Name)" class="md-button">
                            {{data.Name}}
                        </a>
                    </md-menu-item>
                    <md-menu-divider></md-menu-divider>
                    <md-menu-item class="newEnvironmentForm" ng-if="$ctrl.canCreate">
                        <form name="newEnvName" ng-if="$ctrl.newEnv" ng-submit="$ctrl.addEnv(newEnvName)" layout="row" layout-align="space-between center">
                            <md-input-container>
                                <label>New environment</label>
                                <input type="text" ng-model="$ctrl.newEnv.Name">
                            </md-input-container>
                            <md-button class="md-raised md-primary" type="submit" style="text-align: center;">{{ 'COMMON_SAVE' | translate }}</md-button>
                        </form>
                    </md-menu-item>
                    <md-menu-item style="height: 100%;" ng-if="!$ctrl.canCreate">
                        <p style="max-width: 250px;" translate>ENVIRONMENT_CREATE_MANUAL</p>
                    </md-menu-item>
                </md-menu-content>
            </md-menu>
            
            <cmp-api-error-service ng-if="$ctrl.saveError" error="$ctrl.saveError" format="'dialog'"></cmp-api-error-service>
            
        <!--</md-menu-bar>-->
`
};
