import Config from './applications_stateconfig'
import {ApplicationsService} from './applications_service'
import {ApplicationService} from './application_service'
import Models from './models/applications_models_module'
// import Metrics from './metrics/applications_metrics_module'
import Jobs from './jobs/applications_jobs_module'
import Logs from './logs/applications_logs_module'
import Pip from './pip/applications_pip_module'
import Status from './status/applications_status_module'
import {ApplicationsListController} from './applications_list_controller'
import Volumes from './volumes/applications_volumes_module'
import {ApplicationsListItemComponent} from "./applications_item_controller"
import {ApplicationsDisabledComponent} from "./applications_disabled_component"
import Metrics2 from './metrics2/applications_metrics_module'
import Summary from './summary/application_summary_module'
import {ApplicationClusterLinkComponent} from './application_cluster_link'
import History from './history/applications_history_module'
import {ApplicationBranchSelectComponent} from './applications_branches'
import {ApplicationUixController} from './uix/application_uix_controller'
import ApplicationSimply from './simply/applications_simply_module'
import {ProjectItemStatus} from './card/project-item-status'
import {AppFilereader} from "../core/app-filereader/app-filereader-controller";

export default angular
    .module('application', [
        Models.name,
        Volumes.name,
        Jobs.name,
        Logs.name,
        Pip.name,
        Status.name,
        Metrics2.name,
        Summary.name,
        History.name,
        ApplicationSimply.name
    ])
    .config(Config)
    .service('ApplicationsService', ApplicationsService)
    .service('ApplicationService', ApplicationService)
    .component('cmpApplicationBranchSelect', ApplicationBranchSelectComponent)
    .component('cmpApplicationsListItem', ApplicationsListItemComponent)
    .component('cmpApplicationsDisabled', ApplicationsDisabledComponent)
    .component('cmpApplicationClusterLink', ApplicationClusterLinkComponent)
    .component('cmpApplicationUix', ApplicationUixController)
    .component('cmpApplications', {
            bindings: {
                    mainPage: '='
            },
            template: ($templateCache) => $templateCache.get('kuberlab/applications/card/applications_list.html'),
            controller: ApplicationsListController
    })
    .component('cmpApplicationsShort', {
        template: ($templateCache) => $templateCache.get('kuberlab/applications/card/applications_list_short.html'),
        controller: ApplicationsListController
    })

    .constant('applicationMetricsList', [
        "cpu_usage",
        "memory_bytes",
        "gpu_usage",
        "gpu_memory_bytes",
        "gpu_powerdraw",
        "gpu_temp"

        // ,
        //     "tf_step",
        //     "tf_rate_step",
        //     "tf_accuracy",
        //     "tf_cross_entropy"
    ])
    .constant('applicationMetricsListDefault', [
        "cpu_usage",
        "memory_bytes",
        "gpu_usage",
        "gpu_memory_bytes"
    ])
    .constant('applicationTabs', [
                {
                    name: 'summary',
                    displayName: 'PROJECT_TAB_SUMMARY'
                },
                {
                    name : 'sources',
                    displayName: 'Sources'
                },
                {
                    name : 'models',
                    displayName: 'PROJECT_TAB_MODELS'
                },
                {
                    name : 'metrics',
                    displayName: 'metrics'
                },
                {
                    name : 'jobs',
                    displayName: 'PROJECT_TAB_JOBS'
                },
                {
                    name : 'history',
                    displayName: 'PROJECT_TAB_HISTORY'
                },
                // {
                //     name : 'logs',
                //     displayName: 'Logs'
                // },
                // {
                //     name : 'install',
                //     displayName: 'PROJECT_TAB_INSTALL'
                // },
                {
                    name : 'status',
                    displayName: 'Status'
                },
                {
                    name : 'simple',
                    displayName: 'Simple'
                }

        ]
    )
    .directive('projectItemStatus', ProjectItemStatus)
