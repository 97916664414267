    class controller {
    constructor($scope) {
        this.index = 0;
        this.paramsKeys = ['camera', 'cloud_status', 'codec', 'file_fragment_num', 'file_fragment_time', 'height', 'output_fps', 'width', 'zoom'];
    }

    $onInit() {
        // // debugger
        // if(_.isArray(this.data)){
        //     this.a = true;
        // }
    }

    addStream ( event ) {
        event.stopPropagation();
        var key = 'stream';
        var el;
        if(this.index > 0){
            el = this.data[this.index - 1];
            key = this.data[this.index - 1].key + '_1';
        }
        this.data.push({
            key: key,
            input: el ? el.input : '',
            output: el ? el.output : '',
            description: el ? el.description : '',
            params: el ? el.params : {}
        });
    }

    removeStream(index, event) {
        event.stopPropagation();
        // debugger;
        if(this.data.length > 0) {
            this.data.splice( index, 1 );
        }

        console.log(this.data);
    }
}

export const FormConstructorStreamsComponent = {
    bindings: {
        data: '='
    },
    template: ($templateCache) => { return $templateCache.get('kuberlab/core/form-constructor/controls/streams/tpl.html'); },
    controller: controller
};
