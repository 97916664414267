import {AutolistComponent} from './autolist/auto_list'
import {AutolistSingleComponent} from './autolist/auto_list_single'
import {IframeComponent} from './iframe'
import Utils from '../../common/services/utils'
import Message from '../../common/services/message'
import  './password'
import {ViewerMdComponent} from './viewer/md'
import {DynamicSelect} from './dynamic_select/dynamic_select'
import Chart from './chart'
import {FormConstructorComponent} from './form-constructor/component'
import {FormConstructorStreamComponent} from './form-constructor/controls/stream/component'
import {FormConstructorStreamsComponent} from './form-constructor/controls/streams/component'
import {SelectProjectVolume} from './select_project_volume/controller'
import {SelectComponent} from './select/component'
import {AppFilereaderDec} from './app-filereader-dec/controller'
import {AppFilereader} from './app-filereader/app-filereader-controller'
import {ItemStatusIcon} from './item-status-icon';
import {BlockMenu} from './block_menu';
import {HlsVideo} from './hls-video/controller';
import {SelectNgFile} from './select-ng-file/select-ng-file';
import {FocusMe} from './focus_me';
import {ClickMe} from './click_me';
import {PleaseWait} from './please_wait'
import {WebrtcStream} from './webrtc_stream/controller'

export default angular
    .module('core', [
        'btford.markdown',
        'ui.ace'
    ])
    .config(function($mdThemingProvider) {
        $mdThemingProvider.theme('info-toast');
        $mdThemingProvider.theme('error-toast');
    })
    .component('cmpAutolist', AutolistComponent)
    .component('cmpAutolistSingle', AutolistSingleComponent)
    .component('cmpIframe', IframeComponent)
    .component('cmpViewerMd', ViewerMdComponent)
    .component('cmpDynamicSelect', DynamicSelect)
    .component('cmpFormConstructor', FormConstructorComponent)
    .component('cmpFormConstructorStream', FormConstructorStreamComponent)
    .component('cmpFormConstructorStreams', FormConstructorStreamsComponent)
    .component('cmpSelectProjectVolume', SelectProjectVolume)
    .component('cmpSelect', SelectComponent)
    .component('cmpWebrtcStream', WebrtcStream)
    .service('Utils', Utils)
    .service('Message', Message)
    .directive('rickshawChart', Chart)
    .directive('appFilereaderDec', AppFilereaderDec)
    .directive('appFilereader', AppFilereader)
    .directive('itemStatusIcon', ItemStatusIcon)
    .directive('blockMenu', BlockMenu)
    .directive('hlsVideo', HlsVideo)
    .directive("selectNgFiles", SelectNgFile)
    .directive("pleaseWait", PleaseWait)
    .directive('focusMe', ['$timeout', '$parse', FocusMe])
    .directive('clickMe', ['$timeout', '$parse', ClickMe])
