
import {CoreServiceResource} from '../core/service/core_service_resource'

export class WorkspaceCanService extends CoreServiceResource {
    constructor($resource, $q, KuberlabConfigApi, $rootScope ) {
        super(...arguments);
    }

    // getResource(params = {}, clear) {
    //     var wsId = params.wsId;
    //     if( this._wsId != wsId || !this._resource || clear ){
    //         var url = this.cfg.APIPath + '/workspace/' + wsId + '/can';
    //         var resource = this.$resource(
    //             url,
    //             {},
    //             _.extend({}, this.cfg.ResourceParams,
    //                 {
    //                     can: {
    //                         method: 'GET',
    //                         isArray: true
    //                     }
    //                 })
    //         );
    //         this._resource = resource;
    //         this._wsId = wsId;
    //         this.list = null;
    //     }
    //     if(!resource) resource = this._resource;
    //     return resource;
    // }

    // loadPermissions (wsId) {
    //     var self = this;
    //     var res = this.getResource({wsId: wsId});
    //     var r = res.can();
    //         r.$promise
    //             .then(
    //                 function (r) {
    //                     self._can = r;
    //                 },
    //                 function (err) {
    //                     debugger;
    //                 }
    //             );
    //
    //
    //     return r.$promise;
    // }

    set(workspace) {
        this._can = workspace.Can;
    }

    can(name) {
        return _.indexOf(this._can, name) != -1;
    }
}
