/**
 * Created by ilja on 17.11.16.
 */


class LayoutTitleLoginController{
    // constructor (AuthService) {
    //     this.AuthService = AuthService;
    //     this.serviceList = this.AuthService.ServiceList();
    // }
}

export const LayoutTitleLoginComponent = {
    template: function ($templateCache) {
        return $templateCache.get('kuberlab/layout/title/layout_title_login_component.html');
    },
    controller: LayoutTitleLoginController
};