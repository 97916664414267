
import {CoreServiceResource} from '../../core/service/core_service_resource'

export class ApplicationsModelService extends CoreServiceResource {
    constructor($resource, $q, KuberlabConfigApi, $rootScope) {
        super(...arguments);
    }

    getResource(params = {}, clear) {
        var wsId = params.wsId;
        if( this._wsId != wsId || this._application != params.applicationName || !this._resource || clear ){
            var apiPath = this.cfg.APIPath + '/workspace/' + wsId + '/applications/' + params.applicationName + '/builds/:version';
            var resource = this.$resource(
                apiPath,
                {version: '@Version'},
                _.extend({}, this.cfg.ResourceParams,
                    {
                        create: {
                            method: "POST"
                            // , params: {application: "@papplication"}
                        },
                        delete: {
                            method: 'DELETE',
                            params:{ version: '@Version' },
                            url: this.apiPath
                        },
                        start: {
                            method : 'POST',
                            params : {version: '@Version'},
                            url: apiPath + '/start'
                        }
                    })
            );
            this._resource = resource;
            this._wsId = wsId;
            this._application = params.applicationName;
            this.list = null;
            this.listPromise = null;
        }
        if(!resource) resource = this._resource;
        return resource;
    }

    getItem(Version) {
        if(this.list && this.list.length){
            var pr = _.findWhere(this.list, {version : Version});

            if(pr){
                return this.$q.resolve(pr);
            }
        }

        return this._resource.get({version : Version}).$promise;
    }

}