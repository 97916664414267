class AddButtonController {
    constructor ($rootScope, $state, $scope, $translate){
        this.$state = $state;
        this.$rootScope = $rootScope;
        this.$translate = $translate;
        this.topDirections = ['left', 'up'];
        this.bottomDirections = ['down', 'right'];

        this.availableModes = ['md-fling', 'md-scale'];
        this.selectedMode = 'md-scale';

        this.availableDirections = ['up', 'down', 'left', 'right'];
        this.selectedDirection = 'up';
        var self = this;
        this.addButtonConfig = $rootScope.addButtonConfig;



        $rootScope.$watch(
            function () {
               return $rootScope.addButtonConfig;
            },
            function (c) {
                var tr = {};
                self.addButtonConfig = c;
                var btn = _.map(self.addButtonConfig, (val) => {return val.title});
                self.$translate(btn)
                    .then(
                        function(translations){
                            tr = translations;
                        })
                    .finally(
                        function () {
                            _.each(self.addButtonConfig, (val) => {
                                val.title = tr[val.title];
                            });
                        });
            }
        );

        this.$scope = $scope;
    }

    go (name, data) {
        this.$state.go(name, data);
    }

    isMoreThen1Item() {
      return this.addButtonConfig.length > 1;
    }

    mainButtonClick() {
      if (this.isMoreThen1Item()) return;

      let action = this.addButtonConfig[0];
      this.go(action.state.name, action.state.params);
    }

}

export const AddButtonComponent = {
    controller: AddButtonController,
    template: `
      <md-fab-speed-dial 
                ng-if="$ctrl.addButtonConfig.length" 
                md-direction="{{$ctrl.selectedDirection}}"
                ng-class="$ctrl.selectedMode">
        <md-fab-trigger>
          <md-button aria-label="menu" class="md-fab" ng-click="$ctrl.mainButtonClick()">
            <md-tooltip md-direction="top" md-delay="300" ng-if="!$ctrl.isMoreThen1Item()">{{$ctrl.addButtonConfig[0].title}}</md-tooltip>
            <md-icon md-svg-src="core:plus"></md-icon>
          </md-button>
        </md-fab-trigger>

        <md-fab-actions ng-if="$ctrl.isMoreThen1Item()">
            <div ng-repeat="item in $ctrl.addButtonConfig">
              <md-button ng-click="$ctrl.go(item.state.name, item.state.params)" aria-label="{{item.title}}" class="md-fab md-raised md-mini">
                <md-tooltip md-direction="left" md-delay="300">{{item.title}}</md-tooltip>
                <!--<span>{{item.title}}</span>-->
                <md-icon ng-if="item.icon" md-svg-src="{{item.icon}}" aria-label="{{item.title}}"></md-icon>
                <img ng-src="{{item.img}}" ng-if="item.img" />
              </md-button>
            </div>
        </md-fab-actions>
      </md-ng-iffab-speed-dial>
    `
};
