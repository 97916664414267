// import {ApplicationsMetricsService} from './applications_metrics_service'
import {ApplicationsMetrics2Component} from './applications_metrics_component'

import nvd3 from '../../core/nvd3/module'

export default angular
    .module('applcations.metrics', [nvd3.name])
    .component('cmpApplicationsMetrics2', ApplicationsMetrics2Component)
    // .service('ApplicationsMetricsService', ApplicationsMetricsService)
;

