class Controller  {
    constructor(){
        this.medium = [ undefined   , 'Memory', 'HugePages']
    }

    $onInit() {
        // if(!this.data.Medium) this.data.Medium = '';
    }
}

export const StorageEmptyDirComponent = {
    bindings: {
        data : "="
    },
    controller: Controller,
    template: `
        <md-input-container class="flex-50">
            <label>Medium</label>
            <md-select ng-model="$ctrl.data.medium"'>
                <md-option ng-repeat="item in $ctrl.medium" ng-value="item">{{item || ''}}</md-option>
            </md-select> 
        </md-input-container>
        <md-input-container class="flex-50">
            <label>Size limit</label>
            <input ng-model="$ctrl.data.sizeLimit">
        </md-input-container>
`
};