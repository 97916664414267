import {CoreServiceResource} from '../../core/service/core_service_resource'

export class CatalogTagService extends CoreServiceResource {
    constructor($resource, $q, KuberlabConfigApi, $rootScope) {
        super(...arguments);
        this._params = {};
        this.Interface = '';
    }

    getWsUrl (params, link) {
        return this.cfg.APIPath + '/workspace/' + (params.wsId  ? params.wsId  : ':wsId') + '/tag';
    }

    getResourceUrl(params) {
        return params.wsId
            ? this.getWsUrl(params) + '/:name'
            : this.cfg.APIPath + '/catalog/tag/:name' ;
    }

    resourceAction () {
        return {};
    }

    getResource(params = {}, clear, prCb = () => {}) {
        if( this._params.wsId != params.wsId || this._params.Interface != params.Interface || !this._resource || clear ) {
            this._params = params;
            this.list = null;
            this.listPromise = null;
            var url = this.getResourceUrl(params);
            this._resource = this.$resource(
                url,
                { name: '@Name' },
                _.extend(
                    {},
                    this.cfg.ResourceParams, {
                        'create' : {
                            method: 'POST',
                            params: {name: '@createName'}
                        }
                    },
                    this.resourceAction(url)
                )
            );
        }
        return this._resource;
    }
}
