class controller {
    constructor(StorageService, CatalogService) {
        this.CatalogService = CatalogService;
        this.StorageService = StorageService;
    }

    volumeByName(name){
        return _.findWhere(this.volument, {name: name});
    }

    change() {
        debugger;
        this.cVolume = this.volumeByName(this.ngModel.name);
        this.type = this.ServingService.getType(this.cVolume);
        this.ngModel.version = '';
        if(this.type == 'dataset' || this.type == 'datasetFS'){

        }
    }

    getDatasetVersion (name, data) {
        var self = this;
        if( !this.versions ){
            this.versions = {};
        }

        var link = data.workspace + '/' + data.dataset;

        if(!this.versions[name] || this.versions[name].__datalink !== link ){
            this.CatalogService.getResource({wsId: data.workspace, Interface: 'dataset'});
            this.CatalogService.getVersions({Name: data.dataset, wsId: data.workspace})
                .then(
                    (r) => {
                        // self.versions[name] = _.map(r, (v)=> { return v.Version; });
                        self.versions[name] = r;
                        self.versions[name].__datalink = link;
                    },
                    (err) => { debugger; }
                )
        }
    }

}

export const SelectProjectVolume = {
    controller: controller,
    template: ($templateCache) => { return $templateCache.get('kuberlab/core/select_project_volume/tpl.html'); },
    bindings: {
        volumes: '<',
        ngModel: '=',
        appController: '<'
    }
}