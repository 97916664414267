
import {HelpService} from './help_service'
import {HelpListComponent} from './help_list_component'


class HintController {
    constructor($element) {
        $element.parent().addClass("md-icon-right");
    }
    phrase() {
        return "HINT_" + this.hint.toUpperCase().split(".").join("_");
    }
}


export default angular
    .module('help.module', ['ngMaterial'])
    .service('HelpService', HelpService)
    .component('cmpHelpList', HelpListComponent)
    .component('helpHint', {
        controller: HintController,
        bindings : {
            'hint': '@'
        },
        template : `
            <md-icon md-svg-icon="core:hint">
                <md-tooltip md-direction="bottom" class="help-hint">{{ $ctrl.phrase() | translate }}</md-tooltip>
            </md-icon>
        `
    });
;

//
//
//
//
//
//
// class HintController {
//     constructor($element) {
//         $element.parent().addClass("md-icon-right");
//     }
//     phrase() {
//         return "HINT_" + this.hint.toUpperCase().split(".").join("_");
//     }
// }
//
// export default angular
//     .module('help', ['ngMaterial'])
//     .component('helpHint', {
//         controller: HintController,
//         bindings : {
//             'hint': '@'
//         },
//         template : `
//             <md-icon md-svg-icon="core:hint">
//                 <md-tooltip md-direction="bottom" class="help-hint">{{ $ctrl.phrase() | translate }}</md-tooltip>
//             </md-icon>
//         `
//     });
