

import {InstallFromCluster, InstallFromClusterTemplate} from '../charts/install/install_from_cluster'
import {InstallChart, InstallChartTemplate} from '../charts/install/install_chart'
import {ProjectClusterShareController, ProjectClusterShareTemplate} from './clusters/project_cluster_share_component'


export class ProjectClusterBaseController {
    constructor ( $state, $scope, WorkspaceService, ProjectClusterService, $mdDialog, $translate, WorkspacePermissions) {
        this.data;
        this.WorkspaceService = WorkspaceService;
        this.clusterService = ProjectClusterService;
        this.$state = $state;
        this.$scope = $scope;
        this.$mdDialog = $mdDialog;
        // this.$injector = angular.injector();
        this.$translate = $translate;
        this.permissions = WorkspacePermissions;
        var inject = angular.element(document.body).injector();
        this.Utils = inject.get('Utils');
    }


    $onInit() {
        this.clusterResource = this.clusterService.getResource(this.$state.params);
        if(this.data.Name == this.$state.params.tabItem){
            this.getFullInfo();
            this.isActive = true;
        }
    }

    goItem (ev) {
        if(this.isActive) {
            this.$state.go(this.$state.current.name, _.extend({}, this.$state.params, {tabItem : null}));
        }
        else {
            this.$state.go(this.$state.current.name, _.extend({}, this.$state.params, {tabItem : this.data.Name}));
        }
    }

    installChart (ev) {
        var then = this;
        var ProjectService = angular.element(document.body).injector().get('ProjectService');
        ProjectService.getProject(this.$state.params.projectName)
            .then((project) => {
                // debugger;
                this.installError = null;
                this.$mdDialog.show({
                    locals: {
                        dialog: then.$mdDialog,
                        chart : null,
                        clusters: [{
                            Type: "cluster",
                            ID: then.data.Name,
                            Name: then.data.Name,
                            ProjectID : project.ID,
                            ProjectName: project.Name,
                            ProjectEnvironment: project.Environment,
                            Meta: then.data
                        }],
                        workspace_name: then.$state.params.wsId,
                    },
                    controller: InstallChart,
                    controllerAs: '$ctrl',
                    template: InstallChartTemplate,
                    parent: angular.element(document.querySelector('#mainBody')), // angular.element(document.body),
                    targetEvent: ev,
                    clickOutsideToClose:true,
                }).then(
                    function(data){},
                    function () {}
                );
            });
    }

    action (action, ev) {
        var self = this;
        var t = {};
        switch(action){
            case 'delete':
                this.$translate(['CLUSTER_DELETE_DIALOG_TITLE', 'CLUSTER_DELETE_DIALOG_MESSAGE', 'COMMON_DELETE', 'COMMON_CANCEL'])
                    .then(
                        function(translations){
                            t = translations;
                        },
                        function(){
                            debugger;
                        }
                    )
                    .finally(function(){

                        var confirm = self.$mdDialog.confirm()
                            .title(t['CLUSTER_DELETE_DIALOG_TITLE'])
                            .textContent(t['CLUSTER_DELETE_DIALOG_MESSAGE'])
                            .ariaLabel(t['CLUSTER_DELETE_DIALOG_TITLE'])
                            .targetEvent(ev)
                            .ok(t['COMMON_DELETE'])
                            .cancel(t['COMMON_CANCEL']);

                        self.$mdDialog.show(confirm).then(function() {
                            self.actionRequest(action);
                            // $scope.status = 'You decided to get rid of your debt.';
                        }, function() {
                            // $scope.status = 'You decided to keep your debt.';
                        });

                });
                break;
            case 'start' :
                this.Utils.confirmDialog({
                    title: 'CLUSTER_START_DIALOG_TITLE',
                    text: 'CLUSTER_START_DIALOG_MESSAGE',
                    okText: 'COMMON_OK'
                }).then(() => {
                    self.actionRequest(action);
                });

                break;
            case 'stop' :
                this.Utils.confirmDialog({
                    title: 'CLUSTER_STOP_DIALOG_TITLE',
                    text: 'CLUSTER_STOP_DIALOG_MESSAGE',
                    okText: 'COMMON_OK'
                }).then(() => {
                    self.actionRequest(action);
                });
                break;
            default:
                this.actionRequest(action);

        }

    }

    actionRequest(action, data = {}) {
        var self = this;
        this.isAction = true;
        delete this.actionError;
        this.clusterResource[action]({clusterName : this.data.Name}, data)
            .$promise
            .then(
                function (r) {
                    // debugger
                    if(action == 'delete'){
                        if (self.listController) {
                          self.clusterService.remove.call(self.listController, self.data);
                          self.listController.update.call(self.listController, true);
                        } else {
                          self.clusterService.remove(self.data);
                        }
                    }
                    else {
                        self.data.CurrentTask = r.ID;
                    }
                },
                function (err) {
                    self.actionError = err;
                }
            )
            .finally(
                function () {
                    delete self.isAction;
                }
            )
    }

    getWsaList (update) {
        var self = this;
        if(!this.wsaList){
            update = true;
        }
        if(update) {
            this.wsaList = [];
            this.WorkspaceService.Serviceacconts(this.$state.params.wsId)
                .query(
                    {},
                    function(r){
                        if(r.length)
                            self.wsaList = r;
                        else
                            self.wsaError = 'Workspace service account list is empty'
                    },
                    function (r) {
                        // console.error("Organizations team users request error", r);
                    }
                );
        }
        return this.wsaList;
    }

    rebase (ev) {
        var self = this;
        this.$mdDialog.show({
            controller: function($scope){
                var that = this;
                $scope.data = self.data;
                $scope.parentController = self;
                $scope.cs = self.data.Account;
                $scope.list = self.getWsaList();
                $scope.$watch(function () {
                        return self.wsaList;
                    },
                    function (c) {
                        $scope.list = c;
                    }
                );

                $scope.hide = function() {
                    this.parentController.$mdDialog.hide();
                };

                $scope.cancel = function() {
                    this.parentController.$mdDialog.cancel();
                };

                $scope.answer = function(answer) {
                    this.parentController.$mdDialog.hide(answer);
                    this.parentController.data.Account = this.cs;
                    this.parentController.actionRequest('changeaccount', this.parentController.data );
                };
            },
            // templateUrl: 'dialog1.tmpl.html',
            template: `
                <md-dialog aria-label="Kuberlab rebase">
                 <md-toolbar>
                    <div class="md-toolbar-tools"><h2>Select new account</h2></div>
                    
                    </md-toolbar>
                    <md-dialog-content>
                        <div class="md-dialog-content">
                            <md-radio-group ng-model="cs" >
                              <md-radio-button ng-repeat="item in list | filter:{ Service: data.Provider }" value="{{item.ServiceName}}" >{{item.ServiceName}}</md-radio-button>
                              <!--<md-radio-button value="test" class="md-primary">test</md-radio-button>-->
                            </md-radio-group>
                        </div>
                    </md-dialog-content>
                    <md-dialog-actions layout="row">
                      
                      <md-button ng-click="cancel('not useful')">Cancel</md-button>
                      <md-button ng-click="answer()">Apply</md-button>
                    </md-dialog-actions>
                </md-dialog>
            `,
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose:true
            // ,
            // fullscreen: this.$scope.customFullscreen // Only for -xs, -sm breakpoints.
        });
            // .then(function(answer) {
            //     debugger;
            //     $scope.parentController.saveService($scope.cs);
            // }, function() {
            //     debugger;
            //     // $scope.status = 'You cancelled the dialog.';
            // });
    }

    share(ev) {
        var then = this;
        this.installError = null;
        this.$mdDialog.show({
            locals: {
                dialog: then.$mdDialog,
                clusterResource : this.clusterResource,
                params : {clusterName: this.data.Name}
            },
            controller: ProjectClusterShareController,
            controllerAs: '$ctrl',
            template: ProjectClusterShareTemplate,
            parent: angular.element(document.querySelector('#mainBody')),
            targetEvent: ev,
            clickOutsideToClose: false,
            escapeToClose: false
        }).then(
            function(data){
                if(data.ID) {
                    then.$state.go('sharedCluster.tab', {tab: 'my', tabItem: data.ID});
                }
            },
            function () {
            }
        );

    }

    isDisabled() {
        if(this.data.Status == 'Undefined' || this.data.Status == 'Stopped') return true;
        return false;
    }

    getFullInfo(update){}

    goTasks(task){
        debugger;
    }
}
