
import {CoreServiceResource} from '../../core/service/core_service_resource'

export class ProjectStoragesService extends CoreServiceResource {
    constructor ($resource, $q, KuberlabConfigApi, $rootScope) {
        super(...arguments);
    }

    getResource(params) {
        if( !params.wsId || !params.projectName || !params.envName || !params.clusterName ) {
            throw "error params";
        }
        if( this.wsId != params.wsId || this.projectName != params.projectName || this.envName != params.envName || this.clusterName != params.clusterName ){
            var apiPath = this.cfg.APIPath + '/workspace/' + params.wsId + '/projects/' + params.projectName + '/env/' + params.envName + '/clusters/' + params.clusterName + '/storage/:storageName';
            this._resource = this.$resource(
                apiPath,
                {storageName: '@Name'},
                _.extend({}, this.cfg.ResourceParams,
                    {
                        create: {
                            method: "POST",
                            params: {storageName: "@name"}
                        },
                        sharedcluster: {
                            method: "GET",
                            isArray: true,
                            url: this.cfg.APIPath + '/sharedclusters/available/' + params.wsId + '/:sharedcluster/storage',
                            params: {
                                sharedcluster: '@sharedcluster'
                            }
                        },
                        default: {
                            method: "PUT",
                            url: apiPath + '/default',
                            params: {storageName: '@Name'}
                        }
                    }
                )
                            );
            this.wsId = params.wsId;
            this.projectName = params.projectName;
            this.envName = params.envName;
            this.clusterName = params.clusterName;
            this.list = null;
        }
        return this._resource;
    }
}
