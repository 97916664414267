import {controller, template, ChartWizardDatasetComponent} from './dataset'

class controllerModel extends controller {

    constructor ($scope, MlmodelService){
        super(...arguments);
        this.Interface = 'mlmodel';
        this.label = 'Model';
    }

    // $onInit(){
    //     super.$onInit();
    // }

    setDataset(data) {

        this.datasetStorage = {
            model: data ? data.value || '' : '',
            workspace: data ? data.workspace || '' : '',
            version: data ? data.version || '' : ''
        };

        // if(data){
        //     this.datasetStorage = {
        //         model: data.value || '',
        //         workspace: data.workspace || '',
        //         version: data.version || ''
        //     };
        // } else {
        //     this.datasetStorage = {workspace: "", model: "", version: ""};
        // }
    }

    setData(dataset) {
        if (dataset) {
            this.data.value = dataset.model;
            this.data.workspace = dataset.workspace;
            this.data.version = dataset.version;
        }else{
            this.data.value = '';
            this.data.workspace = '';
            this.data.version = '';
        }

    }

}

export const ChartWizardModelComponent = {
    bindings: ChartWizardDatasetComponent.bindings,
    controller : controllerModel,
    template: `
        <div flex layout="row" layout-align="start center">
            <cmp-storage-model data="$ctrl.datasetStorage" workspace-name="$ctrl.parent.workspace_name" flex ng-if="!$ctrl.disable" ></cmp-storage-model>
             <div ng-if="$ctrl.disable" flex layout="row" layout-align="start center">
                <md-input-container flex>
                    <label translate>{{$ctrl.label}}</label>        
                    <md-select ng-model="$ctrl.dataset.dataset" ng-disabled="$ctrl.disable" ></md-select>    
                </md-input-container>
                <md-input-container flex>
                    <label translate>COMMON_VERSION</label>        
                    <md-select ng-model="$ctrl.dataset.version" ng-disabled="$ctrl.disable" ></md-select>    
                </md-input-container>
            </div>

            <md-checkbox ng-change="$ctrl.disabled()" ng-model="$ctrl.disable">{{'COMMON_DISABLE' | translate }}</md-checkbox>
        </div>
    `
};
