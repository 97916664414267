export function LayoutMainMenu () {
    function link (scope, element) {
        element.on('mouseenter', function() {
            element.addClass('over');
        });
        element.on('mouseleave', function() {
            element.removeClass('over');
        });

        scope.$on('$mdMenuClose', (event) => {
            element.removeClass('overMenu');
        });

        scope.$on('$mdMenuOpen', (event) => {
            element.addClass('overMenu');
        });
    }

    return({
        link: link,
        restrict: "E"
    });
}

