 export class OrganizationsService {
    constructor ($resource, KuberlabConfigApi, $q, UserService) {
        this.$q = $q;
        this.UserService = UserService;
        this.cfgApi = KuberlabConfigApi;
        this._currentUserPromise = false;
        var orgApi = this.cfgApi.APIPath + '/org/:orgId';
        this.organizations = $resource(
            orgApi,
            {orgId : '@ID'},
            _.extend({}, KuberlabConfigApi.ResourceParams, {
                upload: {
                    method: 'POST',
                    url: orgApi + '/picture',
                    transformRequest: function (data) {
                        var fd = new FormData();
                        angular.forEach(data, function(value, key) {
                            fd.append(key, value);
                        });
                        return fd;
                    },
                    headers: {'Content-Type':undefined, enctype:'multipart/form-data'}
                },
            }));
        this.organizationCheckDelete = $resource(this.cfgApi.APIPath + '/org/:orgId', {orgId : '@ID'},  {delete : {method: 'DELETE', isArray: true}} );
        this.namechange = $resource(this.cfgApi.APIPath + '/org/:orgId/name?name=:name', {orgId: '@orgId', name: "@name"},  KuberlabConfigApi.ResourceParams );
        var teamsUrl =  this.cfgApi.APIPath + '/org/:orgId/teams/:teamId';
        this.teams = $resource(
                    teamsUrl,
                    {orgId : '@orgId', teamId: '@ID'},
                    _.extend(
                            {},
                            KuberlabConfigApi.ResourceParams,
                            {
                                permissions: {
                                    method: "GET",
                                    // isArray: true,
                                    url: teamsUrl + '/permissions'
                                },
                                update_permissions: {
                                    method: "PUT",
                                    // isArray: true,
                                    url: teamsUrl + '/permissions'
                                }
                            }
                            )
                    );
        this.invite = $resource(this.cfgApi.APIPath + '/org/:orgId/teams/:teamId/invite', {orgId : '@orgId', teamId: '@teamId'},  KuberlabConfigApi.ResourceParams );
        this.users = $resource(this.cfgApi.APIPath + '/org/:orgId/teams/:teamId/users/:userId', {orgId : '@orgId', teamId: '@teamId', userId: '@ID'},  KuberlabConfigApi.ResourceParams );
        this.nameisavailable = $resource(this.cfgApi.APIPath + '/name/isavailable', {name : '@name'},  KuberlabConfigApi.ResourceParams );
        this.namepickup = $resource(this.cfgApi.APIPath + '/name/pickup', {name : '@name'},  KuberlabConfigApi.ResourceParams );
        this._organizations;

    }

    orgList (update) {
        var self = this;
        if(update) {
            this.orgListPromise = null;
        }
        if(!this._organizations) {
            this._organizations = [];
            update = true;
        }
        if(update) {
            this.requestOrgList(update);
        }
        return this._organizations;
    }

    requestOrgList (update) {
        if(!this._requestOrgList || update){
            var self = this;
            this._requestOrgList = this.organizations.query()
                .$promise
                .then(
                    function(r){

                        var list = _.map(r, (v) => {
                            var o = _.findWhere(self._requestOrgList, {ID: v.ID});
                            return o ? _.extend({}, o, v) : v;
                        });
                        self._organizations = list;
                    },
                    function (r) {
                        console.error("Organizations request error", r);
                    }
                );
        }
        return this._requestOrgList
    }

    addOrg(data = {}) {
        var self = this;
        var org = new this.organizations(data)
            .$create(
                {},
                function (r) {
                    self._organizations.push(r);
                    self.UserService.getWs(true);
                }
            );
        return org;
    }

    getOrg (Id) {
        var org = _.findWhere(this._organizations, {ID : Id});
        if(org) {
            var d = this.$q.defer();
            d.resolve(org);
            return d.promise;
        } else {
            return this.organizations.get({orgId : Id}).$promise;
        }
    }
     
    orgNameIsAvailable (data) {
             return this.nameisavailable.get(data).$promise;
     }

     orgNamePickup (data) {
         return this.namepickup.get(data).$promise;
     }

 }