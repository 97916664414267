import {ControllerList} from '../../core/controller/list'

export class StoragePersistentListController extends ControllerList {
    constructor(StoragePersistentService, $state, $scope) {
        super();
        this.$scope = $scope;
        this.$state = $state;
        this.service = StoragePersistentService;
        // this.resource = StoragePersistentService.getResource($state.params);
        this.list;
        this.canEdit = true;
        this.watchWS('storageComplete', this.ws_storageComplete);
    }

    $onInit() {
        this.getList();
    }

    getList(update) {
        this.resource = this.service.getResource(this.$state.params);
        if(!update) this.loading = true;
        this.error = false;
        this.resource.query({Unactive:true}).$promise
            .then(
                (r)=>{
                    this.list = r;
                },
                (err) => {
                    this.error = err;
                }
            )
            .finally(() => {
                this.loading = false;
                this.complite = true;
            })
    }

    delayLoad() {
        if(this._delayLoadTime) { clearTimeout(this._delayLoadTime); }
        this._delayLoadTime = _.delay(() => { this.getList(true); }, 60000);
    }

    hasNew() {
        return  (this.list && this.list.length && this.list[this.list.length - 1].isNew);
    }

    add(data = {isNew : true}) {
      // prevent adding a few empty storage
      // if (this.list.length && !this.list[this.list.length - 1].Name) return;
      super.add(data);
    }

    $destroy() {
        if(this._delayLoadTime) { clearTimeout(this._delayLoadTime); }
    }

    ws_storageComplete (even, data) {
        if(this.$state.params.wsId  == data.WorkspaceName) {

            var item = _.findWhere(this.list, {Name: data.Name});
            if(item) {
                item = _.extend(item, data) ;
                this.$scope.$digest();
            }
        }
    }
}

