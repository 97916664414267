export class ControllerDataset  {
    // constructor($state, CatalogDataService){
    constructor($state, CatalogService, ErrorService, $scope){
        this.$state = $state;
        this.ErrorService = ErrorService;
        this.Service = CatalogService;
        this.list = [];
        this.Interface = 'dataset';
        this.dataName = 'dataset';
        this.label = 'Dataset FS';
        this.dataset = {};
        this.$scope= $scope;
        $scope.$watch(
            () => { return this.data; },
            (c) => { this.setDef(c); },
            true
        );
        $scope.$watch(
            () => {return this.workspaceName; },
            (c) => { if(c) this.initList(); }
        )
    }

    $onInit() {
        this.dataDef = _.clone(this.data) || {};
        if(!this.data) this.data = {};
        this.setDef(this.data);
        if(!this.workspaceName) this.initList();

    }

    initList() {
        this.resource = this.Service.getResource({Interface: this.Interface, wsId: this.workspaceName});
        this.getList();
    }

    setDef(data) {
        if( data[this.dataName] ){
            this.dataset.WorkspaceName = data.workspace;
            this.dataset.Name = this.data[this.dataName];
            this.dataset.version = data.version;
        }
    }

    getList() {
        var self = this;
        this.error = null;
        this.Service.getList(true, {with_global: true, all: true}, true)
            .then(
                (r) => {
                    if(self.workspaceName) {
                        self.list = _.filter(r, (i) => {
                            return i.Published || i.WorkspaceName == self.workspaceName;
                        })
                    } else {
                        self.list = r;
                    }

                    this.cList = _.filter(r, (i) => {
                        return i.WorkspaceName == self.workspaceName;
                    });

                    this.gList = _.filter(r, (i) => {
                        return i.WorkspaceName != self.workspaceName;
                    });

                    if( self.dataDef[self.dataName] ) {
                        self.dataset = _.findWhere(r, {WorkspaceName: self.dataDef.workspace, Name:  self.dataDef[this.dataName]});
                        if(!self.dataset){
                            self.dataset = _.findWhere(r, {Name:  self.dataDef[this.dataName]});
                        }
                    }
                    if(!self.dataset || !self.dataset.Name ) {
                        self.dataset = _.first(r);
                    }
                    self.changeDataset();
                },
                (err) => {
                    this.error = err; //this.ErrorService.error(err);
                }
            );
    }

    getVersions() {
        var self = this;
        this.error = null;
        this.Service.getResource({wsId: this.dataset.WorkspaceName, Interface: this.Interface });
        this.Service.getVersions(this.dataset)
            .then(
                (r) => {
                    // self.versions = _.sortBy (r, (v) => {
                    //     return new Date(v.Created);
                    // }); //.reverse();
                    // // self.versions = _.sortBy (r, 'version'); //.reverse();


                    self.versions = this.getVersionList(r);
                    var v;
                    if(self.data.version) {
                        v = _.findWhere( self.versions, {Version : self.data.version} );
                    }
                    if(!v) {
                        v = _.first(self.versions);
                    }
                    if( v ) {
                        self.data.version = v.Version;
                    }
                },
                (err) => {
                    this.error = err;
                    // this.ErrorService.error(err);
                }
            )
    }

    getVersionList(r){
        var list = _.filter(r, (i) => {
            return i.Editing != true;
        });
        return list;
    }

    changeDataset() {
        if(!this.dataset) return false;
        if(this.data.workspace != this.dataset.WorkspaceName || this.data[this.dataName] != this.dataset.Name)     {
            this.data.workspace = this.dataset.WorkspaceName;
            this.data[this.dataName] = this.dataset.Name;
            this.data.version = null;
            this.versions = null;
        }
        if(!this.versions )
            this.getVersions();
    }

    fork(name) {
        let resource = this.Service.getResource({wsId: this.dataset.WorkspaceName, Interface: this.Interface });
        this.forking = true;
        this.error = null;
        var req;
        var data = {Name:  this.dataset.Name, toWsId: this.workspaceName };
        if(name) {
            data.newName = name;
        }
        resource.fork(data).$promise
            .then(
                (r) => {
                    this.cList.push(r);
                    this.dataset = r;
                    this.changeDataset();
                    this.errorName = null;
                },
                (err) => {
                    if(err.status == 409){
                        this.errorName = err;
                    }else{
                        this.error = err;
                        this.errorName = null;
                    }
                }
            )
            .finally(
                () => {
                    this.forking = null;
                    this.forkName = null;
                }
            )
    }

    forkWithName (form) {
        if(!form.$valid) return false;
        this.fork(this.forkName);

    }

    cancelForkName() {
        this.errorName = null;
        this.forkName = null;
    }

}

export const StorageDatasetComponent = {
    bindings: {
        data : "=",
        workspaceName: '<'
    },
    controller: ControllerDataset,
    template: `
    <div layout="column" flex>
        <div layout="row" flex layout-wrap ng-if="!$ctrl.errorName">
            <div flex="50" layout="row" layout-align="start center">
                <md-input-container flex>
                    <label translate>{{$ctrl.label}}</label>        
                    <md-select ng-model="$ctrl.dataset" ng-change="$ctrl.changeDataset()" required flex>

                        <md-optgroup label="local" ng-if="$ctrl.cList.length">
                           <md-option ng-repeat="item in $ctrl.cList | orderBy : 'DisplayName'" ng-value="item" >{{item.DisplayName}} [{{item.WorkspaceDisplayName}}]</md-option>
                        </md-optgroup>
                        
                        <md-optgroup label="global" ng-if="$ctrl.gList.length">
                           <md-option ng-repeat="item in $ctrl.gList | orderBy : 'DisplayName' " ng-value="item" >{{item.DisplayName}} [{{item.WorkspaceDisplayName}}]</md-option>
                        </md-optgroup>
                    
                    </md-select>   
                </md-input-container>
                <md-progress-circular class="md-hue-2" md-diameter="20px" ng-if="$ctrl.forking"></md-progress-circular> 
                <md-button ng-if="$ctrl.workspaceName && $ctrl.dataset.WorkspaceName != $ctrl.workspaceName" ng-click="$ctrl.fork()" ng-disabled="$ctrl.forking">fork</md-button>
            </div>
            <md-input-container flex="50" ng-if="$ctrl.versions.length">
                <label translate>COMMON_VERSION</label>        
                <md-select ng-model="$ctrl.data.version" required>
                    <md-option ng-repeat="item in $ctrl.versions" ng-value="item.Version" >{{item.Version}} <span ng-if="item.SizeBytes">(<span ng-bind-html="$ctrl.Service.datasetVersionSize(item)"></span>)</span> {{item.Created}}</md-option>
                </md-select>    
            </md-input-container>
            <md-input-container flex="50" ng-if="!$ctrl.versions.length">
                <label>No versions</label>        
                <input ng-disabled="true" >
            </md-input-container>
        </div>
        
        <form name="setForkName" ng-submit="$ctrl.forkWithName(setForkName)"  ng-if="$ctrl.errorName" >
            {{$ctrl.errorName.data.Error}}
            <div flex layout="row" layout-align="start center">
                <md-input-container flex>
                    <label>New name</label>
                    <input ng-model="$ctrl.forkName" required ng-disabled="$ctrl.forking" >
                </md-input-container>
                <md-button type="submit" ng-disabled="$ctrl.forking" >fork</md-button>
                <md-button ng-click="$ctrl.cancelForkName()" ng-disabled="$ctrl.forking" >cancel</md-button>
                <md-progress-circular class="md-hue-2" md-diameter="20px" ng-if="$ctrl.forking"></md-progress-circular>
            </div>
        </form>
        
        
        <!--<form name="setForkName" submit="$ctrl.forkWithName(setForkName)" flex layout="row" layout-align="start center" ng-if="$ctrl.errorName">-->
            <!--<md-input-container flex>-->
                <!--<label>Name</label>-->
                <!--<input ng-model="$ctrl.forkName" required ng-disabled="$ctrl.forking" >-->
            <!--</md-input-container>-->
            <!--<md-button type="submit" ng-disabled="$ctrl.forking" >fork</md-button>-->
            <!--<md-button ng-click="$ctrl.cancelForkName()" ng-disabled="$ctrl.forking" >cancel</md-button>-->
            <!--<md-progress-circular class="md-hue-2" md-diameter="20px" ng-if="$ctrl.forking"></md-progress-circular> -->
        <!--</form>-->
        <cmp-error-toast ng-if="$ctrl.error" error="$ctrl.error"></cmp-error-toast>
    </div>
`
};
