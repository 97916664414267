import {CatalogDataListController} from './catalog_data_list_controller'

// class controller extends MlmodelListController {
//     constructor (ChartsService, $scope, $state, $localStorage, $rootScope, UserService, $element, WorkspacePermissions) {
//         super(...arguments);
//         // this.limit = 3;
//     }
// }

export const CatalogDataCardHomeComponent = {
    controller : CatalogDataListController,
    template:  ($templateCache) => {
        return $templateCache.get('kuberlab/catalog/base/card/card_home.html');
    }
};
