class Controller  {
    constructor($state, WorkspaceService){
        this.$state = $state;
        // this.WorkspaceService = WorkspaceService;
        // this.wsaList = [];
    }

    // $onInit() {
    //     this.resource = this.WorkspaceService.Serviceacconts(this.$state.params.wsId);
    //     this.getWsaList();
    // }
    //
    // getWsaList() {
    //     var self = this;
    //     this.resource.query(
    //         {},
    //         function(r){
    //             self.wsaList = r;
    //         },
    //         function (r) {
    //             console.error("Organizations team users request error", r);
    //         }
    //     );
    // }
}

export const StorageGitComponent = {
    bindings: {
        data : "=",
        required:"<"
    },
    controller: Controller,
    template: `
        <div style="width: 100%" flex layout="row" layout-align="start end">
            <cmp-repo-select repository-url="$ctrl.data.repository" repository-dir="$ctrl.data.directory" repository-rev="$ctrl.data.revision" repository-ac="$ctrl.data.accountId" flex required="$ctrl.required" ws-id="$ctrl.$state.params.wsId"></cmp-repo-select>
            <!--<md-input-container flex="20">-->
                <!--<label>Account</label>        -->
                <!--<md-select ng-model="$ctrl.data.accountId">-->
                    <!--<md-option ng-value="item.ID">without account</md-option>-->
                    <!--<md-option ng-repeat="item in $ctrl.wsaList | filter:{Service: 'git'} " ng-value="item.ID">{{item.Service}}:{{item.Name}}</md-option>-->
                <!--</md-select>   -->
                 <!--<help-hint hint="wsa.git.account"></help-hint>-->
            <!--</md-input-container>-->
        </div>
`
};