export class ApplicationService {
  constructor($translate, $mdDialog, Utils, Message, ApplicationsService) {
    this.$translate = $translate;
    this.$mdDialog = $mdDialog;
    this.Utils = Utils;
    this.Message = Message;
    this.ApplicationsService = ApplicationsService;
  }

  /**
   * Remove application with dialog confirm.
   *
   * @param {object} params params
   * @param {object} params.applicationData application resource data
   * @param {string} [params.title=MLAPP_DELETE_DIALOG_TITLE] Dialog title
   * @param {string} [params.text=MLAPP_DELETE_DIALOG_MESSAGE] Dialog text
   * @param {string} [params.okText=COMMON_DELETE] Dialog OK button text
   *
   * @returns {Promise}
   */
  remove(params) {
    return new Promise((resolve, reject) => {
      this.Utils.confirmDialog({
        title: params.title || 'MLAPP_DELETE_DIALOG_TITLE',
        text: params.text || 'MLAPP_DELETE_DIALOG_MESSAGE',
        okText: 'COMMON_DELETE'
      }).then(() => {
        params.applicationData.$delete().then(() => {
            resolve();
          },
          function(error) {
            reject();
            console.error(error);
          }
        );
      });
    });
  }

  /**
   * Enable or disable application with dialog confirm.
   *
   * @param {object} params params
   * @param {boolean} [params.isEnable] true to enable and wise versa
   * @param {object} [params.applicationData] application resource data
   * @param {string} [params.title] Dialog title. Based on isEnable title will change
   * @param {string} [params.text] Dialog text. Based on isEnable text will change
   * @param {string} [params.okText] Dialog OK button text. Based on isEnable text will change
   *
   * @returns {Promise}
   */
  setEnabled(params) {
    const self = this;

    const title = params.title || params.isEnable ? 'MLAPP_ENABLE_DIALOG_TITLE' : 'MLAPP_DISABLE_DIALOG_TITLE';
    const text = params.text || params.isEnable ? 'MLAPP_ENABLE_DIALOG_MESSAGE' : 'MLAPP_DISABLE_DIALOG_MESSAGE';
    const applicationData = params.applicationData;
    const action = params.isEnable ? 'enable' : 'disable';

    return new Promise((resolve, reject) => {
      this.Utils.confirmDialog({
        title,
        text,
        applicationData,
        okText: params.isEnable ? 'COMMON_ENABLE' : 'COMMON_DISABLE',
        isEnable: params.isEnable
      }).then(() => {
        const wsId = applicationData.WorkspaceName;
        const applicationName = applicationData.Name;

        const applicationsResource = this.ApplicationsService.getResource({
          wsId, applicationName
        });

        self.apiError = false;
        applicationsResource[action]({wsId, application: applicationName}).$promise.then(() => {
            applicationData.Enabled = !applicationData.Enabled;

            if (action === 'enable') {
              applicationData.DisableReason = null;
            }

            resolve();
          }, (err) => {
            console.error(err);
            self.apiError = err;

            this.Message.error(err.data.Error);

            reject();
          }
        );
      });
    });
  }
}
