
import Stateconfig from './settings_stateconfig'
import OrganizationsModule from '../organizations/organizations_module'
import {SettingsService} from './settings_service'
import {SettingsServicesItem, SettingsServicesList} from './settings_connections'

export default angular
    .module('settings', [
        'ngMaterial',
        // OrganizationsModule.name
    ])
    .service('SettingsService', SettingsService)
    .config(Stateconfig)
    .component('cmpSettingsConnectionList', SettingsServicesList)
    .component('cmpSettingsConnectionItem', SettingsServicesItem)
